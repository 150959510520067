import React, { useState, useEffect } from "react";
import axios from "axios";
import DashboardLayout from "../layout/DashboardLayout";
import { BiChevronRight } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import FeatureForm from "../Agent/FeatureForm";
import token from "../services/token";
import { useSelector } from "react-redux";

export default function CreateFeature() {
  const navigate = useNavigate();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const data = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = data && data.length >= 1 ? data[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const URL = process.env.REACT_APP_BASEURL;
  const tokens = token.getToken();
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const [last, setLast] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [categoryResponse, setCategoryResponse] = useState(null);
  const [fetchedData, setFetchedData] = useState(null);
  const [instructionName, setInstructionName] = useState("");
  const [instructionDescription, setInstructionDescription] = useState("");
  const [instructionManual, setInstructionManual] = useState("");
  const [support, setSupport] = useState([]);
  const data1 = localStorage.getItem("agent_features");
  const feature = JSON.parse(data1);
  const [next, setNext] = useState(false);
  const [previousClickCount, setPreviousClickCount] = useState(0); // Track the number of "Previous" clicks

  const [stepData, setStepData] = useState([]);

  const updateStepData = (index, data) => {
    const newData = [...stepData];
    newData[index] = data;
    setStepData(newData);
  };

  const handleNextClick = async () => {
    if (currentFeatureIndex < feature.length - 1) {
      // Save the current step data before moving to the next step

      if (previousClickCount === 0) {
        const response = await createCategory();

        if (response && response.status) {
          // Create category and add instructions
          const addInstructionsStatus = await addInstructionsToCategory(
            response?.data?.id
          );

          if (!addInstructionsStatus) {
            console.error(
              "Error adding instructions. Move back to the previous step or handle accordingly."
            );
          } else {
            setCurrentFeatureIndex(currentFeatureIndex + 1);

            setCategoryName(""); // Clear the categoryName input on the next step
            setCategoryDescription("");
            setInstructionManual("");
            setSupport([]);
            setNext(true);
            setInstructionDescription("");
            setPreviousClickCount(0); // Reset previousClickCount on "Next" click
          }
        } else {
          // Category creation failed or null response, handle accordingly
          // console.log("Category creation failed");
        }
      } else {
        setPreviousClickCount(previousClickCount - 1);
        updateStepData(currentFeatureIndex, {
          categoryResponse,
          categoryName,
          instructionName,
          instructionDescription,
          instructionManual,
          support,
        });
        setCurrentFeatureIndex(currentFeatureIndex + 1);
        setNext(true);
      }
    } else {
      console.log("error");
    }
  };

  const handlePreviousClick = () => {
    if (currentFeatureIndex > 0) {
      setCurrentFeatureIndex(currentFeatureIndex - 1);
      setLast(false);
      setCategoryResponse(null);
      setFetchedData(null);
      if (previousClickCount === 0) {
        setPreviousClickCount(previousClickCount + 1); // Increment previousClickCount
      } else {
        setPreviousClickCount(previousClickCount + 1); // Increment previousClickCount
      }
    }
  };

  const handleFinishClick = async () => {
    // Save the current step data before finishing
    updateStepData(currentFeatureIndex, {
      categoryResponse,
      categoryName,
      instructionName,
      instructionDescription,
      instructionManual,
      support,
    });

    // Create category and add instructions
    const response = await createCategory();
    const addInstructionsStatus = await addInstructionsToCategory(
      response?.data?.id
    );

    if (!addInstructionsStatus) {
      console.error(
        "Error adding instructions. Move back to the previous step or handle accordingly."
      );
    } else {
      ChoosenDataresponse();
      navigate("/agent", {
        state: {
          feature,
        },
        categoryResponse,
        categoryId: categoryResponse?.data?.id,
        fetchedData,
      });
      setCategoryDescription("");
      setNext(true);
      setInstructionManual("");
      setSupport([]);
      setInstructionDescription("");
    }
  };

  const createCategory = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${URL}/dashboard/applications/${applicationId}/category`,
        {
          name: feature[currentFeatureIndex].feature,
          description: categoryDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      setCategoryResponse(res.data);
      return res.data;
    } catch (error) {
      setLoading(false);
      return null;
    }
  };

  const addInstructionsToCategory = async (categoryId) => {
    try {
      const res = await axios.post(
        `${URL}/dashboard/applications/${applicationId}/category/${categoryId}/instructions`,
        {
          name: feature[currentFeatureIndex].feature,
          description: feature[currentFeatureIndex].description,
          how_it_works: instructionManual,
          support_channel: support.map((each) => ({
            support_channel: each.support_channel,
            website:
              each.support_channel === "Phone Number"
                ? `234${each.website}`
                : each.website,
          })),
        },
        {
          headers: {
            Authorization: `Bearer ${tokens}`,
            "Content-Type": "application/json",
          },
        }
      );

      // console.log("Instructions added successfully:", res.data);
      return res.data.status; // Return the status from the API response
    } catch (error) {
      console.error("Error adding instructions:", error);
      return false; // Return false in case of an error
    }
  };

  const fetchInstructions = async (categoryId) => {
    try {
      const res = await axios.get(
        `${URL}/dashboard/applications/${applicationId}/category/${categoryId}/instructions`,
        {
          headers: {
            Authorization: `Bearer ${tokens}`,
          },
        }
      );
      setFetchedData(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const ChoosenDataresponse = async () => {
    try {
      await axios.get(
        `${URL}/dashboard/applications/${applicationId}/category`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Load previously saved step data when the component mounts
    const savedStepData = JSON.parse(localStorage.getItem("stepData")) || [];
    setStepData(savedStepData);
  }, []);

  useEffect(() => {
    // Save step data to localStorage whenever it changes
    localStorage.setItem("stepData", JSON.stringify(stepData));
  }, [stepData]);
  return (
    <DashboardLayout title={"Assistant"} user={true}>
      <main className="font-figtree p-10 mb-7">
        <header className="flex gap-2 items-center mb-4">
          <Link
            to="/agent"
            className="text-[#D2D0D0] text-sm font-normal hover:underline"
          >
            Memory
          </Link>
          <BiChevronRight className=" text-xl text-[#D2D0D0]" />
          <p className="text-[#121212] text-sm font-normal ">
            Add Instruction
          </p>
        </header>
        <section className="flex justify-between w-full items-center">
          <div className="flex flex-col gap-2">
            <h1 className="text-[24px] font-medium text-[#121212] font-figtree">
              Add Instruction
            </h1>
            <p className="text-xs text-[#7F7F81] font-figtree">
              Instruction {currentFeatureIndex + 1} of {feature.length}
            </p>
          </div>
          <div className="flex items-center gap-3">
            <button
              onClick={handlePreviousClick}
              className="cursor-pointer outline-none py-3 w-auto px-5 border border-[#D0D5DD] rounded-lg text-[#828282] text-[14px] font-bold"
            >
              Previous
            </button>
            {currentFeatureIndex === feature.length - 1 ? (
              <button
                onClick={handleFinishClick}
                className={`${
                  true ? "block" : "hidden"
                } cursor-pointer outline-none py-3 w-auto px-5 bg-[#121212] rounded-lg text-white text-[14px] font-bold`}
              >
                Finish
              </button>
            ) : (
              <button
                onClick={handleNextClick}
                className={`${
                  last ? "hidden" : "block"
                } cursor-pointer outline-none py-3 w-auto px-5 bg-[#121212] rounded-lg text-white text-[14px] font-bold`}
              >
                Next Instruction
              </button>
            )}

            <button
              onClick={handleFinishClick}
              className={`${
                last ? "block" : "hidden"
              } cursor-pointer outline-none py-3 w-auto px-5 bg-[#121212] rounded-lg text-white text-[14px] font-bold`}
            >
              Finish
            </button>
          </div>
        </section>

        <FeatureForm
          stepData={stepData[currentFeatureIndex]}
          next={next}
          setNext={setNext}
          instructionNameDefault={feature[currentFeatureIndex].feature}
          feature={feature[currentFeatureIndex]}
          categoryId={categoryResponse?.data?.id}
          categoryName={categoryName}
          setCategoryName={setCategoryName}
          instructionName={instructionName}
          fetchedData={fetchedData}
          setInstructionName={setInstructionName}
          instructionDescription={instructionDescription}
          instructionDescriptionDefault={
            feature[currentFeatureIndex].description
          }
          setInstructionDescription={setInstructionDescription}
          instructionManual={instructionManual}
          setInstructionManual={setInstructionManual}
          support={support}
          setSupport={setSupport}
          width={true}
          multipleInstructions={true}
        />
      </main>
    </DashboardLayout>
  );
}

import React, { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import WhatsApp from "Assets/svg/WhatsApp-publish.svg";
import Website from "Assets/svg/website-publish.svg";
import { useSelector, useDispatch } from "react-redux";
import { api } from "services/api";
import { getApplicationById,getAnEmployee, getApplicationDraft, setAgent } from "features/application/getApplicationByIdSlice";
import { useLocation } from "react-router";
import { ErrorMessage } from "Toast-Messages/Toastmessages";
import { getAllPlugins } from "@/../features/plugins/getAllPluginsSlice";
export default function PublishModal({ handleClose }) {
  const location = useLocation()
  const dispatch = useDispatch();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const appDraftchanges = appDetail.draftChanges;

  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const Agent = getAgentApplication?.agent || null;
  const applicationId = Agent.id;
  const [changes, setChanges] = useState([
    "New Instruction Created",
    ,
    "Enquiries Instruction Disabled",
    ,
    "Document Uploaded to ‘About’ Category",
    ,
    "Assistant's Personality Changed",
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const handlePublish = async () => {
    if(!applicationId) return;
    try {
      setLoading(true);
      await api.post(`/dashboard/applications/${applicationId}/draft/publish`);
      dispatch(getApplicationDraft(applicationId))
      .then(() => {
         dispatch(getAnEmployee({ id:applicationId }));
        
        // if (location.pathname.includes("plugins")){ //this checks the path before getting new data
        //   dispatch(getAllPlugins({ applicationId }))
        // }else if (location.pathname.includes("dashboard")){// this checks the path and stores the new data
        //   dispatch(getApplicationById()).then((res) => {
        //     // console.log(res, "tag res");
        //     dispatch(setAgent(res?.payload?.data[0]))
        //   })
        // }
      });
      handleClose()
    } catch (e) {
      setError(e.data.message)
      setTimeout(() => {
        setError(false);
      }, 3000);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="mt-[89px] rounded-xl py-[30px] px-[51px] w-[519px] bg-white h-fit">
      <p className="text-center text-2xl font-medium">Publish Changes</p>
      <p className="text-center text-sm font-normal text-BLACK-_300">
        Are you sure you want to make your changes live?
      </p>
      <p className="font-medium text-sm mt-10">Review Changes</p>

      <div className=" max-h-96 overflow-auto ">
        {appDraftchanges.map((change, index) => (
          <p
            key={index}
            className="py-6 text-BLACK-_300 text-xs border-b border-WHITE-_300"
          >
            {change}
          </p>
        ))}
      </div>

      {/* <div className="mt-8">
        <p>Publishing To:</p>
        <div className="mt-5 flex gap-5">
          <img src={WhatsApp} alt="img" />
          <img src={Website} alt="img" />
        </div>
      </div> */}
      <div className="mt-8 flex flex-col gap-2 justify-end">
        <button
          disabled={loading}
          type="submit"
          className="bg-BLACK-_500 w-full h-[34px] rounded-lg"
          onClick={handlePublish}
        >
          {loading ? (
            <span className="flex justify-center w-full">
              <CgSpinner className=" animate-spin text-lg text-WHITE-_100" />
            </span>
          ) : (
            <p className="text-WHITE-_100 text-[12px] font-medium">Upload</p>
          )}
        </button>
        <button
          className="bg-WHITE-_100 border w-full border-none h-[34px] rounded-lg"
          onClick={handleClose}
        >
          <p className="text-[#828282] text-[12px] font-medium">Cancel</p>
        </button>
      </div>
      {error && <ErrorMessage error={error} className="mt-5 mr-2" />}
    </div>
  );
}

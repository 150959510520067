import React, {useState} from "react";
import LeftArrow from "../Assets/svg/arrow-narrow-left.svg";
import WelcomeLogoText from "./WelcomeLogotext";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from "./Button";
import NavBar from "./NavBar";
import Terms from "./Terms";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { toast } from "react-toastify";
import axios from "axios";


const style = "rounded-xl px-[12px] w-[420px] line-[24px] py-[13px] text-white text-14px"
const API_URL = "https://staging-api-instance.wano.app/dashboard/auth/forgot-password"

export default function ForgotPassword() {
    const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState } = useForm();
  const { errors, isValid, isDirty } = formState;
  const navigate = useNavigate();

  function onSubmit(data) {
    setLoading(true)
    const mail = {
      email: data.email,
    }
    const config = {
      method: 'post',
      url: API_URL,
      data: JSON.stringify(mail),
      headers: {
        'accept': '/',
        'Content-Type': 'application/json'
      }
    }
    const resetLink = async () => {
      axios(config)
        .then((res) => {
          toast('Password reset code sent successfully')
          navigate("/reset-password");
          setLoading(false);
          return res.data
        }).catch((err) => {
          setLoading(false)
          toast.error('Email not found')
        })
    };
    resetLink();
    
  }

  function onError(errors) {
    console.log(errors);
  }
  return (
    <div className="min-h-screen grid content-between relative bg-[#F7F7F7]">
      <NavBar />
      <div className="w-[522px] mx-auto shadow py-[30px] px-[50px] rounded-xl grid gap-10 items-center justify-center bg-white">
       <div className="grid gap-3">
       <BiArrowBack
          onClick={() => navigate(-1)}
          className="cursor-pointer text-[18px]"
        />
        <WelcomeLogoText
          width={"w-[333px]"}
          title={"Forgot your password"}
          text={
            "Enter your email address below, you will receive a link in your inbox to continue."
          }
        />
       </div>
        <form
          className="grid gap-3 w-[420px]"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <label className="label text-[#101828] text">Email</label>
          <input
            type="email"
            id="email"
            {...register("email", {
              required: "Email address is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            })}
            className="input"
          />
          <span className="text-red-500 text-[12px]">
            {errors?.email && errors?.email?.message}
          </span>
        </form>
        <Button
          disabled={!isDirty && !isValid}
          className={isValid ? `bg-black ${style}` : `bg-[#EBEBEB] ${style}`}
          onClick={handleSubmit(onSubmit, onError)}
          text={"Send"}
          loading={loading}
        />
      </div>
      <Terms />
    </div>
  );
}

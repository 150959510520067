import Button from "@/../component/Button";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { CgSpinner } from "react-icons/cg";

const UnsavedSettings = ({ close, handleClose, handleSave, loading }) => {
  return (
    <div className="w-[522px] bg-[#ffffff] rounded-[12px] h-[276px] px-[23px] pt-[15px] pb-[20px] mt-[100px]">
   <div className="flex justify-end">
   <div
        className="max-w-[36px] max-h-[36px] border rounded-lg flex cursor-pointer justify-center items-center p-2"
        onClick={close}
      >
        <AiOutlineClose />
      </div>
   </div>
      <div className=" w-[406px] flex flex-col gap-[20px] items-center justify center mx-auto">
        <h2 className="text-[24px] leading-[28.8px] font-medium text-center mt-[40px]">
          Unsaved Changes
        </h2>
        <p className="w-[406px] text-[14px] leading-[16.8px] text-[#828282] font-normal text-center">
          You have made changes to your Assistant's settings, leaving without
          saving will discard changes you made.
        </p>
      </div>
      <div className="flex justify-end gap-2 mt-[56px]">
        <Button
          text="Discard Changes"
          className="w-[118px] h-[34px] rounded-lg border font-semibold text-xs text-[#828282] p-1"
          onClick={() => handleClose()}
        />
        <button
        //   text="Save Changes"
          className=" p-1 w-[118px] h-[34px] text-xs rounded-lg bg-BLACK-_500"
          onClick={() => handleSave()}
        >
            {loading ? (
                <span className="flex justify-center w-full">
                    <CgSpinner className=" animate-spin text-lg text-white" />
                </span>
                ) : (
                <span className="text-WHITE-_100">Save Changes</span>
                )}
        </button>
      </div>
      {/* <button
        disabled={!isFormModified || loading}
        onClick={() => handleSave()}
        className={`bg-[#121212] text-white text-sm font-semibold py-[11px] w-[140px] rounded-lg absolute top-0 right-0 ${
          isFormModified ? " cursor-pointer" : " cursor-not-allowed"
        }`}
      >
        {loading ? (
          <span className="flex justify-center w-full">
            <CgSpinner className=" animate-spin text-lg " />
          </span>
        ) : (
          <span>Save Changes</span>
        )}
      </button> */}
    </div>
  );
};

export default UnsavedSettings;

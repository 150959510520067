// pluginsSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const checkPluginsSlice = createSlice({
    name: 'plugins',
    initialState: {
        notificationUrls: {},
        channels: {},
    },
    reducers: {
        setNotificationUrl: (state, action) => {
            const { applicationPluginId, notificationUrl } = action.payload;
            state.notificationUrls[applicationPluginId] = notificationUrl;
        },
        setChannel: (state, action) => {
            const { applicationPluginId, channel } = action.payload;
            state.channels[applicationPluginId] = channel;
        },
    },
});

export const { setNotificationUrl, setChannel } = checkPluginsSlice.actions;

export default checkPluginsSlice.reducer;
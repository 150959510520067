import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import Terms from "./Terms";
import WelcomeLogoText from "./WelcomeLogotext";
import Vector from "../Assets/Vector.png";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import { FiChevronDown } from "react-icons/fi";
import { Popover } from "@headlessui/react";
import { HiCheck } from "react-icons/hi";
import { ErrorMessage, SuccessMessage } from "../Toast-Messages/Toastmessages";
import { useAuth } from "../context/UserContext";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplicationById,
  setAgent,
} from "../features/application/getApplicationByIdSlice";
import { loginUser } from "../features/auth/loginSlice";
import {
  getBusiness,
  setBusiness,
} from "../features/business/getBusinessSlice";
import { BiArrowBack } from "react-icons/bi";
import CustomSelect from "../component/CustomSelect";
import TokenService from "../services/token";
import { getCountries } from "../features/countries/getCountriesSlice";
import spinner from "../Assets/gif/loader.gif";

export default function CompanyDetails() {
  const dispatch = useDispatch();
  const getCountiresAndStore = async () => {
    dispatch(getCountries());
  };
  useEffect(() => {
    getCountiresAndStore();
  }, []);
  const storeCountries = useSelector((state) => state.getAllCountries);
  const countriesLoading = storeCountries.loading;
  const [country, setCountry] = useState("");
  const [countrycode, setCountrycode] = useState("+234");
  const [countryFlagIcon, setCountryFlagIcon] = useState("🇳🇬");
  const [selectedBussinessSize, setSelectedBussinessSize] = useState("");
  const { createBusiness } = useAuth();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [optionalCategory, setOptionalCategory] = useState("");
  const [optionalRole, setOptionalRole] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const logDetails = JSON.parse(sessionStorage.getItem("Log_Details"));
  //added this to get
  const token = TokenService.getToken();
  //end

  const [data, setData] = useState({
    name: "",
    description: "",
    team_size: "",
    category: "",
    website_url: "",
    business_email: "",
    purpose_for_wano: "string",
    country: "Nigeria",
  });

  const navigate = useNavigate();

  const handleBusinessSize = (size) => {
    setSelectedBussinessSize(size);
    setData({ ...data, team_size: size });
  };

  const handleCategory = (Category) => {
    setSelectedCategory(Category);
    if (Category === "Other(Please Specify):") {
      handleCategory2();
    } else {
      setData({ ...data, category: Category });
    }
  };

  const handleCategory2 = (Category) => {
    setOptionalCategory(Category);
    setData({ ...data, category: optionalCategory });
  };

  const handleRole = (Role) => {
    setSelectedRole(Role);
    if (Role === "Other(Please Specify):") {
      handleRole2();
    } else {
      setData({ ...data, description: Role });
    }
  };

  const handleRole2 = (Role) => {
    setOptionalRole(Role);
    setData({ ...data, description: optionalRole });
  };

  const Category = [
    "Agri Tech",
    "Financial Service",
    "SaaS",
    "Ecommerce",
    "Education",
    "Health Tech",
    "Transportation",
    "Other(Please Specify):",
  ];

  const Roles = [
    "CEO or Founder",
    "Software Developer",
    "IT Manager",
    "Customer Support Manager",
    "Project Manager",
    "Sales Manager",
    "Other(Please Specify):",
  ];
  const BussinessSize = ["1 - 10", "10 - 100", "100 - 500", "500+"];

  const handleInputChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  // const isFilled = Object.values(data).every(value => value !== '');
  const isFilled = Object.entries(data).every(([key, value]) => {
    if (key === "website_url") {
      return true;
    }
    return value !== "";
  });

  function handleSubmit() {
    setLoading(true);
    localStorage.setItem("data", JSON.stringify(data));

    localStorage.setItem("User_Type", "Sales");

    const companyData = {
      ...JSON.parse(localStorage.getItem("data")),
      purpose_for_wano: "Sales",
    };

    const handleCreate = async (logDetails) => {
      try {
        const res = await createBusiness(JSON.stringify(companyData));

        if (res?.status) {
          // setActive Business
          dispatch(setBusiness(res.data.data));
          dispatch(setAgent({ type: "" }));

          if (logDetails) {
            const loginResponse = await dispatch(loginUser(logDetails));
            if (loginResponse?.payload?.status) {
              sessionStorage.removeItem("Log_Details");
              const applicationResponse = await dispatch(getApplicationById());
              console.log(applicationResponse, "AppRess")
              if (applicationResponse?.payload?.data?.length >= 1) {
                localStorage.setItem(
                  "wano_agent",
                  JSON.stringify(applicationResponse?.payload?.data[0])
                );
                setTimeout(() => {
                  dispatch(getBusiness());
                  setLoading(false);
                  setSuccess("Business Created Succesfully");
                  navigate("/customize-assistant");
                }, 1000);
              } else {
                dispatch(getBusiness());
                setTimeout(() => {
                  setLoading(false);
                  setSuccess(true);
                  navigate("/customize-assistant");
                }, 1000);
              }
            }
          } else if (token) {
            //added this to handle the case where a user is login and is creating a new business
            dispatch(getBusiness());
            setTimeout(() => {
              setLoading(false);
              setSuccess(true);
              navigate("/customize-assistant");
            }, 1000);
          }
        }
      } catch (error) {
        console.log(error, "pipe error")
        setLoading(false);
        setError(error?.data?.message);
        setTimeout(() => {
          setError();
        }, 1000);
      }
    };

    handleCreate(logDetails);

    // setSuccess("Form submitted");
    // navigate("/what-will-you-use-wano-for");
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  }
  const handleCategoryChange = (e) => {
    console.log("here", e);
  };

  return (
    <main className="">
      <div className="h-screen grid content-between ">
        <NavBar />
        <div className="w-[520px] mx-auto shadow py-[30px] px-[50px] rounded-xl grid gap-6 bg-white">
          <div>
            <BiArrowBack
              onClick={() => navigate(-1)}
              className="cursor-pointer"
            />
            <WelcomeLogoText
              Logo={Vector}
              width={"w-[385px]"}
              title={"What's your Company Like"}
              text={"We just need a few details about your organization."}
            />
          </div>
          <div className="grid gap-5">
            <label className="label">
              Business Name
              <input
                className="input mt-3 h-[38px]"
                name="name"
                value={data.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
              />
            </label>
            <label className="label">
              Business Email
              <input
                type="email"
                className="input mt-3 h-[38px]"
                name="business_email"
                value={data.business_email}
                onChange={(e) =>
                  handleInputChange("business_email", e.target.value)
                }
              />
            </label>
            <label className="label">
              Business Country
              <div
                className={`w-full rounded-lg mt-1.5 h-10 flex gap-2 items-center text-base border px-[10px] cursor-pointer select text-[#575656]
            
            `}
              >
                {countriesLoading ? (
                  <div className="flex items-center justify-center">
                    <img src={spinner} alt="template" className="w-5 h-5" />
                  </div>
                ) : (
                  <p className="text-3xl">{countryFlagIcon}</p>
                )}
                <select
                  name="country"
                  id=""
                  className="w-full outline-none cursor-pointer rounded-lg h-[38px] text-base"
                  onChange={(e) => {
                    const val = e.target.value;
                    const selectedCountry = storeCountries?.countries.find(
                      (country) => country.name === val
                    );
                    setCountryFlagIcon(selectedCountry.flag);
                    setData({ ...data, country: selectedCountry.name });
                    setCountrycode(selectedCountry.phone_code);
                  }}
                  value={data.country}
                >
                  <option value="" disabled></option>
                  {storeCountries?.countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            </label>
            <label className="label flex flex-col">
              <span>
                Business Website{" "}
                <span className="text-[#828282] text-[10px]">Optional</span>
              </span>
              <span className="mt-3 border-solid border border-[#D0D5DD] rounded-[8px] flex p-1 gap-3 h-[38px]">
                <span className="text-[#828282] p-2 bg-[#F2F2F2] rounded-sm">
                  https://
                </span>
                <input
                  className="w-full focus:outline-none border-none"
                  name="website_url"
                  value={data.website_url}
                  onChange={(e) =>
                    handleInputChange("website_url", e.target.value)
                  }
                />
              </span>
            </label>
            <label className="label">
              <CustomSelect
                label="What Category does your business fall under"
                onChange={(e) => handleCategory(e)}
                options={[
                  { label: "Agri Tech", value: "Agri Tech" },
                  { label: "Financial Service", value: "Financial Service" },
                  { label: "SaaS", value: "SaaS" },
                  // { label: "Ecommerce", value: "Ecommerce" },
                  { label: "Education", value: "Education" },
                  { label: "Health Tech", value: "Health Tech" },
                  { label: "Transportation", value: "Transportation" },
                  {
                    label: "Other(Please Specify):",
                    value: "Other(Please Specify):",
                  },
                ]}
              />
            </label>
            {selectedCategory === "Other(Please Specify):" && (
              <label className="label">
                <p className="text-[#828282]">Please specify</p>
                <input
                  className="input mt-3 h-[38px]"
                  type="text"
                  value={optionalCategory}
                  onChange={(event) => handleCategory2(event.target.value)}
                />
              </label>
            )}
            <label className="label">
              <CustomSelect
                label=" Your Role"
                onChange={(e) => handleRole(e)}
                options={[
                  { label: "CEO or Founder", value: "CEO or Founder" },
                  { label: "Software Developer", value: "Software Developer" },
                  { label: "IT Manager", value: "IT Manager" },
                  {
                    label: "Customer Support Manager",
                    value: "Customer Support Manager",
                  },
                  { label: "Project Manager", value: "Project Manager" },
                  { label: "Sales Manager", value: "Sales Manager" },
                  {
                    label: "Other(Please Specify):",
                    value: "Other(Please Specify):",
                  },
                ]}
              />
            </label>
            {selectedRole === "Other(Please Specify):" && (
              <label className="label">
                <p className="text-[#828282]">Please specify</p>
                <input
                  className="input mt-3 h-[38px]"
                  type="text"
                  value={optionalRole}
                  onChange={(event) => handleRole2(event.target.value)}
                />
              </label>
            )}
            <label className="label">
              <CustomSelect
                label=" Team Size"
                onChange={(e) => handleBusinessSize(e)}
                options={[
                  { label: "1 - 10", value: "1 - 10" },
                  { label: "10 - 100", value: "10 - 100" },
                  { label: "100 - 500", value: "100 - 500" },
                  { label: "500+", value: "500+" },
                ]}
              />

              {/* <Popover className=" mt-3 relative pop">
                <Popover.Button>
                  <input
                    type="text"
                    value={selectedBussinessSize}
                    className="h-[38px] cursor-pointer bg-white outline-none pr-16"
                  />
                  <FiChevronDown className=" absolute top-0 right-0 px-2 py-1 text-4xl cursor-pointer text-[#828282]" />
                </Popover.Button>
                {selectedBussinessSize ? '' : (

                <Popover.Panel className=" absolute w-full bg-white z-50 h-[145px] overflow-y-auto shadow-xl flex flex-col rounded-lg">
                  {BussinessSize.map((Size, index) => (
                    <Popover.Button
                      key={index}
                      onClick={() => handleBusinessSize(Size)}
                      className=" py-[10px] px-3 select-none cursor-pointer hover:bg-[rgba(205,222,248,0.20)] flex justify-between"
                    >
                      <p className=" text-xs font-normal text-[#101828]">
                        {Size}
                      </p>
                      {selectedBussinessSize === Size && (
                        <HiCheck className="text-[17px] text-[rgba(23,116,253,1)]" />
                      )}
                    </Popover.Button>
                  ))}
                </Popover.Panel> )}
              </Popover> */}
            </label>
          </div>
          <Button
            disabled={!isFilled}
            text={"Continue"}
            onClick={handleSubmit}
            loading={loading}
            className={
              isFilled &&
              "w-[420px] bg-black rounded-xl px-[12px] line-[24px] py-[13px] text-white text-[14px]"
            }
          />
        </div>
        <div className="mt-10">
          <Terms />
        </div>
      </div>
      {success && <SuccessMessage success={success} className="mt-5 mr-2" />}
      {error && <ErrorMessage error={error} className="mt-5 mr-2" />}
    </main>
  );
}

import { useEffect } from "react"
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import Pricing from "./Pricing";
import Faq from "./Faq";
import ReachUs from "./ReachUs";
import SpeakWithCEO from "./SpeakWithCEO";

export default function SupportContent({ handleCheckboxChange, clickItem }) {

  const hereClicked = sessionStorage.getItem("here")

  useEffect(() => {
    if(hereClicked) {
      handleCheckboxChange("Pricing")
    }
  }, [])

  return (
    <main className=" font-figtree mt-8 mb-32">
      <h2 className=" text-[black] text-3xl font-medium">Need Help?</h2>
      <div className=" flex justify-between mt-2">
        <p className=" text-[#7F7F81] text-[14px] font-normal">
          Explore the options below to find answers to your questions or get in touch with us.
        </p>
        <p className=" text-[#7F7F81] text-[14px] font-normal">
          support@wano.app
        </p>
      </div>
      <section className=" flex flex-col gap-6 mt-6">
        <div
          className={` px-6 py-5  items-center  rounded-xl   ${
            clickItem.Pricing
              ? " bg-white border border-[#E5E5E5] border-solid"
              : "bg-[rgba(252,252,252,0.68)]"
          } cursor-pointer`}
          onClick={() => {handleCheckboxChange("Pricing"); sessionStorage.removeItem("here")}}
        >
          <div className="flex justify-between">
            <p
              className={`text-[16px] font-normal ${
                clickItem.Pricing ? "text-[#121212]" : "text-[#828282]"
              }`}
            >
              Pricing
            </p>
            {clickItem.Pricing ? (
              <BsChevronUp
                className=" text-2xl text-[#828282] cursor-pointer"
                onClick={() => handleCheckboxChange("Pricing")}
              />
            ) : (
              <BsChevronDown
                className=" text-2xl text-[#828282] cursor-pointer"
                onClick={() => handleCheckboxChange("Pricing")}
              />
            )}
          </div>
          {clickItem.Pricing && (
            <Pricing />
          )}
        </div>
        <div
          className={` px-6 py-5  items-center  rounded-xl   ${
            clickItem.Faqs
              ? " bg-white border border-[#E5E5E5] border-solid"
              : "bg-[rgba(252,252,252,0.68)]"
          } cursor-pointer`}
          onClick={() => handleCheckboxChange("Faqs")}
        >
          <div className="flex justify-between" >
            <p
              className={`text-[16px] font-normal ${
                clickItem.Faqs ? "text-[#121212]" : "text-[#828282]"
              }`}
            >
              FAQs
            </p>
            {clickItem.Faqs ? (
              <BsChevronUp
                className=" text-2xl text-[#828282] cursor-pointer"
                onClick={() => handleCheckboxChange("Faqs")}
              />
            ) : (
              <BsChevronDown
                className=" text-2xl text-[#828282] cursor-pointer"
                onClick={() => handleCheckboxChange("Faqs")}
              />
            )}
          </div>
          {clickItem.Faqs && (
            <Faq />
          )}
        </div>
        <div
          className={` px-6 py-5  items-center  rounded-xl   ${
            clickItem.Contact_Us
              ? " bg-white border border-[#E5E5E5] border-solid"
              : "bg-[rgba(252,252,252,0.68)]"
          } cursor-pointer`}
          onClick={() => handleCheckboxChange("Contact_Us")}
        >
          <div className="flex justify-between">
            <p
              className={`text-[16px] font-normal ${
                clickItem.Contact_Us ? "text-[#121212]" : "text-[#828282]"
              }`}
            >
              Contact US
            </p>
            {clickItem.Contact_Us ? (
              <BsChevronUp
                className=" text-2xl text-[#828282] cursor-pointer"
                onClick={() => handleCheckboxChange("Contact_Us")}
              />
            ) : (
              <BsChevronDown
                className=" text-2xl text-[#828282] cursor-pointer"
                onClick={() => handleCheckboxChange("Contact_Us")}
              />
            )}
          </div>
          {clickItem.Contact_Us && (
            <ReachUs />
          )}
        </div>
        <div
          className={` px-6 py-5  items-center  rounded-xl   ${
            clickItem.Speak_with_CEO
              ? " bg-white border border-[#E5E5E5] border-solid"
              : "bg-[rgba(252,252,252,0.68)]"
          } cursor-pointer`}
          onClick={() => handleCheckboxChange("Speak_with_CEO")}
        >
          <div className="flex justify-between">
            <p
              className={`text-[16px] font-normal ${
                clickItem.Speak_with_CEO ? "text-[#121212]" : "text-[#828282]"
              }`}
            >
              Speak With the CEO
            </p>
            {clickItem.Speak_with_CEO ? (
              <BsChevronUp
                className=" text-2xl text-[#828282] cursor-pointer"
                onClick={() => handleCheckboxChange("Speak_with_CEO")}
              />
            ) : (
              <BsChevronDown
                className=" text-2xl text-[#828282] cursor-pointer"
                onClick={() => handleCheckboxChange("Speak_with_CEO")}
              />
            )}
          </div>
          {clickItem.Speak_with_CEO && (
            <SpeakWithCEO />
          )}
        </div>
      </section>
    </main>
  );
}

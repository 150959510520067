import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../services/api";
import { appUrls } from "../../services/urls";

const initialState = {
  loading: false,
  data: [],
  activeBusiness: {},
  config: { 
    // billing: false,
    // bottomBilling: false,
    // customer: false,
    // settings: false,
    // welcomeGuide: false,
  },
  error: "",
};

export const getBusiness = createAsyncThunk(
  "business/getBusiness",
  async (values, { rejectWithValue }) => {
    try {
      const res = await api.get(appUrls?.GET_BUSINESS);
      return res?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);
export const getABusiness = createAsyncThunk(
  "retrieve/getAbusiness",
  async (values, { rejectWithValue }) => {
    try {
      const res = await api.get(`/dashboard/businesses/${values.id}/single`);
      return res?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);
export const switchBusiness = createAsyncThunk(
  "retrieve/switchBusiness",
  async (values, { rejectWithValue }) => {

    try {
      const res = await api.put(`/dashboard/businesses/${values}/switch`);
      return res?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);


const getBusinessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    setBusiness: (state, action) => {
      state.activeBusiness = action.payload;
    },
    setBusinessConfig: (state, action) => {
      state.config = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBusiness.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBusiness.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
      // if (!action.payload.data.length) {
      //   state.activeBusiness = { };
      // } else if (!state.activeBusiness?.id && action.payload.data.length) {
      //   state.activeBusiness = action.payload.data[0];
      // }
      const activeBusiness = action.payload.data.find(business => business.id === state.activeBusiness.id);
      if (activeBusiness) {
        state.activeBusiness = activeBusiness;
        state.config = activeBusiness.config;
      } else if (action.payload.data.length) {
        // this falls back to the first business if no active business is set
        state.activeBusiness = action.payload.data[0];
        state.config = action.payload.data[0].config;
      }
    });
    builder.addCase(getBusiness.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(
      getABusiness.fulfilled,
      (state, action) => void (state.activeBusiness = action.payload)
    );
  },
});
export const { setBusiness, setBusinessConfig } = getBusinessSlice.actions;

export default getBusinessSlice.reducer;

import React from 'react'
import Whatsapp from "../../../../Assets/svg/whatsApp.svg"
import BigPlus from "../../../../Assets/svg/BigPlus.svg"
import dotsVerifyIcon from "../../../../Assets/svg/dotsVerifyIcon.svg"
import phone2 from "../../../../Assets/svg/phone2.svg"
import Stars from "../../../../Assets/svg/Stars.svg"
import Button from '../../../Button'
import { AiOutlineClose } from 'react-icons/ai'

const SetupInstruction = ({ handleClose, setOpenNextModal, setPurpose, setAddWanoNumber }) => {

    const openCustomsNumbers = () => {
        setOpenNextModal(true)
        setPurpose('Custom')
        setAddWanoNumber(false)
        handleClose()
    }
    
  return (
    <div className="w-[498px] h-fit bg-WHITE-_100  mt-[100px] flex flex-col gap-[40px] pb-[40px]  justify-center items-center rounded-lg"> {/* h-[399px] px-[69px] */}
        <div className='w-full flex items-center justify-end px-[28px] py-[14px] border-b border-neutral-150'>
            {/* <p className='text-lg font-medium text-[#101828]'>Setup App</p> */}
            <div className="w-[36px] h-[36px] border rounded-lg mr-3  flex cursor-pointer justify-center items-center p-2" onClick={handleClose}><AiOutlineClose className='text-neutral-500'/></div>
        </div>
        <div className='p-[0px_30px_0px_30px] flex flex-col gap-5'>
            <div className="h-[132px] flex flex-col gap-[20px] justify-center items-center">
                <div className='flex justify-between w-[106.48px] h-[40.32px]'>
                    <img src={Whatsapp} alt='whatsapp_icon' className='w-[40.32px] h-[40.32px]'/>
                    <img src={BigPlus} alt="plus icon" className=''/>
                    <img src={Stars} alt="Star icon" className=''/>
                </div>
                <p className="text-2xl font-medium text-BLACK-_600">Integrate With WhatsApp</p>
                <p className="text-sm font-normal text-BLACK-_300">To integrate your employee with WhatsApp we need just 2 things</p>
            </div>

            <div className="flex flex-col gap-6 justify-center bg-[#FCFCFC] p-[26px_35px]"> {/* w-[341px] h-[40px] */}
                <div className='flex gap-2'>
                    <img src={phone2} alt="phone icon" className='w-[12px] h-[14px]'/>
                    <div className=''>
                        <p className="text-xs font-semibold text-BLACK-_500">
                            A phone number that is not connected to Whatsapp
                        </p>
                        <p className="text-xs font-normal text-[#828282]">
                            We need a phone number that has not been connected to WhatsApp
                            so as to create a Facebook business account with that number
                        </p>
                    </div>
                </div>
                <div className='flex gap-2'>
                    <img src={dotsVerifyIcon} alt="phone icon" className='w-[14px] h-[14px]'/>
                    <div className=''>
                        <p className="text-xs font-semibold text-BLACK-_500">
                            Verification Code
                        </p>
                        <p className="text-xs font-normal text-[#828282]">
                            An OTP code would be sent to your number to verify it.
                        </p>
                    </div>
                </div>
            </div>

            <div className="w-full h-[34px] flex justify-end  gap-3">
                <Button 
                    text="No,Later"
                    type="button" 
                    onClick={handleClose}  
                    className="bg-none cursor-pointer w-[91px] text-[#828282] text-xs font-semibold border border-neutral-200  rounded-lg" 
                />
                <Button
                    onClick={() => {openCustomsNumbers()}}  
                    text="Continue" 
                    className="bg-BLACK-_100 text-sm w-[91px]  cursor-pointer rounded-lg font-semibold text-WHITE-_100"
                    type="button" 
                />
            </div>

        </div>

    </div>
  )
}

export default SetupInstruction
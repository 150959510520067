import DashboardLayout from "../layout/DashboardLayout";
import CustomerContent from "../Customer/Sales/CustomerContent";
import CustomerConversations from "../Customer/Sales/CustomerConversations";
import AuthenticationContent from "../Customer/Authentication/CustomerContent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import ModalPop from "../component/modalPop";
import { TopGuide } from "../component/modals/agentGuide/Guide";
import CustomersSvg from "../Assets/svg/customers.svg";
import {
  getCurrentEmployee,
  updateAgentModals,
} from "@/../features/modals/agentModalsSlice";
import { getCustomers ,getConversations} from "features/customer/customerSlice";
export default function Customers() {
  const dispatch = useDispatch();

  const { customers, customerPagination, error, loading } = useSelector(
    (state) => state.getCustomers
  );


  const [page, setPage] = useState();
  const [openTourModal, setOpenTourModal] = useState(false);
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const newAgent = getAgentApplication?.agent || null;

  const applicationId = newAgent.id;
  const fetchData = () => {
    dispatch(getCustomers({ applicationId, page: 1, per_page: 20 }));
    dispatch(getConversations( {applicationId, page: 1, per_page:20,status: ''}));
  };
  useEffect(() => {
    fetchData();
  }, [applicationId]);

  const location = useLocation();
  const { state } = location || {};
  useEffect(() => {
    if (state) {
      setActiveButton(state);
    }
  }, [state]);

  const { id } = useSelector((state) => state.getApplicationByUserId.agent);
  const config = useSelector((state) => state.agentModals.employeeModals);

  const getCustomersGuide = config?.customers;

  useEffect(() => {
    if (getCustomersGuide) {
      setOpenTourModal(true);
    } else {
      setOpenTourModal(false);
    }
  }, [getCustomersGuide]);

  const handleAgentModal = (name) => {
    const currentModalState = config[name];
    if (currentModalState) {
      const modalsVisibility = { ...config, [name]: false };
      dispatch(updateAgentModals({ modalsVisibility, id: id }))
        .then((res) => {
          dispatch(getCurrentEmployee({ id: id }));
        })
        .catch((error) => {
          console.error("Failed to update modal state:", error);
        });
    }
  };

  const tabs = ["Customers", "Conversations"];
  const [activeButton, setActiveButton] = useState("Customers");
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  return (
    <DashboardLayout title={"Customers"} user={true}>
      {/* <ChangesBanner setPropShowPopUp={setPropShowPopUp} /> */}
      <section className="flex font-figtree relative w-full">
        {/* <div className="py-8 px-4 h-full  shrink-0">
          <ul className="flex flex-col gap-4 w-[154px]">
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={`py-2.5 px-4 cursor-pointer rounded-md text-sm ${
                  activeButton === tab
                    ? "text-PRIMARY bg-[#FAFAFA]"
                    : "text-SECONDARY"
                }`}
                onClick={() => handleButtonClick(tab)}
              >
                <span>
                  {tab === "Features"
                    ? "Instructions"
                    : tab === "Integrations/Deploy"
                    ? "Test/Launch"
                    : tab}
                </span>
              </li>
            ))}
          </ul>
        </div> */}

        <section className="w-full pl-[142px]  border-l border-[#F7F7F7] px-10 py-5">
          {activeButton === "Customers" && (
            // ! "Sales" === sales
            <CustomerContent data={customers} loading={loading} page={customerPagination?.currentPage} />
          )}
          {activeButton === "Conversations" && (
            // ! "Sales" === sales
            <CustomerConversations/>
          )}
          {"Sales" === "Authentication" && (
            // ! "Sales" === Authentication
            <AuthenticationContent
              data={customers}
              loading={loading}
              page={page}
            />
          )}
        </section>
      </section>
      <ModalPop isOpen={openTourModal}>
        <TopGuide
          title="Your Customers"
          subtitle="Find out insights about your customers as they interact with your Digital Assistant."
          link="View Customer List"
          handleClose={() => {
            handleAgentModal("customers");
            setOpenTourModal(false);
            // setTopCustomersGuide();
          }}
          image={CustomersSvg}
        />
      </ModalPop>
    </DashboardLayout>
  );
}

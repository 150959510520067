import React from 'react'
import Head from "../Assets/svg/Shape65.svg";

const BlackStep = () => {
  return (
    <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2 bg-black p-[18px]">
    <div className="flex gap-2 items-center">
      <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
      <p className="text-[#7F7F81]">Takeover Conversation</p>
    </div>
    <p className='text-white w-[301px]'>
      Takeover from your assistant. Click “Takeover Conversation” to takeover the chat.
    </p>

    <p className="text-[#7F7F81]">Step 4 of 4 </p>
  </div>
  )
}

export default BlackStep
import { AddBtnService } from "../Assets/svg";
import { useState, useEffect } from "react";
import ServiceBlocks from "./ServiceLayout/ServiceBlocks";
import ModalPop from "../component/modalPop";
import Select from "./Select";

import InstructionTableTemplate from "../component/InstructionTemplateTable";
import { BiChevronRight } from "react-icons/bi";
import TestAgent from "./TestAgent/TestAgent";
import MakeRequest from "./ServiceLayout/Modal/MakeRequest";
import { UserContext } from "../context/UserContext";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import PublishChange from "./ServiceLayout/Modal/PublishChange";
import { BsDot } from "react-icons/bs";
import axios from "axios";
import TokenService from "../services/token";
import { SmallCard } from "../LoadingSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { SuccessMessage, ErrorMessage } from "../Toast-Messages/Toastmessages";
import shareIcon from "Assets/svg/sharee.svg";
import banner from "Assets/svg/banner.svg";
import backArrow from "Assets/svg/backArrow.svg";
import Button from "component/Button";
import Welcome from "../component/modals/welcome/mustCreate";
import { BottomGuide } from "../component/modals/agentGuide/Guide";

import AgentB from "../Assets/svg/agent4.svg";
import ChooseInstruction from "./ServiceLayout/Modal/ChooseInstruction";
import {
  getCurrentEmployee,
  updateAgentModals,
} from "@/../features/modals/agentModalsSlice";

export default function Service({
  setShowAgentNotification,
  activeButton,
  setActiveButton,
}) {
  const dispatch = useDispatch();
  // const [welcomeModal, setOpenWelcomeModal] = useState(false);
  const { userType } = useContext(UserContext);
  const [openServicesModal, setOpenServicesModal] = useState(false);
  const [openMakeReqModal, setOpenMakeReqModal] = useState(false);
  const [publishChange, setPublishChange] = useState(false);
  const URL = process.env.REACT_APP_BASEURL;
  const [getServiceData, setGetServiceData] = useState(null);
  const [choosenServiceData, setChoosenServiceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = TokenService.getToken();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const data = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = data && data.length >= 1 ? data[0] : {};
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const agentDetails = getAgentApplication?.agent || null;
  const applicationId = agentDetails.id;
  const purpose = agentDetails.type;
  const [buttonLoader, setButtonLoader] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [openBottomGuide, setOpenBottomGuide] = useState(false);
  const [openSecondBottomGuide, setOpenSecondBottomGuide] = useState(false);
  // const [done, setDone] = useState(false);

  const fetchData = async () => {
    if (!applicationId) {
      // setOpenWelcomeModal(true);

      console.error(
        "No applicationId provided. fetchData function will not run."
      );
      return;
    }
    setLoading(true);
    try {
      const cachedServiceData = JSON.parse(
        sessionStorage.getItem("serviceData")
      );
      const cachedChoosenServiceData = JSON.parse(
        sessionStorage.getItem("choosenServiceData")
      );

      if (cachedServiceData && cachedChoosenServiceData) {
        setGetServiceData(cachedServiceData);
        setChoosenServiceData(cachedChoosenServiceData);
        setLoading(false);
        // return;
      }

      const ChoosenDataresponse = await axios.get(
        `${URL}/dashboard/applications/${applicationId}/category`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const getServiceresponse = await axios.get(
        `${URL}/dashboard/applications/${applicationId}/category`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setGetServiceData(getServiceresponse.data.data);
      setChoosenServiceData(ChoosenDataresponse.data.data);

      //! Cache the fetched data
      sessionStorage.setItem(
        "serviceData",
        JSON.stringify(getServiceresponse.data.data)
      );
      sessionStorage.setItem(
        "choosenServiceData",
        JSON.stringify(ChoosenDataresponse.data.data)
      );

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const handleBeforeUnload = () => {
    sessionStorage.removeItem("serviceData");
    sessionStorage.removeItem("choosenServiceData");
  };

  useEffect(() => {
    fetchData();

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      //! Remove the event listener when the component is unmounted
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [appDetail]);

  const handleFetchUpdate = () => {
    handleBeforeUnload();
    fetchData();
  };

  const location = useLocation();

  // const setBottomFeaturesGuide = () =>
  // location.pathname === "/agent"
  //   ? localStorage.setItem("features", true)
  //   : null;
  // const getFeaturesGuide = localStorage.getItem("features");

  // const setBottomSetupGuide = () =>
  // location.pathname === "/agent"
  //   ? sessionStorage.setItem("setup", true)
  //   : null;
  // const getSetupGuide = sessionStorage.getItem("setup");

  const done = sessionStorage.getItem("done");

  let setupData;

  // useEffect(() => {
  // if (!getFeaturesGuide && !getSetupGuide) {
  //   setOpenBottomGuide(true);
  // } else if (getFeaturesGuide && done && !getSetupGuide) {
  //   setOpenBottomGuide(false);
  //   setOpenSecondBottomGuide(true);
  // } else if (getFeaturesGuide && getSetupGuide) {
  //   setOpenBottomGuide(false);
  //   setOpenSecondBottomGuide(false);
  // }
  // }, [getSetupGuide, getFeaturesGuide]);

  const { id } = useSelector((state) => state.getApplicationByUserId.agent);
  const config = useSelector((state) => state.agentModals.employeeModals);

  const getFeaturesGuide = config?.features;

  useEffect(() => {
    if (getFeaturesGuide) {
      setOpenBottomGuide(true);
    } else {
      setOpenBottomGuide(false);
    }
  }, [getFeaturesGuide]);

  const handleAgentModal = (name) => {
    const currentModalState = config[name];
    if (currentModalState) {
      const modalsVisibility = { ...config, [name]: false };
      dispatch(updateAgentModals({ modalsVisibility, id: id }))
        .then((res) => {
          dispatch(getCurrentEmployee({ id: id }));
        })
        .catch((error) => {
          console.error("Failed to update modal state:", error);
        });
    }
  };
  const [viewTemplate, setViewTemplate] = useState(false);
  const sampleData = [
    {
      name: "Opening a conversation",
      category: "Behaviour",
      support_channel: ["email", "phone_number"],
      description:
        "Create instructions for your assistant on how to behave during a conversation.",
      instruction: `Begin every conversation with this: [Insert preferred text here.  E.g “Good Morning, thank you for contacting Wano. How can I assist you today?”]`,
    },
    {
      name: "Closing a conversation",
      category: "Behaviour",
      support_channel: ["chat"],
      description:
        "This instruction is used before concluding any interaction.",
      instruction: `Before ending a conversation, reply to customers with this:

Samples:

[Insert closing text here E.g: “Thank you for contacting us,if you won’t be needing anything else, I’ll be closing this conversation ”]

Then say this:
[Insert new promotion or text here E.g: “Your security is our top priority. We use advanced encryption and two-factor authentication to protect your account. Is there anything else I can assist you with today? ]  Then this:
[Insert preferred text here  Inform customers about new features ‘We’ve recently launched a new budgeting tool in our app that can help you manage your expenses more effectively. Would you like to learn more?]`,
    },
    {
      name: "Hand off",
      description:
        "This instruction is used when the solution or answer to the problem is not found",
      category: "Behaviour",
      support_channel: ["email", "calendar"],
      instruction: `Reply with this  :

[insert preferred text here E.g “I’m going to escalate this issue to our specialist team to ensure it’s resolved promptly. They will contact you within [insert time e.g 2 hours].”`,
    },
    {
      name: "complaints",
      category: "Complaints",
      support_channel: ["N/A"],
      description:
        "This instruction is used when there is any question or complaint that cannot be answered.",
      instruction: `Ask questions to gather more details.
Reply customer with this:

[insert preferred text here E.g “Could you please provide more details about [the problem] so I can better understand the problem”?]

`,
    },
    {
      name: "Providing assistance",
      category: "Enquiries",
      support_channel: ["N/A"],
      instruction: `Reply with this if a customer asks how to [transfer funds]:

[insert preferred text here. E.g "To transfer funds, please go to the 'Transfers' section on our app, enter the recipient's details, and the amount you wish to send]."
 `,
      description:
        "Use this instruction to guide the customers through [insert instance E.g completing transfers].",
    },
    {
      name: "Seasonal greetings (a scheduled instruction)",
      category: "Scheduled Instructions",
      support_channel: ["phone_number", "chat"],
      instruction: `Start every conversation with this:

[insert preferred text here E.g “Hello, Merry christmas. Remember to save for the rainy days while you pay for tables at the club”]

`,
      description:
        "Use this instruction during [insert preferred period here E.g christmas].",
    },
  ];
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const handleEdit = async () => {
    try {
      const createdTemplate = choosenServiceData.find(
        (each) =>
          each.name.toLowerCase() === selectedTemplate.category.toLowerCase()
      );
    
      if (createdTemplate) {
        navigate(
          `/agent/${selectedTemplate.category}/${
            createdTemplate.id
          }/add-instruction?template=${JSON.stringify(selectedTemplate)}`
        );
      } else {
        setIsLoading(true);
        const token = localStorage.getItem("token");
        console.log("selected templates", selectedTemplate);
        const { data } = await axios.post(
          `${URL}/dashboard/applications/${applicationId}/category`,
          {
            name: selectedTemplate.category,
            description: selectedTemplate.description,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        navigate(
          `/agent/${selectedTemplate.category}/${
            data.data.id
          }/add-instruction?template=${JSON.stringify(selectedTemplate)}`
        );
      }
    } catch (e) {
      console.error(e.response.data.message);
      setError(e.response.data.message);
      setTimeout(() => {
        setError(false);
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };
  const [support, setSupport] = useState([]);
  const [next, setNext] = useState(false);

  return (
    <>
      <main>
        {viewTemplate === "table" ? (
          <div>
            <header className="flex gap-1 items-center mb-4 ">
              <div
                className="flex gap-1 cursor-pointer"
                onClick={() => setViewTemplate(false)}
              >
                <img src={backArrow} alt="Images" />

                <p className="text-[#121212] text-sm font-normal ">Templates</p>
              </div>
            </header>
            <h2 className=" text-2xl font-medium">Templates</h2>
            <InstructionTableTemplate
              data={sampleData}
              setViewTemplate={setViewTemplate}
              setSelectedTemplate={setSelectedTemplate}
            />
          </div>
        ) : viewTemplate === "form" ? (
          <div>
            <header className="flex gap-1 items-center mb-4 ">
              <div
                className="flex gap-1 cursor-pointer"
                onClick={() => setViewTemplate("table")}
              >
                <img src={backArrow} alt="Images" />

                <p className="text-[#121212] text-sm font-normal ">
                  View Templates
                </p>
              </div>
            </header>
            <div className="flex justify-between">
              <div className="flex flex-col gap-1">
                <h2 className=" text-2xl font-medium">Opening Conversations</h2>
                <p className="text-[#7F7F81] text-xs font-normal">
                  Schedule detailed instructions or guidelines for your
                  assistants within a given time period.
                </p>
              </div>
              <div>
                <Button
                  onClick={() => {
                    handleEdit();
                  }}
                  text=" Edit Instruction"
                  className="bg-BLACK-_100 text-xs py-3 px-5 cursor-pointer rounded-lg font-semibold text-WHITE-_100"
                  type="button"
                  loading={isLoading}
                />
              </div>
            </div>
            <form className={`mt-11 flex flex-col gap-6 w-8/12`}>
              <div className="flex flex-col gap-3">
                <label className="text-xs font-figtree text-[#101828] flex items-center">
                  Name
                  <span className="text-red-700 pl-1 pt-1.5">*</span>
                </label>
                <input
                  type="text"
                  value={selectedTemplate.name}
                  disabled
                  className={`instructionstep-2 text-[#101828] border-[#D0D5DD] bg-white focus:outline-none w-full`}
                />
              </div>

              <div className="flex flex-col gap-3">
                <label className="text-xs font-figtree text-[#101828] flex items-center">
                  Description
                </label>
                <textarea
                  type="text"
                  value={selectedTemplate.description}
                  disabled
                  className={` text-[#101828] border rounded-lg border-[#D0D5DD] py-4 px-3 bg-white focus:outline-none w-full`}
                />
              </div>
              <div className="flex flex-col gap-3">
                <label className="text-xs font-figtree text-[#101828] flex items-center">
                  {/* How It Works */}
                  Enter Instructions
                  <span className="text-[#7F7F81] ml-[5px] ">
                    ( Provide a Step by Step of your Instruction)
                  </span>
                </label>
                <textarea
                  type="text"
                  rows="10"
                  value={selectedTemplate.instruction}
                  disabled
                  className={` text-[#101828] border rounded-lg border-[#D0D5DD] py-4 px-3 bg-white focus:outline-none w-full`}
                />
              </div>
              <div className="flex flex-col gap-3">
                <label className="text-xs font-figtree text-[#101828] instructionstep-5">
                  Support Channel{" "}
                  <span className="text-[#7F7F81]">
                    (Select where you’d like your assistant to redirect your
                    customers to. Maximum of 3 channels)
                  </span>
                </label>
                <div className="instructionstep-4">
                  {" "}
                  <Select
                    setNext={setNext}
                    next={next}
                    support={support}
                    setSupport={setSupport}
                  />
                </div>
              </div>
            </form>
          </div>
        ) : (
          <section className="mt-10 font-figtree mb-16 ">
            <header
              className="pl-[24px] relative shadow-box pr-[51px] w-full py-[47px] rounded-xl mb-8 flex justify-between items-center"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom right, #FDF6F9 10%, #F6F6FC 50%, #FAFCFF 100%)",
              }}
            >
              <div className=" flex flex-col ">
                <p className=" font-bold text-[32px] text-bamboo ">
                  Need Help? Use templates!
                </p>
                <p className=" text-sm font-normal text-BLACK-_300 leading-6">
                  Don’t know how to get started with instructions? Use our
                  already made templates as your guide.
                </p>
                <div>
                  <button
                    className="text-WHITE-_100 cursor-pointer rounded-lg bg-BLACK-_500 flex gap-2 w-fit mt-6 text-[10px] font-semibold py-2 px-2.5"
                    onClick={() => {
                      setViewTemplate("table");
                    }}
                  >
                    View Templates
                    <img src={shareIcon} alt="requests" className="w-4 h-4" />
                  </button>
                </div>
              </div>
              <img
                src={banner}
                alt="requests"
                className="absolute right-0 bottom-0 w-[65%]"
              />
            </header>
            <h2 className=" text-2xl font-medium ">Instruction</h2>
            {purpose === "sale" && (
              <p className="mt-[8px] text-xs font-normal text-[#7F7F81]">
                Add and configure detailed instructions or guidelines on how
                your Assistant should close a sale with Customers.
              </p>
            )}
            {purpose === "customer" && (
              <p className="mt-[8px] text-xs font-normal text-[#7F7F81]">
                Add and configure detailed instruction or guidelines on how your
                Assistant should resolve Customer issues effectively.
              </p>
            )}
            {purpose === "general" && (
              <p className="mt-[8px] text-xs font-normal text-[#7F7F81]">
                Add and configure detailed instruction or guidelines on how your
                General AI should resolve Customer issues and/or complete sales
                effectively.
              </p>
            )}

            <div className="grid grid-cols-3 gap-6 mt-6">
              {/* {purpose === "sale" && ( */}
              {purpose && (
                <>
                  {loading ? (
                    <SmallCard />
                  ) : (
                    choosenServiceData &&
                    choosenServiceData.map((data) => {
                      setupData = data;
                      return (
                        <ServiceBlocks
                          key={data.id}
                          data={data}
                          handleFetchUpdate={handleFetchUpdate}
                        />
                      );
                    })
                  )}
                </>
              )}

              <div
                className="cursor-pointer  h-[158px] bg-white border border-[#E5E5E5] p-4 rounded-xl"
                onClick={() => setOpenServicesModal(true)}
              >
                <div className=" mb-5">
                  <AddBtnService />
                </div>
                <p className=" text-[#121212] text-base font-medium">
                  Add Instruction Category
                </p>
                <p className=" text-xs font-normal text-[#7F7F81] mt-2">
                  Create a new category for your instructions.
                </p>
              </div>
            </div>

            <TestAgent
              activeButton={activeButton}
              setActiveButton={setActiveButton}
            />
            {/* <ModalPop isOpen={openServicesModal}>
            <ChooseServices
              handleClose={() => setOpenServicesModal(false)}
              getServiceData={getServiceData}
              handleFetchUpdate={handleFetchUpdate}
              choosenServiceData={choosenServiceData}
              // setDone={setDone}
            />
          </ModalPop> */}

            <ModalPop isOpen={openServicesModal}>
              <ChooseInstruction
                handleClose={() => setOpenServicesModal(false)}
                getServiceData={getServiceData}
                handleFetchUpdate={handleFetchUpdate}
                choosenServiceData={choosenServiceData}
                applicationId={applicationId}
              />
            </ModalPop>
            <ModalPop isOpen={openMakeReqModal}>
              <MakeRequest handleClose={() => setOpenMakeReqModal(false)} />
            </ModalPop>
            <ModalPop isOpen={publishChange}>
              <PublishChange
                handleClose={() => setPublishChange(false)}
                setShowAgentNotification={setShowAgentNotification}
              />
            </ModalPop>
            <div>
              <button
                disabled
                className="bg-[#121212] hidden text-white text-sm font-semibold py-[11px] w-[128px] rounded-lg absolute top-[25%] right-0"
              >
                {/* {buttonLoader ? (
                <span className="flex justify-center w-full">
                  <CgSpinner className=" animate-spin text-lg " />
                </span>
              ) : ( */}
                <span>Create Feature</span>
              </button>
              {publishChange && (
                <BsDot className=" text-blue-700 absolute text-4xl -top-2 -right-1" />
              )}
            </div>
          </section>
        )}

        {/* <ModalPop isOpen={welcomeModal}>
          <Welcome handleClose={() => setOpenWelcomeModal(false)} />
        </ModalPop> */}

        {/* <ModalPop isOpen={openBottomGuide}>
          <BottomGuide
            title="Instructions"
            subtitle="Create custom instructions for assistant. From how to close a sale with your customer to resolving complaints and attending to enquiries."
            link="Add Instruction"
            handleClose={() => {
              handleAgentModal("features")
              setOpenBottomGuide(false);
              // setBottomFeaturesGuide();
            }}
            image={AgentB}
            // top={() => setOpenServicesModal(true)}
          />
        </ModalPop> */}
        {/* <ModalPop isOpen={openSecondBottomGuide}>
          <BottomGuide
              title="Setup Features"
              subtitle="Add, Manage and configure the features of your A.I agent based on your business needs. Click ’Choose a Feature’ to add a Feature for your Agent."
              link="Setup Features" 
              handleClose={() => {setOpenSecondBottomGuide(false); setBottomSetupGuide() } }
              image={AgentB}
              // connect={`/agent/${setupData.module.name}/${setupData.id}`}
          />
        </ModalPop> */}
      </main>
      {success && <SuccessMessage success={success} />}
      {error && <ErrorMessage error={error} />}
    </>
  );
}

// {purpose === "Authentication" && (
//   <section className="flex flex-wrap gap-6 mt-6">
//     <AuthenticationBlock />
//     <div className="w-[264px]  bg-gradient-to-bl from-[#CA62EF] via-[#4B92FB] to-[#F6C0D1] bg-origin-border bg-clip-content  rounded-xl ">
//       <div className=" pt-8 px-4">
//         <p className=" font-semibold text-base text-white">
//           Get the most of your agents with your customers
//         </p>
//       </div>
//       <div className=" flex justify-end mt-6  px-4 pb-9">
//         <button
//           onClick={() => navigate("/what-will-you-use-wano-for")}
//           className=" bg-white rounded-lg shadow border-2 outline-none py-2 px-3 flex gap-[10px]"
//         >
//           <span className="relative">
//             <TestAgentIcon />
//             <p className="text-blue-600 text-[4.13px] font-bold absolute right-0 bottom-0">
//               Ai
//             </p>
//           </span>
//           <p className="text-[#1774FD] text-xs font-medium">
//             Expand Agent Capabilities
//           </p>
//         </button>
//       </div>
//     </div>
//   </section>
// )}

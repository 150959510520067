import React from "react";
import { useNavigate } from "react-router-dom";
import Success from "../../../Assets/svg/partycap.svg";
import { AiOutlineClose } from "react-icons/ai";

const AssistSuccess = ({ handleClose}) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    handleClose()
  };

  return (
    <section className="flex justify-center items-center">
      <div className="bg-WHITE-_100 w-[496px] h-[459.59px] rounded-lg">
        <div className="flex justify-end ">
          <button
            className="w-[36px] h-[36px] border rounded-lg flex justify-center items-center p-2 m-[11.33px]"
            onClick={handleClose}
          >
            <AiOutlineClose className="text-BLACK-_300" />
          </button>
        </div>
        <hr />
        <div className="px-[81px] py-[53px] flex flex-col gap-[32px]">
          <div className="flex justify-center">
            <img
              src={Success}
              alt="assist success"
              className="w-[129.5px] h-[131.8px] rounded-lg"
            />
          </div>
          <div className="flex flex-col gap-[15px]">
            <h1 className="font-medium text-[20px] leading-[21.5px] text-[#101828] text-center">
            Request Successful
            </h1>
            <p className="text-[14px] leading-[21px] text-[#828282] w-[334px] text-center">
              <span>You have successfully requested for setup assistance</span>
            </p>
          </div>
          <button
            className="bg-[#1774FD] w-[289px] text-center flex justify-center items-center mx-auto rounded-lg h-[36px] p-2"
            type="submit"
            onClick={handleRedirect}
          >
            <p className="text-center font-semibold text-sm text-WHITE-_100">
              Done
            </p>
          </button>
        </div>
      </div>
    </section>
  );
};

export default AssistSuccess;

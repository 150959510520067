import { useSelector } from "react-redux";
import TokenService from "../../services/token";
import axios from "axios";
import { useState } from "react";
import { CgSpinner } from "react-icons/cg";
import {
  SuccessMessage,
  ErrorMessage,
} from "../../Toast-Messages/Toastmessages";
import { useNavigate } from "react-router";

export default function DeactivateAppModal({ handleClose }) {
  const URL = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  const token = TokenService.getToken();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const data = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = data && data.length >= 1 ? data[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  const handleDeactivate = async () => {
    setIsLoading(true);

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.put(
        `${URL}/dashboard/applications/${applicationId}/deactivate`,
        null,
        {
          headers,
        }
      );

      setSuccess("Assistant successfully deactivated!");
      setIsLoading(false);
      handleClose();

      // setTimeout(() => {
      //   setSuccess(false);
      //   handleClose();
      //   navigate('/')
      //   localStorage.clear()
      // }, 2500);
    } catch (error) {
      setError("Error deactivating assistant");
      // setIsLoading(false);
      // setTimeout(() => {
      //   setError(false);
      // }, 2500);
    } finally {
      setIsLoading(false);
    }
  };
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const fullAgent = getAgentApplication?.agent || null;
  console.log(fullAgent, "full agent")


  return (
    <div className=" w-[496px] h-fit bg-white mt-32 ml-6 rounded-lg shadow-makeReqModal font-figtree py-[30px] px-[50px]">
      <h2 className=" text-2xl font-medium text-[#101828] mb-12 text-center">
       
        {fullAgent.deactivated ? 'Activate':' Deactivate '} Assistant
      </h2>
      <p className=" text-center text-sm font-medium text-[#828282]">
        Are you sure you want to {fullAgent.deactivated ? 'activate':' deactivate '} this Assistant? Doing this {fullAgent.deactivated ? 'continues':'stops'} any
        interaction between the Assistant and your Customers.
      </p>
      <div className=" flex gap-[24px] mt-12">
        <button
          onClick={handleClose}
          className=" border border-[#D0D5DD] w-full py-2 rounded-lg text-sm font-semibold text-[#7F7f81]"
        >
          Cancel
        </button>
        <button
          onClick={handleDeactivate}
          disabled={isLoading}
          className="w-full py-2 rounded-lg bg-[#121212] text-sm text-white font-bold"
        >
          {isLoading ? (
            <span className="flex justify-center w-full">
              <CgSpinner className=" animate-spin text-lg text-WHITE-_100" />
            </span>
          ) : (
            <p className="text-WHITE-_100 text-[12px] font-medium">
              {fullAgent.deactivated ? 'Activate':' Deactivate '}
            </p>
          )}
        </button>
      </div>
      {success && <SuccessMessage success={success} />}
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

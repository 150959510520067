import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from "react-icons/ai"
import { FaPlus } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux';

import { getPluginsChannel } from '../../../features/plugins/getPluginChannelSlice';
import Peer from '../kyc/whatsapp/Peer';
import ModalPop from '../../modalPop';
import Phone from '../kyc/whatsapp/Phone';
import Otp from '../kyc/whatsapp/Otp';
import { ErrorMessage, SuccessMessage } from '../../../Toast-Messages/Toastmessages';
import { postPluginById } from '../../../features/plugins/postPluginByIdSlice';
import { CgSpinner } from 'react-icons/cg';
import { useLocation, useNavigate } from "react-router";
import Success from '../kyc/whatsapp/Success';

const IntegrateCustomBusinessB = ({ handleClose, purpose }) => {
    const navigate = useNavigate();
    const location = useLocation()

    const [openModal, setOpenModal] = useState(false)
    const [loading, setLoading] = useState({})
    const [openServiceModal, setOpenServiceModal] = useState(false)
    const [openPhoneModal, setOpenPhoneModal] = useState(false);
    const [openOtpModal, setOpenOtpModal] = useState(false)
    const [messaging, setMessaging] = useState(false)
    const [pluginsNotificationSuccess, setPluginsNotificationSuccess] = useState(false);
    const [pluginsNotificationError, setPluginsNotificationError] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false)

    const appDetail = useSelector((state) => state.getApplicationByUserId);
    const getdata = appDetail?.data?.data;
    const agentData = localStorage.getItem("wano_agent");
    const agent = getdata && getdata.length >= 1 ? getdata[0] : {};
    const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
    const applicationId = agentDetails.id;

    const dispatch = useDispatch();

    const peerWhatsApp = () => {
        if(openPhoneModal) {
            setOpenModal(false)
        } else if(openOtpModal){
            setOpenPhoneModal(false);
            // setOpenModal(false);
        } else if(openSuccessModal) {
            setOpenOtpModal(false)
        }  else {
            return null
        }
    }

    const openPeerModal = () => {
        // setMessaging(true)
        // setOpenModal(true)
        setOpenPhoneModal(true) //new addition
      
        // navigate("/agent", { state: "Integrations/Deploy" })
        
    }

    let fromPeer = false;
    
    useEffect(() => {
        peerWhatsApp()
    }, [openOtpModal, openPhoneModal, openSuccessModal])

    useEffect(() => {
        dispatch(getPluginsChannel({ applicationId }))
    }, []) //loading


    //pluginId
    const getAllPluginsByIdData = useSelector(state => state.getAllPluginsById);
    // console.log(getAllPluginsByIdData, "austria")

    const pluginId = getAllPluginsByIdData?.data?.data?.id

    //channelId
    const channels = useSelector((state) => state.getPluginChannel)
    // console.log(channels, "overseas")

    const channelsData = channels?.data?.data;

    const postPlugin = useSelector(state => state.postPluginById)
    // console.log(postPlugin, "postPlugin")

    const integrateNumber = (value) => {
        console.log(value, "dodo")
        setLoading((prev) => ({...prev, [value]: true }));
        const url = localStorage.getItem("notificationUrl")
        const data = {
            pluginId: pluginId,
            channelId: value,
            notification_url: url
        }
        dispatch(postPluginById({ applicationId, data}))
        .then((res) => {
            console.log(res, "fire")
            if(res?.payload) {
                setLoading((prev) => ({...prev, [value]: false }))
                setPluginsNotificationSuccess(true)
                setTimeout(() => {
                    handleClose()
                }, 2000);
            }  
        }) 
    }

    const removePluginsNotificationSuccess = () => {
        setTimeout(() => {
            setPluginsNotificationSuccess(false)
        }, 1000);
    }

    const removePluginsNotificationError = () => {
        setTimeout(() => {
            setPluginsNotificationError(false)
        }, 1000);
    }

    const error = "false"

    useEffect(() => {
        removePluginsNotificationSuccess()
    }, [pluginsNotificationSuccess])

    useEffect(() => {
        removePluginsNotificationError()
    }, [pluginsNotificationError])
    
    const message = "Application plugin created successfully"

    //     useEffect(() => {
    //     if(messaging){ 
            
    //         handleClose()
    //     }
    // }, [messaging])

    const uniquePhoneNumbers = new Set();

  return (
    <div className="bg-WHITE-_100 w-[484px] h-[376px]  mt-[60px] rounded-lg"> {/* h-[436px] */}
        <div className="flex justify-between h-[65px] items-center mx-4 my-1 ">
            <div className=" flex ml-6">
                <p className="font-medium text-lg text-BLACK-_600">Setup With Custom Business Number</p>
            </div>
            <button className="w-[30px] h-[30px] border border-[#F7F7F7] rounded-lg flex justify-center items-center p-2" onClick={handleClose}>
                <AiOutlineClose fill='#828282'/>
            </button>
        </div>
        <hr />
        <div className="w-full px-[31px] py-[35px] gap-[43px] flex flex-col gap-5 justify-center items-center">
            <div className="w-[174px]  mt-0 flex justify-center items-center gap-1"> 
                <p className="w-[27px] h-[27px] text-[10px] bg-BLUE-_300 rounded-full text-white flex flex-col items-center justify-center">1</p>
                <div className="w-[24px] h-[2px] border bg-WHITE-_200"></div>
                <p className="w-[27px] h-[27px] text-[10px] bg-BLUE-_300 rounded-full text-white flex flex-col items-center justify-center">2</p>
                <div className="w-[24px] h-[2px] border bg-WHITE-_200"></div>
                <p className="text-[10px] w-[52px] h-[23px] bg-BLUE-_300 rounded text-white flex flex-col items-center justify-center">Integrate</p>
            </div> 

            <div>
                <p className='text-sm font-medium text-[#101828]'>Integrate with Existing Number</p>
                <p className='text-xs text-[#828282]'>Integrate this plugin to an existing number or add a new number. Note that it typically takes 24 hours to approve a new number</p>
            </div>
            <div className=' w-[420px] h-[231px] gap-[19px] flex flex-col'>
                {
                    channelsData ? channelsData?.map((item, index) => {
                        const isUniquePhoneNumber = !uniquePhoneNumbers.has(item?.phone_number);
                        if (isUniquePhoneNumber) {
                            uniquePhoneNumbers.add(item?.phone_number);
                            return (
                                <div className='flex items-center justify-between' key={index}>
                                    {(purpose === 'Wano' && item?.is_general) && 
                                        <p className='text-sm font-medium text-[#101828]'>{item?.phone_number}</p>
                                    }
                                    {(purpose === 'Wano' && item?.is_general) && 
                                        <button 
                                            className='w-[81px] text-[#0359D8] px-[8px] py-[8px] bg-[#FAFAFA] flex justify-center items-center border-solid border-[1px] border-[#EEE] rounded-lg font-medium text-sm'
                                            onClick={() =>  integrateNumber(item?.id)}
                                        >
                                            {loading[item?.id] ? <CgSpinner className=" text-[#0359D8] animate-spin text-lg " /> : 'Integrate'}
                                        </button>
                                    }
                                    {(purpose === 'Custom' && !item?.is_general) && 
                                        <p className='text-sm font-medium text-[#101828]'>{item?.phone_number}</p>
                                    }
                                    {(purpose === 'Custom' && !item?.is_general) && 
                                        <button 
                                            className='w-[81px] text-[#0359D8] px-[8px] py-[8px] bg-[#FAFAFA] flex justify-center items-center border-solid border-[1px] border-[#EEE] rounded-lg font-medium text-sm'
                                            onClick={() =>  integrateNumber(item?.id)}
                                        >
                                            {loading[item?.id] ? <CgSpinner className=" text-[#0359D8] animate-spin text-lg " /> : 'Integrate'}
                                        </button>
                                    }
                                </div>

                            )
                        } else {
                            return null;
                        }
                    }) :
                    <p className='flex items-center justify-between'>No Phone Number Available</p>
                }

                {/* <div className='flex flex-col gap-[12px]'>
                    <p className='text-sm font-medium text-[#101828]'>Integrate with Existing Number</p>
                    <p className='text-xs text-[#828282]'>Integrate this plugin to an existing number or add a new number. Note that it typically takes 24 hours to approve a new number</p>
                </div>
                <div className='flex items-center justify-between'>
                    <p className='text-sm font-medium text-[#101828]'>+234 80234567890</p>
                    <button className='text-[#0359D8] px-[8px] py-[8px] bg-[#FAFAFA] border-solid border-[1px] border-[#EEE]  rounded-lg font-medium text-sm'>Integrate</button>
                </div>
                <div className='flex items-center justify-between'>
                    <p className='text-sm font-medium text-[#101828]'>+234 80234567890</p>
                    <button className='text-[#0359D8] px-[8px] py-[8px] bg-[#FAFAFA] border-solid border-[1px] border-[#EEE]  rounded-lg font-medium text-sm'>Integrate</button>
                </div> */}

                <button 
                    className="bg-[#FAFAFA] h-[40px] p-3 cursor-pointer flex justify-center items-center gap-1 rounded-lg" 
                    type="submit"
                    onClick={() =>{openPeerModal()} }
                >
                    <FaPlus className='w-[13px] h-[13px] text-[#0359D8] font-normal'/>
                    <p className='font-medium text-sm text-[#0359D8]'>Add New Number</p>
                </button>

            </div>
        </div>
        <ModalPop isOpen={openModal}>
            <Peer 
                handleClose={() => {setOpenModal(false); handleClose()}} 
                openPhoneModal={() => setOpenPhoneModal(true)} 
                messaging={messaging}
            />
        </ModalPop>
        <ModalPop isOpen={openPhoneModal}>
            <Phone 
                handleClose={() => {setOpenPhoneModal(false); handleClose()}} 
                openOtpViewModal={() => setOpenOtpModal(true)}
                setOpenPhoneModal={setOpenPhoneModal} 
                messaging={messaging}
            />
        </ModalPop>

        <ModalPop isOpen={openOtpModal}>
            <Otp 
                handleClose={() => {setOpenOtpModal(false); handleClose()}}
                setOpenOtpModal={setOpenOtpModal} 
                openSuccessViewModal={() => setOpenSuccessModal(true)} 
            />
        </ModalPop>

        <ModalPop isOpen={openSuccessModal}>{/* openSuccessModal */}
            <Success handleClose={() => {setOpenSuccessModal(false); handleClose()}} fromPeer={fromPeer}/>
        </ModalPop>

        {pluginsNotificationSuccess && <SuccessMessage Formodal={true} success={message} className="mt-28 mr-12"/>}
        {pluginsNotificationError && <ErrorMessage Formodal={true} error={error} className="mt-28 mr-12"/>}

    </div>
  )
}

export default IntegrateCustomBusinessB
import React, { useState, useEffect } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import InstructionTable from "../Agent/tables/InstructionTable";
import TokenService from "../services/token";
import { useDispatch } from "react-redux";
import ChangesBanner from "component/ChangesBanner";

import axios from "axios";
import { SuccessMessage } from "../Toast-Messages/Toastmessages";
import TestAgent from "@/../Agent/TestAgent/TestAgent";
import { getApplicationDraft } from "features/application/getApplicationByIdSlice";
export default function ViewFeature() {
  const { id, type } = useParams();
  const token = TokenService.getToken();
  const agentData = localStorage.getItem("wano_agent");
  const agentDetails = JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const URL = process.env.REACT_APP_BASEURL;
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  let agentFeatures = "features";
  const [publishLoading, setPublishLoading] = useState(false);
  const [success, setSuccess] = useState();
  const location = useLocation()
  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess("");
      }, 3000);
    }
  }, [success]);
  useEffect(() => {
    if (!applicationId) return;
    fetchData();
  }, []);

  
  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${URL}/dashboard/applications/${applicationId}/category/${id}/instructions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFetchedData(res.data.data);
      console.log(res.data.data, "pipeee");
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const deleteInstruction = async (instructionId) => {
    try {
      const res = await axios.delete(
        `${URL}/dashboard/applications/${applicationId}/category/${id}/instructions/${instructionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchData();
      setSuccess("Instruction deleted successfully!");
    } catch (error) {
      console.error(error);
    }
  };
  const dispatch = useDispatch();

  const publishInstruction = async (instructionId) => {
    setPublishLoading(instructionId);
    try {
      const res = await axios.put(
        `${URL}/dashboard/applications/${applicationId}/category/${id}/instructions/${instructionId}/publish`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchData();
      setSuccess("Instruction published successfully!");
      if(!applicationId){
      dispatch(getApplicationDraft(applicationId));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setPublishLoading(false);
      }, 1000);
    }
  };

  const tabs = ["Memory", "Features", "Settings", "Integrations/Deploy"];

  const handleButtonClick = (location) => {
    if (location === "Features") {
      navigate("/agent", { state: agentFeatures });
    } else if (location === "Settings") {
      navigate("/agent", { state: "settings" });
    } else if (location === "Integrations/Deploy") {
      navigate("/agent", { state: "Integrations/Deploy" });
    } else {
      navigate("/agent", { state: "Memory" });
    }
  };
  const [propShowPopUp, setPropShowPopUp] = useState(false);

  return (
    <DashboardLayout title={"Assistant"} user={true}>
      <section className="flex font-figtree relative w-full">
        <div className="py-8 px-6 h-full  shrink-0 ">
          <ul className="flex flex-col gap-4 w-[135px]">
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={`py-2.5 px-4 cursor-pointer rounded-md text-sm ${
                  tab === "Features"
                    ? "text-PRIMARY bg-[#FAFAFA]"
                    : "text-SECONDARY"
                }`}
                onClick={() => handleButtonClick(tab)}
              >
                   <span>
                  {tab === "Features"
                    ? "Instructions"
                    : tab === "Integrations/Deploy"
                    ? "Integration/Deploy"
                    : tab}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <main className="font-figtree w-full p-10 mb-[100px] border-l border-[#F7F7F7] min-h-screen">
        <ChangesBanner setPropShowPopUp={setPropShowPopUp} />
        <TestAgent
          propShowPopUp={propShowPopUp}
          setPropShowPopUp={setPropShowPopUp}
        />
          <header className="flex gap-2 items-center mb-4">
            <div
              onClick={() => navigate("/agent", { state: agentFeatures })}
              className="cursor-pointer underline text-[#D2D0D0] text-sm font-normal hover:underline"
            >
               Instructions
            </div>
            <BiChevronRight className=" text-xl text-[#D2D0D0]" />
            <p className="text-[#121212] text-sm font-normal capitalize ">{type}</p>
          </header>
          <section className="flex flex-col gap-[33px]">
            <div className="flex justify-between w-full items-center">
              <div>
            <h2 className="text-[24px] font-medium capitalize text-[#121212]">
                    {type}
                  </h2>
              {type.includes("Scheduled") && (

           
                  <p className="text-[#7F7F81] text-[12px] leading-[18px]">
                    Schedule detailed instructions or guidelines for your
                    assistants within a given time period.
                  </p>
  
               )}  
              </div>
              <div className="gap-[12px] flex">
                {type.includes("Scheduled") && (
                <button
                  onClick={() =>
                    navigate(`/agent/${type}/${id}/archives`)
                  }
                  className="outline-none py-3 w-auto px-5 bg-[#ffffff] rounded-lg text-[#828282] border border-neutral-100 text-[14px] font-semibold"
                >
                  Archives
                </button>
                )} 
                <button
                  onClick={() => {
                    navigate(`/agent/${type}/${id}/add-instruction`, {state: { dataLength: fetchedData?.length}});
                  }}
                  className="outline-none py-3 w-auto px-5 bg-[#121212] rounded-lg text-white text-[14px] font-semibold"
                >
                  Add Instructions
                </button>
              </div>
            </div>
            <InstructionTable
              handleActivation={publishInstruction}
              deleteInstruction={deleteInstruction}
              refresh={fetchData}
              publishLoading={publishLoading}
              name={type}
              id={id}
              fetchedData={fetchedData}
              loading={loading}
            />
            {/* <TestAgent /> */}
          </section>
        </main>
      </section>
      {success && <SuccessMessage success={success} />}
    </DashboardLayout>
  );
}

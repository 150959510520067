import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
let URL = process.env.REACT_APP_BASEURL;

const initialState = {
  loading: false,
  countries: [],
  error: "",
};

export const getCountries = createAsyncThunk(
  "countries/getCountries",
  async (val,{ rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await axios.get(`${URL}/misc/countries`);

      return data;
    } catch (error) {
      console.log(error, "error");
      return rejectWithValue("failed to get countries");
    }
  }
);

const getCountrySlice = createSlice({
  name: "plugins",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCountries.pending, state => {
       if(!state.countries.length) state.loading = true
    });
    builder.addCase(
      getCountries.fulfilled,
      (state, action) =>
        void ((state.loading = false), (state.countries = action.payload))
    );
    builder.addCase(
      getCountries.rejected,
      (state, action) =>
        void (
          // showToast
          ((state.data = []), (state.error = action.payload))
        )
    );
  },
});

export default getCountrySlice.reducer;

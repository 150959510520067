import { useState, useEffect } from "react";
import { Route, Routes } from "react-router";
import "./App.css";
import Forms from "./component/Forms";
import Verification from "./component/Verification";
import CompanyDetails from "./component/CompanyDetails";
// import CompanyDetails from '../src/component/CompanyDetails'
import UseWano from "./component/UseWano";
import GetStarted from "./Routes/GetStarted";
import Dashboard from "./Routes/Dashboard";
import Agent from "./Routes/Agent";
import Widget from "./Routes/Widget";
import AskAgent from "./Routes/AskAgent";
import Analytics from "./Routes/Analytics";
import Customers from "./Routes/Customers";
import Conversations from "./Routes/Conversations";
import CustomerConversation from "./Routes/CustomerConversation";
import Customer from "./Routes/Customer";
import Settings from "./Routes/Settings";
import Wallet from "./Routes/Wallet";
import AgentType from "./Agent/ServiceLayout/AgentType";

import ForgotPassword from "./component/ForgotPassword";
import ResetPassword from "./component/ResetPassword";
import Invitation from "./Routes/Invitation";
import GetStartedDashboard from "./Routes/GetStartedDashboard";
import Support from "./Routes/Support";
import Deploy from "./component/Deploy";
import AuthDashboard from "./Routes/AuthDashboard";
import CustormerGetStarted from "./Routes/CustomerGetStarted";
import TwoFactorAuthentication from "./component/TwoFactorAuthentication";
import TwoFAC from "./component/TwoFAC";
import ResetOtp from "./component/ResetOtp";
import Mobile from "./Assets/Mobileprompt.png";
import Plugins from "./Routes/Plugins";
import PluginType from "./Plugins/PluginType";
import AllPluginType from "./Plugins/AllPluginType";
import CreateFeature from "./Routes/CreateFeature";
import EditFeature from "./Routes/EditFeature";
import ViewFeature from "./Routes/ViewFeature";
import AddInstruction from "./Routes/AddInstruction";
import OtherPlugins from "./Plugins/AllPluginType/components/OtherPlugins";
import Archives from "./Routes/Archives";
import CustomizeAssistant from "./component/CustomizeAssistant";
import ManagePlan from "./Routes/ManagePlan";
// import MobileLayout from './MobileLayout';

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      const isMobileDevice = window.innerWidth <= 768; // Adjust the threshold as needed
      setIsMobile(isMobileDevice);
    };

    // Initial check
    checkMobile();

    // Add event listener for window resize
    window.addEventListener("resize", checkMobile);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  return (
    <Routes>
      {isMobile ? (
        <>
          <Route
            exact
            path="*"
            element={
              <div>
                <img src={Mobile} alt="Device Notice" />
              </div>
            }
          />{" "}
          //applied this to all routes
          <Route path="/widget" element={<Widget />} />
        </>
      ) : (
        <>
          {/* <Route element={<MobileLayout />}> */}
          <Route exact path="/" element={<Forms newUser={false} />} />

          <Route path="/signup" element={<Forms newUser={true} />} />
          <Route path="/verify" element={<Verification />} />
          <Route path="/customize-assistant" element={<CustomizeAssistant />} />
          <Route
            path="/reset-verify"
            element={
              // <Verification
              //   title={"Reset your password"}
              //   text={
              //     "Check your email address provided for an email verification from us. Please enter below the OTP in the mail."
              //   }
              //   resetText={'Reset'}
              // />
              <ResetOtp />
            }
          />
          <Route
            path="/2Fa-Authentication"
            element={<TwoFactorAuthentication />}
          />
          <Route path="/company" element={<CompanyDetails />} />
          <Route path="/getstarted" element={<GetStarted />} />
          <Route
            path="/getstarted/dashboard"
            element={<GetStartedDashboard />}
          />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/agent" element={<Agent />} />
          <Route path="/deploy" element={<Deploy />} />
          <Route path="/widget" element={<Widget />} />
          <Route path="/agent/:type/:id" element={<ViewFeature />} />
          <Route path="/agent/:type/:id/archives" element={<Archives />} />
          <Route path="/settings/:type/manage-plan" element={<ManagePlan />} />
          {/* <Route path="/agent/:type" element={<ViewFeature />} /> */}
          <Route
            path="/agent/:type/:name/:id/:mId/view-instruction"
            element={<EditFeature />}
          />
          <Route
            path="/agent/:type/:id/add-instruction"
            element={<AddInstruction />}
          />

          <Route path="/ask-agent" element={<AskAgent />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/conversations" element={<Conversations />} />
          <Route
            path="/customers/conversations/:id"
            element={<CustomerConversation />}
          />
          <Route path="/customers/:id" element={<Customer />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/support" element={<Support />} />
          <Route path="/top-up" element={<Wallet />} />
          <Route path="/agent/create-feature" element={<CreateFeature />} />
          <Route path="/auth/accept-invite" element={<Invitation />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/what-will-you-use-wano-for" element={<UseWano />} />
          <Route path="three-factor-authentic" element={<TwoFAC />} />
          <Route path="/plugins" element={<Plugins />} />
          <Route path="/plugins/pluginType/:id" element={<PluginType />} />
          <Route
            path="/plugins/AllpluginType/:id"
            element={<AllPluginType />}
          />
          <Route
            path="/plugins/AllpluginTypes/:name"
            element={<OtherPlugins />}
          />
          {/* </Route> */}
        </>
      )}
    </Routes>
  );
}

export default App;

import { useNavigate } from "react-router";
import CustomerTable from "./CustomerTables";

export default function CustomerContent({ data, page, loading }) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/ask-agent");
  };
  return (
    <main className=" font-figtree relative">
      <header
        className="pl-[24px] shadow-box pr-[51px] w-full py-[47px] rounded-xl mb-8 flex justify-between items-center"
        style={{
          backgroundImage:
            "linear-gradient(to bottom right, #FDF6F9 10%, #F6F6FC 50%, #FAFCFF 100%)",
        }}
      >
        <div className=" flex flex-col ">
          <p className=" font-bold text-[32px] text-bamboo ">Customers</p>
          <p className=" text-sm font-normal text-BLACK-_300 leading-6">
            Track, manage and Engage your customers
          </p>
        </div>
      </header>
      <CustomerTable data={data} loading={loading} page={page} />
    </main>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { api } from "../../services/api"
import { appUrls } from "../../services/urls";


const initialState = {
    loading: false,
    data: [],
    error: ""
};

export const getAllPlugins = createAsyncThunk(
    "plugins/getAllPlugins", 
    async({ applicationId }, { rejectWithValue }) => {
        try {
            const res = await api.get(`/dashboard/plugins/applications/${applicationId}/available`)            //get(appUrls?.ALL_PLUGINS);
            // console.log(res, "pojo")
            return res?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)
// export const getAllPlugins = createAsyncThunk(
//     "plugins/getAllPlugins", 
//     async(values, { rejectWithValue }) => {
//         try {
//             const res = await api.get(`/dashboard/plugins/applications/${values}/available`);
//             console.log(res, "pojo")
//             return res?.data

//         } catch (error) {
//             return rejectWithValue(error?.response?.data?.message)
//         }
//     }
// )

const getAllPluginsSlice = createSlice({
    name: "plugins",
    initialState,
    extraReducers: builder => {
        builder.addCase(getAllPlugins.pending, state => {
            state.loading = true
        });
        builder.addCase(getAllPlugins.fulfilled, (state, action) => {
            state.loading = false;
            // state.data = action.payload,
            // Ensure the action.payload.data contains the expected structure
            if (action.payload && action.payload.data) {
                const sortedAvailable = [...action.payload.data.available].sort((a, b) => a.name.localeCompare(b.name));
                const sortedActive = [...action.payload.data.active].sort((a, b) => a.name.localeCompare(b.name));
                // console.log(sortedActive, sortedAvailable)
                // Update state with sorted data
                state.data = {
                    ...action.payload,
                    data: {
                        ...action.payload.data,
                        available: sortedAvailable,
                        active: sortedActive,
                    },
                };
            } else {
                // Fallback if the expected data structure is not met
                state.data = action.payload;
            }
            state.error = "";
        })
        builder.addCase(getAllPlugins.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default getAllPluginsSlice.reducer
import React, { useState, useRef, useEffect } from "react";
import Button from "./Button";
import Terms from "./Terms";
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useAuth } from "../context/UserContext";
import { ErrorMessage, SuccessMessage } from "../Toast-Messages/Toastmessages";
import { getBusiness } from "@/../features/business/getBusinessSlice";
import { getApplicationById, setAgent } from "@/../features/application/getApplicationByIdSlice";
import { useDispatch } from "react-redux";
import { loginUser } from "@/../features/auth/loginSlice";
import TokenService from "@/../services/token";
import axios from "axios";

export default function TwoFactorAuthentication() {
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();
  const { login } = useAuth();
  const inputRefs = {
    input1: useRef(),
    input2: useRef(),
    input3: useRef(),
    input4: useRef(),
    input5: useRef(),
    input6: useRef(),
  };

  const isFilled = Object.values(inputValues).every((value) => value);

  const handleInputSubmit = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
    if (value && name !== "input6") {
      inputRefs[
        `input${parseInt(name.charAt(name.length - 1)) + 1}`
      ].current.focus();
    }
  };
   

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const handleVerification = async () => {
      const verificationCode = Object.values(inputValues).join("");
      const userData = JSON.parse(localStorage.getItem("userDetails"));
      const data = {
        ...userData,
        code: verificationCode,
      };
      // try {
      //   const res = await login(JSON.stringify(data));
      //   setSuccess("verification successful");
      //   setLoading(false);
      //   setTimeout(() => {
      //     setSuccess(false);
      //     // navigate("/dashboard");
      //     navigate("/dashboard");
      //   }, 1000);
      // } catch (error) {
      //   setError("Invalid Code");
      //   setTimeout(() => {
      //     setError(false);
      //   }, 1000);
      //   setLoading(false);
      // }

      try {
        const logDetail = JSON.parse(localStorage.getItem("loginDetail"))
        console.log(logDetail)
        setLoading(true);
        const loginResponse = await dispatch(loginUser({...logDetail , code: verificationCode}));
  
        if (loginResponse?.payload?.status) {
          const applicationResponse = await dispatch(getApplicationById());
          if (applicationResponse?.payload?.data?.length >= 1) {
            localStorage.setItem(
              "wano_agent",
              JSON.stringify(applicationResponse?.payload?.data[0])
            );
            dispatch(setAgent(applicationResponse?.payload?.data[0]));
  
            dispatch(getBusiness());
            setLoginSuccess(true);
            setTimeout(() => {
              navigate("/dashboard");
            }, 1000);
          } else {
            dispatch(getBusiness());
            setLoginSuccess(true);
            setTimeout(() => {
              navigate("/dashboard");
            }, 1000);
          }
        } else {
          setLoginError(true);
          if (loginResponse.payload === "Account not verified") {
            navigate("/verify");
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
        setLoginError(true);
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    handleVerification();
  };
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain");
    const pastedValues = pastedData.split("").slice(0, 6);

    const updatedInputValues = { ...inputValues };

    Object.keys(updatedInputValues).forEach((inputName, index) => {
      updatedInputValues[inputName] = pastedValues[index] || "";
    });

    setInputValues(updatedInputValues);
  };

  const handleKeyDown = (e) => {
    const { key, target } = e;
    const inputName = target.name;

    switch (key) {
      case "ArrowLeft":
        handleArrowLeft(inputName);
        break;
      case "ArrowRight":
        handleArrowRight(inputName);
        break;
      default:
        break;
    }
  };

  const handleArrowLeft = (currentInputName) => {
    const currentInputIndex =
      Object.keys(inputValues).indexOf(currentInputName);

    if (currentInputIndex > 0) {
      const prevInputName = `input${currentInputIndex}`;
      inputRefs[prevInputName].current.focus();
    }
  };

  const handleArrowRight = (currentInputName) => {
    const currentInputIndex =
      Object.keys(inputValues).indexOf(currentInputName);

    if (currentInputIndex < Object.keys(inputValues).length - 1) {
      const nextInputName = `input${currentInputIndex + 2}`;
      inputRefs[nextInputName].current.focus();
    }
  };

  useEffect(() => {
    const handlePasteEvent = (e) => handlePaste(e);
    const handleKeyDownEvent = (e) => handleKeyDown(e);

    document.addEventListener("paste", handlePasteEvent);
    document.addEventListener("keydown", handleKeyDownEvent);

    return () => {
      document.removeEventListener("paste", handlePasteEvent);
      document.removeEventListener("keydown", handleKeyDownEvent);
    };
  }, []);

  const removeErrorNotification = () => {
    setTimeout(() => {
      setLoginError(false);
    }, 1000);
  };

  const removeSuccessNotification = () => {
    setTimeout(() => {
      setLoginSuccess(false);
    }, 1000);
  };

  useEffect(() => {
    removeSuccessNotification();
  }, [loginSuccess]);

  useEffect(() => {
    removeErrorNotification();
  }, [loginError]);

  return (
    <>
      <div className="min-h-screen grid content-between bg-[#F7F7F7]">
        <NavBar />
        <div className="w-[526px] mx-auto shadow py-[30px] px-[50px] rounded-xl grid gap-10 items-center justify-center bg-white">
          <BiArrowBack
            onClick={() => navigate(-1)}
            className="cursor-pointer"
          />
          <div className="mx-auto flex flex-col justify-center gap-[2px]">
            <p className=" mx-auto text-2xl text-[#101828] font-medium text-center">
              Enter 6-digit code from your authenticator app
            </p>
            <span className=" mx-auto text-[14px] text-[#828282] text-center font-normal mt-4">
              This helps us keep your account secure.
            </span>
          </div>
          <div className="grid gap-3">
            <div className="w-[420px] mx-auto">
              <div className="w-[395px] mx-auto flex justify-between">
                {Object.keys(inputValues).map((inputName, index) => (
                  <input
                    ref={inputRefs[inputName]}
                    key={index}
                    className="verifyInput input h-[59px] w-[59px] text-center"
                    name={inputName}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={inputValues[inputName]}
                    onChange={handleInputSubmit}
                  />
                ))}
              </div>
            </div>
          </div>
          <Button
            onClick={handleSubmit}
            disabled={!isFilled}
            className={
              isFilled
                ? "w-[420px] bg-black rounded-xl px-[12px] line-[24px] py-[13px] text-white text-[14px] "
                : ""
            }
            text={"Verify Code"}
            loading={loading}
          />
        </div>
        <Terms />
      </div>
      {success && <SuccessMessage success={success} className="mt-5 mr-2" />}
      {error && <ErrorMessage error={error} className="mt-5 mr-2" />}
    </>
  );
}

// {loginSuccess && (
//   <SuccessMessage
//     success={userLogin?.user?.message}
//     className="mt-5 mr-2"
//   />
// )}
// {loginError && (
//   <ErrorMessage error={userLogin?.error} className="mt-5 mr-2" />
// )}
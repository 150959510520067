import React, { useEffect, useState } from 'react'

import {FaPlus} from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux'
import { getBusinessAskAgentChat } from "../features/chat/getBusinessAskAgentChatSlice";
import {
  markInboxAsRead,
  getInbox,
} from "../features/chat/businessAskAgentChatSliceH";
import { createConversation } from "../features/chat/businessAskAgentChatSlice";

import { useLocation } from "react-router";

export default function SideBar({ isDataM, setTags,setInboxChat, handleChangeSection }) {
  const [data, setData] = useState([]);
  const [conversationData, setConversationData] = useState([]);
  const [section, setSection] = useState("NewChat");
  const [done, setDone] = useState(true);
  const [page, setPage] = useState(1);
  const pageSize = 5;

  const fetchChat = useSelector((state) => state.getAgentChat);
  const prevChats = fetchChat?.data?.data;

  const dispatch = useDispatch();

  const applicationId = JSON.parse(localStorage.getItem("wano_agent"));

  const { loading } = useSelector((state) => state.askAgentChat);

  const appId = applicationId?.id;
  const getConversations = async () => {
    dispatch(getBusinessAskAgentChat({ page, pageSize, appId })).then((res) => {
      active !== "inbox" && setData(res?.payload?.data);
      setConversationData(res?.payload?.data);
      setDone(!true);
    });
    dispatch(getInbox({ appId }));
  };
  useEffect(() => {
    getConversations();
  }, [loading]);
  const [active, setActive] = useState("conversations");
  // const [switchSection, setSwitchSection] = useState(false);
  // const location = useLocation();
  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   setActive(searchParams.get("inbox"));
  // }, []);
  const { inbox } = useSelector((state) => state.askAgentChatH);
  useEffect(() => {
    active === "inbox" && setData(inbox);
  }, [inbox]);
  return (
    <main className="h-[60vh] ">
      <div className="my-5 rounded-lg bg-chatBrown p-1 text-xs">
        <div className="grid grid-cols-2 gap-1 bg-white items-center text-center">
          <div
            className={`py-3 cursor-pointer
          ${
            active === "conversations"
              ? " text-BLUE-_200 shadow-md rounded-md"
              : ""
          }
          `}
            onClick={() => {
              setData(conversationData);
              getConversations();
              setActive("conversations");
            }}
          >
            Conversations
          </div>
          <div
            className={`py-3
          ${active === "inbox" ? " text-BLUE-_200 shadow-md rounded-md" : ""}
          flex justify-center items-center gap-2 cursor-pointer
          `}
            onClick={() => {
              setData(inbox);
              setActive("inbox");
            }}
          >
            <p>Inbox</p>
            {!!inbox?.filter((each) => each.is_read === false).length && (
              <div className="h-3 w-3 rounded-full text-white bg-BLUE-_200 text-[8px] flex items-center justify-center">
                {inbox?.filter((each) => each.is_read === false).length}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className=" w-[249px]">
        {active === "conversations" && (
          <button
            onClick={() => handleChangeSection({ type: "new", id: data.id })}
            className="flex w-full  py-[13px] text-[#0359D8] border border-[#F4F3F3] justify-center rounded-lg gap-2 mb-[18px] select-none outline-none"
          >
            <FaPlus className=" text-[16px]" />
            <p className=" text-sm font-semibold">New Chat</p>
          </button>
        )}
      </div>

      <p className="text-[#7F7F81] text-xs font-medium uppercase mb-4 ">
        Recent
      </p>
      {!isDataM ? (
        ""
      ) : (
        <div
          className="hide-scrollbar"
          style={{
            // height: "55vh",
            height: "100%",
            overflow: "auto",
          }}
        >
          {data?.length > 0 &&
            data.map(
              (
                data,
                index //changed from data to previousChats
              ) => (
                <section
                  key={index}
                  className="flex flex-col gap-3 select-none h-11"
                  style={{ marginBottom: "10px" }}
                >
                  <p
                    onClick={async () => {
                      // if tab is an inbox
                      if (active === "inbox") {
                        // mark inbox as read
                        if (data?.conversation?.length) {
                          // if it's part of an existing conversation, use the conversation id
                          handleChangeSection({
                            type: data.title,
                            id: data.conversation[0].id,
                            existingConversation: true,
                            isInbox: active === "inbox",
                            application: data.application,
                          });
                          setInboxChat(null)
                        } else {
                          const inboxId = data.id;
                          // create conversation
                          setInboxChat ({
                            role: "assistant",
                            message: data.title,
                            id:data.id,
                            application: data.application,
                          })
                          console.log("SENDING", data);
                          const payload = {
                            title: data.title,
                            applicationConversationInboxId: data.id,
                          };
                          dispatch(
                            createConversation({ data: payload, appId })
                          ).then((res) => {
                            let chatId = res?.payload.data.id;
                            const payload2 = {
                              message: data.title,
                              assistant_type: applicationId?.type,
                              tags: [],
                              applicationConversationInboxId: data.id,
                            };
                            setTags([]);
                            handleChangeSection({
                              type: data.title,
                              id: chatId,
                              isInbox: active === "inbox",
                              application: data.application,
                            });
                          
                            setSection({ id: chatId });

                            // replace trig id

                            // dispatch(businessAskAgentChatH({ data:payload2, appId, chatId })).then(()=>{
                            //   setTags([])
                            // });
                          });

                          await dispatch(markInboxAsRead({ inboxId, appId }));
                     
                        }
                        dispatch(getInbox({ appId }));
                      } else {
                        setInboxChat(null)

                        handleChangeSection({
                          type: data.title,
                          id: data.id,
                        });
                      }
                    }}
                    className="rounded-lg flex items-center gap-3.5 text-[#121212] pr-5 py-3 bg-[rgba(229,229,229,0.30)] pl-2 text-sm font-medium cursor-pointer"
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <span className="truncate">{active === "inbox" ? data.message :data.title}</span>
                    {active === "inbox" && data.is_read === false && (
                      <div className="w-2 h-2 bg-BLUE-_200 rounded-full"></div>
                    )}
                  </p>
                </section>
              )
            )}
        </div>
      )}
    </main>
  );
}

import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router";
import * as Yup from "yup";

import PasswordField from "../../PasswordField";
import Logo from "../../../Assets/wano logo 1 1.png";
import Google from "../../../Assets/google.png";
import { useDispatch, useSelector } from "react-redux";
import { joinTeam } from "../../../features/teams/joinTeamSlice";
import { SuccessMessage } from "../../../Toast-Messages/Toastmessages";
import { getCountries } from "features/countries/getCountriesSlice";
import spinner from "Assets/gif/loader.gif";

const TeamInvitation = ({ closeInvitationModal, check }) => {
  const dispatch = useDispatch();
  const storeCountries = useSelector((state) => state.getAllCountries);
  const countriesLoading = storeCountries.loading;
  const [country, setCountry] = useState("");
  const [countrycode, setCountrycode] = useState("+234");
  const [countryFlagIcon, setCountryFlagIcon] = useState("🇳🇬");
  const [joinedTeamSuccess, setJoinedTeamSuccess] = useState(false);

  // console.log(check, "koko")

  const joinTeams = useSelector((state) => state.joinTeam);
  // console.log(joinTeams, "JOINTEAM")

  const formValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    password: Yup.string().required(),
    // country: Yup.string().required(),
    phone: Yup.number().required(),
  });

  const navigate = useNavigate();

  const submitForm = async (values) => {
    const data = {
      first_name: values?.firstName,
      last_name: values?.lastName,
      password: values?.password,
      country: country,
      phone_number: `${countrycode}${values?.phone}`,
    };
    const token = check?.invitation?.invite_code;
    await dispatch(joinTeam({ data, token })).then((res) => {
      // console.log(res, "lapa")
      if (res?.payload?.status) {
        setJoinedTeamSuccess(true);
      }
      navigate("/");
    });
  };

  const removeSuccessNotification = () => {
    setTimeout(() => {
      setJoinedTeamSuccess(false);
    }, 1000);
  };

  useEffect(() => {
    removeSuccessNotification();
  }, [joinedTeamSuccess]);
  const getCountiresAndStore = async () => {
    dispatch(getCountries());
  };
  useEffect(() => {
    getCountiresAndStore();
  }, []);
  const [countryError, setCountryError] = useState("");

  return (
    <div
      className="bg-WHITE-_100 w-[520px] h-fit mt-[30px] py-[30px] px-[50px] rounded-lg"
      style={{ boxShadow: "0px 24px 43px 0px rgba(76,76,76, 0.07)" }}
    >
      <div className="w-[321px] h-[86px] gap-1.5 flex flex-col justify-center items-center mx-auto">
        <img src={Logo} alt="Logo" className="w-[35px] h-[35px]" />
        <p className="text-2xl text-BLACK-_600 font-medium">Welcome to Wano</p>
        <p className="text-BLACK-_300 text-sm">
          Create your account to accept your invitation
        </p>
      </div>

      <div className="w-[420px] h-[382px] mt-10">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            // email: "",
            // country: "",
            phone: "",
            password: "",
          }}
          validationSchema={formValidationSchema}
          onSubmit={(values, actions) => {
            window.scrollTo(0, 0);
            // console.log(values, "often")
            submitForm(values);

            actions.resetForm();
          }}
        >
          {({
            handleSubmit,
            handleChange,
            dirty,
            isValid,
            setFieldValue,
            errors,
            touched,
            // setFieldTouched,
            values,
          }) => (
            <Form
              onSubmit={handleSubmit}
              className="flex flex-col lg:items-center"
            >
              <div className="flex flex-col gap-5 ">
                <div className="flex gap-5">
                  <div className="w-[200px] h-[58px] flex flex-col">
                    <label
                      htmlFor="Firstname"
                      className="text-xs font-normal text-[#101828]"
                    >
                      Firstname
                    </label>
                    <input
                      name="firstName"
                      placeholder=""
                      type="text"
                      value={values.firstName}
                      onChange={handleChange}
                      className="rounded-lg border-[#D0D5DD] outline-none w-[200px] mt-1.5 h-[38px] border-solid  p-3 border"
                    />
                    {errors.firstName && touched.firstName ? (
                      <div className="text-RED-_100 text-sm">
                        {errors.firstName}
                      </div>
                    ) : null}
                  </div>
                  <div className="w-[200px] h-[58px] flex flex-col ">
                    <label
                      htmlFor="Firstname"
                      className="text-xs font-normal text-[#101828]"
                    >
                      Lastname
                    </label>
                    <input
                      name="lastName"
                      placeholder=""
                      type="text"
                      value={values.lastName}
                      onChange={handleChange}
                      className="rounded-lg border-[#D0D5DD] outline-none w-[200px] mt-1.5 h-[38px] border-solid  p-3 border"
                    />
                    {errors.lastName && touched.lastName ? (
                      <div className="text-RED-_100 text-sm">
                        {errors.lastName}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex  gap-5">
                  <div className=" w-full h-[58px] flex flex-col">
                    <label
                      htmlFor="country"
                      className="text-xs font-normal text-[#101828]"
                    >
                      Country
                    </label>
                    {/* <input
                      name="country"
                      placeholder=""
                      type="text"
                      value={values.country}
                      onChange={handleChange}
                      className="rounded-lg border-[#D0D5DD] outline-none w-[200px] mt-1.5 h-[38px] border-solid  p-3 border"
                    /> */}
                    <div
                      className={`w-full rounded-lg mt-1.5 h-10 flex gap-2 items-center text-base border px-[10px] cursor-pointer select text-[#575656]
            
            ${countryError ? "border !border-RED-_100" : ""}
            `}
                    >
                      <p className="text-3xl">{countryFlagIcon}</p>
                      <select
                        name="country"
                        id=""
                        className="w-full outline-none cursor-pointer rounded-lg h-[38px] text-base"
                        onChange={(e) => {
                          const val = e.target.value;
                          const selectedCountry =
                            storeCountries?.countries.find(
                              (country) => country.name === val
                            );
                          setCountryFlagIcon(selectedCountry.flag);
                          setCountry(selectedCountry.name);
                          setCountrycode(selectedCountry.phone_code);
                        }}
                        value={country}
                      >
                        <option value="" disabled></option>
                        {storeCountries?.countries.map((country, index) => (
                          <option key={index} value={country.name}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* {errors.country && touched.country ? (
                      <div className="text-RED-_100 text-sm">{errors.country}</div>
                    ) : null} */}
                  </div>
                </div>
                <div className="w-full h-[58px] flex flex-col ">
                  <label
                    htmlFor="phone"
                    className="text-xs font-normal text-[#101828]"
                  >
                    Phone
                  </label>
                  <div
                    className={`flex border mt-1.5 py-[3px] px-[10px] rounded-lg h-10}
              
              ${
                errors?.phonenumber && errors?.phonenumber?.message
                  ? "border !border-RED-_100"
                  : ""
              }
              
              `}
                  >
                    {countriesLoading ? (
                      <div className="flex items-center justify-center">
                        <img src={spinner} alt="template" className="w-5 h-5" />
                      </div>
                    ) : (
                      <select
                        onChange={(e) => {
                          const val = e.target.value;
                          const selectedCountry =
                            storeCountries?.countries.find(
                              (country) => country.name === val
                            );
                          setCountry(selectedCountry.name);
                          setCountryFlagIcon(selectedCountry.flag);
                          setCountrycode(selectedCountry.phone_code);
                        }}
                        value={country}
                        className="border-r !text-xs border-solid border-[#E5E5E5] outline-none"
                      >
                        {storeCountries?.countries.map((country) => (
                          <option
                            className="flex flex-row text-xs"
                            key={country.code}
                            value={country.name}
                          >
                            <span className="!text-xs">
                              {country.code} ({country.phone_code})
                            </span>
                          </option>
                        ))}
                      </select>
                    )}

                    <input
                      className={`w-full border-none outline-none text-base h-8
                  `}
                      type="number"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                    />
                  </div>
                  {/* <input
                      name="phone"
                      placeholder=""
                      type="text"
                      value={values.phone}
                      onChange={handleChange}
                      className="rounded-lg border-[#D0D5DD] outline-none w-[200px] mt-1.5 h-[38px] border-solid  p-3 border"
                    /> */}
                  {errors.phone && touched.phone ? (
                    <div className="text-RED-_100 text-sm">{errors.phone}</div>
                  ) : null}
                </div>

                {/* <div className="flex flex-col ">
                            <label htmlFor='email' className="text-xs font-normal text-[#101828]">Email</label>
                                <input
                                    name="email"
                                    placeholder=""
                                    type="text" 
                                    value={values.email}
                                    onChange={handleChange}
                                    className="rounded-lg border-[#D0D5DD] xs:w-full outline-none lg:w-[420px] mt-1.5 h-[38px] border-solid  p-3 border"
                                />
                                {errors.email && touched.email ? (
                                <div className='text-RED-_100'>{errors.email}</div>
                                ) : null}
                        </div> */}
                <div className="flex flex-col xs:w-full lg:w-[420px]">
                  <label
                    htmlFor="password"
                    className="text-xs font-normal text-[#101828]"
                  >
                    Password
                  </label>
                  <PasswordField
                    name="password"
                    value={values.password}
                    placeholder=""
                    className="border w-[420px] h-[38px] rounded-lg border-[#D0D5DD] mt-1.5"
                    onChange={handleChange}
                  />
                  {errors.password && touched.password ? (
                    <div className="text-RED-_100 text-sm">
                      {errors.password}
                    </div>
                  ) : null}
                </div>

                <div className="mt-6 flex flex-col gap-3">
                  <button
                    className={`${
                      values?.firstName ||
                      values?.lastName ||
                      values?.phone ||
                      values?.password
                        ? "bg-BLACK-_100"
                        : "bg-WHITE-_200"
                    } w-[420px] h-[34px] rounded-lg px-2 py-1`}
                    type="submit"
                  >
                    <p className="text-WHITE-_100 text-sm font-bold">
                      Join Team
                    </p>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {joinedTeamSuccess && (
        <SuccessMessage
          Formodal={true}
          success={joinTeams?.data?.message}
          className="mt-24 mr-20"
        />
      )}
    </div>
  );
};

export default TeamInvitation;

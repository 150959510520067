import React, { Fragment, useEffect, useRef, useState } from 'react';
import axios from 'axios';
// import { Menu, Transition } from '@headlessui/react'
// import { BiChevronDown } from "react-icons/bi"
// import { PiHeadsetFill } from 'react-icons/pi';
// import { Divider } from '@mui/material';
// import { FiPhone } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from 'react-router';
import InstallIcon from '../../../Assets/svg/install.svg'
import TokenService from '../../../services/token';
// import General from '../../../Assets/img/all 1.png'
// import WhatsAppBig from '../../../Assets/img/whatsapp_big.png';
// import Phone from "../../../Assets/svg/phone.svg";
// import Logo from "../../../Assets/wano logo 1 1.png"
import IntegrateWano from '../../../component/modals/plugins/IntegrateWano';
import ModalPop from '../../../component/modalPop';
import IntegrateCustomBusinessA from '../../../component/modals/plugins/IntegrateCustomBusinessA';
import IntegrateCustomBusinessB from '../../../component/modals/plugins/IntegrateCustomBusinessB';
// import RemovePlugin from '../../../component/modals/plugins/RemovePlugin';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPluginById } from '../../../features/plugins/getAllPluginsByIdSlice';
// import { CustomerService, Sales } from '../../../Assets/svg';
import SetupWhatsApp from '../../../component/modals/plugins/SetupWhatsApp';
import AddPlugin from '../../../component/modals/plugins/AddPlugin';
import SetupInstruction from '../../../component/modals/plugins/whatsapp/SetupInstruction';
import { getAllPlugins } from '../../../features/plugins/getAllPluginsSlice';
// import { useAuth } from '../../../context/UserContext';
// import SetupIcon from '../../../Assets/svg/setup.svg'
import { SetUpIcon } from '../../../Assets/icons';


const AllPlugins = () => {
  const [integrateWithWano, setIntegrateWithWano] = useState(false)
  const [integrateWithCustomBusiness, setIntegrateWithCustomBusiness] = useState(false)
  const [openNextModal, setOpenNextModal] = useState()
  const [purpose, setPurpose] = useState('');
  const [addWanoNumber, setAddWanoNumber] = useState(false)
  const [integrateWhatsApp, setIntegrateWhatsapp] = useState(false)
  const [install, setInstall] = useState(false)
//   const [selectedAgent, setSelectedAgent] = useState([])
  const [featureData, setFeatureData] = useState(null)
//   const [viewAgent, setViewAgent] = useState(false);
  const [openSetupInstruction, setOpenSetupInstruction] = useState(false)
  const [isSetupComplete, setIsSetupComplete] = useState(false)

//   const { status } = useAuth()
//   console.log(status)
  
//  const navigate = useNavigate();
//  navigate(`/plugins/pluginType/${state?.plugin.id}`, { state: item });



const appId = JSON.parse(localStorage.getItem("wano_agent"));
const dispatch = useDispatch()//just moved this here
const applicationId = appId?.id;
useEffect(() => {
    dispatch(getAllPlugins({ applicationId }));
}, [dispatch, applicationId]);

const token = TokenService.getToken()
const getAllPluginsData = useSelector((state) => state.getAllPlugins);
//   console.log(getAllPluginsData, "getAllPluginsData");
const allPlugins = getAllPluginsData?.data.data?.available;
const allActivePlugins = getAllPluginsData?.data.data?.active;
const { state } = useLocation()
// console.log(state, "this is state")
  const uninstalledData = allPlugins?.find((plugin) => plugin.id === state.id)
  const installedData = allActivePlugins?.find((plugin) => plugin.id === state.id)

const renderData = uninstalledData? uninstalledData : installedData

const isSetup = renderData?.require_setup;
const isPluginInstalled = renderData?.applicationPlugins?.some(plugin => plugin?.features?.some(feature => feature.notification_url))
// console.log(renderData, "renderData")
// console.log('uninstalledData', uninstalledData)
// console.log('installedData', installedData)
// console.log("AllActivePlugin", allActivePlugins)


//   const { state } = useLocation()
//   console.log(data, "japa")    

//   const dispatch = useDispatch()
  const id = state?.id

  useEffect(() => {
    dispatch(getAllPluginById({ id }))
  }, [])

  //added this line to update pluginState
  useEffect(() => {
        localStorage.setItem("pluginState", "All Plugins")
    }, [])
  //end of line

  const fetchPluginAgentFeatures = async (agentId) => {
    sessionStorage.setItem('plugin_id', renderData?.id)
    const res = await axios.get(
        `${URL}/dashboard/plugins/${renderData?.plugin.id}/application/${agentId}/features`, 
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
    )
    setFeatureData(res.data.data)
    
}

//   const getAllPluginsByIdData = useSelector(state => state.getAllPluginsById)
//   console.log(getAllPluginsByIdData, "getAllPluginsByIdData")

{/* <img
    src={WhatsApp}
    className="w-[36px] h-[36px]"
    alt="icon"
    /> */}

  return (
    <div className="">
        <img src={renderData?.image_url} className='h-[65px] w-[65px]' alt={renderData?.name}/> {/* WhatsAppBig */}
        <div className='flex items-center justify-between mt-[24px]'>
            <p className='text-[#101828] font-medium text-[24px]'>{renderData?.name}</p>
            {
                renderData?.applicationPlugins[0]?.published === true  ? (
                !isSetup && (renderData?.channel || !isPluginInstalled)? 
                    <button
                        className={`cursor-pointer flex gap-3 outline-none py-[10px] w-auto px-5   text-xs font-semibold`}
                    >
                        <p className="text-[#009733] text-sm">Installed</p>
                    </button>
                    :
                    <button onClick={() => setIntegrateWhatsapp(true)}  //{() => {fetchPluginAgentFeatures(state.application.id); setViewAgent(!viewAgent); setSelectedAgent(state.application)}} 
                        className="cursor-pointer flex items-center gap-2.5 outline-none py-[10px] w-auto px-5 bg-zinc-400 bg-opacity-25 rounded-lg text-zinc-700 text-xs font-semibold"
                    >
                        {/* <img src={SetupIcon} alt="Setup" /> */}
                        <SetUpIcon fill={"#484848"}/>
                        <p className='text-sm font-semibold'>Setup</p> 
                    </button>
                    
                
                ) : (
                    <button onClick={() => { setInstall(true); }} className="cursor-pointer flex gap-3 outline-none py-[10px] w-auto px-5 bg-[#121212] rounded-lg text-white text-xs font-semibold">
                        <img src={InstallIcon} alt="Install" />
                        <p>Install</p>
                    </button>
                )
            }
            {/* Removed commented code from here check end of code*/}
        </div>

        <div className='flex w-full gap-6 mt-[30px]'>
            <div className='flex flex-col flex-1 border-[0.5px] border-t-0 border-l-0 border-r-0 pb-[24px] border-b gap-4'>
                <p className='text-[#121212] text-xl font-medium'>About App</p>
                <p className='text-sm text-[#7F7F81] leading-[25.2px]'>
                   {renderData?.about}
                </p>
            </div>
        </div>
        <div className='w-[672px] mt-[24px] mb-[100px]'>
            <div className='flex flex-col gap-4'>
                <p className='text-xl text-[#121212] font-medium'>How to Integrate</p>
                <p className='text-sm text-[#7F7F81] mb-1'>Integrate with Wano Number</p>
            </div>
            <div>
                <p className='text-sm text-[#7F7F81] leading-[25.2px]'>1. Provide a webhook notification URL for the service</p>
                <p className='text-sm text-[#7F7F81] leading-[25.2px]'>2. Click on the available Wano number to integrate and go live on WhatsApp instantly</p>
                <p className='text-sm text-[#7F7F81] mb-1 mt-5'>Integrate with Custom Number</p>
                <p className='text-sm text-[#7F7F81] leading-[25.2px]'>1. Provide a webhook notification URL for seamless service integration.</p>
                <p className='text-sm text-[#7F7F81] leading-[25.2px]'>2. Provide your unique business number (ensuring it's not already connected to WhatsApp)</p>
                <p className='text-sm text-[#7F7F81] leading-[25.2px]'>3. Complete the process by entering the OTP sent to the provided number.</p>
                <p className='text-sm text-[#7F7F81] leading-[25.2px]'>4. Your app will be live within 24 hours.</p>
            </div>
        </div>

        <ModalPop isOpen={integrateWithWano}>
            <IntegrateWano handleClose={() => setIntegrateWithWano(false)} purpose={purpose}/>
        </ModalPop>

        <ModalPop isOpen={integrateWhatsApp}>
            <SetupWhatsApp 
                handleClose={() => setIntegrateWhatsapp(false)}
                setAddWanoNumber={setAddWanoNumber} 
                setPurpose={setPurpose} 
                setOpenSetupInstruction={setOpenSetupInstruction}
                setIntegrateWithCustomBusiness={setIntegrateWithCustomBusiness} 
            />
        </ModalPop>

        <ModalPop isOpen={install}>
            <AddPlugin  handleClose={() => setInstall(false)} data={renderData} />
        </ModalPop>

        <ModalPop isOpen={openSetupInstruction}>
            <SetupInstruction 
                handleClose={() => setOpenSetupInstruction(false)}
                setOpenNextModal={setOpenNextModal}
                setPurpose={setPurpose}
                setAddWanoNumber={setAddWanoNumber}    
            />
        </ModalPop>

        <ModalPop isOpen={integrateWithCustomBusiness}>
            <IntegrateCustomBusinessA 
                handleClose={() => setIntegrateWithCustomBusiness(false)} 
                addWanoNumber={addWanoNumber}
                setIntegrateWithWano={setIntegrateWithWano} 
                setOpenNextModal={setOpenNextModal} 
                purpose={purpose}
            />
        </ModalPop>

        <ModalPop isOpen={openNextModal}>
            <IntegrateCustomBusinessB 
                handleClose={() => {setOpenNextModal(false)}} 
                purpose={purpose}
                finished={setIsSetupComplete}
            /> 
        </ModalPop>
       
    </div>
  )
}

export default AllPlugins

{/* <div className="">
                <Menu as="div" className="relative text-left">
                    <div className='w-[132px] bg-[#121212] flex justify-center items-center rounded-lg '>
                        <Menu.Button className=" flex justify-center gap-1 items-center text-WHITE-_100">
                           <p className='p-2.5 text-sm font-semibold'> Integrate</p>
                           <Divider  orientation="vertical" flexItem className='bg-[#fff] ' />
                            <BiChevronDown
                                className="ml-2 mr-3 h-5 w-5 text-[#fff]"
                                aria-hidden="true"
                            />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                    <Menu.Items className="absolute right-0 mt-2 w-[320px] h-[150px] rounded-lg origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                        <div className="">
                            <Menu.Item>
                                {({ active }) => (
                                <button
                                    className={`${
                                    active ? 'bg-[#F9FAFB] text-[#121212]' : 'text-gray-900'
                                    } group flex flex-col  w-full gap-1   px-[16px] py-[15px]`}
                                    // onClick={() => setIntegrateWithWano(true)}
                                    onClick={() => {setIntegrateWithCustomBusiness(true); setPurpose('Wano'); setAddWanoNumber(true)}}
                                >
                                    <div className='flex items-center gap-2'>
                                        <img src={Logo} alt='wano-logo' className='w-[13px] h-[13px]'/>
                                        <p className='font-semibold text-[#121212] text-sm'>Integrate with Wano Number</p>
                                    </div>
                                    <div>
                                        <p className='text-[#7F7F81] text-left text-xs'>Peer Plugin to default Wano number. Go live instantly</p>
                                    </div>
                                    
                                    
                                </button>
                                )}
                            </Menu.Item>
                        </div>
                        <hr />
                        <div className="">
                            <Menu.Item>
                                {({ active }) => (
                                <button
                                    className={`${
                                    active ? 'bg-[#F9FAFB] text-[#121212]' : 'text-gray-900'
                                    } group flex flex-col  w-full gap-1   px-[16px] py-[15px]`}
                                    // onClick={() => setIntegrateWithCustomBusiness(true)}
                                    onClick={() => {setIntegrateWithCustomBusiness(true); setPurpose('Custom'); setAddWanoNumber(false)}}
                                >
                                    <div className='flex  gap-2'>
                                        <FiPhone className='w-[13px] h-[13px] text-[#7F7F81] mt-1' />
                                        <p className='font-semibold text-[#121212] text-left text-sm'>Integrate with Custom Business Number</p>
                                    </div>
                                    <div>
                                        <p className='text-[#7F7F81] text-left text-xs'>
                                            Peer with your custom business number. Please note that it’ll take 24 hours to become active
                                        </p>
                                    </div>
                                    
                                    
                                </button>
                                )}
                            </Menu.Item>
                        </div>
                    
                    </Menu.Items>
                    </Transition>
                </Menu>
            </div> */}
import React from 'react'
import Icon from '../Assets/🦆 icon _bank_.png'
import { UserContext } from "../context/UserContext";
import { useContext } from "react";
import { Radio } from "@mui/material";
import { BsCheck } from "react-icons/bs";

export default function UseWanoItems({
  header,
  text,
  checkedItems,
  handleItemCheckedChange,
  Suitable,
}) {
  const style = "rounded-[12px] border-solid border-[1.5px] p-5";
  const { userType } = useContext(UserContext);
  const active =
    "bg-[rgba(205,222,248,0.20)] border-[#1774FD] rounded-[12px] border-solid border-[1.5px] p-5";

    const CheckedIcon = () => {
      return (
        <div className="w-5 h-5 bg-[#1774FD] rounded-[999px] flex justify-center items-center">
          <BsCheck className="text-white w-5 h-5" />
        </div>
      );
    };

  return (
    <div
      className={` cursor-pointer ${checkedItems.includes(header) ? active : style} `}
      onClick={() => handleItemCheckedChange(header)} 
    >
      <div className="flex justify-between mb-[8px]">
        <div className="flex gap-[15px] items-center justify-center">
          <div className="border border-[#E5E5E5] rounded-[8px] w-[40px] h-[40px] grid place-items-center">
            <img className="w-[16px] h-[14.48px] " src={Icon} alt="icon" />
          </div>
          <p className="text-[18px] font-medium">{header}</p>
          {header === "Customer Service" && (
            <p className=" text-xs text-[#1774FD] font-bold">Beta</p>
          )}
        </div>

        {/* <button
          className={`w-6 h-6 rounded-full ${checked === header ? 'bg-[#1774FD] text-white' : 'bg-white border border-[#E5E5E5]'}`}
        >
          {checked === header && <span className="text-sm font-bold">&#10003;</span>}
        </button> */}
        <Radio
          sx={{
            color: "#EBEBEB",
            height: 20,
            width: 20,
            border: "1px",
            background: "#fff",
          }}
          checkedIcon={<CheckedIcon />}
          checked={checkedItems.includes(header)}
          // checked={checked === header}
          name="radio-buttons"
          inputProps={{ "aria-label": "A" }}
        />
      </div>
      <div className="flex flex-col gap-4">
        <p className="text-[14px] text-[#7F7F81]">{text}</p>
        <span className="text-[#1774FD] text-[14px] font-semibold">
        {Suitable}
        </span>
      </div>
    </div>
  );
}


import React, { useState, useRef } from "react";

export default function Select({ onChange, options, label }) {
  const [display, setDisplay] = useState(false);
  const [labels, setLabels] = useState("");

  const handleSelect = (selectedLabel) => {
    const selectedOption = options.find(
      (option) => option.label === selectedLabel
    );

    if (selectedOption) {
      setLabels(selectedLabel);
    }
    onChange(selectedLabel);
    setDisplay(false);
  };
  const divRef = useRef(null);

  return (
    <div
      className="relative"
      ref={divRef}
      onBlur={() => setDisplay(false)}
      tabIndex={0}
    >
      <p>{label}</p>
      <div
        onClick={() => {
          setDisplay(!display);
        }}
        className="relative w-full cursor-pointer py-[10px] px-[14px] h-9 rounded-lg border border-[#D0D5DD] bg-white focus:outline-none"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
      >
        <span className="flex items-center">
          <span className="text-xs text-[#101828] font-figtree block truncate">
            {labels}
          </span>
        </span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="6"
            viewBox="0 0 9 6"
            fill="none"
          >
            <path
              d="M8.0013 1.3125L4.6237 4.6901L1.24609 1.3125"
              stroke="#121212"
              stroke-width="1.08566"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
      {!!display && (
        <ul
          className={`absolute z-10 mt-1 w-full overflow-auto rounded-lg bg-white text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
            display
              ? "opacity-100 transition ease-in duration-100"
              : "opacity-0 h-0 transition ease-in duration-100"
          }`}
        >
          {!!options &&
            options.map((option) => {
              return (
                <li
                  onClick={(e) => {
                    handleSelect(option.label);
                  }}
                  className={`text-[#101828] relative cursor-default select-none py-[10px] px-[14px] 
                
                ${labels === option.label ? "bg-[#CDDEF833]" : ""}
                `}
                  id="listbox-option-0"
                  role="option"
                >
                  <div className="flex items-center cursor-pointer">
                    <span className="font-figtree block truncate">
                      {option.label}
                    </span>
                  </div>
                  {labels === option.label && (
                    <span className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-4">
                      <svg
                        width="16"
                        height="11"
                        viewBox="0 0 16 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.6666 1L5.49992 10.1667L1.33325 6"
                          stroke="#1774FD"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  )}
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
}

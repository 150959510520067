// AtInput.js
import React, { useState, useRef, useEffect, forwardRef, useLayoutEffect } from 'react';
import { createPopper } from '@popperjs/core';
import { useDispatch, useSelector } from 'react-redux';
import ModalPop from '@/../component/modalPop';
import NotifyToInstallAppModal from './NotifyToInstallAppModal';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import getCaretCoordinates from 'textarea-caret';
import { getAllPlugins } from '@/../features/plugins/getAllPluginsSlice';
import { set } from 'immutable';

const AtInput3 = forwardRef(({value, onChange, placeholder, setter, className, type="textarea", tags, setTags, ...restprops}, ref) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const textareaRef = useRef(null);
    const dropdownRef = useRef(null);
    const [inputValue, setInputValue] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [filteredApps, setFilteredApps] = useState([]);
    // const [cursorPosition, setCursorPosition] = useState(null);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const [enough, setEnough] = useState(false)
    // const inputRef = useRef(null);
    // const mirrorRef = useRef(null);
    // const dispatch = useDispatch()
    // const appId = JSON.parse(localStorage.getItem('wano_agent'))?.id;


    const LightTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
        ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: '#A7A7A7',
            boxShadow: theme.shadows[1],
            fontSize: 11,
            zIndex: 1000000,
        },
    }));
    // #F2F4F7
    const data  = useSelector(state => state.getAllPlugins.data)
    const active = data?.data?.active || []
    const available = data?.data?.available || []

    //useSelector(state => state.getAllPlugins.data.data.available);
    // const { active = {}, available = {} } = useSelector(state => ({
    //     active: state.getAllPlugins.data.data.active,
    //     available: state.getAllPlugins.data.data.available,
    // }));

    useEffect(() => {
        if (showDropdown) {
            const searchTerm = inputValue.split('@').pop();
            const filtered = [...active, ...available]
                .filter(app => app.name.toLowerCase().includes(searchTerm.toLowerCase()));
            setFilteredApps(filtered);
        }
    }, [inputValue, showDropdown, active, available]);

    useLayoutEffect(() => {
        if (showDropdown) {
            updateDropdownPosition();
        }
    }, [showDropdown]);

    const handleChange = (e) => {
        const { value } = e.target;
        setInputValue(value);
        // setter(value)
        // if (value.trim() === "") {
        //     setTags([]);
        //     setEnough(false);
        //     setShowDropdown(false);
        //     return;
        // }
        // onChange(e)
        // setShowDropdown(value.endsWith('@'));
        // extractMentions(value);
        onChange(e);
        setShowDropdown(value.endsWith('@'));
        const mentionsExist = value.includes('@');
        // const standaloneAt = value.match(/@\b/g);

  
        if (!mentionsExist) { //value.trim() === ""
            setTags([]);
            setEnough(false);
        } else {
            extractMentions(value);
        }
    };

    const extractMentions = value => {
        const matches = value.match(/@\w+/g);
        if (matches && matches.length > 0) {
            const appMentions = matches.map(mention => mention.substring(1));
            const appTags = appMentions
                .map(mention => available.concat(active).find(app => tag(app.name) === mention))
                .filter(app => app)
                .map(app => ({ applicationPluginId: app.applicationPlugins[0].id, slug: app.app_tag_slug }));
            
            if (appTags.length > 0) {
                setTags(appTags);
                setEnough(appTags.length >= 1);
            }
        }
    };

    // useEffect(() => {
    //     if (textareaRef.current) {
    //         textareaRef.current.focus();
    //         const length = inputValue.length;
    //         textareaRef.current.setSelectionRange(length, length);
    //     }
    // }, [inputValue]);
    
    const tag = (name) => {
        switch (name) {
            case 'Slack': return "slack";
            case "Language Translator": return "languagetranslator";
            case "Lead Generation": return "Hubspot";
            case "Calendly": return "calendly";
            default: return name;
          }
    }

    const handleAppClick = (app, isInstalled) => {
        // console.log(app, "tags");
        if(enough) return;
        if (isInstalled) {
            const lastAtIdx = inputValue.lastIndexOf('@');
            let newInputValue;
            if (lastAtIdx !== -1) {
                const beforeAt = inputValue.substring(0, lastAtIdx);
                newInputValue = `${beforeAt}@${tag(app.name)} `;
                setInputValue(newInputValue);
                onChange({ target: { value: newInputValue } });
                if (!tags.some(tag => tag?.slug === app?.app_tag_slug)) {
                    setTags([...tags, {applicationPluginId: app?.applicationPlugins[0]?.id, slug: app?.app_tag_slug}]); //app.id
                    setEnough(true)
                }
                // setter(newInputValue)
            }
            setShowDropdown(false);
            setTimeout(() => {
                const length = newInputValue.length;
                textareaRef.current.setSelectionRange(length, length);
                textareaRef.current.focus();
            }, 0);
        } else {
            setShowModal(true);
        }
    };

    const updateDropdownPosition = () => {
        const position = textareaRef.current.value.lastIndexOf('@');
        if (position !== -1) {
            // const coords = getCaretCoordinate(textareaRef.current, position);
            const { top, height, left } = textareaRef.current ? getCaretCoordinates(textareaRef.current, textareaRef.current?.selectionEnd) : { top: 0, height: 0 };
            // console.log(restprops, "the coordinates");
            setDropdownPosition({
                // top: coords.top + window.scrollY + 50, // -80
                top: top + height,
                left: left //coords.left + 10
            });
        }
    };

    const renderApp = (app, isInstalled) => {
        const tooltipText = enough ? "You can currently only tag one app per instruction or message" : app.description;

        return (
            <LightTooltip
                key={app.id}
                title={tooltipText}
                arrow
                TransitionComponent={Zoom}
                placement="right"
                sx={{
                    '& .MuiTooltip-arrow': {
                        color: '#fff',
                        dropShadow: "0 2px 10px rgba(200, 200, 200, 0.03)",
                        // transform: 'translateY(-6px)',
                    },
                    zIndex: 1000000,
                }}
                slotProps={{
                    popper: {
                    modifiers: [
                        {
                        name: 'offset',
                        options: {
                            offset: [0, 10],
                        },
                        },
                    ],
                    },
                }}
            >
                <div
                    className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
                    style={{ padding: '5px', cursor: 'pointer', opacity: isInstalled ? enough? "0.4": "1": "0.4" }}
                    onClick={() => handleAppClick(app, isInstalled)}
                >
                    <img src={app.image_url} alt={app.name} className='w-4 h-4 rounded-[2.26px]'/> {app.name}
                </div>
            </LightTooltip>
        );
    };

    const InputComponent = type === "textarea" ? "textarea" : "input";

    return (
        <div style={{ position: 'relative' }}>
            <InputComponent
                ref={textareaRef}
                value={value || inputValue}
                onChange={handleChange}
                className={className}
                placeholder={placeholder}
                {...restprops}
            />
            {showDropdown && (
                // <div ref={dropdownRef} className='z-[3] w-full'>
                    <Dropdown top={`${dropdownPosition.top}px`} left={dropdownPosition.left} position="absolute">
                        {/* {enough ?  */}
                            <div className='flex flex-col gap-3'>
                                <p className='text-[#7F7F81] font-medium text-xs px-2 pb-2'>Installed Apps</p>
                                {filteredApps.filter(app => active.includes(app)).map(app => renderApp(app, true))}
        
                                <p className='text-[#7F7F81] font-medium text-xs p-2'>Uninstalled Apps</p>
                                {filteredApps.filter(app => !active.includes(app)).map(app => renderApp(app, false))}
                            </div>
                             {/* :
                            filteredApps.map((app) => { return (renderApp(app, active.includes(app)))}) */}
                            
                        {/* } */}
                    </Dropdown>
                // </div>
            )}
            <ModalPop className isOpen={showModal} closeModal={() => setShowModal(false)}>
                <NotifyToInstallAppModal handleClose={() => setShowModal(false)} />
            </ModalPop>
        </div>
    );
});

export default AtInput3;


export const Dropdown = forwardRef(({ children, top, left, position }, ref) => {
    return (
        <div 
            className='w-fit overflow-hidden bg-white rounded-md drop-shadow-md z-[3]'
            style={{position: position, top: top, left: left}}
        >
            <div className="flex flex-col bg-white hide-scrollbar overflow-y-scroll gap-3 p-3 w-fit h-[180px] cursor-pointer z-[1]">
                {children}
            </div>
        </div>
    );
});


//{/* <div className='flex flex-col gap-2'> */}
{/* <p className='text-[#7F7F81] font-medium text-xs px-2 pb-2'>Installed apps</p> */}

// const updateDropdownPosition = () => {
    //     const position = textareaRef.current.value.lastIndexOf('@');
    //     if (position !== -1) {
    //         const coords = getCaretCoordinates(textareaRef.current, position);
    //         setDropdownPosition({ top: coords.top + 20, left: coords.left }); // 20px for a little space below the line
    //     }
    // };

    // //get the cursor position in the textarea
    // function getCaretCoordinates(element, position) {
    //     const { offsetLeft: inputX, offsetTop: inputY } = element;
    //     let div = document.createElement('div');
    //     document.body.appendChild(div);

    //     const style = div.style;
    //     const computed = getComputedStyle(element);
    //     style.whiteSpace = 'pre-wrap';
    //     style.wordWrap = 'break-word';
    //     style.position = 'absolute';
    //     style.visibility = 'hidden';
    //     style.font = computed.font;
    //     style.width = element.scrollWidth + 'px';
    //     div.textContent = element.value.substr(0, position);

    //     const span = document.createElement('span');
    //     span.textContent = element.value.substr(position) || '.';
    //     div.appendChild(span);

    //     const rect = span.getBoundingClientRect();
    //     const { top, left } = rect;
    //     div.remove();
    //     return { top: top / 16 + inputY , left: inputX + left };  //{ top: inputY + top, left: inputX + left }; original or top: inputY
    // }

/// solves the issue can come back to this
    // function getCaretCoordinate(element, position) {
    //     const { offsetLeft: inputX, offsetTop: inputY } = element;
    //     const div = document.createElement('div');
    //     document.body.appendChild(div);
    
    //     const style = div.style;
    //     const computed = getComputedStyle(element);
    
    //     style.whiteSpace = 'pre-wrap';
    //     style.wordWrap = 'break-word';
    //     style.position = 'absolute';
    //     style.visibility = 'hidden';
    //     style.font = computed.font;
    //     style.width = element.offsetWidth + 'px'; 
    
    //     // Insert the text and a marker element at the position
    //     div.textContent = element.value.substr(0, position);
    //     const span = document.createElement('span');
    //     span.textContent = element.value.substr(position) || '.';
    //     div.appendChild(span);
    
    //     const rect = span.getBoundingClientRect();
    //     const { top, left } = rect;
    //     const textareaRect = element.getBoundingClientRect();
    //     div.remove();
    
    //     return {
    //         top: rect.top - textareaRect.top + element.scrollTop, 
    //         left: inputX + left 
    //     }; //rect.left - textareaRect.left
    // }

    //original
    // filteredApps.map(app => {
        //     return (
        //     <LightTooltip 
        //         key={app.id} 
        //         title={app.description} 
        //         arrow 
        //         TransitionComponent={Zoom}
        //         placement="right"
        //         sx={{
        //             '& .MuiTooltip-arrow': {
        //                 color: '#fff',
        //                 dropShadow: "0 2px 10px rgba(200, 200, 200, 0.03)",
        //                 // transform: 'translateY(-6px)',
        //             },
        //             zIndex: 1000000,
        //         }}
        //         slotProps={{
        //             popper: {
        //             modifiers: [
        //                 {
        //                 name: 'offset',
        //                 options: {
        //                     offset: [0, 10],
        //                 },
        //                 },
        //             ],
        //             },
        //         }}
        //     >
        //         <div
        //             key={app.id}
        //             onClick={() => handleAppClick(app, active.includes(app))}
        //             className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
        //             style={{
        //                 padding: '5px',
        //                 cursor: 'pointer',
        //                 opacity: active.includes(app) ? "1": "0.4",
        //             }}
        //         >
        //             <img src={app.image_url} alt={app.name} className='w-4 h-4 rounded-[2.26px]'/> {app.name}
        //         </div>
        //     </LightTooltip>
        // )})
import { useSelector } from "react-redux";
import TokenService from "../../services/token";
import axios from "axios";
import { useState } from "react";
import { CgSpinner } from "react-icons/cg";
import {
  SuccessMessage,
  ErrorMessage,
} from "../../Toast-Messages/Toastmessages";
import { useNavigate } from "react-router";

export default function DeleteAppModal({ handleClose,justCloseModal, fetchData, appID }) {
  const navigate = useNavigate()
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();
  // const appDetail = useSelector((state) => state.getApplicationByUserId);
  // const data = appDetail?.data?.data;
  // const agentData = localStorage.getItem("wano_agent");
  // const agent = data && data.length >= 1 ? data[0] : {};
  // const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  // console.log(agentDetails)
  // const applicationId = agentDetails.id;
  // console.log(appID)
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
//changed the applicationID to appID passed from the settings page
      await axios.delete(`${URL}/dashboard/applications/${appID}`, {
        headers,
      });

      setSuccess("Application deleted successfully!");
      setIsLoading(false);
      setTimeout(() => {
        navigate('/dashboard?switchEmployee=true')
      }, 1000)
      handleClose();

      // setTimeout(() => {
      //   setSuccess(false);
      //   handleClose();
      //   // localStorage.clear()
      // }, 2500);
    } catch (error) {
      setError("Error deleting application");
      // setTimeout(() => {
      //   setError(false);
      // }, 2500);
    }
    finally{
      setIsLoading(false);

    }
  };
  return (
      <div className=" w-[496px] h-[260px] bg-white mt-32 ml-6 rounded-lg shadow-makeReqModal font-figtree py-[30px] px-[50px]">
        <h2 className=" text-2xl font-medium text-[#101828] mb-12 text-center">
          Delete Assistant
        </h2>
        <p className=" text-center text-sm font-medium text-[#828282]">
          Are you sure you want to delete this Assistant? Doing this removes the Assistant
          from your business permanently.
        </p>
        <div className=" flex gap-[24px] mt-12">
          <button
            onClick={justCloseModal}
            className=" border border-[#D0D5DD] w-full py-2 rounded-lg text-sm font-semibold text-[#7F7f81]"
          >
            Cancel
          </button>
          <button
            disabled={isLoading}
            onClick={handleDelete}
            className="w-full py-2 rounded-lg bg-[#AF202D] text-sm text-white font-bold"
          >
            {isLoading ? (
              <span className="flex justify-center w-full">
                <CgSpinner className=" animate-spin text-lg text-WHITE-_100" />
              </span>
            ) : (
              <p className="text-WHITE-_100 text-[12px] font-medium">Delete</p>
            )}
          </button>
        </div>
        {success && <SuccessMessage success={success} />}
        {error && <ErrorMessage error={error} />}
      </div>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from "../../services/api"

const initialState = {
    loading: false,
    data: [],
    error: ""
};

export const rateBusinessAskAgentChat = createAsyncThunk(
    "rateAgentChat/rateBusinessAskAgentChat", 
    async({data, appId, chatId, messageId}, { rejectWithValue }) => {
        try {
            const res = await api.post(`/dashboard/applications/${appId}/chat/${chatId}/conversations/${messageId}/rating`, data);
            return res?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)

const rateBusinessAskAgentChatSlice = createSlice({
    name: "rateAgentChat",
    initialState,
    extraReducers: builder => {
        builder.addCase(rateBusinessAskAgentChat.pending, state => {
            state.loading = true
        });
        builder.addCase(rateBusinessAskAgentChat.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(rateBusinessAskAgentChat.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default rateBusinessAskAgentChatSlice.reducer
import {
  MinimizeIcon,
  RefreshIcon,
  SendMessageIcon,
  TestAgentIcon,
} from "../../Assets/svg";
import "animate.css/animate.min.css";
import { marked } from "marked";
import { MdVerified } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { fetchWalletBalance } from "../../features/wallet/walletBalanceSlice";
import React, { useEffect, useState, useRef } from "react";
import TokenService from "../../services/token";
import axios from "axios";
import AiLogo from "../../Assets/svg/dashboardStar.svg";
import SterlingLogo from "../../Assets/svg/dashboardStar.svg";
import Loader from "../../Assets/gif/loadingAnimation.gif";
import { CgSpinner } from "react-icons/cg";
import newTestLogo from "../../Assets/img/newTestLogo.svg";
import newLogo from "../../Assets/svg/wanosvg.svg";
import backArrow from "../../Assets/svg/backIcon.svg";
import smile from "../../Assets/svg/smile.svg";
import whitePlane from "../../Assets/svg/planeWhite.svg";

import { setChatID, setPreviousAppID } from "@/../features/testAgent/chatSlice";
import { useNavigate } from "react-router";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm"; // For GitHub-flavored markdown like tables, strikethrough, etc.
import rehypeRaw from "rehype-raw"; // To allow rendering HTML in markdown

export default function TestAgentPop({
  handleClose,
  isMinimizing,
  alldata,
  activeButton,
  setActiveButton,
  gotoMemory,
}) {
  const dispatch = useDispatch();
  const [titleM, setTitleM] = useState("");
  const [title, setTitle] = useState("");
  // const [chatID, setChatID] = useState("");
  const [error, setError] = useState(false);
  const walletBalance = useSelector((state) => state.walletBalance);
  const { tokens } = walletBalance.data;
  let num = Math.floor(tokens).toFixed(2);
  let convertedCredit = num.toLocaleString("en-US");
  const token = TokenService.getToken();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const data = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = data && data.length >= 1 ? data[0] : {};
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const agentDetails = getAgentApplication?.agent || null;
  const applicationId = agentDetails.id;
  const URL = process.env.REACT_APP_BASEURL;
  const [fetchedMessage, setFetchedMessage] = useState([]);
  const [typing, setTyping] = useState(false);

  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.userLogin);
  const scrollContainerRef = useRef(null);
  const lastMessageRef = useRef(null);

  const chatID = useSelector((state) => state.chat.chatID); //used to get current chatID
  const memoryExists = useSelector((state) => state.chat.memoryExists); //checks if there is any uploaded memory
  const creditObject = useSelector(
    (state) => state.getGiftBalance.creditBalance
  );
  // console.log(creditObject, "creditobject")
  const creditsLeft = creditObject?.balance || 0;
  // console.log(activeButton, "this is the activeButton")
  // console.log(alldata, "tag image data")

  useEffect(() => {
    // if (scrollContainerRef.current) {
    //   scrollContainerRef.current.scrollTop =
    //     scrollContainerRef.current.scrollHeight;
    // }
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [fetchedMessage, loading]);

  useEffect(() => {
    if (loading) {
      setTyping(true);
      const timeOutId = setTimeout(() => {
        setTyping(false);
      }, 5000); //changed from 10000 to 5000

      return () => clearTimeout(timeOutId);
    }
  }, [loading]); //removed the typing dependency

  useEffect(() => {
    dispatch(fetchWalletBalance());
  }, []);

  const fetchMessageData = async () => {
    if (chatID.length == 0) {
      const response = await axios.post(
        `${URL}/dashboard/applications/${applicationId}/chat/create-employee`,
        {
          title: "Testing Assistant",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setChatID(response.data.data.id)
      dispatch(setPreviousAppID(applicationId)); //monitors the current employee in use and stores it
      dispatch(setChatID(response.data.data.id));
    }

    if (!applicationId) {
      console.error(
        "No applicationId provided. fetchData function will not run."
      );
      return;
    }

    try {
      axios
        .get(
          `${URL}/dashboard/applications/${applicationId}/chat/${chatID}/conversations`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setFetchedMessage(response.data.data.reverse());
          // setFetchedMessage([...fetchedMessage, ...response.data.data.reverse()]);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    } catch (error) {
      console.error("Error making request:", error);
    }
  };

  useEffect(() => {
    fetchMessageData();
  }, [chatID]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    // Update chat immediately with the user's message
    const newMessage = {
      role: "user",
      message: titleM,
    };
    setFetchedMessage((prevMessages) => [...prevMessages, newMessage]);

    setError(false);
    setTitleM("");
    setLoading(true);
    if (!chatID) await fetchMessageData();
    try {
      const type = agentDetails.type;
      const response = await axios.post(
        `${URL}/dashboard/applications/${applicationId}/chat/${chatID}/conversations/send-test-employee`,
        {
          message: titleM || title,
          assistant_type: type,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming fetchMessageData() is a function that fetches and updates the chat from the backend
      fetchMessageData();
    } catch (error) {
      setError(true);
      console.log("Message not sent", error);
      // Optionally, you can remove the user's message from the chat if the backend request fails.
      setFetchedMessage((prevMessages) =>
        prevMessages.filter((msg) => msg.message !== titleM)
      );
    } finally {
      setLoading(false);
    }
  };
  const renderMarkdown = (text) => {
    const markdown = text || "";
    const __html = marked(markdown, { sanitize: true });
    console.log("stuff is", text);
    return { __html };
  };
  const [showStartChat, setShowStartChat] = useState(true);
  return (
    <div className="fixed inset-0 flex items-center justify-center z-40">
      <div className="bg-black bg-opacity-50 h-screen w-screen fixed top-0 left-0"></div>
      <section
        className={`h-[90vh] p-6 bg-[#FAFAFA] fixed w-[415px] right-[26px] bottom-10 z-50 rounded-lg flex flex-col  ${
          isMinimizing
            ? "animate__animated animate__slideOutDown"
            : "animate__animated animate__slideInUp"
        }`}
      >
        <header
          className={`flex  ${
            showStartChat ? " justify-end " : " justify-between "
          }`}
        >
          {!showStartChat && (
            <div
              className="flex gap-2 cursor-pointer"
              onClick={() => {
                setFetchedMessage([]);
                setError(false);
                dispatch(setChatID(""));
              }}
            >
              {" "}
              {/*  setChatID('') */}
              <RefreshIcon />
              <p className=" text-sm font-semibold text-[#0359D8]">
                Refresh Assistant
              </p>
            </div>
          )}

          <div className="flex gap-2 cursor-pointer" onClick={handleClose}>
            <p className=" text-[#121212] font-semibold text-sm">Minimize</p>
            <span className=" -mt-3">
              <MinimizeIcon />
            </span>
          </div>
        </header>
        <aside className="px-4 pt-2 bottom-10 rounded-[8px] bg-white w-[367px] shadow-sm h-[71.5px] my-[12.5px]">
          <div className="flex gap-3">
            <section className=" mt-4">
              <div className="flex items-end">
                <img src={newTestLogo} alt="sales" className="w-5 h-5" />
                <p className=" text-[#1774FD] text-[5.163px] -ml-1">AI</p>
              </div>
            </section>
            <section>
              <p className=" text-[15px] font-medium text-[#121212]">
                Test {alldata?.name}
              </p>
              <p className=" text-xs font-normal text-[#7F7F81]">
                Test your assistant to see how it’ll interact with your
                customers. You have{" "}
                <span className="text-[#121212]">${creditsLeft} </span>
                credits left
              </p>
            </section>
          </div>
        </aside>
        <section className="flex-1 bg-white border shadow-sm rounded-lg  pb-1">
          <div className="h-full flex flex-col">
            {memoryExists ? (
              <>
                {/* <header className="bg-[#085B53] py-4 px-[30px] rounded-t-md flex gap-1 items-center">
                  <img
                    src={alldata?.icon_url || AiLogo}
                    alt=""
                    className={`w-8 h-8 ${alldata?.icon_url && "rounded-full"}`}
                  />
                  <p className=" text-white font-sans font-bold select-none ml-[15px] capitalize">
                    {alldata?.name} AI
                  </p>
                  <MdVerified className="text-[#00E25C]" />
                </header> */}

                <div className="flex-1 flex flex-col">
                  <div className="flex p-[7%] cursor-pointer">
                    {!showStartChat && (
                      <img
                        src={backArrow}
                        alt="sales"
                        className="w-2.5 h-2.5 "
                        onClick={() => setShowStartChat((prev) => !prev)}
                      />
                    )}
                  </div>
                  {showStartChat ? (
                    <div className=" h-full p-[7%] flex flex-col gap-3 items-center justify-center">
                      <div className="flex justify-center">
                        <img src={smile} alt="sales" className="w-10 h-10" />
                      </div>

                      <p className="text-center text-base">
                        I’m {agentDetails.name} 👋
                      </p>
                      <p className="text-xs text-center">
                        I’m here to answer any questions you have. Let’s start a
                        conversation
                      </p>
                      <button
                        onClick={() => {
                          // setActiveButton("Memory");
                          setFetchedMessage([]);
                          setError(false);
                          dispatch(setChatID(""));
                          setShowStartChat(false);
                        }}
                        className="flex gap-1 items-center w-full justify-center px-4 py-3 rounded-lg bg-[#1774FD]"
                      >
                        <p className="text-[#ffffff] text-center text-xs font-semibold leading-[14.4px]">
                          Start New Conversation
                        </p>
                        <img
                          src={whitePlane}
                          alt="sales"
                          className="w-2.3 h-3"
                        />
                      </button>
                    </div>
                  ) : (
                    <>
                      <div
                        ref={scrollContainerRef}
                        className="overflow-y-auto child p-[7%]"
                        style={{
                          height: 'calc(90vh - 350px)'
                        }}
                      >
                        {fetchedMessage.map((data, index) => (
                          <div
                            key={index}
                            ref={
                              index === fetchedMessage.length - 1
                                ? lastMessageRef
                                : null
                            }
                          >
                            {data.role === "user" ? (
                              <div className="flex  justify-end gap-2 p-2">
                                {/* <div className="gap-[15px] flex items-center">
                              <div className="w-8 h-8 rounded-[28px] bg-[#1774FD] flex justify-center items-center p-[10px]">
                                <p className="text-[16px] font-bold text-white capitalize">
                                  {user.data.user.first_name.charAt(0)}
                                </p>
                              </div>
                              <p className="text-sm text-[#121212] font-semibold">
                                You
                              </p>
                            </div> */}
                                <p className="rounded-[32px]  py-2 px-3 bg-[#EDEDED] text-[#121212] text-xs font-normal">
                                  {data?.message}
                                </p>
                              </div>
                            ) : (
                              <div className="bg-white rounded-[12px] p-2 flex flex-col gap-[15px] mb-[20px]">
                                <div className="gap-2 flex flex-col">
                                  <div className="gap-2 flex items-center">
                                    <img
                                      className={`${
                                        data.application.icon_url &&
                                        "rounded-full"
                                      } w-8 h-8`}
                                      src={data.application.icon_url || AiLogo}
                                      alt="assistant"
                                    />
                                    <p className="text-sm font-semibold text-[#121212] capitalize">
                                      {agentDetails.name} AI
                                    </p>
                                  </div>
                                  <div className="rounded-[12px] text-[#121212] text-xs font-normal chatbox">
                                    <Markdown
                                      remarkPlugins={[remarkGfm]}
                                      rehypePlugins={[rehypeRaw]}
                                      className="prose prose-sm prose-indigo"
                                    >
                                      {data?.message}
                                    </Markdown>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}

                        {loading &&
                          (typing ? (
                            <div className="-mt-0 w-full h-[70px] bg-white ">
                              <img
                                src={Loader}
                                alt="Loading..."
                                className="h-full object-contain mix-blend-darken"
                              />
                            </div>
                          ) : (
                            <div className="bg-white rounded-[12px] p-2 flex flex-col gap-[15px]">
                              <div className="gap-2 flex flex-col">
                                <div className="gap-2 flex items-center">
                                  <img
                                    className={`${
                                      agentDetails.icon_url && "rounded-full"
                                    } w-8 h-8`}
                                    src={agentDetails.icon_url || AiLogo}
                                    alt="assistant"
                                  />
                                  <p className="text-sm font-semibold text-[#121212] capitalize">
                                    {agentDetails.name} AI
                                  </p>
                                </div>
                                <div className="flex flex-col">
                                  <div className="-mt-5 w-full h-[70px]">
                                    <img
                                      src={Loader}
                                      alt="Loading..."
                                      className="h-full object-contain mix-blend-darken"
                                    />
                                  </div>
                                  {/* {loadingExceeded && (
                                <p className="text-sm font-normal text-[#828282]">Please hold on, I’m thinking...</p>
                              )} */}
                                  <p className="text-sm font-normal text-[#828282]">
                                    Please hold on, I’m thinking...
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}

                        {error && (
                          <div className="bg-white rounded-[12px] p-2 flex flex-col gap-[15px]">
                            <div className="gap-2 flex flex-col">
                              <div className="gap-2 flex items-center">
                                <img
                                  className={`w-8 h-8 ${
                                    alldata.icon_url && "rounded-full"
                                  }`}
                                  src={agentDetails.icon_url || AiLogo}
                                  alt="assistant"
                                />
                                <p className="text-sm font-semibold text-[#121212] capitalize">
                                  {agentDetails.name} AI
                                </p>
                              </div>
                              <div className="flex flex-col gap-3">
                                <p className="text-sm font-normal text-[#828282]">
                                  There was a problem somewhere, try again to
                                  regenerate a response.
                                </p>
                                <div
                                  onClick={handleSendMessage}
                                  className="w-[92px] flex justify-center items-center h-[37px] rounded-lg border border-[#EEE] bg-[#FAFAFA] cursor-pointer"
                                >
                                  <p className="text-sm font-medium text-[#AF202D]">
                                    Try Again
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div
                        className="flex-1 relative px-6 py-2"
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          flex: 0,
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Enter a message"
                          value={titleM}
                          onChange={(e) => {
                            setTitleM(e.target.value);
                            setTitle(e.target.value);
                          }}
                          className="placeholder:text-[#A6ADB5] w-full outline-none border mb-3 pr-10 rounded-3xl"
                          onKeyPress={handleKeyPress}
                        />
                        <span
                          className=" absolute right-12 top-3 cursor-pointer"
                          onClick={handleSendMessage}
                          style={{ position: "absolute", marginTop: "2%" }}
                        >
                          {loading ? (
                            <CgSpinner className=" animate-spin text-lg text-BLACK-_100" />
                          ) : (
                            <SendMessageIcon />
                          )}
                        </span>
                      </div>
                    </>
                  )}

                  <p className="flex items-center mt-2 text-xs gap-1 justify-center text-[#828282]">
                    Powered by
                    <img src={newLogo} alt="sales" className="w-3 h-3" />
                    <span className="text-[#1774FD]">wano</span>
                  </p>
                </div>
              </>
            ) : (
              <SetupEmployee
                handleClose={handleClose}
                setActiveButton={setActiveButton}
                alldata={alldata}
                gotoMemory={gotoMemory}
              />
            )}
          </div>
        </section>
      </section>
    </div>
  );
}

export const SetupEmployee = ({
  handleClose,
  setActiveButton,
  gotoMemory,
  alldata,
}) => {
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const agentDetails = getAgentApplication?.agent || null;
  const navigate = useNavigate();
  return (
    <>
      <header className="bg-[#085B53] py-4 px-[30px] rounded-t-md flex gap-1 items-center">
        <img
          src={alldata?.icon_url || AiLogo}
          alt=""
          className={`w-8 h-8 ${SterlingLogo && "rounded-full"}`}
        />
        <p className=" text-white font-sans font-bold select-none ml-[15px] capitalize">
          {agentDetails.name} AI
        </p>
        <MdVerified className="text-[#00E25C]" />
      </header>
      <div className="w-full h-full flex flex-col gap-9 items-center justify-center">
        <div className="flex flex-col gap-3 px-[23.5px]">
          <h1 className="text-sm font-semibold text-[#121212] text-center">
            Upload Documents
          </h1>
          <p className="text-xs font-normal leading-6 text-[#828282] text-center">
            Your assistant requires important documents like: About, FAQs,
            Services and Support in it’s memory before you can start testing it.
            Click the button below to upload.
          </p>
        </div>
        <button
          onClick={() => {
            // setActiveButton("Memory");
            navigate("/agent");
            handleClose();
            gotoMemory && gotoMemory();
          }}
          className="px-4 py-2 rounded-lg bg-[#121212]"
        >
          <p className="text-[#ffffff] text-xs font-semibold leading-[14.4px]">
            Setup Assistant
          </p>
        </button>
      </div>
    </>
  );
};

import React, { useState } from "react";
import Whatsapp from "../../../../Assets/svg/whatsApp.svg";
import BigPlus from "../../../../Assets/svg/BigPlus.svg";
import Stars from "../../../../Assets/svg/Stars.svg";
import Button from "../../../Button";
import { AiOutlineClose } from "react-icons/ai";

const Peer = ({ handleClose, propData,handleDisconnect }) => {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options);
  };
  return (
    <div
      className={`w-[580px] h-fit  bg-WHITE-_100  mt-[60px] flex flex-col justify-center items-center rounded-lg py-3`}
    >
      <div className="w-full flex items-center justify-end px-[28px] py-[14px] border-b border-neutral-150">
        {/* <p className='text-lg font-medium text-[#101828]'>Setup App</p> */}
        <div
          className="w-[36px] h-[36px] border rounded-lg mr-3  flex cursor-pointer justify-center items-center p-2"
          onClick={handleClose}
        >
          <AiOutlineClose className="text-neutral-500" />
        </div>
      </div>
      <div
        className={`w-[430px] flex flex-col gap-[12px] justify-center items-center`}
      >
        <div className="flex justify-between w-[106.48px] h-[40.32px] mt-10">
          <img
            src={Whatsapp}
            alt="whatsapp_icon"
            className="w-[40.32px] h-[40.32px]"
          />
          <img src={BigPlus} alt="plus icon" className="" />
          <img src={Stars} alt="Star icon" className="" />
        </div>
        <p className="text-2xl font-medium text-BLACK-_600">Whatsapp</p>
      </div>
      <div className="px-8 w-full">
        <div className="mt-10 bg-[#F9F9F9] p-3 w-full rounded-lg">
          <div className="bg-white rounded-lg py-5 px-7 text-sm flex flex-col gap-9">
            <div className="flex justify-between w-full">
              <p className="font-medium ">Phone Number</p>
              <p className="text-BLACK-_200 "> {propData.phone_number}</p>
            </div>
            <div className="flex justify-between w-full">
              <p className="font-medium ">Status</p>
              <div className="text-BLACK-_200 rounded-lg flex items-center gap-1 bg-[#DFF8E74D]">
                <div
                  className={` h-1 w-1 rounded-full 
                
                ${
                  propData.setup_status === "integrating"
                    ? "  bg-[#D19C00]"
                    : " bg-[#009733] "
                }
                `}
                ></div>
                <p
                  className={`text-xs font-normal  capitalize
                
                ${
                  propData.setup_status === "integrating"
                    ? " border-[#D19C00]  text-[#D19C00]"
                    : "text-[#009733]  border border-[#059C00]"
                }
                
                `}
                >
                  {propData.setup_status}
                </p>
              </div>
            </div>{" "}
            <div className="flex justify-between w-full">
              <p className="font-medium ">Date Integrated</p>
              <p className="text-BLACK-_200 ">
                {formatDate(propData.created_at)}
              </p>
            </div>
          </div>
        </div>
        <div
          className={`w-full h-[76px] flex items-center justify-end mt-10  gap-3`}
        >
          <Button
            text="Close"
            type="button"
            onClick={handleClose}
            className="bg-none cursor-pointer py-3 px-5 text-[#868686] font-semibold text-xs w-[91px]  border border-neutral-200  rounded-lg"
          />
          <Button
            onClick={handleDisconnect}
            text="Disconnect"
            className="bg-BLACK-_100 text-xs py-3 px-5 cursor-pointer rounded-lg font-semibold text-WHITE-_100"
            type="button"
          />
        </div>
      </div>
    </div>
  );
};

export default Peer;

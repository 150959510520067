import React from 'react'
import Logo from '../Assets/img/Logo.png'
import { useNavigate } from 'react-router'

export default function AuthLayout({ children }) {

    const navigate = useNavigate()
    return (
        <div className='h-screen bg-[#F7F7F7] py-12 px-8 flex flex-col'>
            <div className='h-[98%]'>
                <div className='w-[93px] h-[30px] cursor-pointer' onClick={() => navigate("/dashboard")}>
                    <img src={Logo} alt='logo' />
                </div>
                <div className='mt-[67px] flex-1 flex justify-center items-center'>
                    {children}
                </div>
            </div>
            <div className='flex-1'>
                <p className='text-[18px] font-normal text-[#828282] cursor-pointer'>Terms and Conditions</p>
            </div>
        </div>
    )
}

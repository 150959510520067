import React, { useState, Fragment } from "react";
import Link from "../../../Assets/svg/link.svg";
import { Switch } from "@headlessui/react";
import ArrowDown from "../../../Assets/svg/arrow-down.svg";
import ArrowUp from "../../../Assets/svg/arrow-up.svg";
import Button from "../../../component/Button";
import TokenService from "../../../services/token";
import { CgSpinner } from "react-icons/cg";
import axios from "axios";
import {
  ErrorMessage,
  SuccessMessage,
} from "../../../Toast-Messages/Toastmessages";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getAllPlugins } from "@/../features/plugins/getAllPluginsSlice";
import { getApplicationDraft } from "features/application/getApplicationByIdSlice";
export default function FeaturesAgent({
  singleAgent,
  fetchFeatures,
  selectedAgent,
  currentAgentIndex,
  loading,
  handleNext,
  featureData,
  handleClose,
  state,
}) {
  const [dropDown, setDropDown] = useState([]);
  const [notificationUrl, setNotificationUrl] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const dispatch = useDispatch();
  const applicationId = selectedAgent.id;
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();
  const pluginId = sessionStorage.getItem("plugin_id");
  console.log(pluginId, "pluginID is it equal appId");
  const appModulePID = localStorage.getItem("appModulePID");

  // console.log(state, "this is the BEB")

  const navigate = useNavigate();

  const { pathname } = useLocation();
  const checkPath =
    pathname.includes("AllpluginTypes") || pathname.includes("pluginType");
  // console.log(checkPath, "this is checkPath")

  const handleToggle = (index) => {
    setDropDown((prevState) => {
      const indexExists = prevState.includes(index);

      if (indexExists) {
        return prevState.filter((item) => item !== index);
      } else {
        return [...prevState, index];
      }
    });
  };

  const handleUpdate = async (index, featuresId) => {
    if (!notificationUrl) {
      setError("Notification url required!");
      return;
    }
    setSaveLoading(true);
    setNotificationUrl("");
    try {
      const res = await axios.put(
        `${URL}/dashboard/applications/${selectedAgent[currentAgentIndex]?.application?.id}/plugins/${pluginId}/features/${featuresId}`,
        {
          notification_url: notificationUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setSaveLoading(false);
      handleToggle(index);
      setSuccess("Saved Changes");
      setTimeout(() => {
        setSuccess(null);
      }, 1000);
      if (applicationId) {
        dispatch(getApplicationDraft(applicationId));
      }
      fetchFeatures(
        selectedAgent[currentAgentIndex]?.application?.id,
        selectedAgent[currentAgentIndex]?.id
      );
      dispatch(getAllPlugins({ applicationId })); //added this to automatically refetch newstate
    } catch (error) {
      setSaveLoading(false);
    }
  };

  const handleSingleUpdate = async (index, featuresId) => {
    if (!notificationUrl) {
      setError("Notification url required!");
      return;
    }
    // console.log(featuresId, "this is featuresId from FeaturesAgent")
    setSaveLoading(true);
    setNotificationUrl("");
    try {
      const res = await axios.put(
        `${URL}/dashboard/applications/${selectedAgent?.id}/plugins/${pluginId}/features/${featuresId}`,
        {
          notification_url: notificationUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setSaveLoading(false);
      handleToggle(index);
      setSuccess("Saved Changes");
      setTimeout(() => {
        setSuccess(null);
      }, 1000);
      // fetchFeatures(selectedAgent?.id, pluginId);
      if (checkPath) {
        fetchFeatures(selectedAgent?.id, pluginId); //selectedAgent?.id, pluginId
      } else {
        console.log(appModulePID, "this is appMOdule");
        fetchFeatures(appModulePID, selectedAgent?.id);
      }
      if(applicationId){
      dispatch(getApplicationDraft(applicationId));
      dispatch(getAllPlugins({ applicationId })); //added this to automatically refetch newstate
    }
    } catch (error) {
      setSaveLoading(false);
    }
  };

  const handlePublish = async (featuresId, AMID) => {
    try {
      const res = await axios.put(
        `${URL}/dashboard/applications/${selectedAgent[currentAgentIndex]?.application?.id}/plugins/${AMID}/features/${featuresId}/publish`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      fetchFeatures(
        selectedAgent[currentAgentIndex]?.application?.id,
        selectedAgent[currentAgentIndex]?.id
      );
      dispatch(getAllPlugins({ applicationId })); //added this to automatically refetch newstate
    } catch (error) {
      console.error(error);
    }
  };

  const handleSinglePublish = async (featuresId, AMID) => {
    try {
      const res = await axios.put(
        `${URL}/dashboard/applications/${selectedAgent?.id}/plugins/${AMID}/features/${featuresId}/publish`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (checkPath) {
        fetchFeatures(selectedAgent?.id, pluginId); //selectedAgent?.id, pluginId
      } else {
        console.log(appModulePID, "this is appMOdule");
        fetchFeatures(appModulePID, selectedAgent?.id);

        // console.log(featureData, "trying to see you") featureData[0]?.application_module?.plugin?.id
        // console.log(selectedAgent, "this is trying to see you too")
      }
      dispatch(getAllPlugins({ applicationId })); //added this to automatically refetch newstate
    } catch (error) {
      console.error(error);
    }
  };

  const nextSwitch = () => {
    if (selectedAgent.length === currentAgentIndex + 1) {
      handleClose();
      navigate("/plugins"); //commented this code out
    } else {
      handleNext();
    }
  };

  const featureLength = featureData?.length;
  const minHeight = 310;
  const maxHeight = 700;
  const step = 65;
  const dynamicHeight =
    featureLength === 1
      ? minHeight
      : minHeight +
        Math.min(
          featureLength - 1,
          Math.floor((maxHeight - minHeight) / step)
        ) *
          step;

  return (
    <div
      style={{ height: `${dynamicHeight}px` }}
      className="w-full flex flex-col gap-[52px]"
    >
      <div className="w-full flex flex-col">
        <div className="w-full flex items-center bg-[#E9E9EA] gap-24 rounded-tl-[8px] p-1 rounded-tr-[8px] ">
          <div className="px-2 h-[33px] w-[27.25%] flex items-center gap-3">
            <input type="checkbox" name="" id="" className=" w-[16px] h-full" />
            <p className="font-semibold text-sm text-[#828282]">Features</p>
          </div>
          <div className=" h-[33px] w-[20.25%] flex items-center">
            {" "}
            {/* Just Added this category */}
            <p className=" font-semibold text-sm text-[#828282]">
              Sub Features
            </p>
          </div>
          <div className=" h-[33px] w-[23.25%] flex items-center">
            <p className=" font-semibold text-sm text-[#828282]">API Link</p>
          </div>
          <div className="h-[33px] w-[23.25%]  flex items-center">
            <p className="font-semibold text-sm text-[#828282]">Status</p>
          </div>
          <div className=" h-[33px] flex w-[7%] items-center">
            <p className="font-semibold text-sm text-[#828282]"></p>
          </div>
        </div>
        {featureData &&
          featureData?.map((data, index) => (
            <div
              key={index}
              className={`${
                dropDown.includes(index)
                  ? "h-auto pt-3 border-b-0"
                  : "h-[62px] pt-3"
              } border-x w-full flex gap-1 flex-col`}
            >
              <div className="w-full flex gap-24 items-center">
                <div className=" flex ml-3 items-center w-[27.25%] gap-3 whitespace-nowrap">
                  <input type="checkbox" name="" id="" className=" w-4 h-4" />
                  <p className=" text-sm text-[#121212]">
                    {data?.features?.name}
                  </p>
                </div>

                <div className="py-[8px] whitespace-nowrap flex items-center w-[20.25%]">
                  <div className="flex gap-2">
                    {/* <img src={Link} alt="link" className='w-[16px] h-[16px]' /> */}
                    <p className="text-sm font-medium text-[#828282]">
                      {data.features.features || ""}
                    </p>
                  </div>
                </div>

                <div className="py-[8px] whitespace-nowrap flex items-center w-[23.25%]">
                  <div className="flex gap-2">
                    <img src={Link} alt="link" className="w-[16px] h-[16px]" />
                    <p className="text-sm font-medium text-[#828282]">
                      {data.notification_url || "Nil"}
                    </p>
                  </div>
                </div>

                <div className="py-[8px] w-[23.25%]">
                  <div className="flex gap-[25px] items-center">
                    <div
                      className={`${
                        data.published ? "bg-[#dff8e74d]" : "bg-[#FAFAFA]"
                      } w-[59px] h-[22px] rounded-lg flex flex-col justify-center items-center`}
                    >
                      <div className="flex gap-0.5 items-center">
                        <div
                          className={`rounded w-[4px] h-[4px] ${
                            data.published ? "bg-[#009733]" : "bg-[#7F7F81]"
                          }`}
                        ></div>
                        <p
                          className={`${
                            data.published
                              ? " text-[#009733]"
                              : "text-[#7F7F81]"
                          } text-xs`}
                        >
                          {data.published ? "Active" : "Inactive"}
                        </p>
                      </div>
                    </div>
                    <Switch
                      checked={data?.published}
                      onChange={
                        singleAgent
                          ? () =>
                              handleSinglePublish(
                                data?.id,
                                data?.application_module?.id
                              )
                          : () =>
                              handlePublish(
                                data?.id,
                                data?.application_module?.id
                              )
                      }
                      as={Fragment}
                    >
                      {({ checked }) => (
                        <button
                          className={`${
                            checked ? "bg-blue-600" : "bg-gray-200"
                          } relative inline-flex h-6 w-11 items-center rounded-full`}
                        >
                          <span className="sr-only">Enable notifications</span>
                          <span
                            className={`${
                              checked ? "translate-x-6" : "translate-x-1"
                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                          />
                        </button>
                      )}
                    </Switch>
                  </div>
                </div>
                <div
                  className="py-[8px] px-[20px] w-[7%] flex justify-end cursor-pointer"
                  onClick={() => handleToggle(index)}
                >
                  <img
                    src={dropDown.includes(index) ? ArrowUp : ArrowDown}
                    alt="arrow-down"
                    className="w-4 h-4"
                  />
                </div>
              </div>
              {dropDown.includes(index) && (
                <div
                  className="w-full flex flex-col px-[42px] py-6"
                  style={{ background: "rgba(3, 89, 216, 0.03)" }}
                >
                  <div className="">
                    <p className="text-[#828282] text-sm font-medium">
                      {data?.features?.description}{" "}
                    </p>{" "}
                    {/* Enable your customers send money to your select banks */}
                  </div>
                  <div className="w-full mt-6 flex flex-col items-center gap-[31px]">
                    <div className="w-full flex flex-col gap-3">
                      <p className="text-sm text-medium text-BLACK-_100">
                        URL Link
                      </p>
                      <input
                        className="text-[#828282] text-sm font-medium rounded-[8px] w-[420px] h-[38px] border-[#D0D5DD] focus:outline-none"
                        placeholder="Sterling/sample-app-api"
                        value={notificationUrl}
                        onChange={(e) => setNotificationUrl(e.target.value)}
                      />
                      <p className="text-xs text-BLACK-_200">
                        Set the webhook notification url for this service.
                      </p>
                    </div>
                    <div className="flex w-full justify-end gap-2 items-center">
                      <Button
                        onClick={() => {
                          setNotificationUrl("");
                          handleToggle(index);
                        }}
                        text="Discard Changes"
                        className="w-[118px] h-[34px] rounded-lg border font-semibold text-xs text-RED-_100 p-1"
                      />
                      {saveLoading ? (
                        <Button
                          text={
                            <CgSpinner className=" animate-spin text-lg " />
                          }
                          className="flex justify-center items-center p-1 w-[118px] h-[34px] text-xs rounded-lg bg-BLACK-_500 text-WHITE-_100"
                        />
                      ) : (
                        <Button
                          onClick={
                            singleAgent
                              ? () => handleSingleUpdate(index, data.id)
                              : () => handleUpdate(index, data.id)
                          }
                          text="Save Changes"
                          className=" p-1 w-[118px] h-[34px] text-xs rounded-lg bg-BLACK-_500 text-WHITE-_100"
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
      <div className="flex w-full justify-end gap-3 items-center py-[15.5px]">
        {" "}
        {/* removed this navigate('/plugins') from Cancel button*/}
        <Button
          text="Cancel"
          onClick={() => handleClose()}
          className="w-auto rounded-lg border font-semibold text-sm text-[#828282] py-[10px] px-5 "
        />
        {singleAgent ? (
          <Button
            onClick={() => {
              handleClose();
              navigate("/plugins");
            }}
            text="Finish"
            className=" py-[10px] px-5 w-auto text-sm rounded-lg bg-BLACK-_500 text-WHITE-_100"
          />
        ) : selectedAgent.length === currentAgentIndex + 1 ? (
          <Button
            onClick={nextSwitch}
            text="Finish"
            className=" py-[10px] px-5 w-auto text-sm rounded-lg bg-BLACK-_500 text-WHITE-_100"
          />
        ) : (
          <Button
            onClick={nextSwitch}
            text="Next Agent"
            className=" py-[10px] px-5 w-auto text-sm rounded-lg bg-BLACK-_500 text-WHITE-_100"
          />
        )}
      </div>
      {error && <ErrorMessage error={error} />}
      {success && <SuccessMessage success={success} />}
    </div>
  );
}

import React, { useState, useContext } from "react";
// import Forms from './Forms'
import WanoLogo from "../Assets/wano logo 1 1.png";
import useWano from "../usewano";

import WelcomeLogoText from "./WelcomeLogotext";
import UseWanoItems from "./UseWanoItems";
import NavBar from "./NavBar";
import Terms from "./Terms";
import Button from "./Button";
import { useNavigate } from "react-router";
import { UserContext, useAuth } from "../context/UserContext";
import { BiArrowBack } from "react-icons/bi";
import { ErrorMessage, SuccessMessage } from "../Toast-Messages/Toastmessages";

const style =
  "w-[478px] rounded-xl px-[12px] line-[24px] py-[13px] text-white text-14px";

export default function UseWano({ UserData }) {
  const { userType } = useContext(UserContext);
  const { setUserType } = useContext(UserContext);
  const { createBusiness } = useAuth();
  // const [checkedItems, setCheckedItems] = useState("Sales");
  const [checkedItems, setCheckedItems] = useState(""); 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();

  const handleSubmit = () => {
    setLoading(true);
    // localStorage.setItem("User_Type", checkedItems);
    localStorage.setItem("User_Type", "Sales");
    setUserType(checkedItems);

    const data = {
      ...JSON.parse(localStorage.getItem("data")),
      purpose_for_wano: checkedItems.toString(),
    };

    const handleCreate = async () => {
      try {
        const res = await createBusiness(JSON.stringify(data));
        // console.log("successfully updated", res);
        const userIdentifier = 'Sales';
        let check = JSON.parse(localStorage.getItem("wano_agent"))

        if (check?.id) {
          navigate("/dashboard");
        } else {
          navigate("/dashboard");
        }
      } catch (error) {
        setLoading(false);
        setError(error.response.data.message);
        setTimeout(() => {
          setError(false);
        }, 1000);
      }
    };

    handleCreate();
  };

  // const handleItemCheckedChange = (type) => {
  //   setCheckedItems(type);
  // };

  const handleItemCheckedChange = (type) => {
    setCheckedItems((prevCheckedItems) => {
      if (prevCheckedItems.includes(type)) {
        return prevCheckedItems.filter((item) => item !== type);
      } else {
        return [...prevCheckedItems, type];
      }
    });
  };

  return (
    <>
      <div className="grid content-between min-h-screen bg-[#F7F7F7]">
        <NavBar />
        <div className="w-[578px] px-[50px] py-[30px] mx-auto shadow grid gap-[41px] bg-white rounded-lg">
          {/* <Forms/> */}
          <BiArrowBack
            onClick={() => navigate(-1)}
            className="cursor-pointer"
          />
          <div className="-mt-5">
            <WelcomeLogoText
              width={"w-[396px]"}
              // Logo={WanoLogo}
              title={
                "Sales" === null || "Sales" === undefined || "Sales" === ""
                  ? "What will you use Wano for?"
                  : "What will you use Wano for?"
              }
              text={
                "Sales" === null || "Sales" === undefined || "Sales" === ""
                  ? "This Information helps us tailor your experience to you. Note that you can change this at anytime"
                  : "This Information helps us tailor your experience to you. Note that you can change this at anytime"
              }
            />
            {"Sales" === "" && (
              <p className="text-center mx-auto text-[14px] w-[396px] text-[#828282]">
                Note that you can change this at anytime
              </p>
            )}
          </div>
          <div className="flex flex-col gap-6 w-[478px]">
            {useWano.map((el) => (
              <UseWanoItems
                key={el.text}
                header={el.header}
                text={el.text}
                businessType={el.business_type}
                isBeta={el.beta}
                // checked={checkedItems}
                checkedItems={checkedItems}
                handleItemCheckedChange={handleItemCheckedChange}
                Suitable={el.Suitable}
              />
            ))}
          </div>
          <Button
            disabled={!checkedItems}
            onClick={handleSubmit}
            text={"Finish"}
            loading={loading}
            className={
              checkedItems ? `bg-black ${style}` : `bg-[#EBEBEB] ${style}`
            }
          />
        </div>
        <Terms />
      </div>
      {error && <ErrorMessage error={error} className="mt-5 mr-2" />}
    </>
  );
}

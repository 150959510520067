import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from "../../services/api"



const initialState = {
    loading: false,
    data: [],
    error: ""
};

export const getBusinessAskAgentChatH = createAsyncThunk(
    "getAgentChat/getBusinessAskAgentChat", 
    async({page, pageSize, appId, chatId}, { rejectWithValue }) => {
        try {
            
            const res = await api.get(`/dashboard/applications/${appId}/chat/${chatId}/conversations`);
            return res?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)

const getBusinessAskAgentChatSlice = createSlice({
    name: "getAgentChat",
    initialState,
    extraReducers: builder => {
        builder.addCase(getBusinessAskAgentChatH.pending, state => {
            state.loading = true
        });
        builder.addCase(getBusinessAskAgentChatH.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(getBusinessAskAgentChatH.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default getBusinessAskAgentChatSlice.reducer
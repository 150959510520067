import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { api } from "../../services/api"


const initialState = {
    loading: false,
    data: [],
    error: ""
};

export const getAllPluginById = createAsyncThunk(
    "plugins/getAllPluginById", 
    async({ id }, { rejectWithValue }) => {
        try {
            const res = await api.get(`/plugins/${id}`);
            console.log(res, "fojo")
            return res?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)

const getAllPluginByIdSlice = createSlice({
    name: "plugins",
    initialState,
    extraReducers: builder => {
        builder.addCase(getAllPluginById.pending, state => {
            state.loading = true
        });
        builder.addCase(getAllPluginById.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(getAllPluginById.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default getAllPluginByIdSlice.reducer
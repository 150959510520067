import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router";

import GiftBox from "../../../Assets/svg/giftbox.svg";
import WalletIcon from "../../../Assets/svg/walleticon.svg";
import ConfettiLeft from "../../../Assets/svg/confetti_left.svg";
import ConfettiRight from "../../../Assets/svg/confetti_right.svg";
import axios from "axios";
import TokenService from "../../../services/token";
import { useTour } from "@reactour/tour";

const Welcome = ({ handleClose, setGift, setAgentSetUpOpen, credit, setOpenStartGuide }) => {
  const navigate = useNavigate();
  // const token = TokenService.getToken();
  // const URL = process.env.REACT_APP_BASEURL;

  const saveUser = () => {
    sessionStorage.setItem("user", "userName");
    localStorage.setItem("user", "userName");
    setGift(true);
    handleClose();
  };

  const { setIsOpen } = useTour()

  const openStartPop = () => {
    setOpenStartGuide(true);
    // handleClose();
  };

  const closeWelcomeModal = () => {
    localStorage.setItem("user", "userName");
    sessionStorage.setItem("user", "userName");
    setGift(true);
    handleClose();
  };

  // useEffect(() => {
  //   const fetchCredit = async () => {
  //     try {
  //       const credit = await axios.get(`${URL}/dashboard/businesses/credits`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       console.log(credit, "my credit")
  //       const res = credit?.data?.data;
  //       setCredit(res);
  //       console.log(res, "console of response");
  //     } catch (error) {
  //       console.log(error, "this is the error");
  //     }
  //   };

  //   fetchCredit();
  // }, []);

  const formatCredits = (credits) => {
    if (credits && credits.length >= 2) {
      return credits.slice(0, 2) + "," + credits.slice(2);
    }
    return credits;
  };

  return (
    <div
      className="bg-[#4B92FB] w-[563px] h-fit mt-[150px] py-[30px] px-[50px] rounded-lg"
      style={{ boxShadow: "0px 24px 43px 0px rgba(76,76,76, 0.07)" }}
    >
      <div className="flex justify-end">
        <button
          className="w-[36px] h-[36px] border rounded-lg flex justify-center items-center p-2"
          onClick={() => closeWelcomeModal()}
        >
          <AiOutlineClose className="text-[#828282]" />
        </button>
      </div>
      <div className="w-[454px] flex flex-col">
        <div className="w-[383px] flex flex-col mt-[-160px]  mx-auto">
          <div className="w-[383px] flex flex-col justify-center items-center mx-auto">
            {/* <div
              className="w-[57px] h-[57px] flex flex-col justify-center rounded-full items-center"
              style={{ background: "rgba(205, 222, 248, 0.20)" }}
            > */}
              <img
                src={GiftBox}
                alt="gift box"
                className="w-[200px] h-[187px]"
              />
            {/* </div> */}
            <p className="text-[32px] text-white font-medium mt-3">
              Welcome to Wano!
            </p>
            <p className="text-[14px] text-white font-medium w-[383px] text-center">
            We have a welcoming gift for you. You have been awarded $5 worth of free credits.
            </p>

       <div className="bg-white rounded-[129px] mt-[22px] px-[16px] py-[4px]">
       <div
              className="text-[47px] leading-[56.4px] font-medium flex gap-[8px] items-center"
              style={{
                background:
                  "linear-gradient(92deg, #CA62EF 9.9%, #4B92FB 52.28%, #F6C0D1 96.14%)",
                WebkitTextFillColor: "transparent",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                whiteSpace: "nowrap",
              }}
            >
                {/* <span> ${(credit?.tokens?.split('.')[0])}</span> */}
                <span>${credit}</span>
            <img
                src={WalletIcon}
                alt="wallet icon"
                className="w-[20px] h-[16px]"
              />
            </div>
       </div>
            <div className="flex items-center mt-[7px]">
              <img
                src={ConfettiLeft}
                alt="confetti_left"
                className="w-[23px] h-[20px]"
              />
              <p className="text-[20px] text-white font-medium">For Free</p>
              <img
                src={ConfettiRight}
                alt="confetti_right"
                className="w-[23px] h-[20px]"
              />
            </div>
          </div>
        </div>
        <div className="mt-10">
          <button
            className="w-[454px] h-[40px] py-3 px-5 rounded-lg flex justify-center items-center text-bold bg-BLACK-_500 text-WHITE-_100 text-sm"
            type="submit"
            onClick={() => {
              saveUser();
              openStartPop();
              // setIsOpen(true);
          }}
          >
            Get Started
          </button>
        </div>

        {/* <div className="mt-10 text-center">
          <p className="text-[#868686] text-sm">
            Know what credits are and why they’re important{" "}
            <span
              className="text-[#1774FD] text-sm underline cursor-pointer"
              onClick={() => {
                navigate("/support");
                sessionStorage.setItem("here", true);
              }}
            >
              {" "}
              here
            </span>{" "}
          </p>
        </div> */}
      </div>
    </div>
  );
};
export default Welcome;

import React, { useState } from "react";
import GiftBox from "../../../Assets/svg/giftbox.svg";
import { api } from "@/../services/api";
import { ErrorMessage } from "@/../Toast-Messages/Toastmessages";

const RedeemGift = ({ handleClose, setOpenRedeemSuccess, refresh, setAmount, back }) => {
  const [cardVal, setCardVal] = useState('')
  const [error, setError] = useState('')
  const businessID = JSON.parse(localStorage.getItem("cBus"))?.id
  console.log(businessID, "businessID")
  const handleRedeem = async () => {
    await api
      .post(`/wano-giftcards/redeem/${businessID}`, {code: cardVal} )
      .then((res) => {
        openRedeemSuccessPop();
        refresh();
        setAmount();
        console.log(res, "res 123")
      })
      .catch((error) => {
        setError(error?.data?.message)
        setTimeout(() => {
          setError('')
        }, 1000)
      });
  };
  

  const openRedeemSuccessPop = () => {
    setOpenRedeemSuccess(true);
    handleClose();
  };

  return (
    <div className="bg-WHITE-_100 w-[592px] h-[618px] rounded-lg p-[40px] flex flex-col gap-[72px] mt-[50px]">
      <h1 className="font-medium text-[18px] leading-[21.6px]">
        Redeem Gift Credits
      </h1>
      <div>
        <div className="flex justify-center mb-[35px]">
          <img src={GiftBox} alt="gift box" className="w-[200px] h-[187px]" />
        </div>
        <p className="font-normal leading-[20px] text-[12px] text-[#101828]">
          Enter Code
        </p>
        <input
          placeholder="Enter code to redeem gift"
          value={cardVal}
          onChange={(e) => { e.preventDefault(); setCardVal(e.target.value)}}
          className="text-[12px] leading-[14.4px] text-[#828282] my-3 outline-none w-full"
        />
        <p className="text-[12px] leading-[14.4px] text-[#828282]">
          Note: These credits will only apply to subscriptions and not task
          credits.
        </p>
      </div>
      <div>
        <button
          className="bg-BLACK-_500 w-[420px] text-center flex justify-center items-center mx-auto rounded-lg h-[40px] p-2"
          type="submit"
          // onSubmit={(values) => {
          //     window.scrollTo(0, 0);
          //     openRedeemSuccessPop();
          //   }}
          onClick={handleRedeem}
        >
          {/* {loading ?
                                            <CgSpinner className=" animate-spin text-lg text-WHITE-_100 mx-auto " />
                                            : */}
          <p className="text-center font-semibold text-sm text-WHITE-_100">
            Redeem
          </p>
          {/* } */}
        </button>
        <p
          className="text-[14px] leading-[24px] font-medium text-[#868686] text-center mt-[8px] cursor-pointer"
          onClick={back}
        >
          Back
        </p>
      </div>
      {error && <ErrorMessage error={error}/>}
    </div>
  );
};

export default RedeemGift;

import React, { useState } from 'react'
import { AiOutlineClose } from "react-icons/ai"
import { Formik, Form } from 'formik'
import * as Yup from "yup"

import Button from '../../Button'

const IntegrateCustomBusinessA = ({ handleClose, setOpenNextModal, addWanoNumber, setIntegrateWithWano, purpose }) => {

    const formValidationSchema = Yup.object().shape({
        url: Yup.string().required()
    });

    const submitForm = (values) => {
        localStorage.setItem("notificationUrl", values?.url)
        if(addWanoNumber) {
            setIntegrateWithWano(true)
            handleClose()
        } else {
            setOpenNextModal(true)
            handleClose()
        }

    }

  return (
    <div className="bg-WHITE-_100 w-[484px] h-[436px]  mt-[60px] rounded-lg">
         <div className="flex justify-between h-[65px] items-center mx-4 my-1 ">
            <div className=" flex ml-6">
                <p className="font-medium text-lg text-BLACK-_600">Integrate With {purpose === 'Wano' ? 'Wano' : 'Custom Business'} Number</p>
            </div>
            <button className="w-[30px] h-[30px] border border-[#F7F7F7] rounded-lg flex justify-center items-center p-2" onClick={handleClose}>
                <AiOutlineClose fill='#828282'/>
            </button>
        </div>
        <hr />
        <div className="w-full px-[31px] py-[35px] flex flex-col gap-5 justify-center items-center"> 
            <div className="flex w-[174px] mt-0 justify-center items-center gap-1">
                <p className="w-[27px] h-[27px] text-[10px] bg-BLUE-_200 rounded-full text-white flex flex-col items-center justify-center">1</p>
                <div className="w-[24px] h-[2px] border bg-WHITE-_200"></div>
                <p className="w-[27px] h-[27px] text-[10px] bg-BLUE-_300 rounded-full text-white flex flex-col items-center justify-center">2</p>
                <div className="w-[24px] h-[2px] border bg-WHITE-_200"></div>
                <p className="text-[10px] w-[52px] h-[23px] bg-BLUE-_300 rounded text-white flex flex-col items-center justify-center">Integrate</p>
            </div>
            <div className="flex flex-col w-[420px] mt-4">
                <Formik
                    initialValues={{
                        url: "",
                    }}
                    validationSchema={formValidationSchema} 
                    onSubmit={(values) => {
                        window.scrollTo(0, 0)
                        submitForm(values)
                    }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    dirty,
                    isValid,
                    setFieldValue,
                    errors,
                    touched,
                    // setFieldTouched,
                    values,
                }) => (
                    <Form onSubmit={handleSubmit} className="flex flex-col lg:items-center">
                        <div className='flex flex-col gap-5 '>
                        
                           <div className='flex flex-col gap-2 '>
                                <label className='text-sm font-medium text-BLACK-_100'>URL Link</label>
                                <input
                                    name='url'
                                    value={values?.url}
                                    type='text'
                                    onChange={handleChange}
                                    className='w-[420px] text-[13px] text-[#828282] h-[38px] border p-2 border-[#D0D5DD] outline-none rounded-lg'
                                    placeholder='Sterling/sample-app-api' 
                                />
                                <p className='text-xs text-[#7F7F81]'>Set the webhook notification url for this service.</p>
                                {errors.url && touched.url ? (
                                    <div className='text-RED-_100 text-xs'>{errors.url}</div>
                                    ) : null}
                            </div>
                            
                            
                            <div className="w-[420px] h-[76px] mt-[40px] flex flex-col mx-auto gap-3">
                                <Button
                                    text="Next" 
                                    className="bg-BLACK-_100 text-sm h-[40px] cursor-pointer rounded-lg font-semibold text-WHITE-_100"
                                    type="submit" 
                                />
                                <Button 
                                    text="Go Back"
                                    type="button" 
                                    onClick={handleClose}  
                                    className="bg-none cursor-pointer text-[#868686] font-bold" 
                                />
                            </div>

                        </div>
        
                    </Form>
                )}

                </Formik>

            </div>
        </div>
    </div>
  )
}

export default IntegrateCustomBusinessA
const useWano = [
    {
      header: "Sales",
      text: "Create a powerful digital sales assistant that will sell your financial solutions to customers, resolve their queries and provide your business with useful insights on customer behaviours.",
      beta: false,
      Suitable: "Suitable for Banks and FinTechs"
    },
    {
      header: "Customer Service",
      text: "Create a powerful digital customer service assistant to answer and attend to all your customer enquiries, requests and complaints.",
      beta: true,
      Suitable: "Suitable for all Businesses"
    },
    {
      header: "Messaging",
      text: "Provide instant 2FA and phone number verification service to your customers using WhatsApp.",
      beta: false,
      Suitable: "Suitable for all Businesses"
    }
//     {
//       header: "Authentication",
//       text: "Use Wano to integrate OTP with WhatsApp for your customers for easy, cheap and fast access.",
//       business_type:"Suitable for all Businesses",
//       beta: false,
//     },
  ];

  export default useWano
  
  
export const appUrls = {
    //AUTH ENDPOINTS
    LOGIN_URL: "/dashboard/auth/login",


    //WALLET ENDPOINTS
    WALLET_HISTORY_URL: "/dashboard/transactions",
    WALLET_BALANCE_URL: "/dashboard/businesses/credits",
    WALLET_GENERATE_URL: "/dashboard/top-up/generate",
    WALLET_NOTIFICATION_URL: "/dashboard/top-up/flutterwave-notification",
    BULK_URL: "/dashboard/top-up/request-enterprise-payment",

    //TEAMS ENDPOINTS
    TEAMS_MEMBERS_URL: "/dashboard/team-members",
    ROLE_URL: "/dashboard/team-members/roles",
    INVITE_TEAM_MEMBERS_URL: "/dashboard/team-members/invite",
    DELETE_TEAM_MEMBERS_URL: "/dashboard/team-members/remove",

    //TWOFA ENDPOINTS
    GENERATE_QRCODE_URL: "/dashboard/user-mfa/generate-secret",
    SETUP_TWOFA_URL: "/dashboard/user-mfa/setup",
    SETTINGS_TWOFA_URL: "/dashboard/user-mfa/setting",
    TOGGLE_TWOFA_URL: "/dashboard/user-mfa/toggle",
    GET_APIKEY_URL: "/dashboard/api-key",
    RESET_PUBLIC_APIKEY_URL: "/dashboard/api-key/reset-public-key",
    RESET_SECRET_APIKEY_URL: "/dashboard/api-key/reset-secret-key",


    //APPLICATION ENDPOINTS
    CREATE_APPLICATION_URL: "/dashboard/applications/create",
    GET_APPLICATION_BY_ID_URL: "/dashboard/applications",

    //NOTIFICATION ENDPOINTS
    GET_NOTIFICATION: "/dashboard/notifications",
    UPDATE_NOTIFICATION: "/dashboard/notifications",
    
    //BUSINESS ENDPOINTS
    CREATE_BUSINESS: "/dashboard/businesses/create",
    GET_BUSINESS: "/dashboard/businesses",
    UPDATE_BUSINESS: "/dashboard/businesses",
    BUSINESS_CONFIG: "/dashboard/business-config",

    //SERVICES ENDPOINT
    GET_SERVICES_URL: "/module",

    //PROFILE ENDPOINT
    GET_PROFILE: "/dashboard/account/profile",
    POST_PROFILE: "/dashboard/account/profile",
    CONFIG: "/dashboard/account/account-config",

    //PASSWORD ENDPOINT
    UPDATE_PASSWORD: "/dashboard/account/change-password",

    //REQUEST TOOL ENDPOINT
    REQUEST_TOOL: "/dashboard/request",

    //PLUGIN ENDPOINT
    ALL_PLUGINS: "/dashboard/plugins",


};

import DashboardLayout from "../layout/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import { useEffect, useState } from "react";
import Conversation from "Customer/Conversation";
import Summary from "Customer/Summary";
import Notes from "Customer/Notes";
import Spinner from "component/Spinner";

import { useDispatch, useSelector } from "react-redux";
import {
  getCustomer,
  getConversationSummary,
} from "features/customer/customerSlice";

function Customer() {
  const navigate = useNavigate();
  const { id, type } = useParams();
  // const tabs = ["conversation", "summary", "notes"];
  const tabs = ["conversation", "summary"];
  const [activeTab, setActiveTab] = useState("conversation");
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const newAgent = getAgentApplication?.agent || null;

  const applicationId = newAgent.id;
  const dispatch = useDispatch();
  const [summaryLoading, setSummaryLoading] = useState(false);
  const checkIfSummaryExists = async (conversation) => {
    // if (!conversation.conversation_summary) {
    try {
      setSummaryLoading(true);

      await dispatch(
        getConversationSummary({
          conversationId: conversation.id,
          customerId: id,
          applicationId: newAgent.id,
        })
      );
      // }
      dispatch(getCustomer({ applicationId, id }));
    } catch (e) {
      console.error("Couldn't find", e);
    } finally {
      setSummaryLoading(false);
    }
  };
  const [pageLoading, setPageLoading] = useState(false);

  const fetchData = async () => {
    try {
      setPageLoading(true);

      const response = await dispatch(getCustomer({ applicationId, id }));
      if (response.payload.data.conversations.length > 0) {
        setActiveConversationHistory(response.payload.data.conversations[0]);
        checkIfSummaryExists(response.payload.data.conversations[0]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setPageLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const { customer, loading } = useSelector((state) => state.getCustomers);
  function formatISODate(isoDate) {
    // Create a new Date object from the ISO 8601 date string
    let date = new Date(isoDate);

    // Helper function to get the day with ordinal suffix
    const getDayWithSuffix = (day) => {
      if (day > 3 && day < 21) return day + "th"; // Special case for teens
      switch (day % 10) {
        case 1:
          return day + "st";
        case 2:
          return day + "nd";
        case 3:
          return day + "rd";
        default:
          return day + "th";
      }
    };

    // Array of abbreviated month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Format the date components
    let day = getDayWithSuffix(date.getUTCDate());
    let month = monthNames[date.getUTCMonth()];
    let year = date.getUTCFullYear().toString();

    // Format the readable date string
    return `${day} ${month}, ${year}`;
  }
  function formatDate(isoDate) {
    const date = new Date(isoDate);

    const getDayWithSuffix = (day) => {
      if (day > 3 && day < 21) return day + "th"; // Special case for teens
      switch (day % 10) {
        case 1:
          return day + "st";
        case 2:
          return day + "nd";
        case 3:
          return day + "rd";
        default:
          return day + "th";
      }
    };

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = getDayWithSuffix(date.getUTCDate());
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day}, ${month} ${year}`;
  }
  const [activeConversationHistory, setActiveConversationHistory] = useState(
    {}
  );
  function formatDate(isoDate) {
    const date = new Date(isoDate);

    const getDayWithSuffix = (day) => {
      if (day > 3 && day < 21) return day + "th"; // Special case for teens
      switch (day % 10) {
        case 1:
          return day + "st";
        case 2:
          return day + "nd";
        case 3:
          return day + "rd";
        default:
          return day + "th";
      }
    };

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = getDayWithSuffix(date.getUTCDate());
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day}, ${month} ${year}`;
  }
  return (
    <DashboardLayout title={"Customers"} user={true}>
      {pageLoading ? (
        <div className="h-screen w-screen flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          {!!customer.id && (
            <div className="px-10 py-12">
              <header className="flex gap-2 items-center mb-4">
                <div
                  onClick={() => navigate("/customers", { state: "Customers" })}
                  className="cursor-pointer underline text-[#D2D0D0] text-sm font-normal hover:underline"
                >
                  Customers
                </div>
                <BiChevronRight className=" text-xl text-[#D2D0D0]" />
                <p className="text-[#121212] text-sm font-normal capitalize">
                  {customer.name}
                </p>
              </header>
              <div className="mt-14">
                <p className="font-medium text-2xl capitalize">
                  {customer.name}
                </p>
                <div className="mt-10">
                  {/* details  */}
                  <p className="text-base font-medium">Details</p>
                  <div className="mt-6 grid grid-cols-5 gap-x-10 gap-y-[50px]">
                    <div className="flex flex-col gap-1">
                      <p className="customerHeader">Full name</p>
                      <p className="text-sm">{customer.name}</p>
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="font-medium text-sm text-BLACK-_300">
                        Email
                      </p>
                      <p className="text-sm break-all">{customer.email}</p>
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="font-medium text-sm text-BLACK-_300">
                        Phone Number
                      </p>
                      <p className="text-sm break-all">
                        {customer.phone_number ?? "__"}
                      </p>
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="font-medium text-sm text-BLACK-_300">
                        Last Seen
                      </p>
                      <p className="text-sm break-all">
                        {formatISODate(customer.time_of_first_session)}
                      </p>
                    </div>{" "}
                    <div className="flex flex-col gap-1">
                      <p className="font-medium text-sm text-BLACK-_300">
                        First Seen
                      </p>
                      <p className="text-sm break-all">
                        {formatISODate(customer.time_of_last_session)}
                      </p>
                    </div>
                  </div>
                  {/* CONVERSATION HISTORY  */}
                  <p className="mt-16 mb-6 text-base font-medium">
                    Conversation History
                  </p>
                  <div className="grid grid-cols-10 gap-8 ">
                    <div className="col-span-3 flex flex-col gap-4  overflow-y-auto">
                      {customer.conversations.map((convo, index) => (
                        <div
                          key={index}
                          className={`text-BLACK-_300 bg-[#FDFDFD] hover:text-BLUE-_200 hover:bg-[#F4F9FF]  border border-chatBrown cursor-pointer rounded-lg px-6 py-4
                      ${
                        activeConversationHistory.id === convo.id
                          ? " text-BLUE-_200 bg-[#F4F9FF] "
                          : ""
                      }
                      
                      
                      `}
                          onClick={() => {
                            setActiveConversationHistory(convo);
                            checkIfSummaryExists(convo);
                          }}
                        >
                          {formatDate(convo.created_at)}
                        </div>
                      ))}
                    </div>
                    <div className="col-span-7 border border-BLACK-_400 rounded-lg p-5 px-6">
                      <p className="font-medium text-xs mb-6">
                        {formatDate(activeConversationHistory.created_at)}
                      </p>

                      <div className=" flex mb-6">
                        {tabs.map((tab, i) => (
                          <p
                            className={`border-b cursor-pointer  pb-2 capitalize
                          
                          ${
                            activeTab === tab
                              ? " border-BLACK-_500 text-BLACK-_500 "
                              : " border-BLACK-_150 text-BLACK-_150 "
                          }
                          ${i === 2 ? "pr-2" : "pr-5"}
                          `}
                            onClick={() => {
                              setActiveTab(tab);
                            }}
                          >
                            {tab}
                          </p>
                        ))}
                      </div>
                      {activeTab === "conversation" && (
                        <Conversation
                          customer={customer}
                          activeConversationHistory={activeConversationHistory}
                        />
                      )}
                      {activeTab === "summary" && <Summary summaryLoading={summaryLoading}/>}
                      {activeTab === "notes" && <Notes />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </DashboardLayout>
  );
}

export default Customer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { api } from "../../services/api"
import { appUrls } from "../../services/urls";



const initialState = {
    loading: false,
    data: [],
    error: ""
};

export const getPlugins = createAsyncThunk(
    "plugins/getPlugins", 
    async({ applicationId }, { rejectWithValue }) => {
        try {
            const res = await api.get(`/dashboard/applications/${applicationId}/plugins?page=1&per_page=5`);
            // console.log(res, "jojo")
            return res?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)

const getPluginsSlice = createSlice({
    name: "plugins",
    initialState,
    extraReducers: builder => {
        builder.addCase(getPlugins.pending, state => {
            state.loading = true
        });
        builder.addCase(getPlugins.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(getPlugins.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            // state.filteredData = [],
            state.error = action.payload
        ))
    }
});

// export const { selectPluginData } = getPluginsSlice.actions;
export default getPluginsSlice.reducer


  // Filter and save necessary data for the specific page
            // state.filteredData = (action.payload || []).map(plugin => {
            //     const pluginId = plugin?.plugin?.id;
            //     const pluginName = plugin?.plugin?.name;
            //     const applicationId = plugin?.application?.id;
            //     const applicationChannel = plugin?.application?.channel;

            //     // Check if at least one feature has a non-empty notification_url
            //     const featureWithNotificationUrl = plugin?.features?.find(feature => feature.notification_url);

            //     // If a feature with non-empty notification_url exists, extract it
            //     const notificationUrl = featureWithNotificationUrl ? featureWithNotificationUrl.notification_url : null;

            //     return {
            //         pluginId,
            //         pluginName,
            //         applicationId,
            //         applicationChannel,
            //         notificationUrl
            //     };
            // }),
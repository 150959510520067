import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from "../layout/DashboardLayout";
import { BottomGuide } from "../component/modals/agentGuide/Guide";
import PluginsSvg from "../Assets/svg/plugin.svg";
import BlueLogo from "../Assets/twobluelogo.png";
import ModalPop from "../component/modalPop";
import { useLocation, useNavigate } from "react-router";
import AllPlugins from "../Plugins/AllPluginsTab";
import MyPlugins from "../Plugins/MyPluginsTab";
import { ReactTyped } from "react-typed";
import MakeRequest from "../Agent/ServiceLayout/Modal/MakeRequest";
import AllPlugins2 from "@/../Plugins/AllPluginsTab/index2";
import ChangesBanner from "component/ChangesBanner";
import TestAgent from "@/../Agent/TestAgent/TestAgent";
import { getCurrentEmployee, updateAgentModals } from '@/../features/modals/agentModalsSlice';

// import AppRequest from '../Agent/ServiceLayout/Modal/AppRequest';

const Plugins = () => {
    const dispatch = useDispatch()
    const pluginState =  localStorage.getItem("pluginState")
    const [activeButton, setActiveButton] = useState(pluginState ? pluginState : "All Plugins");
    const [currentText, setCurrentText] = useState("Do more Resolution");
    const [appIdeaModal, setAppIdeaModal] = useState(false)
    const texts = ["Do more Resolutions", "Go Bigger", "Enhance Your Assistant"];
    
    // change currentText every 2 seconds
    useEffect(() => {
      let index = 0;
      const interval = setInterval(() => {
        setCurrentText(texts[index]);
        index++
        if (index === texts.length) {
          index = 0;
        }
      }, 2000);
      return () => clearInterval(interval);
    }, []);

  const [openTourModal, setOpenTourModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };


    const { id, type } = useSelector(state => state.getApplicationByUserId.agent)
    // console.log(id, type, "yah yah appId");

    // const handleModalUpdate = (name) => {
    //   dispatch(updateAgentModalVisibility({ modalsVisibility: { ...config, [name]: false }, id: id }))
    //   .then(() => {
    //     dispatch(getApplicationById())
    //   })
    // }

    // const setBottomPluginGuide = () => location.pathname === "/plugins" ? localStorage.setItem("plugins", true) : null; 

    // const getPluginsGuide = localStorage.getItem("plugins")

    // useEffect(() => {
    //     if(getPluginsGuide) {
    //         setOpenTourModal(false)
    //       } else {
    //         setOpenTourModal(true)
    //       }
    // }, []);

    //checks the state of the modal
    const config = useSelector(state => state.agentModals.employeeModals)
    // console.log(config, "configA");

    const getPluginsGuide = config?.plugins;

    useEffect(() => {
        if(getPluginsGuide) {
            setOpenTourModal(true)
          } else {
            setOpenTourModal(false)
          }
    }, [getPluginsGuide]);

    const handleAgentModal = (name) => {
      const currentModalState = config[name];
      if (currentModalState) {
        const modalsVisibility = {...config, [name]: false}
        dispatch(updateAgentModals({ modalsVisibility, id: id }))
        .then((res) => { 
          dispatch(getCurrentEmployee({id: id}))
        })
        .catch((error) => {
          console.error("Failed to update modal state:", error);
      });;
      }
    }

    //Check if the user has created an agent
    const getAgentApplication = useSelector(state => state.getApplicationByUserId)
    const agentType = getAgentApplication?.data?.data.length ? getAgentApplication?.data?.data[0]?.type : null
    //const CurrentAgentType = getAgentApplication?.data?.data.find((agent) => agent.id === JSON.parse(localStorage.getItem("wano_agent")).id )?.type|| null;

    //console.log(agentType, "agent type from plugins.jsx")
    // console.log(getAgentApplication, "agent application from plugins.jsx")

    useEffect(() => {
        if(agentType === null) {
        // if(type === null) {
            navigate("/dashboard")
        }
      }, [agentType])
      
const [propShowPopUp,setPropShowPopUp] = useState(false)

  return (
    <DashboardLayout title={"App Store"} user={true}>
      {/* {CurrentAgentType === "customer" ?  */}
      {/* { type === "customer" ?( */}
        <section className='w-full bg-gradient-to-br from-rose-50 via-blue-50 to-blue-50 max-h-screen' style={{height: `93.2vh`}}>
          <div className='flex items-center justify-center h-full'>
            <div className='flex flex-col justify-between w-[463px] h-[455px]'>
              <div className='flex flex-col gap-[33px]'>
                <img src={BlueLogo} alt="twobluelogo" className='w-[74.61px] h-[108px] self-center'/>
                <div className='flex flex-col gap-6'>
                  <p className='text-center text-blue-950 text-[32px] font-bold'>We’re Working on it!</p>
                  <p className="text-center text-zinc-500 text-sm font-medium leading-[21px]">We’re still working on the Appstore for your Customer Service Assistant. <br/>We’ll notify you when it’s ready!</p>
                </div>
              </div>
              {/* <div className="text-zinc-500 text-xs leading-[18px]">
                <div className='flex gap-[3px] justify-center items-center'>
                  <span className='text-xs font-normal text-zinc-500'>Have an idea of an App we can add?</span>
                  <span className="text-zinc-500 font-semibold">Let us Know{" "}</span>
                  <button onClick={() => {setAppIdeaModal(true)}} className="cursor-pointer text-blue-600 text-xs font-semibold leading-[18px]"> Here!</button>
                </div>
              </div> */}
            </div>
          </div>
        </section>
     
        {/* <section className="flex font-figtree relative w-full">
          <div className="py-8 px-6 h-full shrink-0">
            <ul className="flex flex-col gap-4 w-[135px]">
              <li
                className={`py-2.5 px-4 cursor-pointer rounded-md ${
                  activeButton === "All Plugins"
                    ? "text-PRIMARY bg-[#FAFAFA]"
                    : "text-SECONDARY"
                }`}
                onClick={() => handleButtonClick("All Plugins")}
              >
                <span>All Apps</span>
              </li>

              <li
                className={`py-2.5 px-4 cursor-pointer rounded-md text-sm font-normal ${
                  activeButton === "My Plugins"
                    ? "text-PRIMARY bg-[#FAFAFA]"
                    : "text-SECONDARY"
                }`}
                onClick={() => handleButtonClick("My Plugins")}
              >
                <span>My Apps</span>
              </li>
            </ul>
          </div>
          <div className="w-full border-l border-[#F7F7F7] p-10">
            <ChangesBanner setPropShowPopUp={setPropShowPopUp} />
            <TestAgent
              propShowPopUp={propShowPopUp}
              setPropShowPopUp={setPropShowPopUp}
            />

            {activeButton === "All Plugins" && (
              <div className="w-full h-[192px] flex items-center px-11 bg-gradient-to-br from-[#f8c4d326] f7f7f8f3 via-[#c7c9e130] to-[#1774fd05]">
        
                <div className="">
                  <h2 className="text-[#052C67] font-bold text-[32px] ">
           
                    <ReactTyped
                      strings={texts}
                      typeSpeed={100}
                      loop
                      backSpeed={20}
                      cursorChar=""
                      showCursor={true}
                    />
                    <br />
                    with Apps
                  </h2>
                  <p className="text-[#828282] text-sm font-medium mt-5">
                    Supercharge your AI’s capabilities with a wide range of
                    Apps.
                  </p>
                </div>
              </div>
            )}
            {activeButton === "All Plugins" && (
              <AllPlugins2 setActiveButton={setActiveButton} />
            )}
            {activeButton === "My Plugins" && (
              <MyPlugins setActiveButton={setActiveButton} />
            )}
          </div>
        </section> */}
      {/* <ModalPop isOpen={openTourModal}>
        <BottomGuide
          title="App Store"
          subtitle="Add software tools to make you A.I more powerful."
          link="View App Store"
          handleClose={() => {
            handleAgentModal('plugins')
            setOpenTourModal(false);
         
          }}
          image={PluginsSvg}
          className="h-[275px]"
        />
      </ModalPop> */}
      <ModalPop isOpen={appIdeaModal}>
        <MakeRequest
          handleClose={() => setAppIdeaModal(false)}
          text={
            "Have an app that we can add for the Customer Service Assistant? Let us know below"
          }
          title={"App Idea"}
          buttonText={"Send Idea"}
        />
      </ModalPop> 
    </DashboardLayout>
  );
};

export default Plugins;

import React from 'react'
import { HiXMark } from 'react-icons/hi2'

const NotifyToInstallAppModal = ({ handleClose }) => {
  return (
    <div className='bg-[#fff] w-[501px] h-fit flex flex-col rounded-lg mt-[100px] gap-[20px]'>
        <div className='pt-[30px] px-[50px] flex items-center justify-between rounded-lg'>{/* w-[48px] h-[48px] border-b-[#E5E5E5] border */}
            <h2 className='text-[#101828] text-lg font-medium'>Install App</h2>
            <div onClick={handleClose} className="border p-2 -mt-1 rounded-lg border-[#F7F7F7] cursor-pointer">
                <HiXMark className="text-[20px] text-[#828282]" />
            </div> 
        </div>
        <div className='flex flex-col justify-center gap-5 border-t-[1px] border-[#E5E5E5] py-[30px] px-[45px]'>
            {/* <img src={data?.image_url} alt='bank' className='w-[48px] h-[48px]' />  */}
            {/* <div>
                <p className='text-[#101828] text-lg'>Connect to your {data?.name} Account</p>
                <p className='text-[#7F7F81] text-xs'>Provide the information below to allow us connect to your {data?.name} Account</p>
            </div> */}
            <p className='text-sm font-medium text-center text-[#828282]'>
                This Application is currently not installed for your Digital Assistant. 
                To use this application, you need to install it from APPSTORE.
            </p>
            
            <div className='w-full flex gap-[12px] justify-end mt-[20px]'>
                <button 
                    className="w-fit h-[37px] py-3 px-5 rounded-lg border-[#E5E5E5] border flex justify-center items-center font-medium  text-[#868686] text-sm"
                    type="submit"
                    onClick={() => handleClose()}
                >
                    Close
                </button>
                {/* <button 
                    className="w-fit h-[37px] py-3 px-5 rounded-lg flex justify-center items-center font-medium bg-BLACK-_500 text-WHITE-_100 text-sm"
                    type="submit"
                    onClick={() => ConnectPlugin()}
                >
                    <p className='text-WHITE-_100 text-sm font-bold'>{loading ? <CgSpinner className=" animate-spin text-lg " /> : 'Connect'}</p>  
                </button> */}
            </div>

        </div>
    </div>
  )
}

export default NotifyToInstallAppModal
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { api } from "../../services/api"
import { appUrls } from "../../services/urls";



const initialState = {
    loading: false,
    data: [],
    error: ""
};

export const resetAgentType = createAsyncThunk(
    "agent/resetAgentType", 
    async({ id, data}, { rejectWithValue }) => {
        try {
            const res = await api.put(`/dashboard/applications/${id}/reset-type`, data);
            console.log(res, "lala")
            return res?.data

        } catch (error) {
            console.log(error, "zala")
            return rejectWithValue(error?.data?.message)
        }
    }
)

const agentResetTypeSlice = createSlice({
    name: "agent",
    initialState,
    extraReducers: builder => {
        builder.addCase(resetAgentType.pending, state => {
            state.loading = true
        });
        builder.addCase(resetAgentType.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(resetAgentType.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default agentResetTypeSlice.reducer
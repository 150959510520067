import React from "react";

const DashboardCard = ({ imageSrc, title, description, imgClassName }) => {
  const cardStyle = {
    background:
      "linear-gradient(142.7deg, rgba(202, 98, 239, 0.105) -31.6%, rgba(75, 146, 251, 0.075) 36.78%, rgba(246, 192, 209, 0.09) 109.48%)",
    // padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  };

  return (
      <div className="flex items-center justify-between rounded-[12px] pt-[20px] h-[153px] pl-[16px] pr-0 pb-[50px]" style={cardStyle}>
        <div className="mx-auto w-full rounded-[8px] rounded-b-none">
          <h2 className="text-[14px] leading-[19.6px] font-medium text-[#121212]">
            {title}
          </h2>
          <p className="text-[12px] leading-[18px] text-[#7F7F81] mt-[23px]">
            {description}
          </p>
        </div>
      <img
        src={imageSrc}
        alt="Card image"
        className={`w-[100.68px] h-[100.68px] self-end ${imgClassName}`}
      />
      </div>
  );
};

export default DashboardCard;

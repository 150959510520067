import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import TokenService from "../services/token";

export default function Pricing() {
  const [credit, setCredit] = useState();
  const token = TokenService.getToken();
  const URL = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  let agentSettings = "settings";

  useEffect(() => {
    const fetchCredit = async () => {
      try {
        const credit = await axios.get(`${URL}/dashboard/businesses/credits`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const res = credit?.data?.data;
        setCredit(res);
        console.log(res, "console of response");
      } catch (error) {
        console.log(error, "this is the error");
      }
    };

    fetchCredit();
  }, []);

  const formatCredits = (credits) => {
    if (credits && credits.length >= 2) {
      return credits.slice(0, 2) + "," + credits.slice(2);
    }
    return credits;
  };

  return (
    <main className=" mt-6">
      <p className="text-[#7F7F81] text-sm font-normal">
        At Wano, we offer a flexible subscription model. Our pricing structure
        is designed to be transparent and accessible for businesses of all
        sizes. Here's an overview of how it works:
      </p>
      <ul className=" mt-[28px] px-4">
        <section>
          <li className=" text-[#292D32] text-sm font-normal list-decimal leading-[173%] ">
            Early Explorer (For early explorers){" "}
            <span className="text-[#7F7F81]">Price: $0/month</span>
          </li>
          <ul className="pl-4">
            <li className=" text-sm font-normal list-disc text-[#7F7F81] leading-[173%]">
              Features include : 2 team members/seats, 1 business and $0.20 per
              resolution
            </li>
            {/* <li className=" text-sm font-normal list-disc text-[#7F7F81] leading-[173%]">
              <span className="font-semibold">Cost</span>: $0.01 per 1 credits.
            </li>
            <li className=" text-sm font-normal list-disc text-[#7F7F81] leading-[173%]">
              4 Credits per Task
            </li>
            <li className=" text-sm font-normal list-disc text-[#7F7F81] leading-[173%]">
              $0.09 per Task
            </li> */}
            {/* <li className=" text-sm font-normal list-disc text-[#7F7F81] leading-[173%]">
                $0.02 per image (100 credits)
              </li>
              <li className=" text-sm font-normal list-disc text-[#7F7F81] leading-[173%]">
                $0.01 per 1 minute audio (50 credits)
              </li> */}
            {/* <li className=" text-sm font-normal list-disc text-[#7F7F81] leading-[173%]">
              At Wano, we believe in transparent pricing, this means that you’re
              able to determine how your credits get used. You can determine how
              detailed or concise your employee’s response is under Verbosity in
              your{" "}
              <span
                onClick={() => navigate("/agent", { state: agentSettings })}
                className="cursor-pointer underline text-[#1774FD]"
              >
                Employee Settings
              </span>
            </li> */}
          </ul>
        </section>
        <section>
          <li className=" text-[#292D32] text-sm font-normal list-decimal leading-[173%] ">
            Team (For teams needing more collaboration){" "}
            <span className="text-[#7F7F81]">Price: $99/month</span>
          </li>
          <ul className="pl-4">
            <li className=" text-sm font-normal list-disc text-[#7F7F81] leading-[173%]">
              Features include: 5 users/seats, Third-party app integration,
              Setup assistance (once monthly), 1 business, and $0.15 per
              resolution
            </li>
          </ul>
        </section>
        <section>
          <li className=" text-[#292D32] text-sm font-normal list-decimal leading-[173%] ">
            Business (For growing teams)
            <span className="text-[#7F7F81]">Price: $499/month</span>
          </li>
          <ul className="pl-4">
            <li className=" text-sm font-normal list-disc text-[#7F7F81] leading-[173%]">
              Features include: 25 users/seats, access to Third-party app
              integration, Setup assistance (four times a month/once weekly), 3
              businesses, and $0.1 per task
            </li>
          </ul>
        </section>
        <section>
          <li className=" text-[#292D32] text-sm font-normal list-decimal leading-[173%]">
            Enterprise (For teams operating at scale){" "}
            <span className="text-[#7F7F81]">Price: Custom pricing</span>
          </li>
          <ul className="pl-4">
            <li className=" text-sm font-normal list-disc text-[#7F7F81] leading-[173%]">
              {" "}
              Features include: Unlimited users/seats, Third-party app
              integration, Custom app integration, Daily setup assistance,
              Volume-based discounts (up to 50%), Advanced customization,
              Unlimited support, Dedicated account manager, Dedicated server,
              Wano partner program (up to $5000 Wano credits for your
              customers). {" "}
            </li>
            <Link to="" className=" underline text-[#1774FD] text-sm ">
              Contact us
            </Link>{" "}
            <span className=" text-sm font-normal list-disc text-[#7F7F81] leading-[173%]">
              to get started with enterprise pricing.
            </span>
          </ul>
        </section>
      </ul>

      <section>
        <p className=" text-[#292D32] text-sm font-normal my-7">
          How to Purchase Credits:{" "}
          <span className="text-sm font-normal text-[#7F7F81]">
            To purchase credits, simply log in to your Wano account and visit
            the{" "}
            <Link to="/top-up" className=" underline text-[#1774FD] ">
              Billings
            </Link>{" "}
            section. There, you can decide to top up your credits as needed or
            contact us to hear about our Enterprise pricing. The minimum funding amount afterwards is $5 (5 credits)
          </span>
        </p>
      </section>
      <section>
        <p className=" text-[#7F7F81] text-sm font-normal">
          Please note:{" "}
          <span className="text-sm font-normal text-[#7F7F81]">
          As a new user, you get $5 free credits when you first sign up on Wano.
            {/* <span> {Math.floor(credit?.tokens)} Credits</span>{" "} */}
            {/* free credits when they first sign up on Wano. */}
          </span>
        </p>
        <p className="text-sm font-normal text-[#7F7F81]">
          If you have any questions about our pricing or need assistance with
          your billing, please feel free to{" "}
          <a
            href="mailto:Support@wano.app"
            className=" underline lowercase text-[#1774FD] "
          >
            contact our support team
          </a>{" "}
          for further guidance.
        </p>
      </section>
    </main>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { Form, Formik } from "formik";
import { Popover } from "@headlessui/react";
import { HiCheck } from "react-icons/hi";
import { FiChevronDown } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { CgSpinner } from "react-icons/cg";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { fetchTeamMembers } from "../../features/teams/getTeamsSlice";
import { fetchRole } from "../../features/role/getRoleSlice";
import { inviteTeamMember } from "../../features/teams/inviteTeamMemberSlice";
import TableMenu from "../../component/TableMenu";
import { deleteTeamMember } from "../../features/teams/deleteTeamMemberSlice";
import { DotLoader } from "react-spinners";
import {
  ErrorMessage,
  SuccessMessage,
} from "../../Toast-Messages/Toastmessages";
import { Skeleton } from "@mui/material";
import { getCurrentPlan } from "@/../features/profile/getCurrentPlanSlice";
import UpgradeToPlan from "@/../Routes/UpgradeToPlan";
import ModalPop from "@/../component/modalPop";
import UpgradePlan from "@/../Routes/UpgradePlan";
import RedeemSuccess from "@/../component/modals/redeemgift/RedeemSuccess";
import { useModal } from "@/../context/ModalContext";

const Teams = () => {
  const { openModal } = useModal();
  const [teamRoleId, setTeamRoleId] = useState("");
  const [inviteError, setInviteError] = useState();
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [deleteMemberSuccess, setDeleteMemberSuccess] = useState(false);
  const [openUpgradeToPlan, setOpenUpgradeToPlan] = useState(false);
  const [openRedeemSuccess, setOpenRedeemSuccess] = useState(false);
  const [openUpgradePlan, setOpenUpgradePlan] = useState(false);
  const [openPayModal, setOpenPayModal] = useState(false);

  const formValidationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    role: Yup.string().required(),
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrentPlan());
  }, []);

  const plan = useSelector((state) => state.getCurrentPlan.data);
  const { subscriptions, currentPlan: current } = plan;
  const currentPlan = current?.subscription_details?.name;
  const nextAvailablePlanAmount = subscriptions?.[1]?.monthly_amount;

  const teams = useSelector((state) => state.teamMembers);

  const roles = useSelector((state) => state.allRoles);
  const Roles = roles?.data;

  const deletedMember = useSelector((state) => state.deleteMember);
  // console.log(deletedMember, "baddo")

  const { loading } = useSelector((state) => state.inviteMember);
  let { error } = useSelector((state) => state.inviteMember);
  let { data } = useSelector((state) => state.inviteMember);
  let inviteMember = useSelector((state) => state.inviteMember);

  const submitForm = async (values) => {
    const data = {
      email: values?.email,
      role_id: teamRoleId,
    };
    if (currentPlan.includes("Startups")) {
      return openModal("ModalB", { title: "Invite Team Members", text: "Add your team members for easy collaboration and support", icon: "isotoxal" });
    } else {
      dispatch(inviteTeamMember(data)).then((res) => {
        if (res?.payload === "Invite sent") {
          setInviteSuccess(true);
        } else {
          setInviteError(true);
        }
      });
    }
  };

  const handleRemoveService = (value) => {
    dispatch(deleteTeamMember(value)).then((res) => {
      if (res?.payload?.status) {
        setDeleteMemberSuccess(true);
      }
    });
  };

  const removeDeleteNotification = () => {
    setTimeout(() => {
      setDeleteMemberSuccess(false);
    }, 1000);
  };

  const removeInviteNotification = () => {
    setTimeout(() => {
      setInviteError(false);
    }, 1000);
  };
  const removeSuccessNotification = () => {
    setTimeout(() => {
      setInviteSuccess(false);
    }, 1000);
  };

  useEffect(() => {
    removeDeleteNotification();
  }, [deleteMemberSuccess]);

  useEffect(() => {
    removeSuccessNotification();
  }, [inviteSuccess]);

  useEffect(() => removeInviteNotification(), [inviteError]);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  useEffect(() => {
    if (deletedMember?.loading === true) {
      dispatch(fetchTeamMembers());
    }
  }, [deletedMember?.loading]);

  useEffect(() => {
    if (loading) {
      dispatch(fetchTeamMembers());
    }
  }, [inviteSuccess]);

  useEffect(() => {
    dispatch(fetchTeamMembers());
  }, []);
  const showRemoveConditions = (team) => {
    const myTeamProfile = teams.data.find(
      (each) => each.invite_email === profile.email
    );
    // console.log("teams is", team);

    if (profile.email === team.invite_email) {
      return false;
    } else if (myTeamProfile.role.name !== "ADMIN") {
      return false;
    } else {
      return true;
    }
  };
  const getUserProfile = useSelector((state) => state.getProfile);
  const profile = getUserProfile?.data?.data;
  return (
    <div className="">
      {/* mt-[52px] */}
      <div className="flex justify-between">
        <div className="w-[229px] h-[35px] flex flex-col gap-1">
          <p className="font-medium text-BLACK-_100 text-sm">Team</p>
          <p className="text-xs text-BLACK-_200">
            Invite and collaborate with team members.
          </p>
        </div>
        <div className="">
          <Formik
            initialValues={{
              email: "",
              role: "",
            }}
            validationSchema={formValidationSchema}
            onSubmit={(values, actions) => {
              window.scrollTo(0, 0);
              // console.log(values, "often")
              submitForm(values);

              actions.resetForm();
            }}
          >
            {({
              handleSubmit,
              handleChange,
              dirty,
              isValid,
              setFieldValue,
              errors,
              touched,
              // setFieldTouched,
              values,
            }) => (
              <Form onSubmit={handleSubmit} className="flex items-center gap-3">
                <div className="flex gap-5 items-center h-[38px]">
                  <div className="flex flex-col">
                    <label
                      htmlFor="email"
                      className="text-xs font-normal text-[#101828]"
                    >
                      Email
                    </label>
                    <input
                      name="email"
                      placeholder=""
                      type="text"
                      value={values.email}
                      onChange={handleChange}
                      className="rounded-lg border-[#D0D5DD]  outline-none w-full  h-[38px] border-solid  p-3 border"
                    />
                    {/* {errors.email && touched.email ? (
                                        <div className='text-RED-_100 text-xs'>{errors.email}</div>
                                        ) : null} */}
                  </div>

                  <div className="flex flex-col">
                    <label className="label">
                      Your Role
                      <Popover className="relative pop">
                        <Popover.Button>
                          <input
                            name="role"
                            type="text"
                            // disabled
                            value={values?.role}
                            onChange={handleChange}
                            className="w-[229px] h-[38px] bg-white outline-none cursor-pointer pr-16"
                          />
                          <FiChevronDown
                            className={` absolute top-1 right-0 px-2 py-1 text-3xl cursor-pointer
                                                    ${
                                                      values?.role !== ""
                                                        ? "text-black"
                                                        : "text-[#828282]"
                                                    }`}
                          />
                        </Popover.Button>
                        <Popover.Panel className=" absolute w-full bg-white z-50  overflow-y-auto shadow-xl flex flex-col rounded-lg">
                          {Roles.map((Role, index) => (
                            <Popover.Button
                              key={index}
                              type="button"
                              onClick={() => {
                                setFieldValue("role", Role?.name);
                                setTeamRoleId(Role?.id);
                              }}
                              className=" py-[10px] px-3 select-none cursor-pointer hover:bg-[rgba(205,222,248,0.20)] flex justify-between"
                            >
                              <p className=" text-xs font-normal text-[#101828]">
                                {Role?.name}
                              </p>
                              {values?.role === Role?.name && (
                                <HiCheck className="text-[17px] text-[rgba(23,116,253,1)]" />
                              )}
                            </Popover.Button>
                          ))}
                        </Popover.Panel>
                      </Popover>
                    </label>
                    {/* {errors.role && touched.role ? (
                                    <div className='text-RED-_100 text-xs'>{errors.role}</div>
                                    ) : null} */}
                  </div>
                </div>

                <div className="w-[188px]  flex flex-col items-center mt-[14px] justify-end">
                  <button
                    className="w-[188px] h-[38px] bg-BLACK-_500 rounded-lg px-[20px] py-[10px]"
                    type="submit"
                  >
                    {loading ? (
                      <CgSpinner className=" animate-spin text-lg text-WHITE-_100 mx-auto " />
                    ) : (
                      <div className="flex gap-2">
                        <p className="text-WHITE-_100 text-xs">
                          Invite Team Member
                        </p>
                        <p className="bg-[#1774FD] text-white px-[6px] py-[4px] text-[10px] leading-[12px] rounded-[4px]">
                          Pro
                        </p>
                      </div>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          <div className="mt-8">
            <p className="text-BLACK-_100 font-medium mb-5 text-sm">Members</p>
            {deletedMember?.loading ? (
              <Skeleton
                variant="rectangular"
                width={582}
                height={100}
                style={{
                  backgroundColor: "rgba(249,249,249,0.86)",
                  borderRadius: "12px",
                }}
              />
            ) : (
              <div className="bg-[#fcfcfcad] w-[582px] rounded-lg">
                {teams &&
                  teams.data.map((team, index) => {
                    return (
                      <div
                        className="flex justify-between items-center border-b border-[#F4F4F4] mx-[20px]"
                        key={index}
                      >
                        <p className="text-xs text-BLACK-_200">
                          {team?.invite_email}
                        </p>
                        <div
                          className={`flex items-center gap-2.5
                          ${showRemoveConditions(team) ? "" : " mr-10 py-2 "}
                        
                        `}
                        >
                          <div
                            className={`${
                              team?.user?.status === "active"
                                ? "bg-[#dff8e74d] w-[59px] h-[22px]"
                                : "bg-[#d7d7d740] w-[75px] h-[22px]"
                            }
                            
                          
                            rounded-lg flex flex-col justify-center items-center`}
                          >
                            <div className="flex gap-0.5 items-center">
                              <div
                                className={`${
                                  team?.user?.status === "active"
                                    ? " rounded w-[4px] h-[4px] bg-[#009733]"
                                    : "hidden"
                                }`}
                              ></div>
                              <p
                                className={`${
                                  team?.user?.status === "active"
                                    ? "text-[#009733]"
                                    : "text-BLACK-_200"
                                } text-xs`}
                              >
                                {team?.user?.status === "active"
                                  ? "Active"
                                  : "Invite Sent"}
                              </p>
                            </div>
                          </div>
                          {showRemoveConditions(team) && (
                            <TableMenu
                              options={[
                                <div
                                  to="#"
                                  key="1"
                                  className="flex gap-1 items-center"
                                  onClick={() => handleRemoveService(team?.id)}
                                >
                                  <MdDeleteForever className="text-RED-_100" />
                                  {deleteTeamMember.loading ? (
                                    <CgSpinner className=" animate-spin text-lg text-RED-_100 mx-auto " />
                                  ) : (
                                    <p className=" text-[11px] text-RED-_100">
                                      Remove
                                    </p>
                                  )}
                                </div>,
                              ]}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
      {inviteError && (
        <ErrorMessage Formodal={true} error={error} className="mt-24 mr-20" />
      )}
      {inviteSuccess && (
        <SuccessMessage
          Formodal={true}
          success={data}
          className="mt-24 mr-20"
        />
      )}
      {deleteMemberSuccess && (
        <SuccessMessage
          Formodal={true}
          success={deletedMember?.data?.message}
          className="mt-24 mr-20"
        />
      )}

      {/* <ModalPop isOpen={openUpgradeToPlan}>
        <UpgradeToPlan
          handleClose={() => {
            setOpenUpgradeToPlan(false);
          }}
          nextModal={() => setOpenUpgradePlan}
          setOpenUpgradePlan={setOpenUpgradePlan}
          amount={nextAvailablePlanAmount}
        />
      </ModalPop>

      <ModalPop isOpen={openUpgradePlan}>
        <UpgradePlan
          handleClose={() => setOpenUpgradePlan(false)}
          setOpenRedeemSuccess={setOpenRedeemSuccess}
          data={subscriptions?.[1]}
          refresh={() => dispatch(getCurrentPlan())}
          setOpenPayModal={setOpenPayModal}
        />
      </ModalPop>

      <ModalPop isOpen={openRedeemSuccess}>
        <RedeemSuccess
          handleClose={() => setOpenRedeemSuccess(false)}
          title={"Upgrade Successful"}
          text1={"You have successfully upgraded to the Business Plan"}
          label={"Done"}
          redirectPath="/settings?page=billing&category=plan"
        />
      </ModalPop> */}
    </div>
  );
};

export default Teams;

import { api } from "@/../services/api";
import { useAuth } from "@/../context/UserContext";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

const CancelPlan = ({
  handleClose,
  state,
  token,
  heading,
  warning,
  label1,
  label2,
  data,
  refresh,
}) => {

  const { basePlan } = useAuth();
  const id = basePlan?.id;
  const navigate = useNavigate();
  // console.log(id, "hi dee")
  // console.log(basePlan, "base base")

  const handleDowngrade = async () => {
    await api
      .post(`/business-subscription/downgrade/${id}`)
      .then((res) => {
        navigate("/settings?page=billing&category=plan");
        handleClose();
      })
      .catch((error) => console.log(`We could not process because ${error}`));
  };

  return (
    <div className="bg-[#fff] w-[496px] h-[280px] py-[30px] px-[50px] flex flex-col rounded-lg mt-[100px] gap-[40px]">
      <p className="text-center text-2xl font-medium">{heading}</p>
      <p className="text-center text-[#7F7F81] text-sm">{warning}</p>
      <div className="w-[420px] mx-auto flex gap-[12px] mt-[20px] mb-[80px]">
        <button
          className="w-[186px] flex justify-center items-center rounded-lg font-medium text-[#868686] text-sm border border-neutral-200"
          type="submit"
          onClick={() => handleClose()}
        >
          {label1}
        </button>
        <button
          onClick={handleDowngrade}
          className="w-[186px] h-[40px] py-3 px-5 rounded-lg flex justify-center items-center font-medium bg-[#AF202D] text-WHITE-_100 text-sm"
          type="submit"
        >
          {label2}
        </button>
      </div>
    </div>
  );
};

export default CancelPlan;

import React from 'react'
import { Link, useLocation } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import DashboardLayout from '../../layout/DashboardLayout'
import MyPlugins from './components/MyPlugins';
import OtherPlugins from '../AllPluginType/components/OtherPlugins'

const PluginType = () => {
    const { state } = useLocation()
    return (
        <DashboardLayout title={"App Store"} user={true}>
            <main className=" font-figtree mt-3 mb-7 p-10">
                <header className=" flex gap-2 items-center mb-4">
                    <Link
                        to="/plugins"
                        className="text-[#D2D0D0] text-sm font-normal hover:underline"
                    >
                        My Apps
                    </Link>
                    <BiChevronRight className=" text-xl text-[#D2D0D0]" />
                    <p className="text-[#121212] text-sm font-normal ">{state.plugin.name}</p>
                </header>
                <div className='mt-[46px]'>
                    <MyPlugins />
                </div>
            </main>
        </DashboardLayout>
    )
}

export default PluginType
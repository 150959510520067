import React, { useContext, useState } from 'react'
import Upload from "../../../Assets/svg/upload.svg"
import Id from "../../../Assets/svg/id_verification.svg"
import Button from '../../Button'
import Peer from './whatsapp/Peer'
import ModalPop from '../../modalPop'
import { useLocation, useNavigate } from 'react-router'
import { UserContext } from '../../../context/UserContext'


const Verify = ({ handleClose, kyc }) => {
    const [openPeerModal, setOpenPeerModal] = useState(false)

    const [file, setFile] = useState()

    function handleChange(event) {
      setFile(event.target.files[0])
    };

    const { userType } = useContext(UserContext)

    const navigate = useNavigate()
    const location = useLocation()

    //navigate("/dashboard")

    const submitDocument = () => {
        localStorage.setItem("Kyc", true);
        handleClose()
    }

  return (
    <div className="bg-WHITE-_100 w-[520px] h-[399px] mt-[60px] flex flex-col justify-center items-center rounded-lg">
        <div className="w-[420px] h-[339px] ">
            <div className="flex flex-col justify-center items-center gap-5">
                {"Sales" === "Authentication" ? <div className='w-[35px] h-[28px]'></div> : <img src={Id} alt="Id" className='w-[35px] h-[28px]' />}
                <p className="text-2xl font-medium text-BLACK-_600">KYC Verification</p>
                <p className="text-xs font-normal text-BLACK-_300">We’ll need to verify your identity before you go live!</p>
            </div>
            <div className="w-[420px] h-[207px] mt-5 flex flex-col gap-8">
                <div className="flex justify-between">
                    <div className="cursor-pointer w-[199px] h-[99px] border border-solid border-WHITE-_400 rounded-lg flex flex-col items-center justify-center">
                        <div className='h-[5px]'>
                            <input
                                type="file"
                                className="opacity-0"
                                onChange={handleChange}
                                id="upload"
                                accept={"image/*"}
                                multiple={false}
                            />
                        </div>
                       <div className='flex flex-col gap-1 items-center justify-center'>
                            <img src={Upload} alt="upload" className="w-[17px] h-[17px]" />
                            <p className="text-[10px] font-normal text-BLACK-_200">Upload your CAC Certificate</p>
                            <p className="text-[7px] text-BLACK-_200">Supported file PDF, 100MB Max</p>
                       </div>
                    </div>
                    <div className="cursor-pointer w-[199px] h-[99px] border border-solid border-WHITE-_400 rounded-lg flex flex-col items-center justify-center">
                        <div className='h-[5px]'>
                            <input
                                type="file"
                                className="opacity-0"
                                onChange={handleChange}
                                id="upload"
                                accept={"image/*"}
                                multiple={false}
                            />
                        </div>
                       <div className='flex flex-col gap-1 items-center justify-center'>
                            <img src={Upload} alt="upload" className="w-[17px] h-[17px]" />
                            <p className="text-[10px] font-normal text-BLACK-_200">Upload your Identification Card</p>
                            <p className="text-[7px] text-BLACK-_200">Supported file PDF, 100MB Max</p>
                       </div>
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <Button 
                        onClick={handleClose}  
                        text="Complete Later" 
                        className="bg-none text-sm font-semibold text-[#868686]"
                        type="button" 
                    />
                    <Button 
                        text="Submit"
                        type="button" 
                        onClick={() => submitDocument()}
                        className="bg-WHITE-_200 cursor-pointer text-WHITE-_100 font-bold rounded-lg h-[40px]" 
                    />
                </div>
            </div>
        </div>

        <ModalPop isOpen={openPeerModal}>
            <Peer handleClose={() => setOpenPeerModal(false) }  />
        </ModalPop>

    </div>
  )
}

export default Verify
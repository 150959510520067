import { HiXMark } from "react-icons/hi2";
import { useState } from "react";
import { Radio } from "@mui/material";
import { BsCheck } from "react-icons/bs";
export default function WordinessLevelModal({
  handleClose,
  selectedVerbosity,
  handleVerbosity,
}) {
 const [selectedVerbosityModal, setSelectedVerbosityModal] =
   useState(selectedVerbosity);

 const handleCheckboxChange = (checkboxName) => {
   setSelectedVerbosityModal(checkboxName);
 };

 const handleSaveChanges = () => {
   if (selectedVerbosityModal) {
     handleVerbosity(selectedVerbosityModal);
   }

   handleClose();
 };

  const CheckedIcon = () => {
    return (
      <div className="w-5 h-5 bg-[#1774FD] rounded-[999px] flex justify-center items-center">
        <BsCheck className="text-white w-5 h-5" />
      </div>
    );
  };
  return (
    <div className=" w-[1020px] h-[520px] bg-white mt-10 ml-36 rounded-lg shadow-makeReqModal font-figtree relative">
      <header className=" pl-10 flex pr-6 justify-between pt-[20px] pb-2">
        <h2 className="text-[#101828] font-medium text-lg">
          Choose your Verbosity Level
        </h2>
        <div
          className="border p-2 -mt-1 rounded-lg border-[#F7F7F7] cursor-pointer"
          onClick={handleClose}
        >
          <HiXMark className="text-[20px]   text-[#828282]" />
        </div>
      </header>
      <hr />
      <section className="pt-6 px-[40px]">
        <p className=" text-[#7F7F81] text-sm  mb-6 font-normal w-[500px]">
          Adjust Assistant’s response detail or word’s used. Note that this directly
          affects Pricing and may affect your users experience with your assistant.
        </p>
        <section className="flex gap-5 flex-wrap">
          <div
            className={`w-[300px]  border border-solid  rounded-xl bg-[#FAFAFA]  ${
              selectedVerbosityModal === "concise"
                ? "border-[#1774FD]"
                : "border-[#E5E5E5]"
            }`}
          >
            <section
              className={`pt-[20px] rounded-t-xl ${
                selectedVerbosityModal === "concise"
                  ? " bg-[rgba(205,222,248,0.20)]"
                  : " bg-[white]"
              }`}
            >
              <div className="flex justify-between mb-2 px-[15px] gap-[43px]">
                <div>
                  <p className="text-base text-[#121212] font-medium">
                    Concise
                  </p>
                  <p className="text-[#7F7F81] font-normal text-xs pb-[14px]">
                    Your Assistant’s response will be brief and to the point.
                  </p>
                </div>
                <Radio
                  sx={{
                    color: "#EBEBEB",
                    height: 20,
                    width: 20,
                    border: "1px",
                    background: "#fff",
                  }}
                  checkedIcon={<CheckedIcon />}
                  checked={selectedVerbosityModal === "concise"}
                  onClick={() => handleCheckboxChange("concise")}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
            </section>
            <div className="py-3 px-[15px] rounded-b-xl">
              <div className="flex justify-end">
                <p className="text-right bg-[#D7FAD1] rounded-lg p-2 text-xs font-normal text-[#171A16]  mt-7">
                  What is this business about?
                </p>
              </div>
              <p className="text-left bg-white rounded-lg p-2 text-xs font-normal text-[#171A16] mt-2">
                This business is focused on completing transaction for
                customers.
              </p>
            </div>
          </div>
          <div
            className={`w-[300px]  border border-solid  rounded-xl bg-[#FAFAFA]  ${
              selectedVerbosityModal === "balanced"
                ? "border-[#1774FD]"
                : "border-[#E5E5E5]"
            }`}
          >
            <section
              className={`pt-[20px] rounded-t-xl ${
                selectedVerbosityModal === "balanced"
                  ? " bg-[rgba(205,222,248,0.20)]"
                  : " bg-[white]"
              }`}
            >
              <div className="flex justify-between mb-2 px-[15px] gap-[43px]">
                <div>
                  <p className="text-base text-[#121212] font-medium">
                    Balanced
                  </p>
                  <p className="text-[#7F7F81] font-normal text-xs pb-[14px]">
                    Your Assistant’s response will have a moderate level of detail.
                  </p>
                </div>
                <Radio
                  sx={{
                    color: "#EBEBEB",
                    height: 20,
                    width: 20,
                    border: "1px",
                    background: "#fff",
                  }}
                  checkedIcon={<CheckedIcon />}
                  checked={selectedVerbosityModal === "balanced"}
                  onClick={() => handleCheckboxChange("balanced")}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
            </section>
            <div className="py-3 px-[15px] rounded-b-xl">
              <div className="flex justify-end">
                <p className="text-right bg-[#D7FAD1] rounded-lg p-2 text-xs font-normal text-[#171A16] mt-4">
                  What is this business about?
                </p>
              </div>
              <p className="text-left bg-white rounded-lg p-2 text-xs font-normal text-[#171A16] mt-2">
                This business primarily operates in the Financial sector,
                specializing in providing safe international and local
                transactions for their customers.
              </p>
            </div>
          </div>
          <div
            className={`w-[300px]  border border-solid  rounded-xl bg-[#FAFAFA]  ${
              selectedVerbosityModal === "detailed"
                ? "border-[#1774FD]"
                : "border-[#E5E5E5]"
            }`}
          >
            <section
              className={`pt-[20px] rounded-t-xl ${
                selectedVerbosityModal === "detailed"
                  ? " bg-[rgba(205,222,248,0.20)]"
                  : " bg-[white]"
              }`}
            >
              <div className="flex justify-between mb-2 px-[15px] gap-[43px]">
                <div>
                  <p className="text-base text-[#121212] font-medium">
                    Detailed
                  </p>
                  <p className="text-[#7F7F81] font-normal text-xs pb-[14px]">
                    Your Assistant’s response will always be comprehensive and
                    thorough.
                  </p>
                </div>
                <Radio
                  sx={{
                    color: "#EBEBEB",
                    height: 20,
                    width: 20,
                    border: "1px",
                    background: "#fff",
                  }}
                  checkedIcon={<CheckedIcon />}
                  checked={selectedVerbosityModal === "detailed"}
                  onClick={() => handleCheckboxChange("detailed")}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
            </section>
            <div className="py-3 px-[15px] rounded-b-xl">
              <div className="flex justify-end">
                <p className="text-right bg-[#D7FAD1] rounded-lg p-2 text-xs font-normal text-[#171A16] mt-2">
                  What is this business about?
                </p>
              </div>
              <p className="text-left bg-white rounded-lg p-2 text-xs font-normal text-[#171A16] mt-2">
                This fintech company specializes in digital payment solutions,
                revolutionizing the way people handle their finances. They offer
                a wide range of services, including mobile payment apps, online
                banking platforms, secure ...
              </p>
            </div>
          </div>
        </section>
        <section className="flex justify-end gap-[10px] absolute bottom-3 right-[40px]">
          <button
            onClick={handleClose}
            className="outline-none rounded-lg border border-solid border-[#D0D5DD] px-5 py-[10px] text-xs font-semibold text-[#828282]"
          >
            Cancel
          </button>
          <button className="outline-none py-[10px] px-5 bg-[#121212] rounded-lg  text-white text-xs font-semibold" onClick={handleSaveChanges}>
            Save Changes
          </button>
        </section>
      </section>
    </div>
  );
}

import { useNavigate } from "react-router";
import arrrowIcon from "../../../Assets/img/arrow-narrow-right.svg";

export const TopGuide = ({
  link,
  image,
  title,
  subtitle,
  handleClose,
  close,
}) => {
  const closeModal = () => {
    handleClose();
  };
  return (
    <div className="w-[574px] h-fit py-[54px] mt-[127px] bg-WHITE-_100 rounded-lg flex flex-col ">
      {close ? (
        <div className="w-full flex justify-end">
          {/* <div className="w-[36px] h-[36px] border rounded-lg mr-3 mt-3 flex cursor-pointer justify-center items-center p-2" onClick={handleClose}><AiOutlineClose /></div> */}
        </div>
      ) : null}
      <div className="rounded-lg w-[476px] mx-auto h-[133px]  flex items-center justify-center">
        {" "}
        {/* bg-[#FAFBFC] */}
        <img src={image} alt="" className="" />
      </div>
      <div
        className={`${
          close ? "w-[480px]" : "w-[372px]"
        } flex flex-col mx-auto text-center mt-[30px]`}
      >
        <h2 className="font-medium text-[28px] text-[#121212]">{title}</h2>
        <h4 className="text-base text-[#7F7F81]">{subtitle}</h4>
      </div>
      <div
        className={`${close ? "flex flex-col gap-5 " : ""} mt-[51px] mx-auto`}
      >
        {close ? (
          <p className="text-base font-normal text-center text-[#7F7F81]">
            Try it Out
          </p>
        ) : null}
        <div
          className="flex gap-2  items-center mx-auto cursor-pointer"
          onClick={() => {
            closeModal();
          }}
        >
          <p
            className="text-base font-medium text-BLUE-_200"
            style={{
              background: close
                ? "linear-gradient(92deg, #CA62EF 9.9%, #4B92FB 52.28%, #F6C0D1 96.14%)"
                : null,
              WebkitTextFillColor: close ? "transparent" : null,
              WebkitBackgroundClip: close ? "text" : null,
              backgroundClip: close ? "text" : null,
              whiteSpace: close ? "nowrap" : null,
            }}
          >
            {link}
          </p>
          <img src={arrrowIcon} alt="template" className="w-4 h-4" />
        </div>
      </div>
    </div>
  );
};

export const BottomGuide = ({
  className,
  image,
  title,
  subtitle,
  secondSubtitle,
  link,
  handleClose,
  connect,
  top,
}) => {
  const navigate = useNavigate();

  const linkPage = () => {
    if (connect) {
      navigate(connect);
      handleClose();
    } else if (top) {
      top();
      handleClose();
    } else {
      handleClose();
    }
  };
//right-[20%] top-[50%]
  return (
    <div
      className={`${
        className ? className : "h-fit"
      } flex flex-col py-[18px] relative right-[32%] top-[30%] md:top-[35%] lg:top-[50%] transform translate-x-[10%] -translate-y-1/4 px-[24px] bg-WHITE-_100 rounded-lg gap-3`}
    >
      {/* <div className='w-full flex justify-end'>
                <div className="w-[36px] h-[36px] border rounded-lg mr-3 mt-3 flex cursor-pointer justify-center items-center p-2" onClick={handleClose}><AiOutlineClose /></div>
            </div> */}
      <div className="rounded-lg max-w-[356px] h-fit max-h-[153px] bg-[#FAFBFC] flex items-center justify-center">
        <img src={image} alt="" className="" />
      </div>
      <h2 className="font-medium text-base w-[356px] text-[#121212]">
        {title}
      </h2>
      <h4 className="text-sm text-[#7F7F81] w-[356px]">{subtitle}</h4>
      {secondSubtitle ? (
        <h4 className="text-sm text-[#7F7F81] w-[356px]">{secondSubtitle}</h4>
      ) : null}
      <div
        className="flex gap-2 items-center cursor-pointer"
        onClick={() => linkPage()}
      >
        <p className="text-sm font-medium text-BLUE-_200">{link}</p>
        <img src={arrrowIcon} alt="template" className="w-4 h-4" />

      </div>
    </div>
  );
};

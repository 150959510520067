import React from 'react'
import { HiXMark } from 'react-icons/hi2'

export default function FeatureModal({ handleClose, data }) {
    console.log(data, "crayon")
    const featureLength =  data?.pluginFeatures?.length;
    const minHeight = 310;
    const maxHeight = 700;
    const step = 65;
    // const dynamicHeight = featureLength === 1 ? minHeight : minHeight + Math.min(featureLength - 1, Math.floor((maxHeight - minHeight) / step)) * step;
    const dynamicHeight = Math.min(maxHeight, minHeight + (featureLength - 1) * step);
    
    return (
        <div style={{height: `${dynamicHeight}px`}} className={`pt-[15px] w-[693px] h-[${dynamicHeight}px] overflow-y-scroll bg-white ml-36 rounded-lg shadow-makeReqModal font-figtree relative mt-2 hide-scroll`}> {/* removed paddingBottom-5 */}
            <header className='px-10 mb-[15px] flex items-center justify-between w-full'>
                <h1 className='text-[24px] font-medium text-black font-figtree'>Features</h1>
                <div
                    className="border p-2 rounded-lg border-[#F7F7F7] cursor-pointer"
                    onClick={handleClose}
                >
                    <HiXMark className="text-[20px] text-[#828282]" />
                </div>
            </header>
            <hr className='bg-[#F7F7F7]'/>
            <div className='px-10 w-full mt-[25px] flex flex-col gap-[39px]'>
                <div className='flex flex-col gap-[19px]'>
                    {data?.pluginFeatures?.map((item, index) => (
                        <div key={index} className='rounded-[8px] bg-[#FAFAFA] px-[15px] py-5 flex flex-col gap-[15px]'>
                            <p className='text-[#343A40] font-medium text-sm'>{item?.name}</p>
                            <p className='text-[#7F7F81] font-medium text-sm'>{item?.description}</p>
                        </div>
                    ))}
                </div>
                {/* moved the close button from here to implement that it shows while scrolling */}
            </div>
            <div className='bg-white p-5 w-full flex items-center justify-end gap-3 sticky bottom-0'>
                <button onClick={handleClose} className="cursor-pointer outline-none py-[10px] w-auto px-5 border border-[#D0D5DD] rounded-lg text-[#828282] text-xs font-semibold">
                    Close
                </button>
            </div>
        </div>
    )
}

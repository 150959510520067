import React, { useState } from "react";
import { CgSpinner } from "react-icons/cg";
import TokenService from "../../../../services/token";
import { useSelector } from "react-redux";
import axios from "axios";

function DisconnectWhatsapp({
  handleClose,
  peerNumber,
}) {
  const URL = process.env.REACT_APP_BASEURL;
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const token = TokenService.getToken();

  const [isdisconnecting, setIsdisconnecting] = useState(false);
  const disconnectNumber = async () => {
    try {
      setIsdisconnecting(true);
      await axios.delete(
        `${URL}/businesses/application/${appDetail.agent.id}/peer/${peerNumber}/disconnect`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleClose()
    } catch (e) {
      console.error(e);
    } finally {
      setIsdisconnecting(false);
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center ">
      <div className="bg-white p-8 w-[496px] text-center rounded-lg shadow-lg">
        {/* Your content goes here */}
        <h1 className="text-2xl font-medium ">Disconnect WhatsApp</h1>
        <p className="text-[#828282] text-sm mt-12">
          Disconnecting WhatsApp removes your assistant from the the integrated
          number.
        </p>
        <div className="w-full grid grid-cols-2 items-center justify-end mt-14 gap-3">
          <button
            onClick={handleClose}
            className="cursor-pointer outline-none py-[10px] w-auto px-5 border border-[#D0D5DD] rounded-lg text-[#828282] text-xs font-semibold"
          >
            Cancel
          </button>
          {isdisconnecting ? (
            <button className="outline-none py-[10px] w-auto px-5 bg-[#AF202D] rounded-lg text-white text-xs font-semibold flex justify-center">
              <CgSpinner className=" animate-spin text-lg " />
            </button>
          ) : (
            <button
              onClick={disconnectNumber}
              className="cursor-pointer outline-none py-[10px] w-auto px-5 bg-[#AF202D] rounded-lg text-white text-xs font-semibold"
            >
              Disconnect Number
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default DisconnectWhatsapp;

import { useState, Fragment } from "react";
import { Popover } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import TokenService from "../../services/token";
import ModalPop from "../../component/modalPop";
import salesIcon from "../../Assets/img/salesIcon.svg";
import enquiryIcon from "../../Assets/img/enquiriesIcon.svg";
import complainIcon from "../../Assets/img/complaintsIcon.svg";
import requestIcon from "../../Assets/img/requestsIcon.svg";
import customIcon from "../../Assets/img/customIcon.svg";
import customIcon1 from "../../Assets/img/customIcon1.svg";
import customIcon2 from "../../Assets/img/customIcon2.svg";

import {
  ThreeDots,
  BankIcon,
  RightChevron,
  Eye,
  Delete,
} from "../../Assets/svg";
import {
  SuccessMessage,
  ErrorMessage,
} from "../../Toast-Messages/Toastmessages";
import { useSelector } from "react-redux";
import DeleteService from "./DeleteService";
export default function ServicesBlocks({ data, handleFetchUpdate }) {
  const navigate = useNavigate();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const getdata = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = getdata && getdata.length >= 1 ? getdata[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const [deleteServiceModal, setDeleteServiceModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRemoveService = async () => {
    setLoading(true);
    const serviceid = data.id;
    try {
      const response = await axios.delete(
        `${URL}/dashboard/applications/${applicationId}/category/${serviceid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setSuccess(`Instruction has been Removed Successfully`);
      setTimeout(() => {
        setSuccess(false);
        setDeleteServiceModal(false);
        setTimeout(() => {
          handleFetchUpdate();
        }, 100);
      }, 2500);
    } catch (error) {
      setLoading(false);
      console.log("Error Removing service(s):", error);
      setError(
        `An error occurred while removing ${data.name}, Please try again`
      );
      setTimeout(() => {
        setError(false);
      }, 2500);
    }
  };
  function hashToIndex(inputString) {
    // Calculate the sum of character codes in the input string
    if (!inputString) return 0;

    let sum = 0;
    for (let i = 0; i < inputString.length; i++) {
      sum += inputString.charCodeAt(i);
    }

    // Map the sum to one of the desired output values (0, 1, or 2)
    return sum % 3;
  }
  return (
    <>
      <div className=" h-[158px] bg-[rgba(249,249,249,0.86)] p-4 rounded-xl relative">
        <section className="flex justify-between ">
          <head className=" flex gap-3 font-medium text-base">
            {data.name.toLowerCase() === "complaints" ? (
              <img src={complainIcon} alt="complaints" className="w-8 h-8" />
            ) : data.name.toLowerCase() === "enquiries" ? (
              <img src={enquiryIcon} alt="enquiries" className="w-8 h-8" />
            ) : data.name.toLowerCase() === "requests" ? (
              <img src={requestIcon} alt="requests" className="w-8 h-8" />
            ) : data.name.toLowerCase() === "sales" ? (
              <img src={salesIcon} alt="sales" className="w-8 h-8" />
            ) : data.name.includes("Scheduled") ? (
              <img src={data?.image_url} alt="sales" className="w-8 h-8" />
            ) : hashToIndex(data.id) === 0 ? (
              <img src={customIcon} alt="sales" className="w-8 h-8" />
            ) : hashToIndex(data.id) === 1 ? (
              <img src={customIcon1} alt="sales" className="w-8 h-8" />
            ) : (
              <img src={customIcon2} alt="sales" className="w-8 h-8" />
            )}
            <p className=" mt-1 text-[#121212] capitalize">{data.name}</p>
          </head>
        </section>
        <p className=" text-xs text-[#7F7F81] font-normal mt-[20px]">
          {data.description.length > 80
            ? data.description.slice(0, 80) + "..."
            : data.description}
        </p>
        <section className=" mt-5 flex  w-full justify-between  ">
          <Link
            to={`/agent/${data.name}/${data.id}`}
            className="flex items-center gap-2 text-[#0359D8]"
          >
            <p className="text-xs">Manage</p>
            <p className="">
              <RightChevron />
            </p>
          </Link>
          <Popover className="relative">
            <Popover.Button className="outline-none">
              <div className=" mt-1 cursor-pointer">
                <ThreeDots />
              </div>
            </Popover.Button>
            <Popover.Panel>
              <Popover.Button className="z-10 cursor-pointer py-[5px] px-[10px] rounded-lg absolute bg-white shadow-md right-3 flex flex-col gap-3">
                <div
                  onClick={() => navigate(`/agent/${data.name}/${data.id}`)}
                  className="flex items-center gap-3"
                >
                  <Eye />
                  <p className="text-xs text-[#121212] font-figtree">View</p>
                </div>
                {data.type === "custom" && (
                  <div
                    onClick={() => setDeleteServiceModal(true)}
                    className="flex items-center gap-3"
                  >
                    <Delete />
                    <p className="text-xs text-[#AF202D] font-figtree">
                      Remove
                    </p>
                  </div>
                )}
              </Popover.Button>
            </Popover.Panel>
          </Popover>
        </section>
      </div>
      {success && <SuccessMessage success={success} />}
      {error && <ErrorMessage error={error} />}
      <ModalPop isOpen={deleteServiceModal}>
        <DeleteService
          handleClose={() => setDeleteServiceModal(false)}
          handleDelete={handleRemoveService}
          loading={loading}
        />
      </ModalPop>
    </>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { api } from "../../services/api"
import { appUrls } from "../../services/urls";



const initialState = {
    loading: false,
    data: [],
    // visibility:  {
    //     agent: true,
    //     bottomAgent: true,
    //     features: true,
    //     agentSettings: true,
    //     integration: true,
    //     ask_agent: true,
    //     customers: true,
    //     plugins: true,
    //     bill: false,
    //     confirmModal: true,
    //     testEmployeePopup: true
    // },
    // businessModals: {},
    employeeModals: {},
    done: false,
    error: ""
};

export const updateAgentModals = createAsyncThunk(
    "agentModals/putModals", 
    async({ modalsVisibility, id }, { rejectWithValue }) => {
        try {
            const res = await api.put(`${appUrls?.GET_APPLICATION_BY_ID_URL}/${id}/config`,  modalsVisibility);
            console.log(res?.data);
            return res?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)



export const getCurrentEmployee = createAsyncThunk(
    "retrieve/getCurrentEmployee",
    async (values, { rejectWithValue }) => {
      try {
        const res = await api.get(`/dashboard/applications/${values.id}`);
        return res?.data?.data;
      } catch (error) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
);

const updateAgentModalVisibilitySlice = createSlice({
    name: "agentModals",
    initialState,
    reducers: {
        resetModals: (state) => {
            state.businessModals = {};
            state.employeeModals = {};
        },
        setIsDone(state, action) {
            state.done = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(getCurrentEmployee.pending, state => {
            state.loading = true
        });
        builder.addCase(getCurrentEmployee.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.employeeModals = action.payload.config,
            // state.businessModals = action.payload.business.config,
            state.error = ""
        ));
        builder.addCase(getCurrentEmployee.rejected, (state, action) => void(
            state.loading = false,
            state.error = action.payload
        ))
    }
})

// const updateAgentModalVisibilitySlice = createSlice({
//     name: "agentModals",
//     initialState,
//     reducers: {
//         setModalVisibility(state, action) {
//           state.visibility = { ...state.visibility, ...action.payload };
//         },
//     },
//     extraReducers: builder => {
//         builder.addCase(updateAgentModalVisibility.pending, state => {
//             state.loading = true
//         });
//         builder.addCase(updateAgentModalVisibility.fulfilled, (state, action) => void(
//             state.loading = false,
//             // state.visibility = { ...state.visibility, ...action.payload },
//             state.data = action.payload,
//             state.error = ""
//         ));
//         builder.addCase(updateAgentModalVisibility.rejected, (state, action) => void(
//             state.loading = false,
//             // state.data = [],
//             state.error = action.payload
//         ))
//     }
// });

export const { resetModals, setIsDone } = updateAgentModalVisibilitySlice.actions;
export default updateAgentModalVisibilitySlice.reducer
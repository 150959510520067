import { useState, useEffect } from "react";
import ModalPop from "./modalPop";
import PublishModal from "./modals/agent/PublishModal";
import { getApplicationDraft } from "features/application/getApplicationByIdSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

function ChangesBanner({ setPropShowPopUp ,navigateToIntegrations}) {
  const dispatch = useDispatch();
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const appDetails = getAgentApplication || null;
  const agentDetails = appDetails?.agent || null;
  const navigate = useNavigate();

  const applicationId = agentDetails.id;
  const [publishModal, setPublishModal] = useState(false);
  const getDraft = async () => {
    dispatch(getApplicationDraft(applicationId));
  };
  useEffect(() => {
    if(applicationId){
    getDraft();
    }
  }, [applicationId]);

  const [draftchanges, Setdraftchanges] = useState(3);
  const appDetail = useSelector((state) => state.getApplicationByUserId);

  const appDraftchanges = appDetail.draftChanges;

  return (
    <div className="mb-4">
      {!!appDraftchanges?.length && (
        <div className="bg-lightGold px-10 py-2 flex justify-between items-center">
          <div>
            <p className="text-darkBrown text-sm font-medium">
              {appDraftchanges.length} change{appDraftchanges.length > 1 && "s"}{" "}
              detected!
            </p>
            <p className="text-xs text-dBrown">
              We detected a few changes. You can
              <span
                className="underline cursor-pointer mx-1"
                onClick={() => {
                  setPropShowPopUp(true);
                }}
              >
                Test these changes
              </span>
              or Publish them now to your
              <span
                className="underline cursor-pointer mx-1"
                onClick={() => {
                  navigateToIntegrations && navigateToIntegrations();
                  navigate("/agent", { state: "Integrations/Deploy" });
                }}
              >
                Channels
              </span>
            </p>
          </div>
          <p
            className="underline text-sm cursor-pointer"
            onClick={() => {
              setPublishModal(true);
            }}
          >
            Publish Changes
          </p>
        </div>
      )}

      <ModalPop isOpen={publishModal}>
        <PublishModal
          handleClose={() => {
            setPublishModal(false);
          }}
        />
      </ModalPop>
    </div>
  );
}

export default ChangesBanner;

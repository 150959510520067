import React, { useState, useEffect } from 'react'
import Bank from "../../../Assets/svg/bank.svg"
import { CgSpinner } from 'react-icons/cg'
import { useDispatch } from 'react-redux'
import { api } from '../../../services/api'
import { ErrorMessage, SuccessMessage } from '../../../Toast-Messages/Toastmessages'
import { useLocation, useNavigate } from 'react-router'
import { getAllPlugins } from '../../../features/plugins/getAllPluginsSlice'
//import { useAuth } from '../../../context/UserContext'


const AddPlugin = ({ handleClose, data, installed, setter }) => {
    const [loading, setLoading] = useState(false)
    const [pluginsNotificationSuccess, setPluginsNotificationSuccess] = useState(false);
    const [pluginsNotificationError, setPluginsNotificationError] = useState(false);
    
    //const {changeStatus} = useAuth();

    const appId  = JSON.parse(localStorage.getItem("wano_agent"));
    const applicationId = appId?.id

    // console.log(applicationId, "applicationId")
    // console.log(data, "data")

    const navigate = useNavigate()
    //newly added
    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(getAllPlugins(applicationId))
    // }, [])

    // useEffect(() => {
    //     dispatch(getAllPlugins({ applicationId }));
    //     console.log("dispatched")
    // }, []); //removed this because it was causing unnecessary rerenders

    //end of newly added
    const location = useLocation()


    const InstallPlugin = async () => {
        setLoading(true)
        const pluginData = {
            "ids": [
                `${applicationId}`
            ],
            "pluginId": `${data?.id}`
          }
        await api.post("/dashboard/plugins", pluginData)
        .then((res) => {
            setLoading(false)
            // console.log(res, "response from installing plugin")
            localStorage.setItem("pluginState", "All Plugins")
            //changeStatus("setup") //added this to track installation state
            setPluginsNotificationSuccess(res.data.message)
            dispatch(getAllPlugins({ applicationId }));
                setTimeout(() => {
                    handleClose();
                    setter(true)
            }, 2000);
                // setTimeout(() => {
                //     if (location.pathname === "/plugins") {
                //         window.location.reload()
                //     } 
                //     // if (location.pathname.includes('plugins')){
                //     //     window.location.reload()
                //     // }
                //     else {
                //         navigate(0)
                //     }
            // }, 2500);
        })
        .catch((err) => {
            setLoading(false)
            setPluginsNotificationError(true)
            // console.log(err, "err")
        })
    }

    const removePluginsNotificationSuccess = () => {
        setTimeout(() => {
            setPluginsNotificationSuccess(false)
        }, 3000);
    }

    const removePluginsNotificationError = () => {
        setTimeout(() => {
            setPluginsNotificationError(false)
        }, 3000);
    }

    const error = "false"

    useEffect(() => {
        removePluginsNotificationSuccess()
    }, [pluginsNotificationSuccess])

    useEffect(() => {
        removePluginsNotificationError()
    }, [pluginsNotificationError])

    const message = "App Installed Successfully"
    // const checkPath = (item) => {
    //     if (location.pathname.includes("/plugins/AllpluginType")){
    //         navigate(`/plugins/pluginType/${item?.plugin?.id}`, {state:item})
    //     }else if(location.pathname.includes("/plugins/AllpluginTypes")) {
    //         navigate(`/plugins/pluginType/${item?.plugin?.id}`, {state:item})
    //     }else{
    //         InstallPlugin()
    //     }
    // }

  return (
    <div className='bg-[#fff] w-[520px] h-[332px] py-[30px] px-[50px] flex flex-col rounded-lg mt-[100px] gap-[20px]'>
        {/* <div className='w-[48px] h-[48px] border-[#E5E5E5] border flex items-center justify-center rounded-lg'> */}
            <img src={data?.image_url} alt='bank' className='w-[48px] h-[48px]' />  {/*data === fetched item in allPlugins //data.plugin?.image_url == this checks for the corresponding plugin icon*/}
        {/* </div> */}
        <p className='text-[#101828] text-2xl'>{data?.name}</p>
        <p className='text-[#7F7F81] text-sm'>{data?.description}</p>{/* Enable users send and receive money with your A.I Employee on various channels */}
        <div className='w-[420px] mx-auto flex flex-col gap-[12px] mt-[20px]'>
            <button 
                className="w-[420px] h-[40px] py-3 px-5 rounded-lg flex justify-center items-center font-medium bg-BLACK-_500 text-WHITE-_100 text-sm"
                type="submit"
                onClick={() => {InstallPlugin()}}
            >
                <p className='text-WHITE-_100 text-sm font-bold'>{loading ? <CgSpinner className=" animate-spin text-lg " /> : 'Install App'}</p>  
            </button>
            <button 
                className="w-[420px]   flex justify-center items-center font-medium  text-[#868686] text-sm"
                type="submit"
                onClick={() => handleClose()}
            >
                Cancel
            </button>
        </div>

        {pluginsNotificationSuccess && <SuccessMessage Formodal={true} success={pluginsNotificationSuccess} className="mt-28 mr-12"/>}
        {pluginsNotificationError && <ErrorMessage Formodal={true} error={error} className="mt-28 mr-12"/>}
    </div>
  )
}

export default AddPlugin
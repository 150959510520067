import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { api } from "../../services/api"


const initialState = {
    loading: false,
    data: [],
    error: ""
};

export const getPluginsChannel = createAsyncThunk(
    "plugins/getPluginsChannel", 
    async({ applicationId }, { rejectWithValue }) => {
        try {
            const res = await api.get(`/dashboard/applications/${applicationId}/channels`);
            return res?.data

        } catch (error) {
            console.log(error, "error")
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)

const getPluginsChannelSlice = createSlice({
    name: "plugins",
    initialState,
    extraReducers: builder => {
        builder.addCase(getPluginsChannel.pending, state => {
            state.loading = true
        });
        builder.addCase(getPluginsChannel.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(getPluginsChannel.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default getPluginsChannelSlice.reducer
import React from "react";
import { CgSpinner } from "react-icons/cg";

export default function Button({
  text,
  className,
  disabled,
  onClick,
  type,
  loading,
}) {
  return (
    //<button className="bg-[#EBEBEB] rounded-xl px-[12px] w-[420px] line-[24px] py-[13px] text-white text-14px">{text}</button>
    //<button className={`bg-[#EBEBEB] rounded-xl px-[12px] ${width} line-[24px] py-[13px] text-white text-14px`}>{text}</button>
    //width = 'w-[420px]'
    <button
      disabled={disabled}
      className={`${
        className
          ? className
          : "bg-[#EBEBEB] rounded-xl px-[12px] w-[420px] line-[24px] py-[13px] text-white text-14px"
      } `}
      onClick={onClick}
      type={type}
    >
      {loading ? (
        <span className="flex justify-center w-full">
          <CgSpinner className=" animate-spin text-2xl" />
        </span>
      ) : (
        <span>{text}</span>
      )}
    </button>
  );
}

import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { BiChevronRight } from "react-icons/bi";
import FeatureForm from "../Agent/FeatureForm";
import ScheduledForm from "../Agent/ScheduledForm";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import TokenService from "../services/token";
import Help from "../Assets/svg/help2.svg";
import Head from "../Assets/svg/Shape65.svg";
import { CgSpinner } from "react-icons/cg";
import { ErrorMessage } from "../Toast-Messages/Toastmessages";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationDraft } from "features/application/getApplicationByIdSlice";
import GiveInstructionModal from "@/../Agent/GiveInstructionModal";
import ModalPop from "@/../component/modalPop";
import { useTour } from "@/../Tour/TourProvider";
import { useTour as tour } from "@reactour/tour";

export default function AddInstruction() {
  const navigate = useNavigate();
  const { state, search, pathname } = useLocation();
  const { id, type } = useParams();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const agentDataDetails = appDetail?.data?.data;
  // const isDataAvailable = state?.dataLength > 0 && agentDataDetails?.length > 0;
  const seenTourS = localStorage.getItem("tourS") == "false";
  const seenTourOthers = localStorage.getItem("tourOthers") == "false";
  const seenTour = pathname.includes("Scheduled") ? seenTourS : seenTourOthers;
  console.log(seenTour, "avalTour");
  const isDataAvailable = agentDataDetails?.length > 0 && seenTour;
  console.log(isDataAvailable, "aval");
  // const seenTour = localStorage.getItem('tour') == 'false';
  console.log(seenTour, "avalTour")
  // const isDataAvailable = agentDataDetails?.length > 0  && seenTour;
  console.log(isDataAvailable, "aval")
  const [giveInstruction, setGiveInstruction] = useState(false);
  let agentFeatures = "features";
  const agentData = localStorage.getItem("wano_agent");
  const agentDetails = JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const URL = process.env.REACT_APP_BASEURL;
  const [instructionName, setInstructionName] = useState("");
  const [instructionDescription, setInstructionDescription] = useState("");
  const [instructionManual, setInstructionManual] = useState("");

  //monitors the tags in the instructionManual
  const [tags, setTags] = useState([]);
  // console.log(tags, "tags");

  const [support, setSupport] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [error, setError] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const [repeatOption, setRepeatOption] = useState("");
  const dispatch = useDispatch();
  // console.log(repeatOption, "you have selected an option")
  // console.log(endDate, "tag start");
  // console.log(startDate, "tag end");
  const formRef = useRef(null);

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const { setIsOpen, setSteps } = tour();
  const { resetTour, setCurrentStep } = useTour();


  // const openStartPop = () => {
  //   resetTour();
  // };

  const convertToISO = (dat) => {
    const date = new Date(dat);
    const now = new Date();
    date.setHours(
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(),
      now.getMilliseconds()
    );
    const isoString = date.toISOString();
    console.log(isoString);
    return isoString;
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  }, [error]);

  const [loading, setLoading] = useState(false);
  const token = TokenService.getToken();

  // console.log({
  //   name: instructionName,
  //   description: instructionDescription,
  //   how_it_works: instructionManual,
  //   support_channel: support.map((each) => ({
  //     support_channel: each.support_channel,
  //     website:
  //       each.support_channel === "Phone Number"
  //         ? `${countryCode}${each.website}`
  //         : each.website,
  //   })),
  // })

  const setErrorMsg = (msg) => {
    setError(msg);
    // console.log(msg);
  };

  const openStartPop = () => {
    resetTour();
    setIsOpen(true);
  };
  const instructionSteps = [
    {
      selector: ".instructionstep-1",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>
            Give your Instruction a Title. This is solely so you can reference
            it later. E.g. “When Customers ask for Pricing”
          </p>
          <p className="text-[#7F7F81]">Step 1 of 5 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
    {
      selector: ".instructionstep-2",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>
            Enter a detailed and informative description that accurately
            explains the content or purpose
          </p>

          <p className="text-[#7F7F81]">Step 2 of 5 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
    {
      selector: ".instructionstep-3",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>Try to be specific when creating an Instruction or Guideline:</p>
          <p>
            e.g. “Whenever a user wants to see a product demo, direct them to
            our demo link”
          </p>

          <p className="text-[#7F7F81]">Step 3 of 5 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },

    {
      selector: ".instructionstep-4",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>
            Provide an optional channel to redirect users to. This could be
            anything from a phone number to call or a website link to follow.
            You can add up to 3 channels.
          </p>

          <p className="text-[#7F7F81]">Step 4 of 5 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },

    {
      selector: ".instructionstep-5",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Good Job</p>
          </div>
          <p>
            You have completed the Instructions guided tour, If you need to go
            through the tour again, you can find it here.
          </p>
          <p className="text-[#7F7F81]">Step 5 of 5 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
  ];

  const scheduledSteps = [
    {
      selector: ".instructionstep-1",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>
            Give your Instruction a Title. This is solely so you can reference
            it later. E.g. “When Customers ask for Pricing”
          </p>
          <p className="text-[#7F7F81]">Step 1 of 7 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
    {
      selector: ".instructionstep-2",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>
            Enter a detailed and informative description that accurately
            explains the content or purpose
          </p>

          <p className="text-[#7F7F81]">Step 2 of 7 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
    {
      selector: ".instructionstep-3",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>Try to be specific when creating an Instruction or Guideline:</p>
          <p>
            e.g. “Whenever a user wants to see a product demo, direct them to
            our demo link”
          </p>

          <p className="text-[#7F7F81]">Step 3 of 7 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },

    {
      selector: ".instructionstep-4",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>
            Provide an optional channel to redirect users to. This could be
            anything from a phone number to call or a website link to follow.
            You can add up to 3 channels.
          </p>

          <p className="text-[#7F7F81]">Step 4 of 7 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
    {
      selector: ".instructionstep-6",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know your Instructions</p>
          </div>
          <p>
            Set an optional start date for Scheduled Instructions. Your
            Instruction will only be active within the start and end date.
          </p>

          <p className="text-[#7F7F81]">Step 5 of 7 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
    {
      selector: ".instructionstep-7",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>
            Set an optional End date for Scheduled Instructions. Your
            Instruction will only be active within the start and end date.
          </p>
          <p className="text-[#7F7F81]">Step 6 of 7 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
    {
      selector: ".instructionstep-5",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Great Job!</p>
          </div>
          <p>
            You have completed the Instructions guided tour, If you need to go
            through the tour again, you can find it here
          </p>
          {/* <p className="text-[#7F7F81]">Step 1 of 4 </p> */}
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
  ];

  // const instructionSteps = [
  //   {
  //     selector: ".instructionstep-1",
  //     content: (
  //       <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
  //         <div className="flex gap-2 items-center">
  //           <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
  //           <p className="text-[#7F7F81]">Get to know Instructions</p>
  //         </div>
  //         <p>
  //           Track your credits and top up before it runs out to keep your
  //           assistant active.
  //         </p>
  //         <p className="text-[#7F7F81]">Step 1 of 4 </p>
  //       </div>
  //     ),
  //     position: "right",
  //     arrow: "disabled",
  //     isAgentStep: false,
  //   },
  //   {
  //     selector: ".instructionstep-2",
  //     content: (
  //       <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
  //         <div className="flex gap-2 items-center">
  //           <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
  //           <p className="text-[#7F7F81]">Get to know your Dashboard</p>
  //         </div>
  //         <p>
  //           Track your credits and top up before it runs out to keep your
  //           assistant active.
  //         </p>
  //         <p className="text-[#7F7F81]">Step 1 of 4 </p>
  //       </div>
  //     ),
  //     position: "right",
  //     arrow: "disabled",
  //     isAgentStep: false,
  //   },
  //   {
  //     selector: ".instructionstep-3",
  //     content: (
  //       <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
  //         <div className="flex gap-2 items-center">
  //           <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
  //           <p className="text-[#7F7F81]">Get to know your Dashboard</p>
  //         </div>
  //         <p>
  //           Track your credits and top up before it runs out to keep your
  //           assistant active.
  //         </p>
  //         <p className="text-[#7F7F81]">Step 1 of 4 </p>
  //       </div>
  //     ),
  //     position: "right",
  //     arrow: "disabled",
  //     isAgentStep: false,
  //   },

  //   {
  //     selector: ".instructionstep-4",
  //     content: (
  //       <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
  //         <div className="flex gap-2 items-center">
  //           <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
  //           <p className="text-[#7F7F81]">Get to know your Dashboard</p>
  //         </div>
  //         <p>
  //           Track your credits and top up before it runs out to keep your
  //           assistant active.
  //         </p>
  //         <p className="text-[#7F7F81]">Step 1 of 4 </p>
  //       </div>
  //     ),
  //     position: "right",
  //     arrow: "disabled",
  //     isAgentStep: false,
  //   },
  //   {
  //     selector: ".instructionstep-5",
  //     content: (
  //       <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
  //         <div className="flex gap-2 items-center">
  //           <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
  //           <p className="text-[#7F7F81]">Get to know your Dashboard</p>
  //         </div>
  //         <p>
  //           Track your credits and top up before it runs out to keep your
  //           assistant active.
  //         </p>
  //         <p className="text-[#7F7F81]">Step 1 of 4 </p>
  //       </div>
  //     ),
  //     position: "right",
  //     arrow: "disabled",
  //     isAgentStep: false,
  //   },
  // ];

  // useEffect(() => {
  //   setCurrentStep(0);
  //   setSteps(instructionSteps);
  // }, []);
  useEffect(() => {
    setCurrentStep(0);
    if (pathname.includes("Scheduled")) {
      setSteps(scheduledSteps);
    } else {
      setSteps(instructionSteps);
    }
  }, []);

  const createInstruction = async () => {
    // console.log("i have just been clicked")
    if (!applicationId) return;
    if (!instructionName || !instructionDescription || !instructionManual) {
      setError("Please fill all fields!");
      return;
    } else if (
      support.length > 0 &&
      !support.every(
        (item) =>
          item.support_channel.trim() !== "" && item.website.trim() !== ""
      )
    ) {
      setError(
        "Please provide valid support information for all selected channels!"
      );
      return;
    }

    try {
      setLoading(true);
      const instructionData = {
        name: instructionName,
        description: instructionDescription,
        how_it_works: instructionManual,
        support_channel: support.map((each) => ({
          support_channel: each.support_channel,
          website:
            each.support_channel === "Phone Number"
              ? `${countryCode}${each.website}`
              : each.website,
        })),
        tags: tags,
      };

      if (type === "Scheduled Instructions") {
        instructionData.start_date = convertToISO(startDate);
        instructionData.end_date = convertToISO(endDate);
      }

      const res = await axios.post(
        `${URL}/dashboard/applications/${applicationId}/category/${id}/instructions`,
        //{
        // name: instructionName,
        // description: instructionDescription,
        // how_it_works: instructionManual,
        // support_channel: support.map((each) => ({
        //   support_channel: each.support_channel,
        //   website:
        //     each.support_channel === "Phone Number"
        //       ? `${countryCode}${each.website}`
        //       : each.website,
        // })),
        // start_date: convertToISO(startDate),
        // end_date: convertToISO(endDate),
        // tags: [
        //   {
        //     applicationPluginId: "string",
        //     slug: "string"
        //   }
        // ]
        //},
        instructionData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);

      if (applicationId) {
        dispatch(getApplicationDraft(applicationId));
      }
      navigate(`/agent/${type}/${id}`);
    } catch (error) {
      setLoading(false);
      setError(error?.response?.data?.message || "Error creating instruction");
    }
  };
  useEffect(() => {
    // handle template
    const queryParams = new URLSearchParams(search);
    const template = queryParams.get("template");

    if (template) {
      const realTemplate = JSON.parse(template);

      console.log("is template present", realTemplate);
      setInstructionName(realTemplate.name);
      setInstructionDescription(realTemplate.description);
      setInstructionManual(realTemplate.instruction)
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 1500);
  }, [error]);

  useEffect(() => {
    if (!isDataAvailable) {
      setGiveInstruction(true);
    } else {
      setGiveInstruction(false);
    }
  }, [isDataAvailable]);

  console.log(giveInstruction, "give instruction")

  const tabs = ["Memory", "Features", "Settings", "Integrations/Deploy"];

  const handleButtonClick = (location) => {
    if (location === "Features") {
      navigate("/agent", { state: agentFeatures });
    } else if (location === "Settings") {
      navigate("/agent", { state: "settings" });
    } else if (location === "Integrations/Deploy") {
      navigate("/agent", { state: "Integrations/Deploy" });
    } else {
      navigate("/agent", { state: "Memory" });
    }
  };
  return (
    <DashboardLayout title={"Assistant"} user={true}>
      <section className="flex font-figtree relative w-full">
        <div className="py-8 px-6 h-full  shrink-0">
          <ul className="flex flex-col gap-4 w-[135px]">
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={`py-2.5 px-4 cursor-pointer rounded-md text-sm ${
                  tab === "Features"
                    ? "text-PRIMARY bg-[#FAFAFA]"
                    : "text-SECONDARY"
                }`}
                onClick={() => handleButtonClick(tab)}
              >
                <span>{tab === "Features" ? "Instructions" : tab}</span>
              </li>
            ))}
          </ul>
        </div>

        <main className="font-figtree p-10 mb-7 w-full border-l border-[#F7F7F7]">
          <header className="flex gap-2 items-center mb-4">
            <div
              onClick={() => navigate("/agent", { state: agentFeatures })}
              className="cursor-pointer underline text-[#D2D0D0] text-sm font-normal hover:underline"
            >
              Instruction
            </div>
            <BiChevronRight className=" text-xl text-[#D2D0D0]" />
            <Link
              to={`/agent/${type}/${id}`}
              className="capitalize text-[#D2D0D0] text-sm font-normal hover:underline"
            >
              {type}
            </Link>
            <BiChevronRight className=" text-xl text-[#D2D0D0]" />
            <p className="text-[#121212] text-sm font-normal ">
              Add Instruction
            </p>
          </header>
          <section className="flex justify-between w-full items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[24px] font-medium text-[#121212] font-figtree">
                Add Instruction
              </h1>
            </div>
            <div className="flex gap-[20px]">
            <div
              className="instructionstep-5 flex items-center bg-[#F7F7F7] rounded-[35px] gap-2 p-[11px] cursor-pointer"
              onClick={openStartPop}
            >
              <img src={Help} alt="help" />
              <p className="text-[12px] leading-[14.4px] font-medium text-[#0359D8]">
                Take Guided Tour
              </p>
            </div>
             <div className="flex items-center gap-3">
              {loading ? (
                <button className="outline-none py-3 w-[139px] px-5 bg-[#121212] rounded-lg text-white text-[14px] font-bold flex justify-center">
                  <CgSpinner className=" animate-spin text-lg " />
                </button>
              ) : (
                <button
                  onClick={handleFormSubmit}
                  className="cursor-pointer outline-none py-3 w-auto px-5 bg-[#121212] rounded-lg text-white text-[14px] font-bold"
                >
                  Add Instruction
                </button>
              )}
            </div> 
            </div>
          </section>
          <div className="-mt-5">
            {!type.includes("Scheduled") ? (
              <FeatureForm
                ref={formRef}
                setErrorMsg={setErrorMsg}
                categoryName={type}
                instructionName={instructionName}
                setInstructionName={setInstructionName}
                instructionDescription={instructionDescription}
                setInstructionDescription={setInstructionDescription}
                instructionManual={instructionManual}
                setInstructionManual={setInstructionManual}
                support={support}
                setSupport={setSupport}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                action={createInstruction}
                setTags={setTags}
                tags={tags}
              />
            ) : (
              <ScheduledForm
                ref={formRef}
                categoryName={type}
                instructionName={instructionName}
                setInstructionName={setInstructionName}
                instructionDescription={instructionDescription}
                setInstructionDescription={setInstructionDescription}
                instructionManual={instructionManual}
                setInstructionManual={setInstructionManual}
                support={support}
                setSupport={setSupport}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                action={createInstruction}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                // repeatOption={repeatOption}
                // setRepeatOption={setRepeatOption}
                setTags={setTags}
                tags={tags}
              />
            )}
          </div>
        </main>
        {error && <ErrorMessage error={error} />}
        <ModalPop isOpen={giveInstruction}>
          <GiveInstructionModal
            // refresh={refresh}
            handleClose={() => {
              setGiveInstruction(false);
            }}
          />
        </ModalPop>
      </section>
    </DashboardLayout>
  );
}

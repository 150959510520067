import { LuMail } from "react-icons/lu";
import { FaXTwitter } from "react-icons/fa6";
export default function SpeakWithCEO() {
  return (
    <main className=" mt-6 flex gap-[40px]">
      <a href="mailto:c@wano.app">
        <section className="flex gap-3">
          <span className=" border border-[#E5E5E5] p-2 rounded-md cursor-pointer">
            <LuMail className=" text-base text-[rgba(41,45,50,1)]" />
          </span>
          <div>
            <p className=" text-sm font-medium text-[#121212] ">Mail</p>
            <p className=" text-xs font-normal text-[#7F7F81]">Send a mail.</p>
          </div>
        </section>
      </a>
      <a href="https://x.com/simplemonarch">
        <section className="flex gap-3">
          <span className=" border border-[#E5E5E5] p-2 rounded-md cursor-pointer">
            <FaXTwitter className=" text-base text-[rgba(41,45,50,1)]" />
          </span>
          <div>
            <p className=" text-sm font-medium text-[#121212] ">Message on X</p>
            <p className=" text-xs font-normal text-[#7F7F81]">Contact via X .</p>
          </div>
        </section>
      </a>
    </main>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from "../../services/api"



const initialState = {
    loading: false,
    data: [],
    inbox:[],
    error: ""
};

export const businessAskAgentChatH = createAsyncThunk(
    "agentChat/businessAskAgentChat", 
    async({data, appId, chatId}, { rejectWithValue }) => {
        try {
            console.log('next')
            const res = await api.post(`/dashboard/applications/${appId}/chat/${chatId}/conversations/send`, data);
            return res?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const getInbox = createAsyncThunk(
    "getAgentChat/getInbox", 
    async({appId}, { rejectWithValue }) => {
        try {
            const res = await api.get(`/dashboard/applications/${appId}/inbox`);
            return res?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)
export const markInboxAsRead = createAsyncThunk(
    "agentChat/markInboxAsRead", 
    async({ inboxId,appId}, { rejectWithValue }) => {
        try {
            const res = await api.put(`/dashboard/applications/${appId}/inbox/${inboxId}/mark-as-read`);
            return res?.data
      

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)


const businessAskAgentChatSlice = createSlice({
    name: "agentChat",
    initialState,
    extraReducers: builder => {
        builder.addCase(businessAskAgentChatH.pending, state => {
            state.loading = true
        });
        builder.addCase(businessAskAgentChatH.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(businessAskAgentChatH.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ));
        builder.addCase(getInbox.pending, state => {
            // state.loading = true
        });
        builder.addCase(getInbox.fulfilled, (state, action) => void(
            state.loading = false,
            state.inbox = action.payload.data,
            state.error = ""
        ));
        builder.addCase(getInbox.rejected, (state, action) => void(
            state.loading = false,
            state.inbox = [],
            state.error = action.payload
        ))
        
    }
});


export default businessAskAgentChatSlice.reducer
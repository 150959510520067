import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import handshake from "../Assets/svg/handshake.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { api } from "@/../services/api";
import { getABusiness } from "@/../features/business/getBusinessSlice";
import RedeemSuccess from "@/../component/modals/redeemgift/RedeemSuccess";
import ModalPop from "@/../component/modalPop";

const SetupAssistance = ({ handleClose, setOpenUpgradeToPlan, openModal, setSuccess, setError }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const getUserProfile = useSelector((state) => state.getProfile);
  
  const setupAssistCount = data?.setup_assist_count;
  const lastSetupAssistDate = data?.setup_assist_date;
  const profile = getUserProfile?.data?.data;

  useEffect(() => {
    const agent = JSON.parse(localStorage.getItem("wano_agent"));
    const business = agent?.business; 
    dispatch(getABusiness(business))
      .then((res) => {
        console.log(res, "busRes");
        setData(res?.payload);
      })
      .catch((error) => console.log(error, 'busError'));
  }, []);

  const openUpgradeToPlanPop = async () => {
    const agent = JSON.parse(localStorage.getItem("wano_agent"));
    const first_name = profile?.first_name;
    const last_name = profile?.last_name;
    const business_email = agent?.business?.business_email;
    const phone_number = profile?.phone_number;
    const assistant_name = agent?.sale_agent_name;
    const business_name = agent?.business?.name;
  
    try {
      const res = await api.post("/forms/setup-assist", {
        first_name,
        last_name,
        business_email,
        phone_number,
        assistant_name,
        business_name,
      });
      // openModal(null);
      setSuccess(res?.data?.message);
      // console.log(res?.data?.message, "alone");
      setTimeout(() => {
        setSuccess("");
      }, 1500);
      openModal("ModalG");
      handleClose();
  
    } catch (error) {
      console.log(error, "bus assist error");
      setError(error.data.message);
      setTimeout(() => {
        setError("");
      }, 1500);
      openModal("ModalB"); 
      handleClose();
    }
  
    handleClose();
  };
  
  return (
    <div className="bg-[#ffffff] w-[523.61px] h-[505px] mt-[100px] rounded-[6.47px]">
      <div>
        <div className="flex items-center justify-between py-[11.33px] px-[19.42px] ">
          <h1 className="text-[18px] leading-[21.6px] font-medium text-[#121212]">
            Setup Assistance
          </h1>
          <button
            className="w-[36px] h-[36px] border rounded-lg flex justify-center items-center p-2"
            onClick={handleClose}
          >
            <AiOutlineClose className="text-BLACK-_300" />
          </button>
        </div>
        <hr />
      </div>
      <div className="flex flex-col gap-[41.39px] mt-[74.21px]">
        <div className="flex items-center justify-center">
          <img src={handshake} alt="handshake" />
        </div>
        <p className="leading-[24.22px] text-[14px] text-[#7F7F81] text-center px-[19.42px]">
          Do you need help{" "}
          <Link
            to="/agent"
            state={{ state: "Features" }}
            className="text-[#121212] underline font-bold"
          >
            creating instructions
          </Link>
          ,{" "}
          <Link
            to="/agent"
            state={{ state: "Memory" }}
            className="text-[#121212] underline font-bold"
          >
            uploading support contents
          </Link>{" "}
          or{" "}
          <Link
            to="/agent"
            state={{ state: "Integrations/Deploy" }}
            className="text-[#121212] underline font-bold"
          >
            deploying your assistant
          </Link>
          ? Request Setup Assistance and an AI specialist will promptly handle
          all your setup needs while you focus on business.
        </p>
        <div className="flex justify-center">
          <button
            className="bg-[#121212] text-[#ffffff] leading-[14.4px] text-[12px] px-[32px] py-[12px] rounded-lg"
            onClick={() => openUpgradeToPlanPop()}
          >
            Request Setup Assistance
          </button>
        </div>
      </div>

      <ModalPop>
        <RedeemSuccess
          title={"Upgrade Successful"}
          text1={"You have successfully upgraded to the Business Plan"}
          label={"Done"}
          redirectPath="/settings?page=billing&category=plan"
        />
      </ModalPop>
    </div>
  );
};

export default SetupAssistance;
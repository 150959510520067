import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import info from "../Assets/svg/infocircle.svg";
import { api } from "@/../services/api";
import { useNavigate } from "react-router";
import Pay from "@/../component/modals/wallet/Pay";
import ModalPop from "@/../component/modalPop";
import { useAuth } from "@/../context/UserContext";
import { useDispatch, useSelector } from "react-redux";
import { getGiftBalance } from "@/../features/profile/getGiftBalanceSlice";

const UpgradePlan = ({
  handleClose,
  setOpenRedeemSuccess,
  data,
  refresh,
  balance,
  setOpenPayModal,
  setSufficient,
}) => {
  const {
    id = '',
    name = '',
    description = '',
    features = [],
    monthly_amount = 0,
    yearly_amount = 0,
    tag_slug = '',
    disabled = false,
    created_at = '',
    updated_at = '',
  } = data || {}; 
  const dispatch = useDispatch();
  // console.log(data, "sub data")
  

  // const giftBalance = JSON.parse(sessionStorage.getItem("giftBal")) ?? 0;
  // let giftBalance = 0;
  // try {
  //   giftBalance = JSON.parse(sessionStorage.getItem("giftBal")) ?? 0;
  // } catch (error) {
  //   console.error("Error parsing giftBal from sessionStorage:", error);
  //   giftBalance = 0; 
  // }
  const business =  useSelector(state => state.getBusiness.activeBusiness);
  const walletBalance = useSelector((state) => state.walletBalance);
  const Balance = useSelector(state => state.getGiftBalance.data)
  const giftBalance = Balance?.filter((bal,_) => bal.currency === "GIFT")?.[0]?.balance;
  // console.log(giftBalance, "gift")
  const businessID = business?.id;
  const { tokens } = walletBalance.data;

  useEffect(() => {
    dispatch(getGiftBalance(businessID))
  },[])


  const sufficient = Number(tokens) + Number(giftBalance);

  const getNextCycleDate = () => {
    const today = new Date();
    const nextCycle = new Date(today.setMonth(today.getMonth() + 1));
    return nextCycle.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

  };

  
  const endDate = getNextCycleDate();

  const handleUpgrade = async () => {
    if (sufficient < Number(monthly_amount)) {
      handleClose();
      setSufficient(true);
      setOpenPayModal(true);
      return;
    } else {
      await api
        .post(`/business-subscription/upgrade/${id}`)
        .then((res) => {
          openRedeemSuccessPop();
          refresh();
        })
        .catch((error) => console.log(`We could not process because ${error}`));
    }
  };

  // console.log(sufficient < Number(monthly_amount), "totalll")

  // console.log(sufficient, giftBalance, Number(tokens), "sufficient");
  const openRedeemSuccessPop = () => {
    setOpenRedeemSuccess(true);
    handleClose();
  };

  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.setItem("amount", monthly_amount);
  }, [monthly_amount])

  return (
    <div className="w-[523.61px] h-[505px] bg-white rounded-[6.47px] flex flex-col justify-between mt-[100px]">
      <div>
        <div className="flex items-center justify-between py-[9.33px] px-[19.42px] ">
          <h1 className="text-[18px] leading-[21.6px] font-medium text-[#121212]">
            Upgrade Plan
          </h1>
          <button
            className="w-[36px] h-[36px] border rounded-lg flex justify-center items-center p-2 m-[11.33px]"
            onClick={handleClose}
          >
            <AiOutlineClose className="text-BLACK-_300" />
          </button>
        </div>
        <hr />
        <div className="px-[20px] pt-[35.21px]">
          <div className="flex justify-between">
            <div>
              <h1 className="text-[20px] leading-[21.5px] text-[#101828] font-medium">
                {name}
              </h1>
              <p className="text-[#828282] text-[14px] leading-[28px]">
                {description}
              </p>
            </div>

            <div className="flex items-center self-end">
              <h1 className="text-[24px] text-[#101828] font-medium leading-[28.12px]">
                ${monthly_amount}
              </h1>
              <span className="text-[#828282] text-[15.69px] leading-[36.62px]">
                /per month
              </span>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex gap-[4px] mt-[16px]">
              <img src={info} alt="info circle" />
              <button onClick={() => navigate("/support")}>
                <p className="text-[10px] leading-[12.85px] text-[#1774FD] underline">
                  How we charge for subscription
                </p>
              </button>
            </div>
            <div className="text-[12px] leading-[28px] text-[#828282] flex gap-[2px]">
              <span className="">Next Cycle:</span>
              <span className="">{endDate}</span>
            </div>
          </div>
          <ul className="list-disc pl-5 text-[#828282] text-[12px] mt-[9px]">
            {features?.map((item, listItemIndex) => (
              <li key={listItemIndex}>
                <p className="text-[12px] leading-[25px] font-normal text-[#828282]">
                  {item.prefix} {item.description}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="pb-[20px] px-[19.42px]">
        <hr />
        <p className="text-[12px] leading-[20px] font-normal text-[#828282] mt-[25px]">
          You will be charged monthly on the 23rd from your credits wallet. By
          tapping the button below, you agree to the details above, our{" "}
          <span className="text-[#1774FD]">Terms</span>, our{" "}
          <span className="text-[#1774FD]">Privacy Policy</span>.
        </p>
        <div className="flex justify-center mt-[38px]">
          <button
            onClick={handleUpgrade}
            className="bg-[#121212] text-[#FFFFFF] w-[435px] py-[8px] px-[20px] text-[12px] leading-[24px] font-semibold mx-auto rounded-lg"
          >
            Confirm Upgrade Plan
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlan;

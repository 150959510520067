import React, { useState, useEffect } from 'react'
import Bank from "../../../Assets/svg/bank.svg"
import { CgSpinner } from 'react-icons/cg'
import { useDispatch, useSelector } from 'react-redux'
import { api } from '../../../services/api'
import { ErrorMessage, SuccessMessage } from '../../../Toast-Messages/Toastmessages'
import { useLocation, useNavigate, useParams } from 'react-router'
import axios from 'axios'
import { getPluginById } from '../../../features/plugins/getPluginByIdSlice'
import { getAllPlugins } from '@/../features/plugins/getAllPluginsSlice'

const UninstallPlugin = ({handleClose, state, token }) => {
    const [integrateWithWano, setIntegrateWithWano] = useState(false)
    const [integrateWithCustomBusiness, setIntegrateWithCustomBusiness] = useState(false)
    const [pluginsNotificationSuccess, setPluginsNotificationSuccess] = useState(false);
    const [pluginsNotificationError, setPluginsNotificationError] = useState(false);
    const [openNextModal, setOpenNextModal] = useState()
    const [addWanoNumber, setAddWanoNumber] = useState(false)
    const [integrateWhatsApp, setIntegrateWhatsapp] = useState(false)
    const [purpose, setPurpose] = useState('');
    const [viewAgent, setViewAgent] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState([])
    const [uninstallPluginLoading, setUninstallPluginLoading] = useState(false)
    // const token = TokenService   .getToken()
    const appDetail = useSelector((state) => state.getApplicationByUserId);
    const getdata = appDetail?.data?.data;
    const agentData = localStorage.getItem("wano_agent");
    const agent = getdata && getdata.length >= 1 ? getdata[0] : {};
    const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
    const applicationId = agentDetails.id;
    const [featureData, setFeatureData] = useState(null)
    const URL = process.env.REACT_APP_BASEURL;
    const navigate = useNavigate()

    // const { state, setActiveButton } = useLocation()
    // console.log(state, setActiveButton, "jaja")

    const applicationPluginId = state?.plugin?.id

    const updatePluginsData = useSelector(state => state.updatePlugins)
    const { error } = updatePluginsData

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPluginById({ applicationId, applicationPluginId }))
    }, [])

    const fetchPluginAgentFeatures = async (agentId) => {
        sessionStorage.setItem('plugin_id', state?.id)
        const res = await axios.get(
            `${URL}/dashboard/plugins/${state?.plugin.id}/application/${agentId}/features`, 
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        )
        setFeatureData(res.data.data)
    }

    const getPluginByApplicationPluginId = useSelector(state => state.getPluginByApplicationPluginId) 
    // console.log(getPluginByApplicationPluginId, "getPluginByApplicationPluginId")

    const location  = useLocation()
    const isAllApps = location?.pathname.includes("AllpluginTypes")
    console.log(location, "tags renderData params"); 

    //Remove Plugin
    const disconnectPlugin = async (item) => {
        setUninstallPluginLoading(true)
        localStorage.setItem("pluginState", "My Plugins")
        try {
            const res = await axios.delete(`${URL}/dashboard/plugins/${isAllApps? item?.id : item?.plugin.id}/remove`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setUninstallPluginLoading(false)
            setPluginsNotificationSuccess(true)
            // dispatch(getPluginById({ applicationId, applicationPluginId }))
            if(!isAllApps){
                setTimeout(() => {
                    navigate("/plugins")
                    
                    // setActiveButton("My Plugins")
                }, 1500)
            }else{
                dispatch(getPluginById({ applicationId, applicationPluginId }))
                dispatch(getAllPlugins({ applicationId }));
                handleClose()
            }
            // window.location.reload()
        } catch(error) {
            setPluginsNotificationError(true)
        }
    }

    const removePluginsNotificationSuccess = () => {
        setTimeout(() => {
            setPluginsNotificationSuccess(false)
        }, 1000);
    }

    const removePluginsNotificationError = () => {
        setTimeout(() => {
            setPluginsNotificationError(false)
        }, 1000);
    }


    useEffect(() => {
        removePluginsNotificationSuccess()
    }, [pluginsNotificationSuccess])

    useEffect(() => {
        removePluginsNotificationError()
    }, [pluginsNotificationError])
    
    const message = "Plugin Uninstalled Successfully"

  return (
    <div className='bg-[#fff] w-[496px] h-[280px] py-[30px] px-[50px] flex flex-col rounded-lg mt-[100px] gap-[40px]'>
        {/* <div className='w-[48px] h-[48px] border-[#E5E5E5] border flex items-center justify-center rounded-lg'> */}
            {/* <img src={Bank} alt='bank' className='w-[48px] h-[48px]' />  data === fetched item in allPlugins //data.plugin?.image_url == this checks for the corresponding plugin icon */}
        {/* </div> */}
        {/* <p className='text-[#101828] text-2xl'>{data.name}</p> */}
        <p className='text-center text-2xl font-medium'>Uninstall App</p>
        <p className='text-center text-[#7F7F81] text-sm'>Are you sure you want to uninstall this App? Doing so will remove the disconnect your Assistant from the App</p>
        <div className='w-[420px] mx-auto flex gap-[12px] mt-[20px] mb-[80px]'>
            <button 
                className="w-[186px] flex justify-center items-center rounded-lg font-medium text-[#868686] text-sm border border-neutral-200"
                type="submit"
                onClick={() => handleClose()}
            >
                Cancel
            </button>
            
            <button 
                onClick={() => disconnectPlugin(state)}
                className="w-[186px] h-[40px] py-3 px-5 rounded-lg flex justify-center items-center font-medium bg-[#AF202D] text-WHITE-_100 text-sm"
                type="submit"
                // onClick={() => InstallPlugin()}
                //bg-BLACK-_500
            >
                <p className='text-WHITE-_100 text-sm font-bold'>{uninstallPluginLoading ? <CgSpinner className=" animate-spin text-lg " /> : 'Yes, Uninstall'}</p>  
            </button>
            {/* <button onClick={() => disconnectPlugin(state)} className="bg-[#EEEEEE] cursor-pointer outline-none border border-[#EEE]  w-[129px] h-[43px] flex items-center justify-center rounded-lg text-[#AF202D] text-sm font-medium">
                {uninstallPluginLoading ? <CgSpinner className='text-RED-_100 animate-spin text-lg' /> : "Yes, Uninstall"}
            </button> */}
            
        </div>

        {pluginsNotificationSuccess && <SuccessMessage Formodal={true} success={message} className="mt-28 mr-12"/>}
        {pluginsNotificationError && <ErrorMessage Formodal={true} error={error} className="mt-28 mr-12"/>}
    </div>
  )
}

export default UninstallPlugin
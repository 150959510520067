import React, { Fragment, useState, useRef, useEffect } from "react";
import Button from "./Button";
import FormFooter from "./FormFooter";
import Terms from "./Terms";
import NavBar from "./NavBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import WelcomeLogoText from "./WelcomeLogotext";
import { useAuth } from "../context/UserContext";
import { ErrorMessage, SuccessMessage } from "../Toast-Messages/Toastmessages";
import { BiArrowBack } from "react-icons/bi";
import OtpSpinner from "../Assets/gif/loader.gif";

export default function Verification({ title, text, resetText }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const { verify, resendVerification } = useAuth();
  const location = useLocation();
  const { pathname } = location;

  const [inputValues, setInputValues] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
  });
  const navigate = useNavigate();
  const inputRefs = {
    input1: useRef(),
    input2: useRef(),
    input3: useRef(),
    input4: useRef(),
    input5: useRef(),
    input6: useRef(),
  };

  const isFilled = Object.values(inputValues).every((value) => value);

  const handleInputSubmit = (e) => {
    const { name, value } = e.target;
    
    if (value.length <= 1) {
      setInputValues({ ...inputValues, [name]: value });
      
      if (value && name !== "input6") {
        inputRefs[`input${parseInt(name.charAt(name.length - 1)) + 1}`].current.focus();
      }
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const handleVerification = async () => {
      const verificationCode = Object.values(inputValues).join("");
      const data = { code: verificationCode };
      try {
        const res = await verify(JSON.stringify(data));
        setSuccess("Verification successful");
        navigate("/company");
        setLoading(false);
        setTimeout(() => {
          setSuccess(false);
        }, 1000);
      } catch (error) {
        setLoading(false);
        setError("Invalid OTP Code");
        setTimeout(() => {
          setError(false);
        }, 1000);
      }
    };

    handleVerification();
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain");
    const pastedValues = pastedData.split("").slice(0, 6);

    const updatedInputValues = { ...inputValues };

    Object.keys(updatedInputValues).forEach((inputName, index) => {
      updatedInputValues[inputName] = pastedValues[index] || "";
    });

    setInputValues(updatedInputValues);
  };

  const handleKeyDown = (e) => {
    const { key, target } = e;
    const inputName = target.name;
    const inputIndex = parseInt(inputName.replace('input', ''), 10);

    switch (key) {
      case "ArrowLeft":
        if (inputIndex > 1) {
          inputRefs[`input${inputIndex - 1}`].current.focus();
        }
        break;
      case "ArrowRight":
        if (inputIndex < 6) {
          inputRefs[`input${inputIndex + 1}`].current.focus();
        }
        break;
      case "Backspace":
        setInputValues({ ...inputValues, [inputName]: '' });
        if (inputValues[inputName] === '' && inputIndex > 1) {
          inputRefs[`input${inputIndex - 1}`].current.focus();
        }
        break;
      default:
        break;
    }
  };

  const handleResendVerification = async () => {
    setLoading(true);
    try {
      const user = JSON.parse(sessionStorage.getItem("Log_Details"));
      const { email } = user;
      const request = { email: email, purpose: "email_verification" };
      if (!user) {
        throw new Error("User data not found.");
      }
      await resendVerification(JSON.stringify(request));
      setLoading(false);
      setSuccess("Verification code resent successfully. Please check your email.");
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } catch (error) {
      setError(error.message || "Failed to resend verification code.");
      setLoading(false);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };

  useEffect(() => {
    const handlePasteEvent = (e) => handlePaste(e);
    const handleKeyDownEvent = (e) => handleKeyDown(e);

    document.addEventListener("paste", handlePasteEvent);
    document.addEventListener("keydown", handleKeyDownEvent);

    return () => {
      document.removeEventListener("paste", handlePasteEvent);
      document.removeEventListener("keydown", handleKeyDownEvent);
    };
  }, [inputValues]);

  return (
    <>
      <div className="min-h-screen grid content-between bg-[#F7F7F7] relative">
        <NavBar />
        {loading ? (
          <div className="h-[80vh] flex items-center justify-center">
            <img src={OtpSpinner} alt="Loading" className="w-9 h-9" />
          </div>
        ) : (
          <div className="w-[526px] mx-auto shadow py-[30px] px-[50px] rounded-xl grid gap-10 items-center justify-center bg-white">
            <div className="grid gap-3">
              <BiArrowBack
                onClick={() => navigate(-1)}
                className="cursor-pointer text-[18px]"
              />
              <div className="mx-auto flex flex-col justify-center gap-[2px]">
                <p className="mx-auto text-2xl text-[#101828] font-medium">
                  Verify your email address
                </p>
                <span className="mx-auto text-[14px] text-[#828282] text-center font-normal">
                  Please enter the <span className="font-bold">One Time Password</span> sent to your email.
                </span>
              </div>
            </div>
            <div className="grid gap-3">
              <div className="w-[420px] mx-auto">
                <p className="mb-3 text-[#101828] text-xs font-normal ml-4">OTP</p>
                <div className="w-[395px] mx-auto flex justify-between">
                  {Object.keys(inputValues).map((inputName, index) => (
                    <input
                      ref={inputRefs[inputName]}
                      key={index}
                      className="verifyInput input h-[59px] w-[59px] text-center"
                      name={inputName}
                      inputMode="numeric"
                      maxLength="1"
                      pattern="[0-9]*"
                      value={inputValues[inputName]}
                      onChange={handleInputSubmit}
                      onKeyDown={handleKeyDown}
                    />
                  ))}
                </div>
              </div>
            </div>
            <Button
              onClick={handleSubmit}
              disabled={!isFilled}
              className={
                isFilled
                  ? "w-[420px] bg-black rounded-xl px-[12px] line-[24px] py-[13px] text-white text-[14px]"
                  : ""
              }
              text={resetText || "Verify"}
              loading={loading}
            />
            <FormFooter
              question={"Didn’t get any mail?"}
              option={"Resend verification code"}
              onClick={handleResendVerification}
            />
          </div>
        )}
        <Terms />
      </div>
      {success && <SuccessMessage success={success} className="mt-5 mr-2" />}
      {error && <ErrorMessage error={error} className="mt-5 mr-2" />}
    </>
  );
}

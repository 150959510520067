import React, { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import isotoxal from "../Assets/svg/isotoxal.svg";
import whatsapp from "../Assets/svg/WhatsApp_svg.svg";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";

const UpgradeToPlan = ({ handleClose, onClick, setOpenUpgradePlan, amount,  setOpenPayModal, nextPlanName, props }) => {
  const navigate = useNavigate();

  const { icon = "", text = "", title= ""} = props || {};
  const iconToUse = icon === "isotoxal" ? isotoxal : whatsapp ;

  const openUpgradePlanPop = () => {
    setOpenUpgradePlan(true);
    handleClose();
  };


  return (
    <div className="bg-[#ffffff] w-[496px] h-[497.79px] mt-[100px] rounded-[6.47px]">
      {" "}
      <div>
        <div className="flex items-center justify-between py-[11.33px] px-[19.42px] ">
          <h1 className="text-[18px] leading-[21.6px] font-medium text-[#121212] ">
            Upgrade To {nextPlanName ?? "Plan"} 
          </h1>
          <button
            className="w-[36px] h-[36px] border rounded-lg flex justify-center items-center p-2"
            onClick={handleClose}
          >
            <AiOutlineClose className="text-BLACK-_300" />
          </button>
        </div>
        <hr />
      </div>
      <div className="px-[81px] my-[44px] h-[340px]">
        <div className="flex justify-center items-center">
          <img src={iconToUse} alt="isotoxal" className="w-[85.98px] h-[86px]" />
          <span className="bg-[#1774FD] text-white rounded-[80px] py-[2px] px-[6px] text-[10px] self-end ml-[-30px]">{nextPlanName ?? "Plan"}</span>
        </div>
        <div className="flex flex-col gap-[22px] my-[32px]">
          <div className="flex flex-col gap-[15px]">
            <h1 className="text-[20px] font-medium leading-[21.5px] text-[#101828] text-center">
              {title}
            </h1>
            <p className="text-[14px] text-[#828282] leading-[19px] text-center">
            {text}
            </p>
          </div>
          <p className="text-[14px] text-[#828282] leading-[9px] text-center">
            for
          </p>
          <div className="flex justify-center items-center">
            <p>${amount}</p>{" "}
            <span className="text-[12px] leading-[16.62px] text-[#828282]">
              / per month
            </span>
          </div>
        </div>
        <div className="flex justify-center" onClick={() => openUpgradePlanPop()}>
          <button className="px-[20px] py-[6px] w-[289px] mx-auto rounded-lg bg-[#1774FD] text-[12px] text-[#ffffff]">
            Upgrade to plan
          </button>
        </div>
        <p
          className="text-[10px] leading-[12.85px] text-[#1774FD] text-center underline mt-[12px] cursor-pointer"
          onClick={() => navigate("/settings?page=billing&category=plan")}
        >
          View Other Plans
        </p>
      </div>
    </div>
  );
};

export default UpgradeToPlan;

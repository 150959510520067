import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { api } from "../../services/api"
import { appUrls } from "../../services/urls";



const initialState = {
    loading: false,
    data: [],
    creditBalance: {},
    error: ""
};

export const getGiftBalance = createAsyncThunk(
    "gift-balance/retrieve", 
    async(businessID, { rejectWithValue }) => {
        try {
            const res = await api.get(`/dashboard/wallet/get/all/${businessID}`);
            // console.log(res?.data, "gift balance data");
            return res?.data?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)


const getGiftBalanceSlice = createSlice({
    name: "gift-balance",
    initialState,
    extraReducers: builder => {
        builder.addCase(getGiftBalance.pending, state => {
            state.loading = true
        });
        builder.addCase(getGiftBalance.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            // if (!action.payload) {
            //     state.creditBalance = {};
            // } else {
            //     state.creditBalance = action.payload[3];
            // }

            const usdObject = action.payload.find(item => item.currency === "USD");
            state.creditBalance = usdObject || {};

            state.error = "";
        })
        builder.addCase(getGiftBalance.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default getGiftBalanceSlice.reducer
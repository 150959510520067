import React, { useState } from "react";
import {  QRCodeSVG } from "qrcode.react";
import phoneIcon from "Assets/svg/docPhone.svg";
import desktopLightMode from "Assets/svg/desktopLight.svg";
import desktopDarkMode from "Assets/svg/desktopDarkMode.svg";
import mobileDarkMode from "Assets/svg/mobileDarkMode.svg";
import mobileLightMode from "Assets/svg/mobileLight.svg";
import lightMode from "Assets/svg/lightMode.svg";
import darkMode from "Assets/svg/darkMode.svg";
import laptopIcon from "Assets/svg/laptop.svg";
import globeIcon from "Assets/svg/globee.svg";
import copyBlue from "Assets/svg/copyy.svg";
import closeIcon from "Assets/svg/closeIcon.svg";

import { ErrorMessage, SuccessMessage } from "Toast-Messages/Toastmessages";

import { AiOutlineClose } from "react-icons/ai";

const Documentation = ({ handleClose,link,mobile }) => {
 
  const [activeApp, setActiveApp] = useState("web");
  const [isDark, setIsDark] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  const toggleSwitch = () => {
    setIsDark(!isDark);
  };
  return (
    <>
      {mobile ? (
        <div
          className={`h-fit w-[380px] relative pb-7  bg-WHITE-_100  mt-[100px] flex flex-col justify-center items-center rounded-lg`}
        >
          <div className="flex w-full p-6 justify-between items-center border-b border-neutral-150">
            <p>Preview Your Widget</p>
            <div onClick={handleClose}>
              <img src={closeIcon} alt="Uploaded" className="cursor-pointer" />
            </div>
          </div>
          <div className="p-6 pt-7">
            <p className="text-xs text-[#828282] text-center ">
              Scan to Preview on Mobile
            </p>
            <div className="flex justify-center">
              <QRCodeSVG
                value={link}
                bgColor="#ffffff"
                fgColor="#000000"
                level="Q"
                includeMargin={true}
              />
            </div>
            <p className="mt-4 text-xs text-[#828282] text-center">
              Or Copy the Preview Link Below
            </p>
            <div className="mt-6 bg-[#F3F8FF] rounded-lg flex gap-3 items-center py-2.5 px-3">
              <div className="bg-white flex p-1 items-center justify-center">
                <img src={globeIcon} alt="Uploaded" className="" />
              </div>
              <div className="flex flex-col ">
                <p className="text-[#121212] text-sm">Preview Link</p>
                <p className="text-[#A7A7A7] text-xs font-medium underline">
                 {link}
                </p>
              </div>
              <img
                src={copyBlue}
                alt="copyBlue"
                className="w-[15px] h-[15px] self-end cursor-pointer"
                onClick={() => {
                  navigator.clipboard
                    .writeText(link)
                    .then(() => {
                      setSuccess("copied");
                      setTimeout(() => {
                        setSuccess(false);
                      }, 3000);
                    })
                    .catch((err) => {
                      setError(false);
                    });
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`h-fit w-[800px] relative pb-7  bg-WHITE-_100  mt-[60px] flex flex-col justify-center items-center rounded-lg`}
        >
          <div className="flex px-10  py-7 w-full items-center justify-between ml-10  border-b border-neutral-150">
            <p className="font-medium text-[20px] text-BLACK-_500 ">
              Widget Preview
            </p>
            <div
              className="w-[36px] h-[36px] border rounded-lg mr-3  flex cursor-pointer justify-center items-center p-2"
              onClick={handleClose}
            >
              <AiOutlineClose className="text-neutral-500" />
            </div>
          </div>
          <div className="px-10  ">
            <div className="flex justify-center mb-4 w-fit mx-auto mt-6 border border-chatBrown rounded-xl">
              <div
                className={`
       
       ${activeApp === "web" ? " shadow-lg " : " "}
         py-2 cursor-pointer rounded-xl text-xs px-6 flex gap-1`}
                onClick={() => {
                  setActiveApp("web");
                }}
              >
                Desktop View
                <img src={laptopIcon} alt="Uploaded" className="" />
              </div>
              <div
                className={`
               ${activeApp === "phone" ? " shadow-lg " : " "}
                 py-2 cursor-pointer rounded-xl text-xs px-6 flex gap-1`}
                onClick={() => {
                  setActiveApp("phone");
                }}
              >
                Mobile View
                <img src={phoneIcon} alt="phoneIcon" className="" />
              </div>
            </div>
            <div className="absolute right-10 top-[122px] flex gap-1 text-[10px] font-medium">
              <div className="flex gap-1 items-center justify-center  ">
                <span className={`${isDark ? "text-BLACK-_200" : ""}`}>
                  Light Mode
                </span>

                <div
                  className={`w-[38px] h-5 flex items-center bg-gray-400 rounded-full p-1 cursor-pointer transition-colors duration-300 `}
                  onClick={toggleSwitch}
                >
                  <div
                    className={`bg-white w-3 h-3 rounded-full shadow-md transform transition-transform duration-300 ${
                      isDark ? "translate-x-4" : ""
                    }`}
                    style={{
                      backgroundImage: `url(${isDark ? darkMode : lightMode})`,
                      backgroundSize: "cover",
                    }}
                  ></div>
                </div>
                <span className={`${isDark ? "" : " text-BLACK-_200 "}`}>
                  Dark Mode
                </span>
              </div>
            </div>

            <div>
              <img
                src={
                  activeApp === "web" && !isDark
                    ? desktopLightMode
                    : activeApp === "web" && isDark
                    ? desktopDarkMode
                    : activeApp === "phone" && isDark
                    ? mobileDarkMode
                    : mobileLightMode
                }
                alt="Uploaded"
                className=""
              />
            </div>
          </div>
        </div>
      )}
      {success && <SuccessMessage success={success} />}
      {error && <ErrorMessage error={error} />}
    </>
  );
};

export default Documentation;

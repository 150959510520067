function Notes() {
  return (
    <div>
      {/* CUSTOMER  */}
      <div className="mt-6">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <div className="w-5 h-5 rounded-full bg-BLUE-_200 text-white flex justify-center items-center font-bold text-[10px]">
              B
            </div>
            <p className="text-xs font-semibold">Bruce Wayne</p>
          </div>
          <p className="text-BLACK-_160 font-medium text-xs">12:30 pm</p>
        </div>
        <p className="mt-1 text-sm">
          What percentage of my customers are using the send money feature
        </p>
      </div>
      {/* CUSTOMER  */}
      <div className="mt-6">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <div className="w-5 h-5 rounded-full bg-BLUE-_200 text-white flex justify-center items-center font-bold text-[10px]">
              B
            </div>
            <p className="text-xs font-semibold">Bruce Wayne</p>
          </div>
          <p className="text-BLACK-_160 font-medium text-xs">12:30 pm</p>
        </div>
        <p className="mt-1 text-sm">
          What percentage of my customers are using the send money feature
        </p>
      </div>
    </div>
  );
}

export default Notes;

import React, { useState, useEffect, useRef, forwardRef } from 'react';
import NotifyToInstallAppModal from './NotifyToInstallAppModal';
import ModalPop from '@/../component/modalPop';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
// import { getAllPlugins } from '@/../features/plugins/getAllPluginsSlice';

const AtInput2 = forwardRef(({ control, name, placeholder, type }, ref ) => {
    // const dispatch = useDispatch()
    const [inputValue, setInputValue] = useState('');
    const [showApps, setShowApps] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [filteredApps, setFilteredApps] = useState([]);
    const [cursorPosition, setCursorPosition] = useState(null);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const inputRef = useRef(null);
    const mirrorRef = useRef(null);
    const appId = JSON.parse(localStorage.getItem('wano_agent'))?.id;

    const LightTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
        ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: '#A7A7A7',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    // useEffect(() => {
    //     dispatch(getAllPlugins({ appId }))
    // }, [appId, dispatch])

    const { active = {}, available = {} } = useSelector(state => ({
        active: state.getAllPlugins.data.data.active,
        available: state.getAllPlugins.data.data.available,
    }));

    useEffect(() => {
        if (showApps) {
            const searchTerm = inputValue.split('@').pop();
            const filtered = [...active, ...available]
                .filter(app => app.name.toLowerCase().includes(searchTerm.toLowerCase()));
            setFilteredApps(filtered);
        }
    }, [inputValue, showApps, active, available]);

    useEffect(() => {
        // Update mirror content on input changes
        const styledValue = inputValue
            .split(' ')
            .map(word => word.startsWith('@') ? `<span style="color: blue">${word}</span>` : word)
            .join(' ')
            .replace(/\n/g, '<br>');
        if (mirrorRef.current) {
            mirrorRef.current.innerHTML = styledValue;
        }
    }, [inputValue]);


    const approximateCharWidth = 5.6; 

    const handleInputChange = (e) => {
        const { value, selectionStart } = e.target;
        setInputValue(value);
        setCursorPosition(selectionStart);
        const lastAtPos = value.lastIndexOf('@', selectionStart - 1);
        // const isNewAt = lastAtPos !== -1 && value[selectionStart - 1] !== ' '
        const isNewAt = lastAtPos !== -1 && (!value[selectionStart - 1] || value[selectionStart - 1].trim() === '')
        setShowApps(isNewAt);
        if (value.endsWith('@')) {
            setShowApps(true);
        } else {
            setShowApps(false);
        }
        if (isNewAt) {
            // Calculate and set the dropdown position only if '@' is detected as part of typing
            const leftOffset = inputRef.current.selectionStart * approximateCharWidth;
            setDropdownPosition({ top: 35, left: leftOffset }); // Adjust as needed
        }
        // const lastIndex = value.lastIndexOf('@');
        // const lastCharBeforeAt = value[lastIndex - 1]; // Get character before last '@'
        // const isNewAt = lastIndex !== -1 && (!lastCharBeforeAt || lastCharBeforeAt.trim() === '');
        // if (value.endsWith('@')) {
        //     setShowApps(true);
        // } else {
        //     setShowApps(false);
        // }
        // if (isNewAt) {
        //     // Calculate and set the dropdown position only if '@' is detected as part of typing
        //     const leftOffset = inputRef.current.selectionStart * approximateCharWidth;
        //     setDropdownPosition({ top: 35, left: leftOffset }); // Adjust as needed
        // }
    };

    const handleAppClick = (app, isInstalled) => {
        if (isInstalled) {
            const preText = inputValue.substring(0, cursorPosition);
            const postText = inputValue.substring(cursorPosition);
            const lastAtPos = preText.lastIndexOf('@');
            const newText = `${preText.substring(0, lastAtPos)}@${app.name} ${postText}`;
            setInputValue(newText);
            setShowApps(false);
            setCursorPosition(lastAtPos + app.name.length + 2); // Update cursor position
        } else {
            setShowModal(true);
        }
    };

    const InputComponent = type === "textarea" ? "textarea" : "input";

    return (
        <div style={{ position: 'relative' }}>
            <div
                ref={mirrorRef}
                style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    // padding: '8px',
                    // border: '1px solid #ccc',
                    minHeight: '100px',
                    overflowY: 'auto',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: 'calc(100% - 2px)',
                    zIndex: '1',
                    visibility: "hidden",
                    pointerEvents: 'none',
                    color: 'black',
                    borderRadius: "8px",
                    fontSize: "12px",
                }}
                className='h-[206px] py-[17px] px-[14px]'
            />
            <textarea
                id='messageBox'
                ref={inputRef}
                value={inputValue}
                onChange={handleInputChange}
                style={{
                    position: 'relative',
                    zIndex: '2',
                    background: 'transparent',
                    // padding: '8px',
                    border: '1px solid #ccc',
                    minHeight: '100px',
                    overflowY: 'auto',
                    width: '100%',
                    borderRadius: "8px",
                    color: "transparent",
                    fontSize: "12px",
                }}
                placeholder={placeholder || "Type your instructions here..."}
                className='h-[206px] py-[17px] px-[14px] focus:outline-none'
            />
            {showApps && (
                <div
                    className="flex flex-col gap-3 p-3 w-fit h-fit drop-shadow-md rounded-md cursor-pointer"
                    style={{
                        position: 'absolute',
                        // top: '20%',
                        // left: '0',
                        top: `${dropdownPosition.top}px`,
                        left: `${dropdownPosition.left}px`,
                        backgroundColor: 'white',
                        // border: '1px solid #ccc',
                        zIndex: '2',
                    }}
                >
                    {filteredApps.map(app => (
                        <LightTooltip 
                            key={app.id} 
                            title={app.description} 
                            arrow 
                            TransitionComponent={Zoom}
                            placement="right"
                            sx={{
                                '& .MuiTooltip-arrow': {
                                    color: '#fff',
                                    dropShadow: "0 2px 10px rgba(200, 200, 200, 0.03)",
                                    // transform: 'translateY(-6px)',
                                }
                            }}
                            slotProps={{
                                popper: {
                                modifiers: [
                                    {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 24],
                                    },
                                    },
                                ],
                                },
                            }}
                        >
                            <div
                                key={app.id}
                                onClick={() => handleAppClick(app, active.includes(app))}
                                className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
                                style={{
                                    padding: '5px',
                                    cursor: 'pointer',
                                    backgroundColor: active.includes(app) ? 'white' : '',
                                    opacity: active.includes(app) ? "1": "0.4",
                                }}
                            >
                                <img src={app.image_url} alt={app.name} className='w-4 h-4 rounded-[2.26px]'/> {app.name}
                            </div>
                        </LightTooltip>
                    ))}
                </div>
            )}
            <ModalPop isOpen={showModal} onClose={() => setShowModal(false)}>
                <NotifyToInstallAppModal handleClose={() => setShowModal(false)} />
            </ModalPop>
        </div>
    );
});

export default AtInput2;

// export const Tooltip = ({ children, content }) => (
//     <div className="tooltip-container">
//       {children}
//       <div className="tooltip-content">
//         <p className='text-[#828282] text-xs w-[220px]'>{content}</p>
//       </div>
//     </div>
// );



// export const Tooltip = ({ children, content }) => {
//   const [isVisible, setIsVisible] = useState(false);
//   const [position, setPosition] = useState({ top: 0, left: 0 });

//   useEffect(() => {
//     if (isVisible) {
//       const rect = children.ref.current.getBoundingClientRect();
//       setPosition({
//         top: (rect.top / 2) + (window.scrollY / 1) + (rect.height / 2),
//         left: rect.left + window.scrollX + (rect.width / 2),
//       });
//     }
//   }, [isVisible, children]);

//   return (
//     <>
//       {React.cloneElement(children, { onMouseEnter: () => setIsVisible(true), onMouseLeave: () => setIsVisible(false) })}
//       {isVisible &&
//         ReactDOM.createPortal(
//           <div
//             style={{
//               position: 'absolute',
//               top: `${position.top}px`,
//               left: `${position.left}px`,
//               backgroundColor: '#fcfcfc',
//               color: 'gray',
//               borderRadius: '6px',
//               padding: '5px',
//               zIndex: 1000,
//             }}
//           >
//             {content}
//           </div>,
//           document.messageBox
//         )}
//     </>
//   );
// };

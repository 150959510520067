import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Form, Formik } from "formik";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import TokenService from "../../../services/token";
import { useState, useEffect } from "react";
import { CgSpinner } from "react-icons/cg";
import spinner from "../../../Assets/gif/loader.gif";
import { getCountries } from "../../../features/countries/getCountriesSlice";

import {
  SuccessMessage,
  ErrorMessage,
} from "../../../Toast-Messages/Toastmessages";

const SupportTemplate = ({ handleClose }) => {
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const data = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = data && data.length >= 1 ? data[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [fetchedData, setFetchedData] = useState([]);
  const storeCountries = useSelector((state) => state.getAllCountries);
  const countriesLoading = storeCountries.loading;
  const dispatch = useDispatch();

  const [countrycode, setCountrycode] = useState("+234");
  const [countrycode2, setCountrycode2] = useState("+234");
  const getCountiresAndStore = async () => {
    dispatch(getCountries());
  };
  useEffect(() => {
    getCountiresAndStore();
  }, []);
  const submitForm = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${URL}/dashboard/applications/${applicationId}/memory-template`,
        {
          data: [
            // {
            //   question: "What Channels can customers seek additional support ",
            //   category: "support",
            //   answer: "22",
            // },
            {
              question: "PhoneNumberOne",
              category: "support",
              answer: values.numberOne
                ? `${countrycode}${String(values.numberOne)}`
                : "",
            },
            {
              question: "PhoneNumberTwo",
              category: "support",
              answer: values.numberTwo
                ? `${countrycode2}${String(values.numberTwo)}`
                : "",
            },
            {
              question: "Email",
              category: "support",
              answer: values.email,
            },
            {
              question: "Twitter",
              category: "support",
              answer: values.twitter,
            },
            {
              question: "Instagram",
              category: "support",
              answer: values.instagram,
            },
            {
              question: "Facebook",
              category: "support",
              answer: values.facebook,
            },
            {
              question: "Url",
              category: "support",
              answer: values.url,
            },
            {
              question:
                "How are escalations managed if an issue cannot be resolved immediately?",
              category: "support",
              answer: values.answerTwo,
            },
            {
              question:
                "Where is your company located ?  mention branches also if any",
              category: "support",
              answer: values.answerThree,
            },
            {
              question:
                "Anything else you’d like your customers to know about your business?",
              category: "support",
              answer: values.answerFour,
            },
          ].filter(each=>each.answer),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      setSuccess("Successful: record created successfully");
      setLoading(false);
      setTimeout(() => {
        setSuccess(false);
        handleClose();
      }, 2500);
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
      setError("Error uploading the form. Please try again later.");
      setTimeout(() => {
        setError(false);
      }, 2500);
    }
  };
  const fetchData = async () => {
    if (applicationId === null) {
      console.error(
        "No applicationId provided. fetchData function will not run."
      );
      return;
    }
    try {
      const response = await axios.get(
        `${URL}/dashboard/applications/${applicationId}/memory-template`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFetchedData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getAnswerByCategoryAndQuestion = (category, question) => {
    const foundItem = fetchedData.find(
      (item) => item.category === category && item.question === question
    );
    return foundItem ? foundItem.answer : "";
  };

  return (
    <div className="bg-WHITE-_100 w-[946px] mt-[30px] rounded-lg overflow-y-scroll">
      <div className="flex justify-between h-[80px] items-center mx-4 my-3 ">
        <div className="w-[421px] h-[57px] flex flex-col gap-1 ml-6">
          <p className="font-medium text-2xl text-BLACK-_600">
            Support - Template Guide
          </p>
          <p className="text-xs leading-5 font-normal  text-BLACK-_200">
            Fill the template with the necessary information.
          </p>
        </div>
        <button
          className="w-[36px] h-[36px] border rounded-lg flex justify-center items-center p-2"
          onClick={handleClose}
        >
          <AiOutlineClose />
        </button>
      </div>
      <hr />
      <div className="mt-[30px] ml-6 py-10">
        <Formik
          enableReinitialize
          initialValues={{
            numberOne: getAnswerByCategoryAndQuestion(
              "support",
              "PhoneNumberOne"
            ),
            numberTwo: getAnswerByCategoryAndQuestion(
              "support",
              "PhoneNumberTwo"
            ),
            email: getAnswerByCategoryAndQuestion("support", "Email"),
            twitter: getAnswerByCategoryAndQuestion("support", "Twitter"),
            instagram: getAnswerByCategoryAndQuestion("support", "Instagram"),
            facebook: getAnswerByCategoryAndQuestion("support", "Facebook"),
            url: getAnswerByCategoryAndQuestion("support", "Url"),
            answerTwo: getAnswerByCategoryAndQuestion(
              "support",
              "How are escalations managed if an issue cannot be resolved immediately?"
            ),
            answerThree: getAnswerByCategoryAndQuestion(
              "support",
              "Where is your company located ?  mention branches also if any"
            ),
            answerFour: getAnswerByCategoryAndQuestion(
              "support",
              "Anything else you’d like your customers to know about your business?"
            ),
          }}
          // validationSchema={formValidationSchema}
          onSubmit={(values) => {
            console.log(values, "otp");
            window.scrollTo(0, 0);
            submitForm(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            dirty,
            isValid,
            setFieldValue,
            errors,
            touched,
            // setFieldTouched,
            values,
          }) => (
            <Form onSubmit={handleSubmit} className="flex justify-center">
              <div className="flex flex-col items-center gap-[40px] ">
                <div className="flex flex-col gap-6">
                  <div className="w-[866px] flex flex-col">
                    <p className="label text-left text-[#121212] text-sm font-medium">
                      Q1. What Channels can customers seek additional support :
                    </p>
                  </div>
                  <div className="w-[866px] flex flex-col">
                    <div className="flex px-3 items-center w-[653px] h-[88px]">
                      <p className="w-[220px] h-[38px] text-[#101828] text-xs">
                        Phone Number
                      </p>
                      <div className="flex flex-col gap-3">
                        <div className="flex w-[433px] rounded-lg border-[#D0D5DD] border h-[38px] bg-WHITE-_100 border-solid mt-1.5">
                          {countriesLoading ? (
                            <div className="flex items-center justify-center">
                              <img
                                src={spinner}
                                alt="template"
                                className="w-5 h-5"
                              />
                            </div>
                          ) : (
                            <select
                              name="number"
                              id=""
                              className="text-xs border-t-0 m-0.5 border border-l-0 border-b-0 outline-none "
                              onChange={(e) => {
                                const val = e.target.value;
                                const selectedCountry =
                                  storeCountries?.countries.find(
                                    (country) => country.name === val
                                  );
                                setCountrycode(selectedCountry.phone_code);
                              }}
                            >
                              {storeCountries?.countries.map((country) => (
                                <option
                                  className="flex flex-rowb"
                                  key={country.code}
                                  value={country.name}
                                >
                                  {country.code} ({country.phone_code})
                                </option>
                              ))}
                            </select>
                          )}

                          <input
                            name="numberOne"
                            placeholder=""
                            type="number"
                            value={values?.numberOne}
                            onChange={handleChange}
                            className="outline-none w-[400px] border-none p-3"
                          />
                        </div>
                        <div className="flex w-[433px] rounded-lg border-[#D0D5DD] border h-[38px] bg-WHITE-_100 border-solid mt-1.5">
                          {countriesLoading ? (
                            <div className="flex items-center justify-center">
                              <img
                                src={spinner}
                                alt="template"
                                className="w-5 h-5"
                              />
                            </div>
                          ) : (
                            <select
                              name="number"
                              id=""
                              className="text-xs border-t-0 m-0.5 border border-l-0 border-b-0 outline-none "
                              onChange={(e) => {
                                const val = e.target.value;
                                const selectedCountry =
                                  storeCountries?.countries.find(
                                    (country) => country.name === val
                                  );
                                setCountrycode2(selectedCountry.phone_code);
                              }}
                            >
                              {storeCountries?.countries.map((country) => (
                                <option
                                  className="flex flex-rowb"
                                  key={country.code}
                                  value={country.name}
                                >
                                  {country.code} ({country.phone_code})
                                </option>
                              ))}
                            </select>
                          )}
                          <input
                            name="numberTwo"
                            placeholder=""
                            type="number"
                            value={values?.numberTwo}
                            onChange={handleChange}
                            className="outline-none w-[400px] border-none p-3"
                          />
                        </div>

                        {/* {errors.numberOne && touched.numberOne ? (
                                        <div className='text-RED-_100 text-xs'>{errors.numberOne}</div>
                                        ) : null} */}
                      </div>
                    </div>
                    <div className="flex px-3 items-center w-[653px] h-[88px]">
                      <p className="w-[220px] h-[38px] flex flex-col justify-center text-[#101828] text-xs">
                        Email
                      </p>
                      <div className="flex flex-col gap-3">
                        <input
                          name="email"
                          className="w-[433px] outline-none h-[38px] rounded-lg"
                          type="email"
                          value={values?.email}
                          onChange={handleChange}
                          required
                        />

                        {/* {errors.email && touched.email ? (
                                        <div className='text-RED-_100 text-xs'>{errors.email}</div>
                                        ) : null} */}
                      </div>
                    </div>

                    <div className="flex px-3 items-center w-[653px] h-[88px]">
                      <p className="w-[220px] h-[38px] flex flex-col justify-center text-[#101828] text-xs">
                        X(formerly Twitter)
                      </p>
                      <div className="flex flex-col gap-3">
                        <input
                          name="twitter"
                          className="w-[433px] outline-none h-[38px] rounded-lg"
                          type="text"
                          value={values?.twitter}
                          onChange={handleChange}
                        />

                        {/* {errors.twitter && touched.twitter ? (
                                        <div className='text-RED-_100 text-xs'>{errors.twitter}</div>
                                        ) : null} */}
                      </div>
                    </div>

                    <div className="flex px-3 items-center w-[653px] h-[88px]">
                      <p className="w-[220px] h-[38px] flex flex-col justify-center text-[#101828] text-xs">
                        Instagram
                      </p>
                      <div className="flex flex-col gap-3">
                        <input
                          name="instagram"
                          className="w-[433px] outline-none h-[38px] rounded-lg"
                          type="text"
                          value={values?.instagram}
                          onChange={handleChange}
                        />

                        {/* {errors.instagram && touched.instagram ? (
                                        <div className='text-RED-_100 text-xs'>{errors.instagram}</div>
                                        ) : null} */}
                      </div>
                    </div>

                    <div className="flex px-3 items-center w-[653px]">
                      <p className="w-[220px] h-[38px] flex flex-col justify-center text-[#101828] text-xs">
                        Facebook
                      </p>
                      <div className="flex flex-col gap-3">
                        <input
                          name="facebook"
                          className="w-[433px] outline-none h-[38px] rounded-lg"
                          type="text"
                          value={values?.facebook}
                          onChange={handleChange}
                        />

                        {/* {errors.facebook && touched.facebook ? (
                                        <div className='text-RED-_100 text-xs'>{errors.facebook}</div>
                                        ) : null} */}
                      </div>
                    </div>

                    <div className="flex px-3 items-center w-[653px] h-[88px]">
                      <p className="w-[220px] h-[38px] flex flex-col justify-center text-[#101828] text-xs">
                        Company’s URL or Website
                      </p>
                      <div className="flex flex-col gap-3">
                        <input
                          name="url"
                          className="w-[433px] outline-none h-[38px] rounded-lg"
                          type="text"
                          value={values?.url}
                          onChange={handleChange}
                        />

                        {/* {errors.url && touched.url ? (
                                        <div className='text-RED-_100 text-xs'>{errors.url}</div>
                                        ) : null} */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-6">
                  <div className="w-[866px] flex flex-col">
                    <p className="label text-left text-[#121212] text-sm font-medium">
                      Q2. How are escalations managed if an issue cannot be
                      resolved immediately?
                    </p>
                  </div>
                  <div className="w-[866px] flex flex-col">
                    <div className="flex flex-col lg:w-[866px] gap-2">
                      <label className="label text-left text-[#101828] text-xs font-normal">
                        Answer
                      </label>
                      <textarea
                        name="answerTwo"
                        type="text"
                        cols="50"
                        rows="10"
                        className=" outline-none rounded-lg border border-[#D0D5DD] p-1"
                        value={values?.answerTwo}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    {/* {errors.answerTwo && touched.answerTwo ? (
                                <div className='text-RED-_100 text-xs'>{errors.answerTwo}</div>
                                ) : null} */}
                  </div>
                </div>

                <div className="flex flex-col gap-6">
                  <div className="w-[866px] flex flex-col">
                    <p className="label text-left text-[#121212] text-sm font-medium">
                      Q3. Where is your company located ?  mention branches also
                      if any
                    </p>
                  </div>
                  <div className="w-[866px] flex flex-col">
                    <div className="flex flex-col lg:w-[866px] gap-2">
                      <label className="label text-left text-[#101828] text-xs font-normal">
                        Answer
                      </label>
                      <textarea
                        name="answerThree"
                        type="text"
                        cols="50"
                        rows="10"
                        className=" outline-none rounded-lg border border-[#D0D5DD] p-1"
                        value={values?.answerThree}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    {/* {errors.answerThree && touched.answerThree ? (
                                <div className='text-RED-_100 text-xs'>{errors.answerThree}</div>
                                ) : null} */}
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <div className="w-[866px] flex flex-col">
                    <p className="label text-left text-[#121212] text-sm font-medium">
                      Anything else you’d like your customers to know about your
                      business?
                    </p>
                  </div>
                  <div className="w-[866px] flex flex-col">
                    <div className="flex flex-col lg:w-[866px] gap-2">
                      <label className="label text-left text-[#101828] text-xs font-normal">
                        Answer
                      </label>
                      <textarea
                        name="answerFour"
                        type="text"
                        cols="50"
                        rows="10"
                        className=" outline-none rounded-lg border border-[#D0D5DD] p-1"
                        value={values?.answerFour}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    {/* {errors.answerFour && touched.answerFour ? (
                                <div className='text-RED-_100 text-xs'>{errors.answerFour}</div>
                                ) : null} */}
                  </div>
                </div>

                <div className="w-[886px] flex gap-2 justify-end">
                  <button
                    className="bg-WHITE-_100 border border-solid border-[#D0D5DD] w-[78px] h-[34px] rounded-lg"
                    onClick={handleClose}
                  >
                    <p className="text-[#828282] text-[12px] font-medium">
                      Cancel
                    </p>
                  </button>
                  <button
                    disabled={loading}
                    type="submit"
                    className="bg-BLACK-_500 w-[79px] h-[34px] rounded-lg"
                  >
                    {loading ? (
                      <span className="flex justify-center w-full">
                        <CgSpinner className=" animate-spin text-lg text-WHITE-_100" />
                      </span>
                    ) : (
                      <p className="text-WHITE-_100 text-[12px] font-medium">
                        Upload
                      </p>
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {success && <SuccessMessage success={success} />}
      {error && <ErrorMessage error={error} />}
    </div>
  );
};

export default SupportTemplate;

// import { createContext, useState } from "react";

// export const UserContext = createContext();

// export const UserProvider = ({ children }) => {
//     const [userType, setUserType] = useState(localStorage.getItem('User_Type') || '')

//     console.log({userType})

//     return (
//         <UserContext.Provider value={{userType, setUserType}}>
//             {children}
//         </UserContext.Provider>
//     )
// }
// AuthContext.js
import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { api } from "services/api";
import io from "socket.io-client";

let BASE_URL = process.env.REACT_APP_BASEURL;

// process.env.REACT_APP_BASEURL

const socketRaw = io(process.env.REACT_APP_BASEURL);

const initialState = {
  user: null,
  token: localStorage.getItem("token") || null,
  isAuthenticated: false,
};

export const UserContext = createContext(initialState);
const UserDispatchContext = createContext(null);

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export const UserProvider = ({ children }) => {
  const [mfa, setMFA] = useState(false);
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();
  const [userType, setUserType] = useState("Sales");
  const [basePlan, setBasePlan] = useState(null);
  const [status, setStatus] = useState("uninstalled");
  const [button, setButton] = useState('Memory')
    // const [userType, setUserType] = useState(localStorage.getItem('User_Type') || '')
  const [giftBal, setGiftBal] = useState([]);
  const [creditBal, setCreditBal] = useState();
  // const [userType, setUserType] = useState(localStorage.getItem('User_Type') || '')
  const [showFilterate, setShowFilter] = useState("");
  const [id, setId] = useState("");
  const changeStatus = (stat) => {
    setStatus(stat);
  };

  useEffect(() => {
    const { token } = state;
    if (token) {
      // const decodedToken = jwt.verify(token, SECRET_KEY);
      const encodedPayload = token.split(".")[1];
      const decodedToken = JSON.parse(atob(encodedPayload));
      dispatch({ type: "LOGIN", payload: { user: decodedToken, token } });
    }
  }, []);

  const login = async (credentials) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/auth/login`,
        credentials,
        {
          headers: {
            accept: "/",
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response)
      const { token } = response.data.data;
      localStorage.setItem("token", token);
      dispatch({ type: "LOGIN", payload: { user: null, token } });
      navigate("");
      return token;
    } catch (error) {
      throw error;
    }
  };

  const registerUser = async (userData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/auth/register`,
        userData,
        {
          headers: {
            accept: "/",
            "Content-Type": "application/json",
          },
        }
      );
      const { token } = response.data.data;
      localStorage.setItem("token", token);
      sessionStorage.setItem("token", token);

      dispatch({ type: "LOGIN", payload: { user: null, token } });
      navigate("/verify");
      return token;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const mfaToggle = async (setMFA) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/dashboard/user-mfa/toggle`,
        {
          headers: {
            accept: "/",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setMFA((prev) => !prev);
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const mfaSetting = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/dashboard/user-mfa/setting`,
        {
          headers: {
            accept: "/",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      // navigate("/");
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const mfaVerify = async (mfaOTP) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/user-mfa/verify-otp`,
        {
          headers: {
            accept: "/",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mfaOTP }),
        }
      );
      // navigate("/");
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const mfaRequest = async (userData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/user-mfa/generate-secret`,
        {
          headers: {
            accept: "/",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      // navigate("/dashboard");
      navigate("/dashboard");
      return response;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const verify = async (data) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/auth/verify-account`,
        data,
        {
          headers: {
            accept: "/",
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response)
    } catch (error) {
      throw error;
    }
  };

  const reset = async (data) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/auth/reset-password`,
        data,
        {
          headers: {
            accept: "/",
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response)
    } catch (error) {
      throw error;
    }
  };

  const resendVerification = async (data) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/auth/resend-email`,
        data,
        {
          headers: {
            accept: "/",
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      throw error;
    }
  };

  const createBusiness = async (data) => {
    try {
      const response = await api.post(`/dashboard/businesses/create`, data, {
        headers: {
          accept: "/",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      return response;
      // console.log(response)
    } catch (error) {
      console.log(error, "pipe error");
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    sessionStorage.clear();
    dispatch({ type: "LOGOUT" });
  };

  const dispatches = {setBasePlan, setGiftBal, setCreditBal}
const [socket,setSocket] = useState(socketRaw)
  return (
    <UserContext.Provider
      value={{
        state,socket,
        id,setId,
        showFilterate,
        setShowFilter,
        mfa,
        button,
        setButton,
        setMFA,
        login,
        verify,
        reset,
        resendVerification,
        registerUser,
        createBusiness,
        mfaRequest,
        mfaVerify,
        logout,
        userType,
        setUserType,
        status,
        changeStatus,
        basePlan,
        giftBal,
        creditBal,
      }}
    >
      <UserDispatchContext.Provider value={dispatches}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const useUserDispatch = () => {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within an AuthProvider');
  }
  return context;
};

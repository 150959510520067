import { HiXMark } from "react-icons/hi2";
import { useState } from "react";
import { Radio } from "@mui/material";
import { BsCheck } from "react-icons/bs";
import Bank from "../../../Assets/svg/bank.svg";
import axios from "axios";
import TokenService from "../../../services/token";
import { CgSpinner } from "react-icons/cg";
import {
  SuccessMessage,
  ErrorMessage,
} from "../../../Toast-Messages/Toastmessages";
import { useSelector } from "react-redux";
import ComingSoon from "./Comingsoon";
import { useNavigate } from "react-router";

export default function ChooseServices({
  handleClose,
  getServiceData,
  handleFetchUpdate,
  choosenServiceData,
}) {
  const navigate = useNavigate()
  const [checkedItems, setCheckedItems] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  const handleCheckboxChange = (checkboxName) => {
    setCheckedItems({
      ...checkedItems,
      [checkboxName]: !checkedItems[checkboxName],
    });
  };
  const CheckedIcon = () => {
    return (
      <div className="w-5 h-5 bg-[#1774FD] rounded-[999px] flex justify-center items-center">
        <BsCheck className="text-white w-5 h-5" />
      </div>
    );
  };
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const data = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = data && data.length >= 1 ? data[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const selectedServices = Object.keys(checkedItems).filter(
    (serviceName) => checkedItems[serviceName]
  );

  const handleAddService = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${URL}/dashboard/applications/${applicationId}/modules`,
        {
          ids: selectedServices,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccess("You have successfully chosen a service.");
      localStorage.setItem('Wano_Feature', 'configured');
      sessionStorage.setItem("done", true)
      setTimeout(() => {
        handleFetchUpdate();
        setSuccess(false);
        setTimeout(() => {
          handleClose();
          navigate('/agent')
          // window.location.reload()
        }, 500);
      }, 2500);
    } catch (error) {
      console.error("Error adding service(s):", error);
      setIsLoading(false);
      setError("An error occurred while choosing a service.");
      setTimeout(() => {
        setError(false);
      }, 2500);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className=" w-[956px] h-[570px] bg-white ml-36 rounded-lg shadow-makeReqModal font-figtree relative mt-7">
      <header className=" pl-10 flex pr-6 justify-between pt-[20px] pb-2">
        <h2 className="text-[#101828] font-medium text-lg">Choose Services</h2>
        <div
          className="border p-2 -mt-1 rounded-lg border-[#F7F7F7] cursor-pointer"
          onClick={handleClose}
        >
          <HiXMark className="text-[20px]   text-[#828282]" />
        </div>
      </header>
      <hr />
      <section className="pt-6 px-[40px]">
        <p className=" text-[#7F7F81] text-sm font-medium mb-6">
          Choose one or multiple services for your business
        </p>
        <section className="flex gap-5 flex-wrap overflow-y-auto h-[340px]">
          {getServiceData &&
            getServiceData.map((data) => (
              <section
                key={data.id}
                className={`w-[264px] rounded-xl pb-1 ${
                  checkedItems[data?.id] ||
                  choosenServiceData?.some(
                    (chosenData) => chosenData?.module?.id === data.id
                  )
                    ? "bg-[rgba(205,222,248,0.20)] border-[#1774FD] border border-solid"
                    : "bg-[rgba(249,249,249,0.86)]"
                }`}
              >
                <div className="flex justify-between  px-[15px] items-center pt-5">
                  <div className=" flex gap-[12px] items-center">
                    <img src={Bank} alt="" />
                    <p className="capitalize text-[16px] font-medium text-[#121212]">
                      {data.name}
                    </p>
                  </div>
                  <Radio
                    sx={{
                      color: "#EBEBEB",
                      height: 20,
                      width: 20,
                      border: "1px",
                      background: "#fff",
                    }}
                    checkedIcon={<CheckedIcon />}
                    checked={
                      checkedItems[data?.id] === true ||
                      choosenServiceData?.some(
                        (chosenData) => chosenData?.module?.name === data.name
                      )
                    }
                    onClick={() => {
                      const isAlreadyChosen = choosenServiceData?.some(
                        (chosenData) => chosenData?.module?.name === data.name
                      );
                      if (!isAlreadyChosen) {
                        handleCheckboxChange(data.id);
                      }
                    }}
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                  />
                </div>
                <p className="px-[15px] text-xs text-[#7F7F81] font-normal  pt-6 pb-11">
                  {data.description}
                </p>
              </section>
            ))}
          <ComingSoon name="Customer Service" />
          <ComingSoon name="Card Issurance" />
          <ComingSoon name="Insurance" />
        </section>
        <section className="flex justify-end absolute bottom-8 right-[40px]">
          <button
            onClick={handleAddService}
            disabled={isLoading || selectedServices.length === 0}
            className={`outline-none bg-[#121212] rounded-lg  text-white text-xs font-semibold  py-[12px] w-28
            ${
              isLoading || selectedServices.length === 0
                ? " cursor-not-allowed"
                : " cursor-pointer"
            }`}
          >
            {isLoading ? (
              <span className="flex justify-center w-full">
                <CgSpinner className=" animate-spin text-lg " />
              </span>
            ) : (
              <span>Add Service(s)</span>
            )}
          </button>
        </section>
      </section>
      {success && <SuccessMessage success={success} />}
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

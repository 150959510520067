import { useState, useEffect } from "react";
import { TestAgentIcon } from "../../Assets/svg";
import "animate.css/animate.min.css";
import TestAgentPop from "./TestAgentPop";
import { fetchWalletBalance } from "../../features/wallet/walletBalanceSlice";
import { useSelector, useDispatch } from "react-redux";
import newTestLogo from "../../Assets/svg/test-left.svg";
import arrowDown from "../../Assets/svg/testArrow.svg";
import testRight from "../../Assets/svg/testRight.svg";
import Head from "../../Assets/svg/Shape65.svg";
import axios from "axios";
import TokenService from "../../services/token";
import {
  setChatID,
  setModalVisibility,
} from "@/../features/testAgent/chatSlice";
import TestAgentPop2 from "./TestAgentPop2";
import {
  getCurrentEmployee,
  updateAgentModals,
} from "@/../features/modals/agentModalsSlice";
import { showTestemployeeTour } from "@/../features/testAgent/chatSlice";
import { api } from "@/../services/api";

export default function TestAgent({
  activeButton,
  setActiveButton,
  propShowPopUp,
  setPropShowPopUp,
  gotoMemory,
}) {
  
  const [showPopUp, setShowPopUp] = useState(false);
  const [isMinimizing, setIsMinimizing] = useState(false);
  const dispatch = useDispatch();
  const walletBalance = useSelector((state) => state.walletBalance);
  const { tokens } = walletBalance.data;
  let num = Math.floor(tokens);
  let convertedCredit = num.toLocaleString("en-US");
  const URL = process.env.REACT_APP_BASEURL;

  useEffect(() => {
    dispatch(fetchWalletBalance());
  }, []);

  const handleOpen = () => {
    setTestEmployeePopup();
    // handleAgentModal("testEmployeePopup")
    setShowModal(false);
    setShowPopUp(true);
  };

  const handleClose = () => {
    setIsMinimizing(true);
    setTimeout(() => {
      setShowPopUp(false);
      setIsMinimizing(false);
      setPropShowPopUp(false);
    }, 500);
  };

  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const data = appDetail?.data?.data;
  const agent = data && data.length >= 1 ? data[0] : {};

  const agentData = localStorage.getItem("wano_agent");
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const token = TokenService.getToken();

  const appID = useSelector((state) => state.chat.appID);
  const getEmployeeTour = useSelector((state) => state?.chat);

  useEffect(() => {
    if(applicationId){
      fetchData();
    }
  }, [applicationId]); //appDetail, 

  const [alldata, setData] = useState();
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const newAgent = getAgentApplication?.agent || null;

  const fetchData = async () => {
    if (!applicationId) {
      console.error(
        "No applicationId provided. fetchData function will not run."
      );
      return;
    }
    // setLoading(true);
    try {
      const response = await api.get(`/dashboard/applications/${applicationId}`);

      if (applicationId !== appID) {
        ////use this to clear previous chatID if the requirement is met
        dispatch(setChatID(""));
      }
      setData({
        name: response.data.data.name,
        description: response.data.data.description,
        icon_url: response.data.data.icon_url || null,
        personality_type: response.data.data.personality_type,
        verbose: response.data.data.verbose,
      });
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false);
    }
  };

  const getTestEmployeePopup = localStorage.getItem("testEmployeePopup");
  const setTestEmployeePopup = () =>
    localStorage.setItem("testEmployeePopup", true);
  const [showModal, setShowModal] = useState(!getTestEmployeePopup);

  // const config = useSelector(state => state.agentModals.employeeModals)
  // console.log(config, "configA");

  // const getTestEmployeePopup = config?.testEmployeePopup; console.log(getTestEmployeePopup, "configA getTestEmployeePopup");
  // const [showModal, setShowModal] = useState(false)
  // console.log(showModal, "configA setShowModal");

  // useEffect(() => {
  //   if(getTestEmployeePopup){
  //     setShowModal(true)
  //   }else{
  //     setShowModal(false)
  //   }
  // }, [getTestEmployeePopup])

  // const handleAgentModal = (name) => {
  //   const currentModalState = config[name];
  //   if (currentModalState) {
  //     const modalsVisibility = {...config, [name]: false}
  //     dispatch(updateAgentModals({ modalsVisibility, id: applicationId }))
  //     .then((res) => {
  //       dispatch(getCurrentEmployee({id: applicationId}))
  //     })
  //     .catch((error) => {
  //       console.error("Failed to update modal state:", error);
  //   });;
  //   }
  // }
  const [isDataM, setIsDataM] = useState(false);
  const modalVisibility = useSelector((state) => state.chat.modalVisibility); //used to get current chatID

  useEffect(() => {
    if(!applicationId) return;
    const fetchData = async () => {
      if (!applicationId) {
        console.error(
          "No applicationId provided. fetchData function will not run."
        );
        return;
      }

      try {
        const response = await axios.get(
          `${URL}/dashboard/applications/${applicationId}/memory`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.data.length > 0) {
          // dispoatch some action here
          dispatch(setModalVisibility(true));
          // setIsDataM(true);
        } else {
          dispatch(setModalVisibility(false));

          setIsDataM(false); //added this clause to change the state if its false
        }
      } catch (error) {}
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId]);
  return (
    <main className="font-figtree">
      {getEmployeeTour.testEmployeeTour && showModal && (
        <>
          <div className="bg-black bg-opacity-50 h-screen w-screen fixed top-0 left-0"></div>
          <div className="fixed z-2 bottom-40  right-[26px]  ">
            {" "}
            {/* adjusted the z-index from z-40 to z-2 */}
            <div className="flex flex-col w-[337px] gap-3 bg-white py-[18px] px-[24px] rounded-xl">
              <div className="flex items-center gap-2">
                <img
                  src={Head}
                  alt="shape"
                  className="w-[23.63px] h-[23.63px]"
                />
                <p className="text-[#7F7F81] text-[12px] leading-[18px]">
                  You just uploaded your first document
                </p>
              </div>
              <p className="text-sm text-[#121212]">
                Test your assistant with the information you just uploaded. Or
                keep uploading more information.
              </p>
            <div className="flex justify-between text-[12px]">
            <button onClick={() => dispatch(showTestemployeeTour(false))} className="text-[#7F7F81] border border-[#7F7F81] leading-[14.4px] p-2 font-semibold rounded-lg">Keep Uploading</button>
            <button onClick={() => {handleOpen();}} className="font-semibold text-white leading-[14.4px] p-2 bg-[#1774FD] rounded-lg">Test Assistant</button>
            </div>
              <img
                src={arrowDown}
                alt="sales"
                className="w-9 h-6 absolute -bottom-5 right-9"
              />
            </div>
          </div>
        </>
      )}
      {true && (
        <aside className="fixed z-10 bottom-10  right-[26px]  ">
          {" "}
          {/* adjusted the z-index from z-40 to z-2 */}
          <div className="flex gap-3 bg-white items-center py-[11.25px] px-[15px] rounded-full shadow-md shadow-box">
            <section className="">
              <div className="flex items-end">
                <img
                  src={
                    newAgent?.draft?.icon_url
                      ? newAgent?.draft?.icon_url
                      : newAgent?.icon_url
                      ? newAgent?.icon_url
                      : newTestLogo
                  }
                  alt="sales"
                  className="w-8 h-8 rounded-full"
                />
                {/* <p className=" text-[#1774FD] text-[5.163px] -ml-1">AI</p> */}
              </div>
            </section>
            <section>
              <p className=" text-[15px] font-medium text-[#121212]">
                Test Assistant
              </p>
            </section>
            <section className="cursor-pointer" onClick={handleOpen}>
              <img src={testRight} alt="sales" className="w-9 h-9" />
            </section>
          </div>
        </aside>
      )}

      {(showPopUp || propShowPopUp) && (
        <TestAgentPop
          handleClose={handleClose}
          isMinimizing={isMinimizing}
          alldata={alldata}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          gotoMemory={gotoMemory}
        />
      )}
    </main>
  );
}

import React, { useState, useEffect, useLayoutEffect } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import Profile from "../Settings/ProfileTab";
import Teams from "../Settings/TeamsTab";
import API from "../Settings/ApiTab";
import Spinner from "component/Spinner";

import Integration from "../Settings/IntegrationTab";
import { useDispatch, useSelector } from "react-redux";
import { fetchTeamMembers } from "../features/teams/getTeamsSlice";
import { fetchRole } from "../features/role/getRoleSlice";
import { useFormikContext } from "formik";
import ModalPop from "../component/modalPop";
import { BottomGuide } from "../component/modals/agentGuide/Guide";
import { useLocation, useNavigate } from "react-router";
import SettingsSvg from "../Assets/svg/settings.svg";
import info from "../Assets/svg/infocircle.svg";
import xCircle from "../Assets/svg/x-circlered.svg";
import Plugins from "../Settings/PluginsTab";
import ApiKey from "@/../Settings/IntegrationTab/component/ApiKey";
import { updateBusinessModals } from "@/../features/profile/getProfileSlice";
import { getProfile } from "@/../features/profile/getProfileSlice";
import PlanCard from "./PlanCard";
import { BiChevronRight } from "react-icons/bi";
import { api } from "@/../services/api";
import CardPlans from "./CardPlans";
import RedeemGift from "@/../component/modals/redeemgift";
import DowngradePlan from "./DowngradePlan";
import CancelPlan from "./CancelPlan";

export default function ManagePlan() {
  const location = useLocation();
  const [activeButton, setActiveButton] = useState("Profile");
  const [openTourModal, setOpenTourModal] = useState(false);
  const [page, setPage] = useState("");
  const [category, setCategory] = useState("");
  const [currentPlan, setCurrentPlan] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openCancelPlan, setOpenCancelPlan] = useState(false);

  const {
    subscription_details = {},
    yearly_amount,
    tag_slug,
    disabled,
    created_at,
  } = currentPlan || {};
  const {
    features = [],
    name,
    description,
    monthly_amount,
  } = subscription_details;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");
    setPage(page);
    const category = searchParams.get("category");
    setCategory(category);
  }, [location.search]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await api.get("/business-subscription/get/current");
      console.log(res?.data?.data, "res res res");
      setCurrentPlan(res?.data?.data);
    } catch (error) {
      console.log(error, "this error");
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();

  return (
    <DashboardLayout title={"Settings"} user={true}>
      {loading ? (
        // <PlanCardSkeleton />
        <div className="h-[50vh] w-screen flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <section className="px-[40px] pt-[52px]">
          <div className="flex">
            <p
              onClick={() => navigate("/top-up")}
              className="cursor-pointer underline text-[#D2D0D0] text-sm font-normal hover:underline"
            >
              Billing
            </p>
            <BiChevronRight className=" text-xl text-[#D2D0D0]" />
            <p
              onClick={() => navigate("/settings?page=billing&category=plan")}
              className="cursor-pointer underline text-[#D2D0D0] text-sm font-normal hover:underline"
            >
              Plans
            </p>
            <BiChevronRight className=" text-xl text-[#D2D0D0]" />
            <p className="text-[#121212] text-sm font-normal ">Manage Plan</p>
          </div>
          <div>
            <div className="flex justify-between items-center mt-[31px]">
              <div className="flex flex-col gap-[16px]">
                <h1 className="text-[20px] leading-[24px] font-medium">
                  {name}
                </h1>
                <p className="text-[14px] leading-[9px] text-[#828282]">
                  {description}
                </p>
              </div>
              <button
                onClick={() => setOpenCancelPlan(true)}
                className="bg-[#AF202D] flex gap-[4px] py-[5px] px-[12px] text-white rounded-[8px] font-semibold items-center text-[12px] leading-[24px]"
              >
                <img src={xCircle} alt="cancel" /> Cancel Plan
              </button>
            </div>
            <div className="flex justify-between items-center">
              <div>
                <div className="mt-[60px] flex items-center">
                  <h1 className="text-[24px] text-[#101828] font-medium leading-[28.12px]">
                    ${monthly_amount}
                  </h1>
                  <span className="text-[#828282] text-[15.69px] leading-[36.62px]">
                    /per month
                  </span>
                </div>
                <div className="text-[14px] leading-[28px] text-[#828282] flex gap-[2px]">
                  <span className="">Next Billing Cycle:</span>
                  <span className="">July 23, 2024</span>
                </div>
              </div>
              <div className="flex gap-[4px]">
                <img src={info} alt="info circle" />
                <button onClick={() => navigate("/support")}>
                  <p className="text-[10px] leading-[12.85px] text-[#1774FD] underline">
                    How we charge for subscription
                  </p>
                </button>
              </div>
            </div>
            <h1 className=" text-[18px] leading-[28.12px] font-medium text-[#101828] mt-[59px]">
              Benefits
            </h1>
            <div className=" flex flex-wrap gap-[95px] mt-[40px]">
              {features?.map(
                ({ baseSubscriptionId, type, prefix, description }, i) => {
                  return (
                    <CardPlans
                      key={baseSubscriptionId}
                      type={type}
                      prefix={prefix}
                      description={description}
                    />
                  );
                }
              )}
            </div>
          </div>
        </section>
      )}

      <ModalPop isOpen={openCancelPlan}>
        <CancelPlan
          handleClose={() => setOpenCancelPlan(false)}
          heading={"Cancel Plan"}
          warning={
            "Are you sure you want to cancel this plan? Doing this will stop you from enjoying all the benefits of the current plan and revert you to the free plan."
          }
          label1={"Cancel"}
          label2={"Cancel"}
        />
      </ModalPop>
    </DashboardLayout>
  );
}

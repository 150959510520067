import React, { Fragment, useState, useRef, useEffect } from "react";
import Button from "./Button";
import FormFooter from "./FormFooter";
import Terms from "./Terms";
import NavBar from "./NavBar";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import WelcomeLogoText from "./WelcomeLogotext";
import { toast } from "react-toastify";
import { useAuth } from "../context/UserContext";
import { ErrorMessage, SuccessMessage } from "../Toast-Messages/Toastmessages";

export default function ResetOtp({ title, text, resetText }) {
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { reset, resendVerification } = useAuth();

  const [inputValues, setInputValues] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
  });
  const navigate = useNavigate();
  const inputRefs = {
    input1: useRef(),
    input2: useRef(),
    input3: useRef(),
    input4: useRef(),
    input5: useRef(),
    input6: useRef(),
  };

  const isFilled = Object.values(inputValues).every((value) => value);

  const handleInputSubmit = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
    if (value && name !== "input6") {
      inputRefs[
        `input${parseInt(name.charAt(name.length - 1)) + 1}`
      ].current.focus();
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const handleVerification = async () => {
      const verificationCode = Object.values(inputValues).join("");
      const schema = {
        password: localStorage.getItem("new_pd"),
        code: verificationCode,
      };
      try {
        const res = await reset(JSON.stringify(schema));
        setLoading(false);
        setSuccess("Password reset was succesful");
        setTimeout(() => {
          setSuccess(false);
          setTimeout(() => {
            navigate("");
          }, 500);
        }, 1000);
      } catch (error) {
        console.log(error)
        setError(error.message);
        setTimeout(() => {
          setError(false);
        }, 1000);
        setLoading(false);
      }
    };
    handleVerification();
  };

  const handleResendVerification = async () => {
    setLoading(true);
    try {
      const user = JSON.parse(sessionStorage.getItem('Log_Details'));
      const { email } = user
      const request = {
        email: email,
        purpose: "forgot_password"
      }
      if (!user) {
        throw new Error("User data not found.");
      }
      await resendVerification(JSON.stringify(request));
      setLoading(false);
      setSuccess("Reset Password code resent successfully. Please check your email.");
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } catch (error) {
      setError(error.message || "Failed to resend verification code.");
      setLoading(false);
      setTimeout(() => {
        setError(false);
      }, 2000); 
    }
  };
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain");
    const pastedValues = pastedData.split("").slice(0, 6);

    const updatedInputValues = { ...inputValues };

    Object.keys(updatedInputValues).forEach((inputName, index) => {
      updatedInputValues[inputName] = pastedValues[index] || "";
    });

    setInputValues(updatedInputValues);
  };

  const handleKeyDown = (e) => {
    const { key, target } = e;
    const inputName = target.name;

    switch (key) {
      case "ArrowLeft":
        handleArrowLeft(inputName);
        break;
      case "ArrowRight":
        handleArrowRight(inputName);
        break;
      default:
        break;
    }
  };

  const handleArrowLeft = (currentInputName) => {
    const currentInputIndex =
      Object.keys(inputValues).indexOf(currentInputName);

    if (currentInputIndex > 0) {
      const prevInputName = `input${currentInputIndex}`;
      inputRefs[prevInputName].current.focus();
    }
  };

  const handleArrowRight = (currentInputName) => {
    const currentInputIndex =
      Object.keys(inputValues).indexOf(currentInputName);

    if (currentInputIndex < Object.keys(inputValues).length - 1) {
      const nextInputName = `input${currentInputIndex + 2}`;
      inputRefs[nextInputName].current.focus();
    }
  };

  useEffect(() => {
    const handlePasteEvent = (e) => handlePaste(e);
    const handleKeyDownEvent = (e) => handleKeyDown(e);

    document.addEventListener("paste", handlePasteEvent);
    document.addEventListener("keydown", handleKeyDownEvent);

    return () => {
      document.removeEventListener("paste", handlePasteEvent);
      document.removeEventListener("keydown", handleKeyDownEvent);
    };
  }, []);

  return (
    <>
      <div className="h-screen grid content-between bg-[#F7F7F7]">
        <NavBar />
        <div className="w-[526px] mx-auto shadow py-[30px] px-[50px] rounded-xl grid gap-10 items-center justify-center bg-white">
          <div className="grid gap-3">
            <BiArrowBack
              onClick={() => navigate(-1)}
              className="cursor-pointer text-[18px]"
            />
            <WelcomeLogoText
              width={"w-[424px]"}
              title={"Reset your password"}
              text={
                "Check your email address provided for an email verification from us. Please enter below the OTP in the mail."
              }
            />
          </div>
          <div className="grid gap-3">
            <div className="w-[420px] mx-auto">
              <div className="w-[395px] mx-auto flex justify-between">
                {Object.keys(inputValues).map((inputName, index) => (
                  <input
                    ref={inputRefs[inputName]}
                    key={index}
                    className="verifyInput input h-[59px] w-[59px] text-center"
                    name={inputName}
                    // type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={inputValues[inputName]}
                    onChange={handleInputSubmit}
                  />
                ))}
              </div>
            </div>
          </div>
          <Button
            onClick={handleSubmit}
            disabled={!isFilled}
            className={
              isFilled
                ? "w-[420px] bg-black rounded-xl px-[12px] line-[24px] py-[13px] text-white text-[14px]"
                : ""
            }
            text={"Reset"}
            loading={loading}
          />
          <FormFooter
            question={"Didn’t get any mail?"}
            option={"Resend verification code"}
            onClick={handleResendVerification}
          />
        </div>
        <Terms />
      </div>
      {success && <SuccessMessage success={success} className="mt-5 mr-2" />}
      {error && <ErrorMessage error={error} className="mt-5 mr-2" />}
    </>
  );
}

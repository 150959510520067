import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import { UserProvider, useAuth } from "./context/UserContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { persistor, store } from "./app/store";
import { PersistGate } from "redux-persist/integration/react";
import { TourProvider as ReactTourProvider, useTour } from "@reactour/tour";
import Head from "./Assets/svg/Shape65.svg";
import Check from "./Assets/svg/check-verified-01.svg";
import { components } from "@reactour/tour";
import { TourProvider } from "./Tour/TourProvider";
import { ModalProvider } from "./context/ModalContext";

function Badge({ children }) {
  return (
    <components.Badge
      styles={{ badge: (base) => ({ ...base, backgroundColor: "" }) }}
    >
      {children}
    </components.Badge>
  );
}

function Close({ onClick }) {
  const { setCurrentStep } = useTour();
  const { setId } = useAuth();
  return (
    <button
      onClick={() => {
        setCurrentStep(0);
        setId("");
        onClick();
      }}
      style={{ position: "absolute", right: 0, top: 0, padding: 14 }}
    >
      x
    </button>
  );
}

const styles = {
  maskArea: (base) => {
    console.log(base, "masked base");
    return { ...base, rx: 12, outline: "2px solid red", borderRadius: "-5px" };
  },
  controls: (base) => ({ ...base }),
  maskWrapper: (base) => {
    console.log(base, "base");
    return {
      ...base,
      color: "",
    };
  },
  highlightedArea: (base, { x, y }) => {
    console.log(base, x, y, "base b");
    return {
      ...base,
      x: x + 10,
      y: y + 10,
    };
  },
  badge: (base) => ({ ...base, color: "blue" }),
  popover: (base) => ({
    ...base,
    //backgroundColor: "#000", //changes the backgroound colour of the popover //must be dynamically changed
  }),
};

const root = ReactDOM.createRoot(document.getElementById("root"));

const activeButton = {
  1: "Memory",
  2: "Features",
  3: "Settings",
  4: "Integrations/Deploy",
  5: "Integrations/Deploy",
};

const FinalStep = ({ setIsOpen }) => {
  const { setButton } = useAuth();

  return (
    <div className="centered-final-step text-[12px] flex flex-col gap-2">
      <div className="flex gap-2 flex-col">
        <img src={Check} alt="Image" className="h-[70px] w-[70px] mx-auto" />
        <p className="text-[#121212] leading-[18px] font-medium">
          You completed the tour! Now, go back to Memory to start training your
          assistant.
        </p>
      </div>
      <button
        className="bg-[#1774FD] text-white rounded-[8px] p-2 leading-[14.4px]"
        onClick={() => {
          setButton("Memory");
          setIsOpen(false);
        }}
      >
        Take me back to Memory
      </button>
    </div>
  );
};

const steps = [
  {
    selector: ".step-1",
    content: (
      <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
          <p className="text-[#7F7F81]">Get to know your Dashboard</p>
        </div>
        <p>
          Track your credits and top up before it runs out to keep your
          assistant active.
        </p>
        <p className="text-[#7F7F81]">Step 1 of 7 </p>
      </div>
    ),
    position: "right",
    arrow: "disabled",
    isAgentStep: false,
  },
  {
    selector: ".step-2",
    content: (
      <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
          <p className="text-[#7F7F81]">Get to know your Dashboard</p>
        </div>
        <p>
          In Assistant, You can train, customize and launch your assistant when
          you think it’s ready
        </p>
        <p className="text-[#7F7F81] text-center">Step 2 of 7 </p>
      </div>
    ),
    position: "right",
    arrow: "disabled",
    isAgentStep: true,
  },
  {
    selector: ".stepsInAgent-0",
    content: (
      <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
          <p className="text-[#7F7F81]">Get to know your Dashboard</p>
        </div>
        <p>
          In Memory, You can train your assistant by uploading documents with
          information about your company.
        </p>
        <p className="text-[#7F7F81]">Step 3 of 7 </p>
      </div>
    ),
    position: "right",
    arrow: "disabled",
    isAgentStep: true,
  },
  {
    selector: ".stepsInAgent-1",
    content: (
      <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
          <p className="text-[#7F7F81]">Get to know your Dashboard</p>
        </div>
        <p>
          In Instructions, You can set how your employee should respond to
          specific requests, complaints and enquiries.
        </p>
        <p className="text-[#7F7F81]">Step 5 of 7 </p>
      </div>
    ),
    position: "right",
    arrow: "disabled",
    isAgentStep: true,
  },
  {
    selector: ".stepsInAgent-2",
    content: (
      <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
          <p className="text-[#7F7F81]">Get to know your Dashboard</p>
        </div>
        <p>
          In Settings, You can customize your assistant to fit your company’s
          brand.
        </p>
        <p className="text-[#7F7F81]">Step 6 of 7 </p>
      </div>
    ),
    position: "right",
    arrow: "disabled",
    isAgentStep: true,
  },
  {
    selector: ".stepsInAgent-3",
    content: (
      <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
          <p className="text-[#7F7F81]">Get to know your Dashboard</p>
        </div>
        <p>
          In Test/Launch, You can test your assistant and launch to your
          customers on web, mobile or whatsapp when you think it’s ready.
        </p>
        <p className="text-[#7F7F81]">Step 7 of 7 </p>
      </div>
    ),
    position: "right",
    arrow: "disabled",
    isAgentStep: true,
  },
  {
    content: (props) => <FinalStep {...props} />,
    position: "center",
    arrow: "disabled",
    isAgentStep: true,
  },
];

const additionalStyles = `
  .centered-final-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    text-align: center;
    background-color: white; /* Optional: set background color */
  }
`;

root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ModalProvider>
              <TourProvider>
                <ReactTourProvider
                  scrollSmooth
                  steps={steps}
                  components={{ Close }}
                  styles={styles}
                  padding={{ mask: 0, popover: 5, wrapper: 0 }}
                  disableDotsNavigation={false}
                  showBadge={false}
                  showDots={false}
                  className={`rounded-lg`}
                  maskClassName="rounded-lg"
                  highlightedMaskClassName="rounded-lg"
                  prevButton={({ currentStep, setCurrentStep, steps }) => {
                    const first = currentStep === 0;
                    const last = currentStep === steps.length - 1;
                    if (last) {
                      return null;
                    }
                    return (
                      <button
                        className="text-[#7F7F81] text-[12px]"
                        onClick={() => {
                          if (first) {
                            setCurrentStep((s) => steps.length - 1);
                          } else {
                            setCurrentStep((s) => s - 1);
                          }
                        }}
                      >
                        Back
                      </button>
                    );
                  }}
                  nextButton={({
                    currentStep,
                    setCurrentStep,
                    steps,
                    stepsLength,
                    setIsOpen,
                  }) => {
                    const navigate = useNavigate();
                    const location = useLocation();
                    const { button, setButton, setShowFilter, setId } = useAuth();
                    const last = currentStep === stepsLength - 1;
                    const second2Last = currentStep === stepsLength - 2;
                    const currentStepData = steps[currentStep];

                    if (last) {
                      return null;
                    }
                    return (
                      <button
                        className="bg-[#1774FD] text-white p-2 rounded-lg text-[12px] leading-[14.4px]"
                        onClick={() => {
                          // if (last) {
                          //   setIsOpen(false);
                          // } else if() {
                          //   setCurrentStep((s) =>
                          //     s === steps?.length - 1 ? 0 : s + 1
                          //   );
                          // }
                          // else if (currentStep === 1) {
                          //   navigate("/agent");
                          // }
                          // else if (location.pathname === "/agent") {
                          //   setButton(() => activeButton[currentStep]);
                          //   console.log(button, "actbutton");
                          // }
                          // Always run this
                          setCurrentStep((s) =>
                            s === steps?.length - 1 ? 0 : s + 1
                          );

                          if (last) {
                            setIsOpen(false);
                            setCurrentStep(0);
                          } else if (currentStepData.isAgentStep) {
                            navigate("/agent");
                            setButton(() => activeButton[currentStep]);
                            console.log(button, "actbutton");
                          };

                          if (currentStepData.isConversation) {
                              setShowFilter(prev => !prev);
                          }else{
                            setShowFilter('');
                          };

                          // if(currentStepData.openConvo){
                          //     setId(localStorage.getItem("convoID"));
                          // };

                          // } else if (currentStep === 1) {
                          //   navigate("/agent");
                          // } else if (location.pathname === "/agent" && steps.some(step => step.selector?.startsWith('.stepsInAgent-'))) {
                          //   setButton(() => activeButton[currentStep]);
                          //   console.log(button, "actbutton");
                          // }
                        }}
                      >
                        Next
                      </button>
                    );
                  }}
                >
                  <style>{additionalStyles}</style>
                  <App />
                </ReactTourProvider>
              </TourProvider>
            </ModalProvider>
          </PersistGate>
        </Provider>
        <ToastContainer />
      </UserProvider>
    </BrowserRouter>
  // {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { combineReducers, configureStore } from "@reduxjs/toolkit"
import storage from 'redux-persist/lib/storage';

import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import loginReducer from "../features/auth/loginSlice";
import walletBalanceReducer from "../features/wallet/walletBalanceSlice"
import walletFundingReducer from "../features/wallet/walletFundingHistorySlice";
import whatsAppReducer from "../features/deploy/whatsAppSlice";
import whatsAppConnectReducer from "../features/deploy/whatsAppConnectSlice";
import walletGenerateReducer from "../features/wallet/walletTopUpSlice";
import getTeamsReducer from "../features/teams/getTeamsSlice.js";
import generateTwoFaReducer from "../features/twofa/generateTwoFaSlice.js";
import setupTwoFaReducer from "../features/twofa/setupTwoFaSlice.js";
import settingsTwoFaReducer from "../features/twofa/settingsTwoFaSlice.js";
import toggleTwoFaReducer from "../features/twofa/toggleTwoFaSlice.js";
import apiKeyReducer from "../features/apikey/apiKeySlice.js";
import apiPublicKeyReducer from "../features/apikey/apiPublicKeySlice.js";
import apiSecretKeyReducer from "../features/apikey/apiSecretKeySlice.js";
import getRoleReducer from "../features/role/getRoleSlice.js";
import inviteTeamMemberReducer from "../features/teams/inviteTeamMemberSlice.js";
import inviteCheckReducer from "../features/teams/inviteCheckSlice.js";
import joinTeamReducer from "../features/teams/joinTeamSlice.js";
import acceptTeamInviteReducer from "../features/teams/acceptTeamInviteSlice.js";
import createApplicationReducer from "../features/application/createApplicationSlice.js";
import deleteTeamMemberReducer from "../features/teams/deleteTeamMemberSlice.js";
import getApplicationByIdReducer from "../features/application/getApplicationByIdSlice.js";
import getServicesReducer from "../features/services/getServicesSlice.js";
import postApplicationReducer from "../features/application/postApplicationSlice.js";
import walletFlutterwaveNotificationReducer from "../features/wallet/walletFlutterwaveNotificationSlice.js";
import getBusinessReducer from "../features/business/getBusinessSlice.js"
import getProfileReducer from "../features/profile/getProfileSlice.js";
import updateProfileReducer from "../features/profile/updateProfileSlice.js";
import updatePasswordReducer from "../features/profile/updatePasswordSlice.js";
import requestToolReducer from "../features/profile/requestToolSlice.js";
import getNotficationReducer from "../features/notification/getNotficationSlice.js";
import updateNotficationReducer from "../features/notification/updateNotficationSlice.js";
import readNotificationReducer from "../features/notification/readNotificationSlice.js";
import updateBusinessReducer from "../features/business/updateBusinessSlice.js";
import businessAskAgentChatReducer from "../features/chat/businessAskAgentChatSlice.js";
import businessAskAgentChatReducerH from "../features/chat/businessAskAgentChatSliceH.js";
import getBusinessAskAgentChatReducer from "../features/chat/getBusinessAskAgentChatSlice.js";
import getBusinessAskAgentChatReducerH from "../features/chat/getBusinessAskAgentChatSliceH.js";
import rateBusinessAskAgentChatReducer from "../features/chat/rateBusinessAskAgentChat.js";
import switchBusinessReducer from "../features/business/switchBusinessSlice.js";
import walletBulkReducer from "../features/wallet/walletBulkSlice.js";
import agentResetTypeReducer from "../features/agentResetType/agentResetTypeSlice.js";
import whatsAppDisconnectReducer from "../features/deploy/whatsAppDisconnectSlice.js";
import getPluginsReducer from "../features/plugins/getPluginsSlice.js";
import updatePluginsReducer from "../features/plugins/updatePluginsSlice.js";
import getPluginChannelReducer from "../features/plugins/getPluginChannelSlice.js";
import getPluginByIdReducer from "../features/plugins/getPluginByIdSlice.js";
import getAllPluginsReducer from "../features/plugins/getAllPluginsSlice.js";
import getAllPluginsByIdReducer from "../features/plugins/getAllPluginsByIdSlice.js";
import postPluginByIdReducer from "../features/plugins/postPluginByIdSlice.js";
import featuresReducer from "../features/plugins/getPluginFeaturesSlice.js";
import getCountrySliceReducer from "../features/countries/getCountriesSlice.js";
import chatSliceReducer from "../features/testAgent/chatSlice.js";
import customerReducer from "../features/customer/customerSlice.js";
import updateAgentModalVisibilityReducer from "../features/modals/agentModalsSlice";
import getCurrentPlanReducer from "../features/profile/getCurrentPlanSlice";
import getGiftBalanceReducer from "../features/profile/getGiftBalanceSlice";
import getCreditBalanceReducer from "../features/profile/getCreditBalanceSlice";



    const persistConfig = {
        key: 'root',
        storage,
    };

    //All reducers should be put here so as to access it across the app
    const rootReducer = combineReducers({
        userLogin: loginReducer,
        walletHistory: walletFundingReducer,
        walletBalance: walletBalanceReducer,
        whatsAppAll: whatsAppReducer,
        whatsAppConnect: whatsAppConnectReducer,
        whatsAppDisconnect: whatsAppDisconnectReducer,
        walletGenerate: walletGenerateReducer,
        teamMembers: getTeamsReducer,
        generateTwoFa: generateTwoFaReducer,
        setupTwoFa: setupTwoFaReducer,
        settingsTwoFa: settingsTwoFaReducer,
        toggleTwoFa: toggleTwoFaReducer,
        apiKey: apiKeyReducer,
        publicApiKey: apiPublicKeyReducer,
        secretApiKey: apiSecretKeyReducer,
        allRoles: getRoleReducer,
        inviteMember: inviteTeamMemberReducer,
        inviteCheckMember: inviteCheckReducer,
        joinTeam: joinTeamReducer,
        acceptTeamInvite: acceptTeamInviteReducer,
        createApplication: createApplicationReducer,
        deleteMember: deleteTeamMemberReducer,
        getApplicationByUserId: getApplicationByIdReducer,
        getNotification: getNotficationReducer,
        readNotification: readNotificationReducer,
        getServices: getServicesReducer,
        getBusiness: getBusinessReducer,
        switchBusiness: switchBusinessReducer,
        postApplication: postApplicationReducer,
        flutterwaveNotification: walletFlutterwaveNotificationReducer,
        getProfile: getProfileReducer,
        updateProfile: updateProfileReducer,
        updatePassword: updatePasswordReducer, 
        requestTool: requestToolReducer,
        updateBusiness: updateBusinessReducer,
        updateNotfication: updateNotficationReducer,
        askAgentChat: businessAskAgentChatReducer,
        askAgentChatH: businessAskAgentChatReducerH,
        rateAgentChat: rateBusinessAskAgentChatReducer,
        getAgentChat: getBusinessAskAgentChatReducer,
        getAgentChatH: getBusinessAskAgentChatReducerH,
        bulkTopUp: walletBulkReducer,
        agentReset: agentResetTypeReducer,
        getPluginsData: getPluginsReducer,
        updatePlugins: updatePluginsReducer,
        getPluginChannel: getPluginChannelReducer,
        getPluginByApplicationPluginId: getPluginByIdReducer,
        getAllPlugins: getAllPluginsReducer,
        getAllPluginsById: getAllPluginsByIdReducer,
        postPluginById: postPluginByIdReducer,
        getFeatures: featuresReducer,
        getAllCountries:getCountrySliceReducer,
        chat: chatSliceReducer,
        getCustomers:customerReducer,
        agentModals: updateAgentModalVisibilityReducer,
        getCurrentPlan: getCurrentPlanReducer,
        getCreditBalance: getCreditBalanceReducer,
        getGiftBalance: getGiftBalanceReducer,
    });
  
    const persistedReducer = persistReducer(persistConfig, rootReducer)

    export const store = configureStore({
        reducer: persistedReducer,
        middleware: [thunk]
    });

export const persistor = persistStore(store)


export default function SaveChanges({ handleClose }) {
  return (
    <div className=" w-[496px] h-[281px] bg-white mt-28 ml-6 rounded-lg shadow-makeReqModal font-figtree py-[30px] px-[50px]">
      <p className=" text-center text-[#101828] text-2xl font-medium">
        You have Unsaved Changes
      </p>
      <p className=" my-[50px] text-sm font-medium text-center text-[#828282]">
        Are you sure you want to leave this page? There are some unsaved
        changes. Keep editing to save changes or close without saving.
      </p>
      <div className=" flex justify-center gap-6">
        <button
          onClick={handleClose}
          className=" w-[50%] py-2  rounded-lg border boder-[#D0D5DD] text-[#7F7F81] font-semibold"
        >
          Close without saving
        </button>
        <button
          onClick={handleClose}
          className=" w-[50%] py-2  rounded-lg bg-[#121212] text-white font-semibold"
        >
          Keep Editing
        </button>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import Gradient from "../Assets/svg/radialgrad.svg";
import CircleIso from "../Assets/svg/circleisotoxal.svg";
import Shape from "../Assets/svg/Shape65.svg";
import {
  AgentProfilePicture,
  ChooseFileBtn,
  ChooseIcon,
  WanoProfilePicture,
} from "../Assets/svg";

import DefaultImage1 from "../Assets/DefaultImage.png";
import ModalPop from "./modalPop";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import PersonalityModal from "@/../Agent/SettingsLayout/PersoanlityModal";
import DefaultImage from "../Assets/svg/wanoprofilepic.svg";
import { useDispatch, useSelector } from "react-redux";
import { api } from "@/../services/api";
import { getBusiness } from "@/../features/business/getBusinessSlice";
import { ErrorMessage } from "@/../Toast-Messages/Toastmessages";
import { SuccessMessage } from "@/../Toast-Messages/Toastmessages";
import { getApplicationById } from "@/../features/application/getApplicationByIdSlice";
import { setAgent } from "@/../features/application/getApplicationByIdSlice";

const CustomizeAssistant = () => {
  const [data, setData] = useState({
    name: "",
    description: "",
    icon_url: null,
    verbose: "",
  });
  const [selectedPersonality, setSelectedPersonality] = useState("");
  const [showPersonalityModal, setShowPersonalityModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImage2, setSelectedImage2] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isFormModified, setIsFormModified] = useState(false);
  const [files, setFile] = useState([]);
  const [searchParams] = useSearchParams();
  const oldUser = searchParams.get("old") == "true";

  const [fieldEdited, setFieldEdited] = useState({
    field1: false,
    field2: false,
  });
  const [showSetupScreen, setShowSetupScreen] = useState(true);
  const [progress, setProgress] = useState(0);
  const [setupText, setSetupText] = useState("Setting up assistant");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue, 
    trigger,
    watch,
   
    formState: { errors, isDirty, isValid },
  } = useForm();

  useEffect(() => {
    dispatch(getBusiness());
  }, []);

  const { loading } = useSelector((state) => state.updateBusiness);

  const getUserBusiness = useSelector((state) => state.getBusiness);
  // console.log("getUserBusiness", getUserBusiness);
  const business =
    getUserBusiness?.activeBusiness || getUserBusiness?.data?.data[0];

  // const [businessName, setBusinessName] = useState(business?.name);
  const [businessName, setBusinessName] = useState(
    oldUser ? "" : business?.name
  );

  const businessData = {
    name: businessName || business?.name,
  };

  const handleUploadAssistant = async (data) => {
    // console.log(data, "form data");
    setFile(data.file);
    setShowSetupScreen(false);

    // const cBus = JSON.parse(localStorage.getItem("cBus"));
    const businessId = business?.id;
    const formData = new FormData();
    formData.append("businessId", businessId);
    formData.append("name", businessName);
    // formData.append("description", businessName);
    formData.append("sale_agent_name", businessName);
    formData.append("personality_type", selectedPersonality);
    formData.append("type", "customer");
    formData.append("file", selectedImage);

    try {
      const response = await api.post(
        `/dashboard/applications/setup`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("Response:2", response?.data?.data);
      if (response?.data?.data) {
        dispatch(setAgent(response?.data?.data));
        // dispatch(getApplicationById());
        localStorage.setItem(
          "wano_agent",
          JSON.stringify(response?.data?.data)
        );

        localStorage.setItem(
          "cBus",
          JSON.stringify(response?.data?.data?.business)
        );

        setProgress(1);
        if (!oldUser) {
          localStorage.setItem("welcomeGuide", false);
        } else {
          localStorage.setItem("welcomeGuide", true);
        }
        const progressInterval = setInterval(() => {
          setProgress((prevProgress) => {
            if (prevProgress >= 100) {
              clearInterval(progressInterval);
              navigate("/dashboard");
              return 100;
            }
            return prevProgress + 2;
          });
        }, 100);
      }
    } catch (error) {
      console.error("Error uploading assistant details:", error);
      setError(error?.data?.message);
      setTimeout(() => {
        setError("");
      }, 2000);

      setShowSetupScreen(true);
    }
  };

  const handleImageChange = (file) => {
    const imageFile = file[0];
    // console.log(file, "file");

    if (imageFile) {
      setSelectedImage(imageFile);

      setData((prevData) => ({
        ...prevData,
        icon_url: imageFile,
      }));

      setIsFormModified(true);

      const reader = new FileReader();

      reader.onload = (e) => {
        const imageDataUrl = e.target.result;
        setSelectedImage2(imageDataUrl);
      };

      reader.onerror = (error) => {
        console.error("Error reading the file:", error);
      };

      reader.readAsDataURL(imageFile);
    }
  };

  const file = watch("file");

  useEffect(() => {
    handleImageChange(files);
  }, [file, files]);

  // useEffect(() => {
  //   if (progress > 0) {
  //     const progressInterval = setInterval(() => {
  //       setProgress((prevProgress) => {
  //         if (prevProgress >= 100) {
  //           clearInterval(progressInterval);
  //           navigate("/dashboard");
  //           if (!oldUser) {
  //             localStorage.setItem("welcomeGuide", false);
  //           }
  //           return 100;
  //         }
  //         return prevProgress + 2;
  //       });
  //     }, 100);

  //     return () => clearInterval(progressInterval);
  //   }
  // }, [progress, navigate]);

  useEffect(() => {
    const messages = [
      "Setting up assistant",
      "Customizing your assistant",
      "Finalizing setup",
    ];
    let messageIndex = 0;
    const textInterval = setInterval(() => {
      setSetupText(messages[messageIndex]);
      messageIndex = (messageIndex + 1) % messages.length;
    }, 500);

    return () => clearInterval(textInterval);
  }, []);

  const handleSavePersonality = (personality) => {
    setSelectedPersonality(personality);
    setData((prevData) => ({
      ...prevData,
      personality_type: personality,
    }));

    setIsFormModified(true);
    
      // Programmatically set the personality_type in the form
      setValue("personality_type", personality);
      trigger("personality_type"); 

  };

  // const handleImageChangeOriginal = (e) => {
  //   const file = e.target.files[0];
  //   console.log(file, "file");

  //   if (file) {
  //     setSelectedImage(file);

  //     setData((prevData) => ({
  //       ...prevData,
  //       icon_url: file,
  //     }));

  //     setIsFormModified(true);

  //     const reader = new FileReader();

  //     reader.onload = (e) => {
  //       const imageDataUrl = e.target.result;
  //       setSelectedImage2(imageDataUrl);
  //     };

  //     reader.onerror = (error) => {
  //       console.error("Error reading the file:", error);
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleInputChange = (event, setStateFunction, field) => {
    const inputValue = event.target.value;
    setStateFunction(inputValue);
    setFieldEdited((previousfieldEdited) => ({
      ...previousfieldEdited,
      [field]: true,
    }));
    setIsFormModified(true);
    localStorage.setItem("isFormModifiedB", true);
    localStorage.setItem("path", "/settings");
  };

  // const cBus = localStorage.getItem("cBus");

  return (
    <div className="relative">
      <button
        className="absolute  top-16 left-16  border rounded-lg py-2.5 px-4 bg-white border-[#D7D7D7]  flex justify-center items-center font-medium  text-[#868686] text-sm"
        type="submit"
        onClick={() => {
          navigate(-1);
        }}
      >
        Cancel
      </button>

      <img src={Gradient} alt="gradient" className="w-full" />
      {showSetupScreen ? (
        <>
          <div
            className="flex p-2 border border-[#D0D5DD] items-center gap-1 w-[175px] mt-[-100px] rounded-[54px] mx-auto"
            style={{
              background:
                "linear-gradient(135deg, rgba(248, 196, 211, 0.15) 0%, rgba(23, 116, 253, 0.02) 100%)",
            }}
          >
            <img src={CircleIso} alt="isotoxal" />
            <p className="text-[14px] font-semibold text-[#121212]">
              Setup Your Assistant
            </p>
          </div>
          <form onSubmit={handleSubmit(handleUploadAssistant)}>
            <h1 className="text-[32px] font-semibold leading-[38.4px] text-center mt-[100px]">
              Customize Your Support Assistant
            </h1>

            <div className="w-[420px] mx-auto mt-[50px]">
              <h3 className="text-[12px] font-medium leading-[14.4px] text-[#121212]">
                Assistant Image
              </h3>
              <div className=" mt-3 flex gap-5 items-end">
                <div className="relative">
                  <WanoProfilePicture />
                  {selectedImage2 ? (
                    <img
                      src={selectedImage2}
                      alt="Uploaded"
                      className="absolute top-0 h-[40px] w-[40px] object-cover"
                    />
                  ) : (
                    <img
                      src={data.icon_url || DefaultImage1}
                      alt="Default"
                      className="absolute top-0 h-[40px] rounded-[6.4px] shadow-md w-[40px] object-cover"
                    />
                  )}
                </div>
                <div className=" relative">
                  <label htmlFor="choosefile" className={`${errors?.file ? "border border-red-900" : ''} cursor-pointer`}>
                    <ChooseFileBtn />
                  </label>
                  <div>
                    <input
                      type="file"
                      {...register("file", {
                        required: "Image is required",
                        onChange: (e) => handleImageChange(e.target.files),
                      })}
                      id="choosefile"
                      className={"hidden"}
                      accept="image/*"
                    />
                    {errors?.file && <p className="text-red-900 text-xs">{errors?.file.message}</p>}
                  </div>
                </div>
              </div>

              <div className="my-8">
                <label className="label">
                  Give your Assistant a name
                  <input
                    className={`${errors?.name ? "border border-red-900" : ''} outline-none mt-3 h-10 text-xs`}
                    placeholder=""
                    value={businessName}
                    {...register("name", {
                      required: "Assistant name is required",
                      onChange: (e) =>
                          handleInputChange(e, setBusinessName, "field1"),
                    })}
                    // onChange={(e) =>
                    //   handleInputChange(e, setBusinessName, "field1")
                    // }
                    type="text"
                  />
                  {errors?.name && <p className="text-red-900 text-xs">{errors?.name.message}</p>}
                </label>
              </div>

              <p className="mb-2 text-xs font-normal">
                Assistant's Personality
              </p>
              <div className=" relative">
                <input
                  type="text"
                  value={
                    data?.personality_type?.split("-")?.join(" ") ||
                    selectedPersonality?.split("-")?.join(" ") ||
                    ""
                  }
                  {...register("personality_type", {
                    required: "Personality is required",
                  })}
                  readOnly
                  className={`${errors?.personality_type ? "border border-red-900" : ''} capitalize w-[420px] outline-none h-10 pr-[75px] text-xs`}
                />
                {errors?.personality_type && <p className="text-red-900 text-xs">{errors?.personality_type.message}</p>}
                <div
                  onClick={() => setShowPersonalityModal(true)}
                  className=" absolute top-[4px] right-2 cursor-pointer"
                >
                  <ChooseIcon />
                </div>
                <p className="text-[12px] leading-[18px] text-[#7F7F81] mt-4">
                  You can change this information anytime in your “Assistant
                  Settings”
                </p>
              </div>
              <div className="flex justify-center items-center mt-[32px]">
                <button
                  className="bg-[#121212] text-white text-[14px] leading-[24px] font-bold rounded-lg px-5 py-3 flex items-center"
                  // onClick={handleUploadAssistant}
                  type="submit"
                >
                  <span>Next</span>
                  <BiChevronRight className=" text-xl" />
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          <div className="bg-[#E5E5E5] w-[64px] h-[64px] rounded-[11.14px] flex items-center justify-center mt-8">
            <img src={Shape} alt="shape" />
          </div>
          <h1 className="text-[32px] font-semibold leading-[38.4px] text-center mt-4">
            Setting Up Business Name
          </h1>
          <div className="w-[287px] mx-auto mt-[100px] mb-[50px]">
            <div className="relative w-full h-1 bg-gray-200">
              <div
                className="absolute top-0 left-0 h-1 bg-blue-600"
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>
          <p className="text-gray-600 mt-2">{setupText}</p>
        </div>
      )}

      {error && <ErrorMessage error={error} />}
      {success && <SuccessMessage success={success} />}

      <ModalPop isOpen={showPersonalityModal}>
        <PersonalityModal
          handleClose={() => setShowPersonalityModal(false)}
          handleSavePersonality={handleSavePersonality}
          selectedPersonality={selectedPersonality}
        />
      </ModalPop>
    </div>
  );
};

export default CustomizeAssistant;

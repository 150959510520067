import React, { useEffect, useState } from "react";
import { Radio } from "@mui/material";
import { CgSpinner } from "react-icons/cg";
import { useSelector, useDispatch } from "react-redux";

import Flutterwave from "../../../Assets/img/flutterwave_icon.jpeg.svg";
import Stripe from "../../../Assets/img/stripe_icon.jpeg.svg";
import { generateTopUp } from "../../../features/wallet/walletTopUpSlice";
import { api } from "../../../services/api";

const Pay = ({ handleClose, setOpenRedeemGift, insufficient = false }) => {
  const [checked, setChecked] = useState("dollar");
  const [rate, setRate] = useState("");

  const { loading } = useSelector((state) => state.walletGenerate);

  const dispatch = useDispatch();

  // console.log(Math.round(rate), "fire");

  const getRate = async () => {
    await api
      .get("/currency/rates")
      .then((res) => {
        setRate(res?.data?.data[3]?.rate);
        console.log(res, "respo");
      })
      .catch((err) => {
        console.log(err, "hope");
      });
  };

  const openRedeemGiftPop = () => {
    setOpenRedeemGift(true);
    handleClose();
  };

  useEffect(() => {
    getRate();
  }, []);

  const submitForm = (values) => {
    const payAmount = sessionStorage.getItem("amount");
    const data = {
      amount: `${
        checked === "naira" ? payAmount * rate * 100 : payAmount * 100
      }`, //values?.amount * 100,
      currency: `${checked === "dollar" ? "USD" : "NGN"}`,
    };
    dispatch(generateTopUp(data)).then((res) => {
      window.location.href = res.payload.link;
    });
  };
  const payAmount = sessionStorage.getItem("amount");

  return (
    <div className="bg-WHITE-_100 w-[592px] h-fit mt-[40px] rounded-lg">
      {insufficient && (
        <div className="bg-[#AF202D] text-white rounded-t-lg py-[14px]">
          <p className="text-[15px] leading-[19.2px] font-medium text-center">
            Insufficient Balance, Top Up your credits to pay for the
            subscription
          </p>
        </div>
      )}
      <div className="p-[40px] ">
        <p className="text-lg font-medium text-[#121212]">
          For Payment: ${payAmount}
        </p>
        <div className="mt-[31px] gap-[31px] flex flex-col">
          <div className="w-[512px] h-[87px] bg-[#f9f9f966] flex items-center justify-between rounded-lg border border-[#E5E5E5] py-[24px] px-[20px]">
            <div className="flex items-center gap-[10px]">
              <img
                src={Flutterwave}
                alt="Flutterwave icon"
                className="w-[29px] h-[29px]"
              />
              <div className="flex flex-col gap-[2px]">
                <p className="text-[#121212] text-base font-medium">
                  Pay With Flutterwave
                </p>
                {/* <p className="text-[#7F7F81] text-xs">{`To Pay ₦ ${Math.round(
                payAmount * rate
              )} (Current Rate: $1 = ₦${Math.round(rate)})`}</p> */}
              </div>
            </div>
            <Radio
              sx={{
                height: 19,
                width: 19,
                background: "white",
              }}
              // checkedIcon={<CheckedIcon />}
              checked={checked === "naira"}
              onClick={() => {
                setChecked("naira");
              }}
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
          </div>
          <div className="w-[512px] h-[87px] bg-[#f9f9f966] flex items-center justify-between rounded-lg border border-[#E5E5E5] py-[24px] px-[20px]">
            <div className="flex items-center gap-[10px]">
              <img
                src={Stripe}
                alt="stripe icon"
                className="w-[29px] h-[29px]"
              />
              <div className="flex flex-col gap-[2px]">
                <p className="text-[#121212] text-base font-medium">
                  Pay With Stripe
                </p>
                {/* <p className="text-[#7F7F81] text-xs">Rate: $1 = $1</p> */}
              </div>
            </div>
            {/* <Radio
            sx={{
              height: 19,
              width: 19,
              background: "white",
            }}
            checked={checked === "dollar"}
            onClick={() => {
              setChecked("dollar");
            }}
            name="radio-buttons"
            inputProps={{ "aria-label": "A" }}
          /> */}
            <p
              className="px-[8px] py-[9px] bg-[#EDF4FF] text-[#1774FD] text-[10px] font-medium leading-3 text-center rounded-[6px]"
              style={{ boxShadow: "0px 0px 2.4px 0px #86B7FF42" }}
            >
              Coming Soon
            </p>
          </div>
          <p
            className="text-[12px] leading-[14.4px] font-[medium] text-[#1774FD] cursor-pointer"
            // onClick={() => openRedeemGiftPop()}
            onClick={() => {setOpenRedeemGift(true); handleClose();}}
          >
            Redeem Gift Credits
          </p>
          <div className="w-[420px] mx-auto flex flex-col gap-[12px]">
            <button
              className="w-[420px] h-[40px] py-3 px-5 rounded-lg flex justify-center items-center font-medium bg-BLACK-_500 text-WHITE-_100 text-sm"
              type="submit"
              onClick={() => submitForm()}
            >
              <p className="text-WHITE-_100 text-sm font-bold">
                {loading ? (
                  <CgSpinner className=" animate-spin text-lg " />
                ) : (
                  "Proceed to Pay"
                )}
              </p>
            </button>
            <button
              className="w-[420px]   flex justify-center items-center font-medium  text-[#868686] text-sm"
              type="submit"
              onClick={() => handleClose()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pay;

import {
  ErrorMessage,
  SuccessMessage,
} from "@/../Toast-Messages/Toastmessages";
import { getAllPlugins } from "@/../features/plugins/getAllPluginsSlice";
import { api } from "@/../services/api";
import React, { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { HiXMark } from "react-icons/hi2";
import { useDispatch } from "react-redux";

const initial = {
  type: "",
  slack_token: "",
  slack_channel_id: "",
  calendly_event_name: "",
  calendly_event_url: "",
  sender_name: "",
  employee_email: "",
  cc_email: "",
  hubspot_base_url: "",
  hubspot_api_key: "",
};

const SetupModal = ({ data, labelA, labelB, handleClose }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [pluginsNotificationSuccess, setPluginsNotificationSuccess] =
    useState("");
  const [pluginsNotificationError, setPluginsNotificationError] = useState("");

  const appId = JSON.parse(localStorage.getItem("wano_agent"));
  const applicationId = appId?.id;
  const id = data?.applicationPlugins[0]?.id;
  useEffect(() => {
    // console.log("data here", data);
    // automatically connect landguage transaltor
    if(data.name === 'Language Translator'){
      ConnectPlugin()
    }
  }, [data]);
  // console.log(id, "tags id")

  // console.log(data, "tags")

  // // console.log(formData, "formData");

  useEffect(() => {
    switch (data?.name.toLowerCase()) {
      case "slack":
        setFormData({ type: data?.app_tag_slug, slack_token: "" });
        break;
      case "calendly":
        setFormData({
          type: data?.app_tag_slug,
          calendly_event_name: "",
          calendly_event_url: "",
        });
        break;
      case "hubspot":
        setFormData({
          type: data?.app_tag_slug,
          hubspot_base_url: "",
          hubspot_api_key: "",
        });
        break;
      case "lead generation":
        setFormData({
          type: data?.app_tag_slug,
          hubspot_base_url: "",
          hubspot_api_key: "",
        });
        break;
      case "gmail":
        setFormData({
          type: data?.app_tag_slug,
          sender_name: "",
          employee_email: "",
          cc_email: "",
        });
        break;
      default:
        setFormData({});
    }
  }, [data]);

  const inputLabels = {
    slack_token: "Token",
    slack_channel_id: "Channel ID",
    calendly_event_name: "Event Name",
    calendly_event_url: "Event Link",
    hubspot_base_url: "Base URL",
    hubspot_api_key: "Api Key",
    sender_name: "Sender Name",
    employee_email: "Email Address",
    cc_email: "Email to cc",
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateFormData = () => {
    return Object.entries(formData)
      .filter(([key]) => key !== "type") //&& formData[key].required
      .every(([key, value]) => value.trim() !== "");
  };

  const ConnectPlugin = async () => {
    if (!validateFormData()) {
      setPluginsNotificationError("Please fill all required fields correctly.");
      return;
    }

    setLoading(true);
    try {
      const verificationResponse = await api.put(
        `dashboard/applications/${applicationId}/plugins/${id}/configuration-verification`,
        { ...initial, ...formData }
      );
      console.log(verificationResponse, "setup res");
      if (verificationResponse?.data?.status) {
        const res = await api.put(
          `dashboard/applications/${applicationId}/plugins/${id}/configuration`,
          { ...initial, ...formData }
        );
        setPluginsNotificationSuccess(res.data.message);
        dispatch(getAllPlugins({ applicationId }));
      } else {
        setPluginsNotificationError("Verification failed");
      }
    } catch (error) {
        console.log('error ',error)
      setPluginsNotificationError(error?.data?.message);
    } finally {
      setLoading(false);
      setTimeout(() => {
        handleClose();
      }, 1500);
    }

    // setLoading(true)
    // await api.put(`dashboard/applications/${applicationId}/plugins/${id}/configuration`, {...initial, ...formData})
    // .then((res) => {
    //     console.log(res, "tag setupModal");
    //     setLoading(false)
    //     localStorage.setItem("pluginState", "All Plugins")
    //     setPluginsNotificationSuccess(true)
    //     dispatch(getAllPlugins({ applicationId }));
    //     // done();
    //     setTimeout(() => {
    //         handleClose()
    //     }, 2000);
    // })
    // .catch((err) => {
    //     setLoading(false)
    //     setPluginsNotificationError(true)
    //     // console.log(err, "err")
    // })
  };

  const removePluginsNotificationSuccess = () => {
    setTimeout(() => {
      setPluginsNotificationSuccess("");
    }, 3000);
  };

  const removePluginsNotificationError = () => {
    setTimeout(() => {
      setPluginsNotificationError("");
    }, 3000);
  };

  // const error = "Failed to install"
  // const message = "App Installed Successfully"

  useEffect(() => {
    removePluginsNotificationSuccess();
  }, [pluginsNotificationSuccess]);

  useEffect(() => {
    removePluginsNotificationError();
  }, [pluginsNotificationError]);

  return (
    <div className="bg-[#fff] w-[501px] h-fit flex flex-col rounded-lg mt-[100px] gap-[20px]">
      <div className="pt-[30px] px-[50px] flex items-center justify-between rounded-lg">
        {/* w-[48px] h-[48px] border-b-[#E5E5E5] border */}
        <h2 className="text-[#101828] text-lg font-medium">Setup</h2>
        <button
          type="button"
          tabIndex={0}
          onClick={() => handleClose()}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleClose();
            }
          }}
          className="border p-2 -mt-1 rounded-lg border-[#F7F7F7] cursor-pointer"
        >
          <HiXMark className="text-[20px] text-[#828282]" />
        </button>
      </div>
      <form className="flex flex-col gap-5 border-t-[1px] border-[#E5E5E5] py-[30px] px-[45px]">
        {/*  style={{padding: "24px 0px 10px 0px"}} */}
        <img src={data?.image_url} alt="bank" className="w-[48px] h-[48px]" />
        <div>
          {data?.name?.includes("Language Translator") ? (
            <>
              <p className="text-[#101828] text-lg">Install {data?.name}</p>
              <p className="text-[#7F7F81] text-xs">
                Installing this app will allow your assistant converse in
                multiple languages
              </p>
            </>
          ) : data?.name === "Gmail" ? (
            <div>
              <p className="text-[#101828] text-lg">Setup Email Messaging</p>
              <p className="text-[#7F7F81] text-xs">
                Provide the information below to allow us generate a gmail
                account for your assistant.
              </p>
            </div>
          ) : (
            <>
              <p className="text-[#101828] text-lg">
                Connect to your {data?.name} Account
              </p>
              <p className="text-[#7F7F81] text-xs">
                Provide the information below to allow us connect to your{" "}
                {data?.name} Account
              </p>
            </>
          )}
        </div>
        <div className="flex flex-col gap-5">
          {Object.entries(formData).map(([key, value]) => {
            if (key === "type") return null;
            // {key.replace(/_/g, ' ')}
            const label = inputLabels[key] || key;
            return (
              <div key={key} className="w-full flex flex-col gap-2">
                <label htmlFor={key} className="text-xs text-[#101828]">
                  {label}
                </label>
                <input
                  name={key}
                  type="text"
                  value={value}
                  className="w-full focus:outline-none"
                  onChange={handleChange}
                />
              </div>
            );
          })}
        </div>
        <div className="w-full flex gap-[12px] justify-end mt-[20px]">
          <button
            className="w-fit h-[37px] py-3 px-5 rounded-lg border-[#E5E5E5] border flex justify-center items-center font-medium  text-[#868686] text-sm"
            type="button"
            onClick={() => handleClose()}
          >
            Close
          </button>
          {data?.name === "Gmail" ? (
            <button
              className="w-[95px] h-[37px] py-3 px-5 rounded-lg flex justify-center items-center font-medium bg-BLACK-_500 text-WHITE-_100 text-sm"
              type="button"
              onClick={() => ConnectPlugin()}
            >
              <p className="text-WHITE-_100 text-sm font-bold">
                {loading ? (
                  <CgSpinner className=" animate-spin text-lg " />
                ) : (
                  "Connect"
                )}
              </p>
            </button>
          ) : (
            <button
              className="w-[95px] h-[37px] py-3 px-5 rounded-lg flex justify-center items-center font-medium bg-BLACK-_500 text-WHITE-_100 text-sm"
              type="button"
              onClick={() => ConnectPlugin()}
            >
              <p className="text-WHITE-_100 text-sm font-bold">
                {loading ? (
                  <CgSpinner className=" animate-spin text-lg " />
                ) : data.name.includes("Language Translator") ? (
                  "Install"
                ) : (
                  "Connect"
                )}
              </p>
            </button>
          )}
        </div>
      </form>

      {pluginsNotificationSuccess && (
        <SuccessMessage
          Formodal={true}
          success={pluginsNotificationSuccess}
          className="mt-28 mr-12"
        />
      )}
      {pluginsNotificationError && (
        <ErrorMessage
          Formodal={true}
          error={pluginsNotificationError}
          className="mt-28 mr-12"
        />
      )}
    </div>
  );
};

export default SetupModal;

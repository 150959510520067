import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Verified from "../../../../Assets/img/check-verified.png"
// import whatsAppIcon from "../../../../Assets/svg/WhatsApp_svg.svg"
import Button from '../../../Button'
import WhatsApp from '../../../../Assets/svg/whatsApp.svg'
import BigPlus from "../../../../Assets/svg/BigPlus.svg"
import Stars from "../../../../Assets/svg/Stars.svg"
import { UserContext } from '../../../../context/UserContext'
import { AiOutlineClose } from 'react-icons/ai'


const Success = ({ handleClose, whatsapp, fromPeer }) => {

    const navigate = useNavigate()
    const location = useLocation()

    const {userType} =  useContext(UserContext);
    // py-[30px] px-[50px] 
  return (
    <div className="bg-WHITE-_100 w-[501px] h-fit mt-[60px] flex flex-col justify-center items-center rounded-lg"> {/* w-[558px] h-[450px] */}
        <div className='w-full flex items-center justify-end px-[28px] py-[14px] border-b border-neutral-150'>
            {/* <p className='text-lg font-medium text-[#101828]'>Setup App</p> */}
            <div className="w-[36px] h-[36px] border rounded-lg mr-3  flex cursor-pointer justify-center items-center p-2" onClick={handleClose}><AiOutlineClose className='text-neutral-500'/></div>
        </div>
        <div className="w-[401px] flex flex-col gap-[30px] justify-center items-center mt-10 mb-[30px]"> {/* w-[396px] h-[340px] h-[348px]*/}
            <div className="flex flex-col gap-[20px] justify-center items-center">
              <div className='flex justify-between w-[106.48px] h-[40.32px]'>
                  <img src={WhatsApp} alt='whatsapp_icon' className='w-[40.32px] h-[40.32px]'/>
                  <img src={BigPlus} alt="plus icon" className=''/>
                  <img src={Stars} alt="Star icon" className=''/>
              </div>
              <p className="text-2xl font-medium text-BLACK-_600">Integration Successful</p>
            </div>

            <div className="mt-5">
                <img src={Verified} alt="Success-img" className='w-[70px] h-[70px]' />
            </div>

            <div className=" flex flex-col justify-center items-center">
                <p className="text-sm font-normal text-center text-BLACK-_300">
                    {
                        fromPeer ? 
                        "We’ll connect your assistant with WhatsApp after 24hours and it’ll be ready for use."
                        : "Your number has been integrated successfully. Your App is now active on WhatsApp."
                    }
                    
                    {/* {"Sales" === "Customer Service" ?
                    "We’ll connect your agent with Whatsapp after 24hours and it’ll ready for use."
                        :  
                     "Congratulations, You’re almost done! A Wano representative will reach out to help set up your WhatsApp API Business account with Facebook within 24 hours."
                     } */}
                </p>
            </div>
           
            <div className="w-full h-[40px] flex flex-col gap-3">
                <Button
                    onClick={handleClose} 
                    text="Done" 
                    // text={"Sales" === "Customer Service" ? "Done" : "Okay, Got it"} 
                    className="bg-BLACK-_100 text-sm h-[40px] cursor-pointer rounded-lg font-semibold text-WHITE-_100"
                    type="button" 
                />
            </div>
        </div>
     


    </div>
  )
}

export default Success

{/* <div className="w-[224px] h-[61px] flex flex-col gap-5 items-center">
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="66" height="65" viewBox="0 0 66 65" fill="none">
        <rect x="1.3125" y="0.8125" width="63.375" height="63.375" rx="12.1875" fill="white"/>
        <rect x="1.3125" y="0.8125" width="63.375" height="63.375" rx="12.1875" stroke="#E5E5E5" stroke-width="1.625"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M42.566 22.9235C40.0282 20.3946 36.653 19.0013 33.0567 19C25.6463 19 19.6154 25.0014 19.6128 32.3781C19.6115 34.7363 20.231 37.038 21.4074 39.0665L19.5 46L26.6268 44.1396C28.5902 45.2058 30.8012 45.7671 33.0509 45.7678H33.0567C40.4658 45.7678 46.4975 39.7658 46.5 32.389C46.5013 28.8139 45.1044 25.453 42.566 22.9241V22.9235ZM33.0567 43.5084H33.0522C31.0475 43.5078 29.0808 42.9714 27.3649 41.9585L26.9568 41.7173L22.7276 42.8213L23.8563 38.7175L23.5907 38.2967C22.4723 36.5262 21.8812 34.4797 21.8825 32.3788C21.885 26.2478 26.8975 21.2594 33.0613 21.2594C36.0458 21.2607 38.8511 22.4186 40.9609 24.5209C43.0707 26.6225 44.2316 29.4169 44.2304 32.3877C44.2277 38.5194 39.2153 43.5078 33.0567 43.5078V43.5084ZM39.1856 35.1803C38.8498 35.0128 37.1984 34.2045 36.8902 34.0929C36.5821 33.9812 36.3584 33.9255 36.1347 34.2603C35.9111 34.5952 35.2671 35.3483 35.0711 35.5709C34.8752 35.7942 34.6792 35.8218 34.3433 35.6543C34.0076 35.4869 32.9252 35.134 31.6418 33.9953C30.6434 33.1088 29.969 32.0144 29.7731 31.6795C29.5772 31.3446 29.7525 31.1637 29.9201 30.9976C30.0709 30.8475 30.2559 30.6069 30.4241 30.4119C30.5924 30.2168 30.6479 30.077 30.76 29.8544C30.8722 29.6311 30.8161 29.4362 30.7323 29.2687C30.6485 29.1013 29.9768 27.4557 29.6964 26.7867C29.4237 26.1349 29.1466 26.2234 28.9409 26.2125C28.745 26.2029 28.5213 26.201 28.297 26.201C28.0726 26.201 27.7091 26.2844 27.401 26.6193C27.0929 26.9541 26.2252 27.763 26.2252 29.4079C26.2252 31.0527 27.4287 32.6431 27.5969 32.8663C27.7652 33.0896 29.9658 36.4659 33.3352 37.9144C34.1365 38.2589 34.7624 38.4648 35.2503 38.6188C36.0548 38.8734 36.7871 38.8375 37.3659 38.7516C38.0112 38.6553 39.3533 37.9426 39.633 37.1619C39.9128 36.3811 39.9128 35.7114 39.829 35.5722C39.7452 35.433 39.5209 35.3489 39.185 35.1815L39.1856 35.1803Z" fill="#292D32"/>
    </svg> *
    <img src={WhatsApp} alt='whatsapp_icon' className='w-[65px] h-[65px]'/>

    {/* <div className="w-[224px] h-[61px] flex flex-col gap-4 items-center">
    <img src={whatsAppIcon} alt="whatsapp-img" className='w-[18px] h-[18px]' />
    *
    <p className="text-[22px] font-medium text-BLACK-_600">Integration Successful</p>
</div> */}
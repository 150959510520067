import React, { useContext, useState, Fragment, useEffect } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { useLocation, useNavigate } from "react-router";
import { Progress } from "antd";
import { BsPlus } from "react-icons/bs";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineCheck } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import { Link } from "react-router-dom";
import ask1 from "Assets/img/ask1.svg";
import ask2 from "Assets/img/ask2.svg";
import ask3 from "Assets/img/ask3.svg";
import AiLogo from "Assets/svg/Group 12.svg";
import BackdropWithSpinner from "component/BackdropWithSpinner";
import ChangesBanner from "component/ChangesBanner";
import TestAgent from "@/../Agent/TestAgent/TestAgent";
import { getApplicationDraft } from "features/application/getApplicationByIdSlice";

import { Menu, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../context/UserContext";
import Spinner from "component/Spinner";
import axios from "axios";
// import Welcome from '../component/modals/welcome/mustCreate';
import Welcome from "../component/modals/welcome/index";

import Logo from "../Assets/svg/logo-b.svg";
import Arrow from "../Assets/svg/forward-arrow.svg";
import Line from "../Assets/img/line.png";
import LineB from "../Assets/img/line-b.png";
import RangeA from "../Assets/img/range-a.png";
import RangeB from "../Assets/img/range-b.png";
import Purse from "../Assets/svg/purse.svg";
import User from "../Assets/svg/user.svg";
import Chat from "../Assets/svg/chat.svg";
import Task from "../Assets/svg/task.svg";
import Puzzle from "../Assets/svg/puzzlehead2.svg";
import Puzzle2 from "../Assets/svg/mentalpuzzle.svg";
import Launch from "../Assets/svg/launch11.svg";
import Launch2 from "../Assets/svg/launch1.svg";
import TestTime from "../Assets/svg/testclock1.svg";
import TestTime2 from "../Assets/svg/testClock.svg";
import DashboardSvg from "../Assets/svg/dahboard.svg";
import Star from "Assets/svg/dashboardStar.svg";
import Happening from "../Assets/img/happening.png";
import Today from "../Assets/img/today.png";
import Ideas from "../Assets/img/ideas.png";

import ModalPop from "../component/modalPop";
import Bulk from "../component/modals/wallet/Bulk";
import Pay from "../component/modals/wallet/Pay";
import WalletTopUp from "../component/modals/wallet";
import AuthDashboard from "./AuthDashboard";
import CustormerGetStarted from "./CustomerGetStarted";
import CustomerDashboard from "./CustomerDashboard";
import { fetchWalletBalance } from "../features/wallet/walletBalanceSlice";
import { Skeleton } from "@mui/material";
import TokenService from "../services/token";
// import { TopGuide } from '../component/modals/agentGuide/Guide';
import { BottomGuide } from "../component/modals/agentGuide/Guide";
import AgentSetUp from "../component/modals/agentGuide/AgentSetUp";
import { SuccessMessage } from "../Toast-Messages/Toastmessages";
import AgentServiceSelect from "../component/modals/agentGuide/AgentService2";
import {
  getCurrentEmployee,
  setIsDone,
  updateAgentModals,
} from "@/../features/modals/agentModalsSlice";
// import { updateBusinessModals } from "@/../features/profile/getProfileSlice";
import { getProfile } from "@/../features/profile/getProfileSlice";
import DashboardCard from "./DashboardCard";
import Guide from "@/../component/modals/Guide";
import { getGiftBalance } from "@/../features/profile/getGiftBalanceSlice";

export default function Dashboard() {
  const dispatch = useDispatch();
  // const [data, setData] = useState(false);
  const businessID = JSON.parse(localStorage.getItem("cBus"))?.id;
  const [switchEmployeeQueryParam, setSwitchEmployeeQueryParam] =
    useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setSwitchEmployeeQueryParam(searchParams.get("switchEmployee"));
    dispatch(getGiftBalance(businessID))
  }, []);

  const [loader, setloader] = useState(false);
  const [openTopUp, setOpenTopUp] = useState(false);
  const [show, setShow] = useState(false);
  const [openBulk, setOpenBulk] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [welcomeModal, setOpenWelcomeModal] = useState(false);
  const [openTourModal, setOpenTourModal] = useState(false);
  const [openRedeemGift, setOpenRedeemGift] = useState(false);
  const [agentSetUpOpen, setAgentSetUpOpen] = useState(false);
  const [openAgentServiceModal, setOpenAgentServiceModal] = useState(false);
  const [openNameModal, setOpenNameModal] = useState(false);
  const [done, setDone] = useState(false);
  const [isDataM, setIsDataM] = useState(false);
  const [openStartGuide, setOpenStartGuide] = useState(false);
  const setwelcomeGuide = () => localStorage.setItem("welcomeGuide", true);
  const getWelcomeGuide = localStorage.getItem("welcomeGuide") == 'true';
  // console.log(getWelcomeGuide, welcomeModal, "welcome");

  const [gift, setGift] = useState(false);

  const [dataA, setDataA] = useState([]);
  const [userWeek, setUserWeek] = useState("");
  const [conversationWeek, setConversationWeek] = useState({
    label: "This week",
    value: "week",
  });
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const creditObject = useSelector((state) => state.getGiftBalance.creditBalance);
const creditsLeft = creditObject?.balance || 0;

  // console.log(appDetail, "this is the dashboard appDetail")
  const getdata = appDetail?.data?.data;
  const navigate = useNavigate();
  const { userType } = useContext(UserContext);
  const token = TokenService.getToken();

  const user = useSelector((state) => state.userLogin);
  const client = user?.user?.data?.user;

  //////added this to retrieve client's data
  // const { loading } = useSelector((state) => state.updateProfile);
  const getUserProfile = useSelector((state) => state.getProfile);
  const profile = getUserProfile?.data?.data;
  // console.log(profile, "person's profile");
  //end

  // const newUser = user?.user?.data?.setup_business;
  const id = client?.id;
  const walletBalance = useSelector((state) => state.walletBalance);
  const { tokens } = walletBalance.data;
  // console.log('tokesn',tokens)
  let num = Math.floor(tokens).toFixed(2);;
  let convertedCredit = num.toLocaleString("en-US")
  const applicationId = appDetail?.agent.id; 
  // console.log(applicationId, "agent applicationId from dashboard");
  // console.log(appDetail.agent, "appDetail.agent applicationId from dashboard");
  const URL = process.env.REACT_APP_BASEURL;
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  // const newAgent = JSON.parse(agentData);
  const newAgent = getAgentApplication?.agent || null;
  // console.log(newAgent, getAgentApplication, "configA newAgent type");

  // console.log(newAgent, "control");
  useEffect(() => {
    if (show === true) {
      setOpenBulk(true);
    }
  }, [show]);
  function formatDateString(dateString) {
    const currentDate = new Date();
    const targetDate = new Date(dateString);

    const monthsDiff =
      (currentDate.getFullYear() - targetDate.getFullYear()) * 12 +
      currentDate.getMonth() -
      targetDate.getMonth();

    return `${monthsDiff} months`;
  }
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (!applicationId) return;
    const fetchData = async () => {
      if (!applicationId) {
        setOpenWelcomeModal(true);
        console.error(
          "No applicationId provided. fetchData function will not run."
        );
        return;
      }
      try {
        setloader(true);
        const response = await axios.get(
          `${URL}/dashboard/applications/${applicationId}/analytics?period=${conversationWeek.value}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const result = response.data.data;
        setDataA(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      try {
        const response = await axios.get(
          `${URL}/dashboard/applications/${applicationId}/memory`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.data.length > 0) {
          setIsDataM(true);
        } else {
          setIsDataM(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setloader(false);
        setSwitchEmployeeQueryParam(false);
      }
    };

    fetchData();
  }, [applicationId, conversationWeek]); //added the dependency appDetail

  // useEffect(() => {
  //   dispatch(fetchWalletBalance());
  // }, [id]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     await dispatch(fetchWalletBalance());
  //   };

  //   // Function to fetch data initially
  //   fetchData();
  //   setFirstRender(false);
  //   // Interval to fetch data every 10 seconds
  //   const intervalId = setInterval(fetchData, 10000); // 10 seconds

  //   // Cleanup function to clear interval when component unmounts
  //   return () => clearInterval(intervalId);
  // }, [id]);
  const location = useLocation();
  const checkGift = localStorage.getItem("user");


  useEffect(() => {
    if(applicationId){
      dispatch(getApplicationDraft(applicationId));
    }
  }, [applicationId])

  useEffect(() => {
    if (!getWelcomeGuide) {
      setOpenWelcomeModal(true);
      // setAgentSetUpOpen(false);
    } else{
      // setAgentSetUpOpen(true);
      setOpenWelcomeModal(false);
    }
    // else if (newAgent?.type && getWelcomeGuide){
    //   setOpenWelcomeModal(false);
    // }
    //  else if (!newAgent?.type && checkGift) {
    //   // setAgentSetUpOpen(true);
    //   setOpenWelcomeModal(false);
    // } else {
    //   return null;
    // }
  }, [getWelcomeGuide]);

  // useEffect(() => {
  //     if (applicationId) {
  //       dispatch(getApplicationDraft(applicationId));
  //     }
  //     if (!getWelcomeGuide) {
  //       setOpenWelcomeModal(true);
  //     } else if (!newAgent?.type) {
  //       // setAgentSetUpOpen(true);
  //     }
  //     //  else if (!newAgent?.type && checkGift) {
  //     //   // setAgentSetUpOpen(true);
  //     //   setOpenWelcomeModal(false);
  //     // } else {
  //     //   return null;
  //     // }
  //   }, [getWelcomeGuide, applicationId]);

  // useEffect(() => {
  //   if (gift === true) {
  //     // setAgentSetUpOpen(true);\
  //     // navigate('/customize-assistant')
  //   }
  // }, [gift]);

  useEffect(() => {
    if (newAgent?.type && !done) {
      setOpenTourModal(false);
    } else if (newAgent?.type && done) {
      setOpenTourModal(true);
    }
  }, [done]);

  const showCloseButton = true;

  const data = [
    {
      features: "Send Money",
      percent: 60,
      conversation: 200,
      status: "Modified",
      creations: "6 months",
    },
    {
      features: "Request Loan",
      percent: 20,
      conversation: 200,
      status: "Modified",
      creations: "6 months",
    },
    {
      features: "Pay Bill",
      img: RangeB,
      percent: 20,
      conversation: 200,
      status: "Modified",
      creations: "6 months",
    },
  ];
  const [propShowPopUp, setPropShowPopUp] = useState(false);

  return (
    <DashboardLayout title={""} user={true}>
      {"Sales" === "Authentication" ? (
        <AuthDashboard />
      ) : "Sales" === "Customer Service" ? (
        <CustomerDashboard />
      ) : (
        <>
          <ChangesBanner setPropShowPopUp={setPropShowPopUp} />
          <TestAgent
            propShowPopUp={propShowPopUp}
            setPropShowPopUp={setPropShowPopUp}
          />
          <div className="px-10 py-12">
            <div className="flex flex-col gap-1">
              <p className="text-xl font-medium text-BLACK-_600 capitalize">{`Hello ${
                profile?.first_name || ""
              }`}</p>{" "}
              {/* changed from ${client?.first_name} */}
              <p className="text-sm text-BLACK-_300">Here’s what’s happening</p>
            </div>
            {walletBalance?.loading === true && firstRender ? (
              <div className="flex gap-6 mt-6">
                <Skeleton
                  variant="rectangular"
                  width={360}
                  height={159}
                  style={{
                    backgroundColor: "rgba(249,249,249,0.86)",
                    borderRadius: "12px",
                  }}
                />
                <Skeleton
                  variant="rectangular"
                  width={360}
                  height={159}
                  style={{
                    backgroundColor: "rgba(249,249,249,0.86)",
                    borderRadius: "12px",
                  }}
                />
                <Skeleton
                  variant="rectangular"
                  width={360}
                  height={159}
                  style={{
                    backgroundColor: "rgba(249,249,249,0.86)",
                    borderRadius: "12px",
                  }}
                />
              </div>
            ) : (
              <div className="flex gap-6 mt-6">
                <div
                  className="w-[33.3%] h-[159px] py-5 px-3.5 rounded-xl step-1"
                  style={{
                    boxShadow: "0px 4px 8px 1px rgba(215, 215, 215, 0.25)",
                    background:
                      "linear-gradient(90deg, #F7F7F7 0%, rgba(250, 250, 250, 0.48) 52.26%, #F7F7F7 100.19%)",
                  }}
                >
                  <div className="flex justify-between">
                    <div>
                      <img src={Purse} alt="wallet" />
                    </div>
                    <button
                      className="flex gap-1"
                      onClick={() => setOpenTopUp(true)}
                    >
                      <BsPlus className="text-BLUE-_200" />
                      <p className="text-xs text-BLUE-_200">Top-up</p>
                    </button>
                  </div>
                  <div className="flex flex-col w-[119px] h-[40px] mt-6 gap-2">
                    <p className="text-BLACK-_300 text-xs">Credits</p>
                    <p className="text-2xl text-BLACK-_600 font-semibold">
                    ${creditsLeft}
                    </p>
                  </div>
                </div>

                <div
                  className="w-[33.3%] h-[159px] py-5 px-3.5 rounded-xl"
                  style={{
                    boxShadow: "0px 4px 8px 1px rgba(215, 215, 215, 0.25)",
                  }}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <img src={Task} alt="user" />
                    </div>
                    <div className="">
                      <Menu as="div" className="relative text-left">
                        <div className="">
                          <Menu.Button className="text-xs flex items-center text-BLACK-_300">
                            {conversationWeek.label || "This week"}
                            <BiChevronDown
                              className="ml-2 -mr-1 h-5 w-5 text-[#828282]"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 mt-2 w-[142px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                            <div className="">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${
                                      active
                                        ? "bg-[#F9FAFB] text-[#121212]"
                                        : "text-gray-900"
                                    } group flex justify-between w-full items-center  px-2 py-2 text-sm`}
                                    onClick={() =>
                                      setConversationWeek({
                                        label: "Today",
                                        value: "today",
                                      })
                                    }
                                  >
                                    Today
                                    {active ? (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 text-[#1774FD]"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 hidden"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${
                                      active
                                        ? "bg-[#F9FAFB] text-[#121212]"
                                        : "text-gray-900"
                                    } group flex w-full justify-between items-center  px-2 py-2 text-sm`}
                                    onClick={() =>
                                      setConversationWeek({
                                        label: "This week",
                                        value: "week",
                                      })
                                    }
                                  >
                                    This Week
                                    {active ? (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 text-[#1774FD]"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 hidden"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                            <div className="">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${
                                      active
                                        ? "bg-[#F9FAFB] text-[#121212]"
                                        : "text-gray-900"
                                    } group flex w-full items-center justify-between px-2 py-2 text-sm`}
                                    onClick={() =>
                                      setConversationWeek({
                                        label: "This Month",
                                        value: "month",
                                      })
                                    }
                                  >
                                    This Month
                                    {active ? (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 text-[#1774FD]"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 hidden"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${
                                      active
                                        ? "bg-[#F9FAFB] text-[#121212]"
                                        : "text-gray-900"
                                    } group flex w-full items-center justify-between px-2 py-2 text-sm`}
                                    onClick={() =>
                                      setConversationWeek({
                                        label: "This Year",
                                        value: "year",
                                      })
                                    }
                                  >
                                    This Year
                                    {active ? (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 text-[#1774FD]"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 hidden"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <p className="text-BLACK-_300 text-xs font-medium mt-6">
                    Average Resolutions
                  </p>
                  <div className="flex flex-col w-[107px] h-[36px] mt-[6px] ">
                    <p className="text-2xl text-BLACK-_600 font-semibold">
                      {dataA?.tasks?.average || 0}
                    </p>
                    <p className="text-GREEN-_200 text-[10px]">
                      {dataA?.increase?.increaseInConversationsPercentage || 0}%
                      More than last week
                    </p>
                  </div>
                </div>

                <div
                  className="w-[33.3%] h-[159px] py-5 px-3.5 rounded-xl"
                  style={{
                    boxShadow: "0px 4px 8px 1px rgba(215, 215, 215, 0.25)",
                  }}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <img src={User} alt="user" />
                    </div>
                    <div className="">
                      <Menu as="div" className="relative text-left">
                        <div className="">
                          <Menu.Button className="text-xs flex items-center text-BLACK-_300">
                            {conversationWeek.label || "This week"}
                            <BiChevronDown
                              className="ml-2 -mr-1 h-5 w-5 text-[#828282]"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 mt-2 w-[142px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                            <div className="">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${
                                      active
                                        ? "bg-[#F9FAFB] text-[#121212]"
                                        : "text-gray-900"
                                    } group flex justify-between w-full items-center  px-2 py-2 text-sm`}
                                    onClick={() =>
                                      setConversationWeek({
                                        label: "Today",
                                        value: "today",
                                      })
                                    }
                                  >
                                    Today
                                    {active ? (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 text-[#1774FD]"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 hidden"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${
                                      active
                                        ? "bg-[#F9FAFB] text-[#121212]"
                                        : "text-gray-900"
                                    } group flex w-full justify-between items-center  px-2 py-2 text-sm`}
                                    onClick={() =>
                                      setConversationWeek({
                                        label: "This week",
                                        value: "week",
                                      })
                                    }
                                  >
                                    This Week
                                    {active ? (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 text-[#1774FD]"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 hidden"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                            <div className="">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${
                                      active
                                        ? "bg-[#F9FAFB] text-[#121212]"
                                        : "text-gray-900"
                                    } group flex w-full items-center justify-between px-2 py-2 text-sm`}
                                    onClick={() =>
                                      setConversationWeek({
                                        label: "This Month",
                                        value: "month",
                                      })
                                    }
                                  >
                                    This Month
                                    {active ? (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 text-[#1774FD]"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 hidden"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${
                                      active
                                        ? "bg-[#F9FAFB] text-[#121212]"
                                        : "text-gray-900"
                                    } group flex w-full items-center justify-between px-2 py-2 text-sm`}
                                    onClick={() =>
                                      setConversationWeek({
                                        label: "This Year",
                                        value: "year",
                                      })
                                    }
                                  >
                                    This Year
                                    {active ? (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 text-[#1774FD]"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <AiOutlineCheck
                                        className="mr-2 h-5 w-5 hidden"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <p className="text-BLACK-_300 text-xs font-medium mt-6">
                    New Users
                  </p>
                  <div className="flex flex-col w-[107px] h-[36px]  mt-[6px] ">
                    <p className="text-2xl text-BLACK-_600 font-semibold">
                      {dataA?.totalNewUsers || 0}
                    </p>
                    <p className="text-GREEN-_200 text-[10px]">
                      {dataA?.increase?.increaseInCustomersPercentage || 0}%
                      More than last week
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-[80px]">
              <h1 className="mb-[32px] font-medium text-[18px] leading-[21.6px]">
                Quick Access
              </h1>
              <div className="mt-[20px] flex gap-[24px]">
                <Link 
                  to="/support" 
                  state={{ Faqs: true }}
                  className="w-[33.3%] h-[254px]"
                >
                  <DashboardCard
                    imageSrc={Launch2}
                    title={"Need help getting started?"}
                    description={
                      "Have questions about your account, assistant or billing? Click to find out more."
                    }
                    imgClassName="mb-[-60px] mr-[-20px]"
                  />
                </Link>
                
                <Link
                  to="/agent"
                  state={{ state: "Memory" }}
                  className="w-[33.3%] h-[254px]"
                >
                  <DashboardCard
                    imageSrc={Puzzle2}
                    title={"Train your customer care assistant"}
                    description={
                      "Upload support documents to your assistant’s memory so it can learn all the basics about your business."
                    }
                    imgClassName="mb-[-50px]"
                  />
                </Link>

                <Link
                  to="/agent"
                  state={{ state: "Features" }}
                  className="w-[33.3%] h-[254px]"
                >
                  <DashboardCard
                    imageSrc={TestTime2}
                    title={"Personalise your customer care assistant"}
                    description={
                      "Add instructions or guidelines on how your Assistant should resolve inquiries, requests and complaints with Customers."
                    }
                    imgClassName="mb-[-70px] mr-[-20px]"
                  />
                </Link>
                
              </div>
            </div>
          </div>

          <ModalPop isOpen={openTopUp}>
            <WalletTopUp
              handleClose={() => setOpenTopUp(false)}
              setShow={setShow}
              setOpenPay={setOpenPay}
            />
          </ModalPop>

          <ModalPop isOpen={openBulk}>
            <Bulk handleClose={() => setOpenBulk(false)} setShow={setShow} />
          </ModalPop>

          <ModalPop isOpen={openPay}>
            <Pay 
              handleClose={() => setOpenPay(false)} 
              setOpenRedeemGift={setOpenRedeemGift}
               />
          </ModalPop>

          {/* <ModalPop isOpen={openTourModal}>
            <BottomGuide
              title="Dashboard"
              subtitle="Your Dashboard shows information like your Credit Balance and useful metrics and also a quick access to your Assistant."
              secondSubtitle="You can Top Up straight from the dashboard as well."
              link="Continue to Memory"
              image={DashboardSvg}
              handleClose={() => {
                setOpenTourModal(false);
              }}
              connect="/agent"
            />
          </ModalPop> */}

          <ModalPop isOpen={welcomeModal && !getWelcomeGuide}>
            <Welcome
              handleClose={() => {
                setOpenWelcomeModal(false);
                setwelcomeGuide();
              }}
              credit={creditsLeft}
              setGift={setGift}
              setAgentSetUpOpen={setAgentSetUpOpen}
              setOpenStartGuide={setOpenStartGuide}
            />
          </ModalPop>

          <ModalPop isOpen={openStartGuide}>
            <Guide
            handleClose={() => {
              setOpenStartGuide(false);
            }}
              text1={`Hi ${profile?.first_name} 👋, I’m Wano AI`}
              text2={
                "I’m here to show you around your dashboard. Would you like a guided tour of the dashboard?"
              }
              text3={"Skip Tour"}
              label={"Take Guided Tour"}
            />
          </ModalPop>

          <ModalPop isOpen={agentSetUpOpen}>
            <AgentSetUp
              agents={getdata}
              handleClose={() => setAgentSetUpOpen(false)}
              handleNextModal={() => setOpenAgentServiceModal(true)}
              setDone={setDone}
              showCloseButton={showCloseButton}
            />
          </ModalPop>
          <ModalPop isOpen={openAgentServiceModal}>
            <AgentServiceSelect
              agents={getdata}
              handleClose={() => setOpenAgentServiceModal(false)}
              previousModal={() => setAgentSetUpOpen(true)}
              setDone={setDone}
              showCloseButton={showCloseButton}
            />
          </ModalPop>
        </>
      )}
    </DashboardLayout>
  );
}

import React, { useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import { AiOutlineClose } from "react-icons/ai"
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'

import { CgSpinner } from 'react-icons/cg'

import PasswordField from '../../PasswordField'
import { updatePassword } from '../../../features/profile/updatePasswordSlice'
import { SuccessMessage } from '../../../Toast-Messages/Toastmessages'

const ChangePassword = ({ handleClose }) => {
    const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState(false);


    const formValidationSchema = Yup.object().shape({
        oldPassword: Yup.string().required("Password is required"),
        newPassword: Yup.string().min(8, "Password must be at least 7 characters").required("Password is required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
            .required("Confirm Password is required"),
    })

    const { loading } = useSelector(state => state.updatePassword)


    const dispatch = useDispatch()


    const submitForm = (values) => {
        const data = {
            password: values?.newPassword,
            old_password: values?.oldPassword
        };
        dispatch(updatePassword(data))
        .then((res) => {
            setUpdatePasswordSuccess(true);
        })
    };

    const removeSuccessNotification = () => {
        setTimeout(() => {
            setUpdatePasswordSuccess(false)
        }, 1000)
      };


      useEffect(() => {
        removeSuccessNotification()
      }, [updatePasswordSuccess])

    

  return (
    <div className="bg-WHITE-_100 w-[500px] h-[510px]  mt-[60px] rounded-lg"> 
        <div className="flex justify-between h-[65px] items-center mx-4 my-1 ">
            <div className=" flex ml-6">
                <p className="font-medium text-lg text-BLACK-_600">Change Password</p>
            </div>
            <button className="w-[30px] h-[30px] border border-[#F7F7F7] rounded-lg flex justify-center items-center p-2" onClick={handleClose}>
                <AiOutlineClose fill='#828282'/>
            </button>
        </div>
        <hr />
        <div className='h-[380px] mt-[33px]'> {/* h-[357px] */}
            <Formik
                initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                }}
                validationSchema={formValidationSchema}
                onSubmit={(values, actions) => {
                    window.scrollTo(0, 0)
                    submitForm(values)

                    actions.resetForm()
                }}
            >
            {({
                handleSubmit,
                handleChange,
                dirty,
                isValid,
                setFieldValue,
                errors,
                touched,
                // setFieldTouched,
                values,
            }) => (
                <Form onSubmit={handleSubmit} className="h-full flex flex-col lg:items-center">
                    <div className='h-full flex flex-col justify-between '>
                        <div className='w-[420px]  flex flex-col gap-5'>
                            <div className="flex flex-col lg:w-[420px]">
                                <label htmlFor='Old Password' className="text-xs font-normal text-[#101828]">Old Password</label>
                                <PasswordField 
                                    name="oldPassword"
                                    value={values.oldPassword}
                                    placeholder=""
                                    className="border w-[420px] h-[38px] rounded-lg border-[#D0D5DD] mt-1.5"
                                    onChange={handleChange}
                                />
                                {errors.oldPassword && touched.oldPassword ? (
                                    <div className='text-RED-_100'>{errors.oldPassword}</div>
                                    ) : null}
                            </div>
                            <div className="flex flex-col lg:w-[420px]">
                                <label htmlFor='New Password' className="text-xs font-normal text-[#101828]">New Password</label>
                                <PasswordField 
                                    name="newPassword"
                                    value={values.newPassword}
                                    placeholder=""
                                    className="border w-[420px] h-[38px] rounded-lg border-[#D0D5DD] mt-1.5"
                                    onChange={handleChange}
                                />
                                {errors.newPassword && touched.newPassword ? (
                                    <div className='text-RED-_100'>{errors.newPassword}</div>
                                    ) : null}
                            </div>
                            <div className="flex flex-col lg:w-[420px]">
                                <label htmlFor='Confirm Password' className="text-xs font-normal text-[#101828]">Confirm Password</label>
                                <PasswordField 
                                    name="confirmPassword"
                                    value={values.confirmPassword}
                                    placeholder=""
                                    className="border w-[420px] h-[38px] rounded-lg border-[#D0D5DD] mt-1.5"
                                    onChange={handleChange}
                                />
                                {errors.confirmPassword && touched.confirmPassword ? (
                                    <div className='text-RED-_100'>{errors.confirmPassword}</div>
                                    ) : null}
                            </div>
                        </div>

                        <div className='flex flex-col w-[340px] h-[76px] mx-auto justify-center self-end'>
                            <button 
                                className="w-[340px] h-[40px] bg-BLACK-_500 rounded-lg px-2 py-1"
                                type="submit"
                            >{
                                loading ?
                                <CgSpinner className=" animate-spin text-lg text-WHITE-_100 mx-auto " />
                                :
                                <p className='text-WHITE-_100 text-sm'>Change Password</p>
                              }
                            </button>
                            <button 
                                className="w-[340px] h-[40px]"
                                type="submit"
                                onClick={handleClose}
                            >
                                <p className='text-BLACK-_800 text-sm font-bold'>Cancel</p>
                            </button>
                        </div>
                    </div>
                    

                </Form>
            )}
            </Formik>

        </div>
        {updatePasswordSuccess && <SuccessMessage  Formodal={true} success="Password Updated Successfully" className="mt-24 mr-20" />}

    </div>
  )
}

export default ChangePassword
import React from 'react'

export default function FormFooter({question,option,onClick}) {
  return (
    <div className="flex gap-[2px] mx-auto">
        <p className="text-sm text-[#828282]">{question}</p>
        <button 
          className="text-[#1774FD] text-sm font-bold"
          onClick={onClick}
        >
            {option}
          </button>
    </div>
  )
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../services/api";
import { appUrls } from "../../services/urls";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

export const readNotification = createAsyncThunk(
  "notification/readNotification",
  async (notificationId, { rejectWithValue }) => {
    try {
      const res = await api.put(
        `/notifications/${notificationId}/read`
      );
      return res?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

const readNotificationSlice = createSlice({
  name: "notification",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(readNotification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      readNotification.fulfilled,
      (state, action) =>
        void ((state.loading = false),
        (state.data = action.payload),
        (state.error = ""))
    );
    builder.addCase(
      readNotification.rejected,
      (state, action) =>
        void ((state.loading = false),
        (state.data = []),
        (state.error = action.payload))
    );
  },
});

export default readNotificationSlice.reducer;

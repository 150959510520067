import React, {useRef, useEffect, useState } from "react";

import { Popover } from "@headlessui/react";
import { HiCheck } from "react-icons/hi";
import { FiChevronDown } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { updateBusiness } from "../../../features/business/updateBusinessSlice";
import { CgSpinner } from "react-icons/cg";
import { SuccessMessage } from "../../../Toast-Messages/Toastmessages";
import { getBusiness,getABusiness } from "../../../features/business/getBusinessSlice";
import ModalPop from "../../../component/modalPop";
import UnsavedSettings from "@/../Settings/UnsavedSettings";
import { useLocation } from "react-router";

const Business = ({
  activeButton,
  setActiveButton,
  setShowUnsavedSettingsModal,
  showUnsavedSettingsModal,
  handleCloseUnsavedSettingsModal,
  reroute,
  setReroute,
  isFormModified,
  setIsFormModified 
}) => {
  const [teamSize, setTeamSize] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [pleaseSpecifyCategory, setPleaseSpecifyCategory] = useState("");
  const [pleaseSpecifyRole, setPleaseSpecifyRole] = useState("");
  const [fieldEdited, setFieldEdited] = useState({
    field1: false,
    field2: false,
  });

  // const handleBusinessNameChange = (event) => {
  //   const inputValue = event.target.value;
  //   setBusinessNameEdited(true);
  //   setBusinessName(inputValue);
  // };

  // console.log(isFormModified, "isFormModified")

  //handles the input change event
  const handleInputChange = (event, setStateFunction, field) => {
    const inputValue = event.target.value;
    setStateFunction(inputValue);
    setFieldEdited((previousfieldEdited) => ({
      ...previousfieldEdited,
      [field]: true,
    }));
    setIsFormModified(true);
    localStorage.setItem("isFormModifiedB", true)
    localStorage.setItem("path", "/settings")
  };

const location = useLocation()
console.log(location)
  
  // const saveChanges = () => {
  //   submitForm()
  //   setIsFormModified(false);
  //   setTimeout(() => {
  //     setShowUnsavedSettingsModal(false);
  //   }, 1500)
  //   setShowUnsavedSettingsModal(false);
  // //   setActiveButton(reroute);
  //   setReroute('');
  // };

  const saveChanges = async () => {
    setShowUnsavedSettingsModal(true); 
    setIsFormModified(false); 
    localStorage.setItem("isFormModifiedB", false)
    localStorage.setItem("showUnsavedSettingsModal", false)
    try {
      await submitForm(); 
      setUpdateSuccess(true); 
    } catch (error) {
      console.error("Failed to save changes:", error);
      setIsFormModified(true);
      localStorage.setItem("isFormModifiedB", true)
      localStorage.setItem("path", "/settings")
    }
  
    // Now close the modal after the operation is complete
    setTimeout(() => {
      setShowUnsavedSettingsModal(false);
      setReroute('');
    }, 1000); // Optionally keep the modal open for a bit longer to show a success messa
  };
  
  
  const discardChanges = () => {
    setIsFormModified(false);
    localStorage.setItem("isFormModifiedB", false)
    localStorage.setItem("showUnsavedSettingsModal", false)
    setShowUnsavedSettingsModal(false);
    setActiveButton(reroute);
    setReroute('');
  };
  
  const closeModal = () => {
    setShowUnsavedSettingsModal(false);
    localStorage.setItem("showUnsavedSettingsModal", false)
  };
  //end

  const [updateSuccess, setUpdateSuccess] = useState(false);

  const handleBusinessSize = (size) => {
    setTeamSize(size);
  };
  const handleCategory = (Category) => {
    setSelectedCategory(Category);
  };
  const handleRole = (Role) => {
    setSelectedRole(Role);
  };

  const Category = [
    "Agri Tech",
    "Financial Service",
    "SaaS",
    "Education",
    "Health Tech",
    "Transportation",
    "Other(Please Specify)",
  ];
  const Roles = [
    "CEO or Founder",
    "Software Developer",
    "IT Manager",
    "Customer Support Manager",
    "Project Manager",
    "Sales Manager",
    "Other(Please Specify)",
  ];
  const BussinessSize = ["1 - 10", "10 - 100", "100 - 500", "500+"];

  // const areAllFieldsFilled = () => {
  //   if (
  //     businessName.trim() !== "" &&
  //     ((selectedCategory !== "Other(Please Specify)" &&
  //       selectedCategory.trim() !== "") ||
  //       (selectedCategory === "Other(Please Specify)" &&
  //         pleaseSpecifyCategory.trim() !== "")) &&
  //     ((selectedRole !== "Other(Please Specify)" &&
  //       selectedRole.trim() !== "") ||
  //       (selectedRole === "Other(Please Specify)" &&
  //         pleaseSpecifyRole.trim() !== "")) &&
  //     teamSize.trim() !== ""
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.updateBusiness);

  const getUserBusiness = useSelector((state) => state.getBusiness);
  // const business = getUserBusiness?.data?.data[0]
  const cBus = localStorage.getItem("cBus");

  const business = getUserBusiness.activeBusiness || getUserBusiness?.data?.data[0];

  const submitForm = async () => {
    const data = {
      name: businessName || business?.name,
      description: businessName || business?.name,
      team_size: teamSize || business?.team_size,
      category: pleaseSpecifyCategory || selectedCategory || business?.category,
      purpose_for_wano: "Sales",
      business_email: business?.business_email,
      website_url:
        `https://${businessWebsite}` || business?.team[0]?.role?.name,
      country: "Nigeria",
    };
   await dispatch(updateBusiness(data))
   await dispatch(getABusiness({id:business.id}))
    setUpdateSuccess(true);
    
  };

  const removeSuccessNotification = () => {
    setTimeout(() => {
      setUpdateSuccess(false);
    }, 1000);
  };

  useEffect(() => {
    removeSuccessNotification();
  }, [updateSuccess]);

  useEffect(() => {
    dispatch(getBusiness());
  }, [loading, dispatch]); // added dispatch

  return (
    <div className="flex gap-[155px]">
      <div className="w-[219px] h-[50px] flex flex-col gap-1">
        <p className="font-medium text-BLACK-_100 text-sm">Business Profile</p>
        <p className="text-xs text-BLACK-_200">
          Set a description and look for your agent while it interacts with your
          customers
        </p>
      </div>
      <div className="w-[420px] mb-[50px] ">
        <div className="grid gap-5 ">
          <label className="label">
            Business Name
            <input
              className="input mt-3 h-[38px]"
              placeholder=""
              value={
                fieldEdited.field1 // Check if the business name has been manually edited
                  ? businessName
                  : business?.name || "" // Use business name if available, otherwise, use an empty string
              }
              onChange={(e) => handleInputChange(e, setBusinessName, "field1")}
              type="text"
            />
          </label>
          <label className="label flex flex-col">
            <span>
              Business Website{" "}
              <span className="text-[#828282] text-[10px]">Optional</span>
            </span>
            <span className="mt-3 border-solid border border-[#D0D5DD] rounded-[8px] flex p-1 gap-1 h-[38px]">
              <span className="text-[#828282] p-2 bg-[#F2F2F2] rounded-sm flex items-center justify-center">
                https://
              </span>
              <input
                type="text"
                className="w-full focus:outline-none border-none"
                value={
                  fieldEdited.field2
                    ? businessWebsite
                    : business?.website_url.replace("https://", "") || ""
                }
                onChange={(event) =>
                  handleInputChange(event, setBusinessWebsite, "field2")
                }
              />
            </span>
          </label>
          <label className="label">
            What Category does your business fall under
            <Popover className=" mt-3 relative ">
              <Popover.Button>
                <input
                  type="text"
                  // disabled
                  value={selectedCategory || business?.category}
                  // onChange={(event) => setSelectedCategory(event.target.value)}
                  className="h-[38px] bg-white outline-none pr-16 cursor-pointer"
                />
                <FiChevronDown
                  className={` absolute top-1 right-0 px-2 py-1 text-3xl cursor-pointer 
                ${selectedCategory !== "" ? "text-black" : "text-[#828282]"}`}
                />
              </Popover.Button>
              <Popover.Panel className=" absolute w-full bg-white z-50 h-[160px] overflow-y-auto shadow-xl flex flex-col rounded-lg">
                {Category.map((Category, index) => (
                  <Popover.Button
                    key={index}
                    onClick={() => handleCategory(Category)}
                    className=" py-[10px] px-3 select-none cursor-pointer hover:bg-[rgba(205,222,248,0.20)] flex justify-between"
                  >
                    <p className=" text-xs font-normal text-[#101828]">
                      {Category}
                    </p>
                    {selectedCategory === Category && (
                      <HiCheck className="text-[17px] text-[rgba(23,116,253,1)]" />
                    )}
                  </Popover.Button>
                ))}
              </Popover.Panel>
            </Popover>
          </label>
          {selectedCategory === "Other(Please Specify)" && (
            <label className="label">
              <p className="text-[#828282]">Please specify</p>
              <input
                className="input mt-3 h-[38px]"
                type="text"
                value={pleaseSpecifyCategory}
                onChange={(event) =>
                  setPleaseSpecifyCategory(event.target.value)
                }
              />
            </label>
          )}
          <label className="label">
            Your Role
            <Popover className=" mt-3 relative ">
              <Popover.Button>
                <input
                  type="text"
                  disabled
                  value={selectedRole || business?.team[0]?.role?.name}
                  // onChange={(event) => setSelectedRole(event.target.value)}
                  className="h-[38px] bg-white outline-none pr-16"
                />
                {/* <FiChevronDown
                className={` absolute top-1 right-0 px-2 py-1 text-3xl cursor-pointer
                ${selectedRole !== "" ? "text-black" : "text-[#828282]"}`}
              /> */}
              </Popover.Button>
              <Popover.Panel className=" absolute w-full bg-white z-50 h-[160px] overflow-y-auto shadow-xl flex flex-col rounded-lg">
                {Roles.map((Role, index) => (
                  <Popover.Button
                    key={index}
                    onClick={() => handleRole(Role)}
                    className=" py-[10px] px-3 select-none cursor-pointer hover:bg-[rgba(205,222,248,0.20)] flex justify-between"
                  >
                    <p className=" text-xs font-normal text-[#101828]">
                      {Role}
                    </p>
                    {selectedRole === Role && (
                      <HiCheck className="text-[17px] text-[rgba(23,116,253,1)]" />
                    )}
                  </Popover.Button>
                ))}
              </Popover.Panel>
            </Popover>
          </label>
          {selectedRole === "Other(Please Specify)" && (
            <label className="label">
              <p className="text-[#828282]">Please specify</p>
              <input
                className="input mt-3 h-[38px]"
                type="text"
                value={pleaseSpecifyRole}
                onChange={(event) => setPleaseSpecifyRole(event.target.value)}
              />
            </label>
          )}
          <label className="label">
            Team Size
            <Popover className=" mt-3 relative ">
              <Popover.Button>
                <input
                  type="text"
                  // disabled
                  value={teamSize || business?.team_size}
                  className="h-[38px] bg-white outline-none pr-16 cursor-pointer"
                />
                <FiChevronDown
                  className={`absolute top-1 right-0 px-2 py-1 text-3xl  cursor-pointer 
                ${teamSize !== "" ? "text-black" : "text-[#828282]"}`}
                />
              </Popover.Button>
              <Popover.Panel className=" absolute w-full bg-white z-50 h-[145px] overflow-y-auto shadow-xl flex flex-col rounded-lg">
                {BussinessSize.map((Size, index) => (
                  <Popover.Button
                    key={index}
                    onClick={() => handleBusinessSize(Size)}
                    className=" py-[10px] px-3 select-none cursor-pointer hover:bg-[rgba(205,222,248,0.20)] flex justify-between"
                  >
                    <p className=" text-xs font-normal text-[#101828]">
                      {Size}
                    </p>
                    {teamSize === Size && (
                      <HiCheck className="text-[17px] text-[rgba(23,116,253,1)]" />
                    )}
                  </Popover.Button>
                ))}
              </Popover.Panel>
            </Popover>
          </label>

          <button
            className="w-[118px] h-[34px] bg-BLACK-_500 rounded-lg px-2 py-1"
            type="submit"
            onClick={() => submitForm()}
          >
            {loading ? (
              <CgSpinner className=" animate-spin text-lg text-WHITE-_100 mx-auto " />
            ) : (
              <p className="text-WHITE-_100 text-xs">Save Changes</p>
            )}
          </button>
        </div>
      </div>
      {updateSuccess && (
        <SuccessMessage
          Formodal={true}
          success="Business Updated Successfully"
          className="mt-24 mr-20"
        />
      )}
      
      <ModalPop isOpen={showUnsavedSettingsModal}>
        <UnsavedSettings
          handleClose={discardChanges} 
          handleSave={() => {saveChanges()}}
          close={() => closeModal()}
          loading={loading}
        />
      </ModalPop>
    </div>
  );
};

export default Business;

{
  /* <div className="flex flex-col">
        <label htmlFor='category' className="text-xs font-normal text-[#101828]">What Category does your business fall under</label>
        <select 
          className="rounded-lg border-[#D0D5DD] text-xs outline-none text-[#828282] w-[420px] mt-1.5 h-[38px] border-solid  p-2 border" 
          onChange={handleChange}
          name='category'
        >
          {categoryOptions.map((items) => (
            // <div key={items?.label} className='bg-WHITE-_100 rounded-lg flex flex-col gap-1'>
              <option 
                id='category' 
                value={items.value} 
                className='text-sm h-[316px] border-none rounded-lg py-[4px]'
                style={{ border: "none", paddingTop:"4px" }}
              >
                  {items?.label}  
                </option>
            // </div>
          ))}
        </select>
          {errors.category && touched.category ? (
          <div className='text-RED-_100'>{errors.category}</div>
          ) : null}
      </div>*/
}

{
  /* <div className='flex flex-col gap-5 '>
        
                  <div className="flex flex-col ">
                    <label htmlFor='name' className="text-xs font-normal text-[#101828]">Business Name</label>
                        <input
                            name="name"
                            placeholder=""
                            type="text" 
                            value={values.name}
                            onChange={handleChange}
                            className="rounded-lg border-[#D0D5DD] xs:w-full outline-none lg:w-[420px] mt-1.5 h-[38px] border-solid  p-3 border"
                        />
                        {errors.name && touched.name ? (
                        <div className='text-RED-_100'>{errors.name}</div>
                        ) : null}
                  </div>

                  <div className="flex flex-col ">
                    <div className='flex items-center gap-1'>
                      <label htmlFor='website' className="text-xs font-normal text-[#101828]">Business Website</label>
                      <p className='text-[10px] font-medium text-BLACK-_300'>Optional</p>
                    </div>
                    <div className='w-[420px] h-[38px] p-1 flex border-[#D0D5DD] outline-none  border border-solid rounded-lg'>
                      <div className='bg-WHITE-_500 p-1.5 rounded-[2px] flex flex-col items-center justify-center'>
                        <p className='text-[10px] text-BLACK-_300 rounded-lg'>https://</p>
                      </div>
                      <input
                          name="website"
                          placeholder=""
                          type="text" 
                          value={values.website}
                          onChange={handleChange}
                          className="border-none outline-none p-3"
                      />
                      {errors.website && touched.website ? (
                      <div className='text-RED-_100'>{errors.website}</div>
                      ) : null}
                    </div>      
                  </div>

                  <div className="flex flex-col">
                    <label htmlFor='category' className="text-xs font-normal text-[#101828]">What Category does your business fall under</label>
                    <Popover className=" mt-3 relative ">
                      <input
                        type="text"
                        name='category'
                        value={values?.category}
                        onChange={handleChange}
                        className="h-[38px] bg-white outline-none pr-16"
                      />
                      <Popover.Button>
                        <BiChevronDown
                          className={` absolute top-0 right-0 px-2 py-1 text-4xl cursor-pointer 
                          ${
                            values?.category !== "" ? "text-black" : "text-[#828282]"
                          }`}
                        />
                      </Popover.Button>
                      <Popover.Panel className=" absolute w-full bg-white z-50 h-[160px] overflow-y-auto shadow-xl flex flex-col rounded-lg">
                        {categoryOptions.map((Category, index) => (
                          <Popover.Button
                            key={index}
                            value={values?.category}
                            className=" py-[10px] px-3 select-none cursor-pointer hover:bg-[rgba(205,222,248,0.20)] flex justify-between"
                          >
                            <p className=" text-xs font-normal text-[#101828]">
                              {Category}
                            </p>
                            {values?.category === Category && (
                              <HiCheck className="text-[17px] text-[rgba(23,116,253,1)]" />
                            )}
                          </Popover.Button>
                        ))}
                      </Popover.Panel>
                    </Popover>
                    {errors.category && touched.category ? (
                    <div className='text-RED-_100'>{errors.category}</div>
                    ) : null}
                  </div>

                  {
                    values?.category === "Other" ?
                      <div className="flex flex-col ">
                        <label htmlFor='categoryOther' className="text-xs font-normal text-[#101828]">Please specify</label>
                            <input
                                name="categoryOther"
                                placeholder=""
                                type="text" 
                                value={values.categoryOther}
                                onChange={handleChange}
                                className="rounded-lg border-[#D0D5DD] xs:w-full outline-none lg:w-[420px] mt-1.5 h-[38px] border-solid  p-3 border"
                            />
                            {errors.categoryOther && touched.categoryOther ? (
                            <div className='text-RED-_100'>{errors.categoryOther}</div>
                            ) : null}
                      </div> 
                      : null

                  }

                  <div className="flex flex-col ">
                    <label htmlFor='role' className="text-xs font-normal text-[#101828]">Your Role</label>
                      <select 
                        className="rounded-lg border-[#D0D5DD] text-xs text-[#828282] outline-none w-[420px] mt-1.5 h-[38px] border-solid  p-2 border" 
                        onChange={handleChange}
                        name='role'
                      >
                        {roleOptions.map((items) => (
                          // <div key={items?.label} className='bg-WHITE-_100 rounded-lg flex flex-col gap-1'>
                            <option id='category' value={items.value} className='text-sm'>{items?.label}</option>
                          // </div>
                        ))}
                      </select>
                      
                        {errors.role && touched.role ? (
                        <div className='text-RED-_100'>{errors.role}</div>
                        ) : null}
                  </div>

                  {
                    values?.role === "Other" ?
                      <div className="flex flex-col ">
                        <label htmlFor='roleOther' className="text-xs font-normal text-[#101828]">Please specify</label>
                            <input
                                name="roleOther"
                                placeholder=""
                                type="text" 
                                value={values.roleOther}
                                onChange={handleChange}
                                className="rounded-lg border-[#D0D5DD] xs:w-full outline-none lg:w-[420px] mt-1.5 h-[38px] border-solid  p-3 border"
                            />
                            {errors.roleOther && touched.roleOther ? (
                            <div className='text-RED-_100'>{errors.roleOther}</div>
                            ) : null}
                      </div> 
                      : null

                  }

                  <div className="flex flex-col ">
                    <label htmlFor='size' className="text-xs font-normal text-[#101828]">Team Size</label>
                      <select 
                        className="rounded-lg border-[#D0D5DD] text-xs text-[#828282] outline-none w-[420px] mt-1.5 h-[38px] border-solid  p-2 border" 
                        onChange={handleChange}
                        name='size'
                      >
                         {teamSizeOptions.map((items) => (
                          // <div key={items?.label} className='bg-WHITE-_100 rounded-lg flex flex-col gap-1'>
                            <option id='category' value={items.value} className='text-sm'>{items?.label}</option>
                          // </div>
                        ))}
                      </select>
                      
                        {errors.size && touched.size ? (
                        <div className='text-RED-_100'>{errors.size}</div>
                        ) : null}
                  </div>
                  
                  <button 
                      className="w-[118px] h-[34px] bg-BLACK-_500 rounded-lg px-2 py-1"
                      type="submit"
                  >
                    <p className='text-WHITE-_100 text-xs'>Save Changes</p>
                  </button>
                
                </div>*/
}

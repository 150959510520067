// modalContext.js
import React, { createContext, useState, useContext } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState({});

  const openModal = (name, props) => {
    setModals((prev) => ({ ...prev, [name]: { isOpen: true, props } }));
  };

  const closeModal = (name) => {
    setModals((prev) => ({ ...prev, [name]: { ...prev[name], isOpen: false } }));
  };

  return (
    <ModalContext.Provider value={{ modals, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
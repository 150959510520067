import React, { useEffect, useState } from 'react'
import { Form, Formik } from "formik"
import { useNavigate } from 'react-router'
import * as Yup from "yup"
import axios from 'axios'
import DashboardLayout from '../layout/DashboardLayout'

import ArrowUp from "../Assets/svg/arrow-up.svg"
import ArrowDown from "../Assets/svg/arrow-down.svg"
import Green from "../Assets/svg/dot_green.svg"
import Red from "../Assets/svg/dot_red.svg"
import Disconnect from "../Assets/svg/disconnect.svg"
import Connect from "../Assets/svg/connect.svg"
import ModalPop from './modalPop'
import PhoneVerify from './modals/kyc/PhoneVerify'
import Success from './modals/kyc/whatsapp/Success'
import TokenService from '../services/token'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWhatsAppNumbers } from '../features/deploy/whatsAppSlice'
import { connectWhatsapp } from '../features/deploy/whatsAppConnectSlice'
import { CgSpinner } from 'react-icons/cg'
import { SuccessMessage } from '../Toast-Messages/Toastmessages'
import Otp from './modals/kyc/whatsapp/Otp'


const Deploy = () => {
    const [showTestDropDown, setShowTestDropDown] = useState(true);
    const [showDeployDropDown, setShowDeployDropDown] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [openDeployModal, setOpenDeployModal] = useState(false);
    const [deployAgent, setDeployAgent] = useState(false);
    const [connect, setConnect] = useState(false)
    const [deployConnect, setDeployConnect] = useState(false)
    const [whatsAppNotification, setWhatsAppNotification] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [otp, setOtp] = useState(false);
    const URL = process.env.REACT_APP_BASEURL;
    const token = TokenService.getToken();
    const appDetail = useSelector((state) => state.getApplicationByUserId);
    const data = appDetail?.data?.data;
    const agentData = localStorage.getItem("wano_agent");
    const agent = data && data.length >= 1 ? data[0] : {};
    const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
    const applicationId = agentDetails.id;
 
    const navigate = useNavigate()

    const whatsAppConnect = useSelector(state => state.whatsAppConnect);
    const whatsAppLoading = whatsAppConnect.loading

    const formValidationSchema = Yup.object().shape({
        number: Yup.number().required(),
    });

    const deployFormValidationSchema = Yup.object().shape({
        number: Yup.number().max(10).required(),
    });

    const testDropDown = () => {
        setShowTestDropDown(prev => !prev)
    };

    const deployDropDown = () => {
        setShowDeployDropDown(prev => !prev)
    };

    const selectOption = () => {
        setConnect(prev => !prev)
    };
    const deployOption = () => {
        setDeployConnect(prev => !prev)
    };

    const dispatch = useDispatch();

    const whatsApp = useSelector(state => state.whatsAppAll);


    const submitForm = (values) => {
        const data = {
            phone_number: `+234${values?.number}`,
            type: "messaging",
            channel: "whatsapp"
        }
        dispatch(connectWhatsapp({applicationId, data }))
        setWhatsAppNotification(true)
    }

    const submitDeployForm = async (values) => {
        if (!applicationId) {
            console.error(
              "No applicationId provided. submitform function will not run."
            );
            return;
        }
        setLoading(true);
        const data = {
            phone_number: `+234${values?.number}`,
            type: "messaging",
            channel: "whatsapp"
        }
        try {
            const res = await axios.post(`${URL}/businesses/application/${applicationId}/peer/request`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            if (res?.data?.message === "OTP sent successfully") {
                localStorage.setItem("whatsapp", `+234${values?.number}`)
                localStorage.setItem("channel", `whatsapp`)
                localStorage.setItem("type", `messaging`)

                setLoading(false);
                setOtp(true)
            }
        } catch(error) {
            console.error(error)
            setLoading(false)
        }
    }
    
    const submitDeployForm2 = async (values) => {
        if (!applicationId) {
            console.error(
              "No applicationId provided. submitform function will not run."
            );
            return;
        }
        setLoading2(true);
        const data = {
            phone_number: `+234${values?.number}`,
            type: "ai_bot",
            channel: "whatsapp"
        }
        try {
            const res = await axios.post(`${URL}/businesses/application/${applicationId}/peer/request`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            if (res?.data?.message === "OTP sent successfully") {
                localStorage.setItem("whatsapp", `+234${values?.number}`)
                localStorage.setItem("channel", `whatsapp`)
                localStorage.setItem("type", `ai_bot`)
                setLoading2(false);
                setOtp(true)
            }
        } catch(error) {
            console.error(error)
            setLoading2(false)
        }
    }

    const removeWhatsAppNotification = () => {
        setTimeout(() => {
            setWhatsAppNotification(false)
        }, 1000);
    }


    useEffect(() => {
        removeWhatsAppNotification()
    }, [whatsAppLoading])

    useEffect(() => {
        dispatch(fetchWhatsAppNumbers(applicationId))
    }, [whatsAppLoading])

    // const phoneNumber = []
    // const showPhoneNumber = () => {
    //     const phone = localStorage.getItem("test")
    //     if(!openModal) {
    //         phoneNumber.push(phone);
    //     } else {
    //        return null
    //     }
    //     return phoneNumber
    // };
    // showPhoneNumber();
    
    // console.log(phoneNumber, "IGS")


    // const showDeployNumber = () => {
    //     const deploy = localStorage.getItem("deploy");
    //     if(!openDeployModal) {
    //         deployNumber.push(deploy)
    //     } else {
    //         return null
    //     }
    //     return deployNumber
    // };
    // showDeployNumber();
    

    const removeDeployNumber = () => localStorage.removeItem("deploy");
    const removePhoneNumber = () => localStorage.removeItem("test");

    useEffect(() => {
        // removeDeployNumber();
        removePhoneNumber();
    }, [])



  return (
    <DashboardLayout title={'Test/Deploy'} >
        <div className='p-10'>
            <div className='flex flex-col w-[647px] h-[88px] gap-2'>
                <p className='text-sm font-semibold text-[#7F7F81]'>Step 3 of 3</p>
                <p className='text-BLACK-_100 text-2xl font-medium'>Test/Deploy</p>
                <p className='text-xs text-BLACK-_200'>
                    And finally, Test and deploy your assistant to WhatsApp and get started with interactive conversations 
                    with your customers.
                </p>
            </div>

            <div className="mt-8">
                <div 
                    className={`${showTestDropDown ? "shadow-sm h-[300px]" : "h-[50px] "} overflow-y-scroll border border-solid border-BLACK-_400 p-3 rounded-lg`}  
                    style={{backgroundColor: "#fff" }} //
                >
                    <div className="items-center cursor-pointer  flex justify-between mx-2" onClick={() => testDropDown()}>
                        <p style={{color: `${showTestDropDown ?  "#121212" : "#121212"}`}} className="text-base font-medium">Test on Whatsapp</p>
                        <img src={showTestDropDown ? ArrowUp : ArrowDown} alt="arrow-down" />
                    </div>
                    {showTestDropDown && (
                        <div className="flex mt-3.5 mx-2">
                            <Formik
                                initialValues={{
                                    number: "",
                                }}
                                validationSchema={formValidationSchema}
                                onSubmit={(values, action) => {
                                    window.scrollTo(0, 0)
                                    submitForm(values)

                                
                                    // setOpenModal(true)
                                
                                    action.resetForm();
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    dirty,
                                    isValid,
                                    setFieldValue,
                                    errors,
                                    touched,
                                    // setFieldTouched,
                                    values,
                                }) => (
                                    <Form onSubmit={handleSubmit} className="flex flex-col items-center">
                                        <div className= "flex flex-col gap-5">

                                            <div className='w-[724px]'>
                                                <p className='text-sm text-BLACK-_500'>Add numbers that you would like to use to test your assistant on WhatsApp. You can add multiple numbers for the test.</p>
                                            </div>

                                            <div className="flex flex-col   ">
                                                <div className='flex items-center gap-3'>
                                                    <div className='flex flex-col'>
                                                        <label htmlFor='number' className="text-xs font-medium text-[#101828]">Add new number</label>

                                                        <div className='flex w-[420px] rounded-lg border-[#D0D5DD] border h-[38px] bg-WHITE-_100 border-solid mt-1.5'>
                                                            <select name="number" id="" className='text-xs border-t-0 m-0.5 border border-l-0 border-b-0 outline-none '>
                                                                <option>NGN(+234)</option>
                                                            </select>

                                                            <input
                                                                name="number"
                                                                placeholder=""
                                                                type="text" 
                                                                value={values.number}
                                                                onChange={handleChange}
                                                                className="outline-none w-[308px] border-none p-3"
                                                            />
                                                        </div>
                                                    </div>

                                                    <button 
                                                        className="w-[121px] h-[38px] bg-BLACK-_500 mt-5 rounded-lg px-2  py-1"
                                                        type="submit"
                                                    >
                                                        {
                                                            whatsAppLoading ?
                                                            <CgSpinner className=" animate-spin text-lg text-WHITE-_100 mx-auto " />
                                                            :
                                                            <p className='text-WHITE-_100 text-xs'>Verify Number</p>
                                                        }
                                                    </button>
                                                </div>
                                                {errors.number && touched.number ? (
                                                        <div className='text-RED-_100 text-xs'>{errors.number}</div>
                                                        ) : null}
                                            </div>

                                            {whatsApp?.data?.length > 0 && 
                                                <div className='flex flex-col'>
                                                    <div className={`${whatsApp?.data === null ? "invisible" : 'w-[510px] flex justify-between'}`}>
                                                        <p className='text-sm text-[#121212]'>Phone Number</p>
                                                        <p className='text-sm text-[#121212]'>Status</p>
                                                    </div>
                                                    {whatsApp?.data.length > 0 ? 
                                                        whatsApp?.data.map((item, index) => (
                                                            <div className={`${item === null ? "invisible" : 'w-[540px] flex justify-between'}`}  key={item?.id}>
                                                                <div className='flex flex-col gap-1'>
                                                                    {/* <p className='text-sm text-[#121212]'>Phone Number</p> */}
                                                                    <div className='flex items-center gap-1'> 
                                                                        <img src={connect ? Green : Red} alt='dot' className="w-[9px] h-[9px]" />
                                                                        <p className='text-BLACK-_100 text-sm'>{`+${item?.phone_number}`}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='flex flex-col gap-1'>
                                                                    {/* <p className='text-sm text-[#121212]'>Status</p> */}
                                                                    <div className={`${item === null ? "invisible" : 'flex cursor-pointer items-center gap-1'}`}  > {/* onClick={() => selectOption()} */}
                                                                        {/* <img src={connect ? Connect : Disconnect} alt='dot' className="w-[10px] h-[10px]" /> */}
                                                                        {/* <p className={`${connect ? "text-[#057601]" : "text-RED-_100"} text-xs`}>{connect ? "Connect" : "Disconnect"}</p> */}
                                                                        <img src={item.status === "connecting" ? Disconnect : Connect} alt='dot' className="w-[10px] h-[10px]" />
                                                                        <p className={`${item.status === "connecting" ? "text-RED-_100" : "text-[#057601]"} text-xs`}>{item.status}</p>
                                                                    </div>
                                                                </div>
                                                                                                                    
                                                            </div>
                                                        ))
                                                        : null
                                                    }

                                                </div>
                                            }
                                        
                                        </div>
                                        

                                    </Form>
                                )}
                            </Formik>
                            
                        </div>
                    )}
                </div>
                {whatsAppNotification && <SuccessMessage Formodal={true} success={whatsAppConnect?.data?.message} className="mt-28 mr-12"/>}
            </div>

            <div className='mt-6 mb-8'>
                <div 
                    className={`${showDeployDropDown ? "shadow-sm h-[330px]" : "h-[50px] "} border border-solid border-BLACK-_400 p-3 rounded-lg`}  
                    style={{backgroundColor: "#fff" }} //
                >
                    <div className="items-center cursor-pointer  flex justify-between mx-2" onClick={() => deployDropDown()}>
                        <p style={{color: `${showDeployDropDown ?  "#121212" : "#121212"}`}} className="text-base font-medium">Deploy Message Assistant Now</p>
                        <img src={showDeployDropDown ? ArrowUp : ArrowDown} alt="arrow-down" />
                    </div>
                    {showDeployDropDown && (
                        <div className="flex mt-3.5 mx-2">
                            <Formik
                                initialValues={{
                                    number: "",
                                }}
                                // validationSchema={deployFormValidationSchema}
                                onSubmit={(values, action) => {
                                    window.scrollTo(0, 0)
                                    submitDeployForm(values)
                                    // setDeployAgent(true)
                                    // setOpenDeployModal(true)
                                    action.resetForm()
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    dirty,
                                    isValid,
                                    setFieldValue,
                                    errors,
                                    touched,
                                    // setFieldTouched,
                                    values,
                                }) => (
                                    <Form onSubmit={handleSubmit} className="flex flex-col items-center">
                                        <div className= "flex flex-col gap-3">

                                            <div className='w-[724px]'>
                                                <p className='text-sm text-BLACK-_500'>Ready to Deploy? We require 3 things from you for us to complete your assistants deployment to WhatsApp:</p>
                                            </div>

                                            <ul className='list-decimal ml-5 mt-2'>
                                                <li className='text-sm text-[#828282]'>An Active Facebook Account for your Business</li>
                                                <li className='text-sm text-[#828282] mt-2'>A number that hasn’t been registered to WhatsApp before</li>
                                                <li className='text-sm text-[#828282] mt-2'>An SMS OTP Code to verify the number provided.</li>
                                            </ul>

                                            <div className='flex items-center mt-[17px] gap-3'>
                                                <div className="flex flex-col  ">
                                                    <label htmlFor='number' className="text-xs font-medium text-[#101828]">Enter New Number</label>

                                                    <div className='flex w-[420px] rounded-lg border-[#D0D5DD] border h-[38px] bg-WHITE-_100 border-solid mt-1.5'>
                                                        <select name="number" id="" className='text-xs border-t-0 m-0.5 border border-l-0 border-b-0 outline-none '>
                                                            <option>NGN(+234)</option>
                                                        </select>

                                                        <input
                                                            name="number"
                                                            placeholder=""
                                                            type="text" 
                                                            value={values.number}
                                                            onChange={handleChange}
                                                            className="  outline-none w-[308px] border-none p-3 "
                                                        />
                                                    </div>
                                                </div>
                                        
                                                <button 
                                                    className="w-[141px] h-[38px] bg-BLACK-_500 rounded-lg px-2 mt-5 py-1"
                                                    type="submit"
                                                >
                                                    {loading ?
                                                        <CgSpinner className=" animate-spin text-lg text-WHITE-_100 mx-auto " />
                                                    :
                                                        <p className='text-WHITE-_100 text-xs'>Deploy to Whatsapp</p>
                                                    }
                                                </button>
                                            </div>
                                            {/* {errors.number && touched.number ? (
                                            <div className='text-RED-_100'>{errors.number}</div>
                                            ) : null} */}

                                            {whatsApp?.data?.length > 0 && 
                                                <div className='flex flex-col'>
                                                    <div className={`${whatsApp?.data === null ? "invisible" : 'w-[510px] flex justify-between'}`}>
                                                        <p className='text-sm text-[#121212]'>Phone Number</p>
                                                        <p className='text-sm text-[#121212]'>Status</p>
                                                    </div>
                                                    {whatsApp?.data.length > 0 ? 
                                                        whatsApp?.data.map((item, index) => (
                                                            <div className={`${item === null ? "invisible" : 'w-[540px] flex justify-between'}`}  key={item?.id}>
                                                                <div className='flex flex-col gap-1'>
                                                                    {/* <p className='text-sm text-[#121212]'>Phone Number</p> */}
                                                                    <div className='flex items-center gap-1'> 
                                                                        <img src={connect ? Green : Red} alt='dot' className="w-[9px] h-[9px]" />
                                                                        <p className='text-BLACK-_100 text-sm'>{`+${item?.phone_number}`}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='flex flex-col gap-1'>
                                                                    {/* <p className='text-sm text-[#121212]'>Status</p> */}
                                                                    <div className={`${item === null ? "invisible" : 'flex cursor-pointer items-center gap-1'}`}  > {/* onClick={() => selectOption()} */}
                                                                        {/* <img src={connect ? Connect : Disconnect} alt='dot' className="w-[10px] h-[10px]" /> */}
                                                                        {/* <p className={`${connect ? "text-[#057601]" : "text-RED-_100"} text-xs`}>{connect ? "Connect" : "Disconnect"}</p> */}
                                                                        <img src={item.status === "connecting" ? Disconnect : Connect} alt='dot' className="w-[10px] h-[10px]" />
                                                                        <p className={`${item.status === "connecting" ? "text-RED-_100" : "text-[#057601]"} text-xs`}>{item.status}</p>
                                                                    </div>
                                                                </div>
                                                                                                                    
                                                            </div>
                                                        ))
                                                        : null
                                                    }

                                                </div>
                                            }                                           
                                        </div>
                                        

                                    </Form>
                                )}
                            </Formik>
                            
                        </div>
                    )}
                </div>
            </div>

            <div className='mt-6 mb-8'>
                <div 
                    className={`${showDeployDropDown ? "shadow-sm h-[330px]" : "h-[50px] "} border border-solid border-BLACK-_400 p-3 rounded-lg`}  
                    style={{backgroundColor: "#fff" }} //
                >
                    <div className="items-center cursor-pointer  flex justify-between mx-2" onClick={() => deployDropDown()}>
                        <p style={{color: `${showDeployDropDown ?  "#121212" : "#121212"}`}} className="text-base font-medium">Deploy Other Assistant Now</p>
                        <img src={showDeployDropDown ? ArrowUp : ArrowDown} alt="arrow-down" />
                    </div>
                    {showDeployDropDown && (
                        <div className="flex mt-3.5 mx-2">
                            <Formik
                                initialValues={{
                                    number: "",
                                }}
                                // validationSchema={deployFormValidationSchema}
                                onSubmit={(values, action) => {
                                    window.scrollTo(0, 0)
                                    submitDeployForm2(values)
                                    // setDeployAgent(true)
                                    // setOpenDeployModal(true)
                                    action.resetForm()
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    dirty,
                                    isValid,
                                    setFieldValue,
                                    errors,
                                    touched,
                                    // setFieldTouched,
                                    values,
                                }) => (
                                    <Form onSubmit={handleSubmit} className="flex flex-col items-center">
                                        <div className= "flex flex-col gap-3">

                                            <div className='w-[724px]'>
                                                <p className='text-sm text-BLACK-_500'>Ready to Deploy? We require 3 things from you for us to complete your assistants deployment to WhatsApp:</p>
                                            </div>

                                            <ul className='list-decimal ml-5 mt-2'>
                                                <li className='text-sm text-[#828282]'>An Active Facebook Account for your Business</li>
                                                <li className='text-sm text-[#828282] mt-2'>A number that hasn’t been registered to WhatsApp before</li>
                                                <li className='text-sm text-[#828282] mt-2'>An SMS OTP Code to verify the number provided.</li>
                                            </ul>

                                            <div className='flex items-center mt-[17px] gap-3'>
                                                <div className="flex flex-col  ">
                                                    <label htmlFor='number' className="text-xs font-medium text-[#101828]">Enter New Number</label>

                                                    <div className='flex w-[420px] rounded-lg border-[#D0D5DD] border h-[38px] bg-WHITE-_100 border-solid mt-1.5'>
                                                        <select name="number" id="" className='text-xs border-t-0 m-0.5 border border-l-0 border-b-0 outline-none '>
                                                            <option>NGN(+234)</option>
                                                        </select>

                                                        <input
                                                            name="number"
                                                            placeholder=""
                                                            type="text" 
                                                            value={values.number}
                                                            onChange={handleChange}
                                                            className="  outline-none w-[308px] border-none p-3 "
                                                        />
                                                    </div>
                                                </div>
                                        
                                                <button 
                                                    className="w-[141px] h-[38px] bg-BLACK-_500 rounded-lg px-2 mt-5 py-1"
                                                    type="submit"
                                                >
                                                    {loading2 ?
                                                        <CgSpinner className=" animate-spin text-lg text-WHITE-_100 mx-auto " />
                                                    :
                                                        <p className='text-WHITE-_100 text-xs'>Deploy to Whatsapp</p>
                                                    }
                                                </button>
                                            </div>
                                            {/* {errors.number && touched.number ? (
                                            <div className='text-RED-_100'>{errors.number}</div>
                                            ) : null} */}

                                            {whatsApp?.data?.length > 0 && 
                                                <div className='flex flex-col'>
                                                    <div className={`${whatsApp?.data === null ? "invisible" : 'w-[510px] flex justify-between'}`}>
                                                        <p className='text-sm text-[#121212]'>Phone Number</p>
                                                        <p className='text-sm text-[#121212]'>Status</p>
                                                    </div>
                                                    {whatsApp?.data.length > 0 ? 
                                                        whatsApp?.data.map((item, index) => (
                                                            <div className={`${item === null ? "invisible" : 'w-[540px] flex justify-between'}`}  key={item?.id}>
                                                                <div className='flex flex-col gap-1'>
                                                                    {/* <p className='text-sm text-[#121212]'>Phone Number</p> */}
                                                                    <div className='flex items-center gap-1'> 
                                                                        <img src={connect ? Green : Red} alt='dot' className="w-[9px] h-[9px]" />
                                                                        <p className='text-BLACK-_100 text-sm'>{`+${item?.phone_number}`}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='flex flex-col gap-1'>
                                                                    {/* <p className='text-sm text-[#121212]'>Status</p> */}
                                                                    <div className={`${item === null ? "invisible" : 'flex cursor-pointer items-center gap-1'}`}  > {/* onClick={() => selectOption()} */}
                                                                        {/* <img src={connect ? Connect : Disconnect} alt='dot' className="w-[10px] h-[10px]" /> */}
                                                                        {/* <p className={`${connect ? "text-[#057601]" : "text-RED-_100"} text-xs`}>{connect ? "Connect" : "Disconnect"}</p> */}
                                                                        <img src={item.status === "connecting" ? Disconnect : Connect} alt='dot' className="w-[10px] h-[10px]" />
                                                                        <p className={`${item.status === "connecting" ? "text-RED-_100" : "text-[#057601]"} text-xs`}>{item.status}</p>
                                                                    </div>
                                                                </div>
                                                                                                                    
                                                            </div>
                                                        ))
                                                        : null
                                                    }

                                                </div>
                                            }                                           
                                        </div>
                                        

                                    </Form>
                                )}
                            </Formik>
                            
                        </div>
                    )}
                </div>
            </div>

            <button 
                className="w-[94px] h-[43px] border border-WHITE-_300 mt-[40px] mb-14 rounded-lg px-2 mt-5 py-1"
                type="button"
                onClick={() => navigate(-1)}
            >
                <p className='text-[#0359D8] text-sm font-medium'>Previous</p>
            </button>
        </div>

        

        <ModalPop isOpen={openModal}>
            <PhoneVerify handleClose={() => setOpenModal(false)} />
        </ModalPop>

        <ModalPop isOpen={openDeployModal}>
            <PhoneVerify handleClose={() => setOpenDeployModal(false)} deployAgent={deployAgent}/>
        </ModalPop>
        
        <ModalPop isOpen={otp}>
            <Otp handleClose={() => setOtp(false)}/>
        </ModalPop>


    </DashboardLayout>
  )
}

export default Deploy
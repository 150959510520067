import React, {
  useEffect,
  useMemo,
  useContext,
  useCallback,
  useState,
} from "react";
import axios from "axios";
import DashboardLayout from "../layout/DashboardLayout";
import SideBar from "../AskAgent/SideBar";
import Maincontent from "../AskAgent/Maincontent";
import Chatcontent from "../AskAgent/ChatContent";
import { UserContext } from "../context/UserContext";
import { useDispatch, useSelector } from "react-redux";
import ModalPop from "../component/modalPop";
import { TopGuide } from "../component/modals/agentGuide/Guide";
import { useLocation, useNavigate } from "react-router";
import AgentC from "../Assets/svg/agent4.svg";
import TokenService from "../services/token";
import MainAskAgent from "../component/MainAskAgent";
import Spinner from "component/Spinner";
import {
  getCurrentEmployee,
  updateAgentModals,
} from "@/../features/modals/agentModalsSlice";
import { getAllPlugins } from "@/../features/plugins/getAllPluginsSlice";
import { getInbox } from "../features/chat/businessAskAgentChatSliceH";

export default function AskAgent({gotoMemory}) {
  const token = TokenService.getToken();
  const [data, setData] = useState([]);
  const [section, setSection] = useState({ type: "new", id: "" });
  const [done, setDone] = useState(true);
  const [trig, setTrig] = useState("nill");
  const [inboxChat, setInboxChat] = useState(null);
  const [isDataM, setIsDataM] = useState(false);
  const [openTourModal, setOpenTourModal] = useState(false);
  const URL = process.env.REACT_APP_BASEURL;
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const { inbox } = useSelector((state) => state.askAgentChatH);
  const getdata = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = getdata && getdata.length >= 1 ? getdata[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;

  const { userType } = useContext(UserContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //monitors the tags in the instructionManual
  const [tags, setTags] = useState([]);
  useEffect(() => {
    dispatch(getAllPlugins({ applicationId }));
  }, [dispatch]);

  //Check if the user has created an Agent
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const appId = getAgentApplication.agent.id;
      dispatch(getInbox({ appId }));
    }, 10000); // 30 seconds in milliseconds
    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array to run only once on component mount

  const { loading } = useSelector((state) => state?.getApplicationByUserId);
  const agentType = getAgentApplication?.data?.data[0]?.type || null;
  useEffect(() => {
    if (agentType === null) {
      navigate("/dashboard");
    }
  }, [agentType]);
  //end

  const fetchChat = useSelector((state) => state.getAgentChat);
  // console.log(fetchChat, "this is fetchChat from AskAent")
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader(true);

        const response = await axios.get(
          `${URL}/dashboard/applications/${applicationId}/memory`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(applicationId, "this is application/agent Id from ask agent")
        // console.log(response.data.data,'response.data.dataresponse.data.data from ask agent')
        if (response.data.data.length > 0) {
          setData(fetchChat?.data?.data);
          setIsDataM(true);
        } else {
          //added this to change the state of IsDataM and set Data to []
          setIsDataM(false);
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    };
    fetchData();
  }, [loading, applicationId, dispatch]); //added dependency applicationId

  const handleChangeSection = useCallback((contentName) => {
    // Your handleChangeSection logic here

    console.log("content name is", contentName);
    if (contentName.isInbox) {
      // if it has exisitinf conversation
      if (contentName.existingConversation) {
        setSection(contentName);
        setTrig(contentName.id);
      } else {
        // clear chat

        setSection(contentName);

        // GENERATE NEW CHAT ID
        // setInboxChat(newMessage);
      }
    } else {
      setSection(contentName);
      setTrig(contentName.id);
    }
  }, []);

  const location = useLocation();

  // const guided = localStorage.getItem("Ask agent");

  // useEffect(() => {
  //   if (location.pathname === "/ask-agent" && guided !== "Guided") {
  //     setOpenTourModal(true);
  //   } else {
  //     setOpenTourModal(false);
  //   }
  // }, []);

  const { id } = useSelector((state) => state.getApplicationByUserId.agent);
  const config = useSelector((state) => state.agentModals.employeeModals);

  const getAskAgentGuide = config?.ask_agent;

  useEffect(() => {
    if (location.pathname === "/ask-agent" && getAskAgentGuide) {
      setOpenTourModal(true);
    } else {
      setOpenTourModal(false);
    }
  }, [getAskAgentGuide, location.pathname]);

  const handleAgentModal = (name) => {
    const currentModalState = config[name];
    if (currentModalState) {
      const modalsVisibility = { ...config, [name]: false };
      dispatch(updateAgentModals({ modalsVisibility, id: id }))
        .then((res) => {
          dispatch(getCurrentEmployee({ id: id }));
        })
        .catch((error) => {
          console.error("Failed to update modal state:", error);
        });
    }
  };

  // Memoize props to prevent unnecessary re-renders
  const memoizedMainContentProps = useMemo(
    () => ({
      handleChangeSection,
      section,
      done,
      data,
      trig,
      setTrig,
      tags,
      setTags,
    }),
    [handleChangeSection, section, done, data, trig, tags]
  );
  // Memoize props to prevent unnecessary re-renders
  const memoizedChatContentProps = useMemo(
    () => ({
      data,
      setDone,
      done,
      section,
      trig,
      setTrig,
      tags,
      setTags,
      inboxChat,
      setInboxChat,
      setSection,
    }),
    [data, setDone, done, section, trig, tags, inboxChat, setSection]
  );

  // Conditional rendering for MainContent and ChatContent based on section.type and isDataM
  let contentComponent;
  if (section.type === "new") {
    contentComponent = loader ? (
      <Spinner />
    ) : !isDataM ? (
      <MainAskAgent gotoMemory={gotoMemory} />
    ) : (
      <Maincontent {...memoizedMainContentProps} />
    );
  } else {
    contentComponent = <Chatcontent {...memoizedChatContentProps} />;
  }

  return (
    <DashboardLayout
      title={"Ask your Digital Assistant"}
      user={true}
      pad={true}
      height={true}
    >
      <section className="flex  h-full">
        {/* {section.type === 'new' ? //changed equality
          
              <div className="w-full">
                {!isDataM ? 
                  <MainAskAgent /> 
                    : 
                  <Maincontent handleChangeSection={handleChangeSection} section={section} done={done} data={data} trig={trig} setTrig={setTrig}/>
                }
              </div>
            : 
              <div className="w-full">
                <Chatcontent data={data} setDone={setDone} done={done} section={section} trig={trig} setTrig={setTrig}/>
              </div>
            }   */}
        <div className="w-full">{contentComponent}</div>

        <aside className="w-[300px] mt-4  h-full">
          {!!inbox?.filter((each) => each.is_read === false).length && (
            <div className="px-3 py-2 text-BLUE-_200 bg-BLUE-50 text-center text-xs">
              You have {inbox?.filter((each) => each.is_read === false).length}{" "}
              unread message{inbox?.length > 1 ? "s" : ""} check your inbox
            </div>
          )}

          <div className="px-[30px]">
            <SideBar
              isDataM={isDataM}
              done={done}
              setDone={setDone}
              handleChangeSection={handleChangeSection}
              trig={trig}
              setTrig={setTrig}
              setTags={setTags}
              setInboxChat={setInboxChat}
            />
          </div>
        </aside>
      </section>

      <ModalPop isOpen={openTourModal}>
        <TopGuide
          title="Ask Assistant"
          subtitle="Here you can ask me anything regarding your customers, how they
          behave, their problems, insights and even possible business ideas."
          link="Ask me Anything"
          handleClose={() => {
            handleAgentModal("ask_agent");
            setOpenTourModal(false);
            // localStorage.setItem("Ask agent", "Guided");
          }}
          close={true}
          image={AgentC}
        />
      </ModalPop>
    </DashboardLayout>
  );
}

import React, { Fragment, useEffect, useRef, useState } from "react";
import { ReactComponent as Banking } from "../../../Assets/svg/Banking.svg";
import { ReactComponent as Savings } from "../../../Assets/svg/Save.svg";
import { ReactComponent as Credits } from "../../../Assets/svg/cred.svg";
import { ReactComponent as Payment } from "../../../Assets/svg/pay.svg";
import { Sales, CustomerService, FeaturesIcon } from "../../../Assets/svg";
import InstallIcon from "../../../Assets/svg/install.svg";
import SetupIcon from "../../../Assets/svg/setup.svg";
import General from "../../../Assets/img/all 1.png";
import ModalPop from "../../../component/modalPop";
import FeatureModal from "./FeatureModal";
import ViewAgents from "./ViewAgents";
import axios from "axios";
import Spinner from "component/Spinner";
import TokenService from "../../../services/token";
import { CgSpinner } from "react-icons/cg";
import { useLocation, useNavigate } from "react-router";
import AgentModal from "./AgentModal";
import { postPluginById } from "../../../features/plugins/postPluginByIdSlice";
import { useDispatch, useSelector } from "react-redux";
import AddPlugin from "../../../component/modals/plugins/AddPlugin";
import { getPluginById } from "../../../features/plugins/getPluginByIdSlice";
import SetupModal from "./setupModal";
import { checkConfigType, highlightAtText } from "@/../helper";
import LanguageModal from "./LanguageModal";
import { Switch } from "@headlessui/react";
import UninstallPlugin from "@/../component/modals/plugins/UninstallPlugin";
import {
  ErrorMessage,
  SuccessMessage,
} from "@/../Toast-Messages/Toastmessages";
import { getPlugins } from "@/../features/plugins/getPluginsSlice";
import { getAllPlugins } from "@/../features/plugins/getAllPluginsSlice";
import { api } from "@/../services/api";

export default function OtherPluginsDetail({ data }) {
  // console.log("data is", data);
  const [viewFeature, setViewFeature] = useState(false);
  const [install, setInstall] = useState(false);
  const [setup, setSetup] = useState(false);
  const [viewAgents, setViewAgents] = useState(false);
  const [viewAgent, setViewAgent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [featureData, setFeatureData] = useState(null);
  const [isDone, setIsDone] = useState(false);
  const [languagesM, setLanguagesM] = useState(false);

  // console.log(data, "lalala")

  //added this data supplied to MyPlugins page to see editFeatures
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();
  const agentData = localStorage.getItem("wano_agent");
  // const agent = JSON.parse(agentData);
  // const applicationId = agent?.id
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const plugins = useSelector((state) => state.getPluginsData);
  const pluginsData = plugins?.data?.data?.filter(
    (item) => !item.should_create
  );
  const neededData = pluginsData?.filter((item) => item.id === data.id);
  //End of addition

  //added this
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const getdata = appDetail?.data?.data;
  // console.log(getdata, "this is getdata from other plugins")
  // const agentData = localStorage.getItem("wano_agent");
  // console.log(agentData, "this is agentData from other plugins");
  const agent = getdata && getdata.length >= 1 ? getdata[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const { state, setActiveButton } = useLocation();
  const applicationPluginId = state?.plugin?.id;
  //end of addition
  // console.log(state, "this is state from otherPluginsDetails")
  // const URL = process.env.REACT_APP_BASEURL;
  // const token = TokenService.getToken()
  const [selectedAgent, setSelectedAgent] = useState([]);
  // const navigate = useNavigate()

  // console.log(data, "hehe");
  const appId = JSON.parse(localStorage.getItem("wano_agent")); //added this line
  // const dispatch = useDispatch()
  // const applicationId = appId?.id;

  //just Added this
  const getAllPluginsData = useSelector((state) => state.getAllPlugins);
  // console.log(getAllPluginsData, "tags renderData getAllPluginsData");
  console.log("df", getAllPluginsData);
  const allPlugins = getAllPluginsData?.data.data?.available;
  const allActivePlugins = getAllPluginsData?.data.data?.active;
  // console.log(state, "tags renderData this is state")
  const uninstalledData = allPlugins?.find((plugin) => plugin.id === state.id);
  const installedData = allActivePlugins?.find(
    // (plugin) => plugin.id === state.id
    (plugin) => plugin.id === state.id
  );

  const renderData = uninstalledData ? uninstalledData : installedData;
  // console.log(data, "tags data")
  // console.log(installedData, "tags neededData")
  // console.log(renderData, "tags renderData")
  const id = renderData?.applicationPlugins[0]?.id;

  const isSetup = renderData?.require_setup;

  const hasConfig = renderData.applicationPlugins.some((ap) => ap.configured);
  // console.log(renderData, hasConfig, "hasConfig");

  const isPluginInstalled = renderData?.applicationPlugins?.some((plugin) =>
    plugin?.features?.some((feature) => feature.notification_url)
  );
  // console.log(isPluginInstalled, "is plugin installed or not installed")

  const [pluginsNotificationSuccess, setPluginsNotificationSuccess] =
    useState(false);
  const [pluginsNotificationError, setPluginsNotificationError] =
    useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [uninstallPlugin, setUninstallPlugin] = useState(false);
  const [uninstallPluginLoading, setUninstallPluginLoading] = useState(false);
  const [loading1, setLoading1] = useState(true);
  // console.log(page, "tags renderData page");

  useEffect(() => {
    dispatch(getPlugins({ applicationId }))
      .then((res) => {
        // console.log(res, "tags renderData res");
        setLoading1(false);
      })
      .catch((err) => {
        console.log(err, "tags renderData");
        setLoading1(false);
      });
  }, []); //dispatch, applicationId, page, renderData?.id
  const [toggling, setToggling] = useState(false);
  const togglePlugin = async (item) => {
    // console.log(item,"item")
    try {
      setToggling(true);

      const res = await axios.put(
        `${URL}/dashboard/applications/${applicationId}/plugins/${item?.applicationPlugins[0]?.id}/status`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(res, "tags renderData res");
      const pluginsRes = await dispatch(getPlugins({ applicationId }));
      // console.log(pluginsRes, "tags renderData pluginsRes");
      setPluginsNotificationSuccess(true);
      await dispatch(getPluginById({ applicationId, applicationPluginId }));
      await dispatch(getAllPlugins({ applicationId }));
      // .then(() => {
      //     const res = await dispatch(getPlugins({ applicationId }))
      //     console.log(res, "tags renderData res");
      //     setData(res?.payload)
      //     setPluginsNotificationSuccess(true)
      // }).then(() => {
      //     dispatch(getPluginById({ applicationId, applicationPluginId }))
      //     dispatch(getAllPlugins({ applicationId }));
      // })
      // setPluginsNotificationSuccess(true)
      // window.location.reload()
      // const applicationPluginId = item?.id
      // dispatch(getAllPlugins({ applicationId })).then(() => {
      //     dispatch(updatePlugins({ applicationId, applicationPluginId } ))
      // }).then(() => dispatch(getPlugins({ applicationId })))
    } catch (error) {
      setPluginsNotificationError(true);
    } finally {
      setToggling(false);
    }
  };

  const removePluginsNotificationSuccess = () => {
    setTimeout(() => {
      setPluginsNotificationSuccess(false);
    }, 1000);
  };

  const removePluginsNotificationError = () => {
    setTimeout(() => {
      setPluginsNotificationError(false);
    }, 1000);
  };

  useEffect(() => {
    removePluginsNotificationSuccess();
  }, [pluginsNotificationSuccess]);

  useEffect(() => {
    removePluginsNotificationError();
  }, [pluginsNotificationError]);

  const renderActionButton = () => {
    if (hasConfig || isPluginInstalled) {
      return (
        // <button
        //   className={`${
        //     renderData?.published ? "" : ""
        //   } cursor-pointer flex gap-3 outline-none py-[10px] w-auto px-5 bg-zinc-100 rounded-lg text-xs font-semibold`}
        // >
        //   <p className="text-[#009733] text-sm">Installed</p>
        // </button>
        <>
          {loading1 ? (
            <div></div>
          ) : (
            <div className="flex gap-3 items-center">
              {toggling ? (
                <div className="w-2 h-2 mr-2">
                  <Spinner />
                </div>
              ) : (
                <Switch
                  checked={renderData?.applicationPlugins[0]?.published}
                  onChange={() => togglePlugin(renderData)}
                  as={Fragment}
                >
                  {({ checked }) => (
                    <button
                      className={`${
                        checked ? "bg-blue-600" : "bg-gray-200"
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${
                          checked ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </button>
                  )}
                </Switch>
              )}

              <button
                onClick={() => setUninstallPlugin(true)}
                className="bg-[#EEEEEE] cursor-pointer outline-none border border-[#EEE]  w-[129px] h-[43px] flex items-center justify-center rounded-lg text-[#AF202D] text-sm font-medium"
              >
                {uninstallPluginLoading ? (
                  <CgSpinner className="text-RED-_100 animate-spin text-lg" />
                ) : (
                  "Uninstall App"
                )}
              </button>
            </div>
          )}
        </>
      );
    } else if (installedData) {
      return (
        <button
          onClick={() => handleButton()}
          className="cursor-pointer flex items-center gap-2.5 outline-none py-[10px] w-auto px-5 bg-[#121212] rounded-lg text-white text-xs font-semibold"
        >
          <img src={SetupIcon} alt="Setup" />
          <p className="text-sm font-semibold">Setup</p>
        </button>
      );
    }
    return (
      <button
        onClick={() => {
          setInstall(true);
        }}
        className="cursor-pointer flex gap-3 outline-none py-[10px] w-auto px-5 bg-[#121212] rounded-lg text-white text-xs font-semibold"
      >
        <img src={InstallIcon} alt="Install" />
        <p>Install</p>{" "}
        {/* Link to Agent onClick={() => setViewAgents(!viewAgents)} */}
      </button>
    );
  };

  const handleButton = () => {
    if (isSetup && !renderData.require_configuration_setup) {
      fetchPluginAgentFeatures(renderData?.application.id);
      setViewAgent(!viewAgent);
      setSelectedAgent(renderData.application);
    } else {
      // setState(() => )
      setSetup(true);
    }
  };
  // Function to transform 'how_it_works' into an array of steps
  const parseHowItWorks = (howItWorks) => {
    return howItWorks.split("\n").filter((line) => line.trim() !== "");
  };

  const checkName = (name) => {
    switch (name) {
      case "Slack":
        return { labelA: "Token", labelB: "Channel ID" };
      case "Hubspot":
        return { labelA: "Base URL", labelB: "Api Key" };
      case "Calendly":
        return { labelA: "Api Key" };
      // case "Gmail": return {labelA: "", labelB: ""};
      default:
        return null;
    }
  };

  // const disconnectPlugin = async () => {
  //   setLoading(true);
  //   try {
  //     const res = await axios.delete(
  //       `${URL}/dashboard/plugins/${state.plugin.id}/remove`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setLoading(false);
  //     dispatch(getPluginById({ applicationId, applicationPluginId }));
  //     navigate("/plugins");
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  const fetchPluginAgentFeatures = async (agentId) => {
    //changed from data to renderData
    sessionStorage.setItem("", data?.id);
    const res = await axios.get(
      `${URL}/dashboard/plugins/${state?.id}/application/${agentId}/features`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    setFeatureData(res.data.data);
    sessionStorage.setItem(
      "plugin_id",
      res.data.data[0].application_module?.id
    );
    // console.log(res.data.data, "this is feature data")
  };

  // console.log(data, "hehehehehehe");
  // console.log(renderData, isInstalled, "tags button");

  useEffect(() => {
    localStorage.setItem("pluginState", "All Plugins");
  }, []);

  return (
    <div className="">
      {/* {renderData?.name === "Credit" ? (
        <Credits />
      ) : renderData?.name === "Savings" ? (
        <Savings />
      ) : renderData?.name === "Bill Payment" ? (
        <Payment />
      ) : (
        <Banking />
      )} */}
      <img
        src={renderData?.image_url}
        className="h-[65px] w-[65px]"
        alt={renderData?.name}
      />
      <div className="flex items-center justify-between mt-[24px]">
        <p className="text-[#101828] font-medium text-[24px]">
          {renderData?.name}
        </p>
        {/* /{renderData?.applicationPlugins[0]?.published || */}
        {/* renderData?.application ? ( */}
        {/* <p className='text-[#009733] text-lg font-medium'>Installed</p> */}
        <div className="flex gap-2.5">
          {/* {" "} */}
          {renderActionButton()}
        </div>
        {/* handles setup and uninstall */}
        {/* {isSetup && (!isPluginInstalled) ? (
              renderData?.require_configuration_setup? (
                <button
                  onClick={() => {
                    setSetup(true)
                    // fetchPluginAgentFeatures(renderData?.application.id);
                    // setViewAgent(!viewAgent);
                    // setSelectedAgent(renderData.application);
                    // setState(() => )
                  }}
                  className="cursor-pointer flex items-center gap-2.5 outline-none py-[10px] w-auto px-5 bg-[#121212] rounded-lg text-white text-xs font-semibold"
                >
                  <img src={SetupIcon} alt="Setup" />
                  <p className="text-sm font-semibold">Setup</p>
                </button>
              ) : (
                <button
                  onClick={() => {
                    fetchPluginAgentFeatures(renderData?.application.id);
                    setViewAgent(!viewAgent);
                    setSelectedAgent(renderData.application);
                    // setState(() => )
                  }}
                  className="cursor-pointer flex items-center gap-2.5 outline-none py-[10px] w-auto px-5 bg-[#121212] rounded-lg text-white text-xs font-semibold"
                >
                  <img src={SetupIcon} alt="Setup" />
                  <p className="text-sm font-semibold">Setup</p>
                </button> 
              )
            ) : (
              <button
                className={`${
                  renderData?.published ? "" : ""
                } cursor-pointer flex gap-3 outline-none py-[10px] w-auto px-5 bg-zinc-100 rounded-lg text-xs font-semibold`}
              >
                <p className="text-[#009733] text-sm">Installed</p>
              </button>
            )}
          </div>
        ) : (
          <button
            onClick={() => {
              setInstall(true);
            }}
            className="cursor-pointer flex gap-3 outline-none py-[10px] w-auto px-5 bg-[#121212] rounded-lg text-white text-xs font-semibold"
          >
            <img src={InstallIcon} alt="Install" />
            <p>Install</p>{" "}
            {/* Link to Agent onClick={() => setViewAgents(!viewAgents)} 
          </button>
        )} */}
      </div>

      <div className="flex w-full gap-6 mt-[30px]">
        <div
          className={`${
            renderData?.application?.length > 0 ? "hidden" : "flex flex-col"
          } flex-1 h-auto border-[0.5px] border-t-0 border-l-0 border-r-0 pb-[24px] border-b gap-4`}
        >
          <p className="text-[#121212] text-xl font-medium">About App</p>
          {/* <p className="text-sm text-[#7F7F81]">{renderData?.about}</p> */}
          <p
            className="text-sm text-[#7F7F81]"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {renderData?.about}
          </p>
          {/* {!renderData?.require_configuration_setup? ( */}
          {renderData?.require_configuration_setup &&
          renderData?.name?.includes("Language") ? (
            <div className="flex-1 flex items-end">
              <div
                // onClick={() => setViewFeature(!viewFeature)} w-[169px]
                onClick={() => setLanguagesM(true)}
                className="py-3 px-4 flex gap-2 items-center rounded-[8px] border border-[#EEE] bg-[#FAFAFA] w-fit cursor-pointer"
              >
                <FeaturesIcon />
                <p className="text-sm font-medium text-[#0359D8] font-figtree">
                  {/* View All Features */} View All Languages
                </p>
              </div>
            </div>
          ) : null}
        </div>
        {/* <div className={`${data?.applications?.length > 0 ? 'flex-1 flex' : 'hidden'} flex-col gap-4`}>
                    <h2 className='text-[18px] font-medium text-[#121212]'>Linked Agents</h2>
                    <div className='w-full flex flex-wrap gap-6'>
                        {data?.applications?.map((agent, index) => (
                            <div key={index} onClick={() => {fetchPluginAgentFeatures(agent.id); setViewAgent(!viewAgent); setSelectedAgent(agent)}} className='cursor-pointer w-[35%] flex flex-col gap-[10px] border border-[#E5E5E5] rounded-xl py-[18px] px-4'>
                                <div className='w-full justify-between flex items-center'>
                                    <div className='flex gap-[10px] items-center'>
                                        {agent.icon_url ? <img src={agent.icon_url} alt='icon' /> :
                                            <div className='w-5 h-5 bg-[#1774FD] rounded-lg flex justify-center items-center'>
                                                <p className='capitalize text-white text-[9px] font-medium'>
                                                    {agent?.name?.charAt(0)}
                                                </p>
                                            </div>
                                        }
                                        <p className='text-[16px] font-medium text-[#121212]'>{agent.name}</p>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect x="0.599609" width="23.4" height="23.4" rx="3.51" fill="#FBFBFB"/>
                                        <path d="M9.95703 14.0414L15.807 8.19141M15.807 8.19141H9.95703M15.807 8.19141V14.0414" stroke="black" stroke-width="1.17" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <p className='text-xs text-[#7F7F81]'>{agent.description}</p>
                            </div>
                        ))}
                    </div>
                </div> */}

        {/* <div className='bg-[#FAFAFA] w-[423px] p-[24px] flex gap-5 flex-col rounded-lg'>
                    <p className='text-[18px] text-[#121212] font-medium'>Compatibility</p>
                    <p className='text-sm text-[#101828]'>This plugin is suited for businesses that offer Banking/Financial services and can be used by any of the following AI Employee categories:</p>
                    <div className='flex justify-between w-full'>
                        {
                            data?.plugin?.compatible_with?.map((item, index) => {
                                return (
                                    <div key={index} className='flex flex-col'>
                                        <div 
                                            className="w-[31.32px] h-[31.32px] bg-WHITE-_100 border border-[#E5E5E5] rounded-lg flex cursor-pointer justify-center items-center p-2" 
                                        >
                                            {item === 'sale' && <Sales />}
                                            {item === 'general' && 
                                                <img src={General} alt='ai'/>
                                            }
                                            {item === 'customer' && <CustomerService />}
                                        </div>
                                        <p className='text-sm text-left text-[#121212] mt-[10px] font-medium'><span className='capitalize'>{item}</span> A.I</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div> */}
      </div>
      {renderData?.applications?.length > 0 && (
        <hr className="my-8 bg-[#F7F7F7] w-[672px]" />
      )}
      {renderData?.applications?.length > 0 && (
        <div className="flex flex-col w-[672px] pb-[24px] gap-4">
          <p className="text-[#121212] text-xl font-medium">About App</p>
          <p className="text-sm text-[#7F7F81] leading-[25.2px]">
            {renderData.plugin.about}
          </p>
          <div
            onClick={() => setViewFeature(!viewFeature)}
            className="py-3 px-4 flex gap-2 items-center rounded-[8px] border border-[#EEE] bg-[#FAFAFA] w-[169px] cursor-pointer"
          >
            <FeaturesIcon />
            <p className="text-sm font-medium text-[#0359D8] font-figtree">
              View All Features
            </p>
          </div>
        </div>
      )}
      <div className="w-[839px] mt-[24px] mb-[100px]">
        {/* w-[672px] name === "Web Search"*/}
        {renderData?.require_configuration_setup ? (
          <>
            <div className="flex flex-col gap-4 mb-4">
              <p className="text-xl text-[#121212] font-medium">
                How to Use App
              </p>
            </div>
            {/*<div className="leading-[25.2px]">
              <p className="text-sm text-[#7F7F81] leading-[25.2px]">
                1. Install the Application.
              </p>
              <p className="text-sm text-[#7F7F81] leading-[25.2px]">
                2. While interacting with your Employee on the
            Ask Employee Page tag the application using <span className="text-[#1774FD] font-medium">“@websearch”</span> to trigger
            it.
              </p>
              <p className="text-sm text-[#7F7F81] leading-[25.2px]">
                3. You could also use secondary triggers by using words like {" "}<span className="text-[#1774FD] font-medium">“Search online”</span>, <span className="text-[#1774FD] font-medium">“Google”</span> or <span className="text-[#1774FD] font-medium">“Search the internet”</span> with your
            request.
              </p>
              <p className="text-sm text-[#7F7F81] leading-[25.2px]">
                4.  Using the tag will allow your employee to use the
            application to search the internet
              </p>
            </div> */}
            <div>
              <ol>
                {parseHowItWorks(renderData?.how_it_works).map(
                  (step, index) => (
                    <li
                      key={index}
                      className="text-sm text-[#7F7F81] leading-[25.2px]"
                    >
                      {step}
                    </li>
                  )
                )}
              </ol>
            </div>
            <div className="flex flex-col gap-4 mt-[24px] mb-4">
              <p className="text-xl text-[#121212] font-medium">Triggers</p>
            </div>
            <div>
              {/* <p className="text-sm text-[#7F7F81] leading-[25.2px]">hallelujah</p> */}
              {/* <p className="text-sm text-[#7F7F81]" style={{ whiteSpace: 'pre-wrap' }}>{renderData?.trigger}</p>
              <p className="text-sm text-[#7F7F81] leading-[25.2px]">
                You can also tag this application to your Instructions for your employee. You can tag by using {" "}<span className="text-[#1774FD] font-medium">@{renderData?.name.toLowerCase()}</span> in your instruction.
              </p> */}
              <TextWithHighlights text={renderData?.trigger} />
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col gap-4 mb-4">
              <p className="text-xl text-[#121212] font-medium">
                How to Integrate
              </p>
            </div>
            <div>
              {/* <p className="text-sm text-[#7F7F81] leading-[25.2px]"> 
                1. Select employees you want to link employee to
              </p>
              <p className="text-sm text-[#7F7F81] leading-[25.2px]">
                2. Configure your app by activating features that fit your
                business’s use case
              </p>
              <p className="text-sm text-[#7F7F81] leading-[25.2px]">
                3. Provide a webhook notification URL for each activated
                feature.
              </p>
              <p className="text-sm text-[#7F7F81] leading-[25.2px]">
                4. Save app configurations
              </p> */}
              <ol>
                {parseHowItWorks(renderData?.how_it_works).map(
                  (step, index) => (
                    <li
                      key={index}
                      className="text-sm text-[#7F7F81] leading-[25.2px]"
                    >
                      {step}
                    </li>
                  )
                )}
              </ol>
            </div>
          </>
        )}
      </div>
      <ModalPop isOpen={viewFeature}>
        <FeatureModal handleClose={() => setViewFeature(false)} data={data} />
      </ModalPop>
      <ModalPop isOpen={viewAgents}>
        <ViewAgents data={data} handleClose={() => setViewAgents(false)} />
      </ModalPop>
      <ModalPop isOpen={languagesM}>
        <LanguageModal data={data} handleClose={() => setLanguagesM(false)} />
      </ModalPop>

      {/* <ModalPop isOpen={install}>
                <UninstallPlugin handleClose={() => setInstall(false)} data={data}/>
            </ModalPop> */}

      <ModalPop isOpen={install}>
        <AddPlugin
          handleClose={() => {
            setInstall(false);
          }}
          data={data}
          setter={setSetup}
        />
      </ModalPop>

      <ModalPop isOpen={setup}>
        <SetupModal
          data={renderData}
          handleClose={() => setSetup(false)}
          labelA={checkName(data?.name)?.labelA}
          labelB={checkName(data?.name)?.labelB}
          // done={() => setIsDone(true)}
        />
      </ModalPop>

      <ModalPop isOpen={viewAgent}>
        <AgentModal
          state={neededData}
          featureData={featureData}
          handleClose={() => {
            setViewAgent(false);
          }} // navigate('/plugins')
          selectedAgent={selectedAgent}
          fetchFeatures={fetchPluginAgentFeatures}
          singleAgent={true}
        />
      </ModalPop>
      <ModalPop isOpen={uninstallPlugin}>
        <UninstallPlugin
          handleClose={() => setUninstallPlugin(false)}
          token={token}
          state={renderData}
        />
      </ModalPop>
      {pluginsNotificationSuccess && (
        <SuccessMessage
          Formodal={true}
          success={message}
          className="mt-28 mr-12"
        />
      )}
      {pluginsNotificationError && (
        <ErrorMessage Formodal={true} error={error} className="mt-28 mr-12" />
      )}
    </div>
  );
}

export const TextWithHighlights = ({ text }) => {
  return (
    <p className="text-sm text-[#7F7F81]" style={{ whiteSpace: "pre-wrap" }}>
      {highlightAtText(text)}
    </p>
  );
};

{
  /* <button className="bg-[#EEEEEE] cursor-pointer outline-none border border-[#EEE] py-[13px] w-auto px-4 rounded-lg text-[#AF202D] text-sm font-medium">
                            Uninstall App
                        </button> */
}

{
  /* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path d="M6.66667 2.5H2V7.16667H6.66667V2.5Z" stroke="#0359D8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M14.0007 2.5H9.33398V7.16667H14.0007V2.5Z" stroke="#0359D8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M14.0007 9.83203H9.33398V14.4987H14.0007V9.83203Z" stroke="#0359D8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.66667 9.83203H2V14.4987H6.66667V9.83203Z" stroke="#0359D8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> */
}

{
  /* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                d="M6.66667 2.5H2V7.16667H6.66667V2.5Z"
                stroke="#0359D8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.0007 2.5H9.33398V7.16667H14.0007V2.5Z"
                stroke="#0359D8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.0007 9.83203H9.33398V14.4987H14.0007V9.83203Z"
                stroke="#0359D8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.66667 9.83203H2V14.4987H6.66667V9.83203Z"
                stroke="#0359D8"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg> */
}

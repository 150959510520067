import { useState } from "react";
import { getConversations } from "features/customer/customerSlice";
import { ServiceFeaturesCard } from "../../LoadingSkeleton";
import skeletonImage from "Assets/svg/skeletonImage.svg";
import blueDot from "Assets/svg/blueDot.svg";
import greenDot from "Assets/svg/greenDot.svg";
import orangeDot from "Assets/svg/orangeDot.svg";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

function CustomerConversations() {
  const [activeButton, setActiveButton] = useState("All");
  const [errorHeader, setErrorHeader] = useState("No Conversations");
  const [errorBody, setErrorBody] = useState(
    "Your employee has not had any conversations yet"
  );

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    dispatch(
      getConversations({
        applicationId,
        page: currentPage,
        per_page: 20,
        status: getStatusType(buttonName),
      })
    );
    if (buttonName === "Resolved") {
      setErrorHeader(`No ${getStatusType(buttonName)} Conversations`);
      setErrorBody("You have not resolved any conversations yet");
    } else {
      setErrorHeader("No Conversations");
      setErrorBody("Your employee has not had any conversations yet");
    }
  };
  const { conversations, conversationPagination, loading } = useSelector(
    (state) => state.getCustomers
  );
  // console.log(page, "lado")
  const [currentPage, setCurrentPage] = useState(
    conversationPagination?.currentPage
  );
  const totalPages = conversationPagination?.totalPages; // Total number of pages
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const newAgent = getAgentApplication?.agent || null;

  const applicationId = newAgent.id;
  const dispatch = useDispatch();

  const handlePageChange = (e) => {
    setCurrentPage(Number(e.target.value));
    dispatch(
      getConversations({ applicationId, page: e.target.value, per_page: 20 })
    );
  };
  const TableRow = ({ customer, email, id, status, conversationDate }) => {
    const navigate = useNavigate();

    const handleClick = () => {
      navigate(`/customers/conversations/${id}`);
    };
    function formatISODate(isoDate = conversationDate) {
      // Create a new Date object from the ISO 8601 date string
      let date = new Date(isoDate);

      // Helper function to get the day with ordinal suffix
      const getDayWithSuffix = (day) => {
        if (day > 3 && day < 21) return day + "th"; // Special case for teens
        switch (day % 10) {
          case 1:
            return day + "st";
          case 2:
            return day + "nd";
          case 3:
            return day + "rd";
          default:
            return day + "th";
        }
      };

      // Array of abbreviated month names
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Format the date components
      let day = getDayWithSuffix(date.getUTCDate());
      let month = monthNames[date.getUTCMonth()];
      let year = date.getUTCFullYear().toString();

      // Format the readable date string
      return `${day} ${month}, ${year}`;
    }
    return (
      <tr
        className="cursor-pointer  border border-[#F7F7F7] border-solid"
        onClick={handleClick}
      >
        <td className="text-[#121212] capitalize font-normal text-sm  py-[10px] pl-[20px]">
          {customer.name ?? customer.random_username}
        </td>
        <td className="text-[#121212] font-normal text-sm  pl-5">
          {customer?.email ?? "-"}
        </td>

        <td className="text-[#121212] font-normal text-sm w-[15%]">
          {formatISODate(conversationDate)}
        </td>
        <td
          className={`text-[#121212]  font-normal capitalize text-sm w-[15%]
  ${
    status === "active"
      ? "text-green-600"
      : status === "awaiting_agent"
      ? "text-[#E8AF18]"
      : status === "agent_assigned"
      ? "text-[#E8AF18]"
      : "text-[#1774FD]"
  }`}
        >
          <div className="flex gap-1 py-4">
            <img
              src={
                status === "active"
                  ? greenDot
                  : status === "awaiting_agent"
                  ? orangeDot
                  : status === "agent_assigned"
                  ? orangeDot
                  : blueDot
              }
              alt="template"
              className=""
            />
            {status.split("_").join(" ")}
          </div>
        </td>
      </tr>
    );
  };
  const getStatusType = (name) => {
    switch (name) {
      case "all":
        return "";
      case "Live Conversations":
        return "active";
      case "Awaiting Handoff":
        return "awaiting_agent";
      case "Resolved":
        return "resolved";
      case "Assigned Conversations":
        return "agent_assigned";
      default:
        return "";
    }
  };
  return (
    <div>
      <p className="font-medium text-2xl mb-5">Conversations</p>
      {/* TABS  */}
      <div className="bg-[#FDFDFD]  flex w-fit border  border-[#F2F4F7] rounded-xl py-[2px] h-[40px] gap-[10px] select-none">
        {" "}
        {/* w-560px */}
        <button
          className={`font-medium flex justify-center items-center text-xs text-center px-6 rounded-lg transform transition-transform outline-none ${
            activeButton === "All"
              ? " text-BLACK-_500 bg-white shadow-custom"
              : "text-BLACK-_200"
          }`}
          onClick={() => handleButtonClick("All")}
        >
          <p>All</p>
        </button>
        <button
          className={`font-medium px-6 flex justify-center items-center  rounded-lg text-center text-xs transform transition-transform outline-none ${
            activeButton === "Live Conversations"
              ? " text-BLACK-_500 bg-white shadow-custom"
              : "text-BLACK-_200"
          }`}
          onClick={() => handleButtonClick("Live Conversations")}
        >
          <p>Live Conversations</p>
        </button>
        <button
          className={`font-medium px-6 flex justify-center items-center rounded-lg text-center text-xs transform transition-transform outline-none ${
            activeButton === "Awaiting Handoff"
              ? " text-BLACK-_500 bg-white shadow-custom "
              : "text-BLACK-_200"
          }`}
          onClick={() => handleButtonClick("Awaiting Handoff")}
        >
          <p>Awaiting Handoff</p>
          <img
            src={orangeDot}
            alt="template"
            className="relative -top-1 -right-1"
          />
        </button>
        <button
          className={`font-medium px-6 flex justify-center items-center rounded-lg text-center text-xs transform transition-transform outline-none ${
            activeButton === "Assigned Conversations"
              ? " text-BLACK-_500 bg-white shadow-custom "
              : "text-BLACK-_200"
          }`}
          onClick={() => handleButtonClick("Assigned Conversations")}
        >
          <p>Assigned Conversations</p>
        </button>
        <button
          className={`font-medium px-6 rounded-lg text-center text-xs transform transition-transform outline-none ${
            activeButton === "Resolved"
              ? " text-BLACK-_500 bg-white shadow-custom"
              : "text-BLACK-_200"
          }`}
          onClick={() => handleButtonClick("Resolved")}
        >
          Resolved
        </button>
      </div>
      {/* TABLE  */}
      <table className="w-full font-figtree my-5">
        <tr>
          <th className="rounded-tl-lg flex items-center bg-[#FAFAFA] py-[10px] pl-[20px] text-[#828282] font-semibold text-[14px]">
            Customer
          </th>
          <th className="bg-[#FAFAFA] py-[10px] text-left text-[#828282] text-sm font-semibold">
            Email Address
          </th>
          <th className="bg-[#FAFAFA] py-[10px] text-left text-[#828282] text-sm font-semibold">
            Conversation Date
          </th>
          <th className="bg-[#FAFAFA] py-[10px] text-left text-[#828282] text-sm font-semibold"></th>
          <th className="bg-[#FAFAFA] py-[10px] text-left text-[#828282] text-sm font-semibold"></th>
        </tr>
        {conversations.length === 0 ? (
          <tr>
            <td colSpan={5} className="w-full pt-[128px]">
              <div className="flex justify-center">
                <img
                  src={skeletonImage}
                  alt="template"
                  className="w-[237px] h-[25px] mb-5"
                />
              </div>

              <p className="text-[rgba(130,130,130,1)] capitalize text-lg font-medium text-center">
                {errorHeader}
              </p>
              <p className=" text-center text-sm font-normal text-[#828282]">
                {errorBody}
              </p>
            </td>
          </tr>
        ) : (
          conversations.length &&
          conversations.map((data) => {
            return (
              <TableRow
                key={data.id}
                customer={data?.customer ?? data?.random_customer}
                email={data?.email}
                phoneNumber={data?.phone_number}
                status={data?.status}
                conversationDate={data?.created_at}
                id={data?.id}
              />
            );
          })
        )}
      </table>
      {loading && !conversations?.length && (
        <div className=" flex justify-center w-full absolute top-[120px] ">
          <ServiceFeaturesCard />
          <ServiceFeaturesCard />
        </div>
      )}
      {conversations?.length > 0 ? (
        <div className="flex justify-end gap-[10px] items-center mr-2 pb-2 ">
          <p className=" text-sm font-medium text-[#7F7F81] select-none">
            Page
          </p>
          <select
            className="cursor-pointer px-[12px] py-[5px] border border-solid border-[#DDD] bg-white rounded-lg outline-none text-sm font-medium text-[#7F7F81]"
            name="page"
            value={currentPage}
            onChange={handlePageChange}
          >
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <option key={page} value={page}>
                {page}
              </option>
            ))}
          </select>
          <p className=" text-sm font-medium text-[#7F7F81] select-none">{`of ${totalPages}`}</p>
        </div>
      ) : null}
      {/* {activeButton === "About" && <div>about</div>}
      {activeButton === "Resolved" && <div>Resolved </div>} */}
    </div>
  );
}

export default CustomerConversations;

import React, { useState, useEffect } from "react";
import { Form, Formik, useFormik } from "formik";
// import { Radio } from "@mui/material";
// import { BsCheck } from "react-icons/bs";
// import { CgSpinner } from "react-icons/cg";
import { AiOutlineClose } from "react-icons/ai";
import * as Yup from "yup";
// import { setAgent } from "../../../features/application/getApplicationByIdSlice";
// import agentSetUpSvg from "../../../Assets/svg/agentsetup.svg";
import HeadSetUp from "../../../Assets/img/HeadSetUp.png"
// import { useDispatch, useSelector } from "react-redux";
// import { createApplication } from "../../../features/application/createApplicationSlice";
import {
  SuccessMessage,
  ErrorMessage,
} from "../../../Toast-Messages/Toastmessages";
import { useSelector } from "react-redux";
// import ModalPop from "@/../component/modalPop";
// import AgentServiceSelect from "./AgentService2";

const AgentSetUp = ({ agents, handleClose, setDone, showCloseButton, handleNextModal }) => {
  // const [openServicesModal, setOpenServicesModal] = useState(false);
  // const [openAgentServiceModal, setOpenAgentServiceModal] = useState(false)
  // const [agentSetUpSuccess, setAgentSetUpSuccess] = useState(false);
  // const [showEmployeeError, setShowEmployeeError] = useState(false);
  // const [checkedItem, setCheckedItem] = useState();
  const [error, setError] = useState();
  const [value, setValue] = useState({
    EmployeeName: "",
    description: "",
  });
  

  const formValidationSchema = Yup.object().shape({
    EmployeeName: Yup.string().required(),
    description: Yup.string().required(),
  });

  const submitForm = (values, actions) => {
    const data = {
      name: values?.EmployeeName,
      description: values?.description,
      sale_agent_name: values?.EmployeeName,

    };
    //checks if the name is unique or name is not wano
    const agentExists = agents && agents.length && agents.find(item => item.name.toLowerCase() === values.EmployeeName.toLowerCase());
    // const isNotWano = values?.EmployeeName.toLowerCase() !== "wano";
    // console.log(agentExists, "validity")

    if(!agentExists){
      localStorage.setItem("Employee_data", JSON.stringify(data))
      handleNextModal()
      handleClose()
      //  actions.resetForm();
    } else{
      setError("Assistant name is not unique")
      setTimeout(() => {
        setError(null);
      }, 1000)
    }
  };


  return (
    <div className="relative max-w-[519px] h-fit max-h-[90vh] mt-[5px]"> {/* h-[720px] */}
      <div className="bg-WHITE-_100 rounded-lg max-w-[519px] h-[95vh] max-h-[680px]"> {/* h-[720px]  max-h-[90vh] mt-[20px] overflow-y-scroll*/}
        <div className="relative rounded-lg w-full flex flex-col items-center justify-center max-h-[214px] bg-[#FAFBFC]">
            <div
                className="absolute top-2 right-4 max-w-[36px] max-h-[36px] border rounded-lg mr-3 mt-3 flex cursor-pointer justify-center items-center p-2"
                onClick={handleClose}
            >
                <AiOutlineClose />
            </div>
          <div className="flex items-center justify-center">
            <img src={HeadSetUp} alt="" className="" />{/* agentSetUpSvg */}
          </div>
        </div>
        <div className="flex justify-center flex-col gap-4 py-[5px] text-center items-center">
       
          <div className="max-w-[395px] flex flex-col gap-2 lg:gap-5 mt-4">
            <p  className="font-normal text-sm text-center text-[#7F7F81]">Step 1</p>
            <p className="text-BLACK-_600 font-medium text-lg md:text-xl lg:text-2xl">
              Let’s set up your Digital Assistant
            </p>
            <p className="font-normal text-sm text-center text-[#7F7F81]">
              Give your Assistant a Name and a short description.
        
            </p>
          </div>
          <div className="flex flex-col mt-[0px]">
            <Formik
              initialValues={{ 
                EmployeeName: value.EmployeeName || "", 
                description: value.description || "",
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values, actions) => {
                window.scrollTo(0, 0);
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                dirty,
                isValid,
                setFieldValue,
                errors,
                touched,
                // setFieldTouched,
                values,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  className="flex flex-col items-center"
                >
                  <div className="flex flex-col mx-auto gap-4 lg:gap-[40px] px-[33px]">
                    <div className=" flex flex-col gap-2 lg:gap-5">
                      <div className="max-w-[455px] flex flex-col">
                        <div className="flex flex-col gap-2">
                          <label className="label text-left text-[#101828] text-xs font-normal">
                            Assistant Name
                          </label>
                          <input
                            name="EmployeeName"
                            type="text"
                            className="outline-none "
                            value={values?.EmployeeName}
                            onChange={handleChange}
                          />
                        </div>
                        <p className="text-left">
                          {errors.EmployeeName && touched.EmployeeName ? (
                            <div className="text-RED-_100 text-xs">
                              {errors.EmployeeName}
                            </div>
                          ) : null}
                        </p>
                      </div>
                      <div className="flex flex-col ">
                        <div className="flex flex-col gap-2">
                          <label className="label text-left text-[#101828] text-xs font-normal">
                            Description
                          </label>
                          <input
                            name="description"
                            type="text"
                            className="outline-none"
                            value={values?.description}
                            onChange={handleChange}
                          />
                        </div>
                        <p className="text-left">
                          {errors.description && touched.description ? (
                            <div className="text-RED-_100 text-xs">
                              {errors.description}
                            </div>
                          ) : null}
                        </p>
                      </div>
                    </div>
                    <div
                      // style={{
                      //   boxShadow: "0px -4px 8px 1px #D7D7D740",
                      // }}
                      // h-[100px] 
                      className={`flex flex-col items-center ${!agents.length? "pb-2": "pb-6"} px-8 bg-white w-full absolute left-0 -bottom-5 rounded-b-lg`}
                    >
                      <button
                        className="w-full h-[40px] flex mx-auto justify-center items-center bg-BLACK-_500 disabled:bg-[#EDEDED] disabled:cursor-not-allowed  p-2 rounded-lg"
                        type="submit"
                        disabled={
                          !values.EmployeeName || !values.description || // Checks if any field is empty
                          (touched.EmployeeName && !!errors.EmployeeName) || // Checks if there's an error in EmployeeName after it's been touched
                          (touched.description && !!errors.description) // Checks if there's an error in description after it's been touched
                        }
                      >
                        <p className="text-WHITE-_100 text-sm font-bold">
                          {/* {loading ? (
                            <CgSpinner className=" animate-spin text-lg " />
                          ) : (
                            "Create AI Employee"
                          )} */}
                          Continue
                        </p>
                      </button>
                      {!agents.length &&
                        <div
                          className="mt-2 text-BLACK-_200 font-bold text-sm cursor-pointer"
                          onClick={handleClose}
                        >
                          Skip for Now
                        </div>
                      }
                    </div>

                    {/* <div className="flex flex-col gap-5 mb-[100px]">
                      <p className="text-left text-xs text-[#101828]">
                        Select Employee Type
                      </p>
                      {showEmployeeError && (
                        <div className="text-RED-_100 text-xs">
                          Employee Type is required
                        </div>
                      )}

                      <div className="flex flex-col gap-0 border border-[#E3E3E3] rounded-2xl">
                        {aiData.map((item) => {
                          return (
                            <div
                              className="flex justify-between px-5 py-4 border-b"
                              key={item?.id}
                            >
                              <div className="flex flex-col gap-2">
                                <p className="text-[#121212] text-sm text-left font-medium">
                                  {item?.title}
                                </p>
                                <p className="text-xs font-medium text-left text-BLACK-_200">
                                  {item?.description}
                                </p>
                              </div>
                              <Radio
                                sx={{
                                  color: "#EBEBEB",
                                  height: 20,
                                  width: 20,
                                  border: "1px",
                                  background: "#fff",
                                }}
                                checked={checkedItem === item?.type}
                                // disabled={customer || general}
                                value={item?.type}
                                checkedIcon={<CheckedIcon />}
                                onChange={() => handleCheckedItem(item?.type)}
                                // onChange={handleChange}
                                name="item"
                                inputProps={{ "aria-label": "A" }}
                              />
                            </div>
                          );
                        })} */}
                      {/* removed div buttons from here */}
                        {/* <div className="flex justify-between px-5 py-4 ">
                                            <div className="flex flex-col gap-2">
                                                <p className="text-[#121212] text-sm text-left font-medium">Sales A.I</p>
                                                <p className="text-xs font-medium text-left text-BLACK-_200">
                                                    Meet a new member of your sales team. 
                                                    Sales AI will sell your financial solution to customers talk to customers, 
                                                    represent your brand and offer useful insights to make better sales decisions.
                                                </p>
                                            </div>
                                            <Radio
                                                sx={{ color: '#EBEBEB', height: 20, width: 20, border: "1px", background: "#fff" }}
                                                checked={sales}
                                                disabled={customer || general}
                                                value={values?.sales}
                                                checkedIcon={<CheckedIcon />}
                                                onClick={() => handleSalesChange()}
                                                // onChange={handleChange}
                                                name="sales"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                    </div>
                                    <hr /> */}
                        {/* <div className="flex justify-between px-5 py-4 ">
                                        <div className="flex flex-col gap-2">
                                            <p className="text-[#121212] text-sm text-left font-medium">Customer Service A.I</p>
                                            <p className="text-xs font-medium text-left text-BLACK-_200">
                                                Meet a new member of your customer care team. Customer Service AI never sleeps or tire, 
                                                he/she will always attend to your customer enquiries, requests and complaints.
                                            </p>
                                        </div>
                                        <Radio
                                            sx={{ color: '#EBEBEB', height: 20, width: 20, border: "1px", background: "#fff" }}
                                            checked={customer}
                                            disabled={sales || general}
                                            value={values?.customer}
                                            checkedIcon={<CheckedIcon />}
                                            onClick={() => handleCustomerServiceChange()}
                                            name="customer"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                    </div>
                                    <hr />
                                    <div className="flex justify-between px-5 py-4">
                                        <div className="flex flex-col gap-2">
                                            <p className="text-[#121212] text-sm text-left font-medium">General A.I</p>
                                            <p className="text-xs font-medium text-left text-BLACK-_200">
                                                Meet the new generalist in your team. General AI can help you do it all 
                                                in one go — sell your service, attend to customers, and give you a 
                                                broader insight across sales and customer care combined.
                                            </p>
                                        </div>
                                        <Radio
                                            sx={{ color: '#EBEBEB', height: 20, width: 20, border: "1px", background: "#fff" }}
                                            checked={general}
                                            checkedIcon={<CheckedIcon />}
                                            value={values?.general}
                                            disabled={sales || customer}
                                            onClick={() => handleGeneralChange()}
                                            name="general"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                    </div> */}
                      {/* </div> */}
                    {/* </div> */}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {/* {agentSetUpSuccess && (
          <SuccessMessage
            Formodal={true}
            success={message}
            className="mt-24 mr-20"
          />
        )} */}
        {error && <ErrorMessage error={error} />}
      </div>
    </div>
  );
};

export default AgentSetUp;

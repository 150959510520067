import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  Fragment,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "./Select";
import SelectCategory from "./SelectCategory";
import { Delete } from "../Assets/svg";
import Shape from "../Assets/svg/Shape65.svg";
import { getCountries } from "features/countries/getCountriesSlice";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Switch } from "@headlessui/react";
import AtInput3 from "@/../Plugins/AllPluginType/components/AtInput3";
import AtInput6 from "@/../Plugins/AllPluginType/components/AtInput6";

//added forwardRef to pass down the ref
const ScheduledForm = forwardRef(
  (
    {
      width,
      categoryName,
      instructionDescription,
      instructionDescriptionDefault,
      setInstructionDescription,
      instructionManual,
      setInstructionManual,
      instructionName,
      instructionNameDefault,
      setInstructionName,
      support,
      setCategoryName,
      setSupport,
      next,
      setNext,
      multipleInstructions,
      stepData, // new prop to receive step data
      setCountryCode,
      countryCode, //new prop for coutry code
      action, //new prop calls the submit function
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      // repeatOption,
      // setRepeatOption,
      setTags,
      tags,
    },
    ref
  ) => {
    const [selectCategory, setSelectCategory] = useState(null);
    const [showEndDate, setShowEndDate] = useState(false);
    // const [toggle, setToggle] = useState(false)

    //Toggle function to handle the switch click
    // const toggleSwitch = () => {
    //   setShowEndDate(!showEndDate);
    // };

    // console.log(startDate, "okayyy");

    // console.log(repeatOption, " this is the selected repeat option ");

    const {
      register,
      handleSubmit,
      control,
      setValue,
      getValues,
      clearErrors,
      formState: { errors },
      reset,
    } = useForm({
      defaultValues: {
        supportChannels: support,
        // repeatOption: "",
      },
    });

    //used this to handle the dynamic form fields of the support_channel
    const { fields, append, remove } = useFieldArray({
      control,
      name: "supportChannels",
    });

    // Validation rules
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    // const urlRegex = /^(https?:\/\/(?:www\.|(?!www))[^\s.]+\.[^\s]{2,}|www\.[^\s.]+\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[^\s.]+\.[^\s]{2,}|www\.[^\s.]+\.[^\s]{2,})$/;
    // /^(https?:\/\/)?(www\.)?[^\s.]+\.[^\s]{2,}$/i
    const urlRegex =
      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

    useImperativeHandle(ref, () => ({
      submitForm: () => handleSubmit(onSubmit)(),
    }));

    const storeCountries = useSelector((state) => state.getAllCountries);
    // const handleRepeatOptionClick = (option) => {
    //   setRepeatOption(option); 
    //   setValue("repeatOption", option, { shouldValidate: true });
    // };

    // useEffect(() => {
    //   register("repeatOption", { required: "Repeat option is required" }); 
    // }, [register]);

    const onSubmit = (data) => {
      action(); 
      console.log(data);
    };

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setValue(name, value);
      if (errors[name]) {
        clearErrors(name);
      }
    };

    useEffect(() => {
      if (instructionManual) {
        reset({
          how_it_works: instructionManual || ''
        });
      }
    }, [instructionManual, reset]);
  
    useEffect(() => {
      if (instructionManual) {
        setValue('how_it_works', instructionManual, {
          shouldValidate: true 
        });
      }
    }, [instructionManual, setValue]);

    useEffect(() => {
      support?.forEach((item) => {
        const existingItems = getValues("supportChannels");
        if (
          !existingItems?.find(
            (channel) => channel?.support_channel === item?.support_channel
          )
        ) {
          append(item); 
          const updatedSupportChannels = [...getValues("supportChannels")];
          setSupport(updatedSupportChannels); 
        }
      });
    }, [support, append, getValues, setValue]);

    useEffect(() => {
      if (stepData) {
        setCategoryName && setCategoryName(stepData.categoryName || "");
        setInstructionName(stepData.instructionName || "");
        setInstructionDescription(stepData.instructionDescription || "");
        setInstructionManual(stepData.instructionManual || "");
        setSupport(stepData.support || []);
        const StartDate = new Date(stepData.start_date);
        const formattedDate = StartDate.toISOString().split('T')[0];
        setStartDate(formattedDate || '');
        const endDate = new Date(stepData.start_date);
        const formatDate = endDate.toISOString().split('T')[0];
        setEndDate(formatDate || '');
      }
    }, [stepData]);
    console.log(stepData, "prime")
    

    const dispatch = useDispatch();

    const clearCategoryInput = () => {
      setSelectCategory(null);
      setCategoryName("");
    };

    const getCountiresAndStore = async () => {
      dispatch(getCountries());
    };

    useEffect(() => {
      getCountiresAndStore();
    }, []);

    const getAgentApplication = useSelector(
      (state) => state?.getApplicationByUserId
    );

    const fullAgent = getAgentApplication?.agent || null;

    return (
      <section className="flex justify-between">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`mt-11 flex flex-col gap-6 ${
          width ? "w-full" : "w-[711px]"
        }`}
      >
        <div className="flex flex-col gap-3">
          <label className="text-xs font-figtree text-[#101828] flex items-center">
            Name
            <span className="text-red-700 pl-1 pt-1.5">*</span>
          </label>
          {multipleInstructions ? (
            <>
              <input
                value={instructionName}
                {...register("name", { required: !instructionName })}
                onChange={(e) => {
                  setInstructionName(e.target.value);
                  handleInputChange(e);
                }}
                placeholder={instructionNameDefault}
                className={`instructionstep-1 text-[#101828] placeholder:text-[#101828] text-xs py-[10px] px-[14px] rounded-lg border ${
                  errors.name ? "border-[#AF202D]" : "border-[#D0D5DD]"
                } bg-white focus:outline-none w-full`}
              />
              {errors.name && (
                <span className="text-[#AF202D] text-xs">
                  This field is required
                </span>
              )}
            </>
          ) : (
            <>
              <input
                value={instructionName}
                {...register("name", { required: !instructionName })}
                onChange={(e) => {
                  setInstructionName(e.target.value);
                  handleInputChange(e);
                }}
                placeholder={"Card Request"}
                className={`instructionstep-1 text-[#101828] text-xs py-[10px] px-[14px] rounded-lg border ${
                  errors.name ? "border-[#AF202D]" : "border-[#D0D5DD]"
                } bg-white focus:outline-none w-full`}
              />
              {errors.name && (
                <span className="text-[#AF202D] text-xs">
                  This field is required
                </span>
              )}
            </>
          )}
        </div>
        {multipleInstructions ? (
          <div className="flex flex-col gap-3">
            <label className="text-xs font-figtree text-[#101828] flex items-center">
              Category
              <span className="text-red-700 pl-1 pt-1.5">*</span>
            </label>
            <SelectCategory
              select={selectCategory}
              setSelect={setSelectCategory}
              setCategoryName={setCategoryName}
            />
            {selectCategory === "Others (Please Specify)" &&
              (categoryName === "Others (Please Specify)" ? (
                <input
                  value={""}
                  onChange={(e) => {
                    setCategoryName(e.target.value);
                    handleInputChange(e);
                  }}
                  className="text-[#101828] text-xs py-[10px] px-[14px] rounded-lg border border-[#D0D5DD] bg-white focus:outline-none w-full"
                />
              ) : (
                <input
                  value={categoryName}
                  onChange={(e) => {
                    setCategoryName(e.target.value);
                    handleInputChange(e);
                  }}
                  className="text-[#101828] text-xs py-[10px] px-[14px] rounded-lg border border-[#D0D5DD] bg-white focus:outline-none w-full"
                />
              ))}
          </div>
        ) : 
        null}
        <div className="flex flex-col gap-3">
          <label className="text-xs font-figtree text-[#101828] flex items-center">
            Description
            <span className="text-red-700 pl-1 pt-2">*</span>
          </label>
          <input
            type="text"
            value={instructionDescription}
            {...register("description", { required: !instructionDescription })}
            onChange={(e) => {
              setInstructionDescription(e.target.value);
              handleInputChange(e);
            }}
            placeholder={
              multipleInstructions
                ? instructionDescriptionDefault
                : "e.g.: These instructions are for whenever a customer has a complaint about a Failed Transaction and would like it resolved."
            }
            className={`instructionstep-2 text-[#101828] ${
              multipleInstructions ? "placeholder:text-[#101828]" : ""
            } text-xs py-[12px] px-[14px] rounded-lg border ${
              errors.description ? "border-[#AF202D]" : "border-[#D0D5DD]"
            } bg-white focus:outline-none w-full`}
          />
          {errors.description && (
            <span className="text-[#AF202D] text-xs">
              This field is required
            </span>
          )}
        </div>
        <div className="flex flex-col gap-3">
          <label className="text-xs font-figtree text-[#101828] flex items-center">
            {/* How It Works */}
            Enter Instructions
            <span className="text-red-700 pl-1 pt-1">*</span>
            <span className="text-[#7F7F81] ml-[5px]">
              (Provide a Step by Step Description of how this instruction should
              work)
            </span>
          </label>
          {/* <textarea
            value={instructionManual}
            {...register("how_it_works", { required: !instructionManual })}
            onChange={(e) => {
              setInstructionManual(e.target.value);
              handleInputChange(e);
            }}
            className={`h-[206px] text-[#101828] text-xs py-[17px] px-[14px] rounded-lg border ${
              errors.how_it_works ? "border-[#AF202D]" : "border-[#D0D5DD]"
            } bg-white focus:outline-none w-full`}
            placeholder="e.g:&#10;When a customer complains about any transaction failure, ask them if they have:&#10;- retried the transaction &#10;- confirmed that the transaction details are correct&#10;If their response is affirmative, and their complaint is still the same redirect them to the support channels provided for further assistance."
          />
          {errors.how_it_works && (
            <span className="text-[#AF202D] text-xs">
              This field is required
            </span>
          )} */}
          <div className="instructionstep-3">
          <Controller
            control={control}
            name="how_it_works"
            rules={{ required: "This field is required" }} 
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
              <div className="flex flex-col gap-1">
                {/* <AtInput3
                    type="textarea"
                    className={`h-[206px] text-[#101828] text-xs py-[17px] px-[14px] rounded-lg border ${error ? 'border-[#AF202D]' : 'border-[#D0D5DD]'} bg-white focus:outline-none w-full`}
                    value={instructionManual || value}
                    setTags={setTags}
                    tags={tags}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setInstructionManual(e.target.value); 
                    }}
                    onBlur={onBlur}
                    ref={ref} 
                    placeholder="e.g:&#10;When a customer complains about any transaction failure, ask them if they have:&#10;- retried the transaction &#10;- confirmed that the transaction details are correct&#10;If their response is affirmative, and their complaint is still the same redirect them to the support channels provided for further assistance."
                /> */}
                <AtInput6
                    type="textarea"
                    className={`h-[206px] text-[#101828] text-xs py-[17px] px-[14px] rounded-lg border ${error ? 'border-[#AF202D]' : 'border-[#D0D5DD]'} bg-white focus:outline-none w-full`}
                    value={instructionManual || value}
                    setTags={setTags}
                    tags={tags}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setInstructionManual(e.target.value); 
                    }}
                    onBlur={onBlur}
                    ref={ref} 
                    placeholder="e.g:&#10;When a customer complains about any transaction failure, ask them if they have:&#10;- retried the transaction &#10;- confirmed that the transaction details are correct&#10;If their response is affirmative, and their complaint is still the same redirect them to the support channels provided for further assistance."
                />
                {error && <span className="text-[#AF202D] text-xs">Please fill this field</span>}
              </div>
            )}
          />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <label className="text-xs font-figtree text-[#101828]">
            Support Channel
            <span className="text-[#7F7F81]">
              (Select where you’d like your assistant to redirect your customers
              to. Maximum of 3 channels)
            </span>
          </label>
          <div className="instructionstep-4">
              {" "}
              <Select
                setNext={setNext}
                next={next}
                support={support}
                setSupport={setSupport}
              />
            </div>
        </div>
        {support &&
          fields
            .map((s, index) => {
              return (
                <div key={s.id} className="flex flex-col gap-3">
                  {" "}
                  {/* key={index} */}
                  <div className="w-full flex justify-between items-center">
                    <label className="text-xs font-figtree text-[#101828]">
                      {s.support_channel}
                    </label>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        remove(index); 
                        setSupport((prevSupport) =>
                          prevSupport.filter((item, i) => i !== index)
                        );
                      }}
                    >
                      <Delete />
                    </div>
                  </div>
                  <div
                    className={`border ${
                      errors.supportChannels &&
                      errors.supportChannels[index]?.website
                        ? "border-[#AF202D]"
                        : "border-[#D0D5DD]"
                    } rounded-lg flex p-[5px] h-[38px]`}
                  >
                    {s.support_channel === "Email Address" ? (
                      <div></div>
                    ) : s.support_channel === "Phone Number" ? (
                      <select
                        name="number"
                        id=""
                        className="text-xs border-t-0 m-0.5 border border-l-0 border-b-0 outline-none "
                        onChange={(e) => {
                          const val = e.target.value;
                          const selectedCountry =
                            storeCountries?.countries.find(
                              (country) => country.name === val
                            );
                          setCountryCode(selectedCountry.phone_code);
                        }}
                        value={
                          storeCountries?.countries.find(
                            (country) => country.phone_code === countryCode
                          )?.name
                        }
                      >
                        {storeCountries?.countries.map((country) => (
                          <option
                            className="flex flex-rowb"
                            key={country.code}
                            value={country.name}
                          >
                            {country.code} ({country.phone_code})
                          </option>
                        ))}
                      </select>
                    ) : (
                      <span className="text-[#828282] h-full px-[10px] font-medium text-[10px] bg-[#F2F2F2] rounded-[4px] flex items-center justify-center">
                        https://
                      </span>
                    )}
                    <input
                      type={`${
                        s.support_channel === "Phone Number" ? "number" : "text"
                      }`}
                      // value={s.website}
                      {...register(`supportChannels[${index}].website`, {
                        required: "This field is required",
                        validate: (value) => {
                          if (s.support_channel === "Email Address")
                            return (
                              emailRegex.test(value) || "Invalid email address"
                            );
                          if (s.support_channel === "Phone Number")
                            return (
                              phoneRegex.test(value) || "Invalid phone number"
                            );
                          return urlRegex.test(value) || "Invalid URL";
                        },
                      })}
                      // onChange={(e) => handleDynamicInputChange(e, index)}
                      className="w-full focus:outline-none border-none text-xs font-figtree text-[#101828]"
                    />
                  </div>
                  {errors.supportChannels &&
                    errors.supportChannels[index]?.website && (
                      <p className="w-full text-[#AF202D] text-xs">
                        {errors.supportChannels[index].website.message}
                      </p>
                    )}
                </div>
              );
            })}


        {/* <div className="flex gap-[5px] items-center">
          <label className="text-xs font-figtree text-[#101828]">
            Schedule This Instruction
          </label>
          <Switch as={Fragment}>
            {({ checked }) => (
              <button
                className={`${
                  checked ? "bg-blue-600" : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
                onClick={toggleSwitch}
              >
                <span className="sr-only">Enable scheduling</span>
                <span
                  className={`${
                    checked ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </button>
            )}
          </Switch>
        </div> */}

        {/* {showEndDate && ( */}
          <>
            <div className="flex flex-col gap-3">
              <label className="text-xs font-figtree text-[#101828]">
                Start Date
              </label>
              <input
                type="date"
                {...register("startDate", {
                  required: true,
                  onChange: (e) => setStartDate(e.target.value),
                })}
                value={startDate}
                className={`instructionstep-6 focus:outline-none w-full cursor-pointer text-[#101828] text-xs ${
                  errors.startDate ? "border-[#AF202D]" : ""
                }`}
              />
              {errors.startDate && <span class="text-[#AF202D] text-xs"> This field is required </span>}
            </div>

            <div class="flex flex-col gap-3">
              <label class="text-xs font-figtree text-[#101828]">
                End Date
              </label>
              <input
                type="date"
                {...register("endDate", {
                  required: true,
                  onChange: (e) => setEndDate(e.target.value),
                })}
                value={endDate}
                className={`instructionstep-7 focus:outline-none w-full cursor-pointer text-[#101828] text-xs ${
                  errors.endDate ? "border-[#AF202D]" : ""
                }`}
              />
              {errors.endDate && <span class="text-[#AF202D] text-xs"> This field is required </span>}
            </div>
          </>
        {/* )} */}
        {/* <div className="flex flex-col gap-3">
          <label className="text-xs font-figtree text-[#101828]">
            Start Date
          </label>
          <input
            type="date"
            {...register("startDate", {
              required: true,
              onChange: (e) => {
                setStartDate(e.target.value); 
              },
            })}
            value={startDate}
            className={`focus:outline-none w-full cursor-pointer text-[#101828] text-xs ${
              errors.startDate ? "border-[#AF202D]" : ""
            }`}
          />
          {errors.startDate && <span className="text-[#AF202D] text-xs"> This field is required </span>}
        </div> */}

        {/* <label className="text-xs font-figtree text-[#101828]">
          Repeats
          <div className="flex w-[331px] gap-[12px] mt-[12px]">
            {["daily", "weekly", "monthly", "yearly"].map((option) => (
              <button
                key={option}
                type="button"
                className={`${
                  getValues("repeatOption") === option
                    ? "bg-blue-100 border-blue-600"
                    : "bg-white border-neutral-100"
                } outline-none py-3 w-auto px-5 text-[#828282] border text-[14px] rounded-lg font-semibold`}
                onClick={() => handleRepeatOptionClick(option)}
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </button>
            ))}
          </div>
          {errors.repeatOption && (
            <p className=" text-[#AF202D] text-xs mt-[10px]">This field is required</p>
          )}
        </label> */}

        {/* <div className="flex gap-[5px] items-center">
          <label className="text-xs font-figtree text-[#101828]">
            Schedule This Instruction
          </label>
          <div>
            <Switch as={Fragment}>
              {({ checked }) => (
                <button
                  className={`${
                    checked
                      ? "bg-blue-600"
                      : showEndDate
                      ? "bg-blue-600"
                      : "bg-gray-200"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                  onClick={toggleSwitch}
                >
                  <span className="sr-only">Enable notifications</span>
                  <span
                    className={`${
                      checked
                        ? "translate-x-6"
                        : showEndDate
                        ? "translate-x-6"
                        : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </button>
              )}
            </Switch>
          </div>
        </div> 
    
          <div className="flex flex-col gap-3">
            <label className="text-xs font-figtree text-[#101828]">
              End Date
            </label>
            <input
              type="date"
              {...register("endDate", {
                required: true,
                onChange: (e) => {
                  setEndDate(e.target.value);
                },
              })}
              value={endDate}
              className={`focus:outline-none w-full cursor-pointer text-[#101828] text-xs ${
                errors.endDate ? "border-[#AF202D]" : ""
              }`}
            />
            {errors.endDate && <span className="text-[#AF202D] text-xs"> This field is required </span>}
          </div> */}
        {/* HOW It Works */}
      </form>
      <div className="border border-[#D0D5DD] h-fit w-[330px] mt-[70px] px-[20px] py-[14px] rounded-[8px]"   style={{
              boxShadow: "0px 4px 8px 1px rgba(215, 215, 215, 0.25)",
              background:
                "linear-gradient(160deg,#F8C4D326 2%,  #1774FD08 68%)",
            }}>
          <div className="flex gap-[8px] items-center">
            <img src={Shape} alt="shape" className="w-[23.63px] h-[23.63px]" />
            <p className="text-[#828282] text-[12px] leading-[18px]">Example</p>
          </div>
          <div className="text-[12px] leading-[20px] mt-[10px]">
            <p>Try to be specific when creating an Instruction or Guideline:</p>
            <p>
              For e.g: “Whenever a user wants to see a product demo, direct them
              to our demo link
            </p>
            <p>
              Make sure to{" "}
              <span className="font-medium">
                provide links referred to in the instructions under support
                channels.
              </span>
            </p>
          </div>
        </div>
        </section>
    );
  }
);

export default ScheduledForm;

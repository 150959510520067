import React, { useEffect, useState } from "react";
import Button from "./Button";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../features/auth/loginSlice";
import { ErrorMessage, SuccessMessage } from "../Toast-Messages/Toastmessages";
import {
  getApplicationById,
  setAgent,
} from "../features/application/getApplicationByIdSlice";
import {
  getBusiness,
  setBusiness,
  switchBusiness,
} from "../features/business/getBusinessSlice";
import { showTestemployeeTour } from "../features/testAgent/chatSlice";
import { getAllPlugins } from "@/../features/plugins/getAllPluginsSlice";

const style =
  "rounded-xl px-[12px] w-[420px] line-[24px] py-[13px] text-white text-14px";

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState } = useForm();
  const { errors, isValid, isDirty } = formState;
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);

  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    // console.log(data)
    try {
      setLoading(true);
      const loginResponse = await dispatch(loginUser(data));

      if (loginResponse?.payload?.status) {
        const busnessResponse = await dispatch(getBusiness());
        if (!busnessResponse?.payload?.data?.length) {
          return navigate("/company");
        } else {
          await dispatch(switchBusiness(busnessResponse?.payload?.data[0].id));
          localStorage.setItem('cBus', JSON.stringify(busnessResponse?.payload?.data[0]));
          dispatch(setBusiness(busnessResponse?.payload?.data[0]));
        }
        const applicationResponse = await dispatch(getApplicationById());
        if (applicationResponse?.payload?.data?.length >= 1) {
          // const id = applicationResponse?.payload?.data[0]?.id;
          // dispatch(getAllPlugins({ id }))
          localStorage.setItem(
            "wano_agent",
            JSON.stringify(applicationResponse?.payload?.data[0])
          );
          dispatch(setAgent(applicationResponse?.payload?.data[0]));
          // console.log(applicationResponse, "appResponse tags");
          //dispatch(showTestemployeeTour(false))
          setLoginSuccess(true);
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        } else {
          dispatch(getBusiness())
          //dispatch(showTestemployeeTour(false))
          setLoginSuccess(true);
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        }
      } else {
        setLoginError(true);
        if (loginResponse.payload === "Account not verified") {
          navigate("/verify");
        }
        if (loginResponse.payload === "2FA OTP is required") {
          localStorage.setItem("loginDetail", JSON.stringify(data));
          navigate("/2Fa-Authentication");
        }

        // if (loginResponse.payload === ""){
        //   navigate("/2Fa-Authentication");
        // }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setLoginError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const removeErrorNotification = () => {
    setTimeout(() => {
      setLoginError(false);
    }, 1000);
  };

  const removeSuccessNotification = () => {
    setTimeout(() => {
      setLoginSuccess(false);
    }, 1000);
  };

  useEffect(() => {
    removeSuccessNotification();
  }, [loginSuccess]);

  useEffect(() => {
    removeErrorNotification();
  }, [loginError]);

  function onError(errors) {
    console.log(errors);
  }

  return (
    <>
      <div className="grid gap-6">
        <form className="grid gap-3" onSubmit={handleSubmit(onSubmit, onError)}>
          <label className="label text-[#101828] text">Email</label>
          <input
            type="email"
            id="email"
            {...register("email", {
              required: "Email address is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            })}
            className=" h-[38px] outline-none rounded-lg"
          />
          <span className="text-red-500 text-[12px]">
            {errors?.email && errors?.email?.message}
          </span>
          <label className="label text-[#101828] text-[12px]">Password</label>
          <div className=" relative">
            <input
              className="w-full outline-none rounded-lg h-[38px] pr-9"
              type={showPassword ? "text" : "password"}
              id="password"
              {...register("password", {
                required: "Password is required",
              })}
            />
            {showPassword ? (
              <BsEyeSlash
                className=" absolute top-[10px] right-3 cursor-pointer text-[#828282]"
                onClick={togglePasswordVisibility}
              />
            ) : (
              <BsEye
                className=" absolute top-[10px] right-3 cursor-pointer text-[#828282]"
                onClick={togglePasswordVisibility}
              />
            )}
            <div className="flex justify-between mt-3 mb-4">
              <span className="text-red-500 text-[12px]">
                {errors?.password && errors?.password?.message}
              </span>
              <span className="flex justify-end text-[#1774FD] text-[12px]">
                <Link to="/forgotpassword">Forgot Password ?</Link>{" "}
              </span>
            </div>
          </div>

          <Button
            disabled={!isDirty || !isValid}
            type="submit"
            className={
              isValid
                ? `bg-black ${style} cursor-pointer`
                : `bg-[#EBEBEB] ${style} cursor-not-allowed`
            }
            text={"Login"}
            loading={loading}
          />
        </form>
        {/* <Link to="/company"> */}
      </div>
      {loginSuccess && (
        <SuccessMessage
          success={userLogin?.user?.message}
          className="mt-5 mr-2"
        />
      )}
      {loginError && (
        <ErrorMessage error={userLogin?.error} className="mt-5 mr-2" />
      )}
    </>
  );
}

import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import backgroundImage from "Assets/img/backdrop.svg";
import { useDispatch, useSelector } from "react-redux";
// import { updateAgentModalVisibility } from "@/../features/modals/agentModalsSlice";
// import { getApplicationById } from "@/../features/application/getApplicationByIdSlice";
export default function ConfirmModal({
  handleClose,
  handleDontShow,
  handleUploadTrain,
  purpose,
  id,
}) {
  // const dispatch = useDispatch();
  // const config = useSelector(state => state.getApplicationByUserId.config);

  // const handleModalUpdate = (name) => {
  //   dispatch(updateAgentModalVisibility({ modalsVisibility: { ...config, [name]: false }, id: id }))
  //   .then(() => {
  //     dispatch(getApplicationById())
  //   })
  // }


  return (
    <div className="bg-WHITE-_100 rounded-lg w-[617px] h-fit mt-[75px] ">
      <div
        className="w-full flex justify-end h-[175px] px-6 "
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="mt-7">
          <div
            className=" bg-white text-[#7F7F81] text-xs rounded-lg flex cursor-pointer justify-end items-center p-2"
            onClick={() => {handleDontShow()}}
          >
            Don't show this again
          </div>
        </div>
      </div>
      <div className=" pb-10 pt-3 px-10">
        <p className="text-bamboo text-[22px] mb-8 text-center font-semibold">
          What Happens When You Train
        </p>
        <ul className="UlListStyle pl-9 flex flex-col gap-7">
          <li>Data will be simplified for your Digital Assistant to understand.</li>
          <li>
            Any data unrelated to the '{purpose}' category will be removed from
            document.
          </li>
          <li>
            Complete training may take up to 48 hours and you’ll be notified on
            completion. You will also be notified where one or more context is
            required.
          </li>
        </ul>
        <div className="mt-10 px-2 py-3 bg-[#F6F7FF] w-fit mx-auto text-skyBlue text-xs">
          Note: Your Digital Assistant will be fully operational while it trains on
          your data
        </div>

        <div className="flex justify-between w-full items-center mt-12">
          <button
            className="bg-transparent border border-[#D0D5DD] text-[#828282] py-[10px] px-5 rounded-lg font-semibold text-xs"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="bg-[#121212] text-[white] py-3 px-4 rounded-lg text-xs font-semibold"
            onClick={handleUploadTrain}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import { AiOutlineClose } from "react-icons/ai"
import { useNavigate } from 'react-router'

import Confetti from "../../../Assets/svg/confetti.svg"
import ConfettiLeft from "../../../Assets/svg/confetti_left.svg"
import ConfettiRight from "../../../Assets/svg/confetti_right.svg"

const Welcome = ({ handleClose }) => {

    const navigate = useNavigate();

    const saveUser = () => {
        sessionStorage.setItem("user", "userName");
        localStorage.setItem("user", "userName");

        handleClose()
    }

  return (
    <div 
        className="bg-WHITE-_100 w-[563px] h-[479px] mt-[30px] py-[30px] px-[50px] rounded-lg" 
        style={{boxShadow: "0px 24px 43px 0px rgba(76,76,76, 0.07)", height: "fit-content"}} 

    >
        <div className='flex justify-end' style={{    height: "fit-content"}}>
        
        </div>
        <div className='w-[454px] flex flex-col' style={{    height: "fit-content"}}>
            <div className='w-[296px] flex flex-col mt-5  mx-auto' style={{    height: "fit-content"}}>
                <div className='w-[214px] flex flex-col justify-center items-center mx-auto'>
                    <div className='w-[57px] h-[57px] flex flex-col justify-center rounded-full items-center' style={{ background: "rgba(205, 222, 248, 0.20)" }}>
                        <img src={Confetti} alt="confetti" className='w-[35px] h-[33px]' />
                    </div>
                    <p className='text-2xl text-BLACK-_100 font-medium mt-3'>Welcome to Wano!</p>
                    <p className='text-xs text-[#7F7F81] font-medium'>Let's get you ready.</p>

                   
                </div>
            </div>
            <div className='mt-10'>
                <button
                    className="w-[454px] h-[40px] py-3 px-5 rounded-lg flex justify-center items-center text-bold bg-BLACK-_500 text-WHITE-_100 text-sm"
                    type="submit"
                    onClick={() => navigate("/getstarted")}
                >
                    Get Started
                </button>
            </div>

            <div className='mt-10 text-center'>
                <p className='text-[#868686] text-sm'>Have questions? <span className='text-[#1774FD] text-sm underline cursor-pointer' onClick={() => navigate("/support")}>click here</span> </p>
            </div>

        </div>

    </div>
  )
}

export default Welcome
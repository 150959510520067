import React from 'react'
import { CgSpinner } from 'react-icons/cg'

export default function DeleteService({ loading, handleClose, handleDelete }) {
    return (
        <div className=" w-[546px] h-[271px] bg-white mt-32 ml-6 rounded-lg shadow-makeReqModal font-figtree py-[30px] px-[50px]">
            <h2 className="text-2xl font-medium text-[#101828] mb-12 text-center">
                Remove Instruction
            </h2>
            <p className="text-center text-sm font-medium text-[#828282]">
                Are you sure you want to remove this instruction? Doing this removes the instruction from your Assistant’s Instruction list and would require that you create it again if you need it later.
            </p>
            <div className=" flex gap-[24px] mt-12">
                <button
                    onClick={handleClose}
                    className=" border border-[#D0D5DD] w-full py-2 rounded-lg text-sm font-semibold text-[#7F7f81]"
                >
                    Cancel
                </button>
                <button
                    disabled={loading}
                    onClick={handleDelete}
                    className="w-full py-2 rounded-lg bg-[#AF202D] text-sm text-white font-bold"
                >
                    {loading ? (
                        <span className="flex justify-center w-full">
                            <CgSpinner className=" animate-spin text-lg text-WHITE-_100" />
                        </span>
                    ) : (
                        <p className="text-WHITE-_100 text-[12px] font-medium">Remove</p>
                    )}
                </button>
            </div>
        </div>
    )
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaRegCopy } from "react-icons/fa";
import { GrPowerReset } from "react-icons/gr";
import axios from "axios";

import Whatsapp from "../../../Assets/svg/whatsApp.svg";
import Green from "../../../Assets/svg/dot_green.svg";
import Telegram from "../../../Assets/svg/telegram.svg";
import Internet from "../../../Assets/svg/internet.svg";
import Key from "../../../Assets/svg/key.svg";
import TokenService from "../../../services/token";
import ModalPop from "../../../component/modalPop";
import Peer from "../../../component/modals/kyc/whatsapp/Peer";
import PeerModal from "../../../component/modals/kyc/whatsapp/PeerModal";

import Phone from "../../../component/modals/kyc/whatsapp/Phone";
import DisconnectWhatsapp from "../../../component/modals/kyc/whatsapp/DisconnectWhatsapp";

import Otp from "../../../component/modals/kyc/whatsapp/Otp";

import { getApiKey } from "../../../features/apikey/apiKeySlice";
import { copyTextToClipboard } from "../../../helper";
import { resetSecretApiKey } from "../../../features/apikey/apiSecretKeySlice";
import { resetPublicApiKey } from "../../../features/apikey/apiPublicKeySlice";
import Success from "../../../component/modals/kyc/whatsapp/Success";

import { getPluginsChannel } from "../../../features/plugins/getPluginChannelSlice";
import { getCurrentPlan } from "@/../features/profile/getCurrentPlanSlice";
// import UpgradeToPlan from "@/../Routes/UpgradeToPlan";
// import RedeemSuccess from "@/../component/modals/redeemgift/RedeemSuccess";
// import UpgradePlan from "@/../Routes/UpgradePlan";
import { getGiftBalance } from "@/../features/profile/getGiftBalanceSlice";
import { getCreditBalance } from "@/../features/profile/getCreditBalanceSlice";
// import RedeemGift from "@/../component/modals/redeemgift";
// import Pay from "@/../component/modals/wallet/Pay";
import { useModal } from "@/../context/ModalContext";

const Integrate = ({ setShowDocumentationScreen }) => {
  const { openModal: openProModal } = useModal();
  const [openModal, setOpenModal] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [openPhoneModal, setOpenPhoneModal] = useState(false);
  const [messaging, setMessaging] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [copyPublicKey, setCopyPublicKey] = useState(false);
  const [copySecretKey, setCopySecretKey] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  // const [openUpgradeToPlan, setOpenUpgradeToPlan] = useState(false);
  // const [upgradeToPlan, setUpgradeToPlan] = useState(false);
  // const [openRedeemSuccess, setOpenRedeemSuccess] = useState(false);
  // const [openUpgradePlan, setOpenUpgradePlan] = useState(false);
  // const [openPayModal, setOpenPayModal] = useState(false);
  // const [show, setShow] = useState(false);
  // const [openRedeemGift, setOpenRedeemGift] = useState(false);

  const dispatch = useDispatch();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const plan = useSelector((state) => state.getCurrentPlan.data);
  const { subscriptions, currentPlan: current } = plan;
  const currentPlan = current?.subscription_details?.name;
  const nextAvailablePlanAmount = subscriptions?.[1]?.monthly_amount;
  console.log(currentPlan, nextAvailablePlanAmount, "currentPlan");

  let fromPeer = true;

  const peerWhatsApp = () => {
    if (openPhoneModal) {
      setOpenModal(false);
    } else if (openOtpModal) {
      setOpenPhoneModal(false);
      // setOpenModal(false);
    } else if (openSuccessModal) {
      setOpenOtpModal(false);
    } else {
      return null;
    }
  };
  const peerOtherServices = () => {
    if (openPhoneModal) {
      setOpenServiceModal(false);
    } else if (openOtpModal) {
      setOpenPhoneModal(false);
      // setOpenModal(false);
    } else {
      return null;
    }
  };

  // console.log(openPhoneModal, "star");

  const [peerNumber, setpeerNumber] = useState("");
  const [openPeerModal, setOpenPeerModal] = useState("");
  const [setup_status, setSetup_status] = useState("");

  const otherServiceNumber = localStorage.getItem("whatsapp");
  const URL = process.env.REACT_APP_BASEURL;

  useEffect(() => {
    peerWhatsApp();
    getIntegrationDetails();
  }, [openOtpModal, openPhoneModal, openSuccessModal, appDetail]);
  const token = TokenService.getToken();
  const [propData, setPropdata] = useState();
  const getIntegrationDetails = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${URL}/businesses/application/${appDetail.agent.id}/peer`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.length) {
        setPropdata(data[0]);
        setpeerNumber(data[0]?.phone_number);
        setSetup_status(data[0]?.setup_status);
      } else {
        setpeerNumber("");
        setSetup_status("");
      }
      const reposne = await dispatch(
        getPluginsChannel({ applicationId: appDetail.agent.id })
      );
    } catch (e) {
      console.log(e);
    }
  };
  //   useEffect(() => {
  //     dispatch(getPluginsChannel({ applicationId }))
  // }, []) //loading

  useEffect(() => {
    peerOtherServices();
  }, [openOtpModal, openPhoneModal, appDetail]);

  const apiKey = useSelector((state) => state.apiKey);
  const secretKey = useSelector((state) => state.secretApiKey);
  // const changeSecret = secretKey?.data?.status
  // console.log(secretKey, "sun")

  useEffect(() => {
    dispatch(getApiKey());
    dispatch(getCurrentPlan());
  }, []);

  function resetSecret() {
    if (!copySecretKey) {
      dispatch(resetSecretApiKey());
    } else {
      return null;
    }
  }

  function resetPublic() {
    if (!copyPublicKey) {
      dispatch(resetPublicApiKey());
    } else {
      return null;
    }
  }

  const handleOpenModal = () => {
    if (currentPlan.includes("Startups")) {
      return openProModal("ModalB", { title: "Integrate To WhatsApp", text: "Add your assistant to WhatsApp for your customers to interact with fast and easy", icon: "whatsapp" });
      // setOpenUpgradeToPlan(true);
    }
    return setOpenModal(true);
  };

  const id = JSON.parse(localStorage.getItem("cBus"))?.id;
  // const activeBusiness = useSelector(state => state.getBusiness.activeBusiness)
  // console.log(activeBusiness, "active")
  // const id = activeBusiness?.id

  useEffect(() => {
    dispatch(getGiftBalance(id));
    dispatch(getCreditBalance());
  }, [id, dispatch]);

  //   useEffect(() => {
  //     dispatch(getPluginsChannel({ applicationId }))
  // }, []) //loading
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const newAgent = getAgentApplication?.agent || null;
  console.log("is it here", newAgent);
  const [openDisconnectWhatsapp, setOpenDisconnectWhatsapp] = useState(false);
  return (
    <div className="flex gap-[79px]">
      <div className="w-[286px] h-[50px] flex flex-col gap-1">
        <p className="font-medium text-BLACK-_100 text-sm">Integrations</p>
        <p className="text-xs text-BLACK-_200">
          Integrate and deploy your employee to any of these channels.
        </p>
      </div>
      <div className="w-[595px]   border-BLACK-_400 border bg-WHITE-_100 rounded-lg">
        <div
          className={`flex  ${
            peerNumber ? "cursor-pointer" : ""
          } justify-between py-[15px] border-b`}
          onClick={() => {
            peerNumber && setOpenPeerModal(true);
          }}
        >
          <div className="flex gap-1 items-center gap-[9px] ml-[20px]">
            <img src={Whatsapp} className="w-[24px] h-[24px]" />
            <div className="flex flex-col">
              <p className="text-BLACK-_100 text-xs font-medium">Whatsapp</p>
              <p className="text-[10px] text-BLACK-_200">
                Integrate your sales employee to Whatsapp.
              </p>
            </div>
          </div>
          {/* <div className='flex flex-col gap-1 mt-[13px] ml-[52px]'>
                    <div className='flex justify-between'>
                        <p className='text-[12px] text-BLACK-_1000'>Peer your Message Service</p>
                        <div className='flex items-center'>
                            {(peerNumber && !openOtpModal) && 
                                <div className='flex items-center gap-1 mr-[10px]' > 
                                    <img src={Green} alt='dot' className="w-[9px] h-[9px]" />
                                    <p className='text-BLACK-_100 text-sm'>{peerNumber}</p>
                                </div>
                            }
                            
                            <button 
                                className="text-BLUE-_200 cursor-pointer mr-[20px] text-[10px] font-semibold py-2 px-2.5"
                                onClick={() => {setOpenModal(true); setMessaging(true)}}
                            >
                                Peer Service
                            </button>
                        </div>
                    </div>
                    <div className='flex justify-between'>
                        <p className='text-[12px] text-BLACK-_1000'>Peer other Service</p>
                        <div className='flex items-center'>
                            {(otherServiceNumber && !openOtpModal) && 
                                <div className='flex items-center gap-1 mr-[10px]' > 
                                    <img src={Green} alt='dot' className="w-[9px] h-[9px]" />
                                    <p className='text-BLACK-_100 text-sm'>{otherServiceNumber}</p>
                                </div>
                            }
                            
                            <button 
                                className="text-BLUE-_200 cursor-pointer mr-[20px] text-[10px] font-semibold py-2 px-2.5"
                                onClick={() => {setOpenServiceModal(true); setMessaging(false)}}
                            >
                                Peer Service
                            </button>
                        </div>
                    </div>
                </div> */}
          <div className="flex items-center">
            {peerNumber && (
              <div className="flex gap-2">
                <button
                  className={` border  capitalize  rounded-full   text-[12px] font-semibold py-2 px-2.5
 ${
   setup_status === "integrating"
     ? " border-[#D19C00] bg-[#FFF7DF] text-[#D19C00]"
     : "text-[#059C00] bg-[#BBFDB9] border border-[#059C00]"
 }
  
  `}
                >
                  {setup_status}
                </button>
                <button
                  className=" border text-RED-_100 mr-2 capitalize  rounded-full  text-[12px] font-semibold py-2 px-2.5
     bg-[#FAFAFA]  border-[#EEEEEE]
  
  "
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDisconnectWhatsapp(true);
                  }}
                >
                  Disconnect
                </button>
              </div>
            )}

            {!peerNumber && (
              <button
                className="text-WHITE-_100 cursor-pointer rounded-lg bg-BLACK-_500 mr-[20px] text-[10px] font-semibold py-2 px-2.5"
                onClick={handleOpenModal}
              >
                Integrate
                <span className="bg-[#1774FD] text-white px-[6px] py-[4px] rounded-[4px] ml-[8px]">
                  Pro
                </span>
              </button>
            )}
          </div>
        </div>
        {/* <div className="flex justify-between py-[15px] border-b">
          <div className="flex gap-1 items-center gap-[9px] ml-[20px]">
            <img src={Telegram} className="w-[24px] h-[24px]" />
            <div className="flex flex-col">
              <p className="text-BLACK-_100 text-xs font-medium">Telegram</p>
              <p className="text-[10px] text-BLACK-_200">
                Launch your assistant on Telegram.
              </p>
            </div>
          </div>
          <button className="text-BLACK-_200 cursor-pointer mr-[20px] text-xs font-semibold">
            Coming Soon
          </button>
        </div> */}
        <div className="flex justify-between py-[15px] border-b">
          <div className="flex items-center gap-[9px] ml-[20px]">
            <img src={Internet} className="w-[24px] h-[24px]" />
            <div className="flex flex-col">
              <p className="text-BLACK-_100 text-xs font-medium">
                Website or Custom App
              </p>
              <p className="text-[10px] text-BLACK-_200">
                Integrate your assistant to your business website or custom
                application.
              </p>
            </div>
          </div>

          <button
            className={`text-WHITE-_100 ${
              newAgent?.id ? "cursor-pointer" : "cursor-not-allowed"
            } rounded-lg bg-BLACK-_500 mr-[20px] text-[10px] font-semibold py-2 px-2.5`}
            onClick={() => setShowDocumentationScreen(true)}
            disabled={newAgent?.id ? false : true}
          >
            Configure
          </button>
          {/* <div
            className="text-BLUE-_200 bg-[#F0F6FF] rounded-[6px] py-1 px-2 text-xs mr-[20px] flex items-center"
            style={{
              "box-shadow": "0px 0px 2.4px 0px #86B7FF42",
            }}
          >
            Coming soon
          </div> */}
        </div>
      </div>

      <ModalPop isOpen={openModal}>
        <Peer
          handleClose={() => setOpenModal(false)}
          openPhoneModal={() => setOpenPhoneModal(true)}
          messaging={messaging}
        />
      </ModalPop>
      <ModalPop isOpen={openPeerModal}>
        <PeerModal
          handleClose={() => setOpenPeerModal(false)}
          handleDisconnect={() => {
            setOpenPeerModal(false);
            setOpenDisconnectWhatsapp(true);
          }}
          propData={propData}
        />
      </ModalPop>
      <ModalPop isOpen={openServiceModal}>
        <Peer
          handleClose={() => setOpenServiceModal(false)}
          openPhoneModal={() => setOpenPhoneModal(true)}
        />
      </ModalPop>

      <ModalPop isOpen={openDisconnectWhatsapp}>
        <DisconnectWhatsapp
          handleClose={() => {
            setOpenDisconnectWhatsapp(false);
            getIntegrationDetails();
          }}
          deleteSource={() => {
            setOpenDisconnectWhatsapp(false);
          }}
          peerNumber={propData?.id}
        />
      </ModalPop>
      <ModalPop isOpen={openPhoneModal}>
        <Phone
          handleClose={() => {
            setOpenPhoneModal(false);
            setOpenModal(true);
          }}
          openOtpViewModal={() => setOpenOtpModal(true)}
          setOpenPhoneModal={setOpenPhoneModal}
          messaging={messaging}
        />
      </ModalPop>
      <ModalPop isOpen={openOtpModal}>
        <Otp
          handleClose={() => setOpenOtpModal(false)}
          setOpenOtpModal={setOpenOtpModal}
          openSuccessViewModal={() => setOpenSuccessModal(true)}
        />
      </ModalPop>

      <ModalPop isOpen={openSuccessModal}>
        <Success
          handleClose={() => {
            setOpenSuccessModal(false);
          }}
          fromPeer={fromPeer}
        />
      </ModalPop>
      {/* <ModalPop isOpen={openUpgradeToPlan}>
        <UpgradeToPlan
          handleClose={() => {setOpenUpgradeToPlan(false)}}
          nextModal={() => setOpenUpgradePlan}
          setOpenUpgradePlan={setOpenUpgradePlan}
          amount={nextAvailablePlanAmount}
        />
      </ModalPop>

      <ModalPop isOpen={openUpgradePlan}>
        <UpgradePlan
          handleClose={() => setOpenUpgradePlan(false)}
          setOpenRedeemSuccess={setOpenRedeemSuccess}
          data={subscriptions?.[1]}
          refresh={() => dispatch(getCurrentPlan())}
          setOpenPayModal={setOpenPayModal}
        />
      </ModalPop>

      <ModalPop isOpen={openPayModal}>
        <Pay
          handleClose={() => setOpenPayModal(false)}
          insufficient
          setOpenRedeemGift={setOpenRedeemGift}
        />
      </ModalPop>

      <ModalPop isOpen={openRedeemGift}>
        <RedeemGift
          handleClose={() => setOpenRedeemGift(false)}
          back={() => {setOpenPayModal(true); setOpenRedeemGift(false)}}
          setShow={setShow}
          setOpenRedeemSuccess={setOpenRedeemSuccess}
          refresh={dispatch(getGiftBalance())}
        />
      </ModalPop>

      <ModalPop isOpen={openRedeemSuccess}>
        <RedeemSuccess
          handleClose={() => setOpenRedeemSuccess(false)}
          title={"Upgrade Successful"}
          text1={"You have successfully upgraded to the Business Plan"}
          label={"Done"}
          redirectPath="/settings?page=billing&category=plan"
        />
      </ModalPop> */}
    </div>
  );
};

export default Integrate;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import DashboardLayout from "../layout/DashboardLayout";
import ArrowUp from "../Assets/svg/arrow-up.svg";
import ArrowDown from "../Assets/svg/arrow-down.svg";
import Button from "../component/Button";
import ModalPop from "../component/modalPop";
import Name from "../component/modals/dashboard/Name";
import Verify from "../component/modals/kyc/Verify";
import { SuccessMessage } from "../Toast-Messages/Toastmessages";
import Peer from "../component/modals/kyc/whatsapp/Peer";
import CustomerGetStarted from "./CustomerGetStarted";
import Welcome from "../component/modals/welcome";
import { useDispatch, useSelector } from "react-redux";
import { createApplication } from "../features/application/createApplicationSlice";
import ChooseServices from "../Agent/ServiceLayout/Modal/ChooseService";
import axios from "axios";
import TokenService from "../services/token";

export default function GetStarted() {
  const [openNameModal, setOpenNameModal] = useState(false);
  const [success, setSuccess] = useState()
  const [openServicesModal, setOpenServicesModal] = useState(false);
  const [openPeerModal, setOpenPeerModal] = useState(false);
  const [openKycVerificationModal, setOpenKycVerificationModal] = useState(false);
  const [welcomeModal, setOpenWelcomeModal] = useState(false);
  const [openSuggestionModal, setOpenSuggestionModal] = useState(false);
  const [showAboutDropDown, setShowAboutDropDown] = useState(false);
  const [showConfigureDropDown, setShowConfigureDropDown] = useState(false);
  const [showKycDropDown, setShowKycDropDown] = useState(false);
  const [agentName, setAgentName] = useState("");
  const [agentDescription, setAgentDescription] = useState("");
  const [showDeployDropDown, setShowDeployDropDown] = useState(false);

  const [about, setAbout] = useState(false);
  const [banking, setBanking] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);

  const aboutDropDown = () => {
    setShowAboutDropDown((prev) => !prev);
  };
  const configureDropDown = () => {
    setShowConfigureDropDown((prev) => !prev);
  };
  const kycDropDown = () => {
    setShowKycDropDown((prev) => !prev);
  };
  const deployDropDown = () => {
    setShowDeployDropDown((prev) => !prev);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = localStorage.getItem("user");

  const launchModal = () =>
    "Sales" === "Sales"
      ? setOpenWelcomeModal(true)
      : setOpenWelcomeModal(false);

  const storeUser = () => {
    if (user === "userName") {
      return null;
    } else {
      launchModal();
    }
  };

    const { loading } = useSelector(state => state.createApplication);
    
    const submitForm = (values) => {
        const data = {
          name: values.agentName,
          description: values.description,
          type: 'banking',
          sale_agent_name : values.agentName
        }

        dispatch(createApplication(data))
        .then(() => {
            setSuccess("Application Created!");
            setOpenNameModal(false)
            setTimeout(() => {
                setSuccess(false)
                setBanking(true)
            }, 1000);
        })
    }
    
    const token = TokenService.getToken();
    const getAgentInfo = JSON.parse(localStorage.getItem("wano_agent"));
    const checkIfBusinessNameExist = getAgentInfo?.name;
    const checkIfBusinessFeatureExist = localStorage.getItem('Wano_Feature')
    const [getServiceData, setGetServiceData] = useState(null);
    const [openServicesModalData, setOpenServicesModalData] = useState(false);
    const URL = process.env.REACT_APP_BASEURL;
    const getKycStatus = localStorage.getItem("Kyc");

    const userInfo = useSelector((state) => state.userLogin);
    const client = userInfo?.user?.data?.user;

    const userDetails = useSelector((state) => state.userLogin);
    const id = userDetails?.user?.data?.user?.id;

    useEffect(() => {
        storeUser();
    }, []);

    const whatsApp = useSelector((state) => state.whatsAppAll);

    const checkIfWhatsAppExist = whatsApp?.data[0]?.status;

    const fetchData = async () => {
        try {
            const getServiceresponse = await axios.get(`${URL}/module`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setGetServiceData(getServiceresponse.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFetchUpdate = () => {
    console.log("fetch agent service");
  };

  return (
    <DashboardLayout title={"Get Started"} getKycStatus={getKycStatus}>
      {"Sales" === "Customer Service" ? (
        <CustomerGetStarted />
      ) : (
        <>
          <div className="mt-6 w-full flex justify-center overflow-x-hidden">
            <div className="flex w-[845px] mb-8 justify-center flex-col items-center">
              <div className="w-[651px] mx-auto flex flex-col justify-center items-center">
                <p className="text-base font-medium text-BLACK-_200">{`Welcome ${client?.first_name}`}</p>
                <p className="text-[32px] text-BLACK-_100 mt-2">
                  Setup your Digital Assistant
                </p>
                <p className="text-sm text-BLACK-_200 text-center">
                  Let’s get you started on setting up your Assistant to begin
                  conversing with your customers.
                </p>
              </div>

              <div className="w-full mt-6 flex flex-col gap-6">
                <div
                  className={`${
                    checkIfBusinessNameExist || agentName
                      ? "h-[44px]"
                      : showAboutDropDown
                      ? "shadow-sm h-[151px] border border-solid border-BLACK-_400 "
                      : "h-[44px] "
                  } p-3 rounded-2xl`}
                  style={{
                    backgroundColor: `${
                      checkIfBusinessNameExist
                        ? "rgba(130, 130, 130, 0.05)"
                        : showAboutDropDown
                        ? "#fff"
                        : "rgba(130, 130, 130, 0.05)"
                    }`,
                  }}
                >
                  <div
                    className="items-center cursor-pointer flex justify-between mx-2"
                    onClick={() => aboutDropDown()}
                  >
                    <p
                      style={{
                        color: `${showAboutDropDown ? "#121212" : "#828282"}`,
                      }}
                      className="text-base font-medium"
                    >
                      Tell us more about your Business
                    </p>
                    {checkIfBusinessNameExist || agentName ? (
                      <p className="text-BLUE-_200 text-xs font-semibold">
                        Completed
                      </p>
                    ) : (
                      <img
                        src={showAboutDropDown ? ArrowUp : ArrowDown}
                        alt="arrow-down"
                      />
                    )}
                  </div>

                  {checkIfBusinessNameExist || agentName
                    ? null
                    : showAboutDropDown && (
                        <div className="flex flex-col mx-2 mt-3.5 gap-3">
                          <p className="text-BLACK-_200 text-xs w-[627px]">
                            Set up your digital assistant with all the business
                            information it needs to connect and communicate with
                            your customers.
                          </p>
                          <div className="flex justify-between mt-[15px] items-center">
                            <Button
                              text="Get Started"
                              type="button"
                              className="bg-BLACK-_500 text-WHITE-_100 text-xs p-2 rounded-md font-medium"
                              onClick={() => setOpenNameModal(true)}
                            />
                            <p className="text-BLACK-_200 text-xs">
                              0% completed
                            </p>
                          </div>
                        </div>
                      )}
                </div>

                <div
                  className={`${
                    checkIfBusinessFeatureExist == 'configured'
                      ? "h-[44px] "
                      : showConfigureDropDown
                      ? "shadow-sm h-[151px] border border-solid border-BLACK-_400 "
                      : "h-[44px]"
                  } p-3 rounded-2xl`}
                  style={{
                    backgroundColor: `${
                      checkIfBusinessFeatureExist == 'configured'
                      ? "rgba(130, 130, 130, 0.05)"
                        : showConfigureDropDown
                        ? "#fff"
                        : "rgba(130, 130, 130, 0.05)"
                    }`,
                  }}
                >
                  <div
                    className="items-center cursor-pointer flex justify-between mx-2"
                    onClick={() => configureDropDown()}
                  >
                    <p
                      style={{
                        color: `${
                          showConfigureDropDown ? "#121212" : "#828282"
                        }`,
                      }}
                      className="text-base font-medium"
                    >
                      Configure your Business Features
                    </p>
                    {                    checkIfBusinessFeatureExist == 'configured'
 ? (
                      <p className="text-BLUE-_200 text-xs font-semibold">
                        Completed
                      </p>
                    ) : (
                      <img
                        src={showConfigureDropDown ? ArrowUp : ArrowDown}
                        alt="arrow-down"
                      />
                    )}
                  </div>
                  {                    checkIfBusinessFeatureExist == 'configured'

                    ? null
                    : showConfigureDropDown && (
                        <div className="flex flex-col mx-2 mt-3.5 gap-3">
                          <p className="text-BLACK-_200 text-xs w-[627px]">
                            Take your time to explore the available services
                            that best suit your business needs and add them to
                            your digital assistant to make them uniquely yours.
                          </p>
                          <div className="flex justify-between items-center">
                            <Button
                              text="Continue"
                              type="button"
                              onClick={() => setOpenServicesModalData(true)}
                              className="bg-BLACK-_500 text-WHITE-_100 text-xs p-2 rounded-md font-medium"
                            />
                            <p className="text-BLACK-_200 text-xs">
                              0% completed
                            </p>
                          </div>
                        </div>
                      )}
                </div>

                {/* <div 
                                    className={`${getKycStatus ? "h-[44px]" : showKycDropDown ? "shadow-sm h-[151px] border border-solid border-BLACK-_400 " : "h-[44px] "} p-3 rounded-2xl`}  
                                    style={{backgroundColor: `${getKycStatus ? "rgba(130, 130, 130, 0.05)" : showKycDropDown ? "#fff" : "rgba(130, 130, 130, 0.05)"}` }}
                                >
                                    <div className="items-center flex justify-between mx-2 cursor-pointer" onClick={() => kycDropDown()}>
                                        <p 
                                            style={{color: `${showKycDropDown ?  "#121212" : "#828282"}`}} 
                                            className="text-base font-medium">
                                                KYC Verification
                                        </p>
                                        {getKycStatus 
                                            ? <p className="text-BLUE-_200 text-xs font-semibold">Completed</p>
                                            : <img src={showKycDropDown ? ArrowUp : ArrowDown} alt="arrow-down" />
                                        }
                                    
                                    </div>
                                    {getKycStatus ? null : showKycDropDown && (
                                        <div className="flex flex-col mx-2 mt-3.5 gap-3">
                                            <p className="text-BLACK-_200 text-xs w-[627px]">
                                                Finish a brief verification process with ease by sharing both your CAC 
                                                document and ID card. This simple step ensures that our platform is a 
                                                safe space for every business.
                                            </p>
                                            <div className="flex justify-between items-center">
                                                <Button 
                                                    text="Continue"
                                                    onClick={() => setOpenKycVerificationModal(true)}
                                                    type="button"
                                                    className= "bg-BLACK-_500 text-WHITE-_100 text-xs p-2 rounded-md font-medium"
                                                />
                                                <p className="text-BLACK-_200 text-xs">0% completed</p>
                                            </div>
                                        </div>
                                    )}
                                </div> */}

                <div
                  className={`${
                    checkIfWhatsAppExist
                      ? "h-[44px]"
                      : showDeployDropDown
                      ? "shadow-sm h-[151px] border border-solid border-BLACK-_400 "
                      : "h-[44px] "
                  } p-3 rounded-2xl`}
                  style={{
                    backgroundColor: `${
                      checkIfWhatsAppExist
                        ? "rgba(130, 130, 130, 0.05)"
                        : showDeployDropDown
                        ? "#fff"
                        : "rgba(130, 130, 130, 0.05)"
                    }`,
                  }}
                >
                  <div
                    className="items-center flex justify-between mx-2 cursor-pointer"
                    onClick={() => deployDropDown()}
                  >
                    <p
                      style={{
                        color: `${showDeployDropDown ? "#121212" : "#828282"}`,
                      }}
                      className="text-base font-medium"
                    >
                      Test and Deploy your Sales Assistant
                    </p>
                    {checkIfWhatsAppExist ? (
                      <p className="text-BLUE-_200 text-xs font-semibold">
                        Completed
                      </p>
                    ) : (
                      <img
                        src={showDeployDropDown ? ArrowUp : ArrowDown}
                        alt="arrow-down"
                      />
                    )}
                  </div>
                  {checkIfWhatsAppExist
                    ? null
                    : showDeployDropDown && (
                        <div className="flex flex-col mx-2 mt-3.5 gap-3">
                          <p className="text-BLACK-_200 text-xs w-[627px]">
                            And finally, Test and Peer your digital assistant with your
                            custom WhatsApp number to start selling to your
                            customers on WhatsApp.
                          </p>
                          <div className="flex justify-between items-center">
                            <Button
                              text="Continue"
                              type="button"
                              onClick={() => navigate("/deploy")}
                              className="bg-BLACK-_500 text-WHITE-_100 text-xs p-2 rounded-md font-medium"
                            />
                            <p className="text-BLACK-_200 text-xs">
                              0% completed
                            </p>
                          </div>
                        </div>
                      )}
                </div>
              </div>
            </div>
          </div>
          {success && <SuccessMessage success={success} className="mt-5 mr-2" />}
          <ModalPop isOpen={openNameModal}>
            <Name
              handleClose={() => setOpenNameModal(false)}
              handleSubmitAgent={submitForm}
              loading={loading}
            />
          </ModalPop>

          {/* <ModalPop isOpen={openSuggestionModal}>
                        <Services handleClose={() => setOpenSuggestionModal(false)}/> 
                    </ModalPop> */}

          {/* {checkIfBusinessFeatureExist ? null : (
            <ModalPop isOpen={openServicesModal}>
              <Services
                agentName={agentName}
                agentDescription={agentDescription}
                handleClose={() => setOpenServicesModal(false)}
                banking={() => setBanking(true)}
                handleOpenServices={() => setOpenServicesModalData(true)}
              />
            </ModalPop>
          )} */}
          <ModalPop isOpen={openServicesModalData}>
            <ChooseServices
              handleClose={() => setOpenServicesModalData(false)}
              getServiceData={getServiceData}
              handleFetchUpdate={handleFetchUpdate}
              choosenServiceData={[null]}
            />
          </ModalPop>
          <ModalPop isOpen={openKycVerificationModal}>
            <Verify handleClose={() => setOpenKycVerificationModal(false)} />
          </ModalPop>

          <ModalPop isOpen={openPeerModal}>
            <Peer
              handleClose={() => setOpenPeerModal(false)}
              whatsapp={() => setWhatsapp(true)}
            />
          </ModalPop>

          <ModalPop isOpen={welcomeModal}>
            <Welcome handleClose={() => setOpenWelcomeModal(false)} />
          </ModalPop>
        </>
      )}
    </DashboardLayout>
  );
}

import { api } from '@/../services/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const initialState = {
  data: [], // Store fetched data
  currentItem: null, // Store current item
  agentId: null, // Store agentId
  pluginId: null, // Store plugin_id
};

// Async thunk function for fetching plugin agent features
export const fetchPluginAgentFeatures = createAsyncThunk(
  'features/fetchPluginAgentFeatures',
  async ({ itemId, agentId }, { rejectWithValue }) => {
    try {
      const res = await api.get(
        `/plugins/${itemId}/application/${agentId}/features`,
      );
      const featureData = res.data.data;
      const pluginId = featureData[0].application_module?.id;
      sessionStorage.setItem('plugin_id', pluginId);
      return featureData;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message)
    }
  }
);

const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setCurrentItemAndAgentId(state, action) {
      state.currentItem = action.payload.currentItem;
      state.agentId = action.payload.agentId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPluginAgentFeatures.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const { setCurrentItemAndAgentId } = featuresSlice.actions;

export default featuresSlice.reducer;

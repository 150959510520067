import { HiXMark } from "react-icons/hi2";
import { useState, useRef, useEffect } from "react";
import { Radio } from "@mui/material";
import { BsCheck } from "react-icons/bs";
import BRAND from "../../Assets/img/brand.svg";
import PROFESSIONAL from "../../Assets/img/professional.svg";
import FRIENDLY from "../../Assets/img/friendly.svg";
import YOUTHFUL from "../../Assets/img/youthful.svg";
import personalityBackground from "../../Assets/img/personalityBackground.svg";
import BRANDD from "../../Assets/img/brandd.svg";
import PROF from "../../Assets/img/prof2.svg";
import FRIEND from "../../Assets/img/friendd.svg";
import TRENDY from "../../Assets/img/trendy.svg";

export default function PersonalityModal({
  handleClose,
  handleSavePersonality,
  selectedPersonality,
}) {
  const scrollingContainerRef = useRef(null);
  const [selectedPersonalityModal, setSelectedPersonalityModal] =
    useState(selectedPersonality);

  const handleCheckboxChange = (checkboxName) => {
    setSelectedPersonalityModal(checkboxName);
  };

  const handleSaveChanges = () => {
    if (selectedPersonalityModal) {
      handleSavePersonality(selectedPersonalityModal);
    }

    handleClose();
  };

  const CheckedIcon = () => {
    return (
      <div className="w-5 h-5 bg-[#1774FD] rounded-[999px] flex justify-center items-center">
        <BsCheck className="text-white w-5 h-5" />
      </div>
    );
  };


  return (
    <div className=" w-[956px] h-fit bg-white ml-36 rounded-lg shadow-makeReqModal font-figtree relative">
      <header className=" pl-10 flex pr-6 justify-between pt-[20px] pb-2">
        <h2 className="text-[#101828] font-medium text-lg">
          Choose Personality
        </h2>
        <div
          className="border p-2 -mt-1 rounded-lg border-[#F7F7F7] cursor-pointer"
          onClick={handleClose}
        >
          <HiXMark className="text-[20px]   text-[#828282]" />
        </div>
      </header>
      <hr />
      <section className="pt-6 px-[40px]">
        <p className=" text-[#7F7F81] text-sm font-medium mb-6">
          Choose a personality for your assistant, you can make it trendy or a
          cooperate head.
        </p>
        <section className="flex gap-5 flex-wrap">
          <div
            className={`w-[264px] overflow-hidden border border-solid  rounded-xl bg-[#FAFAFA]  ${
              selectedPersonalityModal === "brand-specific"
                ? "border-[#1774FD]"
                : "border-[#E5E5E5]"
            }`}
          >
            <section
              className={`rounded-t-xl ${
                selectedPersonalityModal === "brand-specific"
                  ? " bg-[rgba(205,222,248,0.20)]"
                  : " bg-[white]"
              }`}
            >
              <div className="flex justify-between pt-3  px-[15px] items-center">
                <img src={BRAND} alt="template" className="w-8 h-8" />

                <Radio
                  sx={{
                    color: "#EBEBEB",
                    height: 20,
                    width: 20,
                    border: "1px",
                    background: "#fff",
                  }}
                  checkedIcon={<CheckedIcon />}
                  checked={selectedPersonalityModal === "brand-specific"}
                  onClick={() => handleCheckboxChange("brand-specific")}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
              <p className="px-[15px] text-base text-[#121212] font-medium">
                Brand/Specific
              </p>
              <p className="text-[#7F7F81] font-normal text-xs pb-[7px] px-[15px]">
                Your AI takes on the tone specified in your sources
              </p>
            </section>
            <img src={BRANDD} alt="background" />
          </div>
          <div
            className={`w-[264px] overflow-hidden border border-solid  rounded-xl bg-[#FAFAFA] ${
              selectedPersonalityModal === "professional"
                ? "border-[#1774FD]"
                : "border-[#E5E5E5]"
            }`}
          >
            <section
              className={` rounded-t-xl ${
                selectedPersonalityModal === "professional"
                  ? " bg-[rgba(205,222,248,0.20)]"
                  : " bg-[white]"
              }`}
            >
              <div className="flex justify-between pt-3 px-[15px] items-center">
                <img src={PROFESSIONAL} alt="template" className="w-8 h-8" />

                <Radio
                  sx={{
                    color: "#EBEBEB",
                    height: 20,
                    width: 20,
                    border: "1px",
                    background: "#fff",
                  }}
                  checkedIcon={<CheckedIcon />}
                  checked={selectedPersonalityModal === "professional"}
                  onClick={() => handleCheckboxChange("professional")}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
              <p className="px-[15px] text-base text-[#121212] font-medium">
                Professional/Formal
              </p>
              <p className="text-[#7F7F81] font-normal text-xs mb-[14px] px-[15px] pb-[7px] ">
                Your AI takes on a professional and competent tone
              </p>
            </section>
            <img src={PROF} alt="background" className="px-[15px]" />

          </div>
          <div
            className={`w-[264px] overflow-hidden border border-solid rounded-xl bg-[#FAFAFA] ${
              selectedPersonalityModal === "friendly"
                ? "border-[#1774FD]"
                : "border-[#E5E5E5]"
            }`}
          >
            <section
              className={` rounded-t-xl ${
                selectedPersonalityModal === "friendly"
                  ? " bg-[rgba(205,222,248,0.20)]"
                  : " bg-[white]"
              }`}
            >
              <div className="flex justify-between pt-3 px-[15px] items-center">
                <img src={FRIENDLY} alt="template" className="w-8 h-8" />

                <Radio
                  sx={{
                    color: "#EBEBEB",
                    height: 20,
                    width: 20,
                    border: "1px",
                    background: "#fff",
                  }}
                  checkedIcon={<CheckedIcon />}
                  checked={selectedPersonalityModal === "friendly"}
                  onClick={() => handleCheckboxChange("friendly")}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
              <p className="px-[15px] text-base text-[#121212] font-medium">
                Friendly/Informal
              </p>
              <p className="text-[#7F7F81] font-normal text-xs mb-[14px] px-[15px] pb-[3px]">
                Your AI takes on a friendly and Informal tone
              </p>
            </section>
            <img src={FRIEND} alt="background" className="mt-[-10px]" />

          </div>
          <div
            className={`w-[264px] overflow-hidden border  border-solid  rounded-xl  ${
              selectedPersonalityModal === "youthful"
                ? "border-[#1774FD]"
                : "border-[#E5E5E5]"
            }`}
          >
            <section
              className={` rounded-t-xl ${
                selectedPersonalityModal === "youthful"
                  ? "bg-[rgba(205,222,248,0.20)]"
                  : "bg-[white]"
              }`}
            >
              <div className="flex justify-between mb-1 pt-3 px-[15px] items-center">
                <img src={YOUTHFUL} alt="template" className="w-8 h-8" />

                <Radio
                  sx={{
                    color: "#EBEBEB",
                    height: 20,
                    width: 20,
                    border: "1px",
                    background: "#fff",
                  }}
                  checkedIcon={<CheckedIcon />}
                  checked={selectedPersonalityModal === "youthful"}
                  onClick={() => handleCheckboxChange("youthful")}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
              <p className="px-[15px] text-base text-[#121212] font-medium">
                Youthful/Trendy
              </p>
              <p className="text-[#7F7F81] font-normal text-xs pb-[7px] px-[15px]">
                Your AI takes on a youthful and Trendy tone, Suitable for
                younger audiences
              </p>
            </section>
            <img src={TRENDY} alt="background" />
       
          </div>
        </section>
        <section className="flex justify-end gap-[10px] mb-10 mt-6">
          <button
            onClick={handleClose}
            className="outline-none rounded-lg border border-solid border-[#D0D5DD] px-5 py-[10px] text-xs font-semibold text-[#828282]"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveChanges}
            className="outline-none py-[10px] px-5 bg-[#121212] rounded-lg  text-white text-xs font-semibold"
          >
            Save Changes
          </button>
        </section>
      </section>
    </div>
  );
}

import React, { useState } from 'react'
import { HiXMark } from 'react-icons/hi2'
import {CgSpinner} from 'react-icons/cg'
import axios from "axios";
import token from '../../../services/token';

export default function ChooseInstruction({
    handleClose,
    applicationId,
    handleFetchUpdate
}) {
    const [loading, setLoading] = useState(false)
    const [categoryName, setCategoryName] = useState('')
    const [categoryDescription, setCategoryDescription] = useState('')
    const URL = process.env.REACT_APP_BASEURL
    const tokens = token.getToken()

    const submit = async () => {
        if (!categoryName && !categoryDescription) return;
        setLoading(true)
        try {
            const res = await axios.post(
                `${URL}/dashboard/applications/${applicationId}/category`,
                {
                  name: categoryName,
                  description: categoryDescription
                }, 
                {
                  headers: {
                    Authorization: `Bearer ${tokens}`,
                    "Content-Type": "application/json",
                  },
                }
            ) 
            console.log(res)
            setLoading(false)
            handleFetchUpdate()
            handleClose()
        } catch(error) {
            setLoading(false)
        }
    }
    return (
        <div className=" w-[570px] h-[475px] bg-white ml-36 rounded-lg shadow-makeReqModal font-figtree relative mt-[150px]">
            <header className="pl-10 flex pr-6 justify-between py-[15px] items-center">
                <div className='flex flex-col gap-2'>
                    <h2 className="text-[#101828] font-medium text-[24px]">Add Instruction Category</h2>
                    <p className='text-[#7F7F81] text-xs'>Create a new category for your instructions.</p>
                </div>
                <div
                    className="border p-2 size-9 rounded-lg border-[#F7F7F7] cursor-pointer"
                    onClick={handleClose}
                >
                    <HiXMark className="text-[20px] text-[#828282]" />
                </div>
            </header>
            <hr />
            <div className='pl-10 pr-6 mt-[30px] flex flex-col gap-6'>
                <div className='flex flex-col gap-3'>
                    <label className='text-xs font-figtree text-[#101828]'>Category Name</label>
                    <input placeholder={'Card Request'} value={categoryName} onChange={(e) => setCategoryName(e.target.value)} className='text-[#101828] text-xs py-[10px] px-[14px] rounded-lg border border-[#D0D5DD] bg-white focus:outline-none w-full'/>
                </div>
                <div className='flex flex-col gap-3'>
                    <label className='text-xs font-figtree text-[#101828]'>Category Description</label>
                    <textarea placeholder={'Card Request'} value={categoryDescription} onChange={(e) => setCategoryDescription(e.target.value)} className='h-[108px] text-[#101828] text-xs py-[17px] px-[14px] rounded-lg border border-[#D0D5DD] bg-white focus:outline-none w-full'/>
                </div>
            </div>
            <div className='pl-10 pr-6 mt-[61px] w-full flex items-center justify-end gap-3'>
                <button onClick={handleClose} className="cursor-pointer outline-none py-3 w-auto px-5 border border-[#D0D5DD] rounded-lg text-[#828282] text-[14px] font-bold">
                    Cancel
                </button>
                {loading ? (
                    <button className="outline-none py-3 w-auto px-5 bg-[#121212] rounded-lg text-white text-[14px] font-bold flex justify-center">
                        <CgSpinner className=" animate-spin text-lg " />
                    </button>
                ) : (
                    <button onClick={submit} className="cursor-pointer outline-none py-3 w-auto px-5 bg-[#121212] rounded-lg text-white text-[14px] font-bold">
                        Save
                    </button>
                )}
            </div>
        </div>
    )
}

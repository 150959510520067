import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../services/api";
import { appUrls } from "../../services/urls";
import { setChatID } from "../testAgent/chatSlice";

const initialState = {
  loading: false,
  data: [],
  draft: {},
  draftChanges: [],
  error: "",
  config: {},
  agent: { type: "" },
};

export const getApplicationById = createAsyncThunk(
  "retrieve/retrieveApplication",
  async (values, { dispatch, rejectWithValue }) => {
    try {
      const res = await api.get(appUrls?.GET_APPLICATION_BY_ID_URL);
      // const { config } = res?.data
      return res?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);
export const getApplicationDraft = createAsyncThunk(
  "retrieve/retrieveApplicationDraft",
  async (values, { dispatch, rejectWithValue }) => {
    try {
      if (!values) return;
      const res = await api.get(`/dashboard/applications/${values}/draft`);
      return res?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);
const getChanges = (draft) => {
  const changesText = [];
  // HANDLE APPLICATION
  if (draft.application.length) {
    const EmployeeDetails = draft.application[0];
    const oldEmployee = EmployeeDetails.application;
    const oldObj = {
      name: oldEmployee.name,
      // type: oldEmployee.type,
      description: oldEmployee.description,
      personality_type: oldEmployee.personality_type,
      verbose: oldEmployee.verbose,
      deactivated: oldEmployee.deactivated,
      is_deleted: oldEmployee.is_deleted,
      icon_url: oldEmployee.icon_url,
      is_light: oldEmployee.is_light,
    };
    const newObj = {
      name: EmployeeDetails.name,
      // type: EmployeeDetails.type,
      description: EmployeeDetails.description,
      personality_type: EmployeeDetails.personality_type,
      verbose: EmployeeDetails.verbose,
      deactivated: EmployeeDetails.deactivated,
      is_deleted: EmployeeDetails.is_deleted,
      icon_url: EmployeeDetails.icon_url,
      is_light: EmployeeDetails.is_light,
    };
    const changes = {};
    console.log("old", oldObj, newObj);
    for (const key in newObj) {
      if (
        newObj.hasOwnProperty(key) &&
        // newObj[key] &&
        newObj[key] !== oldObj[key]
      ) {
        changes[key] = {
          oldValue: oldObj[key],
          newValue: newObj[key],
        };
      }
    }

    for (let key in changes) {
      if (key === "type") {
        changesText.push(
          `Assistant's category changed to ${EmployeeDetails.type}`
        );
      } else if (key === "deactivated") {
        changesText.push("Assistant active status has been Changed");
      } else if (key === "icon_url") {
        changesText.push("Assistant's image has been Changed");
      } else if (key === "is_light") {
        changesText.push("Assistant's light mode has been toggled");
      } else {
        changesText.push(`Assistant ${key} Changed`);
      }
    }
  }

  // HANDLE instructions
  draft.instructions
    .filter((e) => e.instruction?.still_in_draft)
    .forEach((element) => {
      changesText.push(
        `New instruction Created in ${element.category.name} Category`
      );
      // HANDLE ACTIVE/INACTIVE
      const newObj = {
        active: element.active,
      };
      const oldObj = {
        active: element.instruction.active,
      };

      const changes = {};
      for (const key in newObj) {
        if (newObj[key] !== oldObj[key]) {
          changes[key] = {
            oldValue: oldObj[key],
            newValue: newObj[key],
          };
        }
      }
      for (let key in changes) {
        if (key === "active") {
          changesText.push(
            `Assistant's ${element.instruction.name}s visibility has been Changed`
          );
        } else {
          changesText.push(`Assistant ${key} Changed`);
        }
      }
    });
  // HANDLE MEMORY
  draft.memory.forEach((element) => {
    if (element.purpose === "faq") {
      changesText.push(`Document Uploaded to FAQ Category`);
    } else {
      changesText.push(
        `Document Uploaded to ${
          element.purpose.charAt(0).toUpperCase() + element.purpose.slice(1)
        } Category`
      );
    }

    // HANDLE ACTIVE/INACTIVE
    const newObj = {
      active: element.active,
      status: element.status,
    };
    const oldObj = {
      active: element.main.active,
      status: element.main.status,
    };

    const changes = {};
    for (const key in newObj) {
      if (newObj[key] !== oldObj[key]) {
        changes[key] = {
          oldValue: oldObj[key],
          newValue: newObj[key],
        };
      }
    }
    for (let key in changes) {
      if (key === "active") {
        changesText.push(
          `Assistant's ${element.main.name}a visibility has been Changed`
        );
      } else {
        changesText.push(`Assistant ${key} Changed`);
      }
    }
  });
  draft.plugins.forEach((element) => {
    changesText.push(
      `${element.notification_url} urls has been added to ${element.main.features.name} feature`
    );
  });
  // HANDLE PLUGINS

  // console.log("changes ofr employuee", draft);

  return changesText;
};

export const getAnEmployee = createAsyncThunk(
  "retrieve/getAnEmployee",
  async (values, { rejectWithValue }) => {
    try {
      const res = await api.get(`/dashboard/applications/${values.id}`);
      console.log("here", res);
      return res?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

const getApplicationByIdSlice = createSlice({
  name: "retrieve",
  initialState,
  reducers: {
    // Define a reducer to update the name in the state
    setAgentName: (state, action) => {
      // state.agent.type = action.payload;
      state.agent = { ...state.agent, type: action.payload };
    },
    setAgent: (state, action) => {
      state.agent = action.payload;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getApplicationById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getApplicationById.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
      if (!action.payload.data.length) {
        state.agent = { type: "" };
      } else if (!state.agent?.type && action.payload.data.length) {
        state.agent = action.payload.data[0];
      }

      if (action.payload.data.length) {
        const [firstApplication] = action.payload.data;
        // state.agent = firstApplication;
        state.config = firstApplication.config || {};
      } else {
        // state.agent = { type: "" };
        state.config = {};
      }
    });
    builder.addCase(getApplicationDraft.fulfilled, (state, action) => {
      state.draft = action.payload.data;
      state.draftChanges = getChanges(action.payload.data);
    });
    builder.addCase(
      getApplicationById.rejected,
      (state, action) =>
        void ((state.loading = false),
        (state.data = []),
        (state.error = action.payload))
    );
    builder.addCase(
      getAnEmployee.fulfilled,
      (state, action) => void (state.agent = action.payload)
    );
  },
});
export const { setAgentName, setAgent, setConfig } =
  getApplicationByIdSlice.actions;
export default getApplicationByIdSlice.reducer;

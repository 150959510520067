import React from 'react'
import { useNavigate } from 'react-router'
import Star from "../Assets/svg/star.svg"

export default function MainAskAgent() {
    const navigate = useNavigate()

    return (
        <div className='w-full h-[95vh] bg-[#FAFBFC] flex flex-col items-center justify-center'>
            <div className='w-[599px] gap-4 mx-auto flex flex-col items-center justify-center' style={{marginTop:'-5%'}}>
                <img src={Star} alt="star" className='w-[42px] h-[41px]' />
                <h2 className='font-medium text-[#101828] text-lg'>Gain Valuable Insights</h2>
                <p className='text-center text-[#828282] text-base'>Get the most out of your Digital Assistant by sharing information about your business, FAQs, and Products/services. Uploading documents will make your assistant better at interacting with your customers and offering you insights on your business.</p>
                <button 
                    className='bg-[#121212] rounded-lg text-WHITE-_100 font-medium text-sm px-4 py-2 mt-4'
                    onClick={() => navigate("/agent")}
                > 
                    Setup Assistant
                </button>
            </div>
        </div>
    )
}

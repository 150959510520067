import React, { useState } from "react";
import Whatsapp from "../../../../Assets/svg/whatsApp.svg";
import BigPlus from "../../../../Assets/svg/BigPlus.svg";
import Stars from "../../../../Assets/svg/Stars.svg";
import Button from "../../../Button";
import ModalPop from "../../../modalPop";
import Phone from "./Phone";
import WhatsAppIcon from "../../../../Assets/svg/WhatsAppIcon.svg";
import { AiOutlineClose } from "react-icons/ai";
import dotsVerifyIcon from "../../../../Assets/svg/dotsVerifyIcon.svg";
import phone2 from "../../../../Assets/svg/phone2.svg";
import { useLocation, useNavigate } from "react-router";

const Peer = ({ handleClose, whatsapp, openPhoneModal }) => {
  // const [openPhoneModal, setOpenPhoneModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div
      className={`${
        location.pathname === "/getstarted"
          ? "w-[528px] h-fit gap-10"
          : "w-[520px] h-fit "
      } bg-WHITE-_100  mt-[60px] flex flex-col justify-center items-center rounded-lg py-3`}
    >
      <div className="w-full flex items-center justify-end px-[28px] py-[14px] border-b border-neutral-150">
        {/* <p className='text-lg font-medium text-[#101828]'>Setup App</p> */}
        <div
          className="w-[36px] h-[36px] border rounded-lg mr-3  flex cursor-pointer justify-center items-center p-2"
          onClick={handleClose}
        >
          <AiOutlineClose className="text-neutral-500" />
        </div>
      </div>
      <div
        className={`${
          location.pathname === "/getstarted" ? "w-[390px]" : "w-[430px]"
        }  flex flex-col gap-[12px] justify-center items-center`}
      >
        <div className="flex justify-between w-[106.48px] h-[40.32px] mt-10">
          <img
            src={Whatsapp}
            alt="whatsapp_icon"
            className="w-[40.32px] h-[40.32px]"
          />
          <img src={BigPlus} alt="plus icon" className="" />
          <img src={Stars} alt="Star icon" className="" />
        </div>
        {location.pathname === "/getstarted" ? (
          <p className="text-2xl font-medium text-BLACK-_600">
            Deploy To Whatsapp
          </p>
        ) : (
          <p className="text-2xl font-medium text-BLACK-_600">
            Integrate With Whatsapp
          </p>
        )}
        {location.pathname === "/getstarted" ? (
          <p className="text-sm font-normal text-BLACK-_300">
            We need 2 things from you to deploy your assistant on Whatsapp
          </p>
        ) : (
          <p className="text-sm font-normal text-BLACK-_300">
            To integrate your assistant with WhatsApp we need just 2 things
          </p>
        )}
      </div>

      <div className="mx-8 flex mt-10 flex-col gap-6 justify-center bg-[#FCFCFC] p-[26px_35px]">
        {" "}
        {/* w-[341px] h-[40px] */}
        <div className="flex gap-2">
          <img src={phone2} alt="phone icon" className="w-[12px] h-[14px]" />
          <div className="">
            <p className="text-xs font-semibold text-BLACK-_500">
              A phone number that is not connected to Whatsapp
            </p>
            <p className="text-xs font-normal text-[#828282]">
              We need a phone number that has not been connected to WhatsApp so
              as to create a Facebook business account with that number
            </p>
          </div>
        </div>
        <div className="flex gap-2">
          <img
            src={dotsVerifyIcon}
            alt="phone icon"
            className="w-[14px] h-[14px]"
          />
          <div className="">
            <p className="text-xs font-semibold text-BLACK-_500">
              Verification Code
            </p>
            <p className="text-xs font-normal text-[#828282]">
              An OTP code would be sent to your number to verify it.
            </p>
          </div>
        </div>
      </div>

      <div
        className={`${
          location.pathname === "/getstarted" ? "" : "mt-10"
        } w-full pr-8 h-[76px] flex items-center justify-end  gap-3`}
      >
          <Button
          text="No,Later"
          type="button"
          onClick={handleClose}
          className="bg-none cursor-pointer py-3 px-5 text-[#868686] font-semibold text-xs w-[91px]  border border-neutral-200  rounded-lg"
        />
        {location.pathname === "/getstarted" ? (
          <Button
            // onClick={() => setOpenPhoneModal(true)}
            text="Deploy (with 8540 words)"
            className="bg-BLACK-_100 text-xs py-3 px-5 cursor-pointer rounded-lg font-semibold text-WHITE-_100"
            type="button"
          />
        ) : (
          <Button
            onClick={openPhoneModal}
            text="Continue"
            className="bg-BLACK-_100 text-xs py-3 px-5 cursor-pointer rounded-lg font-semibold text-WHITE-_100"
            type="button"
          />
        )}
      
      </div>
      {location.pathname === "/getstarted" ? (
        <div className="flex mx-auto items-center w-[317px] h-[34px]">
          <p className="text-BLACK-_800 text-center text-xs">
            You will be deploying with{" "}
            <span className="text-bold">8540 Words.</span> Know what words mean{" "}
            <span
              className="text-BLUE-_200 underline text-xs cursor-pointer"
              onClick={() => navigate("/support")}
            >
              here
            </span>
          </p>
        </div>
      ) : null}

      {/* <ModalPop isOpen={openPhoneModal}>
            <Phone handleClose={() => setOpenPhoneModal(false)} whatsapp={whatsapp}/>
        </ModalPop> */}
    </div>
  );
};

export default Peer;

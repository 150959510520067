import React, { useEffect, useState } from 'react'
import axios from 'axios';
import TokenService from '../services/token';
export default function SelectCategory({select, setSelect, setCategoryName}) {
    const [display, setDisplay] = useState(false);
    const [services, setServices] = useState([])
    const token = TokenService.getToken()
    const URL = process.env.REACT_APP_BASEURL;
    const agentData = localStorage.getItem("wano_agent");
    const agentDetails = JSON.parse(agentData);
    const applicationId = agentDetails.id;

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        try {
            const cachedChoosenServiceData = JSON.parse(
                sessionStorage.getItem("choosenServiceData")
            );
    
            if (cachedChoosenServiceData) {
                setServices(cachedChoosenServiceData);
                return;
            }
    
            const ChoosenDataresponse = await axios.get(
                `${URL}/dashboard/applications/${applicationId}/category`,
                {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                }
            );
    
            setServices(ChoosenDataresponse.data.data);
            
    
            sessionStorage.setItem(
                "choosenServiceData",
                JSON.stringify(ChoosenDataresponse.data.data)
            );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
    };
    
    const options = [
        ...services,
        { name: 'Others (Please Specify)' },
    ];    

    console.log(options)
    
    return (
        <div className="relative">
            <button type="button" onClick={() => setDisplay(!display)} className="relative w-full cursor-pointer py-[10px] px-[14px] h-9 rounded-lg border border-[#D0D5DD] bg-white focus:outline-none" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
                <span className="flex items-center">
                    <span className="text-xs text-[#101828] font-figtree block truncate">{select}</span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                        <path d="M8.0013 1.3125L4.6237 4.6901L1.24609 1.3125" stroke="#121212" stroke-width="1.08566" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
            </button>
            <ul
                className={`absolute z-10 mt-1 w-full overflow-auto rounded-lg bg-white text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
                display ? "opacity-100 transition ease-in duration-100" : "opacity-0 h-0 transition ease-in duration-100"
                }`}
            >
                {options?.map((option) => {
                    return (
                        <li onClick={() => {setSelect(option.name); setCategoryName(option.name); setDisplay(false)}} className="text-[#101828] relative cursor-default select-none py-[10px] px-[14px]" id="listbox-option-0" role="option">
                            <div className="flex items-center cursor-pointer">
                                <span className="font-figtree block truncate">{option.name}</span>
                            </div>
                            {(select === option.name) && (
                                <span className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <rect width="20" height="20" rx="10" fill="#1774FD"/>
                                        <path d="M13.5984 7.27344L8.64844 12.2234L6.39844 9.97344" stroke="white" stroke-width="1.49994" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            )}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import TokenService from "../../../services/token";
import { CgSpinner } from "react-icons/cg";
import {
  ErrorMessage,
  SuccessMessage,
} from "@/../Toast-Messages/Toastmessages";
import { getPluginById } from "@/../features/plugins/getPluginByIdSlice";
import { useDispatch } from "react-redux";
// import { getAllPlugins } from "@/../features/plugins/getAllPluginsSlice";
// import { getPlugins } from "@/../features/plugins/getPluginsSlice";
import { updatePlugins } from '@/../features/plugins/updatePluginsSlice';

const AddSetUpURL = ({
  setup,
  state,
  handleClose,
  selectedAgent,
  fetchFeatures,
  featuresData,
}) => {
  const [notificationUrl, setNotificationUrl] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [error, setError] = useState();
  const [notificationError, setNotificationError] = useState(null);
  const [notificationSuccess, setNotificationSuccess] = useState(null);
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();
  const pluginId = sessionStorage.getItem('plugin_id')
  const featureId = state?.features[0]?.id;
  const applicationId = selectedAgent?.id
  const applicationPluginId = state?.id

  const dispatch = useDispatch()
//   console.log(featureId, "this is featureId from addSetupURL")

  // console.log(state, "this is the state prop in addSetUpURL from MyPlugins.jsx")
//   console.log(pluginId, "this is pluginId from addSetUpURL");
  // console.log(selectedAgent, "this is selected agent from addSetUpURL")

  const handleSingleUpdate = async () => {
    if (!notificationUrl) {
      setError("Notification url required!");
      setTimeout(() => {
        setError(null);
      }, 1000);
      return;
    }
    setSaveLoading(true);
    setNotificationUrl("");
    try {
      const res = await axios.put(
        `${URL}/dashboard/applications/${selectedAgent?.id}/plugins/${pluginId}/features/${featureId}`,
        {
          notification_url: notificationUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setSaveLoading(false);
      setNotificationSuccess("Successful");
      // handleToggle(index)
      fetchFeatures(selectedAgent?.id, pluginId);
        dispatch(getPluginById({ applicationId, applicationPluginId }))
        dispatch(updatePlugins({ applicationId, applicationPluginId }))
        // dispatch(getAllPlugins({ applicationId }));
        // dispatch(getPlugins({ applicationId }))
    } catch (error) {
      setSaveLoading(false);
      setNotificationError("Error");
    }
  };

  const removeNotificationSuccess = () => {
    setTimeout(() => {
      setNotificationSuccess(false);
    }, 1000);
  };

  const removeNotificationError = () => {
    setTimeout(() => {
      setNotificationError(false);
    }, 1000);
  };

  useEffect(() => {
    removeNotificationSuccess();
  }, [notificationSuccess]);

  useEffect(() => {
    removeNotificationError();
  }, [notificationError]);

  return (
    <div className="bg-WHITE-_100 w-[484px] h-[376px]  mt-[60px] rounded-lg">
      {" "}
      {/* h-[436px] */}
      <div className="flex justify-between h-[65px] items-center mx-4 my-1 ">
        <div className=" flex ml-6">
          <p className="font-medium text-lg text-BLACK-_600">
            {setup ? "Setup" : "Edit"} URL
          </p>
        </div>
        <button
          className="w-[30px] h-[30px] border border-[#F7F7F7] rounded-lg flex justify-center items-center p-2"
          onClick={handleClose}
        >
          <AiOutlineClose fill="#828282" />
        </button>
      </div>
      <hr />
      <div className="w-full px-[31px] pt-[35px] pb-[50px] gap-10 flex flex-col justify-center items-center">
        {" "}
        {/* gap-5 */}
        <div className="flex flex-col justify-start items-start gap-3">
          <label className="text-neutral-900 text-sm font-medium">
            URL Link
          </label>
          <div className="flex flex-col justify-start items-start gap-2">
            <input
              type="text"
              placeholder="Sterling/sample-app-api"
              className="text-sm text-[#828282] font-medium leading-normal focus-within:outline-none focus-visible:outline-none"
              value={notificationUrl}
              onChange={(e) => setNotificationUrl(e.target.value)}
            />
            <p className="text-zinc-500 text-xs font-normal">
              Set the webhook notification url for this service. This is
              Optional
            </p>
          </div>
        </div>
        <div className=" w-[420px] h-[231px] gap-3 flex flex-col">
          <button
            className="w-[420px] h-10 px-6 py-3 bg-neutral-900 rounded-lg justify-center items-center gap-2 cursor-pointer flex"
            type="submit"
            onClick={() => {
                handleSingleUpdate();
                setTimeout(() => { 
                    handleClose()
                }, 1500)
            }}
            // onClick={() =>{openPeerModal()} }
          >
            {saveLoading ? (
              <p>
                <CgSpinner className=" animate-spin text-lg " />
              </p>
            ) : (
              <p className="text-white text-sm font-bold leading-normal">
                Save
              </p>
            )}
          </button>
          <button
            className="bg-inherit h-[40px] p-3 cursor-pointer flex justify-center items-center"
            type="submit"
            onClick={() => handleClose()}
          >
            <p className="text-zinc-500 text-sm font-medium leading-normal">
              Cancel
            </p>
          </button>
        </div>
      </div>
      {error && <ErrorMessage error={error} />}
      {notificationError && <ErrorMessage error={notificationError} />}
      {notificationSuccess && <SuccessMessage success={notificationSuccess} />}
    </div>
  );
};

export default AddSetUpURL;

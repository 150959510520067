import React from "react";
import { Warning } from "../../Assets/svg";
import { useNavigate } from "react-router";

export default function CustomFeatures({ handleClose, data }) {
  const navigate = useNavigate();
  localStorage.setItem("agent_features", JSON.stringify(data));
  return (
    <div className="py-[30px] px-[50px] flex flex-col gap-6 bg-white rounded-[8px] h-fit  w-[582px] mt-10">
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-5 items-center w-full">
            <Warning />
            <p className="text-[24px] text-[#101828] font-medium">
              Features Identified
            </p>
          </div>
          {!!data.length && (
            <p className="text-[14px] font-medium text-[#828282] text-center">
              We found {data?.length} feature(s) from the document uploaded.
              <br />
              Create instructions on how you want your Assistant to complete
              sales for them.
            </p>
          )}
        </div>
        <div className="flex flex-col gap-[19px]">
          {!!data.length && (
            <p className="text-[14px] font-medium text-[#121212]">Features</p>
          )}
          <div className="flex flex-col gap-[25px]">
            {data.length > 0 ? (
              data.map((d) => (
                <p className="text-[14px] font-medium text-[#828282]">
                  {d.feature}
                </p>
              ))
            ) : (
              <p className="text-center">No Features</p>
            )}
          </div>
        </div>
      </div>
      {data.length > 0 ? (
        <div className="flex flex-col gap-3 items-center">
          <button
            onClick={() => navigate("/agent/create-feature")}
            className="outline-none py-3 w-full px-5 bg-[#121212] rounded-lg text-white text-[14px] font-bold"
          >
            Create Instructions
          </button>
          <p
            onClick={handleClose}
            className="text-[14px] font-bold text-[#868686] cursor-pointer"
          >
            Cancel
          </p>
        </div>
      ) : (
        <div className="flex flex-col gap-3 items-center">
          <button
            onClick={handleClose}
            className="outline-none py-3 w-full px-5 bg-[#121212] rounded-lg text-white text-[14px] font-bold"
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
}

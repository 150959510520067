import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "./Select";
import SelectCategory from "./SelectCategory";
import { Delete } from "../Assets/svg";
import Shape from "../Assets/svg/Shape65.svg";
import { getCountries } from "features/countries/getCountriesSlice";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import AtInput from "../Plugins/AllPluginType/components/AtInput";
import AtInput2 from "../Plugins/AllPluginType/components/AtInput2";
import AtInput3 from "../Plugins/AllPluginType/components/AtInput3";
import { ErrorMessage } from "@/../Toast-Messages/Toastmessages";
import MentionEditor from "@/../Plugins/AllPluginType/components/AtInput5";

//added forwardRef to pass down the ref
const FeatureForm = forwardRef(
  (
    {
      width,
      categoryName,
      instructionDescription,
      instructionDescriptionDefault,
      setInstructionDescription,
      instructionManual,
      setInstructionManual,
      instructionName,
      instructionNameDefault,
      setInstructionName,
      support,
      setCategoryName,
      setSupport,
      next,
      setNext,
      multipleInstructions,
      stepData, // new prop to receive step data
      setCountryCode,
      countryCode, //new prop for coutry code
      action, //new prop calls the submit function
      setErrorMsg,
      setTags,
      tags,
    },
    ref
  ) => {
    const [selectCategory, setSelectCategory] = useState(null);
    const storeCountries = useSelector((state) => state.getAllCountries);
    const [formSubmitted, setFormSubmitted] = useState(false);

    console.log(formSubmitted, "tag formSubmitted status");

    const {
      register,
      handleSubmit,
      control,
      setValue,
      getValues,
      clearErrors,
      formState: { errors, isValid },
      reset,
    } = useForm({
      defaultValues: {
        supportChannels: support,
        // how_it_works: stepData?.instructionManual || ''
      },
    });

    //used this to handle the dynamic form fields of the support_channel
    const { fields, append, remove } = useFieldArray({
      control,
      name: "supportChannels",
    });

    // Validation rules
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    const urlRegex =
      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

    useImperativeHandle(ref, () => ({
      submitForm: () => handleSubmit(onSubmit)(),
    }));

    const onSubmit = (data) => {
      console.log(data, "tag data before");

      if (!isValid || Object.keys(errors).length > 0) {
        // setFormSubmitted(true);
        // console.log(formSubmitted, "tag formSubmitted status 2");
        return;
      }

      action(); //triggers the submit function, it can be modified to accept the data directly
      console.log(data);
    };

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setValue(name, value);
      if (errors[name]) {
        clearErrors(name);
      }
    };

    // useEffect(() => {
    //   if (stepData?.instructionManual) {
    //     reset({
    //       how_it_works: stepData.instructionManual
    //     });
    //   }
    // }, [stepData, reset]);
    useEffect(() => {
      if (instructionManual) {
        reset({
          how_it_works: instructionManual || "",
        });
      }
    }, [instructionManual, reset]);

    useEffect(() => {
      if (instructionManual) {
        setValue("how_it_works", instructionManual, {
          shouldValidate: true,
        });
      }
    }, [instructionManual, setValue]);

    useEffect(() => {
      support?.forEach((item) => {
        // Check if the item is already in the form to avoid duplicates
        const existingItems = getValues("supportChannels");
        if (
          !existingItems?.find(
            (channel) => channel?.support_channel === item?.support_channel
          )
        ) {
          append(item); // Append new item if it's not already present
          const updatedSupportChannels = getValues("supportChannels")
            ? [...getValues("supportChannels")]
            : [];
          setSupport(updatedSupportChannels); // Update parent component
        }
      });
      // setValue('supportChannels', support);
    }, [support, append, getValues, setValue]);

    // useEffect to set state based on received stepData
    useEffect(() => {
      if (stepData) {
        setCategoryName(stepData.categoryName || "");
        setInstructionName(stepData.instructionName || "");
        setInstructionDescription(stepData.instructionDescription || "");
        setInstructionManual(stepData.instructionManual || "");
        setSupport(stepData.support || []);
      }
    }, [stepData]);

    const dispatch = useDispatch();

    const clearCategoryInput = () => {
      setSelectCategory(null);
      setCategoryName("");
    };

    const getCountiresAndStore = async () => {
      dispatch(getCountries());
    };

    useEffect(() => {
      getCountiresAndStore();
    }, []);

    const getAgentApplication = useSelector(
      (state) => state?.getApplicationByUserId
    );

    const fullAgent = getAgentApplication?.agent || null;

    return (
      <section className="flex justify-between">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`mt-11 flex flex-col gap-6 ${
            width ? "w-full" : "w-[711px]"
          }`}
        >
          <div className="flex flex-col gap-3">
            <label className="text-xs font-figtree text-[#101828] flex items-center">
              Name
              <span className="text-red-700 pl-1 pt-1.5">*</span>
            </label>
            {multipleInstructions ? (
              <>
                <input
                  value={instructionName}
                  {...register("name", { required: !instructionName })}
                  onChange={(e) => {
                    setInstructionName(e.target.value);
                    handleInputChange(e);
                  }}
                  placeholder={instructionNameDefault}
                  className={`instructionstep-1 text-[#101828] placeholder:text-[#101828] text-xs py-[10px] px-[14px] rounded-lg border ${
                    errors.name ? "border-[#AF202D]" : "border-[#D0D5DD]"
                  } bg-white focus:outline-none w-full`}
                />
                {errors.name && (
                  <span className="text-[#AF202D] text-xs">
                    Please fill this field
                  </span>
                )}
              </>
            ) : (
              <>
                <input
                  value={instructionName}
                  {...register("name", { required: !instructionName })}
                  onChange={(e) => {
                    setInstructionName(e.target.value);
                    handleInputChange(e);
                  }}
                  placeholder={"Card Request"}
                  className={`instructionstep-1 text-[#101828] text-xs py-[10px] px-[14px] rounded-lg border ${
                    errors.name ? "border-[#AF202D]" : "border-[#D0D5DD]"
                  } bg-white focus:outline-none w-full`}
                />
                {errors.name && (
                  <span className="text-[#AF202D] text-xs">
                    Please fill this field
                  </span>
                )}
              </>
            )}
          </div>
          {multipleInstructions ? (
            <div className="flex flex-col gap-3">
              <label className="text-xs font-figtree text-[#101828] flex items-center">
                Category
                <span className="text-red-700 pl-1 pt-1.5">*</span>
              </label>
              <SelectCategory
                select={selectCategory}
                setSelect={setSelectCategory}
                setCategoryName={setCategoryName}
              />
              {selectCategory === "Others (Please Specify)" &&
                (categoryName === "Others (Please Specify)" ? (
                  <input
                    value={""}
                    onChange={(e) => {
                      setCategoryName(e.target.value);
                      handleInputChange(e);
                    }}
                    className="text-[#101828] text-xs py-[10px] px-[14px] rounded-lg border border-[#D0D5DD] bg-white focus:outline-none w-full"
                  />
                ) : (
                  <input
                    value={categoryName}
                    onChange={(e) => {
                      setCategoryName(e.target.value);
                      handleInputChange(e);
                    }}
                    className="text-[#101828] text-xs py-[10px] px-[14px] rounded-lg border border-[#D0D5DD] bg-white focus:outline-none w-full"
                  />
                ))}
            </div>
          ) : // <div className="flex flex-col gap-3">
          //   <label className="text-xs font-figtree text-[#101828] flex items-center">
          //     Category
          //     <span className="text-red-700 pl-1 pt-1.5">*</span>
          //   </label>
          //   <input
          //     value={categoryName}
          //     disabled
          //     className="text-[#101828] text-xs py-[10px] px-[14px] rounded-lg border border-[#D0D5DD] bg-white focus:outline-none w-full"
          //   />
          // </div>
          null}
          <div className="flex flex-col gap-3">
            <label className="text-xs font-figtree text-[#101828] flex items-center">
              Description
              <span className="text-red-700 pl-1 pt-2">*</span>
            </label>
            <input
              type="text"
              value={instructionDescription}
              {...register("description", {
                required: !instructionDescription,
              })}
              onChange={(e) => {
                setInstructionDescription(e.target.value);
                handleInputChange(e);
              }}
              placeholder={
                multipleInstructions
                  ? instructionDescriptionDefault
                  : "e.g.: These instructions are for whenever a customer has a complaint about a Failed Transaction and would like it resolved."
              }
              className={`instructionstep-2 text-[#101828] ${
                multipleInstructions ? "placeholder:text-[#101828]" : ""
              } text-xs py-[12px] px-[14px] rounded-lg border ${
                errors.description ? "border-[#AF202D]" : "border-[#D0D5DD]"
              } bg-white focus:outline-none w-full`}
            />
            {errors.description && (
              <span className="text-[#AF202D] text-xs">
                Please fill this field
              </span>
            )}

            {/* {multipleInstructions ? (
          <textarea
            style={{resize: "none"}}
            rows="1"
            value={instructionDescription}
            onChange={(e) => setInstructionDescription(e.target.value)}
            placeholder={instructionDescriptionDefault}
            className=" text-[#101828] placeholder:text-[#101828] text-xs py-[12px] px-[14px] rounded-lg border border-[#D0D5DD] bg-white focus:outline-none w-full"
            // h-[108px] py-[17px]
          />
        ) : (
          <textarea
            rows="1"
            style={{resize: "none"}}
            value={instructionDescription}
            onChange={(e) => setInstructionDescription(e.target.value)}
            placeholder="e.g: These instructions are for whenever a customer has a complaint about a Failed Transaction and would like it resolved."
            className=" text-[#101828] text-xs py-[12px] px-[14px] rounded-lg border border-[#D0D5DD] bg-white focus:outline-none w-full"
          />
        )} */}
          </div>
          <div className="flex flex-col gap-3">
            <label className="text-xs font-figtree text-[#101828] flex items-center">
              {/* How It Works */}
              Enter Instructions
              <span className="text-red-700 pl-1 pt-1">*</span>
              <span className="text-[#7F7F81] ml-[5px] ">
                (Provide a Step by Step Description of how this instruction
                should work)
              </span>
            </label>
            <div className="instructionstep-3">
              <Controller
                control={control}
                name="how_it_works"
                rules={{ required: "This field is required" }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <div className="flex flex-col gap-1">
                    <AtInput3
                      type="textarea"
                      className={`h-[206px] text-[#101828] text-xs py-[17px] px-[14px] rounded-lg border ${
                        error ? "border-[#AF202D]" : "border-[#D0D5DD]"
                      } bg-white focus:outline-none w-full`}
                      value={instructionManual || value}
                      setTags={setTags}
                      tags={tags}
                      onChange={(e) => {
                        onChange(e.target.value);
                        setInstructionManual(e.target.value);
                      }}
                      onBlur={onBlur}
                      ref={ref}
                      // placeholder="e.g:&#10;When a customer complains about any transaction failure, ask them if they have:&#10;- retried the transaction &#10;- confirmed that the transaction details are correct&#10;If their response is affirmative, and their complaint is still the same redirect them to the support channels provided for further assistance."
                      placeholder="Enter your instruction here and press ‘@’ to use apps in your instructions"
                    />
                    {error && (
                      <span className="text-[#AF202D] text-xs">
                        Please fill this field
                      </span>
                    )}
                  </div>
                )}
              />
            </div>
            {/* <MentionEditor/> */}
            {/* <AtInput2
          type="textarea"
          className={`h-[206px] text-[#101828] text-xs py-[17px] px-[14px] rounded-lg border border-[#D0D5DD] bg-white focus:outline-none w-full`}
          placeholder="e.g:&#10;When a customer complains about any transaction failure, ask them if they have:&#10;- retried the transaction &#10;- confirmed that the transaction details are correct&#10;If their response is affirmative, and their complaint is still the same redirect them to the support channels provided for further assistance."
        /> */}
            {/* {errors.how_it_works && <span className="text-[#AF202D] text-xs">Please fill this field</span>} */}
          </div>
          <div className="flex flex-col gap-3">
            <label className="text-xs font-figtree text-[#101828] instructionstep-5">
              {fullAgent.type === "sale"
                ? "Sales"
                : fullAgent.type === "general"
                ? "Support or Sales"
                : "Support"}{" "}
              Channel{" "}
              <span className="text-[#7F7F81]">
                (Select where you’d like your assistant to redirect your
                customers to. Maximum of 3 channels)
              </span>
            </label>
            <div className="instructionstep-4">
              {" "}
              <Select
                setNext={setNext}
                next={next}
                support={support}
                setSupport={setSupport}
              />
            </div>
          </div>
          {support &&
            fields
              // ?.filter((s) => s?.support_channel?.trim() !== "")
              .map((s, index) => {
                return (
                  <div key={s.id} className="flex flex-col gap-3">
                    {" "}
                    {/* key={index} */}
                    <div className="w-full flex justify-between items-center">
                      <label className="text-xs font-figtree text-[#101828]">
                        {s.support_channel}
                      </label>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          remove(index); //this removes the dynamic input field
                          setSupport((prevSupport) =>
                            prevSupport.filter((item, i) => i !== index)
                          );
                        }}
                      >
                        <Delete />
                      </div>
                    </div>
                    <div
                      className={`border ${
                        errors.supportChannels &&
                        errors.supportChannels[index]?.website
                          ? "border-[#AF202D]"
                          : "border-[#D0D5DD]"
                      } rounded-lg flex p-[5px] h-[38px]`}
                    >
                      {s.support_channel === "Email Address" ? (
                        <div></div>
                      ) : s.support_channel === "Phone Number" ? (
                        <select
                          name="number"
                          id=""
                          className="text-xs border-t-0 m-0.5 border border-l-0 border-b-0 outline-none "
                          onChange={(e) => {
                            const val = e.target.value;
                            const selectedCountry =
                              storeCountries?.countries.find(
                                (country) => country.name === val
                              );
                            setCountryCode(selectedCountry.phone_code);
                          }}
                          value={
                            storeCountries?.countries.find(
                              (country) => country.phone_code === countryCode
                            )?.name
                          }
                        >
                          {storeCountries?.countries.map((country) => (
                            <option
                              className="flex flex-rowb"
                              key={country.code}
                              value={country.name}
                            >
                              {country.code} ({country.phone_code})
                            </option>
                          ))}
                        </select>
                      ) : (
                        <span className="text-[#828282] h-full px-[10px] font-medium text-[10px] bg-[#F2F2F2] rounded-[4px] flex items-center justify-center">
                          https://
                        </span>
                      )}
                      <input
                        type={`${
                          s.support_channel === "Phone Number"
                            ? "number"
                            : "text"
                        }`}
                        // value={s.website}
                        {...register(`supportChannels[${index}].website`, {
                          required: "Please fill this field",
                          validate: (value) => {
                            if (s.support_channel === "Email Address")
                              return (
                                emailRegex.test(value) ||
                                "Invalid email address"
                              );
                            if (s.support_channel === "Phone Number")
                              return (
                                phoneRegex.test(value) || "Invalid phone number"
                              );
                            return urlRegex.test(value) || "Invalid URL";
                          },
                        })}
                        // onChange={(e) => handleDynamicInputChange(e, index)}
                        className="w-full focus:outline-none border-none text-xs font-figtree text-[#101828]"
                      />
                    </div>
                    {errors.supportChannels &&
                      errors.supportChannels[index]?.website && (
                        <p className="w-full text-[#AF202D] text-xs">
                          {errors.supportChannels[index].website.message}
                        </p>
                      )}
                  </div>
                );
              })}
          {/* HOW It Works */}
          {formSubmitted && !isValid && (
            <ErrorMessage error={"Please fill all fields!"} />
          )}
        </form>

        <div
          className="border border-[#D0D5DD] h-fit w-[330px] mt-[70px] px-[20px] py-[14px] rounded-[8px]"
          style={{
            boxShadow: "0px 4px 8px 1px rgba(215, 215, 215, 0.25)",
            background: "linear-gradient(160deg,#F8C4D326 2%,  #1774FD08 68%)",
          }}
        >
          <div className="flex gap-[8px] items-center">
            <img src={Shape} alt="shape" className="w-[23.63px] h-[23.63px]" />
            <p className="text-[#828282] text-[12px] leading-[18px]">Example</p>
          </div>
          <div className="text-[12px] leading-[20px] mt-[10px]">
            <p>Try to be specific when creating an Instruction or Guideline:</p>
            <p>
              For e.g: “Whenever a user wants to see a product demo, direct them
              to our demo link
            </p>
            <p>
              Make sure to{" "}
              <span className="font-medium">
                provide links referred to in the instructions under support
                channels.
              </span>
            </p>
          </div>
        </div>
      </section>
    );
  }
);

export default FeatureForm;

{
  /* <AtInput>
  <textarea
    value={instructionManual}
    {...register("how_it_works", { required: !instructionManual })}
    onChange={(e) => {setInstructionManual(e.target.value); handleInputChange(e)}}
    className={`h-[206px] text-[#101828] text-xs py-[17px] px-[14px] rounded-lg border ${errors.how_it_works ? 'border-[#AF202D]' : 'border-[#D0D5DD]'} bg-white focus:outline-none w-full`}
    placeholder="e.g:&#10;When a customer complains about any transaction failure, ask them if they have:&#10;- retried the transaction &#10;- confirmed that the transaction details are correct&#10;If their response is affirmative, and their complaint is still the same redirect them to the support channels provided for further assistance."
  />
</AtInput> */
}

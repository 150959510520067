import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit"

import { api } from "../../services/api"
import { appUrls } from "../../services/urls"


const initialState = {
    loading: false,
    data: [],
    error: ""
}

export const bulkTopUp = createAsyncThunk(
    "billing/bulkTopUp", 
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.post(appUrls?.BULK_URL, data);
            console.log(res, "pako")
            return res?.data?.data
        }
        catch (err) {
            return rejectWithValue(err.data.message)
        }
    }
)


const walletBulkSlice = createSlice({
    name: "billing",
    initialState,
    extraReducers: builder => {
        builder.addCase(bulkTopUp.pending, state => {
            state.loading = true
    });
        builder.addCase(bulkTopUp.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(bulkTopUp.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});

export default walletBulkSlice.reducer
import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router'

import Qr from "../../../Assets/svg/qr_code_a.svg"
import QrCode from "../../../Assets/svg/qr_code_b.svg"
import { useAuth } from '../../../context/UserContext'
import { AiOutlineClose } from 'react-icons/ai'
import { QRCodeSVG } from 'qrcode.react'
import { ErrorMessage, SuccessMessage } from '@/../Toast-Messages/Toastmessages'
import { useDispatch } from 'react-redux'
import { setupTwoFA } from '@/../features/twofa/setupTwoFaSlice'

const TwoFA = ({ handleClose, secret }) => {
    const [mfa, setMFA] = useState();
    const [success, setSuccess] = useState();
    const [error, setError] = useState();
    
    const { toggleMFA } = useAuth();
    const dispatch = useDispatch();

    const handleToggle = async () => {
        await toggleMFA(setMFA)
    }

    const handleSubmit = async (values) => {
        try {
            await dispatch(setupTwoFA({ "code": values.otpCode }));
            setSuccess("Two-factor authentication set up successfully.");
            setTimeout(() => {
                setSuccess(null);
              }, 1000);
            setError(null);
            handleClose()
        } catch (error) {
            setError("Failed to set up two-factor authentication. Please try again.");
            setTimeout(() => {
                setError("");
              }, 1000);
        }
    }

    // useEffect(() => {
    //     dispatch(setupTwoFA())
    // }, [dispatch,])

    const navigate = useNavigate();

  return (
    <div className="bg-WHITE-_100 w-[522px] h-fit mt-[40px] p-5 rounded-lg"> {/* h-[590px] */}
        <div className="flex justify-end h-[35px] items-center mx-4  ">
            <button className="w-[30px] h-[30px] border border-[#F7F7F7] rounded-lg flex justify-center items-center p-2" onClick={handleClose}>
                <AiOutlineClose fill='#828282'/>
            </button>
        </div>
        <div className="flex flex-col justify-center items-center gap-2 mx-auto w-[338px] h-[91px]">
            <img src={Qr} alt='qr-code' className='w-[24px] h-[24px]' />
            <p className="text-[#101828] text-2xl font-medium">Set up 2FA</p>
            <p className="text-sm text-[#828282] font-normal">Scan the QR Code below with your authenticator app.</p>
        </div>
        <div className="flex w-[420px] mx-auto h-[220px] justify-center py-[20px] mt-5 rounded-lg" style={{ background: "rgba(241, 241, 241, 0.39)"}}>
            {/* <img src={QrCode} alt='qr-code' className='flex'  /> */}
            <div className='w-fit bg-white p-[10px] rounded-lg'>
                <QRCodeSVG
                    value={secret}
                    size={160}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"L"}
                    includeMargin={false}
                    // imageSettings={{
                    //     src: "https://static.zpao.com/favicon.png",
                    //     x: undefined,
                    //     y: undefined,
                    //     height: 24,
                    //     width: 24,
                    //     excavate: true,
                    // }}
                    />
            </div>
        </div>
        <div className='w-[395px] mx-auto mt-5'>
            <Formik
                initialValues={{
                    otpCode: "",
                }}
                // validationSchema={formValidationSchema}
                // onSubmit={(values) => {
                //     // window.scrollTo(0, 0)
                //     // submitForm(values)
                //     // handleClose()

                // }}
                onSubmit={(values) => handleSubmit(values)}
            >
                {({
                    handleSubmit,
                    handleChange,
                    dirty,
                    isValid,
                    setFieldValue,
                    errors,
                    touched,
                    // setFieldTouched,
                    values,
                }) => (
                    <Form onSubmit={handleSubmit} className="flex justify-center">
                        <div className='flex flex-col items-center gap-6 '>
            
                            <div className="mb-2">
                                <label htmlFor='Otp' className="text-sm font-normal text-[#101828]">Enter OTP Code from your Authenticator</label>
                                    {/* Tablet and Laptop */}
                                <div className="mt-3 xs:hidden md:block">
                                    <OtpInput
                                        name="otpCode"
                                        value={values.otpCode}
                                        onChange={(e) => setFieldValue("otpCode", e)}
                                        // placeholder="123456"
                                        numInputs={6}
                                        renderSeparator={<span style={{ width: "8px" }}></span>}
                                        renderInput={(props) => <input {...props} />}
                                        isInputNum={true}
                                        shouldAutoFocus={true}
                                        inputStyle={{
                                            border: "1px solid #D0D5DD",
                                            boxShadow: "0px 0.92453px 1.84906px 0px rgba(16, 24, 40, 0.05)",
                                            borderRadius: "8px",
                                            width: "59px",
                                            height: "59px",
                                            fontSize: "16px",
                                            color: "#000",
                                            fontWeight: "400",
                                            caretColor: "black",
                                            outline: "none"
                                        }}
                                        focusStyle={{
                                            border: "1px solid #D0D5DD",
                                            outline: "none",
                                        }}
                                    />
                                    {/* {errors.otpCode && touched.otpCode ? (
                                        <div className='text-RED-_100'>{errors.otpCode}</div>   
                                    ) : null} */}
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <button 
                                    className="w-[420px] h-[40px] py-3 px-5 rounded-lg flex justify-center items-center text-bold bg-BLACK-_500 text-WHITE-_100 text-sm"
                                    type="submit"
                                    // onClick={handleClose}
                                >
                                    Complete 2fa
                                </button>
                                    {/*  bg-BLACK-_500 text-WHITE-_100 */}
                                <button 
                                    className="w-[420px] h-[40px] py-3 px-5 rounded-lg flex justify-center items-center text-bold text-[#868686] text-sm"
                                    type="submit"
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                            </div>
                        
                        </div>
                        

                    </Form>
                )}
            </Formik>
        </div>
        {success && <SuccessMessage success={success} />}
        {error && <ErrorMessage error={error} />}
    </div>
  )
}

export default TwoFA
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from "../../services/api"


const initialState = {
    loading: false,
    data: [],
    error: ""
};

export const connectWhatsapp = createAsyncThunk(
    "whatsapp/connectWhatsapp", 
    async({applicationId, data}, { rejectWithValue }) => {
        try {
            const res = await api.post(`/dashboard/applications/${applicationId}/session/connect`, data);
            console.log(res, "log")
            return res?.data

        } catch (error) {
            console.log(error, "error")
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)

const whatsAppConnectSlice = createSlice({
    name: "whatsapp",
    initialState,
    extraReducers: builder => {
        builder.addCase(connectWhatsapp.pending, state => {
            state.loading = true
        });
        builder.addCase(connectWhatsapp.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(connectWhatsapp.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default whatsAppConnectSlice.reducer
import React from 'react'
import WanoLogo from '../Assets/wano logo 1 1.png'

export default function NavBar() {
  return (
    <nav className="flex gap-2 mt-6 ml-6 items-center">
      <img src ={WanoLogo} alt="Wano logo" className="w-[27px] h-[27px]"/>
      <p className="text-[22px] w-[57px] text-[#101828] font-medium">Wano</p>
    </nav>
  )
}

import React, { useState, useEffect } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import AnalyticsBtn from "../Analytics/AnalyticsBtn";
import AnalyticsContentSales from "../Analytics/AnalyticsContentSales";
import { UserContext } from "../context/UserContext";
import { useContext } from "react";
import AnalyticsContentAuth from "../Analytics/AnalyticsContentAuth";
import TokenService from "../services/token";
import axios from "axios";
import { useSelector } from "react-redux";


export default function Analytics() {
  const { userType } = useContext(UserContext);
  const [activeButton, setActiveButton] = useState("today");
  const [data, setData] = useState([]);
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const getdata = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = getdata && getdata.length >= 1 ? getdata[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;
  useEffect(() => {
    const fetchData = async () => {
      if (!applicationId) {
        console.error(
          "No applicationId provided. fetchData function will not run."
        );
        return;
      }
      try {
        const response = await axios.get(
          `${URL}/dashboard/applications/${applicationId}/analytics?period=${activeButton}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const result = response.data.data;
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [activeButton]);
  return (
    <DashboardLayout title={"Analytics"} user={true} padd={true}>
      {"Sales" === "Sales" && (
        // ! "Sales" === sales
        <main>
          <AnalyticsBtn
            handleButtonClick={handleButtonClick}
            activeButton={activeButton}
          />
          <AnalyticsContentSales data={data}/>
        </main>
      )}
      {"Sales" === "Authentication" && (
        // ! "Sales" === authentication
        <main>
          <AnalyticsBtn
            handleButtonClick={handleButtonClick}
            activeButton={activeButton}
            data={data}
          />
          <AnalyticsContentAuth />
        </main>
      )}
      {"Sales" === "Customer Service" && (
        // ! "Sales" === customer
        <main>
          <AnalyticsBtn
            handleButtonClick={handleButtonClick}
            activeButton={activeButton}
            data={data}
          />
          <AnalyticsContentSales blur={true} />
        </main>
      )}
    </DashboardLayout>
  );
}

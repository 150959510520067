import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { Link, useParams } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import { Features } from "../Assets/svg";
import ModalPop from "../component/modalPop";
import EditFeatureModal from "../Agent/EditFeatureModal";
import DeleteInstructionModal from "../Agent/DeleteInstructionModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TokenService from "../services/token";

import salesIcon from "../Assets/img/salesIcon.svg";
import enquiryIcon from "../Assets/img/enquiriesIcon.svg";
import complainIcon from "../Assets/img/complaintsIcon.svg";
import requestIcon from "../Assets/img/requestsIcon.svg";
import customIcon from "../Assets/img/customIcon.svg";
import customIcon1 from "../Assets/img/customIcon1.svg";
import customIcon2 from "../Assets/img/customIcon2.svg";
import { useDispatch } from "react-redux";
export default function EditFeature() {
  const { type, name, id, mId } = useParams();
  const [openFeature, setOpenFeature] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const token = TokenService.getToken();
  const URL = process.env.REACT_APP_BASEURL;
  const agentData = localStorage.getItem("wano_agent");
  const agentDetails = JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const [instructionData, setInstructionData] = useState(null);
  const [done, setDone] = useState(false)
  const dispatch = useDispatch();

  // console.log(type, name, "hahahaha");
  const start_date = instructionData?.start_date;
  const end_date = instructionData?.end_date;

  const convert_date = (dat) => {
    const date = new Date(dat);
    const readableDate = date.toLocaleDateString(); 
    const readableDateTime = date.toLocaleString(); 
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  useEffect(() => {
    if (!applicationId) return;
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await axios.get(
      `${URL}/dashboard/applications/${applicationId}/category/${id}/instructions/${mId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    setInstructionData(res.data.data.draft ?? res.data.data);
    // console.log(res.data.data.draft ?? res.data.data, "pipeeeeRT");
  };
  const navigate = useNavigate();

  const tabs = ["Memory", "Features", "Settings", "Integrations/Deploy"];

  const handleButtonClick = (location) => {
    if (location === "Features") {
      navigate("/agent", { state: "features" });
    } else if (location === "Settings") {
      navigate("/agent", { state: "settings" });
    } else if (location === "Integrations/Deploy") {
      navigate("/agent", { state: "Integrations/Deploy" });
    } else {
      navigate("/agent", { state: "Memory" });
    }
  };
  function hashToIndex(inputString) {
    // Calculate the sum of character codes in the input string
    if (!inputString) return 0;
    let sum = 0;
    for (let i = 0; i < inputString.length; i++) {
      sum += inputString.charCodeAt(i);
    }

    // Map the sum to one of the desired output values (0, 1, or 2)
    return sum % 3;
  }
  return (
    <DashboardLayout title={"Assistant"} user={true}>
      <section className="flex font-figtree relative w-full">
        <div className="py-8 px-6 h-full  shrink-0">
          <ul className="flex flex-col gap-4 w-[135px]">
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={`py-2.5 px-4 cursor-pointer rounded-md text-sm ${
                  tab === "Features"
                    ? "text-PRIMARY bg-[#FAFAFA]"
                    : "text-SECONDARY"
                }`}
                onClick={() => handleButtonClick(tab)}
              >
                <span>{tab === "Features" ? "Instructions" : tab}</span>
              </li>
            ))}
          </ul>
        </div>
        <main className="font-figtree w-full p-10 mb-[100px] border-l border-[#F7F7F7]">
          <header className="flex gap-1 items-center mb-4">
            <Link
              to={`/agent/${type}/${id}`}
              className="text-[#D2D0D0] text-sm font-normal hover:underline"
            >
              {type}
            </Link>
            <BiChevronRight className=" text-xl text-[#D2D0D0]" />
            <p className="text-[#121212] text-sm font-normal ">{instructionData?.name}</p>
          </header>
          <div className="mt-[33px] flex flex-col gap-10">
            {instructionData?.name.toLowerCase() === "complaints" ? (
              <img src={complainIcon} alt="complaints" className="w-16 h-16" />
            ) : instructionData?.name.toLowerCase() === "enquiries" ? (
              <img src={enquiryIcon} alt="enquiries" className="w-16 h-16" />
            ) : instructionData?.name.toLowerCase() === "requests" ? (
              <img src={requestIcon} alt="requests" className="w-16 h-16" />
            ) : instructionData?.name.toLowerCase() === "sales" ? (
              <img src={salesIcon} alt="sales" className="w-16 h-16" />
            ) : hashToIndex(instructionData?.id) === 0 ? (
              <img src={customIcon} alt="sales" className="w-16 h-16" />
            ) : hashToIndex(instructionData?.id) === 1 ? (
              <img src={customIcon1} alt="sales" className="w-16 h-16" />
            ) : (
              <img src={customIcon2} alt="sales" className="w-16 h-16" />
            )}
            <div className="w-full flex justify-between items-center">
              <div className="flex flex-col gap-2">
                <h1 className="text-[24px] font-medium text-[#121212] font-figtree">
                  {instructionData?.name}
                </h1>
                <p className="text-xs text-[#7F7F81] font-figtree">
                  {instructionData?.description}
                </p>
              </div>
              <div className="flex gap-3 items-center">
                <button
                  onClick={() => setOpenFeature(!openFeature)}
                  className="outline-none py-3 w-auto px-5 bg-[#121212] rounded-lg text-white text-[14px] font-bold"
                >
                  Edit
                </button>
                <button
                  onClick={() => setDeleteModal(true)}
                  className="outline-none py-3 w-auto px-5 bg-[#FAFAFA] rounded-lg text-RED-_100 border border-[#EEEEEE] text-[14px] font-bold"
                >
                  Delete
                </button>
              </div>
            </div>
            <div className="flex gap-[31px] items-center">
              <div className="flex flex-col gap-1">
                <label className="text-xs font-medium text-[#7F7F81]">
                  Category
                </label>
                <p className="text-[#121212] font-medium font-figtree">
                  {type}
                </p>
              </div>
              {/* <div className='flex flex-col gap-1'>
                            <label className='text-xs font-medium text-[#7F7F81]'>Sub Category</label>
                            <p className='text-[#121212] font-medium font-figtree'>Banking</p>
                        </div> */}
            </div>
            <div className="flex flex-col gap-6">
              <h3 className="text-[18px] font-medium text-[#121212]">
                Support Channels
              </h3>
              <div className="flex gap-5 items-center">
                {instructionData?.support_channel?.map((channel, index) => (
                  <div
                    key={index}
                    className="w-[245px] h-[58px] px-[15px] justify-center border border-[#EAECF0] rounded-md flex flex-col gap-2"
                  >
                    <label className="text-xs font-medium text-[#7F7F81]">
                      {channel?.support_channel}
                    </label>
                    <p className="text-[#121212] text-xs font-medium font-figtree">
                      {channel?.website}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <h3 className="text-[18px] font-medium text-[#121212]">
                How it works
              </h3>
              <p className="text-sm text-[#7F7F81]">
                {instructionData?.how_it_works}
              </p>
            </div>
            {type === "Scheduled Instructions" && (
              <>
                <div className="flex flex-col gap-4">
                  <h3 className="text-[18px] font-medium text-[#121212]">
                    Start Date
                  </h3>
                  <p className="text-sm text-[#7F7F81]">
                    {convert_date(start_date)}
                  </p>
                </div>
                <div className="flex flex-col gap-4">
                  <h3 className="text-[18px] font-medium text-[#121212]">
                    End Date
                  </h3>
                  <p className="text-sm text-[#7F7F81]">
                    {convert_date(end_date)}
                  </p>
                </div>
              </>
            )}
          </div>
        </main>
      </section>
      <ModalPop isOpen={openFeature}>
        <EditFeatureModal
          refresh={fetchData}
          handleClose={() => setOpenFeature(false)}
          categoryName={type}
          id={id}
          instructionId={mId}
          instructionData={instructionData}
          // type={type}
        />
      </ModalPop>
      <ModalPop isOpen={deleteModal}>
        <DeleteInstructionModal
          handleClose={() => setDeleteModal(false)}
          id={id}
          instructionId={mId}
        />
      </ModalPop>
    </DashboardLayout>
  );
}

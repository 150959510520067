import React, { useState, useRef, useEffect, forwardRef, useLayoutEffect } from 'react';
import { createPopper } from '@popperjs/core';
import { useSelector } from 'react-redux';
import ModalPop from '@/../component/modalPop';
import NotifyToInstallAppModal from './NotifyToInstallAppModal';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { Dropdown } from './AtInput3';
import getCaretCoordinates from 'textarea-caret';

const AtInput4 = forwardRef(({ value, onChange, placeholder, className, tags, setTags, type = "textarea", ...restProps }, ref) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const [inputValue, setInputValue] = useState('');
    const [filteredApps, setFilteredApps] = useState([]);
    const [enough, setEnough] = useState(false)

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#A7A7A7',
        boxShadow: theme.shadows[0.7],
        fontSize: 11,
    },
    }));

    // const active = useSelector(state => state.getAllPlugins.data.data.active);
    // const available = useSelector(state => state.getAllPlugins.data.data.available);

    const data  = useSelector(state => state.getAllPlugins.data)
    const active = data?.data?.active || []
    const available = data?.data?.available || []

    useEffect(() => {
        if (showDropdown) {
            const searchTerm = inputValue.split('@').pop();
            const filtered = [...active, ...available]
                .filter(app => app.name.toLowerCase().includes(searchTerm.toLowerCase()));
            setFilteredApps(filtered);
            // console.log(filteredApps, "apps tags");
        }
    }, [inputValue, showDropdown, active, available]);

    useLayoutEffect(() => {
        if (showDropdown  && inputRef.current && dropdownRef.current) {
            const position = inputRef.current.value.lastIndexOf('@');
            if (position !== -1) {
                // const coords = getCaretCoordinates(inputRef.current, position);
                const { left } = inputRef.current ? getCaretCoordinates(inputRef.current, inputRef.current?.selectionEnd) : { top: 0, height: 0 };
                const popperInstance = createPopper(inputRef.current, dropdownRef.current, {
                    placement: 'top',
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                // offset: [coords.left + 10, 20],
                                offset: [left, -5]
                            },
                        },
                    ],
                });
                return () => popperInstance.destroy();
            }

        }
    }, [showDropdown, inputValue]);

    const tag = (name) => {
        switch (name) {
            case 'Slack': return "slack";
            case "Language Translator": return "languagetranslator";
            case "Lead Generation": return "Hubspot";
            case "Calendly": return "calendly";
            default: return name;
          }
    }


    const handleChange = (e) => {
        const { value } = e.target;
        setInputValue(value);
        onChange(e);
        setShowDropdown(value.includes('@') && value.slice(-1) === '@');
        const mentionsExist = value.includes('@');
        // const standaloneAt = value.match(/@\b/g);

        // if (!mentionsExist || standaloneAt === null) {
        if (!mentionsExist) { //value.trim() === ""
            setTags([]);
            setEnough(false);
        } else {
            extractMentions(value);
        }
    };

    const extractMentions = value => {
        const matches = value.match(/@\w+/g);
        if (matches && matches.length > 0) {
            const appMentions = matches.map(mention => mention.substring(1));
            const appTags = appMentions
                .map(mention => available.concat(active).find(app => tag(app.name) === mention))
                .filter(app => app)
                .map(app => ({ applicationPluginId: app.applicationPlugins[0].id, slug: app.app_tag_slug }));
            
            if (appTags.length > 0) {
                setTags(appTags);
                setEnough(appTags.length >= 1);
            }
        }
    };

    const handleAppClick = (app, isInstalled) => {
        if(enough) return;
        if (isInstalled) {
            const lastAtIdx = inputValue.lastIndexOf('@');
            if (lastAtIdx !== -1) {
                const beforeAt = inputValue.substring(0, lastAtIdx);
                const newInputValue = `${beforeAt}@${tag(app.name)} `;
                setInputValue(newInputValue);
                onChange({ target: { value: newInputValue } });
                if (!tags.some(tag => tag?.slug === app?.app_tag_slug)) {
                    setTags([...tags, {applicationPluginId: app?.applicationPlugins[0]?.id, slug: app?.app_tag_slug}]);
                }
                setShowDropdown(false);
                setTimeout(() => {
                    const length = newInputValue.length;
                    inputRef.current.setSelectionRange(length, length);
                    inputRef.current.focus();
                }, 0);
            }
        }else{
            setShowModal(true);
        }
    };

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const InputComponent = type === "textarea" ? "textarea" : "input";

    const renderApp = (app, isInstalled) => {
        const tooltipText = enough ? "You can currently only tag one app per instruction or message" : app.description;

        return (
            <LightTooltip
                key={app.id}
                title={tooltipText}
                arrow
                TransitionComponent={Zoom}
                placement="right"
                sx={{
                    '& .MuiTooltip-arrow': {
                        color: '#fff',
                        dropShadow: "0 2px 10px rgba(200, 200, 200, 0.03)",
                        // transform: 'translateY(-6px)',
                    },
                    zIndex: 1000000,
                }}
                slotProps={{
                    popper: {
                    modifiers: [
                        {
                        name: 'offset',
                        options: {
                            offset: [0, 10],
                        },
                        },
                    ],
                    },
                }}
            >
                <div
                    className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
                    style={{ padding: '5px', cursor: 'pointer', opacity: isInstalled ? enough? "0.4": "1": "0.4" }}
                    onClick={() => handleAppClick(app, isInstalled)}
                >
                    <img src={app.image_url} alt={app.name} className='w-4 h-4 rounded-[2.26px]'/> {app.name}
                </div>
            </LightTooltip>
        );
    };

    return (
        <div>
            <InputComponent
                ref={inputRef}
                value={inputValue}
                onChange={handleChange}
                className={className}
                placeholder={placeholder}
                {...restProps}
            />
            {showDropdown && (
                <div ref={dropdownRef} className='z-[2] w-full'>
                    <Dropdown>
                    {/* {enough ?  */}
                            <div className='flex flex-col gap-3'>
                                <p className='text-[#7F7F81] font-medium text-xs px-2 pb-2'>Installed Apps</p>
                                {filteredApps.filter(app => active.includes(app)).map(app => renderApp(app, true))}
        
                                <p className='text-[#7F7F81] font-medium text-xs p-2'>Uninstalled Apps</p>
                                {filteredApps.filter(app => !active.includes(app)).map(app => renderApp(app, false))}
                            </div> 
                            {/* :
                            filteredApps.map((app) => { return (renderApp(app, active.includes(app)))}) */}
                        
                        {/* } */}
                    </Dropdown>
                </div>
            )}
            <ModalPop isOpen={showModal} closeModal={() => setShowModal(false)}>
                <NotifyToInstallAppModal handleClose={() => setShowModal(false)} />
            </ModalPop>
        </div>
    );
});

export default AtInput4;


// function getCaretCoordinates(element, position) {
    //     const { offsetLeft: inputX, offsetTop: inputY } = element;
    //     const div = document.createElement('div');
    //     document.body.appendChild(div);
    
    //     const style = div.style;
    //     const computed = getComputedStyle(element);
    
    //     // Apply relevant styles to match the textarea
    //     style.whiteSpace = 'pre-wrap';
    //     style.wordWrap = 'break-word';
    //     style.position = 'absolute';
    //     style.visibility = 'hidden';
    //     style.font = computed.font;
    //     style.width = element.offsetWidth + 'px'; // use offsetWidth to include padding
    
    //     // Insert the text and a marker element at the position
    //     div.textContent = element.value.substr(0, position);
    //     const span = document.createElement('span');
    //     span.textContent = element.value.substr(position) || '.';
    //     div.appendChild(span);
    
    //     // Calculate positions relative to the textarea
    //     const rect = span.getBoundingClientRect();
    //     const { top, left } = rect;
    //     const textareaRect = element.getBoundingClientRect();
    //     div.remove();
    
    //     return {
    //         top: rect.top - textareaRect.top + element.scrollTop, 
    //         left: rect.left - textareaRect.left
    //     }; //rect.left - textareaRect.left
    // }


    //original 
    // {/* {filteredApps.map(app => { 
            //     return(
            //     <LightTooltip 
            //         key={app.id} 
            //         title={app.description} 
            //         arrow 
            //         TransitionComponent={Zoom}
            //         placement="right"
            //         sx={{
            //             '& .MuiTooltip-arrow': {
            //                 color: '#fff',
            //                 dropShadow: "0 2px 10px rgba(200, 200, 200, 0.03)",
            //                 // transform: 'translateY(-6px)',
            //             }
            //         }}
            //         slotProps={{
            //             popper: {
            //             modifiers: [
            //                 {
            //                 name: 'offset',
            //                 options: {
            //                     offset: [0, 24],
            //                 },
            //                 },
            //             ],
            //             },
            //         }}
            //     >
            //         <div
            //             key={app.id}
            //             onClick={() => handleAppClick(app, active.includes(app))}
            //             className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
            //             style={{
            //                 padding: '5px',
            //                 cursor: 'pointer',
            //                 opacity: active.includes(app) ? "1": "0.4",
            //             }}
            //         >
            //             <img src={app.image_url} alt={app.name} className='w-4 h-4 rounded-[2.26px]'/> {app.name}
            //         </div>
            //     </LightTooltip>
            // )}) */}
import React from "react";
import whatsappIcon from "../Assets/svg/whatsappico.svg";
import thirdPartyIcon from "../Assets/svg/databaseico.svg";
import advancedCustomizationIcon from "../Assets/svg/advancedico.svg";
import serversIcon from "../Assets/svg/vectorico.svg";
import wanoLogoIcon from "../Assets/svg/wanoico.svg";

const CardPlans = ({ key, type, prefix, description }) => {
  const icon = {
    "whatsapp": whatsappIcon,
    "third-party": thirdPartyIcon,
    "advanced-customization": advancedCustomizationIcon,
    "servers": serversIcon,
    "wano-logo": wanoLogoIcon,
  };

  return (
    <div
      key={key}
      className="flex flex-col gap-[20px] items-center justify-center"
    >
      {icon[prefix] ? (
        <img className="w-[24px] h-[24px]" src={icon[prefix]} alt="icon" />
      ) : (
        <h1 className="text-[24px] leading-[28.12px] font-medium text-[#101828]">
          {prefix}
        </h1>
      )}
      <p className="text-[16px] leading-[32px] text-[#828282]">{description}</p>
    </div>
  );
};

export default CardPlans;

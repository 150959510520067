import React, { useEffect, useRef, useState } from "react";
import { HiXMark } from "react-icons/hi2";
import FeatureForm from "./FeatureForm";
// import ScheduledForm from "./FeatureForm";
import TokenService from "../services/token";
import axios from "axios";
import { CgSpinner } from "react-icons/cg";
import { useDispatch,useSelector } from "react-redux";
import { useLocation } from "react-router";
import ScheduledForm from "./ScheduledForm";
import {
  getApplicationDraft
} from "features/application/getApplicationByIdSlice";

export default function EditFeatureModal({
  refresh,
  handleClose,
  id,
  categoryName,
  instructionId,
  instructionData,
  type,
}) {
  const agentData = localStorage.getItem("wano_agent");
  const agentDetails = JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const URL = process.env.REACT_APP_BASEURL;
  const [instructionName, setInstructionName] = useState("");
  const [instructionDescription, setInstructionDescription] = useState("");
  const [instructionManual, setInstructionManual] = useState("");
  const [support, setSupport] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  // const [repeatOption, setRepeatOption] = useState(""); 
  const token = TokenService.getToken();
  const storeCountries = useSelector((state) => state.getAllCountries);
  const dispatch = useDispatch();
  const location = useLocation()

  console.log(location, "location param")

//   console.log(repeatOption, "you have selected an option")
// console.log(endDate, "tag end")
// console.log(startDate, "tag start")
// console.log(type, "type ha")

 //monitors the tags in the instructionManual
 const [tags, setTags] = useState([])
 // console.log(tags, "tags");
 const convertToISO = (dat) => {
   const date = new Date(dat);
   const now = new Date();
   date.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
   const isoString = date.toISOString();
  //  console.log(isoString); 
   return isoString;
 };

  const formRef = useRef(null);

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const convertTime = (dat) => {
    const fetchedDate = new Date(dat);
    const formattedDate = fetchedDate.toISOString().split('T')[0];
    return formattedDate
  }

  useEffect(() => {
    if (instructionData) {
      setInstructionName(instructionData.name);
      setInstructionDescription(instructionData.description);
      setInstructionManual(instructionData.how_it_works);
      setSupport(instructionData.support_channel);
      
      setEndDate(convertTime(instructionData.end_date))
      setStartDate(convertTime(instructionData.start_date))
    }
    instructionData.support_channel.forEach((channel) => {
      if (channel.support_channel === "Phone Number") {
        getCoutryCode(channel.website);
      }
    });
  }, []);
  
  const getCoutryCode = (phoneNumber) => {
    // Iterate through each possible country code
    const possibleNumbers = storeCountries?.countries.map(
      (country) => country.phone_code
    );
    for (let countryCode of possibleNumbers) {
      // Check if the phone number starts with the country code
      if (phoneNumber.startsWith(countryCode)) {
        const remaining = phoneNumber.slice(countryCode.length);
        setSupport(
          instructionData.support_channel.map((each) => ({
            support_channel: each.support_channel,
            website:
              each.support_channel === "Phone Number"
                ? remaining
                : each.website,
          }))
        );
        return setCountryCode(countryCode); // Return the matched country code
      }
    }

    // If no match is found, return null
    return setCountryCode(234);
  };

  const setErrorMsg = (msg) => {
    setError(msg);
  };

  const editInstruction = async () => {
    if (!applicationId) return;
    setError("");
    if (
      !instructionName ||
      !instructionDescription ||
      !instructionManual 
    ) {
      setError("Please fill all fields!");
      return;
    } else if (
      support.length > 0 &&
      !support.every(
        (item) =>
          item.support_channel.trim() !== "" && item.website.trim() !== ""
      )
    ) {
      setError(
        "Please provide valid support information for all selected channels!"
      );
      return;
    }
    try {
      setLoading(true);
      const instructionData = {
        name: instructionName,
        description: instructionDescription,
        how_it_works: instructionManual,
        support_channel: support.map((each) => ({
          support_channel: each.support_channel,
          website:
            each.support_channel === "Phone Number"
              ? `${countryCode}${each.website}`
              : each.website,
        })),
        tags: tags,
      };
      if (location.pathname.includes("Scheduled")) {
        instructionData.start_date = convertToISO(startDate);
        instructionData.end_date = convertToISO(endDate);
      }
      const res = await axios.put(
        `${URL}/dashboard/applications/${applicationId}/category/${id}/instructions/${instructionId}`,
        // {
        //   name: instructionName,
        //   description: instructionDescription,
        //   how_it_works: instructionManual,
        //   support_channel: support.map((each) => ({
        //     support_channel: each.support_channel,
        //     website:
        //       each.support_channel === "Phone Number"
        //         ? `${countryCode}${each.website}`
        //         : each.website,
        //   })),
        // },
        instructionData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      refresh();
      // GET DRAFTS /
      if(applicationId){
        dispatch(getApplicationDraft(applicationId));
      }
 
      handleClose();
    } catch (error) {
      setLoading(false);
      setError("Error updating instruction");
    }
  };
  const [countryCode, setCountryCode] = useState("");
  return (
    <div className="pt-[15px] pb-5 w-[749px] min-h-[600px] overflow-y-scroll bg-white ml-36 rounded-lg shadow-makeReqModal font-figtree relative mt-5">
      <header className="px-10 mb-[15px] flex items-center justify-between w-full">
        <div className="flex flex-col gap-2">
          <h1 className="text-[24px] font-medium text-black font-figtree">
            Edit
          </h1>
          <p className="text-xs font-figtree text-[#7F7F81]">
            Edit your instructions
          </p>
        </div>
        <div
          className="border p-2 rounded-lg border-[#F7F7F7] cursor-pointer"
          onClick={handleClose}
        >
          <HiXMark className="text-[20px] text-[#828282]" />
        </div>
      </header>
      <hr className="bg-[#F7F7F7]" />
      <div className="px-10 w-full -mt-[14px] flex flex-col gap-[39px]">
        {categoryName !== "Scheduled Instructions"?
        <FeatureForm
          ref={formRef}
          setErrorMsg={setErrorMsg}
          width={true}
          categoryName={categoryName}
          instructionName={instructionName}
          setInstructionName={setInstructionName}
          instructionDescription={instructionDescription}
          setInstructionDescription={setInstructionDescription}
          instructionManual={instructionManual}
          setInstructionManual={setInstructionManual}
          support={support}
          setSupport={setSupport}
          setCountryCode={setCountryCode}
          countryCode={countryCode} //new prop for coutry code
          action={editInstruction}
          setTags={setTags}
          tags={tags}
        />
        :
        <ScheduledForm 
          ref={formRef}
          width={true}
          categoryName={categoryName}
          instructionName={instructionName}
          setInstructionName={setInstructionName}
          instructionDescription={instructionDescription}
          setInstructionDescription={setInstructionDescription}
          instructionManual={instructionManual}
          setInstructionManual={setInstructionManual}
          support={support}
          setSupport={setSupport}
          setCountryCode={setCountryCode}
          countryCode={countryCode} //new prop for coutry code
          action={editInstruction}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          // repeatOption={repeatOption}
          // setRepeatOption={setRepeatOption}
          setTags={setTags}
          tags={tags}
              />
        }{/* {error && <p className="text-red-400">{error}</p>} */}

        <div className="w-full flex items-center justify-end gap-3">
          <button
            onClick={handleClose}
            className="cursor-pointer outline-none py-[10px] w-auto px-5 border border-[#D0D5DD] rounded-lg text-[#828282] text-xs font-semibold"
          >
            Cancel
          </button>
          {loading ? (
            <button className="outline-none py-[10px] w-auto px-5 bg-[#121212] rounded-lg text-white text-xs font-semibold flex justify-center">
              <CgSpinner className=" animate-spin text-lg " />
            </button>
          ) : (
            <button
              onClick={handleFormSubmit}
              className="cursor-pointer outline-none py-[10px] w-auto px-5 bg-[#121212] rounded-lg text-white text-xs font-semibold"
            >
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import AiLogo from "../../../Assets/svg/Group 12.svg";
import ModalPop from '../../../component/modalPop';
import AgentModal from './AgentModal';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import TokenService from '../../../services/token';
import { CgSpinner } from 'react-icons/cg';
import {getAllPlugins} from '../../../features/plugins/getAllPluginsSlice';
import { useNavigate } from 'react-router';

export default function ViewAgents({ handleClose, data }) {
    const [viewAgent, setViewAgent] = useState(false)
    const [selectedAgent, setSelectedAgent] = useState([])
    const appDetail = useSelector(state => state.getApplicationByUserId);
    const agentDetails = appDetail?.data?.data;
    const [loading, setLoading] = useState(false)
    const URL = process.env.REACT_APP_BASEURL;
    const token = TokenService.getToken()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [featureData, setFeatureData] = useState(null)
    const [currentAgentIndex, setCurrentAgentIndex] = useState(0);
    const [nextLoading, setNextLoading] = useState(false)
    
    const handleAgentSelection = (agent) => {
        setSelectedAgent((prevSelected) =>
            prevSelected.includes(agent)
            ? prevSelected.filter((name) => name !== agent)
            : [...prevSelected, agent]
        );
    };
    
    const linkAgent = async () => {
        try {
            setLoading(true)
            const res = await axios.post(
                `${URL}/dashboard/plugins/`,
                {
                    ids: selectedAgent.map((agent) => agent.id),
                    pluginId: data.plugin.id
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            )
            if (res.data.status === true) {
                const responseData = res.data.data;
                const recentlyLinkedAgent = responseData[responseData.length - 1]
                sessionStorage.setItem('plugin_id', recentlyLinkedAgent?.id)
                const filteredData = responseData.filter(agent => agent.plugin.id === data.plugin.id);
                const reversedData = filteredData.reverse()
                setSelectedAgent(reversedData);  
                dispatch(getAllPlugins())
                fetchPluginAgentFeatures(recentlyLinkedAgent.application.id, recentlyLinkedAgent?.plugin.id)
            } 
            setViewAgent(!viewAgent)
        } catch(error) {
            setLoading(false)
        }
    }

    const fetchPluginAgentFeatures = async (agentId, id) => {
        const res = await axios.get(
            `${URL}/dashboard/plugins/${id}/application/${agentId}/features`,

            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        )
        setFeatureData(res.data.data)
        setLoading(false)
        setNextLoading(false)
    }

    const handleNext = () => {
        setCurrentAgentIndex((prevIndex) => prevIndex + 1);
        sessionStorage.setItem('plugin_id', selectedAgent[currentAgentIndex + 1]?.id)
        setNextLoading(true)
        fetchPluginAgentFeatures(selectedAgent[currentAgentIndex + 1]?.application?.id, selectedAgent[currentAgentIndex + 1]?.id);
    };

    return (
        <div className="flex overflow-y-auto flex-col gap-10 py-[30px] px-[50px] w-[570px] h-[510px] bg-white ml-36 rounded-lg shadow-makeReqModal font-figtree relative mt-[10%]">
            <header className='w-full justify-center items-center flex flex-col gap-5'>
                <img
                    src={AiLogo}
                    alt="sterling"
                    className="h-14 w-16 rounded-full"
                />  
                <h1 className='text-[24px] font-medium text-[#101828] font-figtree'>Your Assistants</h1>
                <p className='text-xs font-medium text-[#828282]'>Select which of your assistants you want this plugin to be activated for</p>
            </header>
            <div className='flex flex-col gap-6'>
                {agentDetails?.filter((agent) => !data.application?.some((application) => application.id === agent.id))?.map((agent, index) => (
                    <div key={index} className='w-full flex justify-between items-center py-3 px-4 border border-[#E5E5E5] rounded-[12px]'>
                        <div className='flex flex-col gap-[10px]'>
                            <div className='flex gap-4 items-center'>
                                {agent.icon_url ? <img src={agent.icon_url} alt='icon' /> :
                                    <div className='w-5 h-5 bg-[#1774FD] rounded-lg flex justify-center items-center'>
                                        <p className='capitalize text-white text-[9px] font-medium'>
                                            {agent?.name?.charAt(0)}
                                        </p>
                                    </div>
                                }
                                <p>{agent.name}</p>
                            </div>
                            <p className='text-[#7F7F81] text-xs font-figtree'>{agent.description}</p>
                        </div>
                        <div className='w-5 h-5' onClick={() => handleAgentSelection(agent)}>
                            <input 
                                type='radio' 
                                className='cursor-pointer w-5 h-5' 
                                checked={selectedAgent.includes(agent)} 
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className='w-full flex flex-col gap-3 items-center'>
                {loading ? (
                    <button className="outline-none py-[10px] w-full px-5 bg-[#121212] rounded-lg text-white text-xs font-semibold flex justify-center">
                        <CgSpinner className=" animate-spin text-lg " />
                    </button>
                ) : (
                    <button disabled={selectedAgent.length === 0} onClick={linkAgent} className={`${selectedAgent.length === 0 ? 'opacity-5' : 'cursor-pointer '} outline-none py-[10px] w-full px-5 bg-[#121212] rounded-lg text-white text-xs font-semibold`}>
                        Link Assistant(s)
                    </button>
                )}
                <p onClick={handleClose} className='cursor-pointer text-sm font-bold text-[#868686]'>Cancel</p>
            </div>
            <ModalPop isOpen={viewAgent}>
                <AgentModal 
                    currentAgentIndex={currentAgentIndex}
                    nextLoading={nextLoading}
                    handleNext={handleNext} 
                    featureData={featureData} 
                    handleClose={() => {setViewAgent(false); navigate('/plugins'); handleClose()}} 
                    selectedAgent={selectedAgent}
                    fetchFeatures={fetchPluginAgentFeatures}
                />
            </ModalPop>
        </div>
    )
}

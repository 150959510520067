import { useState, useEffect } from "react";
import searchIcon from "Assets/svg/searchIcon.svg";

function DebounceSearch({ searchTerm, setSearchTerm }) {
  const [inputValue, setInputValue] = useState(searchTerm);

  useEffect(() => {
    // Debounce function: update searchTerm 1 second after user stops typing
    const handler = setTimeout(() => {
      setSearchTerm(inputValue); // Set searchTerm after delay
    }, 1000); // 1 second delay

    // Cleanup function to clear the timeout if inputValue changes before 1 second
    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, setSearchTerm]);

  return (
    <div className="relative mb-4 flex items-center px-2 border border-gray-300 rounded-lg">
      <img src={searchIcon} alt="Search Icon" className="h-4 w-4" />
      <input
        type="text"
        placeholder="Search"
        className="w-full px-4 py-2 border-none focus:outline-none text-xs"
        onChange={(e) => setInputValue(e.target.value)} // Update inputValue immediately
        value={inputValue}
      />
    </div>
  );
}

export default DebounceSearch;
import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit"

import { api } from "../../services/api"
import { appUrls } from "../../services/urls"


const initialState = {
    loading: false,
    data: "",
    error: ""
}

export const inviteTeamMember = createAsyncThunk(
    "invite/generateTopUp", 
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.post(appUrls?.INVITE_TEAM_MEMBERS_URL, data);
            return res?.data?.message
        }
        catch (err) {
            return rejectWithValue(err?.data?.message)
        }
    }
)


const inviteTeamMemberSlice = createSlice({
    name: "invite",
    initialState,
    extraReducers: builder => {
        builder.addCase(inviteTeamMember.pending, state => {
            state.loading = true
    });
        builder.addCase(inviteTeamMember.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(inviteTeamMember.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});

export default inviteTeamMemberSlice.reducer
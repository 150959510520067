import React, { useEffect } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { useState } from "react";
import SupportContent from "../Support/SupportContent";
import { UserContext } from "../context/UserContext";
import { useContext } from "react";
import { useLocation } from "react-router";
export default function Support() {
      const { userType } = useContext(UserContext);
      const { state } = useLocation();
      const [clickItem, setClickItem] = useState({
        Pricing: false,
        Faqs: false,
        Contact_Us: false,
        Speak_with_CEO: false,
      });

      console.log(state, "state ha")

      useEffect(() => {
        setClickItem((prev) => ({...prev, ...state}))
      }, [state])
      // const handleCheckboxChange = (clickItemName) => {
      //   setClickItem({
      //     ...clickItem,
      //     [clickItemName]: !clickItem[clickItemName],
      //   });
      // };

      //added this to make sure no two accordion is open at any instance
      const handleCheckboxChange = (clickItemName) => {
        const updatedClickItem = Object.keys(clickItem).reduce((acc, item) => {
          acc[item] = item === clickItemName ? !clickItem[item] : false;
          return acc;
        }, {});
      
        setClickItem(updatedClickItem);
      };

  return (
    <DashboardLayout title={"Support"} user={true}>
      <section className="p-10">
        <SupportContent
          handleCheckboxChange={handleCheckboxChange}
          clickItem={clickItem}
        />
      </section>
    </DashboardLayout>
  );
}

import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Formik, Field, FieldArray } from "formik";
import axios from "axios";
import { useSelector } from "react-redux";
import TokenService from "../../../services/token";
import { useState } from "react";
import { CgSpinner } from "react-icons/cg";
import {
  SuccessMessage,
  ErrorMessage,
} from "../../../Toast-Messages/Toastmessages";

const initialValues = {
  questionsAndAnswers: [
    { question: '', category: "faq", answer: '' },
  ],
};

const FaqTemplate = ({ handleClose }) => {
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const data = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = data && data.length >= 1 ? data[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  const submitForm = async (values) => {
    setLoading(true)
    try {
      const response = await axios.post(
        `${URL}/dashboard/applications/${applicationId}/memory-template`,
        {
          data: values.questionsAndAnswers.filter(each=>each.answer),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      setSuccess("Successful: record created successfully");
      setLoading(false);
      setTimeout(() => {
        setSuccess(false);
        handleClose();
      }, 2500);
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
      setError("Error uploading the form. Please try again later.");
      setTimeout(() => {
        setError(false);
      }, 2500);
    }
  };

  return (
    <div className="bg-WHITE-_100 w-[946px] mt-[30px] rounded-lg overflow-y-scroll">
      <div className="flex justify-between h-[80px] items-center mx-4 my-3 ">
        <div className="w-[421px] h-[57px] flex flex-col gap-1 ml-6">
          <p className="font-medium text-2xl text-BLACK-_600">
            FAQ - Template Guide
          </p>
          <p className="text-xs leading-5 font-normal  text-BLACK-_200">
            Fill the template with the necessary information.
          </p>
        </div>
        <button
          className="w-[36px] h-[36px] border rounded-lg flex justify-center items-center p-2"
          onClick={handleClose}
        >
          <AiOutlineClose />
        </button>
      </div>
      <hr />

      <div className="mt-[30px] ml-6 py-10">
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            submitForm(values);
            window.scrollTo(0, 0);
            console.log(values, "otp");
          }}
        >
          {({values, handleSubmit}) => (
            <form onSubmit={handleSubmit} className="flex justify-center">
              <FieldArray name="questionsAndAnswers">
                {({ push, remove }) => (
                <div className="flex flex-col items-center gap-6 ">
                  {values.questionsAndAnswers.map((qa, index) => (
                    <div key={index} className="w-[866px] flex flex-col">
                      <div className="flex flex-col gap-2">
                        <label className="label text-left text-[#101828] text-xs font-normal">
                          Question {index + 1}
                        </label>
                        <Field
                          name={`questionsAndAnswers.${index}.question`}
                          type="text"
                          className="outline-none w-[866px]"
                        />
                      </div>
                      <div className="w-[866px] flex flex-col">
                        <div className="flex flex-col lg:w-[866px] gap-2">
                          <label className="label text-left text-[#101828] text-xs font-normal">
                            Answer
                          </label>
                          <Field
                            name={`questionsAndAnswers.${index}.answer`}
                            type="text"
                            component="textarea"
                            className="outline-none rounded-lg border border-[#D0D5DD] p-1"
                            cols="50"
                            rows="10"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="w-[866px] flex justify-end">
                    <p className="text-[#1774FD] text-sm font-semibold cursor-pointer" onClick={() => push({ question: '', category: "faq", answer: '' })}>
                      Add another question
                    </p>
                  </div>

                  <div className="w-[886px] flex gap-2 mt-[30px] justify-end">
                    <button
                      className="bg-WHITE-_100 border border-solid border-[#D0D5DD] w-[78px] h-[34px] rounded-lg"
                      onClick={handleClose}
                    >
                      <p className="text-[#828282] text-[12px] font-medium">
                        Cancel
                      </p>
                    </button>
                    <button
                      disabled={loading}
                      type="submit"
                      className="bg-BLACK-_500 w-[79px] h-[34px] rounded-lg"
                    >
                      {loading ? (
                        <span className="flex justify-center w-full">
                          <CgSpinner className=" animate-spin text-lg text-WHITE-_100" />
                        </span>
                      ) : (
                        <p className="text-WHITE-_100 text-[12px] font-medium">
                          Upload
                        </p>
                      )}
                    </button>
                  </div>
                </div>
              )}
              </FieldArray>
            </form>
          )}
        </Formik>
      </div>
      {success && <SuccessMessage success={success} />}
      {error && <ErrorMessage error={error} />}
    </div>
  );
};

export default FaqTemplate;
import line from "Assets/svg/literalLine.svg";
import newTestLogo from "Assets/svg/test-left.svg";
import transfer from "Assets/svg/transfer.svg";
import { useEffect, useRef, useState } from "react";
import mailIcon from "Assets/svg/mail.svg";
import { useDispatch, useSelector } from "react-redux";
import Summary from "Customer/Summary";
import { marked } from "marked";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm"; // For GitHub-flavored markdown like tables, strikethrough, etc.
import rehypeRaw from "rehype-raw"; // To allow rendering HTML in markdown

function ConversationChatBox({
  stateCustomerDetails,
  connectingToAgent,
  agentTakeOver,
  isUserTyping,
  showSummary,
  conversationResolved,
}) {
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );

  const newAgent = getAgentApplication?.agent || null;
  function formatDateTime(isoString) {
    const date = new Date(isoString);

    // Add 1 hour
    date.setUTCHours(date.getUTCHours() + 1);

    // Extracting date components
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const day = date.getUTCDate();
    const month = date.toLocaleString("en-US", {
      month: "long",
      timeZone: "UTC",
    });
    const year = date.getUTCFullYear();

    // Convert 24-hour time to 12-hour time
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Add leading zero to minutes if necessary
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    // Format the day with ordinal suffix
    const ordinalSuffix = (n) => {
      if (n > 3 && n < 21) return "th";
      switch (n % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const dayWithSuffix = day + ordinalSuffix(day);

    // Construct the final formatted string
    return ` ${hours}:${formattedMinutes} ${ampm}, ${dayWithSuffix} ${month}, ${year}`;
  }
  function formatTo12HourTime(isoDate) {
    const date = new Date(isoDate);

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'

    const minutesStr = minutes < 10 ? "0" + minutes : minutes; // Ensure two-digit minutes

    const timeString = `${hours}:${minutesStr} ${ampm}`;
    return timeString;
  }
  const { conversation } = useSelector((state) => state?.getCustomers);
  const chatBoxRef = useRef(null);

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [
    conversation,
    connectingToAgent,
    agentTakeOver,
    isUserTyping,
    showSummary,
  ]);
  const renderMarkdown = (text) => {
    const markdown = text || "";
    const __html = marked(markdown, { sanitize: true });
    // console.log("stuff is", text);
    return { __html };
  };
  return (
    <div
      ref={chatBoxRef}
      className=" h-[75vh] no-scrollbar pb-44  px-5  overflow-auto"
    >
      {/* INFOMRATION  */}
      <div className="flex justify-center gap-3 ">
        <img src={line} alt="line" />

        <p className="text-BLACK-_160 text-xs font-medium pt-2">
          <span className="capitalize mr-1">
            {" "}
            {stateCustomerDetails?.customer?.name ??
              stateCustomerDetails?.random_customer?.random_username}
          </span>
          {`  Started the conversation at 
            ${formatDateTime(stateCustomerDetails.created_at)}`}
        </p>
        <img src={line} alt="line" />
      </div>
      {/* CUSTOMER  */}
      {!!conversation?.length &&
        conversation.map(
          (
            { role, created_at, message, status, messageStatus, agent_name },
            index
          ) => (
            <div key={index}>
              {status === "connecting" ? (
                <div className="mt-6  py-4 px-6">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <div className="w-5 h-5 rounded-full capitalize bg-BLUE-_200 text-white flex justify-center items-center font-bold text-[10px]">
                        {stateCustomerDetails?.customer?.name.charAt(0) ??
                          stateCustomerDetails?.random_customer?.random_username.charAt(
                            0
                          )}
                      </div>
                      <p className="text-xs font-semibold capitalize">
                        {stateCustomerDetails?.customer?.name ??
                          stateCustomerDetails?.random_customer
                            ?.random_username}
                      </p>
                    </div>
                  </div>
                  <span className="mt-2 w-fit text-sm p-2.5  flex gap-2.5 border border-grey-300 rounded-lg">
                    <img src={transfer} alt="transfer" />
                    User requested Transfer to Human
                  </span>
                </div>
              ) : (
                <>
                  {status === "connected" || messageStatus === "connected" ? (
                    <div className="flex justify-center gap-3 my-10">
                      <img src={line} alt="line" />

                      <p className="text-BLACK-_160 text-xs font-medium">
                        You joined the conversation at
                        {formatDateTime(created_at)}
                      </p>

                      <img src={line} alt="line" />
                    </div>
                  ) : (
                    <>
                      {role === "user" ? (
                        <div className="mt-6  py-4 px-6">
                          <div className="flex justify-between items-center">
                            <div className="flex items-center gap-2">
                              <div className="w-5 h-5 rounded-full bg-BLUE-_200 capitalize text-white flex justify-center items-center font-bold text-[10px]">
                                {stateCustomerDetails?.customer?.name.charAt(
                                  0
                                ) ??
                                  stateCustomerDetails?.random_customer?.random_username.charAt(
                                    0
                                  )}
                              </div>
                              <p className="text-xs font-semibold capitalize">
                                {stateCustomerDetails.customer?.name ??
                                  stateCustomerDetails?.random_customer
                                    .random_username}
                              </p>
                            </div>
                            <p className="text-BLACK-_160 font-medium text-xs">
                              {formatTo12HourTime(created_at)}
                            </p>
                          </div>
                          <div className="mt-1 text-sm">
                            <Markdown
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[rehypeRaw]}
                              className="prose prose-sm prose-indigo"
                            >
                              {message}
                            </Markdown>
                            {/* Who is Rory Sutherland? */}
                            {/* <span className="text-PRIMARY ml-1">
                      @WebSearch
                    </span> */}
                          </div>
                        </div>
                      ) : role === "agent" ? (
                        <div className="mt-6 bg-white py-4 px-6 rounded-xl">
                          <div className="flex justify-between items-center">
                            <div className="flex items-center gap-2">
                              <div className="text-xs bg-BLUE-_200 text-white flex items-center justify-center rounded-full h-5 w-5">
                                {agent_name.charAt(0)}
                              </div>

                              <p className="text-xs font-semibold">
                                {agent_name}
                              </p>
                            </div>
                            <p className="text-BLACK-_160 font-medium text-xs">
                              {formatTo12HourTime(created_at)}
                            </p>
                          </div>
                          <div className="mt-1 text-sm">
                            <Markdown
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[rehypeRaw]}
                              className="prose prose-sm prose-indigo"
                            >
                              {message}
                            </Markdown>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-6 bg-white py-4 px-6 rounded-xl">
                          <div className="flex justify-between items-center">
                            <div className="flex items-center gap-2">
                              <img
                                src={
                                  newAgent?.draft?.icon_url
                                    ? newAgent?.draft?.icon_url
                                    : newAgent?.icon_url
                                    ? newAgent?.icon_url
                                    : newTestLogo
                                }
                                alt="sales"
                                className="w-5 h-5 rounded-full"
                              />
                              <p className="text-xs font-semibold">
                                {newAgent.name}
                              </p>
                            </div>
                            <p className="text-BLACK-_160 font-medium text-xs">
                              {formatTo12HourTime(created_at)}
                            </p>
                          </div>
                          <div className="mt-1 text-sm">
                            <Markdown
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[rehypeRaw]}
                              className="prose prose-sm prose-indigo"
                            >
                              {message}
                            </Markdown>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}

              {/* AI  */}
            </div>
          )
        )}
      {connectingToAgent && (
        <div className="mt-6  py-4 px-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <div className="w-5 h-5 rounded-full capitalize bg-BLUE-_200 text-white flex justify-center items-center font-bold text-[10px]">
                {stateCustomerDetails?.customer?.name.charAt(0) ??
                  stateCustomerDetails?.random_customer?.random_username.charAt(
                    0
                  )}
              </div>
              <p className="text-xs font-semibold capitalize">
                {stateCustomerDetails?.customer?.name ??
                  stateCustomerDetails?.random_customer?.random_username}
              </p>
            </div>
          </div>
          <span className="mt-2 w-fit text-sm p-2.5  flex gap-2.5 border border-grey-300 rounded-lg">
            <img src={transfer} alt="transfer" />
            User requested Transfer to Human
          </span>
        </div>
      )}
      {agentTakeOver && (
        <div className="flex justify-center gap-3 my-10">
          <img src={line} alt="line" />

          <p className="text-BLACK-_160 text-xs font-medium">
            You joined the conversation at 5:00 am, 23rd July,2024 from outside
          </p>

          <img src={line} alt="line" />
        </div>
      )}

      {agentTakeOver && (
        <div className="flex justify-center gap-3 my-10">
          <img src={line} alt="line" />

          <p className="text-BLACK-_160 text-xs font-medium">
            You joined the conversation at 5:00 am, 23rd July,2024 from outside
          </p>

          <img src={line} alt="line" />
        </div>
      )}
      {isUserTyping && (
        <div className="mt-6  py-4 px-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <div className="w-5 h-5 rounded-full bg-BLUE-_200 capitalize text-white flex justify-center items-center font-bold text-[10px]">
                {stateCustomerDetails?.customer?.name.charAt(0) ??
                  stateCustomerDetails?.random_customer?.random_username?.charAt(
                    0
                  )}
              </div>
              <p className="text-xs font-semibold capitalize">
                {stateCustomerDetails.customer.name ??
                  stateCustomerDetails?.random_customer?.random_username}
              </p>
            </div>
          </div>
          <p className="mt-1 text-sm">
            typing...
            {/* Who is Rory Sutherland? */}
            {/* <span className="text-PRIMARY ml-1">
        @WebSearch
      </span> */}
          </p>
        </div>
      )}
      {/* INFOMRATION  */}
      {conversationResolved && (
        <div className="flex justify-center gap-3 my-10">
          <img src={line} alt="line" />

          <p className="text-BLACK-_160 text-xs font-medium">
            You resolved the conversation at
            {formatDateTime(conversationResolved)}
          </p>

          <img src={line} alt="line" />
        </div>
      )}
      {showSummary && (
        <div className="pb-14 mt-4">
          <Summary title="Conversation Summary" />
        </div>
      )}
    </div>
  );
}

export default ConversationChatBox;

import newTestLogo from "Assets/svg/test-left.svg";
import { useSelector } from "react-redux";
import { marked } from "marked";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm"; // For GitHub-flavored markdown like tables, strikethrough, etc.
import rehypeRaw from "rehype-raw"; // To allow rendering HTML in markdown
import Spinner from "component/Spinner";

function Summary({ title ,summaryLoading}) {
  function formatTo12HourTime(isoDate) {
    const date = new Date(isoDate);

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'

    const minutesStr = minutes < 10 ? "0" + minutes : minutes; // Ensure two-digit minutes

    const timeString = `${hours}:${minutesStr} ${ampm}`;
    return timeString;
  }
  const { conversationSummary } = useSelector((state) => state?.getCustomers);
  const renderMarkdown = (text) => {
    const markdown = text || "";
    const __html = marked(markdown, { sanitize: true });
    // console.log("stuff is", text);
    return { __html };
  };
  return (
    <div
      style={{
        background:
          "linear-gradient(79.13deg, rgba(191, 29, 248, 0.05) -18.32%, rgba(26, 116, 249, 0.05) 40.22%, rgba(254, 135, 172, 0.05) 97.32%)",
      }}
      className="p-5 rounded-lg"
    >
          {summaryLoading ? (
            <div className="h-[20vh] w-full flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="mt-2">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <img
                  src={newTestLogo}
                  alt="sales"
                  className="w-5 h-5 rounded-full"
                />
                <p className="text-sm font-semibold ">
                  {title ? title : "Conversation Summary"}
                </p>
              </div>
              <p className="text-BLACK-_160 font-medium text-xs">
                {formatTo12HourTime(conversationSummary.created_at)}
              </p>
            </div>
            <div className="mt-5 text-sm text-grey-100 break-all overflow-auto ">
              <Markdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                className="prose prose-sm prose-indigo"
              >
                {conversationSummary?.summary}
              </Markdown>
            </div>
          </div>
          )}
      {/* AI  */}
    
    </div>
  );
}

export default Summary;

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import { api } from "../../services/api";
import { appUrls } from "../../services/urls";


const initialState = {
    loading: false,
    data: [],
    error: ""
}

export const fetchWalletBalance = createAsyncThunk(
    "balance/fetchWalletBalance",
    async(values, { rejectWithValue }) => {
        try {
            const res = await api.get(appUrls?.WALLET_BALANCE_URL)
            return res?.data?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)

        }

    }
)

const walletBalanceSlice = createSlice({
    name: "balance",
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchWalletBalance.pending, state => {
            state.loading = true
        });
        builder.addCase(fetchWalletBalance.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(fetchWalletBalance.rejected, (state, action) => void(
            state.loading = false,
            state.data = "",
            state.error = action.payload
        ))
    }
})

export default walletBalanceSlice.reducer
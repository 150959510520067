import React, { useState, useEffect, useRef } from "react";
import { BiChevronRight } from "react-icons/bi";
import docImage from "../../Assets/svg/docCard.svg";
import shareImage from "../../Assets/svg/arrow-left.svg";
import shareImageLight from "../../Assets/svg/arrow-left-light.svg";
import EmployeePackage from "../../component/modals/Integration/EmployeePackage";
import PreviewModal from "../../component/modals/Integration/PreviewModal";
import {
  getApplicationById,
  setAgent,
  getApplicationDraft,
} from "features/application/getApplicationByIdSlice";
import axios from "axios";
import TokenService from "services/token";
import { ErrorMessage, SuccessMessage } from "Toast-Messages/Toastmessages";
import skyBackground from "../../Assets/svg/docSkyBackgroud.svg";
import arrowLeft from "../../Assets/svg/arrowLeftt.svg";
import avatar1 from "Assets/svg/avatar1.svg";
import avatar2 from "Assets/svg/avatar2.svg";
import shareBlue from "Assets/svg/arrow-leftBlue.svg";
import copyBlue from "Assets/svg/copyy.svg";

import Integrate from "./component/Integrate";
// import Tool from './component/Tool'
// import ApiKey from './component/ApiKey'
import ModalPop from "../../component/modalPop";
import { BottomGuide } from "../../component/modals/agentGuide/Guide";

import AgentB from "../../Assets/svg/agent6.svg";
import Test from "./component/Test";
import MakeRequest from "../../Agent/ServiceLayout/Modal/MakeRequest";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentEmployee,
  updateAgentModals,
} from "@/../features/modals/agentModalsSlice";

const Integration = ({ applicationId }) => {
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const newAgent = getAgentApplication?.agent || null;
  // console.log("agent", newAgent);
  // const [openBottomGuide, setOpenBottomGuide] = useState(false);
  const [openMakeReqModal, setOpenMakeReqModal] = useState(false);
  const [activeIcon, setActiveIcon] = useState("one");
  const [activeColor, setActiveColor] = useState(
    newAgent.is_light ? "two" : "one"
  );
  const [changesMade, setChangesMade] = useState(false);
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the effect on the first render
      isFirstRender.current = false;
      return;
    }
    setChangesMade(true);
  }, [activeColor, activeIcon]);
  const location = useLocation();
  const dispatch = useDispatch();

  // const setBottomIntegrationGuide = () => location.pathname === "/agent" ? localStorage.setItem("integration", true) : null;

  // const getIntegrationGuide = localStorage.getItem("integration");

  // useEffect(() => {
  //   if(getIntegrationGuide) {
  //     setOpenBottomGuide(false)
  //     } else {
  //       setOpenBottomGuide(true)
  //     }
  // }, [])

  const { id } = useSelector((state) => state.getApplicationByUserId.agent);
  const config = useSelector((state) => state.agentModals.employeeModals);
  // console.log(config, "configA");

  const getIntegrationGuide = config?.integration;
  // console.log(getIntegrationGuide, "configA getIntegrationGuide");

  // useEffect(() => {
  //     if(getIntegrationGuide) {
  //         setOpenBottomGuide(true)
  //       } else {
  //         setOpenBottomGuide(false)
  //       }
  // }, [getIntegrationGuide]);

  const handleAgentModal = (name) => {
    const currentModalState = config[name];
    if (currentModalState) {
      const modalsVisibility = { ...config, [name]: false };
      dispatch(updateAgentModals({ modalsVisibility, id: id }))
        .then((res) => {
          dispatch(getCurrentEmployee({ id: id }));
        })
        .catch((error) => {
          console.error("Failed to update modal state:", error);
        });
    }
  };

  const [showDocumentationScreen, setShowDocumentationScreen] = useState(false);
  const [preview, setPreview] = useState(false);
  const [preview2, setPreview2] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [isChanging, setIsChanging] = useState(false);
  const [documentationModal, setDocumentationModal] = useState(false);
  const token = TokenService.getToken();
  const URL = process.env.REACT_APP_BASEURL;

  const fullAgent = getAgentApplication?.agent || null;
  const newagentType = fullAgent?.type || null;
  const handleChanges = async () => {
    try {
      setIsChanging(true);
      const formData = new FormData();
      formData.append("is_light", activeColor === "one" ? false : true);
      formData.append("type", newagentType);

      const response = await axios.put(
        `${URL}/dashboard/applications/${applicationId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
          },
        }
      );
      const object = {
        ...(response?.data?.data?.draft
          ? response?.data?.data?.draft
          : response?.data?.data),
      };
      object.id = response?.data?.data?.id;
      dispatch(setAgent(object));
      dispatch(getApplicationById());
      dispatch(getApplicationDraft(applicationId));
      setSuccess("Your details have been uploaded successfully");
      setTimeout(() => {
        setSuccess(false);
      }, 1000);
    } catch (error) {
      console.error("Error updating data:", error);
      setError("Error updating data. Please try again.");
      setTimeout(() => {
        setError();
      }, 1000);
    } finally {
      setIsChanging(false);
    }
  };

  const script = `<script
  crossorigin
  src="https://unpkg.com/react@18/umd/react.development.js"
></script>
<script
  crossorigin
  src="https://unpkg.com/react-dom@18/umd/react-dom.development.js"
></script>
<script type="text/javascript">
  (function (window, document, scriptUrl) {
    var script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";
    script.src = scriptUrl;
    script.onload = function () {
      if (window.WidgetLibrary) {
        console.log(typeof window.WidgetLibrary.initChatWidget);
        window.WidgetLibrary.initChatWidget('${newAgent.id}');
      } 
    };
    script.onerror = function () {
      console.error("Failed to load widget");
    };
    document.head.appendChild(script);
  })(window, document, "${process.env.REACT_APP_WIDGET_URL}");
</script>`;
  return (
    <div className="mt-[27px]">
      {showDocumentationScreen ? (
        <div>
          <header className="flex justify-between gap-1 items-center mb-4">
            {/* <div
              onClick={() => setShowDocumentationScreen(false)}
              className="cursor-pointer text-[#D2D0D0] text-sm font-normal hover:underline"
            >
              Integrations
            </div> */}
            <div
              className="flex gap-2 cursor-pointer"
              onClick={() => setShowDocumentationScreen(false)}
            >
              <img src={arrowLeft} alt="Uploaded" className="" />
              <p className="text-[#121212] text-sm font-normal ">
                Website/Store/CustomApp
              </p>
            </div>

            <button
              onClick={() => {
                handleChanges();
              }}
              className={`${
                !changesMade
                  ? "bg-[#A0A0A0] cursor-not-allowed"
                  : "bg-[#121212]"
              } flex gap-2 items-center outline-none py-2.5 w-auto px-5  rounded-lg text-white text-[14px] font-semibold`}
            >
              {isChanging ? (
                <div className="flex justify-center items-center">
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mx-7"></div>
                </div>
              ) : (
                " Save Changes"
              )}
            </button>
          </header>
          <div className="rounded-lg px-4 py-7 bg-skyBlue1 relative flex items-center justify-between">
            <img
              src={skyBackground}
              alt="Uploaded"
              className="absolute  -bottom-20 right-10 z-0 w-[604px] h-[110px]"
            />
            <div className="flex flex-col gap-4">
              <p className="text-bamboo font-bold text-base">
                Add your Assistant to your Website
              </p>
              <p className="text-bamboo text-xs font-medium">
                Add the employee package by embedding the code to add your{" "}
                <br />
                employee to your Website, Store or Custom App
              </p>
            </div>
            <div className="flex gap-4">
              <button
                onClick={() =>
                  window.open(
                    `${window.location.origin}/widget?id=${id}`,
                    "_blank"
                  )
                }
                className="flex gap-2 items-center outline-none py-3 w-auto px-5 bg-white border border-[#EEEEEE] rounded-lg text-[#121212] text-[12px] font-semibold"
              >
                Preview Widget
                <img
                  src={shareImage}
                  alt="Uploaded"
                  className=" object-cover"
                />
              </button>
              <button
                onClick={() => setDocumentationModal(true)}
                className="flex gap-2 items-center outline-none py-3 w-auto px-5 bg-black border border-[#EEEEEE] rounded-lg text-white text-[12px] font-semibold"
              >
                View Script
                <img
                  src={shareImageLight}
                  alt="Uploaded"
                  className=" object-cover"
                />
              </button>
            </div>
          </div>
          {/* <div className="mt-11 flex justify-between z-10 relative">
            <div className="flex flex-col gap-1">
              <p className="text-BLACK-_500 text-sm">Appearance</p>
              <p className="text-xs font-medium">
                Customize the appearance of your employee on your website. Check
                out a
                <span
                  className="text-PRIMARY px-1 underline cursor-pointer"
                  onClick={() => setPreview(true)}
                >
                  Preview here
                </span>
              </p>
            </div>
            <button
              onClick={() => {
                handleChanges();
              }}
              className="flex gap-2 items-center outline-none py-2.5 w-auto px-5 bg-[#121212] rounded-lg text-white text-[14px] font-semibold"
            >
              {isChanging ? (
                <div className="flex justify-center items-center">
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mx-7"></div>
                </div>
              ) : (
                " Save Changes"
              )}
            </button>
          </div> */}

          <div className="grid grid-cols-2 mt-[52px]  z-10 relative">
            <div className="flex flex-col gap-1">
              <p className="text-BLACK-_500 text-sm">Embed Widget</p>
              <p className="text-xs font-medium text-BLACK-_200">
                Copy script to embed your assistant to your website/app
              </p>
            </div>

            <div className="flex gap-4 text-[#0359D8] text-xs ">
              <div
                className={` border cursor-pointer border-[#EE55EE55] flex items-center gap-0.5 bg-[#FAFAFA] p-2.5 rounded-lg `}
                onClick={() => {
                  navigator.clipboard
                    .writeText(script)
                    .then(() => {
                      setSuccess("copied");
                      setTimeout(() => {
                        setSuccess(false);
                      }, 3000);
                    })
                    .catch((err) => {
                      setError(false);
                    });
                }}
              >
                Copy Script
                <img
                  src={copyBlue}
                  alt="avatar1"
                  className="w-[14px] h-[14px]"
                />{" "}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 mt-[52px]">
            <div className="flex flex-col gap-1">
              <p className="text-BLACK-_500 text-sm">Preview/Test Widget</p>
              <p className="text-xs font-medium text-BLACK-_200">
                Preview and Test your widget on web or mobile
              </p>
            </div>

            <div className="flex gap-4 text-[#0359D8] text-xs ">
              <div
                className={` border cursor-pointer border-BLACK-_400 flex items-center gap-0.5 bg-[#FAFAFA] p-2.5 rounded-lg `}
                onClick={() => {
                  window.open(
                    `${window.location.origin}/widget?id=${id}`,
                    "_blank"
                  );
                }}
              >
                Preview on Web
                <img
                  src={shareBlue}
                  alt="avatar1"
                  className="w-[14px] h-[14px]"
                />{" "}
              </div>
              <div
                className={` cursor-pointer border border-BLACK-_400 flex items-center gap-0.5 p-2.5  bg-[#FAFAFA] rounded-lg `}
                onClick={() => setPreview2(true)}
              >
                Preview on Mobile
                <img
                  src={shareBlue}
                  alt="avatar2"
                  className="w-[14px] h-[14px]"
                />{" "}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 mt-[52px]">
            <div className="flex flex-col gap-1">
              <p className="text-BLACK-_500 text-sm">Employee Avatar</p>
              <p className="text-xs font-medium text-BLACK-_200">
                Select an avatar to represent your customer care assistant
              </p>
            </div>
            <div className="flex gap-4 ">
              <div
                className={`p-2 border cursor-pointer border-BLACK-_400 rounded-lg  ${
                  activeIcon === "one" ? " bg-skyBlue2 " : ""
                }`}
                onClick={() => setActiveIcon("one")}
              >
                <img
                  src={avatar1}
                  alt="avatar1"
                  className="w-[37px] h-[37px]"
                />{" "}
              </div>
              <div
                className={`p-2 cursor-pointer border border-BLACK-_400 rounded-lg  ${
                  activeIcon === "two" ? " bg-skyBlue2 " : ""
                }`}
                onClick={() => setActiveIcon("two")}
              >
                <img
                  src={avatar2}
                  alt="avatar2"
                  className="w-[37px] h-[37px]"
                />{" "}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 mt-[52px]">
            <div className="flex flex-col gap-1">
              <p className="text-BLACK-_500 text-sm">Choose Background Color</p>
              <p className="text-xs font-medium text-BLACK-_200">
                Select the background color for your chat interface
              </p>
            </div>
            <div className="flex flex-col gap-3">
              <p className="text-xs font-medium text-BLACK-_200">
                Select Color (hover to preview)
              </p>
              <div className="flex gap-4 ">
                <div
                  className={`p-2 border cursor-pointer bg-black rounded-full w-[27px] h-[27px] ${
                    activeColor === "one"
                      ? "  border-2 border-blue-500  "
                      : " border-BLACK-_400"
                  }`}
                  onClick={() => setActiveColor("one")}
                ></div>
                <div
                  className={`p-2 cursor-pointer border-2  rounded-full w-[27px] h-[27px] ${
                    activeColor === "two"
                      ? " border-2 border-blue-500 "
                      : " border-BLACK-_400"
                  }`}
                  onClick={() => setActiveColor("two")}
                ></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex justify-between">
            <div className="flex flex-col gap-1">
              <h2 className="text-2xl text-BLACK-_100 font-medium">
                Integrations
              </h2>
              <p className="text-xs text-[#7F7F81] font-normal">
                Select and integrate your assistant to any of the channels
                below.
              </p>
            </div>
            {/* <div className='flex flex-col gap-2'>
   <p className='text-[#7F7F81] text-xs'>Don’t see a tool you need?</p>
   <p className='text-[#1774FD] text-sm cursor-pointer font-semibold' onClick={() => setOpenMakeReqModal(true)}>
     Make a Request
   </p>
 </div> */}
          </div>

          <div className="mb-[50px] mt-[55px]">
            <Integrate
              setShowDocumentationScreen={setShowDocumentationScreen}
            />
          </div>

          {/* <hr className="w-[970px] my-[50px]" />

          <div>
            <Test />
          </div> */}
        </div>
      )}

      {/* <div className='mt-[112px]'>
            <Tool />
        </div> */}

      {/* <ModalPop isOpen={openBottomGuide}>
          <BottomGuide
              title="Testing and Integration"
              subtitle="Choose and peer a channel for your agent to interact with your customers."
              secondSubtitle="You can also test your agent before integrating fully with our WhatsApp Simulator."
              link="Test and Deploy Agent" 
              handleClose={() => { 
                handleAgentModal("integration")
                setOpenBottomGuide(false); 
                // setBottomIntegrationGuide() 
              }}
              image={AgentB}
          />
        </ModalPop> */}

      <ModalPop isOpen={documentationModal}>
        <EmployeePackage
          script={script}
          handleClose={() => setDocumentationModal(false)}
        />
      </ModalPop>
      <ModalPop isOpen={preview}>
        <PreviewModal handleClose={() => setPreview(false)} />
      </ModalPop>
      <ModalPop isOpen={preview2}>
        <PreviewModal
          link={`${window.location.origin}/widget?id=${id}`}
          handleClose={() => setPreview2(false)}
          mobile
        />
      </ModalPop>

      <ModalPop isOpen={openMakeReqModal}>
        <MakeRequest handleClose={() => setOpenMakeReqModal(false)} />
      </ModalPop>

      <ModalPop isOpen={openMakeReqModal}>
        <MakeRequest handleClose={() => setOpenMakeReqModal(false)} />
      </ModalPop>
      {success && <SuccessMessage success={success} />}
      {error && <ErrorMessage error={error} />}
    </div>
  );
};

export default Integration;

import line from "Assets/svg/literalLine.svg";
import newTestLogo from "Assets/svg/test-left.svg";
import { useDispatch, useSelector } from "react-redux";

function Conversation({ activeConversationHistory, customer }) {
  function formatDateTime(isoString) {
    const date = new Date(isoString);

    // Add 1 hour
    date.setUTCHours(date.getUTCHours() + 1);

    // Extracting date components
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const day = date.getUTCDate();
    const month = date.toLocaleString("en-US", {
      month: "long",
      timeZone: "UTC",
    });
    const year = date.getUTCFullYear();

    // Convert 24-hour time to 12-hour time
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Add leading zero to minutes if necessary
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    // Format the day with ordinal suffix
    const ordinalSuffix = (n) => {
      if (n > 3 && n < 21) return "th";
      switch (n % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const dayWithSuffix = day + ordinalSuffix(day);

    // Construct the final formatted string
    return ` ${hours}:${formattedMinutes} ${ampm}, ${dayWithSuffix} ${month}, ${year}`;
}
  function formatTo12HourTime(isoDate) {
    const date = new Date(isoDate);

    // Set the desired fixed time
    date.setUTCHours(12);
    date.setUTCMinutes(30);

    // Format hours and minutes to ensure they are two digits
    const hours = ("0" + date.getUTCHours()).slice(-2);
    const minutes = ("0" + date.getUTCMinutes()).slice(-2);

    // Determine AM/PM based on hours
    const period = date.getUTCHours() >= 12 ? "pm" : "am";

    return `${hours}:${minutes} ${period}`;
  }
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const newAgent = getAgentApplication?.agent || null;
  return (
    <>
      {activeConversationHistory && customer && (
        <div className="h-full overflow-auto">
          {/* INFOMRATION  */}
          <div className="flex justify-center gap-3 ">
            <img src={line} alt="line" />

            <p className="text-BLACK-_160 text-xs font-medium">
              <span className="capitalize">{customer?.name}</span>
              {` Started the conversation at ${formatDateTime(
                activeConversationHistory.created_at
              )}`}
            </p>
            <img src={line} alt="line" />
          </div>
          {/* CUSTOMER  */}
          {activeConversationHistory?.messages?.length &&
            activeConversationHistory?.messages.map(
              ({ message, created_at, role, status }, index) => (
                <div key={index} className="mt-6">
                  <div className="flex justify-between items-center">
                    {role === "assistant" ? (
                      <>
                        {status === "connected" ? (
                          <div className="flex justify-center gap-3 my-8">
                            <img src={line} alt="line" />

                            <p className="text-BLACK-_160 text-xs font-medium">
                              You joined the conversation at
                              {formatDateTime(created_at)}
                            </p>
                            <img src={line} alt="line" />
                          </div>
                        ) : (
                          <div className="flex items-center gap-2">
                            <img
                              src={
                                newAgent?.icon_url
                                  ? newAgent?.icon_url
                                  : newTestLogo
                              }
                              alt="sales"
                              className="w-5 h-5 rounded-full"
                            />
                            <p className="text-xs font-semibold">
                              {newAgent.name}{" "}
                            </p>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="flex items-center gap-2">
                        <div className="w-5 h-5 rounded-full capitalize bg-BLUE-_200 text-white flex justify-center items-center font-bold text-[10px]">
                          {customer.name.charAt(0)}
                        </div>
                        <p className="text-xs font-semibold">
                          {" "}
                          {customer.name}
                        </p>
                      </div>
                    )}

                    <p className="text-BLACK-_160 font-medium text-xs">
                      {formatTo12HourTime(created_at)}
                    </p>
                  </div>
                  <p className="mt-1 text-sm">{message}</p>
                </div>
              )
            )}
          {/* AI  */}

          <div className="flex justify-center gap-3 ">
            <img src={line} alt="line" />

            <p className="text-BLACK-_160 text-xs font-medium">
              You resolved the conversation at 5:00 am, 23rd July,2024
            </p>
            <img src={line} alt="line" />
          </div>
        </div>
      )}
    </>
  );
}

export default Conversation;

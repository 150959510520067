import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { api } from "../../services/api"
import { appUrls } from "../../services/urls";



const initialState = {
    loading: false,
    data: [],
    error: ""
};

export const getCreditBalance = createAsyncThunk(
    "credit-balance/retrieve", 
    async(values, { rejectWithValue }) => {
        try {
            // const res = await api.get('/dashboard/businesses/credits');
            // console.log(res?.data, "credit balance data");
            // return res?.data?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)


const getCreditBalanceSlice = createSlice({
    name: "credit-balance",
    initialState,
    extraReducers: builder => {
        builder.addCase(getCreditBalance.pending, state => {
            state.loading = true
        });
        builder.addCase(getCreditBalance.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(getCreditBalance.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default getCreditBalanceSlice.reducer
import React, { useState } from 'react'
import { HiXMark } from 'react-icons/hi2'
import FeaturesAgent from './FeaturesAgent'
import axios from 'axios'
import TokenService from '../../../services/token'
import { CgSpinner } from 'react-icons/cg'
import { useNavigate } from 'react-router'
import { SuccessMessage } from '../../../Toast-Messages/Toastmessages'

export default function AgentModal({ singleAgent, fetchFeatures, currentAgentIndex, handleNext, featureData, data, handleClose, selectedAgent, state }) {
    const [deleteLoading, setDeleteLoading] = useState(false)
    const token = TokenService.getToken()
    const URL = process.env.REACT_APP_BASEURL;
    const navigate = useNavigate()
    const [success, setSuccess] = useState()
    const pluginId = sessionStorage.getItem('plugin_id')
    // console.log({selectedAgent})
    
    const disconnectSingleAgent = async () => {
        setDeleteLoading(true)
        try {
            const res = await axios.delete(`${URL}/dashboard/applications/${selectedAgent?.id}/plugins/${pluginId}/remove`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (res.data.message === "Application module removed") {
                setDeleteLoading(false)
                setSuccess('Assistant disconnected successfully')
                handleClose()
                navigate('/plugins')
            }
        } catch(error) {
            setDeleteLoading(false)
            console.error(error)
        }
    }
    
    const disconnectAgent = async () => {
        setDeleteLoading(true)
        try {
            const res = await axios.delete(`${URL}/dashboard/applications/${selectedAgent[currentAgentIndex]?.application?.id}/plugins/${pluginId}/remove`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (res.data.message === "Application module removed") {
                setDeleteLoading(false)
                setSuccess('Assistant disconnected successfully')
                if (selectedAgent.length === (currentAgentIndex + 1)) {
                    handleClose()
                    navigate('/plugins')
                } else {
                    handleNext()
                }

            }
        } catch(error) {
            setDeleteLoading(false)
            console.error(error)
        }
    };

    const featureLength =  featureData?.length;
    const minHeight = 310;
    const maxHeight = 700;
    const step = 65;
    const dynamicHeight = featureLength === 1 ? minHeight : minHeight + Math.min(featureLength - 1, Math.floor((maxHeight - minHeight) / step)) * step;
    

    return (
        <div className="pb-5 w-[1230px] h-fit bg-white ml-36 rounded-lg shadow-makeReqModal font-figtree relative mt-[15px]"> {/* min-h-[600px] */}
            <header className='py-[15px] pl-10 pr-6 flex items-center justify-between'>
                <div className='flex flex-col gap-2'>
                    <h3 className='text-[24px] font-medium text-black'>{state?.plugin?.name}</h3>
                    <h5 className='text-xs text-[#7F7F81]'>{state?.plugin?.description}</h5>
                </div>
                <div
                    className="border p-2 rounded-lg border-[#F7F7F7] cursor-pointer"
                    onClick={handleClose}
                >
                    <HiXMark className="text-[20px] text-[#828282]" />
                </div>
            </header>
            <hr className='bg-[#F7F7F7] mb-6 '/>
            <div style={{height: `${dynamicHeight}px`}} className="flex flex-col gap-8 px-10 overflow-y-scroll hide-scrollbar">
                <div className='flex items-center justify-between w-full'>
                    <div className="flex flex-col gap-2">
                        <p className="font-medium text-2xl text-black">Features</p>
                        <p className="text-xs font-normal text-[#7F7F81]">Select features from the list below for your assistant.</p>
                    </div>
                    {deleteLoading ? (
                        <button className="w-[104px] h-[43px] border border-[#EEE] bg-[#FAFAFA] rounded-lg flex justify-center text-sm font-medium text-[#AF202D] items-center p-2">
                            <CgSpinner className=" animate-spin text-lg " />
                        </button>
                    ): (
                        <></>
                        // <button onClick={singleAgent ? disconnectSingleAgent : disconnectAgent} className="w-[104px] h-[43px] border border-[#EEE] bg-[#FAFAFA] rounded-lg flex justify-center text-sm font-medium text-[#AF202D] items-center p-2">
                        //     Disconnect
                        // </button>
                    )}
                </div>
                <FeaturesAgent singleAgent={singleAgent} handleNext={handleNext} fetchFeatures={fetchFeatures} selectedAgent={selectedAgent} currentAgentIndex={currentAgentIndex} featureData={featureData} handleClose={handleClose} state={state}/>
            </div>
            {success && <SuccessMessage success={success} />}
        </div>
    )
}

import React, {
  useState,
  Fragment,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { Bell, Down, Help, Check, Add } from "../Assets/svg";
import help from "../Assets/svg/help.svg";
import Aside from "../component/Aside";
import { useNavigate, useLocation } from "react-router";
import { Menu, Transition } from "@headlessui/react";
import { HiXMark } from "react-icons/hi2";
import Notifications from "../component/Notifications";
import { useSelector, useDispatch } from "react-redux";
import { getApplicationById } from "../features/application/getApplicationByIdSlice";
import {
  getBusiness,
  setBusinessConfig,
} from "../features/business/getBusinessSlice";
import { getConversations } from "features/customer/customerSlice";
import { getNotification } from "../features/notification/getNotficationSlice";
import { setBusiness } from "../features/business/getBusinessSlice";
import { setAgent } from "../features/application/getApplicationByIdSlice";

import { UserContext } from "../context/UserContext";
import TokenService from "../services/token";
import TestAgent from "@/../Agent/TestAgent/TestAgent";

import axios from "axios";
import { getProfile } from "../features/profile/getProfileSlice";
import {
  ErrorMessage,
  SuccessMessage,
} from "@/../Toast-Messages/Toastmessages";
import { getCurrentEmployee } from "@/../features/modals/agentModalsSlice";
import { getAllPlugins } from "@/../features/plugins/getAllPluginsSlice";
import { getCurrentPlan } from "@/../features/profile/getCurrentPlanSlice";
import RedeemSuccess from "@/../component/modals/redeemgift/RedeemSuccess";
import UpgradePlan from "@/../Routes/UpgradePlan";
import ModalPop from "@/../component/modalPop";
import UpgradeToPlan from "@/../Routes/UpgradeToPlan";
import ModalContainer from "@/../component/modals/ModalContainer";
import { useModal } from "@/../context/ModalContext";
// import ChangesBanner from "@/../component/ChangesBanner";

export default function DashboardLayout({
  title,
  user,
  children,
  pad,
  height,
  getKycStatus,
  padd,
}) {
  const navigate = useNavigate();
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const newAgent = getAgentApplication?.agent || null;
  const { socket } = useContext(UserContext);
  const fetchConversation = async () => {
    // const response = await dispatch(getConversation(id));
    await dispatch(
      getConversations({
        applicationId: newAgent.id,
        page: 1,
        per_page: 999,
        start_date: "",
        end_date: "",
        search_term: "",
        status: "",
      })
    );
  };
  useEffect(() => {
    // socket.on("disconnect", () => {
    //   console.log("disconnected", socket.id); // undefined
    // });
    socket.emit("joinApplication", { applicationId: newAgent.id });
    socket.on("newMessage", fetchConversation);
    return () => {
      socket.off("newMessage", fetchConversation);
      // socket.disconnect();
    };
  }, [newAgent.id]);
  const { openModal } = useModal();
  const [readNotification, setReadNotification] = useState(false);
  const [propShowPopUp, setPropShowPopUp] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [openUpgradeToPlan, setOpenUpgradeToPlan] = useState(false);
  const [openRedeemSuccess, setOpenRedeemSuccess] = useState(false);
  const [openUpgradePlan, setOpenUpgradePlan] = useState(false);
  const [openPayModal, setOpenPayModal] = useState(false);

  // const [openSetupAssistance, setOpenSetupAssistance] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { logout } = useContext(UserContext);
  const active = location.pathname;
  const token = TokenService.getToken();

  const handleSetupAssistanceClick = () => {
    // navigate("/settings?openSetupAssistance=true");
    openModal("ModalA");
  };

  const userLogin = useSelector((state) => state.userLogin);
  // console.log(userLogin, "this is userLogin data from dashboard layout")
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  // const agentApp = useSelector((state) => state.getApplicationByUserId.agent);
  // console.log(agentApp, "this is agent app from dashboardLayout");
  // console.log(appDetail, "this is appDetail from dashboard Layout")
  const businessDetail = useSelector((state) => state.getBusiness);
  // console.log(businessDetail, "this is businessDetail from DashbardLayout")
  const businesses = businessDetail?.data?.data;
  // console.log(businesses, "this is businesses data from dashboard layout")

  const business = businesses && businesses.length >= 1 ? businesses[0] : {};
  // console.log(business, "this is business from dashboard layout")

  const [skip, setSkip] = useState(false);
  const cBus = localStorage.getItem("cBus");

  // const [selectedBusiness, setSelectedBusiness] = useState(
  //   JSON.parse(cBus) || business
  // );
  const getUserBusiness = useSelector((state) => state.getBusiness);

  const selectedBusiness = getUserBusiness.activeBusiness;

  // console.log(selectedBusiness, "this is selected business from dashboard layout")

  // const [notificationData, setNotificationData] = useState([]);

  //added this to retrieve client's data
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getCurrentPlan());
  }, []);

  const plan = useSelector((state) => state.getCurrentPlan.data);
  const { subscriptions, currentPlan: current } = plan;
  const currentPlan = current?.subscription_details?.name;
  const nextAvailablePlanAmount = subscriptions?.[1]?.monthly_amount;

  const handleOpenModal = () => {
    if (currentPlan.includes("Startups")) {
      return openModal("ModalB", {
        title: "Add New Business",
        text: "Need more assistants for your various businesses? Upgrade to have multiple accounts",
        icon: "isotoxal",
      });
      // setOpenUpgradeToPlan(true);
    } else {
      navigate("/company");
    }
  };

  // const { loading } = useSelector((state) => state.updateProfile);
  const getUserProfile = useSelector((state) => state.getProfile);
  // console.log(getUserProfile, "this is the getuserProfile data from dashboard layout")
  const profile = getUserProfile?.data?.data;
  // console.log(profile, "this is the profile from dashboard layout")
  // console.log(profile, "this is the profile from dashboard layout")
  //end

  const client = userLogin?.user?.data?.user;
  // console.log(client, "this is client data from dashboard Layout")

  const id = client?.id;

  const agentDetails = appDetail?.data?.data;
  // console.log(agentDetails, "this is agentDetails from Dashboard Layout")

  const localAgent = localStorage.getItem("wano_agent");
  const agent = agentDetails && agentDetails.length >= 1 ? agentDetails[0] : {};
  // console.log(agent, "this is agent from dashboardlayout");
  const agentDetail =
    localAgent == undefined || {} ? agent : JSON.parse(localAgent);
  const URL = process.env.REACT_APP_BASEURL;

  useEffect(() => {
    if (!localAgent) {
      localStorage.setItem("wano_agent", JSON.stringify(agentDetail));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!appId) return;
    dispatch(getApplicationById());
  }, [dispatch]);

  const { loading } = useSelector((state) => state.updateBusiness);

  useEffect(() => {
    dispatch(getBusiness());
  }, [loading, dispatch]);

  //   useEffect(() => {
  //     dispatch(getAllPlugins())
  // }, [])

  // useEffect(() => {
  //   // dispatch(getApplicationByUserId())
  // }, [])

  useEffect(() => {
    // Check if the current pathname is not '/plugins'
    if (!location.pathname.includes("/plugins")) {
      localStorage.removeItem("pluginState");
    }
  }, [location]); // Dependency array includes location to react on route changes

  const [unreadCount, setUnreadCount] = useState(0);
  const notificationDetail = useSelector((state) => state.getNotification);
  const notificationData = useMemo(
    () => notificationDetail?.data?.data || [],
    [notificationDetail]
  );

  useEffect(() => {
    dispatch(getNotification());
    // setNotificationData(notification.data);
  }, [dispatch]); //readNotification

  useEffect(() => {
    const unreadNotifications = notificationData.filter((n) => !n.is_read);
    setUnreadCount(unreadNotifications.length);
  }, [notificationData]);

  // Function to mark a single notification as read
  const clearNotification = async (notificationId) => {
    try {
      const response = await axios.put(
        `${URL}/dashboard/notifications/${notificationId}/read`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.data) {
        dispatch(getNotification());
        setSuccess("Successfully marked as read");
        setTimeout(() => {
          setSuccess(null);
        }, 1000);
      }
    } catch (error) {
      console.log(error, "this is the error");
      setError("Error");
      setTimeout(() => {
        setError(null);
      }, 1000);
    }
  };

  //old
  // Function to clear all notifications
  //   const clearAllNotifications = async () => {
  //     try {
  //       const unreadNotificationIds = notificationData
  //         .filter((n) => !n.is_read)
  //         .map((n) => n.id);

  //     await Promise.all(unreadNotificationIds.map(clearNotification));
  //   } catch (error) {
  //     console.log(error, "this is the error");
  //   }
  // };

  //newest addition
  const clearNotificationsSafely = async (ids) => {
    return Promise.all(
      ids.map((id) =>
        clearNotification(id).catch((e) => {
          console.error(`Failed to clear notification ${id}:`, e);
          return null; // or handle error more specifically
        })
      )
    );
  };
  // Function to clear all notifications safely
  const clearAllNotifications = async () => {
    try {
      const unreadNotificationIds = notificationData
        .filter((n) => !n.is_read)
        .map((n) => n.id);

      await clearNotificationsSafely(unreadNotificationIds);
    } catch (error) {
      console.error("Error clearing all notifications:", error);
    }
  };

  const handleBusinessSelection = async (s) => {
    await dispatch(setBusiness(s));
    await dispatch(setBusinessConfig(s?.config));
    navigate("/dashboard?switchEmployee=true");
    sessionStorage.removeItem("serviceData");
    sessionStorage.removeItem("choosenServiceData");
    try {
      const res = await axios.put(
        `${URL}/dashboard/businesses/${s.id}/switch`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      localStorage.setItem("cBus", JSON.stringify(s));
      // console.log(s, "this is s") //added this
      const applicationResponse = await dispatch(getApplicationById());
      // console.log(applicationResponse?.payload?.data,'applicationResponse?.payload?.data?.length')
      if (applicationResponse?.payload?.data?.length >= 1) {
        dispatch(setAgent(applicationResponse?.payload?.data[0]));
        localStorage.setItem(
          "wano_agent",
          JSON.stringify(applicationResponse?.payload?.data[0])
        );
        dispatch(getAllPlugins(applicationResponse?.payload?.data[0]?.id));
        // setTimeout(() => {
        //   navigate("/dashboard");
        // }, 1000);
      } else {
        localStorage.setItem("wano_agent", JSON.stringify({}));
        localStorage.setItem("Wano_Feature", false);
        // setTimeout(() => {
        //   navigate("/dashboard");
        // }, 1000);
      }

      // setSelectedBusiness(s);
      if (res.status == 200) {
        // window.location.reload()
      }
    } catch (error) {
      return console.log(error?.response?.data?.message);
    }
  };
  const dontShowModalsAgain = () => {
    sessionStorage.clear();
    // guides shouldn't clear from local storage
    const keysToKeep = [
      "agent",
      "bottomAgent",
      "features",
      "agentSettings",
      "integration",
      "Ask agent",
      "customers",
      "plugins",
      "bill",
      "bottomBilling",
      "settings",
      "settings",
      "welcomeGuide",
      "confirmModal",
      "testEmployeePopup",
      "tourS",
      "tourOthers",
    ];

    const newData = {};

    // Iterate through all keys in local storage
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      // Check if the key should be kept
      if (keysToKeep.includes(key)) {
        // If the key should be kept, add it to the new data object
        newData[key] = localStorage.getItem(key);
      }
    }

    // Clear local storage
    localStorage.clear();

    // Set the new data object back to local storage
    for (const [key, value] of Object.entries(newData)) {
      localStorage.setItem(key, value);
    }
  };

  const { id: appId } = useSelector(
    (state) => state.getApplicationByUserId.agent
  );

  useEffect(() => {
    if (appId) {
      dispatch(getCurrentEmployee({ id: appId }));
    }
  }, [dispatch, agent?.id, appId]);

  // console.log(activeButton, "activeButton");

  return (
    <div className="flex h-screen relative">
      {/* {!["/ask-agent","/agent","/plugins"].includes(location.pathname) && <TestAgent />} */}

      {/* <aside className={`flex-[2] h-screen ${getKycStatus === undefined ? 'overflow-y-scroll' : 'overflow-hidden'} bg-white pl-4 pr-[26px] pt-7 border-r border-[F7F7F7]`}>
                <Aside data={agentDetails} client={client} business={business}/>
            </aside> */}
      <aside>
        <Aside
          data={agentDetails}
          client={client}
          business={business}
          setActiveButton={setActiveButton}
        />
      </aside>

      <div className="w-full overflow-y-scroll rounded">
        <div className="flex justify-between w-full px-10 items-center py-4 border-b border-[#E6E7EC]">
          <div>
            <p className="text-[18px] font-medium text-[#101828] capitalize">
              {title}
            </p>
          </div>
          <div className="flex gap-3 items-center cursor-pointer">
            <div
              className="bg-[#F0F6FF] rounded-[45px] py-[9px] px-[10px] flex items-center gap-[4px] cursor-pointer"
              onClick={handleSetupAssistanceClick}
            >
              <img src={help} alt="help icon" />
              <span className="text-[12px] leading-[14.4px] text-[#535353] font-medium">
                Setup Assistance
              </span>
            </div>
            <Menu as="div" className="relative">
              <Menu.Button
                className={`gap-[10px] items-center ${
                  active.includes("/getstarted") ? "hidden" : "flex"
                }`}
              >
                <div className="w-8 h-8 bg-[#1774FD] rounded-lg justify-center items-center flex">
                  <p className="text-white text-[16px] font-bold capitalize">
                    {profile?.first_name[0]}
                    {/* {client?.first_name[0]} */}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  {/* <p className="capitalize text-xs text-[#A7A7A7] text-start">{client?.first_name} {client?.last_name}</p> */}
                  <p className="capitalize text-sm text-[#535353] text-start">
                    {selectedBusiness?.name} {/* selectedBusiness */}
                  </p>
                </div>
                <div>
                  <Down />
                </div>
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-[9999] absolute right-0 mt-6 w-[240px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                  <div className="">
                    <div className="py-3 px-4 gap-3 items-center flex">
                      <div className="w-8 h-8 bg-[#1774FD] rounded-lg justify-center items-center flex">
                        <p className="text-white text-[16px] font-bold capitalize">
                          {profile?.first_name[0]}
                          {/* {client?.first_name[0]} */}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm font-medium text-[#121212] text-start capitalize">
                          {profile?.first_name} {profile?.last_name}{" "}
                          {/* {client?.first_name} {client?.last_name} */}
                        </p>
                        <p className="text-sm text-[#828282] capitalize">
                          {selectedBusiness?.name} {/* selectedBusiness */}
                        </p>
                      </div>
                    </div>
                    <div className="px-4 py-[6px]">
                      <p className="text-xs font-medium text-[#A7A7A7]">
                        Switch business
                      </p>
                    </div>
                    {businesses?.map((b, id) => {
                      return (
                        <Menu.Item key={id}>
                          <button
                            className="hover:bg-[#FAFAFA] capitalize group flex text-[#121212] justify-between w-full items-center px-4 py-[9px] font-medium text-sm"
                            onClick={() => handleBusinessSelection(b)}
                          >
                            {b?.name}
                            {selectedBusiness?.id === b?.id && (
                              <Check className="mr-2" />
                            )}
                          </button>
                        </Menu.Item>
                      );
                    })}

                    <Menu.Item>
                      <button
                        className="hover:bg-[#FAFAFA] group flex text-[#121212] justify-between w-full items-center px-4 py-[9px] font-medium text-sm"
                        onClick={handleOpenModal}
                      >
                        <div
                          className="flex items-center gap-2"
                          onClick={handleOpenModal}
                        >
                          <Add />
                          <p>Add New Business</p>
                          <p className="px-[6px] py-[4px] bg-[#1774FD] text-white rounded-[4px] text-[10px]">
                            pro
                          </p>
                        </div>
                      </button>
                    </Menu.Item>
                    <Menu.Item>
                      <button
                        className="hover:bg-[#FAFAFA] group flex text-[#AF202D] justify-between w-full items-center px-4 py-[9px] font-medium text-sm"
                        onClick={() => {
                          logout();
                          // localStorage.clear();
                          sessionStorage.clear();
                          dontShowModalsAgain();
                          navigate("/");
                        }}
                      >
                        Logout
                      </button>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            {skip || user ? (
              <Menu as="div" className="relative">
                <Menu.Button
                  className={`border p-2 border-[#F7F7F7] rounded-lg ${
                    unreadCount > 0 ? "relative" : ""
                  }`}
                >
                  <Bell />
                  {unreadCount > 0 && ( //shows the number of unread notifications
                    <span className="absolute top-0 right-0 w-4 h-4 bg-red-500 text-white text-xs rounded-full flex items-center justify-center">
                      {unreadCount}
                    </span>
                  )}
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-[9999] absolute right-0 mt-1 w-[482px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                    <div className="pt-[15px]">
                      <Menu.Item>
                        <div className="px-6 flex items-center justify-between mb-[14px]">
                          <p className="text-[#101828] text-[18px] font-medium">
                            Notifications
                          </p>
                          <div className="border p-2 -mt-1 rounded-lg border-[#F7F7F7] cursor-pointer">
                            <HiXMark className="text-[20px] text-[#828282]" />
                          </div>
                        </div>
                      </Menu.Item>
                      <hr className="bg-[#F7F7F7]" />
                      {notificationData?.length > 0 ? (
                        <>
                          <p
                            onClick={clearAllNotifications}
                            className="my-4 px-6 text-xs font-medium text-[#1774FD] cursor-pointer"
                          >
                            Mark all as read
                          </p>
                          {notificationData?.map((n, id) => {
                            //change this to notification?.data?.map()
                            return (
                              <Menu.Item as="div" key={id}>
                                <Notifications
                                  data={n}
                                  markAsRead={clearNotification}
                                />{" "}
                                {/* passed the func to clear single notification */}
                              </Menu.Item>
                            );
                          })}
                        </>
                      ) : (
                        <div className="p-6 flex justify-center items-center">
                          <p>No Notifications!</p>
                        </div>
                      )}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            ) : (
              // <div className='border p-[11px] border-[#F7F7F7] rounded-lg' onClick={() => navigate('/getstarted/dashboard')}>
              //     <p className='text-xs font-medium text-[#0359D8]'>Skip to Dashboard</p>
              // </div>
              ""
            )}
            <div
              className="cursor-pointer border p-2 border-[#F7F7F7] rounded-lg"
              onClick={() => navigate("/support")}
            >
              <Help />
            </div>
          </div>
        </div>
        {/* <hr className="absolute top-20 border-t w-full border-t-[#E6E7EC] " /> */}
        <div className="">
          {/* <ChangesBanner setPropShowPopUp={setPropShowPopUp} /> */}
          <ModalContainer />
          {children}
        </div>
        {/* <div
          className={`${pad && "pt-[23px] px-0"} ${padd && "px-7"} pt-9 ${
            !pad && "px-10"
          } ${height && "h-full"}`}
        >
          {children}
        </div> */}
        {error && <ErrorMessage error={error} />}
        {success && <SuccessMessage success={success} />}
      </div>

      {/* <ModalPop isOpen={openUpgradeToPlan}>
        <UpgradeToPlan
          handleClose={() => {
            setOpenUpgradeToPlan(false);
          }}
          nextModal={() => setOpenUpgradePlan}
          setOpenUpgradePlan={setOpenUpgradePlan}
          amount={nextAvailablePlanAmount}
        />
      </ModalPop>
      <ModalPop isOpen={openUpgradePlan}>
        <UpgradePlan
          handleClose={() => setOpenUpgradePlan(false)}
          setOpenRedeemSuccess={setOpenRedeemSuccess}
          data={subscriptions?.[1]}
          refresh={() => dispatch(getCurrentPlan())}
          setOpenPayModal={setOpenPayModal}
        />
      </ModalPop> */}
    </div>
  );
}

import {
  AgentProfilePicture,
  ChooseFileBtn,
  ChooseIcon,
  ChangeIcon,
} from "../Assets/svg";
import TestAgent from "./TestAgent/TestAgent";
import ModalPop from "../component/modalPop";
import PersonalityModal from "./SettingsLayout/PersoanlityModal";
import { useState, useEffect, Fragment } from "react";
import WordinessLevelModal from "./SettingsLayout/WordinessLevelModal";
import { xt } from "../context/UserContext";
import { useContext } from "react";
import SaveChanges from "./SettingsLayout/SaveChanges";
import { Countries } from "../phone/countrycode";
import TokenService from "../services/token";
import axios from "axios";
import { ErrorMessage, SuccessMessage } from "../Toast-Messages/Toastmessages";
import { CgSpinner } from "react-icons/cg";
import DefaultImage from "../Assets/DefaultImage.png";
// import DefaultImage from "../Assets/img/ProfileImageholder.png";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@headlessui/react";

import AgentB from "../Assets/svg/agent5.svg";
import { BottomGuide } from "../component/modals/agentGuide/Guide";
import DeleteAppModal from "./SettingsLayout/DeleteAppModa";
import DeactivateAppModal from "./SettingsLayout/DeactivateAppModal";
import { useLocation } from "react-router";
import SwitchAgentRole from "../component/modals/switch/SwitchAgentRole";
import {
  getApplicationById,
  getAnEmployee,
  setAgent,
  getApplicationDraft,
} from "../features/application/getApplicationByIdSlice";
import ConfirmSwitch from "../component/modals/switch/ConfirmSwitch";
import UnsavedSettings from "@/../Settings/UnsavedSettings";
import { updateAgentModalVisibility } from "@/../features/modals/agentModalsSlice";
import {
  getCurrentEmployee,
  updateAgentModals,
} from "@/../features/modals/agentModalsSlice";

export default function Settings({
  activeButton,
  setActiveButton,
  setShowUnsavedSettingsModal,
  showUnsavedSettingsModal,
  handleCloseUnsavedSettingsModal,
  reroute,
  setReroute,
  isFormModified,
  setIsFormModified,
  navigate,
}) {
  const dispatch = useDispatch();
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const newAgent = getAgentApplication?.agent || null;
  const agentType = getAgentApplication?.data?.data[0]?.type || " ";
  const fullAgent = getAgentApplication?.agent || null;
  const newagentType = fullAgent?.type || null;
  const reset = useSelector((state) => state.agentReset);

  // const { userType } = useContext(UserContext);
  const [showPersonalityModal, setShowPersonalityModal] = useState(false);
  const [showWordinessModal, setShowWordinessModal] = useState(false);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  // const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImage2, setSelectedImage2] = useState("");
  const [openBottomGuide, setOpenBottomGuide] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [openAgentCategory, setOpenAgentCategory] = useState(false);
  const [change, setChange] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [selectedCountryCode, setSelectedCountryCode] = useState(
    Countries.length > 0 ? Countries[0].code : ""
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [isFormModified, setIsFormModified] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [verifyLoading, setVerfyLoading] = useState(false);
  const [selectedPersonality, setSelectedPersonality] = useState("");
  const [selectedVerbosity, setSelectedVerbosity] = useState("");
  const [phoneNumberData, setPhoneNumbrData] = useState([]);

  const [deleteAppModal, setDeleteAppModal] = useState(false);
  const [deactivateAppModal, setDeactivateAppModal] = useState(false);
  const [data, setData] = useState({
    name: "",
    description: "",
    icon_url: null,
    verbose: "",
  });
  const token = TokenService.getToken();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const getdata = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = getdata && getdata.length >= 1 ? getdata[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = fullAgent.id;
  // console.log(fullAgent)
  // console.log(applicationId)
  const URL = process.env.REACT_APP_BASEURL;
  const fetchData = async () => {
    if (!applicationId) {
      console.error(
        "No applicationId provided. fetchData function will not run."
      );
      return;
    }
    // setLoading(true);
    try {
      const response = await axios.get(
        `${URL}/dashboard/applications/${applicationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data.data.draft
        ? response.data.data.draft
        : response.data.data;
      setData({
        name: result.name,
        description: result.description,
        icon_url: result.icon_url || null,
        personality_type: result.personality_type,
        verbose: result.verbose,
      });
      // setLoading(false);
      setIsFormModified(false);
      // localStorage.setItem("isFormModified", false)
      // localStorage.removeItem("path", "/agent")
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getApplicationById());
  }, [reset?.loading]);

  useEffect(() => {
    if (change === true) {
      setOpenConfirmModal(true);
    }
  }, [change]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedImage(file);

      setData((prevData) => ({
        ...prevData,
        icon_url: file, // Set the file directly
      }));

      setIsFormModified(true);
      // localStorage.setItem("isFormModified", true)
      // localStorage.setItem("path", "/agent")
      // Create a FileReader to read the file and update the preview
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageDataUrl = e.target.result;
        setSelectedImage2(imageDataUrl); // Set the preview state
      };

      reader.onerror = (error) => {
        console.error("Error reading the file:", error);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("personality_type", data.personality_type);
      formData.append("verbose", data.verbose);
      formData.append("type", agentDetails.type);

      if (selectedImage) {
        formData.append("file", selectedImage);
      }

      const response = await axios.put(
        `${URL}/dashboard/applications/${applicationId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
          },
        }
      );
      const object = {
        ...(response?.data?.data?.draft
          ? response?.data?.data?.draft
          : response?.data?.data),
      };
      object.id = response?.data?.data?.id;
      dispatch(setAgent(object));
      dispatch(getApplicationById());
      if (applicationId) {
        dispatch(getApplicationDraft(applicationId));
      }
      // const result = response?.data?.data?.draft;
      // setData(result);
      setIsFormModified(false);
      // localStorage.setItem("showUnsavedSettingsModal", false)
      setLoading(false);
      setSuccess("Your details have been uploaded successfully");
      setTimeout(() => {
        setSuccess(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.error("Error updating data:", error);
      setError("Error updating data. Please try again.");
      setTimeout(() => {
        setError();
      }, 1000);
    }
  };

  const handleCountryChange = (event) => {
    const countryCode = event.target.value
      .split(" ")[1]
      .replace("(", "")
      .replace(")", "");
    setSelectedCountryCode(countryCode);
  };

  const handleSavePersonality = (personality) => {
    setSelectedPersonality(personality);
    setData((prevData) => ({
      ...prevData,
      personality_type: personality,
    }));

    setIsFormModified(true);
    // localStorage.setItem("isFormModified", true);
    // localStorage.setItem("path", "/agent");
  };

  const handleVerbosity = (Verbosity) => {
    setSelectedVerbosity(Verbosity);
    setData((prevData) => ({
      ...prevData,
      verbose: Verbosity,
    }));

    setIsFormModified(true);
    // localStorage.setItem("isFormModified", true);
    // localStorage.setItem("path", "/agent")
  };
  const fetchNumbers = () => {
    if (!applicationId) {
      console.error(
        "No applicationId provided. fetchData function will not run."
      );
      return;
    }
    try {
      axios
        .get(`${URL}/dashboard/applications/${applicationId}/session`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // Access the data inside the PromiseResult
          const responseData = response && response?.data?.data;
          setPhoneNumbrData(responseData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error making request:", error);
    }
  };

  useEffect(() => {
    fetchData();
    // fullAgent
    if (fullAgent.id) {
      setData({
        name: fullAgent?.draft?.name ? fullAgent?.draft?.name : fullAgent?.name,
        description: fullAgent?.draft?.description
          ? fullAgent?.draft?.description
          : fullAgent?.description,
        icon_url: fullAgent?.draft?.icon_url
          ? fullAgent?.draft?.icon_url
          : fullAgent.icon_url || null,
        personality_type: fullAgent?.draft?.personality_type
          ? fullAgent?.draft?.personality_type
          : fullAgent?.personality_type,
        verbose: fullAgent?.draft?.verbose
          ? fullAgent?.draft?.verbose
          : fullAgent?.draft?.verbose,
      });
    }
  }, [fullAgent]);

  useEffect(() => {
    fetchNumbers();
  }, []);
  const fullPhoneNumber = `${selectedCountryCode}${phoneNumber}`;
  const handleVerify = async () => {
    setVerfyLoading(true);
    try {
      const result = await axios.post(
        `${URL}/dashboard/applications/${applicationId}/session/connect`,
        {
          phone_number: fullPhoneNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setVerfyLoading(false);
      setPhoneNumber("");
      fetchNumbers();
    } catch (error) {
      setVerfyLoading(false);
      console.error("Error updating data:", error);
    }
  };

  const location = useLocation();

  // const setBottomAgentSettingsGuide = () =>
  //   location.pathname === "/agent"
  //     ? localStorage.setItem("agentSettings", true)
  //     : null;
  // const getAgentSettingsGuide = localStorage.getItem("agentSettings");

  // useEffect(() => {
  //   if (getAgentSettingsGuide) {
  //     setOpenBottomGuide(false);
  //   } else {
  //     setOpenBottomGuide(true);
  //   }
  // }, []);

  const { id } = useSelector((state) => state.getApplicationByUserId.agent);
  const config = useSelector((state) => state.agentModals.employeeModals);

  const getAgentSettingsGuide = config?.agentSettings;

  useEffect(() => {
    if (getAgentSettingsGuide) {
      setOpenBottomGuide(true);
    } else {
      setOpenBottomGuide(false);
    }
  }, [getAgentSettingsGuide]);

  const handleAgentModal = (name) => {
    const currentModalState = config[name];
    if (currentModalState) {
      const modalsVisibility = { ...config, [name]: false };
      dispatch(updateAgentModals({ modalsVisibility, id: id }))
        .then((res) => {
          dispatch(getCurrentEmployee({ id: id }));
        })
        .catch((error) => {
          console.error("Failed to update modal state:", error);
        });
    }
  };

  const handleSwitchChange = (isChecked) => {
    // setEnabled(isChecked);
    setDeactivateAppModal(true);
  };
  // useEffect(() => {
  //   if (activeButton !== "Settings") {
  //     setShowUnsavedChangesModal(true);
  //   }
  // }, [activeButton]);

  // const [setConfirmedNavigation] = useNavigationGuard(isFormModified, setShowUnsavedSettingsModal);

  const saveChanges = () => {
    // localStorage.setItem("isFormModified", false)
    // localStorage.removeItem("path", "/agent")
    // localStorage.setItem("showUnsavedSettingsModal", false)
    handleSave();
    setIsFormModified(false);
    setTimeout(() => {
      setShowUnsavedSettingsModal(false);
    }, 1500);
    // setActiveButton(reroute);
    setReroute("");
  };

  const discardChanges = () => {
    setIsFormModified(false);
    // localStorage.setItem("isFormModified", false)
    // localStorage.removeItem("path", "/agent")
    // localStorage.setItem("showUnsavedSettingsModal", false)
    setShowUnsavedSettingsModal(false);
    // setConfirmedNavigation(true)
    setActiveButton(reroute);
    setReroute("");
  };

  const closeModal = () => {
    setShowUnsavedSettingsModal(false);
    // localStorage.setItem("showUnsavedSettingsModal", false)
  };

  return (
    <section className="mt-10 font-figtree ">
      <div className="flex gap-20 w-full mb-10">
        <aside className=" w-2/6">
          <h3 className=" text-sm font-medium">Description</h3>
          <p className="text-[#7F7F81] font-normal text-xs">
            Set a description and look for your assistant while it interacts
            with your customers
          </p>
        </aside>
        <aside className=" w-1/2">
          <h3 className="">Image</h3>
          <div className=" mt-3 flex gap-3 items-end">
            <div className="relative">
              <AgentProfilePicture />
              {selectedImage2 ? (
                <img
                  src={selectedImage2}
                  alt="Uploaded"
                  className="absolute top-0 h-[40px] w-[40px] object-cover"
                />
              ) : (
                <img
                  src={data.icon_url || DefaultImage}
                  alt="Default"
                  className="absolute top-0 h-[40px] rounded-[6.4px] shadow-md w-[40px] object-cover"
                />
              )}
            </div>
            <div className=" relative">
              <label htmlFor="choosefile" className=" cursor-pointer">
                <ChooseFileBtn />
              </label>
              <input
                type="file"
                name=""
                onChange={handleImageChange}
                id="choosefile"
                className=" hidden"
                accept="image/*"
              />
            </div>
          </div>
          <div className=" mt-6">
            <p className="text-[#101828] text-xs font-normal">Name</p>
            <input
              type="text"
              className=" w-[420px] mt-3 outline-none h-9"
              value={data?.name}
              onChange={(e) => {
                setData((prevData) => ({
                  ...prevData,
                  name: e.target.value,
                }));
                setIsFormModified(true);
              }}
            />
            {/* <div className="flex flex-col my-[24px]  gap-[12px]">
              <p className="text-xs text-[#121212] font-normal">
                Category
              </p>
              <div className=" relative flex items-center w-[420px] border rounded-xl">
                <input
                  type="text"
                  value={newagentType ? newagentType : agentType}
                  readOnly
                  className=" border-none outline-none " // h-10 pr-[75px]
                />
                <div
                  onClick={() => setOpenAgentCategory(true)}
                  className="  mr-2 cursor-pointer" //absolute top-[4px] right-2
                >
                  <ChangeIcon />
                </div>
              </div>
            </div> */}
            <div className="mt-6 w-[420px]">
              <p className="mb-2 text-xs font-normal">Assistant ID</p>
              <div className=" relative p-1 flex items-center justify-between border border-[#D0D5DD] rounded-lg">
                <span className="text-xs ">
                {newAgent.id}
                </span>
                <div
                    onClick={() => {
                      navigator.clipboard
                        .writeText(newAgent.id)
                        .then(() => {
                          setSuccess("copied");
                          setTimeout(() => {
                            setSuccess(false);
                          }, 3000);
                        })
                        .catch((err) => {
                          setError(false);
                        });
                    }}
                  className=" text-[#0359D8] p-2.5 cursor-pointer text-[10px] rounded-lg border border-[#D0D5DD]"
                >
                  Copy ID
                </div>
              </div>
            </div>
            <p className="mt-6 text-[#101828] text-xs font-normal mb-3">
              Description
            </p>
            <textarea
              name=""
              id=""
              cols="50"
              rows="4"
              className=" outline-none rounded-lg border border-[#D0D5DD] p-1"
              value={data?.description}
              onChange={(e) => {
                setData((prevData) => ({
                  ...prevData,
                  description: e.target.value,
                }));
                setIsFormModified(true);
              }}
            ></textarea>
          </div>
        </aside>
      </div>
      <hr className=" w-[830px] my-[50px]" />
      {("Sales" === "Sales" || "Sales" === "Customer Service") && (
        <section>
          <section className="flex gap-20 w-full mb-10">
            <div className=" w-2/6">
              <p className=" text-xs font-medium">Personality</p>
              <p className="text-[#7F7F81] text-xs font-normal">
                Select a Personality for your assistant, you can change this
                whenever you want
              </p>
            </div>
            <div>
              <p className="mb-2 text-xs font-normal">Personality</p>
              <div className=" relative">
                <input
                  type="text"
                  value={
                    data?.personality_type?.split("-")?.join(" ") ||
                    selectedPersonality?.split("-")?.join(" ") ||
                    ""
                  }
                  readOnly
                  className="capitalize w-[420px] outline-none h-10 pr-[75px]"
                />
                <div
                  onClick={() => setShowPersonalityModal(true)}
                  className=" absolute top-[4px] right-2 cursor-pointer"
                >
                  <ChooseIcon />
                </div>
              </div>
            </div>
          </section>
          <hr className=" w-[830px] my-[50px]" />
          <section className="flex gap-20 w-full mb-10">
            <div className=" w-2/6">
              <p className=" text-xs font-medium">Verbosity</p>
              <p className="text-[#7F7F81] text-xs font-normal">
                Adjust Assistant’s response detail or word’s used
              </p>
            </div>
            <div>
              <p className="mb-2 text-xs font-normal">Choose Verbosity level</p>
              <div className=" relative">
                <input
                  type="text"
                  value={data?.verbose || selectedVerbosity}
                  readOnly
                  className="capitalize w-[420px] outline-none h-10 pr-[75px]"
                />
                <div
                  onClick={() => setShowWordinessModal(true)}
                  className=" absolute top-[4px] right-2 cursor-pointer"
                >
                  <ChooseIcon />
                </div>
              </div>
            </div>
          </section>
          <hr className=" w-[830px] my-[50px]" />
          {/* <section className="flex gap-20 w-full mb-10">
            <div className=" w-2/6">
              <p className=" text-xs font-medium">Test Numbers</p>
              <p className="text-[#7F7F81] text-xs font-normal">
                Test your agent on our WhatsApp Simulator. Add a phone number to
                begin.
              </p>
            </div>
            <div>
              <p className=" text-[#121212] text-xs font-medium mb-3">
                Add new number
              </p>
              <div className=" flex gap-3">
                <div className=" flex border py-[3px] px-[10px] rounded-[10px] h-10 w-[292px]">
                  <select
                    onChange={handleCountryChange}
                    value={selectedCountryCode}
                    className="border-r border-solid border-[##E5E5E5] outline-none text-[10px] font-normal text-[#121212]"
                  >
                    {Countries.map((country) => (
                      <option
                        className="flex flex-row text-[10px] font-normal text-[#121212]"
                        key={country.id}
                      >
                        {country.id} ({country.code})
                      </option>
                    ))}
                  </select>
                  <input
                    value={phoneNumber}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                    className="w-full border-none outline-none text-xs h-8"
                    type="text"
                  />
                </div>
                <button
                  onClick={handleVerify}
                  disabled={phoneNumber.trim().length < 10}
                  className={` text-white text-xs font-semibold py-[10px] bg-black rounded-lg w-32 ${
                    phoneNumber.trim().length < 10
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                    }`}
                >
                  {verifyLoading ? (
                    <span className="flex justify-center w-full">
                      <CgSpinner className=" animate-spin text-lg " />
                    </span>
                  ) : (
                    <span>Verify Number</span>
                  )}
                </button>
              </div>
              <div className=" mt-8 flex flex-col gap-3">
                {phoneNumberData.map((data, index) => (
                  <aside className=" flex items-center" key={index}>
                    <div className="flex items-center w-[80%]">
                      <GoDotFill className="text-[#057601] text-[20px]" />
                      <p className=" text-[#121212] font-normal text-sm">
                        +{data.phone_number}
                      </p>
                    </div>
                    <div className="flex items-center gap-1 cursor-pointer">
                      <LiaUnlinkSolid className=" text-[15px] text-black" />
                      <p className={`font-normal text-xs ${data.status === "connected" ? "text-[green]" : "text-[#AF202D]"}`}>
                  
                        {data.status}
                      </p>
                    </div>
                  </aside>
                ))}
              </div>
            </div>
          </section> */}

          <section className="flex  w-full mb-10">
            <div className="flex w-full gap-20  items-center">
              <div className="w-2/6 flex flex-col">
                <p className="text-sm text-BLACK-_100 font-medium">
                  Deactivate Assistant
                </p>
                <p className="text-sm text-[#7F7F81]">
                  Set your assistant as inactive. This prevents your customers
                  from interacting with the Assistant.
                </p>
              </div>
              <div>
                <Switch
                  checked={fullAgent.deactivated}
                  onChange={handleSwitchChange}
                  as={Fragment}
                >
                  {({ checked }) => (
                    /* Use the `checked` state to conditionally style the button.  mb-[180px]*/
                    <button
                      className={`${
                        checked
                          ? "bg-blue-600"
                          : enabled
                          ? "bg-blue-600"
                          : "bg-gray-200"
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${
                          checked
                            ? "translate-x-6"
                            : enabled
                            ? "translate-x-6"
                            : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </button>
                  )}
                </Switch>
              </div>
            </div>
          </section>
          <hr className=" w-[830px] my-[50px]" />

          {/* <section className="flex items-center w-full gap-20 mb-10">
            <div className="w-2/6 flex flex-col">
              <p className="text-sm text-[#AF202D] font-medium">
                Delete Assistant
              </p>
              <p className="text-sm text-[#7F7F81]">
                Deleting this Assistant removes it from your business completely.
              </p>
            </div>
            <div>
              <button
                onClick={() => setDeleteAppModal(true)}
                className="bg-[#AF202D] text-WHITE-_100 rounded-lg px-6 py-2 text-sm font-semibold"
              >
                Delete Assistant
              </button>
            </div>
          </section> */}
        </section>
      )}
      {/* <TestAgent activeButton={activeButton} setActiveButton={setActiveButton}/> */}
      <button
        disabled={!isFormModified || loading}
        onClick={() => handleSave()}
        className={`bg-[#121212] text-white text-sm font-semibold py-[11px] w-[140px] rounded-lg absolute top-0 right-0 ${
          isFormModified ? " cursor-pointer" : " cursor-not-allowed"
        }`}
      >
        {loading ? (
          <span className="flex justify-center w-full">
            <CgSpinner className=" animate-spin text-lg " />
          </span>
        ) : (
          <span>Save Changes</span>
        )}
      </button>
      <ModalPop isOpen={showPersonalityModal}>
        <PersonalityModal
          handleClose={() => setShowPersonalityModal(false)}
          handleSavePersonality={handleSavePersonality}
          selectedPersonality={selectedPersonality}
        />
      </ModalPop>
      <ModalPop isOpen={showWordinessModal}>
        <WordinessLevelModal
          handleClose={() => setShowWordinessModal(false)}
          handleVerbosity={handleVerbosity}
          selectedVerbosity={selectedVerbosity}
        />
      </ModalPop>
      <ModalPop isOpen={deleteAppModal}>
        <DeleteAppModal
          handleClose={async () => {
            const res = await dispatch(getApplicationById());
            if (res.payload.data.length) {
              await dispatch(getAnEmployee({ id: res.payload.data[0].id }));
              localStorage.setItem(
                "wano_agent",
                JSON.stringify(res.payload.data[0])
              ); //added this
            }
            setDeleteAppModal(false);
          }}
          justCloseModal={() => {
            setDeleteAppModal(false);
          }}
          fetchData={fetchData}
          appID={applicationId}
        />
      </ModalPop>
      <ModalPop isOpen={deactivateAppModal}>
        <DeactivateAppModal
          handleClose={() => {
            setDeactivateAppModal(false);
            dispatch(getApplicationById());

            dispatch(getAnEmployee({ id: fullAgent.id }));
          }}
        />
      </ModalPop>
      <ModalPop isOpen={showSaveChangesModal}>
        <SaveChanges handleClose={() => setShowSaveChangesModal(false)} />
      </ModalPop>
      <ModalPop isOpen={showUnsavedSettingsModal}>
        <UnsavedSettings
          handleClose={discardChanges}
          handleSave={() => {
            saveChanges();
          }}
          close={() => closeModal()}
          loading={loading}
        />
      </ModalPop>
      {/* <ModalPop isOpen={verifyNumber}>
        <VerifyNumber
          handleClose={() => setVerifyNumber(false)}
          handleVerifyNumber={handleVerifyNumber}
        />
      </ModalPop> */}
      {/* <ModalPop isOpen={openBottomGuide}>
        <BottomGuide
          title="Settings"
          subtitle="Customize your Assistant, Select a personality and how words are used. All in your Assistant’s setting"
          link="Customize Assistant"
          handleClose={() => {
            handleAgentModal("agentSettings");
            setOpenBottomGuide(false);
            // setBottomAgentSettingsGuide();
          }}
          image={AgentB}
          className="h-[299px]"
        />
      </ModalPop> */}
      <ModalPop isOpen={openAgentCategory}>
        <SwitchAgentRole
          handleClose={() => setOpenAgentCategory(false)}
          setChange={setChange}
        />
      </ModalPop>
      <ModalPop isOpen={openConfirmModal}>
        <ConfirmSwitch
          handleClose={async () => {
            setOpenConfirmModal(false);
            // 'get correct role'
            const res = await dispatch(getApplicationById());
            if (res.payload.data.length) {
              await dispatch(getAnEmployee({ id: res.payload.data[0].id }));
              localStorage.setItem(
                "wano_agent",
                JSON.stringify(res.payload.data[0])
              ); //added this
            }
          }}
          setChange={setChange}
        />
      </ModalPop>
      {success && <SuccessMessage success={success} />}
      {error && <ErrorMessage error={error} />}
    </section>
  );
}

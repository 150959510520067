import React from 'react'
import { Dot } from '../Assets/svg'
import { useNavigate } from 'react-router';

export default function Notifications({ data, markAsRead }) {
    const navigate = useNavigate()
    const date = new Date(data?.created_at);

    const formattedDate = date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        month: 'short',
        day: 'numeric',
    });
    
    return (
        <div className='mx-6 mb-5' onClick={() => { markAsRead() }}> {/* markAsRead(); navigate("/ask-agent?inbox=true")*/} {/* onClick of the Notification markasread and navigate to inbox */}
            <div className={`${data?.is_read ? 'bg-NOTIFICATIONREAD' : 'bg-NOTIFICATION '} cursor-pointer mb-3 px-6 py-3 rounded-lg flex justify-between`}>
                <div className='flex flex-col gap-3'>
                    <p className='text-xs text-[#101828] font-medium'>{data?.title}</p>
                    <p className='text-xs text-[#828282]'>{data?.message}</p>
                </div>

                {!data?.is_read && <Dot />}
            </div>
            {data?.created_at && <p className='text-[10px] font-semibold text-[#828282]'>{formattedDate}</p>}
        </div>
    )
}
import React, { useEffect, useState } from "react";
import {
  AiOutlineClose,
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";
import { Form, Formik } from "formik";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import * as Yup from "yup";

import Button from "../../Button";

import Message from "../../../Assets/svg/message.svg";
import MessageB from "../../../Assets/svg/message-b.svg";
import Exchange from "../../../Assets/svg/exchange.svg";

import { generateTopUp } from "../../../features/wallet/walletTopUpSlice";
import { CgSpinner } from "react-icons/cg";
import ModalPop from "../../modalPop";
import Pay from "./Pay";
import { getCurrentPlan } from "@/../features/profile/getCurrentPlanSlice";

const WalletTopUp = ({ handleClose, setShow, setOpenPay }) => {
  const [openMore, setOpenMore] = useState(false);
  const [credit, setCredit] = useState("");
  const [exchangeAmount, setExchangeAmount] = useState(0);

  const userDetails = useSelector((state) => state.userLogin);
  const { data } = userDetails?.user;
  let agentSettings = "settings";

  const { loading } = useSelector((state) => state.walletGenerate);
  const plan = useSelector((state) => state.getCurrentPlan.data);
  const { subscriptions, currentPlan: current } = plan;
  // const currentPlanTaskAmount = current?.subscription_details?.features?.[3]?.prefix;
  const currentPlanTaskAmount = current?.subscription_details?.features?.find(data => data?.description == "per resolution")?.prefix;
  console.log(currentPlanTaskAmount, "currTask")

  const formValidationSchema = Yup.object().shape({
    amount: Yup.number().min(10).max(1000).required(),
  });

  // const [config, setConfig] = useState({
  //         public_key: 'FLWPUBK_TEST-337d4e3e6c0c10c22b28cac389a35d46-X', //This Key will be changed for production
  //         tx_ref: Date.now(),
  //         amount: 0,
  //         currency: 'USD',
  //         payment_options: " ",
  //         redirect_url: "https://wano.vercel.app/top-up?status=cancelled&tx_ref=PROV_01YVCPGUYWW8LSQ3WENS",
  //         customer: {
  //             email: data?.user?.email,
  //             phone_number: data?.user?.phone_number,
  //             name: data?.user?.first_name + " " + data?.user?.last_name,
  //         },
  //         customizations: {
  //           title: 'Wallet Top Up',
  //           description: 'Top Up',
  //           logo: "https://checkout.flutterwave.com/assets/img/rave-logo.png",
  //         },
  // })

  const navigate = useNavigate();

  const OpenMoreInfo = () => {
    setOpenMore((prev) => !prev);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrentPlan());
  },[])

  // const handleFlutterPayment =  useFlutterwave(config);

  // const submitForm = async (values) => {
  //     const payAmount = sessionStorage.getItem("amount")
  //     const data = {
  //         amount: payAmount * 100,            //values?.amount * 100,
  //         currency: "USD"
  //     }
  //     console.log(data, "mopo")
  //     dispatch(generateTopUp(data))
  //     .then((res) => {
  //       window.location.href = res.payload.link;

  //     })

  // };

  useEffect(() => {
    // Assuming 1 dollar = 100 cents
    const centsPerDollar = 100;

    // Conversion rate
    const creditsPerCent = 1;

    if (exchangeAmount > 0) {
      // Calculate credits
      const numOfCredits = exchangeAmount * centsPerDollar * creditsPerCent;
      // const numOfCredits = exchangeAmount * 5000;
      setCredit(numOfCredits);
    } else {
      const zero = 0;
      setCredit(zero);
    }
  }, [exchangeAmount]);

  function calculatingCredits(values) {
    setExchangeAmount(values);
  }

  // function dollarsToCredits(dollarAmount) {
  //     // Assuming 1 dollar = 100 cents
  //     const centsPerDollar = 100;

  //     // Conversion rate
  //     const creditsPerCent = 1;

  //     // Calculate credits
  //     const credits = dollarAmount * centsPerDollar * creditsPerCent;

  //     return credits;
  //   }

  //   const dollarValue = 0.01;
  //   const credits = dollarsToCredits(dollarValue);

  //   console.log(⁠ ${dollarValue} dollar(s) is equal to ${credits} credits. ⁠);

  const openBulk = () => {
    setShow(true);
    handleClose();
  };

  const openPayment = () => {
    setOpenPay(true);
    handleClose();
  };

  return (
    <>
      <div
        className="bg-WHITE-_100 w-[578px] h-[545px] overflow-y-scroll rounded-lg hide-scrollbar"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        <div className="flex justify-between items-center m-4 ">
          <p className="font-medium text-lg ml-6">Top Up Wallet</p>
          <button
            className="w-[36px] h-[36px] border rounded-lg flex justify-center items-center p-2"
            onClick={handleClose}
          >
            <AiOutlineClose className="text-BLACK-_300" />
          </button>
        </div>
        <hr />
        <div className="flex items-center justify-center mt-[33px] ">
          <div
            className=" h-[413x] w-[472px] mx-auto rounded-lg"
            style={{ boxShadow: "0px 4px 8px 1px rgba(215, 215, 215, 0.25)" }}
          >
            <div
              className="px-[24px] py-[24px]"
              style={{ background: "rgba(205, 222, 248, 0.20)" }}
            >
              <div>
                <div className="flex flex-col gap-3.5">
                  <p className="text-[14px] leading-[16.8px] font-medium text-BLACK-_500">
                    Pay-As-You-Go
                  </p>
                  <div className="flex gap-2.5">
                    <p className="font-medium text-[35px] leading-[42px] text-BLACK-_500">
                      {currentPlanTaskAmount}{" "}
                    </p>
                    <p className="text-sm text-BLACK-_300 mt-2.5">per resolution</p>
                  </div>
                </div>
                <span className="text-[#1774FD] text-[12px] mt-[12px]">
                  Subscription charges will be taken from this wallet. Find out
                  more <span className="underline cursor-pointer" onClick={() => navigate('/settings?page=billing&category=plan')}>Here</span>
                </span>
              </div>

              {openMore && (
                <div
                  style={
                    {
                      // transform: openMore ? "translateY(0)" : "translateY(0)",
                      // transition: "transform 0.9s ease-in-out",
                    }
                  }
                  className="flex flex-col mt-[30px] gap-3"
                >
                  {" "}
                  {/* animate__animated animate__slideInUp animate__slow */}
                  <div className="w-[364px]">
                    <p className="text-sm font-medium text-BLACK-_300">
                      Purchase Credits in advance and use them to pay for
                      resolutions completed by your Digital Assistant. Each
                      credit is worth $0.01 and each Resolution completed by
                      your assistant is worth 10 Credits which equates to $0.1
                      per Resolution. Here is a breakdown of the cost:
                    </p>
                  </div>
                  <div className="w-[374px] flex flex-col gap-6">
                    {/* <div className='flex gap-1 items-center'>
                                    <img src={MessageB} alt='message' className='w-[10px] h-[10px]' />
                                    <p className='text-BLACK-_300 font-medium text-sm'>$0.2 per 1000 credits</p>
                                </div> */}
                    <div className="flex gap-1 items-center">
                      <img
                        src={MessageB}
                        alt="message"
                        className="w-[10px] h-[10px]"
                      />
                      <p className="text-BLACK-_300 font-medium text-sm leading-3">
                        $0.01 per Credit
                      </p>
                    </div>
                    <div className="flex gap-1 items-center">
                      <img
                        src={MessageB}
                        alt="message"
                        className="w-[10px] h-[10px]"
                      />
                      <p className="text-BLACK-_300 font-medium text-sm leading-3">
                        10 Credits per Resolution
                      </p>
                    </div>
                    <div className="flex gap-1 items-center">
                      <img
                        src={MessageB}
                        alt="message"
                        className="w-[10px] h-[10px]"
                      />
                      <p className="text-BLACK-_300 font-medium text-sm leading-3">
                        $0.1 per Resolution
                      </p>
                    </div>
                    <div className="flex gap-1 items-start">
                      <img
                        src={MessageB}
                        alt="message"
                        className="w-[10px] h-[10px]"
                      />
                      <p className="text-BLACK-_300 font-medium text-sm leading-3">
                        Minimum funding amount is 10$ or 1,000 <br />{" "}
                        <span className="leading-normal">credits</span>
                      </p>
                    </div>
                  </div>
                  <div className="w-[364px] mt-8">
                    <p className="text-xs font-normal text-BLACK-_300">
                      You can find out more about our credits and our pricing
                      per resolution in{" "}
                      {/* Our pay-per-word pricing model gives you full control. You
                      can choose the amount of words your AI generates in your */}
                      {/* <span
                        className="underline text-BLUE-_100 cursor-pointer"
                        onClick={() =>
                          navigate("/agent", { state: agentSettings })
                        }
                      >
                        {" "}
                        Employee Settings.
                      </span> */}
                      {/* You can find out more about our pricing in{" "} */}
                      <span
                        className="underline text-BLUE-_100 cursor-pointer"
                        onClick={() => navigate("/support")}
                      >
                        support.
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className=" flex flex-col  mx-[28px] my-[30px]">
              <Formik
                initialValues={{
                  amount: "",
                  // credit: "",
                }}
                validationSchema={formValidationSchema}
                onSubmit={(values) => {
                  window.scrollTo(0, 0);
                  // submitForm(values)
                  sessionStorage.setItem("amount", values?.amount);
                  openPayment();
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  dirty,
                  isValid,
                  setFieldValue,
                  errors,
                  touched,
                  // setFieldTouched,
                  values,
                }) => (
                  <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div className="flex flex-col">
                      <p className="ml-4 text-xs font-medium text-BLACK-_200">
                        Enter Amount
                      </p>
                      <div className="flex items-center">
                        {/* {values?.amount && (
                          <span className="text-[28px] relative -right-2">
                            $
                          </span>
                        )}
                        <input
                          name="amount"
                          type="number"
                          className="border-l-0 text-[28px] py-0 border-r-0 shadow-none outline-none w-[362px] border-t-0 border-b-0"
                          placeholder="$ 0"
                          value={values?.amount}
                          onChange={handleChange}
                          onInput={(e) => calculatingCredits(e.target.value)}
                        /> */}
                        <NumericFormat
                          name="amount"
                          value={values?.amount}
                          thousandSeparator={true}
                          prefix={"$"}
                          placeholder="$ 0"
                          className="border-l-0 text-[28px] py-0 border-r-0 shadow-none outline-none w-[362px] border-t-0 border-b-0"
                          decimalScale={2}
                          fixedDecimalScale={true}
                          onValueChange={(values) => {
                            const {  value } = values;
                            calculatingCredits(value);
                            handleChange({ target:  { name: 'amount', value } });
                          }}
                        />
                      </div>

                      <hr />
                      <p
                        className={`${
                          exchangeAmount && exchangeAmount < 10
                            ? "text-RED-_100"
                            : "text-BLACK-_200"
                        } flex justify-end text-xs font-medium mt-[5px]`}
                      >
                        Minimum funding amount is $10
                      </p>
                    </div>

                    {/* <div className="flex justify-center">
                      <img
                        src={Exchange}
                        alt="exchange"
                        className="w-[26px] h-[26px]"
                      />
                    </div> */}

                    {/* <div className="flex flex-col"> */}
                    {/* <p className="ml-4 text-xs font-medium text-BLACK-_200">
                        Number of Credits
                      </p>
                      <div className="border-l-0 text-[28px] ml-5 h-[40px] py-0 border-r-0 shadow-none outline-none w-[362px] border-t-0 border-b-0 ">
                        {credit}
                      </div> */}
                    {/* <input
                                            name='credit' 
                                            type='number'
                                            disabled 
                                            className='border-l-0 text-[28px] py-0 border-r-0 shadow-none outline-none w-[362px] border-t-0 border-b-0 ' 
                                            placeholder='0'
                                            value={values?.credit}
                                            onChange={() => setFieldValue("credit", credit)}
                                        /> */}
                    {/* <hr />
                    </div> */}

                    <div className="flex justify-center mt-[59px]">
                      <button
                        className="bg-BLACK-_500 w-[362px] rounded-lg h-[45px] p-2 "
                        type="submit"
                      >
                        {/* {loading ?
                                            <CgSpinner className=" animate-spin text-lg text-WHITE-_100 mx-auto " />
                                            : */}
                        <p className="text-center font-semibold text-sm text-WHITE-_100">
                          Top Up Now
                        </p>
                        {/* } */}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          {/* <p
            className={`text-sm font-bold text-BLACK-_100 my-auto ${
              openMore && "h-[350px]"
            }`}
          >
            OR
          </p> */}
          {/* 
          <div
            className="w-[428px] h-[494px] flex flex-col justify-between py-6 rounded-lg"
            style={{
              boxShadow: "0px 4px 8px 1px rgba(215, 215, 215, 0.25)",
              background:
                "linear-gradient(0deg, rgba(70, 31, 196, 0.50), #111017 35%) no-repeat",
            }}
          > */}
          {/* <div className="flex flex-col w-[362px] pl-[31px] gap-3">
              <div className="flex gap-3.5">
                <p className="text-WHITE-_100 text-[16px] font-bold">
                  Bulk Commitment
                </p>
                <div
                  className="w-[125px] h-[24px] rounded-[18px] p-1.5 flex flex-col items-center justify-center"
                  style={{ background: "rgba(39, 151, 37, 0.50)" }}
                >
                  <p className="text-GREEN-_300 text-xs text-center">
                    Up to 50% Discount
                  </p>
                </div>
              </div>
              <div className="flex flex-col mt-1.5 ">
                <p className="text-WHITE-_100 opacity-70 text-sm font-medium">
                  Starting at
                </p>
                <p className="font-medium text-[40px] text-WHITE-_100">
                  $10,000
                </p>
              </div>
              <div>
                <p className="text-WHITE-_100 text-sm">
                  Talk with our sales team for access to deeper discount when
                  you commit.
                </p>
              </div>
            </div> */}

          {/* <div className="flex flex-col w-[374px] gap-4 pl-[31px]">
              <div className="flex gap-1 items-center">
                <img
                  src={Message}
                  alt="message"
                  className="w-[10px] h-[10px]"
                />
                <p className="text-WHITE-_100 font-medium text-sm">
                  Priority Support
                </p>
              </div>
              <div className="flex gap-1 items-center">
                <img
                  src={Message}
                  alt="message"
                  className="w-[10px] h-[10px]"
                />
                <p className="text-WHITE-_100 font-medium text-sm">
                  Personal Account Manager
                </p>
              </div>
              <div className="flex gap-1 items-center">
                <img
                  src={Message}
                  alt="message"
                  className="w-[10px] h-[10px]"
                />
                <p className="text-WHITE-_100 font-medium text-sm">
                  Dedicated Data Services
                </p>
              </div>
              <div className="flex gap-1 items-start">
                <img
                  src={Message}
                  alt="message"
                  className="w-[10px] h-[10px] mt-1"
                />
                <p className="text-WHITE-_100 font-medium text-sm">
                  Access to advanced AI Worker: <br />
                  Personal Assistance X, Human Resources X, Treasury X,
                  Researcher X, Analyst X, Compliance X
                </p>
              </div>
            </div> */}

          {/* <div className="flex justify-center mt-[43px]">
              <Button
                text="Contact Sales"
                onClick={() => openBulk()}
                className="bg-WHITE-_100 w-[362px] rounded-lg h-[45px] font-semibold text-sm text-BLUE-_100 p-2 text-center"
              />
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default WalletTopUp;

import { api } from "@/../services/api";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const DowngradePlan = ({
  handleClose,
  state,
  token,
  heading,
  warning,
  label1,
  label2,
  data,
  refresh,
  setOpenPayModal,
  index,
}) => {
  const {
    id,
    name,
    description,
    features,
    monthly_amount,
    yearly_amount,
    tag_slug,
    disabled,
    created_at,
    updated_at,
  } = data;

    const giftBalance = JSON.parse(sessionStorage.getItem("giftBal"));

  const walletBalance = useSelector((state) => state.walletBalance);
  const { tokens } = walletBalance.data;

  const sufficient = Number(tokens) + Number(giftBalance);
// console.log(index, "index1")
  const handleDowngrade = async () => {
    if ((sufficient < Number(monthly_amount)) && index == 1 || index == 2){
      handleClose();
      setOpenPayModal(true);
      return;
    }else{
    await api
      .post(`/business-subscription/downgrade/${id}`)
      .then((res) => { refresh(); handleClose() })
      .catch((error) => console.log(`We could not process because ${error}`));
  }};

    useEffect(() => {
    sessionStorage.setItem("amount", monthly_amount);
  }, [monthly_amount])

  return (
    <div className="bg-[#fff] w-[496px] h-[280px] py-[30px] px-[50px] flex flex-col rounded-lg mt-[100px] gap-[40px]">
      <p className="text-center text-2xl font-medium">{heading}</p>
      <p className="text-center text-[#7F7F81] text-sm">{warning}</p>
      <div className="w-[420px] mx-auto flex gap-[12px] mt-[20px] mb-[80px]">
        <button
          className="w-[186px] flex justify-center items-center rounded-lg font-medium text-[#868686] text-sm border border-neutral-200"
          type="submit"
          onClick={() => handleClose()}
        >
          {label1}
        </button>
        <button
          onClick={handleDowngrade}
          className="w-[186px] h-[40px] py-3 px-5 rounded-lg flex justify-center items-center font-medium bg-[#AF202D] text-WHITE-_100 text-sm"
          type="submit"
        >
          {label2}
        </button>
      </div>
    </div>
  );
};

export default DowngradePlan;

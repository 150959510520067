import { api } from "@/../services/api";
import { Form, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
// import { Form } from 'react-hook-form'

const EnterpriseForm = ({ handleClose }) => {
  const getUserBusiness = useSelector((state) => state.getBusiness);
  const business =
    getUserBusiness.activeBusiness || getUserBusiness?.data?.data[0];

  const getUserProfile = useSelector((state) => state.getProfile);
  // console.log(business, "this");

  const profile = getUserProfile?.data?.data;
  // console.log(profile, "user profile");

  const handleMailing = async( data ) => {
  await api.post('/forms/contact-us', data)
  .then((res) => { 
    //handle notification
    handleClose(); 
  })
  .catch((error) => { 
    //handle notification
  })}

  return (
    <div className="bg-white w-[578px] h-[550px] rounded-lg px-[50px] py-[30px]">
      <h1 className="text-center text-[28px] leading-[33.6px] text-[#101828] font-medium">
        Let's Talk
      </h1>
      <Formik
        initialValues={{
          first_name: profile?.first_name,
          last_name: profile?.last_name,
          business_email: business?.business_email,
          phone_number: profile?.phone_number,
          business_name: business?.name,
          message: "",
        }}
        // validationSchema={}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleMailing(values);
          setTimeout(() => {
            resetForm();
            setSubmitting(false);
          })
        }}
      >
        {({ handleSubmit, handleChange, values }) => (
          <Form onSubmit={handleSubmit} className="flex flex-col items-center">
            <div className="flex flex-col gap-3">
              <label className="text-[12px] leading-[20px]">Name</label>
              <input
                name="name"
                type="text"
                value={`${values.first_name} ${values.last_name}`}
                onChange={handleChange}
                className="outline-none w-[473px] py-[8px] px-[14px]"
              />
            </div>

            <div className="hidden">
              <label className="text-[12px] leading-[20px]">first</label>
              <input
                name="first_name"
                type="text"
                onChange={handleChange}
                value={values.first_name}
                className="outline-none w-[473px] py-[8px] px-[14px]"
              />
            </div>
            <div className="hidden">
              <label className="text-[12px] leading-[20px]">Last</label>
              <input
                name="last_name"
                type="text"
                onChange={handleChange}
                value={values.last_name}
                className="outline-none w-[473px] py-[8px] px-[14px]"
              />
            </div>

            <div className="flex flex-col gap-3 mt-[14px]">
              <label className="text-[12px] leading-[20px]">Company Name</label>
              <input
                name="business_name"
                type="text"
                onChange={handleChange}
                value={values.business_name}
                className="outline-none w-[473px] py-[8px] px-[14px]"
              />
            </div>


              {/* <label className="text-[12px] leading-[20px]">
                Phone Number
              </label> */}
              <input
                name="phone_number"
                type="tel"
                onChange={handleChange}
                value={values.phone_number}
                className="outline-none w-[473px] py-[8px] px-[14px] hidden"
              />
          

            <div className="flex flex-col gap-3 mt-[14px]">
              <label className="text-[12px] leading-[20px]">
                Email Address
              </label>
              <input
                name="business_email"
                type="email"
                onChange={handleChange}
                value={values.business_email}
                className="outline-none w-[473px] py-[8px] px-[14px]"
              />
            </div>

            <div className="flex flex-col gap-3 mt-[14px]">
              <label className="text-[12px] leading-[20px]">Message</label>
              <textarea name="message" value={values.message} onChange={handleChange} className="border border-[#D0D5DD] border-solid w-[473px] py-[10px] px-[12px] outline-none rounded-lg" />
            </div>

            <button
              className="w-[478px] px-[20px] py-[10px] bg-[#121212] rounded-lg text-[14px] text-white mt-[24px]"
              type="submit"
            >
              Send
            </button>

            <p
              className="text-[#868686] text-[14px] leading-[24px] font-medium mt-[12px] cursor-pointer"
              onClick={handleClose}
            >
              Cancel
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EnterpriseForm;

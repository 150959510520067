import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import Logo from "../../../Assets/svg/team-invite.svg"
import ModalPop from '../../modalPop'
import TeamInvitation from './TeamInvitation'
import { useDispatch, useSelector } from 'react-redux'
import { acceptTeamInvite } from '../../../features/teams/acceptTeamInviteSlice'
import { SuccessMessage } from '../../../Toast-Messages/Toastmessages'

const Accept = ({ handleClose, check }) => {
    const [openTeamInvitationModal, setOpenTeamInvitationModal] = useState(false);
    const [acceptTeamInviteSuccess, setAcceptTeamInviteSuccess] = useState(false);

    const navigate = useNavigate()
    const dispatch = useDispatch();


    const acceptTeamInvitation = useSelector(state => state.acceptTeamInvite);
    // console.log(acceptTeamInvitation, "acceptTEAM")
    
    const token = check?.invitation?.invite_code

    const acceptInvite = () => {
        dispatch(acceptTeamInvite(token))
        .then((res) => {
            // console.log(res, "lapa")
            if(res?.payload?.status) {
                setAcceptTeamInviteSuccess(true)
            }
            navigate("/")
        })
    }

    const removeSuccessNotification = () => {
        setTimeout(() => {
            setAcceptTeamInviteSuccess(false)
        }, 1000);
    };

    useEffect(() => {
        removeSuccessNotification()
    }, [acceptTeamInviteSuccess])

  return (
    <div className="bg-WHITE-_100 w-[520px] h-[276px] mt-[80px] py-[30px] px-[50px] rounded-lg" style={{boxShadow: "0px 24px 43px 0px rgba(76,76,76, 0.07)"}}>
        <div className='w-[321px] h-[86px] gap-1.5 flex flex-col justify-center items-center mx-auto'>
            <img src={Logo} alt='Logo' className='w-[35px] h-[35px]'/>
            <p className='text-2xl text-BLACK-_600 font-medium'>Team Invitation</p>
            <p className='text-BLACK-_300 text-sm'>Sterling has invited you to join their Team on Wano.</p>
        </div>
        <button 
            className='bg-BLACK-_500 w-[420px] h-[40px] p-1.5 text-WHITE-_100 rounded-lg mt-10'
            onClick={() => acceptInvite()}
            type='button'
        >
            Accept
        </button>
        <div className='mt-10 w-[306px] h-[10px] mx-auto'>
            <p className='text-sm text-BLACK-_300'>Don’t know why you’re receiving this? <span className='font-bold text-BLUE-_200 cursor-pointer' onClick={() => setOpenTeamInvitationModal(true)}>Click Here</span></p>
        </div>

        {acceptTeamInviteSuccess && <SuccessMessage Formodal={true} success={acceptTeamInvite?.data?.message} className="mt-24 mr-20" />}

        <ModalPop isOpen={openTeamInvitationModal}>
            <TeamInvitation closeInvitationModal={() => setOpenTeamInvitationModal(false)} />
        </ModalPop>
    </div>
  )
}

export default Accept
import React, { useEffect } from "react";
import Image from "../Assets/svg/giveinstruction.svg";
import { FaArrowRight } from "react-icons/fa6";
import Head from "../Assets/svg/Shape65.svg";
import { useTour } from "@reactour/tour";
import { useLocation } from "react-router";
const GiveInstructionModal = ({ handleClose }) => {
  const location = useLocation();
  const { setIsOpen, setSteps, setCurrentStep } = useTour();

  const openStartPop = () => {
    setIsOpen(true);
    handleClose();
  };

  const instructionSteps = [
    {
      selector: ".instructionstep-1",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>
            Give your Instruction a Title. This is solely so you can reference
            it later. E.g. “When Customers ask for Pricing”
          </p>
          <p className="text-[#7F7F81]">Step 1 of 5 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
    {
      selector: ".instructionstep-2",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>
            Enter a detailed and informative description that accurately
            explains the content or purpose
          </p>

          <p className="text-[#7F7F81]">Step 2 of 5 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
    {
      selector: ".instructionstep-3",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>Try to be specific when creating an Instruction or Guideline:</p>
          <p>
            e.g. “Whenever a user wants to see a product demo, direct them to
            our demo link”
          </p>

          <p className="text-[#7F7F81]">Step 3 of 5 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },

    {
      selector: ".instructionstep-4",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>
            Provide an optional channel to redirect users to. This could be
            anything from a phone number to call or a website link to follow.
            You can add up to 3 channels.
          </p>

          <p className="text-[#7F7F81]">Step 4 of 5 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },

    {
      selector: ".instructionstep-5",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Good Job</p>
          </div>
          <p>
            You have completed the Instructions guided tour, If you need to go
            through the tour again, you can find it here.
          </p>
          <p className="text-[#7F7F81]">Step 5 of 5 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
  ];

  const scheduledSteps = [
    {
      selector: ".instructionstep-1",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>
            Give your Instruction a Title. This is solely so you can reference
            it later. E.g. “When Customers ask for Pricing”
          </p>
          <p className="text-[#7F7F81]">Step 1 of 7 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
    {
      selector: ".instructionstep-2",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>
            Enter a detailed and informative description that accurately
            explains the content or purpose
          </p>

          <p className="text-[#7F7F81]">Step 2 of 7 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
    {
      selector: ".instructionstep-3",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>Try to be specific when creating an Instruction or Guideline:</p>
          <p>
            e.g. “Whenever a user wants to see a product demo, direct them to
            our demo link”
          </p>

          <p className="text-[#7F7F81]">Step 3 of 7 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },

    {
      selector: ".instructionstep-4",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>
            Provide an optional channel to redirect users to. This could be
            anything from a phone number to call or a website link to follow.
            You can add up to 3 channels.
          </p>

          <p className="text-[#7F7F81]">Step 4 of 7 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
    {
      selector: ".instructionstep-6",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know your Instructions</p>
          </div>
          <p>
            Set an optional start date for Scheduled Instructions. Your
            Instruction will only be active within the start and end date.
          </p>

          <p className="text-[#7F7F81]">Step 5 of 7 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
    {
      selector: ".instructionstep-7",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Get to know Instructions</p>
          </div>
          <p>
            Set an optional End date for Scheduled Instructions. Your
            Instruction will only be active within the start and end date.
          </p>
          <p className="text-[#7F7F81]">Step 6 of 7 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
    {
      selector: ".instructionstep-5",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Great Job!</p>
          </div>
          <p>
            You have completed the Instructions guided tour, If you need to go
            through the tour again, you can find it here
          </p>
          {/* <p className="text-[#7F7F81]">Step 1 of 4 </p> */}
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isAgentStep: false,
    },
  ];

  const saveTourValue = () => {
    if(location.pathname.includes("Scheduled")){
      return localStorage.setItem('tourS', false)
    }else {
      return localStorage.setItem('tourOthers', false)
    }
  }

  useEffect(() => {
    if (location.pathname.includes("Scheduled")) {
      setSteps(scheduledSteps);
    } else {
      setSteps(instructionSteps);
    }
  }, []);

  return (
    <div className="w-[578px] bg-white h-fit  text-center rounded-lg mt-[80px]">
      <div className="bg-[#F6FAFF] px-[70px] pt-[50px] rounded-lg">
        <img src={Image} alt="image" />
      </div>
      <div className="px-[70px] pb-[50px]">
        <h1 className="text-[#101828] text-[28px] leading-[33.6px] mt-[20px] font-medium">
          Give your Assistant Instructions
        </h1>
        <p className="text-[#7F7F81] text-[16px] leading-[24px] mt-[20px]">
          Set how your employee should respond to specific requests, complaints
          and enquiries.
        </p>
        <button
          className="bg-[#121212] text-white px-[20px] py-[10px] rounded-lg text-[14px] mt-[40px] leading-[16.8px] cursor-pointer"
          onClick={() => {openStartPop(); saveTourValue()}}
        >
          Take a Guided Tour
        </button>
        <div
          className="flex items-center justify-center gap-2 mt-[20px] cursor-pointer"
          onClick={() => {
            handleClose();
            // localStorage.setItem('tour', false)
            saveTourValue();
          }}
        >
          <p className="text-[#1774FD] text-[14px] leading-[16.8px] font-medium">
            I’ll do this on my own
          </p>
          <FaArrowRight className="text-base font-medium text-BLUE-_200 mt-0.5" />
        </div>
      </div>
    </div>
  );
};

export default GiveInstructionModal;

import React from 'react'
import LoginForm from './LoginForm'
import WelcomeLogoText from './WelcomeLogotext'
import FormFooter from './FormFooter'
import { useState } from 'react'
import SignUpForm from './SignUpForm'
import WanoLogo from '../Assets/wano logo 1 1.png'
import { useLocation, useNavigate } from 'react-router'

export default function Login({ initialNewUser = false }) {
    const [newUser, setNewUser] = useState(initialNewUser)
    const navigate = useNavigate();
    const location = useLocation();
    const allowNewUser = process.env.REACT_APP_ALLOW_SIGN_UP === 'true'
    console.log('jhgf',typeof(allowNewUser))
    
    function handleUser(){
        setNewUser(!newUser)
        navigate(newUser ? '/' : '/signup');
    }
  return (
    <div className="w-[520px] mx-auto shadow py-[30px] px-[50px] rounded-xl grid gap-10 bg-white">
      <WelcomeLogoText
        width={"w-[300px]"}
        Logo={WanoLogo}
        title={newUser ? "Get Started with Wano" : "Welcome back"}
        text={
          newUser
            ? "Let's set up your account now"
            : "Let's continue from where you stopped"
        }
      />
      {newUser ? <SignUpForm /> : <LoginForm />}

      {/* this controls toggles between login and signUp */}

      {allowNewUser && (
        <FormFooter
          question={
            newUser ? "Already have an account?" : "Don't have an account?"
          }
          option={newUser ? "Login Here" : "Sign Up Here"}
          onClick={handleUser}
        />
      )}
    </div>
  );
}

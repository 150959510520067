import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "./Button";
import NavBar from "./NavBar";
import Terms from "./Terms";
import WelcomeLogoText from "./WelcomeLogotext";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const style =
  "rounded-xl px-[12px] w-[420px] line-[24px] py-[13px] text-white text-14px";

export default function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, formState } = useForm();
  const { errors, isDirty, isValid } = formState;
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  function onSubmit(data) {
    const { password } = data;
    localStorage.setItem("new_pd", password);
    navigate("/reset-verify");
  }

  function onError(errors) {
    console.log(errors);
  }
  return (
    <div className="min-h-screen grid content-between bg-[#F7F7F7]">
      <NavBar />
      <div className="w-[522px] mx-auto shadow py-[30px] px-[50px] rounded-xl grid gap-10 items-center justify-center bg-white">
        <div className="grid gap-3">
          <BiArrowBack
            onClick={() => navigate(-1)}
            className="cursor-pointer text-[18px]"
          />
          <WelcomeLogoText
            width={"w-[333px]"}
            title={"Reset Password"}
            text={"Enter a new password for your account."}
          />
        </div>
        <form className="grid gap-3" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className=" relative">
            <input
              className="w-full outline-none rounded-lg h-10 pr-10 text-base"
              type={showPassword ? "text" : "password"}
              id="password"
              {...register("password", {
                required: "Password is required",
                pattern: {
                  value:
                    /^(?=.*[A-Za-z\d])(?=.*[!@#$%^&*.,><*])[A-Za-z\d!@#$%^&*,.><*]{8,}$/,
                  message:
                    "Password must be at least 8 characters and must include at least  one letter, one digit, and one special character.",
                },
              })}
            />
            {showPassword ? (
              <BsEyeSlash
                className=" absolute top-[10px] right-3 cursor-pointer text-[#828282] text-lg"
                onClick={togglePasswordVisibility}
              />
            ) : (
              <BsEye
                className=" absolute top-[10px] right-3 cursor-pointer text-[#828282] text-lg"
                onClick={togglePasswordVisibility}
              />
            )}
          </div>
          <span className="text-red-500 text-[12px]">
            {errors?.password && errors?.password?.message}
          </span>
        </form>

        <Button
          disabled={!isDirty && !isValid}
          className={isValid ? `bg-black ${style}` : `bg-[#EBEBEB] ${style}`}
          onClick={handleSubmit(onSubmit, onError)}
          text={"Reset"}
        />
      </div>
      <Terms />
    </div>
  );
}


import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api"

const initialState = {
  messages: [],
  chatID: "",
  appID: "",
  memoryExists: false,
  loading: false,
  error: null,
  testEmployeeTour:false,
  modalVisibility:false,
};


// Define thunk for fetching messages
// export const fetchMessages = createAsyncThunk(
//     'chat/fetchMessages',
//     async ({chatID, applicationId}, { rejectWithValue }) => { // Get applicationId from state (replace with your actual application id)
//       try {
//         const res = await api.get(`/dashboard/applications/${applicationId}/chat/${chatID}/conversations`)
//         return res.data.data.reverse();
//       } catch (error) {
//         return rejectWithValue(error?.response?.data?.message)
//       }
//     }
//   );
  
  // Define thunk for sending message
//   export const sendMessage = createAsyncThunk(
//     'chat/sendMessage',
//     async ({ message, title, chatID, agentDetails, applicationId }, { rejectWithValue }) => {
//       try {
//         const res = await api.post(`/dashboard/applications/${applicationId}/chat/${chatID}/conversations/send-test-employee`, {
//             message: message || title,
//             assistant_type: agentDetails.type,
//         })
//         return res.data;
//       } catch (error) {
//         return rejectWithValue(error?.response?.data?.message)
//       }
//     }
//   );
  

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChatID(state, action) {
      state.chatID = action.payload;
    },
    setPreviousAppID(state, action) {
        state.appID = action.payload;
    },
    setMemoryExists(state, action) {
      state.memoryExists = action.payload;
    },
    showTestemployeeTour(state,action) {
      state.testEmployeeTour = action.payload;
    },
    setModalVisibility(state,action){
      state.modalVisibility = action.payload;
    }
  },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchMessages.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(fetchMessages.fulfilled, (state, action) => {
//         state.loading = false;
//         state.messages = action.payload;
//       })
//       .addCase(fetchMessages.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.error.message;
//       })
//       .addCase(sendMessage.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(sendMessage.fulfilled, (state, action) => {
//         state.loading = false;
//         // Handle successful message sending if needed
//       })
//       .addCase(sendMessage.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.error.message;
//       });
//   },

});

export const { setChatID, setPreviousAppID, setMemoryExists,showTestemployeeTour,setModalVisibility } = chatSlice.actions;

export default chatSlice.reducer;
import React, { useEffect, useState } from "react";
import { planData } from "./planData";
import { useNavigate } from "react-router";
import ModalPop from "@/../component/modalPop";
import UpgradePlan from "./UpgradePlan";
import DowngradePlan from "./DowngradePlan";
import EnterpriseForm from "./EnterpriseForm";
import RedeemSuccess from "../component/modals/redeemgift/RedeemSuccess";
import { api } from "@/../services/api";
import PlanCardSkeleton from "@/../PlanCardSkeleton";
import { useAuth, useUserDispatch } from "@/../context/UserContext";
import Pay from "@/../component/modals/wallet/Pay";
import RedeemGift from "@/../component/modals/redeemgift";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentPlan } from "@/../features/profile/getCurrentPlanSlice";
import { getGiftBalance } from "@/../features/profile/getGiftBalanceSlice";
import Spinner from "component/Spinner";
// import whatsappIcon from "../Assets/svg/whatsappico.svg";
// import thirdPartyIcon from "../Assets/svg/databaseico.svg";
// import advancedCustomizationIcon from "../Assets/svg/advancedico.svg";
// import serversIcon from "../Assets/svg/vectorico.svg";
// import wanoLogoIcon from "../Assets/svg/wanoico.svg";

const PlanCard = () => {
  const [modal, setModal] = useState({ type: "", title: "" });
  const [openRedeemSuccess, setOpenRedeemSuccess] = useState(false);
  const [plan, setPlan] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openRedeemGift, setOpenRedeemGift] = useState(false);
  const [data, setData] = useState();
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState();
  const [error, setError] = useState("");
  const [openPayModal, setOpenPayModal] = useState(false);
  const [ isNotSufficient, setSufficient ] = useState(false);
  const { basePlan,  giftBal } = useAuth();
  const { setBasePlan, setGiftBal } = useUserDispatch();
  const activeIndex = plan?.findIndex((plan) => plan?.id == currentPlan?.id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(currentPlan, "plan");
  const business =  useSelector(state => state.getBusiness.activeBusiness);
  const businessID = business?.id;
  // const walletBalance = useSelector((state) => state.walletBalance);
  // const { tokens } = walletBalance.data;
  // const desiredPlanCharge = Number(data?.monthly_amount);
  // const availableBalance = Number(giftBal) + Number(tokens);
  // const isNotSufficient = availableBalance < desiredPlanCharge;
  // const businessID = JSON.parse(localStorage.getItem("cBus"))?.id;

  // useEffect(() => {
  //   dispatch(getGiftBalance())
  // },[])

  const pageHandler = (type) => {
    navigate(`/settings/${type}/manage-plan`);
  };

  const handleModal = (type, title, data, index) => {
    setModal({ type, title });
    setData(data);
    setIndex(index);
  };

  const closeModal = () => {
    setModal({ type: "", title: "" });
  };



  const fetchBalance = async () => {
    await api
      .get(`/dashboard/wallet/get/all/${businessID}`)
      .then((res) => {
        setGiftBal(res?.data?.data[2]);
        sessionStorage.setItem("giftBal", res?.data?.data[2]?.balance);
      })
      .catch((error) => {
        setError(error?.data.message);
        setTimeout(() => {
          setError("");
        }, 1000);
      });
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await api.get("/dashboard/subscriptions/business/available");
      // console.log(res.data.data.subscriptions, "res res res");
      setPlan(res.data.data.subscriptions);

      setCurrentPlan(res.data.data.currentPlan.subscription_details);
    } catch (error) {
      console.log(error, "this error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (plan.length > 0) {
      setBasePlan(plan[0]);
    }
  }, [plan, setBasePlan]);

  // console.log(basePlan, "baseplan");
  // console.log(plan, "plan plan");

  useEffect(() => {
    fetchData();
  }, []);

  // const icon = {
  //   "whatsapp": whatsappIcon,
  //   "third-party": thirdPartyIcon,
  //   "advanced-customization": advancedCustomizationIcon,
  //   "servers": serversIcon,
  //   "wano-logo": wanoLogoIcon,
  // };

  return (
    <div className="flex justify-between flex-wrap gap-10">
      {loading ? (
        // <PlanCardSkeleton />
        <div className="h-[50vh] w-screen flex items-center justify-center">
        <Spinner />
      </div>

      ) : (
        plan?.map(
          (
            // {
            //   id,
            //   name,
            //   description,
            //   features,
            //   monthly_amount,
            //   yearly_amount,
            //   tag_slug,
            //   disabled,
            //   created_at,
            //   updated_at,
            // },
            option,
            index
          ) => {
            const active = option?.id === currentPlan?.id;
            let buttonLabel = "";
            let buttonAction = () => {};
            if (active) {
              buttonLabel = "manage plan";
              buttonAction = () => pageHandler(option?.name);
            } else if (index < activeIndex) {
              buttonLabel = "downgrade";
              buttonAction = () =>
                handleModal("downgrade", option?.name, option, index);
            } else if (index > activeIndex && index < plan.length - 1) {
              buttonLabel = "upgrade";
              buttonAction = () => handleModal("upgrade", option?.name, option, index);
            } else if (index === plan.length - 1) {
              buttonLabel = "let's talk";
              buttonAction = () =>
                handleModal("letstalk", option?.name, option);
            }
            console.log(option?.features, "featuress");
            return (
              <div
                key={option?.id}
                className={`w-[305px] flex flex-col justify-between  border border-solid border-[#E5E5E5] rounded-lg px-[20px] pt-[30px] pb-[24px] ${
                  active ? "bg-[#FAFBFC]" : ""
                }`}
              >
                <div>
                  <div>
                    <div className="flex justify-between">
                      <div>
                        <h1 className="font-medium text-[16px] leading-[21.5px] text-[#101828] mb-[12px]">
                          {option?.name}
                        </h1>
                        <p
                          className="font-normal text-[12px] leading-[19px] text-[#828282]"
                          style={index === 1 ? { width: "143px" } : {}}
                        >
                          {option?.description}
                        </p>
                      </div>
                      {active && (
                        <button className="text-white bg-[#1774FD] px-[7px] py-[6px] text-[10px] w-[71px] h-fit flex items-center justify-center rounded-[4px]">
                          Current Plan
                        </button>
                      )}
                    </div>
                    <div
                      className={`flex items-center ${
                        index === 1 ? "mt-[12px]" : "mt-[30px]"
                      }`}
                    >
                      {option?.name === "Enterprise" ? (
                        <span>Let's talk</span>
                      ) : (
                        <>
                          <span className="font-medium text-[18px] leading-[21.5px] text-[#101828]">
                            ${option?.monthly_amount}
                          </span>
                          <span className="text-[#828282] text-[12px] leading-[28px]">
                            /per month
                          </span>
                        </>
                      )}
                    </div>
                  </div>

                  <ul
                    className={`list-disc pl-5 text-[#828282] text-[12px] ${
                      index === 3 ? "mt-[16px]" : "mt-[28px]"
                    }`}
                  >
                    {option?.features?.map((item, listItemIndex) => { 
                      return (
                      <li key={listItemIndex}>
                        <p className="text-[12px] leading-[25px] font-normal text-[#828282]">
                          {["whatsapp", "servers", "wano-logo", "third-party", "discount", "advanced-customization"].includes(item?.prefix) ? "": item?.prefix} {item?.description}
                        </p>
                        {/* <div className="text-[12px] leading-[25px] flex gap-1 items-center font-normal text-[#828282]">
                          {icon[item?.prefix] ? (
                            <>
                              <img
                                className="w-[14px] h-[14px]"
                                src={icon[item?.prefix]}
                                alt="icon"
                              />
                              {item?.description}
                            </>
                          ) : (
                            <p className="">
                              {item?.prefix} {item?.description}
                            </p>
                          )}
                        </div> */}
                      </li>
                    )})}
                  </ul>
                </div>
                <div className="self-end">
                  <button
                    onClick={buttonAction}
                    className={`text-white capitalize w-[265px] rounded-[8px] text-[12px] py-[6px] px-[20px] ${
                      active ? "bg-[#1774FD]" : "bg-black"
                    }`}
                  >
                    {buttonLabel}
                  </button>
                </div>
              </div>
            );
          }
        )
      )}

      {modal.type && (
        <ModalPop isOpen={!!modal.type}>
          {modal.type === "upgrade" ? (
            <UpgradePlan
              handleClose={closeModal}
              setOpenRedeemSuccess={setOpenRedeemSuccess}
              data={data}
              refresh={() => { fetchData(); dispatch(getCurrentPlan())}}
              setOpenPayModal={setOpenPayModal}
              setSufficient={setSufficient}
            />
          ) : modal.type === "downgrade" ? (
            <DowngradePlan
              heading={"Downgrade Plan"}
              warning={
                "Are you sure you want to downgrade this plan? This action cannot be undone."
              }
              label1={"Cancel"}
              label2={"Downgrade"}
              handleClose={closeModal}
              data={data}
              refresh={() => {fetchData(); dispatch(getCurrentPlan())}}
              setOpenPayModal={setOpenPayModal}
              setSufficient={setSufficient}
              index={index}
            />
          ) : (
            <EnterpriseForm handleClose={closeModal} />
          )}
        </ModalPop>
      )}
      <ModalPop isOpen={openRedeemSuccess}>
        <RedeemSuccess
          handleClose={() => setOpenRedeemSuccess(false)}
          title={"Upgrade Successful"}
          text1={"You have successfully upgraded to the Business Plan"}
          label={"Done"}
          redirectPath="/settings?page=billing&category=plan"
        />
      </ModalPop>

      <ModalPop isOpen={openPayModal}>
        <Pay
          handleClose={() => setOpenPayModal(false)}
          insufficient={isNotSufficient}
          setOpenRedeemGift={setOpenRedeemGift}
        />
      </ModalPop>

      <ModalPop isOpen={openRedeemGift}>
        <RedeemGift
          handleClose={() => setOpenRedeemGift(false)}
          back={() => {setOpenPayModal(true); setOpenRedeemGift(false)}}
          setShow={setShow}
          setOpenRedeemSuccess={setOpenRedeemSuccess}
          refresh={fetchBalance}
        />
      </ModalPop>
    </div>
  );
};

export default PlanCard;

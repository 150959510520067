import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from "../../services/api"

const initialState = {
    loading: false,
    data: [],
    error: ""
};

export const fetchWhatsAppNumbers = createAsyncThunk(
    "whatsapp/fetchWhatsapp", 
    async(values, { rejectWithValue }) => {
        try {
            const res = await api.get(`/dashboard/applications/${values}/session`);
            // console.log(res, "kiz")
            return res?.data?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)

const whatsAppSlice = createSlice({
    name: "whatsapp",
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchWhatsAppNumbers.pending, state => {
            state.loading = true
        });
        builder.addCase(fetchWhatsAppNumbers.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(fetchWhatsAppNumbers.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default whatsAppSlice.reducer
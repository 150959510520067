import React, { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { Switch } from "antd";
import { Popover } from "@headlessui/react";
import { Eye, Delete, EditIcon } from "../../Assets/svg";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { useLocation, useNavigate } from "react-router";
import ModalPop from "../../component/modalPop";
import EditFeatureModal from "../EditFeatureModal";
import DeleteInstructionModal from "../DeleteInstructionModal";
import { ServiceFeaturesCard } from "@/../LoadingSkeleton";
import { useSelector } from "react-redux";
import GiveInstructionModal from "../GiveInstructionModal";

export default function InstructionTable({
  publishLoading,
  deleteInstruction,
  refresh,
  name,
  id,
  loading,
  fetchedData,
  handleActivation,
}) {
  const navigate = useNavigate();
  const [editFeature, setEditFeature] = useState(false);
  const [instructionId, setInstructionId] = useState("");
  const [InstructiontoEdit, setInstructiontoEdit] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const fullAgent = getAgentApplication?.agent || null;
  const [data, setData] = useState([]);

  const location = useLocation();
  console.log(name, "what is name?")

  const timeRef = (currentDate) => {
    // const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); 
    const year = currentDate.getFullYear();

    const fullDate = day + month + year;
    console.log(fullDate);
    return fullDate
  }
  const filteredData = fetchedData.filter((data)=> {
    const endDate = new Date(data.end_date);
    const currentDate = new Date();
    console.log(currentDate === endDate, timeRef(currentDate), timeRef(endDate), "stattus", "today", "end"  )
  })

  useEffect(() => {
    // if (location.pathname.includes("archives")) {
    //   const currentDate = new Date();
    //   console.log(currentDate.toLocaleString(), "now date");

    //   const filteredData = fetchedData.filter((each)=>{
    //     if(each?.draft?.to_be_deleted){
    //       return false;
    //     }
    //     else{
    //       return true
    //     }
    //   }).filter((data) => {
    //     if (data.end_date === null) {
    //       return false;
    //     }
    //     const endDate = new Date(data.end_date);
    //     return timeRef(currentDate) > timeRef(endDate) ;
    //   });

    //   setData(filteredData);

    //   console.log(
    //     filteredData,
    //     "Filtered Data where current date is greater than end date"
    //   );
    // } else 
    // if (location.pathname.includes("Scheduled")) {
    //   const currentDate = new Date();
    //   console.log(currentDate.toLocaleString(), "now date");

    // const filteredData = fetchedData.filter((each)=>{
    //   if(each?.draft?.to_be_deleted){
    //     return false;
    //   }
    //   else{
    //     return true
    //   }
    // }).filter((data) => {
    //   if (data.end_date === null) {
    //     return true;
    //   }
    //   const endDate = new Date(data.end_date);
    //   return timeRef(currentDate) <= timeRef(endDate) ;
    // });
    // setData(filteredData);
  // }else{
   
    setData(fetchedData.filter((each)=>{
      if(each?.draft?.to_be_deleted){
        return false;
      }
      else{
        return true
      }
    }))
  // }
}, [location.pathname, fetchedData]);

  // console.log(name, "type")
  return (
    <div className="w-full border border-[#EEEFF0] rounded-lg pb-10">
      <table className="w-full ">
        <tr>
          <th className="rounded-tl-lg flex items-center bg-[#E9E9EA] py-2 pl-5 font-semibold text-sm text-[#7F7F81]">
            <input
              type="checkbox"
              name=""
              id=""
              className="w-4 h-4 mr-[12px] border border-solid border-[#E9E9EA] rounded-sm"
            />
            Instruction Name
          </th>
          <th className="bg-[#E9E9EA] py-2 text-left text-sm text-[#7F7F81] font-semibold">
            Description
          </th>
          <th className="bg-[#E9E9EA] py-2 text-left text-sm text-[#7F7F81] font-semibold">
            {}{" "}
            {fullAgent.type === "sale"
              ? "Sales"
              : fullAgent.type === "general"
              ? "Support or Sales"
              : "Customer"}{" "}
            Channels
          </th>
          <th className=" bg-[#E9E9EA] py-2 text-left text-sm text-[#7F7F81] font-semibold">
            Visibility
          </th>
          <th className="w-[100px] rounded-tr-lg bg-[#E9E9EA] py-2 text-left text-sm text-[#7F7F81] font-semibold"></th>
        </tr>
        {!loading && data.length === 0 ? (
          <tr>
            <td colSpan={6} className="w-full pt-14">
              {location.pathname.includes("archives") ? (
                <>
                  <p className="text-[#121212] text-[16px] font-medium text-center">
                    You don't have any instruction in your Archive yet
                  </p>
                  <p className="text-center text-sm font-normal text-[#7F7F81] mt-1">
                    You can find all expired scheduled instructions here.
                  </p>
                </>
              ) : name.includes("Scheduled") ? (
                <>
                <p className="text-[#121212] text-[16px] font-medium text-center">
                  You haven’t scheduled any Instructions yet
                </p>
                <p className="text-center text-sm font-normal text-[#7F7F81] mt-1">
                  Schedule Instructions for your assistants to use either now
                  or in the future.
                </p>
              </>
              ) : (
                <>
                <p className="text-[#121212] text-[16px] font-medium text-center">
                  You haven’t created any Instructions yet
                </p>
                <p className="text-center text-sm font-normal text-[#7F7F81] mt-1">
                  Create Instructions on what responses your assistant gives
                  <br /> your customers.
                </p>
              </>
               
              )}{" "}
              <div className="mt-4 w-full flex justify-center">
                <button
                  onClick={() => {
                    navigate(`/agent/${name}/${id}/add-instruction`, {state: { dataLength: data?.length}});
                  }}
                  className="flex gap-2 items-center outline-none py-3 w-auto px-5 bg-[#121212] rounded-lg text-white text-[14px] font-semibold"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M7.5 3.125V11.875M3.125 7.5H11.875"
                      stroke="white"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Add Instruction
                </button>
              </div>
            </td>
          </tr>
        ) : (
          data &&
          data.map((data, index) => (
            <tr key={index} className="border-b border-[#F7F7F7]  cursor-pointer hover:bg-[#F4F8FF]/70">
              <td className="flex items-center pl-[20px] py-5 text-[#121212] font-normal text-sm">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  className=" w-4 h-4 mr-3 border border-solid border-[#E9E9EA] rounded-sm"
                />
                <span className="capitalize truncate w-[147x]">
                  {data.draft ? data.draft.name : data.name}
                </span>
              </td>
              <td className="capitalize text-[#828282] font-normal text-sm py-5">
                <p className="truncate w-[268px]">
                  {data.draft ? data.draft.description : data.description}
                </p>
              </td>
              <td className="capitalize text-[#828282] font-normal text-sm py-5">
                {data.draft
                  ? data.draft.support_channel[0]?.support_channel
                  : data.support_channel[0]?.support_channel}
              </td>
              <td className="flex gap-[25px] items-center text-[#828282] font-normal text-sm">
                <div
                  className={`py-1 px-2 flex items-center gap-1 rounded-[8px] ${
                    (data.draft ? data.draft.active : data.active)
                      ? "bg-ACTIVE"
                      : "bg-[#FAFAFA]"
                  }`}
                >
                  {(data.draft ? data.draft.active : data.active) ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="4"
                      height="4"
                      viewBox="0 0 4 4"
                      fill="none"
                    >
                      <circle cx="2" cy="2" r="2" fill="#009733" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="4"
                      viewBox="0 0 5 4"
                      fill="none"
                    >
                      <circle cx="2.5" cy="2" r="2" fill="#7F7F81" />
                    </svg>
                  )}
                  {/* <p
                    className={`text-xs w-20  ${
                      (data.draft ? data.draft.active : data.active)
                        ? "text-[#009733]"
                        : "text-[#7F7F81]"
                    } font-figtree`}
                  >
                    {(data.draft ? data.draft.active : data.active)
                      ? "Active"
                      : "InActive"}
                  </p> */}
                  <p
                    className={`text-xs w-20 ${
                      location.pathname.includes("archives")
                        ? "text-[#7F7F81]"
                        : (data.draft ? data.draft.active : data.active)
                        ? "text-[#009733]"
                        : "text-[#7F7F81]"
                    } font-figtree`}
                  >
                    {location.pathname.includes("archives")
                      ? "Inactive"
                      : (data.draft ? data.draft.active : data.active)
                      ? "Active"
                      : "Inactive"}
                  </p>
                </div>
                {publishLoading === data.id ? (
                  <div className="flex justify-center  w-10">
                    <CgSpinner className="animate-spin text-lg" />
                  </div>
                ) : (
                  <div className="flex justify-center">
                    <Switch
                      checked={
                        location.pathname.includes("/archives")
                          ? false
                          : data.draft
                          ? data.draft.active
                          : data.active
                      }
                      onChange={() => handleActivation(data.id)}
                      className={`${
                        location.pathname.includes("/archives") ||
                        !(data.draft ? data.draft.active : data.active)
                          ? "bg-gray-200"
                          : "bg-blue-600"
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${
                          location.pathname.includes("/archives") ||
                          !(data.draft ? data.draft.active : data.active)
                            ? "translate-x-1"
                            : "translate-x-6"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </Switch>
                  </div>
                )}
              </td>
              <td className="text-[#121212] font-normal text-sm">
                <Popover className="relative">
                  <Popover.Button className="outline-none">
                    <PiDotsThreeVerticalBold className="cursor-pointer text-base text-[#121212]" />
                  </Popover.Button>
                  <Popover.Panel>
                    <Popover.Button className="cursor-pointer py-[5px] px-[10px] rounded-lg z-10 absolute bg-white shadow-md right-3 flex flex-col gap-3">
                      <div
                        onClick={() =>
                          navigate(
                            `/agent/${name}/${data.draft? data.draft.name : data.name}/${id}/${data.id}/view-instruction`
                          )
                        }
                        className="flex items-center gap-3"
                      >
                        <Eye />
                        <p className="text-xs text-[#121212] font-figtree">
                          View
                        </p>
                      </div>
                      <div
                        className="flex items-center gap-3"
                        onClick={() => {
                          console.log("data", data);
                          setEditFeature(!editFeature);
                          setInstructionId(data.id);
                          setInstructiontoEdit(data.draft ? data.draft : data);
                        }}
                      >
                        {/* <Eye /> */}
                        <EditIcon />
                        <p className="text-xs text-[#121212] font-figtree">
                          Edit
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setDeleteModal(true);
                          setInstructionId(data.id);
                        }}
                        className="flex items-center gap-3"
                      >
                        <Delete />
                        <p className="text-xs text-[#AF202D] font-figtree">
                          Remove
                        </p>
                      </div>
                    </Popover.Button>
                  </Popover.Panel>
                </Popover>
              </td>
            </tr>
          ))
        )}
        <ModalPop isOpen={editFeature}>
          <EditFeatureModal
            refresh={refresh}
            handleClose={() => setEditFeature(false)}
            categoryName={name}
            id={id}
            instructionId={instructionId}
            instructionData={InstructiontoEdit}
          />
        </ModalPop>
        
        <ModalPop isOpen={deleteModal}>
          <DeleteInstructionModal
            handleClose={() => setDeleteModal(false)}
            id={id}
            instructionId={instructionId}
            refresh={refresh}
          />
        </ModalPop>
      </table>
      {loading && (
        <div className=" flex justify-center w-full ">
          <ServiceFeaturesCard />
          <ServiceFeaturesCard />
        </div>
      )}
    </div>
  );
}

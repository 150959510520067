import DashboardLayout from "../layout/DashboardLayout";
import Service from "../Agent/Service";
import Business from "../Agent/Business";
import Settings from "../Agent/Settings";
import { UserContext, useAuth } from "../context/UserContext";
import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { fetchWhatsAppNumbers } from "../features/deploy/whatsAppSlice";
import ModalPop from "../component/modalPop";
import ChangesBanner from "../component/ChangesBanner";
import { BottomGuide, TopGuide } from "../component/modals/agentGuide/Guide";
import PublishModal from "../component/modals/agent/PublishModal";
import TestAgent from "Agent/TestAgent/TestAgent";
import { api } from "services/api";

import AgentA from "../Assets/svg/agent1.svg";
import AgentB from "../Assets/svg/agent2.svg";
import Integration from "../Settings/IntegrationTab";
import {
  setMemoryExists,
  showTestemployeeTour,
} from "@/../features/testAgent/chatSlice";
import axios from "axios";
import TokenService from "../services/token";
import { getAllPlugins } from "@/../features/plugins/getAllPluginsSlice";
import useNavigationGuard from "@/../helper/hooks/useNavigationGuard";
import { set } from "react-hook-form";
import { updateAgentModals } from "@/../features/modals/agentModalsSlice";
import { getCurrentEmployee } from "@/../features/modals/agentModalsSlice";
import { useTour } from "@reactour/tour";

export default function Agent(props) {
  const dispatch = useDispatch();

  const whatsApp = useSelector((state) => state.whatsAppAll);
  const [showAgentNotification, setShowAgentNotification] = useState(false);
  const [openTopGuide, setOpenTopGuide] = useState(false);
  const [openBottomGuide, setOpenBottomGuide] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location || {};
  const { feature, reload } = state || {};
  const appDetail = useSelector((state) => state.getApplicationByUserId);

  const data = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = data && data.length >= 1 ? data[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();

  let defaultButton;

  const tabs = ["Memory", "Features", "Settings", "Integrations/Deploy"];

  if (location?.state === "features") {
    defaultButton = "Features";
  } else if (location?.state === "settings") {
    defaultButton = "Settings";
  } else if (location?.state === "Integrations/Deploy") {
    defaultButton = "Integrations/Deploy";
  } else {
    defaultButton = "Memory";
  }

  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );

  const agentType = getAgentApplication?.data?.data?.length
    ? getAgentApplication?.data?.data[0]?.type
    : null;
  // useEffect(() => {
  //   if (agentType === null) {
  //     navigate("/dashboard");
  //   }
  // }, [agentType]);

  // if ("Sales" === "Sales") {
  //   defaultButton = "Agent Memory";
  // } else if ("Sales" === "Authentication") {
  //   defaultButton = "Agent Memory";
  // } else if ("Sales" === "Customer Service") {
  //   defaultButton = "Agent Memory";
  // }

  // const [activeButton, setActiveButton] = useState(defaultButton);
  const { button: activeButton, setButton: setActiveButton } = useAuth();
  // State for controlling the visibility of the unsaved settings modal
  const [isFormModified, setIsFormModified] = useState(false);
  const [showUnsavedSettingsModal, setShowUnsavedSettingsModal] =
    useState(false);
  const [reroute, setReroute] = useState(defaultButton);
  const [tx, setTx] = useState();
  // const [setConfirmedNavigation] = useNavigationGuard(isFormModified, setShowUnsavedSettingsModal);/

  // Function to close the unsaved settings modal
  const handleCloseUnsavedSettingsModal = () => {
    setShowUnsavedSettingsModal(false);
    setActiveButton(reroute);
  };

  // useEffect(() => {
  //   const modified = localStorage.getItem("isFormModified") === "true";
  //   setIsFormModified(modified)
  // },[isFormModified])

  // useEffect(() => {
  //   const show = localStorage.getItem("showUnsavedSettingsModal") === "true";
  //   setShowUnsavedSettingsModal(show)
  // },[showUnsavedSettingsModal])

  // const handleButtonClick = (buttonName) => {
  //   if (isFormModified && !["Memory", "Features", "Settings", "Integrations/Deploy"]. {
  //     setShowUnsavedSettingsModal(true)
  //     setReroute(buttonName)
  //     return;
  //   }
  //   setActiveButton(buttonName);
  // };
  const handleButtonClick = (buttonName) => {
    if (isFormModified) {
      // navigate('/settings')
      setShowUnsavedSettingsModal(true);
      setReroute(buttonName);
      return;
    }
    setActiveButton(buttonName);
  };

  useEffect(() => {
    if (!applicationId) return;
    dispatch(fetchWhatsAppNumbers(applicationId));
  }, []);

  useEffect(() => {
    if (!applicationId) return;
    dispatch(getAllPlugins({ applicationId }));
  }, []);

  // const setTopAgentGuide = () =>
  //   location.pathname === "/agent" ? localStorage.setItem("agent", true) : null;
  // const setBottomAgentGuide = () =>
  //   location.pathname === "/agent"
  //     ? localStorage.setItem("bottomAgent", true)
  //     : null;

  // const getAgentGuide = localStorage.getItem("agent");
  // const getBottomAgentGuide = localStorage.getItem("bottomAgent");

  // useEffect(() => {
  //   if (getAgentGuide) {
  //     setOpenTopGuide(false);
  //   } else {
  //     setOpenTopGuide(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (reload) {
  //     // window.location.reload();
  //   }
  //   if (openTopGuide && !getBottomAgentGuide) {
  //     setOpenBottomGuide(false);
  //   } else if (!openTopGuide && !getBottomAgentGuide) {
  //     setOpenBottomGuide(true);
  //   } else if (!openTopGuide && getBottomAgentGuide) {
  //     setOpenBottomGuide(false);
  //   } else {
  //     setOpenBottomGuide(true);
  //   }
  // }, [reload, openTopGuide, getBottomAgentGuide]);

  //checks the state of the modal
  const config = useSelector((state) => state.agentModals.employeeModals);
  const datAgent = useSelector((state) => state.agentModals);

  const getAgentGuide = config?.agent;
  const getBottomAgentGuide = config?.bottomAgent;

  //bug it takes time for the next modal to show
  // useEffect(() => {
  //   if (getAgentGuide && getBottomAgentGuide) {
  //     setOpenTopGuide(true);
  //     setOpenBottomGuide(false);
  //   } else if(!getAgentGuide && getBottomAgentGuide) {
  //     setOpenTopGuide(false);
  //     setOpenBottomGuide(true);
  //   } else if(!getAgentGuide && !getBottomAgentGuide) {
  //     setOpenTopGuide(false);
  //     setOpenBottomGuide(false);
  //   }
  // }, [getAgentGuide, getBottomAgentGuide]);

  // const handleAgentModal = (name) => {
  //   const currentModalState = config[name];
  //   if (currentModalState) {
  //     const modalsVisibility = {...config, [name]: false}
  //     dispatch(updateAgentModals({ modalsVisibility, id: applicationId }))
  //     .then((res) => {
  //       dispatch(getCurrentEmployee({id: applicationId}))
  //     })
  //     .catch((error) => {
  //       console.error("Failed to update modal state:", error);
  //   });;
  //   }
  // }

  useEffect(() => {
    setActiveButton(state?.state ?? defaultButton);
  }, [state?.state]);

  // useEffect(() => {
  //   if (getAgentGuide && getBottomAgentGuide) {
  //     setOpenTopGuide(true);
  //   } else {
  //     setOpenTopGuide(false);
  //   }
  // }, [getAgentGuide, getBottomAgentGuide]);

  // const handleBatchModalUpdate = () => {
  //   const modalsVisibility = {
  //     ...config,
  //     agent: false,
  //     bottomAgent: false,
  //     bill: true,
  //   };

  //   dispatch(updateAgentModals({ modalsVisibility, id: applicationId })).then(
  //     () => {
  //       dispatch(getCurrentEmployee({ id: applicationId }));
  //     }
  //   );
  // };

  const fetchdata = async () => {
    if (!applicationId) {
      console.error(
        "No applicationId provided. fetchData function will not run."
      );
      return;
    }
    try {
      const response = await axios.get(
        `${URL}/dashboard/applications/${applicationId}/memory`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // const filteredData = response.data.data.filter(
      // (item) => item.purpose === "about"
      // );
      // setLoading(false);
      // setFetchedData(filteredData);
      // console.log('responsememory', response?.data?.data.length)
      if (response?.data?.data.length > 0) {
        dispatch(setMemoryExists(true));
        if (response?.data?.data.length === 1) {
          dispatch(showTestemployeeTour(true));
        }
      } else {
        dispatch(setMemoryExists(false));
        dispatch(showTestemployeeTour(false));
      }
    } catch (error) {
      // if (error.response.status === 401) {
      // navigate("/");
      // localStorage.clear();
      // }
      console.error("Error fetching data:", error);
      // setLoading(false);
    }
  };
  useEffect(() => {
    fetchdata();
  }, [appDetail]);

  // GET DRAFT

  // useEffect(() => {
  //   if(openTopGuide === true) {
  //     setOpenBottomGuide(false)
  //   } else {
  //     setOpenBottomGuide(true)
  //   }
  // }, [openTopGuide])

  const [publishModal, setPublishModal] = useState(false);
  const [propShowPopUp, setPropShowPopUp] = useState(false);

  const testAgent = () => {};
  return (
    <DashboardLayout title={"Assistant"} user={true}>
      <section className="flex font-figtree relative w-full">
        <div className="py-8 px-4 h-full  shrink-0">
          <ul className="flex flex-col gap-4 w-[154px]">
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={`stepsInAgent-${index} py-2.5 px-4 cursor-pointer rounded-md text-sm ${
                  activeButton === tab
                    ? "text-PRIMARY bg-[#FAFAFA]"
                    : "text-SECONDARY"
                }`}
                onClick={() => handleButtonClick(tab)}
              >
                <span>
                  {tab === "Features"
                    ? "Instructions"
                    : tab === "Integrations/Deploy"
                    ? "Test/Launch"
                    : tab}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <section className="w-full border-l border-[#F7F7F7]">
          {/* here  */}
          <ChangesBanner
            setPropShowPopUp={setPropShowPopUp}
            navigateToIntegrations={() =>
              setActiveButton("Integrations/Deploy")
            }
          />

          {showAgentNotification && activeButton === "Features" && (
            <section className="-mt-1 bg-[#FFF9E0] flex px-10 justify-between py-2 items-center">
              <div>
                <p className=" text-[#665301] text-sm font-medium">
                  3 change(s) detected!
                </p>
                <p className=" text-[#665301] text-xs font-medium">
                  We detected a few changes. You can{" "}
                  <span className=" underline cursor-pointer">
                    Test these here
                  </span>{" "}
                  or
                  <span className=" underline cursor-pointer">
                    Test on WhatsApp
                  </span>
                </p>
              </div>
              <p className=" text-[#665301] text-sm font-medium underline cursor-pointer">
                Publish Changes
              </p>
            </section>
          )}
          <section className=" px-10">
            {"Sales" === "Sales" && (
              // ! "Sales" === sales
              <section className="mt-7 mb-60 font-figtree relative">
                {/* <div className="flex w-full justify-between">
                  <div
                    className="bg-[#FDFDFD] flex w-[330px] border rounded-xl py-[2px] h-[34px] gap-[10px] select-none"
                    style={{ width: " fit-content" }}
                  >
                    <button
                      className={`font-medium  w-[129px]  rounded-lg text-center text-xs transform transition-transform outline-none ${
                        activeButton === "Memory"
                          ? " text-[black] bg-white shadow-custom"
                          : "text-[#7F7F81]"
                      }`}
                      onClick={() => handleButtonClick("Memory")}
                    >
                      Employee Memory
                    </button>
                    <button
                      className={`font-medium text-xs text-center w-[149px] rounded-lg transform transition-transform outline-none ${
                        activeButton === "Features"
                          ? " text-[black] bg-white shadow-custom"
                          : "text-[#7F7F81]"
                      }`}
                      onClick={() => handleButtonClick("Features")}
                    >
                      Employee Instructions
                    </button>
                    <button
                      className={`font-medium w-[110px]  rounded-lg text-center text-xs transform transition-transform outline-none ${
                        activeButton === "Settings"
                          ? " text-[black] bg-white shadow-custom"
                          : "text-[#7F7F81]"
                      }`}
                      onClick={() => handleButtonClick("Settings")}
                    >
                      Employee Settings
                    </button>
                    <button
                      className={`font-medium w-[151px]  rounded-lg text-center text-xs transform transition-transform outline-none ${
                        activeButton === "Integrations/Deploy"
                          ? " text-[black] bg-white shadow-custom"
                          : "text-[#7F7F81]"
                      }`}
                      onClick={() => handleButtonClick("Integrations/Deploy")}
                    >
                      Integration/Deploy
                    </button>
                  </div>
                </div> */}
                {activeButton === "Memory" && (
                  <Business
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                    // setShowUnsavedSettingsModal={setShowUnsavedSettingsModal}
                    // showUnsavedSettingsModal={showUnsavedSettingsModal}
                    // handleCloseUnsavedSettingsModal={handleCloseUnsavedSettingsModal}
                    // reroute={reroute}
                    // setReroute={setReroute}
                    // isFormModified={isFormModified}
                    // setIsFormModified={setIsFormModified}
                    // navigate={() => navigate(tx.location)}
                  />
                )}
                {activeButton === "Features" && (
                  <Service
                    setShowAgentNotification={setShowAgentNotification}
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                  />
                )}
                {activeButton === "Settings" && (
                  <Settings
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                    setShowUnsavedSettingsModal={setShowUnsavedSettingsModal}
                    showUnsavedSettingsModal={showUnsavedSettingsModal}
                    handleCloseUnsavedSettingsModal={
                      handleCloseUnsavedSettingsModal
                    }
                    reroute={reroute}
                    setReroute={setReroute}
                    isFormModified={isFormModified}
                    setIsFormModified={setIsFormModified}
                    // navigate={() => navigate(tx.location)}
                  />
                )}
                {activeButton === "Integrations/Deploy" && (
                  <Integration
                    applicationId={applicationId}
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                  />
                )}
              </section>
            )}
            {"Sales" === "Authentication" && (
              // ! "Sales" === Authentication
              <section className="mt-7 mb-60 font-figtree relative">
                <div
                  className="bg-[#FDFDFD] flex w-[200px] border rounded-xl py-[2px] h-[34px] gap-[10px] select-none"
                  style={{ width: " fit-content" }}
                >
                  <button
                    className={`font-medium text-xs text-center w-[100px] rounded-lg transform transition-transform outline-none ${
                      activeButton === "Features"
                        ? " text-[black] bg-white shadow-custom"
                        : "text-[#7F7F81]"
                    }`}
                    onClick={() => handleButtonClick("Features")}
                  >
                    Assistant Features
                  </button>
                  <button
                    className={`font-medium w-[110px]  rounded-lg text-center text-xs transform transition-transform outline-none ${
                      activeButton === "Settings"
                        ? " text-[black] bg-white shadow-custom"
                        : "text-[#7F7F81]"
                    }`}
                    onClick={() => handleButtonClick("Settings")}
                  >
                    Settings
                  </button>
                </div>
                {activeButton === "Service" && (
                  <Service
                    setShowAgentNotification={setShowAgentNotification}
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                  />
                )}
                {activeButton === "Settings" && (
                  <Settings
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                    setShowUnsavedSettingsModal={setShowUnsavedSettingsModal}
                    showUnsavedSettingsModal={showUnsavedSettingsModal}
                    handleCloseUnsavedSettingsModal={
                      handleCloseUnsavedSettingsModal
                    }
                    reroute={reroute}
                    setReroute={setReroute}
                    isFormModified={isFormModified}
                    setIsFormModified={setIsFormModified}
                    // navigate={() => navigate(tx.location)}
                  />
                )}
              </section>
            )}
            {"Sales" === "Customer Service" && (
              // ! "Sales" === Authentication
              <section className="mt-7 mb-60 font-figtree relative">
                <div
                  className="bg-[#FDFDFD] flex w-[200px] border rounded-xl py-[2px] h-[34px] gap-[10px] select-none"
                  style={{ width: " fit-content" }}
                >
                  <button
                    className={`font-medium text-xs text-center w-[100px] rounded-lg transform transition-transform outline-none ${
                      activeButton === "Memory"
                        ? " text-[black] bg-white shadow-custom"
                        : "text-[#7F7F81]"
                    }`}
                    onClick={() => handleButtonClick("Memory")}
                  >
                    Memory
                  </button>
                  <button
                    className={`font-medium w-[110px]  rounded-lg text-center text-xs transform transition-transform outline-none ${
                      activeButton === "Settings"
                        ? " text-[black] bg-white shadow-custom"
                        : "text-[#7F7F81]"
                    }`}
                    onClick={() => handleButtonClick("Settings")}
                  >
                    Assistant's Settings
                  </button>
                </div>
                {activeButton === "Memory" && (
                  <Business
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                    // setShowUnsavedSettingsModal={setShowUnsavedSettingsModal}
                    // showUnsavedSettingsModal={showUnsavedSettingsModal}
                    // handleCloseUnsavedSettingsModal={handleCloseUnsavedSettingsModal}
                    // reroute={reroute}
                    // setReroute={setReroute}
                    // isFormModified={isFormModified}
                    // setIsFormModified={setIsFormModified}
                  />
                )}
                {activeButton === "Settings" && (
                  <Settings
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                    setShowUnsavedSettingsModal={setShowUnsavedSettingsModal}
                    showUnsavedSettingsModal={showUnsavedSettingsModal}
                    handleCloseUnsavedSettingsModal={
                      handleCloseUnsavedSettingsModal
                    }
                    reroute={reroute}
                    setReroute={setReroute}
                    isFormModified={isFormModified}
                    setIsFormModified={setIsFormModified}
                    // navigate={() => navigate(tx.location)}
                  />
                )}
              </section>
            )}
          </section>
        </section>
      </section>
      <TestAgent
        activeButton={activeButton}
        propShowPopUp={propShowPopUp}
        setPropShowPopUp={setPropShowPopUp}
        gotoMemory={()=>{
          setActiveButton("Memory")
        }}
      />

      {/* <ModalPop isOpen={openTopGuide}>
        <TopGuide
          title="Your Assistant"
          handleClose={() => {
            // handleAgentModal("agent")
            setOpenTopGuide(false);
            setOpenBottomGuide(true); //fixed the lateness by triggering the nextModal
            // setTopAgentGuide();
          }}
          subtitle="Customize, build and train your Digital Assistant to suit your business specific needs"
          link="Continue to Memory"
          image={AgentA}
        />
      </ModalPop> */}

      {/* <ModalPop isOpen={openBottomGuide}>
        <BottomGuide
          title="Memory"
          subtitle="Upload necessary documents to teach your Digital Assistant all the basics about your business. You can also use our Templates if you don’t have any document prepared."
          link="Upload Document"
          secondSubtitle="We recommend uploading for the About, FAQs and Product/Services Tabs."
          handleClose={() => {
            // handleAgentModal("bottomAgent");
            handleBatchModalUpdate();
            setOpenBottomGuide(false);
            // setBottomAgentGuide();
          }}
          image={AgentB}
        />
      </ModalPop> */}
    </DashboardLayout>
  );
}

import React, { useState } from "react";
import axios from "axios";
import { CgSpinner } from "react-icons/cg";
import TokenService from "../services/token";
import { SuccessMessage } from "../Toast-Messages/Toastmessages";
function DeleteInstructionModal({
  handleClose,
  id,
  instructionId,
  deleteInstructionFromTable,
  refresh,
}) {
  const [loading, setLoading] = useState(false);
  const agentData = localStorage.getItem("wano_agent");
  const agentDetails = JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();
  const [success, setSuccess] = useState();
  const deleteInstruction = async () => {
    try {
      setLoading(true);

      await axios.delete(
        `${URL}/dashboard/applications/${applicationId}/category/${id}/instructions/${instructionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccess("Instruction deleted successfully!");
      if (refresh) {
        refresh();
        handleClose();
      } else {
        setTimeout(() => {
          window.history.back();
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(true);
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center ">
      <div className="bg-white p-8 w-[496px] text-center rounded-lg shadow-lg">
        {/* Your content goes here */}
        <h1 className="text-2xl font-medium mt-8">Delete Instruction</h1>
        <p className="text-[#828282] text-sm mt-12">
          Are you sure you want to delete this instruction from this category?
          Doing this will remove the instruction permanently.
        </p>
        <div className="w-full grid grid-cols-2 items-center justify-end mt-14 gap-3">
          <button
            onClick={handleClose}
            className="cursor-pointer outline-none py-[10px] w-auto px-5 border border-[#D0D5DD] rounded-lg text-[#828282] text-xs font-semibold"
          >
            Cancel
          </button>
          {loading ? (
            <button className="outline-none py-[10px] w-auto px-5 bg-[#AF202D] rounded-lg text-white text-xs font-semibold flex justify-center">
              <CgSpinner className=" animate-spin text-lg " />
            </button>
          ) : (
            <button
              onClick={deleteInstruction}
              className="cursor-pointer outline-none py-[10px] w-auto px-5 bg-[#AF202D] rounded-lg text-white text-xs font-semibold"
            >
              Delete
            </button>
          )}
        </div>
      </div>
      {success && <SuccessMessage success={success} />}
    </div>
  );
}

export default DeleteInstructionModal;

import React, { useEffect, Fragment, useState } from "react";
import axios from "axios";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { IoIosArrowForward } from "react-icons/io";
import Logo from "../Assets/img/Logo.png";
import MiniLogo from "../Assets/wano logo 1 1.png";
// import AskAgent from '../Assets/img/ask-agent.png'
// import AskAgentActive from '../Assets/img/active-ask.png'
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  WalletIcon,
  WalletActive,
  AgentIcon,
  PluginActive,
  AnalyticsIcon,
  CustomerIcon,
  DashboardIcon,
  DashboardIconActive,
  SettingsIcon,
  AgentIconActive,
  AnalyticsView,
  CustomersView,
  ConversationIcon,
  ConversationView,
  SettingsView,
  SupportActive,
  SupportIcon,
  ArrowRight,
  ArrowRightBlack,
  Padlock,
  AddBlue,
  Plugins,
  SwitchRoleIcon,
  AskAgentIcon,
  AskAgentIconActive,
} from "../Assets/svg";
import { Menu, Transition } from "@headlessui/react";
import Name from "./modals/dashboard/Name";
import ModalPop from "./modalPop";
import ChooseServices from "../Agent/ServiceLayout/Modal/ChooseService";
import { createApplication } from "../features/application/createApplicationSlice";
import { useDispatch, useSelector } from "react-redux";
import TokenService from "../services/token";
import SwitchAgentRole from "./modals/switch/SwitchAgentRole";
import ConfirmSwitch from "./modals/switch/ConfirmSwitch";
import {
  getApplicationById,
  setAgentName,
  setAgent,
  setConfig,
} from "../features/application/getApplicationByIdSlice";
import AgentSetUp from "./modals/agentGuide/AgentSetUp";
import { DoubleArrowIcon } from "../Assets/icons";
import { getAllPlugins } from "../features/plugins/getAllPluginsSlice"; //added this
import toggleIcon from "../Assets/img/toggleIcon.svg";
import AgentServiceSelect from "./modals/agentGuide/AgentService2";
import UnsavedSettings from "@/../Settings/UnsavedSettings";
export default function Aside({ data, client, business, setActiveButton,
  setShowUnsavedSettingsModal,
  showUnsavedSettingsModal, }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const active = location.pathname;
  const navigate = useNavigate();
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();
  const [openServicesModal, setOpenServicesModal] = useState(false);
  const [openAgentServiceModal, setOpenAgentServiceModal] = useState(false);
  const [success, setSuccess] = useState();
  const [openNameModal, setOpenNameModal] = useState(false);
  const getKycStatus = localStorage.getItem("Kyc");
  const agentData = localStorage.getItem("wano_agent");
  const localAgent = (agentData && JSON.parse(agentData)) || {};
  const agent = data && data?.length >= 1 ? data[0] : {}; //data here is agentDetails coming from the main dashboard appDetail= state.getApplicationByUserId.data.data
  const agentDetails =
    Object.keys(localAgent).length === 0 ? agent : localAgent;
  const [selectedAgent, setSelectedAgent] = useState(agentDetails);
  const [getServiceData, setGetServiceData] = useState(null);
  const [openAgent, setOpenAgent] = useState(false);
  const [change, setChange] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [done, setDone] = useState(false);

  // const [sidebarExtended, setSidebarExtended] = useState(
  //   active.includes("/dashboard") ||
  //     active.includes("/plugins") ||
  //     active.includes("/agent") ||
  //     active.includes("/customers") ||
  //     active.includes("/top") ||
  //     active.includes("/settings") ||
  //     active.includes("/support") ||
  //     active.includes("/top") ||
  //     active.includes("/ask-agent")
  //     ? false
  //     : true
  // );
  const [sidebarExtended, setSidebarExtended] = useState(true);

  const appId = agentData?.id; //added this line to get the application id
  const navs = [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      activeIcon: <DashboardIconActive />,
      link: "/dashboard",
    },
    {
      name: "Assistant",
      icon: <AgentIcon />,
      activeIcon: <AgentIconActive />,
      link: "/agent",
    },
    // {
    //   name: "Ask Assistant",
    //   icon: <AskAgentIcon />,
    //   activeIcon: <AskAgentIconActive />,
    //   link: "/ask-agent",
    // },
    // {
    //   name: "Analytics",
    //   icon: <AnalyticsIcon />,
    //   activeIcon: <AnalyticsView />,
    //   link: "/analytics",
    // },
    {
      name: "Customers",
      icon: <CustomerIcon />,
      activeIcon: <CustomersView />,
      link: "/customers",
    },
    {
      name: "Conversations",
      icon: <ConversationIcon />,
      activeIcon: <ConversationView />,
      link: "/conversations",
    },
    {
      name: "App Store",
      icon: <Plugins />,
      activeIcon: <PluginActive />,
      link: "/plugins",
    },
  ];

  const bottomNavs = [
    {
      name: "Billing",
      icon: <WalletIcon />,
      activeIcon: <WalletActive />,
      link: "/top-up",
    },
    {
      name: "Settings",
      icon: <SettingsIcon />,
      activeIcon: <SettingsView />,
      link: "/settings",
    },
    {
      name: "Support",
      icon: <SupportIcon />,
      activeIcon: <SupportActive />,
      link: "/support",
    },
  ];


  const pathToButtonValueMap = {
    "/dashboard": "dashboard", 
    "/agent": "agent",
    "/ask-agent": "askAgent",
    "/customers": "customers",
    "/plugins": "plugins",
    "/top-up": "billing",
    "/settings": "settings",
    "/support": "support",
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const activeButtonValue = Object.entries(pathToButtonValueMap).find(([pathKey]) => 
      currentPath.startsWith(pathKey)
    )?.[1]; 
  
    if (activeButtonValue) {
      setActiveButton(activeButtonValue);
    }
  }, [location.pathname, setActiveButton]);

  useEffect(() => {
    const element = document.querySelector(".step-2");
    // setSidebarExtended(element !== null);
  }, [sidebarExtended]);

  const removeStepClass = (stepClass) => {
    const element = document.querySelector(`.${stepClass}`);
    if (element) {
      element.classList.remove(stepClass);
    }
  };
  
  

  const handleAgent = (application) => {
    setSelectedAgent(application);
    dispatch(setAgentName(""));
    dispatch(setAgent(application));
    dispatch(setConfig(application.config))
    localStorage.setItem("agentType", application.type);
    localStorage.setItem("wano_agent", JSON.stringify(application));
    sessionStorage.removeItem("serviceData");
    sessionStorage.removeItem("choosenServiceData");
    navigate("/dashboard?switchEmployee=true");
    // window.location.reload(); //removed this because changing Agent shouldn't cause a reload
  };

  const handleAgentClose = () => {
    setOpenNameModal(false);
    // setOpenAgentServiceModal(true)
  };

  const toggleSidebar = () => {
    setSidebarExtended(!sidebarExtended);
  };

  const { loading } = useSelector((state) => state.createApplication);
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );

  // console.log(getAgentApplication, "SASO")

  // const agentType = getAgentApplication?.data?.data[0]?.type || null;
  //added this line to double check the status of the agentType
  // const CurrentAgentType = getAgentApplication?.data?.data.find((agent) => agent.id === JSON.parse(localStorage.getItem("wano_agent")).id )?.type|| null;
  //end
  const newAgent = getAgentApplication?.agent || null;
  const newagentType = newAgent?.draft?.type ? newAgent?.draft?.type :newAgent.type || null;
  const reset = useSelector((state) => state.agentReset);
  // console.log(reset, "state from reset in Aside.jsx")

  const submitForm = (values) => {
    const data = {
      name: values.agentName,
      description: values.description,
      type: "banking",
      sale_agent_name: values.agentName,
    };

    dispatch(createApplication(data)).then(() => {
      setSuccess("Application Created!");
      setOpenNameModal(false);
      setTimeout(() => {
        setSuccess(false);
        setOpenServicesModal(true);
      }, 1000);
    });
  };

  // const fetchData = async () => {
  //   try {
  //       const getServiceresponse = await axios.get(`${URL}/module`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //       });

  //       setGetServiceData(getServiceresponse.data.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  useEffect(() => {
    if(!appId) return;
    dispatch(getApplicationById());
  }, [reset?.loading]);

  // useEffect(() => {
  //   if (reset || ){
  //     dispatch(getApplicationById())
  //   }
  // }, [reset])

  const showCloseButton = true;

  const handleFetchUpdate = () => {
    console.log("fetch agent service");
  };

  useEffect(() => {
    if (change === true) {
      setOpenConfirmModal(true);
    }
  }, [change]);

  
  const [isFormModified, setIsFormModified] = useState(false)
  useEffect(() => {
    const isModified = localStorage.getItem("isFormModified") === 'true';
    setIsFormModified(isModified);
  }, [isFormModified])

  const [path, setPath] = useState()
  useEffect(() => {
    const path = localStorage.getItem("path")
    setPath(path)
  },[path])




  return (
    <div
      className={`h-screen flex flex-col justify-between bg-white px-4 pt-10 transition-all border-r border-[F7F7F7] ${
        sidebarExtended ? "w-[230px]" : "w-[68px]"
      } `}
      onMouseEnter={() => {
        setSidebarExtended(true);
      }}
      onMouseLeave={() => {
        setSidebarExtended(false);
      }}
    >
      {/* <BackdropWithSpinner isLoading={isLoading} /> */}
      <section className="flex flex-col gap-4">
        {/* Logo */}
        <div
          className="flex justify-between w-full cursor-pointer"
          onClick={toggleSidebar}
        >
          <img
            src={sidebarExtended ? Logo : MiniLogo}
            className="h-[25px]"
            alt="logo"
          />
          <div
            className={`flex ${sidebarExtended ? "" : "hidden"} `}
            onClick={toggleSidebar}
          >
            <img
              src={toggleIcon}
              alt=""
              className={`flex ${sidebarExtended ? "rotate-180" : ""} `}
            />
          </div>
        </div>
        <div
          className={`flex ${sidebarExtended ? "hidden" : "justify-center"} `}
          onClick={toggleSidebar}
        >
          <img
            src={toggleIcon}
            alt=""
            className={`flex ${sidebarExtended ? "rotate-180" : ""} `}
          />
        </div>

        {newAgent?.name && active !== "/getstarted" && (
          <Menu as="div" className="relative mb-1">
            <Menu.Button
              className={`flex w-full cursor-pointer items-center border-[#E5E5E5] border rounded-lg ${
                sidebarExtended
                  ? "py-2 px-4 justify-between "
                  : "py-1.5 px-2.5 justify-center"
              }`}
            >
              <div className="flex gap-2 items-center">
                <div className="w-4 h-4 bg-[#1774FD] rounded-lg flex justify-center items-center">
                  <p className="capitalize text-white text-[7.5px] font-medium">
                    {newAgent?.name?.charAt(0)}
                  </p>
                </div>
                {sidebarExtended && (
                  <p className="break-all max-w- capitalize text-xs text-[#121212] truncate">
                    {newAgent?.name.substring(0, 20) + (newAgent?.name.length > 20 ?'...':'')}
                  </p>
                )}
              </div>
              {sidebarExtended && <ArrowRight />}
            </Menu.Button>

            {/* Toggle agent */}
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="z-[9999] absolute left-0 top-12 w-[198px] rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                <div className="py-[18px] px-4">
                  <p className="mb-6 text-SECONDARY text-sm">Select Assistant</p>
                  {data?.map((d, id) => {
                    return (
                      <Menu.Item key={id}>
                        <div
                          onClick={() => handleAgent(d)}
                          className="cursor-pointer mb-2 py-[9px] px-3 border border-[#E5E5E5] rounded-[6px] flex items-center justify-between"
                        >
                          <div className="flex gap-2 items-center">
                            <div className="w-4 h-4 bg-PRIMARY rounded-lg flex justify-center items-center">
                              <p className="capitalize text-white text-[7.5px] font-medium">
                                {d?.name?.charAt(0)}
                              </p>
                            </div>
                            <p className="text-xs text-[#121212] capitalize">
                              {d?.name}
                            </p>
                          </div>
                          <ArrowRightBlack />
                        </div>
                      </Menu.Item>
                    );
                  })}
                  <Menu.Item>
                    <div
                      // onClick={() => setOpenNameModal(!openNameModal)}
                      onClick={() => navigate("/customize-assistant?old=true")}
                      className="cursor-pointer bg-[#F9F9F9] rounded-lg h-8 py-[9px] flex justify-center items-center gap-2"
                    >
                      <AddBlue />
                      <p className="text-xs text-PRIMARY font-medium">
                        Add New Assistant
                      </p>
                    </div>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
        {
          !newAgent?.name && (
            <div>
            {/* <p className="text-sm px-4">
              You have no assistant
            </p> */}
          </div>

          )
        }
    
             <div
                      // onClick={() => setOpenNameModal(!openNameModal)}
                      onClick={() => navigate("/customize-assistant?old=true")}
                      className="cursor-pointer bg-[#F9F9F9] rounded-lg h-8 py-[9px] flex justify-center items-center gap-2"
                    >
                      {
                        sidebarExtended &&(
                          <AddBlue />
                        )
                      }
                  
                      <p className="text-xs text-PRIMARY font-medium">
                        Add 
                        {sidebarExtended ? ' New Assistant':''}
                        
                       
                      </p>
                    </div>

        {/* Navs */}
        <ul className="flex flex-col gap-[15px] text-[#A7A7A7] w-full">
          {navs.map((nav, index) => {
            localStorage.setItem("activeButton", active === nav.link)
            const saveToStorage = () => {
              localStorage.setItem("navLink", nav.link)
              if(isFormModified){
                localStorage.setItem("showUnsavedSettingsModal", true)
              }
            }
            return (
              // <div key={nav.name} onClick={() => {saveToStorage()}}>
                <Link to={isFormModified? path: nav.link} key={nav.name}>
                {/* <Link to= {nav.link} key= {nav.name}> */}
                  <div
                    className={`${index == 1? "step-2" : ""} py-[7px] rounded-md duration-100 transition ease-in-out flex items-center gap-2 font-normal hover:bg-[#FAFAFA] hover:text-[#1774FD] cursor-pointer w-full text-sm truncate ${
                      active === nav.link ? "bg-[#FAFAFA] text-[#1774FD]" : ""
                    } ${sidebarExtended ? "px-4" : "px-2.5"}`}
                    // onClick={() => nav.link === "/plugins" && dispatch(getAllPlugins({appId}))}
                  >
                    <span className="shrink-0">
                      {" "}
                      {active === nav.link ? nav.activeIcon : nav.icon}
                    </span>

                    {sidebarExtended && 
                      (
                        <>
                          {nav.name}
                          {nav.name.includes("App Store")  && <span style={{filter: "drop-shadow(0px 0px 2.4px #86B7FF42)"}} className="text-xs text-[#1774FD] rounded-[3px] py-[3px] px-[9px] font-medium ml-1 bg-white">Beta</span>}
                        </> 
                        //&& newagentType === "customer"
                      )
                    }
                  </div>
                </Link>
              // </div>
            );
          })}
          {/* <Link to={"/dashboard"}>
            <li
              className={`px-4 py-[7px] rounded-md duration-100 transition ease-in-out flex items-center gap-2 text-[14px] hover:bg-[#FAFAFA]  hover:text-[#1774FD] 
                            hover:font-bold cursor-pointer p-2 w-full ${
                              active === "/dashboard"
                                ? "font-bold bg-[#FAFAFA] text-[#1774FD]"
                                : "font-medium"
                            } `}
            >
              {active === "/dashboard" ? (
                <DashboardIconActive />
              ) : (
                <DashboardIcon />
              )}{" "}
              Dashboard
            </li>
          </Link>
          <Link to={"/agent"}>
            {" "}
            <li
              className={`px-4 py-[7px] text-[14px] rounded-md duration-100 transition ease-in-out flex items-center gap-2 hover:bg-[#FAFAFA]  hover:text-[#1774FD] 
                        hover:font-semibold cursor-pointer p-2 w-full ${
                          active.includes("/agent")
                            ? "font-bold bg-[#FAFAFA] text-[#1774FD]"
                            : "font-medium"
                        }`}
            >
              {active.includes("/agent") ? <AgentIconActive /> : <AgentIcon />}{" "}
              Employee
            </li>
          </Link>
          <Link to={"/ask-agent"}>
            {" "}
            <li
              className={`px-4 py-[7px]  text-[14px] rounded-md duration-100 transition ease-in-out flex items-center gap-2 hover:bg-[#FAFAFA]  hover:text-[#1774FD] 
                        hover:font-bold cursor-pointer p-2 w-full ${
                          active === "/ask-agent"
                            ? "font-bold bg-[#FAFAFA] text-[#1774FD]"
                            : "font-medium"
                        }`}
            >
              {" "}
              {active === "/ask-agent" ? (
                <img src={AskAgentActive} />
              ) : (
                <img src={AskAgent} />
              )}{" "}
              Ask Employee
            </li>
          </Link>
           <Link to={"/analytics"}>
              <li
                className={`px-4 py-[7px] text-[14px] rounded-md duration-100 transition ease-in-out flex items-center gap-2 hover:bg-[#FAFAFA]  hover:text-[#1774FD] 
                        hover:font-bold cursor-pointer p-2 w-full ${
                          active === "/analytics"
                            ? "font-bold bg-[#FAFAFA] text-[#1774FD]"
                            : "font-medium"
                        }`}
              >
                {active === "/analytics" ? (
                  <AnalyticsView />
                ) : (
                  <AnalyticsIcon />
                )}{" "}
                Analytics
              </li>
            </Link> 
          <Link to={"/customers"}>
            <li
              className={`px-4 py-[7px] text-[14px] rounded-md duration-100 transition ease-in-out flex items-center gap-2 hover:bg-[#FAFAFA]  hover:text-[#1774FD] 
                        hover:font-bold cursor-pointer p-2 w-full ${
                          active === "/customers"
                            ? "font-bold bg-[#FAFAFA] text-[#1774FD]"
                            : "font-medium"
                        }`}
            >
              {active === "/customers" ? <CustomersView /> : <CustomerIcon />}{" "}
              Customers
            </li>
          </Link>
          <Link to={"/plugins"}>
            <li
              className={`px-4 py-[7px] text-[14px] rounded-md duration-100 transition ease-in-out flex items-center gap-2 hover:bg-[#FAFAFA]  hover:text-[#1774FD] 
                        hover:font-bold cursor-pointer p-2 w-full ${
                          active === "/plugins"
                            ? "font-bold bg-[#FAFAFA] text-[#1774FD]"
                            : "font-medium"
                        }`}
            >
              {active === "/plugins" ? <PluginActive /> : <Plugins />} Plugins
            </li>
          </Link>
          <Link to={"/top-up"} className="mt-[60%]">
            <li
              className={`px-4 py-[7px] text-[14px] rounded-md duration-100 transition ease-in-out flex items-center gap-2 hover:bg-[#FAFAFA]  hover:text-[#1774FD] 
                        hover:font-bold cursor-pointer p-2 w-full ${
                          active === "/top-up"
                            ? "font-bold bg-[#FAFAFA] text-[#1774FD]"
                            : "font-medium"
                        }`}
            >
              {active === "/top-up" ? <WalletActive /> : <WalletIcon />} Billing
            </li>
          </Link>
          <Link to={"/settings"}>
            <li
              className={`px-4 py-[7px] text-[14px] rounded-md duration-100 transition ease-in-out flex items-center gap-2 hover:bg-[#FAFAFA]  hover:text-[#1774FD] 
                        hover:font-bold cursor-pointer p-2 w-full ${
                          active === "/settings"
                            ? "font-bold bg-[#FAFAFA] text-[#1774FD]"
                            : "font-medium"
                        }`}
            >
              {active === "/settings" ? <SettingsView /> : <SettingsIcon />}{" "}
              Settings
            </li>
          </Link>
          <Link to={"/support"}>
            <li
              className={`px-4 py-[7px] text-[14px] rounded-md duration-100 transition ease-in-out flex items-center gap-2 hover:bg-[#FAFAFA]  hover:text-[#1774FD] 
                        hover:font-bold cursor-pointer p-2 w-full ${
                          active === "/support"
                            ? "font-bold bg-[#FAFAFA] text-[#1774FD]"
                            : "font-medium"
                        }`}
            >
              {active === "/support" ? <SupportActive /> : <SupportIcon />}{" "}
              Support
            </li>
          </Link> */}
        </ul>
      </section>

      <section>
        <ul className="flex flex-col gap-[15px] text-[#A7A7A7] w-full">
          {bottomNavs.map((nav) => {
            const saveToStorage = () => {
              localStorage.setItem("navLink", nav.link)
              if(isFormModified){
                localStorage.setItem("showUnsavedSettingsModal", true)
              }
            };
            return (
              // <div onClick={() => saveToStorage()} key={nav.name}>
                <Link to={isFormModified? path: nav.link} key={nav.name}>
                  <li
                    className={` py-[7px] rounded-md duration-100 transition ease-in-out flex items-center gap-2 font-normal hover:bg-[#FAFAFA] hover:text-[#1774FD] cursor-pointer w-full text-sm truncate ${
                      active === nav.link ? "bg-[#FAFAFA] text-[#1774FD]" : ""
                    } ${sidebarExtended ? "px-4" : "px-2.5"}`}
                  >
                    <span className="shrink-0">
                      {" "}
                      {active === nav.link ? nav.activeIcon : nav.icon}
                    </span>

                    {sidebarExtended && nav.name}
                  </li>
                </Link>
              // </div>
            );
          })}

          {/* Change Role */}
          {/* {newagentType && (
            <div
              className={`${
                sidebarExtended ? "px-3" : "flex items-center justify-center"
              } py-2 cursor-pointer border mt-[15px] rounded-lg border-solid border-[#E5E5E5]`}
              onClick={() => setOpenAgent(true)}
            >
              <div className="flex gap-2 items-center">
                <SwitchRoleIcon />
                {sidebarExtended && (
                  <div className="flex flex-col">
                    <p className="text-[#121212] text-xs truncate">
                      Change Assistant Role
                    </p>
                    <p className="text-[#808080] text-[10px] capitalize">
                      Current Role:{" "}
                      {`${newagentType ? newagentType : ""} AI`}{" "}
     
                    </p>
                  </div>
                )}
              </div>
            </div>
          )} */}
        </ul>

        <section className="flex-1 flex flex-col text-base pb-12 justify-end gap-2">
          <div
            className={`${
              sidebarExtended ? "px-4" : "justify-center"
            } py-2 gap-[10px] border rounded-lg border-[#E6E7EC] items-center mt-4 ${
              active.includes("/getstarted") ? "flex" : "hidden"
            }`}
          >
            <div className="w-6 h-6 bg-PRIMARY rounded-md justify-center items-center flex">
              <p className="text-white text-[16px] font-bold capitalize">
                {client?.first_name[0]}
              </p>
            </div>
            {sidebarExtended && (
              <div className="">
                <p className="text-xs text-[#A7A7A7] capitalize truncate">
                  {client?.first_name} {client?.last_name}
                </p>
                <p className="text-sm text-[#535353] capitalize truncate">
                  {business?.name}
                </p>
              </div>
            )}
          </div>
        </section>
      </section>

      <ModalPop isOpen={openNameModal}>
        <AgentSetUp
          agents={data}
          handleNextModal={() => setOpenAgentServiceModal(true)}
          handleClose={handleAgentClose}
          setOpenNameModal={setOpenNameModal}
          setDone={setDone}
          showCloseButton={showCloseButton}
        />
      </ModalPop>

      <ModalPop isOpen={openAgentServiceModal}>
        <AgentServiceSelect
          agents={data}
          handleClose={() => setOpenAgentServiceModal(false)}
          previousModal={() => setOpenNameModal(true)}
          setDone={setDone}
          showCloseButton={showCloseButton}
        />
      </ModalPop>

      {/* handleSubmitAgent={submitForm} loading={loading}*/}

      <ModalPop isOpen={openServicesModal}>
        <ChooseServices
          handleClose={() => setOpenServicesModal(false)}
          getServiceData={getServiceData}
          handleFetchUpdate={handleFetchUpdate}
          choosenServiceData={[null]}
        />
      </ModalPop>

      <ModalPop isOpen={openAgent}>
        <SwitchAgentRole
          handleClose={() => setOpenAgent(false)}
          setChange={setChange}
        />
      </ModalPop>
      <ModalPop isOpen={openConfirmModal}>
        <ConfirmSwitch
          handleClose={async() => {setOpenConfirmModal(false)
          // 'get correct role'
             await dispatch(getApplicationById());
          

            // if (res.payload.data.length) {
            //   localStorage.setItem(
            //     "wano_agent",
            //     JSON.stringify(res.payload.data[0])
            //   ); //added this
            // }

          }}
          setChange={setChange}
        />
      </ModalPop>
      {/* <ModalPop isOpen={isFormModified}>
        <UnsavedSettings
          handleClose={() => {localStorage.setItem("isFormModified", false); setIsFormModified(false)}} 
          // // handleSave={() => {saveChanges()}}
          // close={() => closeModal()}
          // loading={loading}
        />
      </ModalPop> */}
    </div>
  );
}

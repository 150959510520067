import { Link, useNavigate } from "react-router-dom";
import WanoAi from "../Assets/img/ask-ai.png";
import Card from "../component/Card";
import { UserContext } from "../context/UserContext";
import { useContext } from "react";

export default function AnalyticsContentSales({ blur, data }) {
  const { userType } = useContext(UserContext);
  const navigate = useNavigate();
  return (
    <main className=" mt-10 font-figtree  grid grid-cols-4 grid-rows-5 gap-4">
      <Card
        text={"These are the total conversations your assistant has engaged in."}
        title={"Total Conversation"}
        number={data?.totalConversations || 0}
        gridStyle={"row-span-2"}
        empty={"pb-[40px]"}
        agentPolygon={true}
      ></Card>
      <Card
        text={"This is the average number of messages sent per conversation"}
        title={"Average Message"}
        empty={"pb-[40px]"}
        number={data?.averageMessagesPerConversation || 0}
        gridStyle={"row-span-2"}
      ></Card>

      <Card
        text={"This is the total new users from your assistant "}
        title={"Total New Users"}
        empty={"pb-[40px]"}
        number={data?.totalNewUsers || 0}
        gridStyle={"row-span-2"}
      ></Card>

      <Card
        text={"This is the total users from your assistant"}
        title={"Total Users"}
        empty={"pb-[40px]"}
        number={data?.totalUsers || 0}
        gridStyle={"row-span-2"}
      ></Card>

      <Card
        text={"This is the average conversations sent per user"}
        title={"Average Conversations Per User"}
        empty={"pb-[40px]"}
        number={data?.averageConversationsPerUser || 0}
        gridStyle={"row-span-2"}
      ></Card>

      <Card
        text={"This is the average message sent per conversation"}
        title={"Average Messages Per Conversation"}
        empty={"pb-[40px]"}
        number={data?.averageMessagesPerConversation || 0}
        gridStyle={"row-span-2"}
      ></Card>
      {/* <Card
        text={
          "Ratings provided by your customers based on responses from your agent."
        }
        empty={"pb-[20px]"}
        title={"Conversation Rating"}
        progress={true}
        gridStyle={"row-span-2"}
        helpful={60}
        NotHelpful={40}
        FirstProgressbartext={"Helpful"}
        SecondProgressbartext={"Not Helpful"}
      ></Card>  */}
      <section className="w-full relative row-span-2">
        <div className="h-[140px] w-full rounded-[10px] py-[12px] px-[9px] bg-gradient-to-r from-purple-500 via-blue-500 to-pink-300">
          <p className="text-white text-[16px] font-medium pt-2 pr-[10px]">
            {"Sales" === "Sales" && "Get insights from your sales agent"}
            {"Sales" === "Customer Service" &&
              "Get insights from your customer service agent."}
          </p>
          <Link
            to="/ask-agent"
            className="cursor-pointer mt-5 flex justify-between float-right rounded-lg bg-white items-center px-[12px] py-[9px] w-[133.84px]"
          >
            <img src={WanoAi} alt="wano-ai" className=" w-[15.84px]" />
            <div>
              <p className="text-right text-[12px] justify-end">
                Ask Your Assistant
              </p>
            </div>
          </Link>
        </div>
      </section>
      {/* <Card
        text={"Total number of new users interacting with your agent."}
        empty={"pb-[40px]"}
        title={"New Users"}
        number={"143"}
        gridStyle={"row-span-2 row-start-3"}
      ></Card>
      <Card
        text={"A glance at your customers most engaged features"}
        empty={"pb-[30px]"}
        title={"Features"}
        progress={true}
        gridStyle={"row-span-2 row-start-3"}
        helpful={60}
        NotHelpful={40}
        FirstProgressbartext={"Send Money"}
        SecondProgressbartext={"Pay Utility"}
        blur={blur}
      ></Card> */}
    </main>
  );
}

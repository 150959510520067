import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../services/api";
import { appUrls } from "../../services/urls";

const initialState = {
    loading: false,
    data: [],
    error: "",
}

export const fetchWalletHistory = createAsyncThunk(
    "history/fetchWalletHistory",
    async(values, { rejectWithValue }) => {
        try {
            const res = await api.get(appUrls?.WALLET_HISTORY_URL)
            return res?.data
            console.log(res?.data, "transaction response")

        } catch (error) {
            // console.log(error)
            return rejectWithValue(error?.response?.data?.message)

        }

    }
);


const walletFundingHistorySlice = createSlice({
    name: "history",
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchWalletHistory.pending, state => {
            state.loading = true
        });
        builder.addCase(fetchWalletHistory.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(fetchWalletHistory.rejected, (state, action) => void(
            state.loading = false,
            state.data = "",
            state.error = action.payload
        ))
    }
})

export default walletFundingHistorySlice.reducer
import React, { useState, useEffect } from 'react'
import { Form, Formik } from "formik"
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup"
import { CgSpinner } from 'react-icons/cg';

import { bulkTopUp } from '../../../features/wallet/walletBulkSlice';
import { ErrorMessage, SuccessMessage } from '../../../Toast-Messages/Toastmessages';
import { getProfile } from '../../../features/profile/getProfileSlice';

const Bulk = ({ handleClose, setShow }) => {
    const [bulkPurchaseSuccess, setBulkPurchaseSuccess] = useState(false);
    const [bulkPurchaseError, setBulkPurchaseError] = useState();

    const formValidationSchema = Yup.object().shape({
        name: Yup.string().required(),
        companyName: Yup.string().required(),
        email: Yup.string().email().required(),
        message: Yup.string().required()

    })

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProfile());
      }, [dispatch]);

    const { error, loading } = useSelector(state => state.bulkTopUp)
    const getUserProfile = useSelector((state) => state.getProfile);
    // console.log(getUserProfile, "this is the getuserProfile data from personal.jsx")

    const profile = getUserProfile?.data?.data;
    // console.log(profile, "profile")
    const getUserBusiness = useSelector(state => state.getBusiness)
    const business = getUserBusiness?.data?.data[0]
    // console.log(business, "business")

    const submitForm = (values, actions) => {
        const data = {
            name: values?.companyName,
            email: values?.email,
            message: values?.message
        }

        dispatch(bulkTopUp(data))
        .then((res) => {
            // console.log(res, "snow")
            if(res?.meta?.requestStatus === "fulfilled") {
                setBulkPurchaseSuccess(true);
                setTimeout(() => {
                    handleClose()
                }, 2000);
            } else {
                setBulkPurchaseError(true)
            }
            // if(res?.meta?.requestStatus === "fulfilled") {
            //     setTimeout(() => {
            //         handleClose()
            //     }, 2000);
            // } 
        });
        actions.resetForm();

    };

    const removeSuccessNotification = () => {
        setTimeout(() => {
            setBulkPurchaseSuccess(false)
        }, 1000);
      }

  
    useEffect(() => {
        removeSuccessNotification()
    }, [bulkPurchaseSuccess])

    const removeErrorNotification = () => {
        setTimeout(() => {
            setBulkPurchaseError(false)
        }, 1000);
    }

    useEffect(() => (
        removeErrorNotification()
    ),[bulkPurchaseError])
  
    const message =  "Request Sent Successfully"

    const Close = () => {
        setShow(false)
        handleClose()
    }

  return (
    <div className='bg-WHITE-_100 w-[578px] h-[630px] py-[30px] mt-[40px] rounded-lg'>
        <p className='text-center text-[28px] font-medium text-[#101828]'>We'll Reach You</p>
        <div className="w-[473px] mt-[25px]  mx-auto">
            <Formik
                initialValues={{
                    name: `${profile?.first_name} ${profile?.last_name}`,
                    companyName: `${business?.name}`,
                    email: `${business?.business_mail}`,
                    message: ""
                }}
                validationSchema={formValidationSchema}
                onSubmit={(values, actions) => {
                    // console.log(values, "otp")
                    window.scrollTo(0, 0)
                    submitForm(values, actions)
                    
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    dirty,
                    isValid,
                    setFieldValue,
                    errors,
                    touched,
                    // setFieldTouched,
                    values,
                }) => (
                    <Form onSubmit={handleSubmit} className="flex justify-center">
                        <div className='flex flex-col items-center gap-6 '>
                            <div className='w-[455px] flex flex-col'>
                                <div className="flex flex-col lg:w-[455px] gap-2">
                                    <label className="label text-left text-[#101828] text-xs font-normal">Name</label>
                                    <input 
                                        name='name'
                                        disabled
                                        type ="text" 
                                        className='outline-none  w-[455px]' 
                                        value={`${profile?.first_name} ${profile?.last_name}`} 
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.name && touched.name ? (
                                    <div className='text-RED-_100 text-xs'>{errors.name}</div>
                                    ) : null}
                            </div>
                            <div className='w-[455px] flex flex-col'>
                                <div className="flex flex-col lg:w-[455px] gap-2">
                                    <label className="label text-left text-[#101828] text-xs font-normal">Company Name</label>
                                    <input 
                                        name='companyName'
                                        disabled
                                        type ="text" 
                                        className='outline-none  w-[455px]' 
                                        value={business?.name} 
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.companyName && touched.companyName ? (
                                    <div className='text-RED-_100 text-xs'>{errors.companyName}</div>
                                    ) : null}
                            </div>
                            <div className='w-[455px] flex flex-col'>
                                <div className="flex flex-col lg:w-[455px] gap-2">
                                    <label className="label text-left text-[#101828] text-xs font-normal">Email Address</label>
                                    <input 
                                        name='email'
                                        disabled
                                        type ="text" 
                                        className='outline-none  w-[455px]' 
                                        value={business?.business_email} 
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.email && touched.email ? (
                                    <div className='text-RED-_100 text-xs'>{errors.email}</div>
                                    ) : null}
                            </div>
                            <div className='w-[455px] flex flex-col'>
                                <div className="flex flex-col lg:w-[455px] gap-2">
                                    <label className="label text-left text-[#101828] text-xs font-normal">Message</label>
                                    <textarea 
                                        name='message'
                                        type ="text" 
                                        cols="50"
                                        rows="3"
                                        className=" outline-none rounded-lg border border-[#D0D5DD] p-1"
                                        value={values?.message} 
                                        onChange={handleChange}
                                    ></textarea>
                                </div>
                                {errors.message && touched.message ? (
                                    <div className='text-RED-_100 text-xs'>{errors.message}</div>
                                    ) : null}
                            </div>
                            

                            <button 
                                className="w-[455px] h-[40px] py-3 px-5 rounded-lg flex justify-center items-center font-medium bg-BLACK-_500 text-WHITE-_100 text-sm"
                                type="submit"
                            >
                                <p className='text-WHITE-_100 text-sm font-bold'>{loading ? <CgSpinner className=" animate-spin text-lg " /> : 'Save'}</p>  
                            </button>
                            <button 
                                className="w-[455px]   flex justify-center items-center font-medium  text-[#868686] text-sm"
                                type="submit"
                                onClick={() => Close()}
                            >
                                Cancel
                            </button>
                        
                        </div>
                        

                    </Form>
                )}
            </Formik>
        </div>
        {bulkPurchaseSuccess && <SuccessMessage Formodal={true} success={message} className="mt-24 mr-20"/>}
        {bulkPurchaseError && <ErrorMessage Formodal={true} error={error} className="mt-24 mr-20"/>}
    </div>
  )
}

export default Bulk
import { useEffect, useMemo, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import WholeCustomer from "Customer/WholeCustomer";
import DebounceSearch from "Customer/DebounceSearch";
import DateFilter from "Customer/DateFilter";
import chevDown from "Assets/svg/chevDown.svg";
import emptyConversations from "Assets/svg/emptyConversations.svg";

import { useDispatch, useSelector } from "react-redux";
import { getConversations } from "features/customer/customerSlice";
import Spinner from "component/Spinner";

import { FaArrowRight } from "react-icons/fa6";
import Head from "../Assets/svg/Shape65.svg";
import { useTour } from "@reactour/tour";
import ModalPop from "@/../component/modalPop";
import Guide from "@/../component/modals/Guide";
import { useAuth } from "@/../context/UserContext";

function Conversations() {
  // const [id, setId] = useState("");
  const { showFilterate, setShowFilter, id, setId } = useAuth();

  function formatTimeDifference(timestamp) {
    if (!timestamp) return "";
    const now = new Date();
    const diffInMinutes = Math.floor((now - new Date(timestamp)) / 60000);

    if (diffInMinutes < 1) return "now";
    if (diffInMinutes < 60)
      return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
    if (diffInMinutes < 1440)
      return `${Math.floor(diffInMinutes / 60)} hour${
        Math.floor(diffInMinutes / 60) > 1 ? "s" : ""
      } ago`;
    if (diffInMinutes < 2880) return "yesterday";

    return new Date(timestamp).toLocaleDateString("en-GB");
  }
  const { conversations, customer, loading } = useSelector(
    (state) => state?.getCustomers
  );

  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const newAgent = getAgentApplication?.agent || null;

  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [stateUnReadMessages, setStateUnReadMessages] = useState([]);

  useEffect(() => {
    // put unrread messages in state
    setStateUnReadMessages(
      conversations
        .filter((conversation) => conversation.user_unread_msg_counter > 0)
        .map((conversation) => conversation.id)
    );
  }, [conversations]);
  const fetchData = async () => {
    // const response = await dispatch(getConversation(id));

    await dispatch(
      getConversations({
        applicationId: newAgent.id,
        page: 1,
        per_page: 999,
        start_date: startDate,
        end_date: endDate,
        search_term: searchTerm,
        status,
      })
    );
  };
  // use the first day and last day of the month
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const getData = async (dontShowLoader = false) => {
    try {
      if (!dontShowLoader) setPageLoading(true);

      await fetchData();
      setFirstRender(false);
    } catch (e) {
      console.log(e);
    } finally {
      setPageLoading(false);
    }
  };
  const [status, setStatus] = useState("");
  const getStatusType = (name) => {
    switch (name) {
      case "all":
        return "";
      case "Live Conversations":
        return "active";
      case "Awaiting Handoff":
        return "awaiting_agent";
      case "Resolved":
        return "resolved";
      case "Assigned Conversations":
        return "agent_assigned";
      default:
        return "";
    }
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "active":
        return {
          statusColor: "text-green-500",
          dotColor: "bg-green-500",
        };
      case "agent_assigned":
        return { statusColor: "text-pink-500", dotColor: "bg-pink-500" };
      case "awaiting_agent":
        return { statusColor: "text-yellow-500", dotColor: "bg-yellow-500" };
      case "resolved":
        return {
          statusColor: "text-blue-500",
          dotColor: "bg-blue-500",
        };

      default:
        return {
          statusColor: "text-blue-500",
          dotColor: "bg-blue-500",
        };
    }
  };
  useEffect(() => {
    if (endDate && startDate) {
      getData();
    }
    if (!endDate && !startDate) {
      getData();
    }
  }, [status, searchTerm, endDate, startDate]);
  const [pageLoading, setPageLoading] = useState(false);

  // const [showFilterate, setShowFilter] = useState("");

  const [filter, setFilter] = useState("");

  const [showFilter2, setShowFilter2] = useState("");

  const { setSteps, setIsOpen } = useTour();
  const [openTourModal, setOpenTourModdal] = useState(false);
  const tourConversation = JSON.parse(localStorage.getItem("tourConvo"));

  const hasConversationWithAwaitingHandOff = useMemo(() => {
    return conversations.some((convo) => convo.status === "awaiting_agent");
  }, [conversations]);

  const openTour = conversations.length > 0 && hasConversationWithAwaitingHandOff && !tourConversation;
  const saveTourValue = () => {
    return localStorage.setItem('tourConvo', true);
  };
  const conversationSteps = [
    {
      selector: ".conversation-1",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Takeover Conversation</p>
          </div>
          <p>
            Set the dropdown filter to “Awaiting Handoff” to find the conversation Faster.
          </p>
          <p className="text-[#7F7F81]">Step 1 of 4 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      isConversation: true,
    },
    {
      selector: ".conversation-2",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Takeover Conversation</p>
          </div>
          <p>
            Go to all conversations awaiting handoff.
          </p>

          <p className="text-[#7F7F81]">Step 2 of 4 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      openConvo: true
    },
    {
      selector: ".conversation-3",
      content: (
        <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
            <p className="text-[#7F7F81]">Takeover Conversation</p>
          </div>
          <p>
            Click to open conversation
          </p>

          <p className="text-[#7F7F81]">Step 3 of 4 </p>
        </div>
      ),
      position: "right",
      arrow: "disabled",
      styles: { popover: (defaultStyles) => ({
        ...defaultStyles,
        background: "black",
        color: "white", 
      }),},
    },

    // {
    //   selector: ".conversation-4",
    //   content: (
    //     <div className="text-[12px] leading-[18px] rounded-[20px] flex flex-col gap-2">
    //       <div className="flex gap-2 items-center">
    //         <img src={Head} alt="Image" className="h-[23.63px] w-[23.63px]" />
    //         <p className="text-[#7F7F81]">Takeover Conversation</p>
    //       </div>
    //       <p>
    //         Takeover from your assistant. Click “Takeover Conversation” to takeover the chat.
    //       </p>

    //       <p className="text-[#7F7F81]">Step 4 of 4 </p>
    //     </div>
    //   ),
    //   position: "right",
    //   arrow: "disabled",
    //   backgound: 'black'
    // },
  ];
  useEffect(() => {
      setSteps(conversationSteps);
  },[]);

  useEffect(() => {
  

    if(openTour){
      setOpenTourModdal(true)
    }else{
      setOpenTourModdal(false)
    };
  },[openTour]);


  return (
    <DashboardLayout title={"Conversations"} user={true}>
      {showFilterate && (
        <div
          className="fixed inset-0 h-screen w-screen z-10 "
          onClick={() => setShowFilter(false)}
        ></div>
      )}
      {showFilter2 && (
        <div
          className="fixed inset-0 h-screen w-screen z-10 "
          onClick={() => setShowFilter2(false)}
        ></div>
      )}
      <div className="w-full grid grid-cols-12">
        <div className="col-span-3 min-h-screen  bg-white shadow-lg rounded-lg overflow-auto">
          <div className="p-4 pr-0">
            <div className="relative">
              <div className="conversation-1 flex flex-wrap justify-between pr-4">
                <h2
                  className="text-lg flex gap-2 items-center font-medium mb-4 cursor-pointer"
                  onClick={() => setShowFilter((prev) => !prev)}
                >
                  {status === "active"
                    ? "Active Conversations"
                    : status === "agent_assigned"
                    ? "Assigned Conversations"
                    : status === "awaiting_agent"
                    ? "Awaiting Handoff Conversations"
                    : status === "resolved"
                    ? "Resolved Conversations"
                    : "All Conversations"}

                  <span className="text-[#858585] text-sm">
                    ({conversations.length})
                  </span>
                  <img src={chevDown} alt="Image" className="h-2 w-2" />
                </h2>
              </div>

              {showFilterate && (
                <div className="absolute right-2 top-8 bg-white z-20 border border-[#D0D5DD] shadow rounded-lg space-y-4">
                  <p className="py-2.5 px-3.5  text-xs text-[#7F7F81]">
                    Filter Conversation Type
                  </p>
                  {[
                    {
                      name: "All Conversations",
                      type: "",
                    },
                    {
                      name: "Active Conversations",
                      type: "active",
                    },
                    {
                      name: "Awaiting Handoff",
                      type: "awaiting_agent",
                    },
                    {
                      name: "Assigned Conversations",
                      type: "agent_assigned",
                    },
                    {
                      name: "Resolved",
                      type: "resolved",
                    },
                  ].map((option) => (
                    <div
                      onClick={() => {
                        setStatus(option.type);
                      }}
                      className={`py-2.5 px-3.5 hover:bg-[#EFF7FF] cursor-pointer text-xs
                          ${status === option.type ? " bg-[#EFF7FF] " : ""}
                          ${option.type === "awaiting_agent" ? "conversation-2" : ""}
                          `}
                    >
                      {option.name}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-500">
                  3 Aug, 2024 - 6 Aug, 2024
                </span>
              </div>
            </div> */}
            <div className="flex items-center mb-4 w-full pr-4 gap-3">
              <div className="w-full ">
                <DateFilter
                  setStartDate={setStartDate}
                  startDate={startDate}
                  setEndDate={setEndDate}
                  endDate={endDate}
                />
              </div>

              {/* <div className="relative">
                <div
                  className="flex capitalize cursor-pointer items-center text-xs px-3 py-2.5 gap-1 border border-BLACK-_400  rounded-lg"
                  onClick={() => setShowFilter2((prev) => !prev)}
                >
                  <svg
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 5H11M1 1H13M5 9H9"
                      stroke="#121212"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {filter ?? "Filter"}
                </div>
                {showFilter2 && (
                  <div className="absolute w-44 right-0 top-10 bg-white z-20 border border-[#D0D5DD] shadow rounded-lg space-y-4">
                    <p className="py-2.5 px-3.5  text-xs text-[#7F7F81]">
                      Filter Conversation Type
                    </p>
                    {[
                      {
                        name: "All",
                        type: null,
                      },
                      {
                        name: "Read",
                        type: "read",
                      },
                      {
                        name: "Unread",
                        type: "unread",
                      },
                    ].map((option) => (
                      <div
                        onClick={() => {
                          setFilter(option.type);
                          setShowFilter2(false);
                        }}
                        className={`py-2.5 px-3.5 hover:bg-[#EFF7FF] cursor-pointer text-xs
                    ${filter === option.type ? " bg-[#EFF7FF] " : ""}
                    `}
                      >
                        {option.name}
                      </div>
                    ))}
                  </div>
                )}
              </div> */}
            </div>

            <div className="pr-4">
              <DebounceSearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            </div>
            {pageLoading ? (
              <div className="h-[40vh] w-full flex items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <>
                {conversations.length ? (
                  <ul className="space-y-4 scrollContainer max-h-[70vh] overflow-auto">
                    {conversations.map((conv, index) => {
                      return (
                        <li
                          key={index}
                          className={`p-4 hover:bg-[#E7F2FF80] cursor-pointer rounded-lg ${conv?.status === "awaiting_agent"? "conversation-3" : ""} ${
                            conv.id === id ? "bg-[#E7F2FF80] " : ""
                          }`}
                          onClick={() => {
                            // setStateUnReadMessages((prevItems) => [
                            //   ...prevItems,
                            //   conv.id,
                            // ]);
                            setStateUnReadMessages((prevItems) =>
                              prevItems.filter((item) => item !== conv.id)
                            );
                            setId(conv.id);
                            //closetourguide
                            setIsOpen(false);
                          }}
                        >
                          <div className="flex justify-between">
                            <h3 className="text-sm font-medium capitalize">
                              {conv?.customer?.name ? conv?.customer?.name : conv.random_customer.random_username }
                            </h3>
                            <div className="flex items-center gap-1">
                              <p
                                className={`text-xs ${
                                  stateUnReadMessages.includes(conv.id)
                                    ? "text-[#1774FD]"
                                    : "text-[#858585]"
                                }`}
                              >
                                {formatTimeDifference(
                                  conv.messages?.length
                                    ? conv.messages[0]
                                        .created_at
                                    : ""
                                )}
                              </p>
                              {stateUnReadMessages.includes(conv.id) && (
                                <span className="w-1.5 h-1.5 bg-[#1774FD] rounded-full"></span>
                              )}
                            </div>
                          </div>
                          <div className="grid grid-cols-10  justify-between items-center">
                            <div className="text-xs col-span-6   truncate text-[#858585]">
                              {conv.messages?.length
                                ? conv.messages[0]
                                    .message
                                : ""}
                            </div>
                            <div
                              className={`text-xs col-span-4  flex justify-end   flex-grow font-semibold ${
                                getStatusColor(conv.status).statusColor
                              } inline-flex justify-end gap-1 items-center`}
                            >
                              <div
                                className={` w-1.5 h-1.5 rounded-full  ${
                                  getStatusColor(conv.status).dotColor
                                }`}
                              ></div>
                              <div className="">
                                {conv.status === "active"
                                  ? "Active Chat"
                                  : conv.status === "agent_assigned"
                                  ? "Agent Assigned"
                                  : conv.status === "awaiting_agent"
                                  ? "Awaiting Handoff"
                                  : conv.status === "resolved"
                                  ? "Resolved"
                                  : conv.status}
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <div className="text-center text-sm ">
                    No Conversations to show
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="col-span-9">
          {id ? (
            <WholeCustomer
              id={id}
              getconversations={(dontShowLoader) => getData(dontShowLoader)}
            />
          ) : (
            <div className="flex flex-col items-center justify-center h-full">
              <img
                src={emptyConversations}
                alt="emptyConversations"
                className="w-[310px] h-[64px]"
              />

              <p className="text-BLACK-_300 text-lg font-semibold mt-5">
                Select a Conversation
              </p>
              <div className="text-BLACK-_300 text-base">
                Choose a conversation from the side bar to view
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalPop isOpen={openTourModal}>
        <Guide
          handleClose={() => {
            saveTourValue();
            setOpenTourModdal(false);
          }}
          // close={saveTourValue}
          text1={`Takeover Conversation`}
          text2={
            "You have a conversation awaiting handoff! A customer has requested to speak with a live agent. Ready for a quick tour on what to do?"
          }
          text3={"Skip Tour"}
          label={"Start Tour"}
        />
      </ModalPop>
    </DashboardLayout>
  );
}

export default Conversations;

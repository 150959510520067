import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
// import { Menu, Transition } from '@headlessui/react'
// import { BiChevronDown } from "react-icons/bi"
// import { PiHeadsetFill } from 'react-icons/pi';
// import { Divider } from '@mui/material';
// import { FiPhone } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router";
import AgentModal from "../../AllPluginType/components/AgentModal";
// import Logo from "../../../Assets/wano logo 1 1.png"
import SetupIcon from "../../../Assets/svg/setup.svg";
import IntegrateWano from "../../../component/modals/plugins/IntegrateWano";
import ModalPop from "../../../component/modalPop";
import IntegrateCustomBusinessA from "../../../component/modals/plugins/IntegrateCustomBusinessA";
import IntegrateCustomBusinessB from "../../../component/modals/plugins/IntegrateCustomBusinessB";
// import RemovePlugin from '../../../component/modals/plugins/RemovePlugin';
import { useDispatch, useSelector } from "react-redux";
import { getPluginById } from "../../../features/plugins/getPluginByIdSlice";
import {
  CustomerService,
  EditIcon,
  Sales,
  WhatsAppIconBig,
} from "../../../Assets/svg";
// import General from '../../../Assets/img/all 1.png'
// import WhatsAppBig from '../../../Assets/img/whatsapp_big.png';
import TokenService from "../../../services/token";
import {
  ErrorMessage,
  SuccessMessage,
} from "../../../Toast-Messages/Toastmessages";
import { CgSpinner } from "react-icons/cg";
import SetupWhatsApp from "../../../component/modals/plugins/SetupWhatsApp";
import UninstallPlugin from "../../../component/modals/plugins/UninstallPlugin";
import { ConnectIcon, PlusIcon } from "../../../Assets/icons";
import AddSetUpURL from "../../../component/modals/plugins/AddSetUpURL";
// import { updatePlugins } from '@/../features/plugins/updatePluginsSlice';
import { getPluginsChannel } from "@/../features/plugins/getPluginChannelSlice";
import { Switch } from "@headlessui/react";
import { updatePlugins } from "@/../features/plugins/updatePluginsSlice";
import { getPlugins } from "@/../features/plugins/getPluginsSlice";
import { TextWithHighlights } from "@/../Plugins/AllPluginType/components/OtherPluginsDetail";
import { getAllPlugins } from "@/../features/plugins/getAllPluginsSlice";

const MyPlugins = () => {
  const [integrateWithWano, setIntegrateWithWano] = useState(false);
  const [integrateWithCustomBusiness, setIntegrateWithCustomBusiness] =
    useState(false);
  const [pluginsNotificationSuccess, setPluginsNotificationSuccess] =
    useState(false);
  const [pluginsNotificationError, setPluginsNotificationError] =
    useState(false);
  const [openNextModal, setOpenNextModal] = useState();
  const [addWanoNumber, setAddWanoNumber] = useState(false);
  const [integrateWhatsApp, setIntegrateWhatsapp] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [viewAgent, setViewAgent] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [uninstallPlugin, setUninstallPlugin] = useState(false);
  const [uninstallPluginLoading, setUninstallPluginLoading] = useState(false);

  //newly added for adding or editing URL
  const [addURL, setAddURL] = useState(false);
  const [editURL, setEditURL] = useState(false);
  //end

  const token = TokenService.getToken();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const getdata = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = getdata && getdata.length >= 1 ? getdata[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const [featureData, setFeatureData] = useState(null);
  // const [data, setData] = useState({})
  const URL = process.env.REACT_APP_BASEURL;

  const navigate = useNavigate();

  const { state } = useLocation(); //removed setActiveButton
  console.log(state, "this is state from MyPlugins.jsx aka the preview page "); //removed setActiveButton

  const applicationPluginId = state?.id; //previously state?.plugin?.id
  const isSetup = state?.plugin?.require_setup;
  //added this line to get the stored number from local storage
  const whatsAppSetupOTPNumber = localStorage.getItem("whatsapp") ?? null;
  const url =
    (state?.features[0]?.notification_url ||
      featureData?.[0]?.notification_url) ??
    null;
  // console.log(url, "this is url")
  // console.log(featureData[0].notification_url, "this is featureData myplugins")
  //end of line

  const { data } = useSelector((state) => state.getPluginByApplicationPluginId);
  const renderData = data?.data;
  // console.log(data, "tag renderData selector res");
  const updatePluginsData = useSelector((state) => state.updatePlugins);
  const { error } = updatePluginsData;

  useEffect(() => {
    dispatch(getPluginsChannel({ applicationId }));
  }, []); //loading
  //channelId
  const channels = useSelector((state) => state.getPluginChannel);
  // console.log(channels, "overseas")

  const channelsData = channels?.data?.data;
  // console.log(channelsData, "channels data");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPluginById({ applicationId, applicationPluginId })).then(
      (res) => {
        console.log(res, "tag renderData res");
        // setData(res?.payload?.data)
      }
    );
  }, [dispatch, applicationPluginId, applicationId]);

  const fetchPluginAgentFeatures = async (agentId) => {
    sessionStorage.setItem("plugin_id", state?.id);
    const res = await axios.get(
      `${URL}/dashboard/plugins/${state?.plugin?.id}/application/${agentId}/features`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    setFeatureData(res.data.data);
    // console.log(res.data, "this is fetchPluginAgentFeatures in MyPlugins.jsx")
    // sessionStorage.setItem('plugin_id', res.data.data[0].application_module?.id)
  };

  // const getPluginByApplicationPluginId = useSelector(state => state.getPluginByApplicationPluginId)
  // console.log(getPluginByApplicationPluginId, "getPluginByApplicationPluginId")

  //Remove Plugin
  const disconnectPlugin = async (item) => {
    setUninstallPluginLoading(true);
    localStorage.setItem("pluginState", "My Plugins");
    try {
      const res = await axios.delete(
        `${URL}/dashboard/plugins/${item.plugin.id}/remove`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUninstallPluginLoading(false);
      setPluginsNotificationSuccess(true);
      setTimeout(() => {
        navigate("/plugins");

        // setActiveButton("My Plugins")
      }, 1500);
      // window.location.reload()
    } catch (error) {
      setPluginsNotificationError(true);
    }
  };

  const togglePlugin = async (item) => {
    // console.log(item,"item")
    try {
      const res = await axios
        .put(
          `${URL}/dashboard/applications/${applicationId}/plugins/${item.id}/status`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          dispatch(getPlugins({ applicationId }));
          setPluginsNotificationSuccess(true);
        })
        .then(() => {
          dispatch(getPluginById({ applicationId, applicationPluginId }));
        });
      // setPluginsNotificationSuccess(true)
      // window.location.reload()
      // const applicationPluginId = item?.id
      // dispatch(getAllPlugins({ applicationId })).then(() => {
      //     dispatch(updatePlugins({ applicationId, applicationPluginId } ))
      // }).then(() => dispatch(getPlugins({ applicationId })))
    } catch (error) {
      setPluginsNotificationError(true);
    }
  };

  const removePluginsNotificationSuccess = () => {
    setTimeout(() => {
      setPluginsNotificationSuccess(false);
    }, 1000);
  };

  const removePluginsNotificationError = () => {
    setTimeout(() => {
      setPluginsNotificationError(false);
    }, 1000);
  };

  useEffect(() => {
    removePluginsNotificationSuccess();
  }, [pluginsNotificationSuccess]);

  useEffect(() => {
    removePluginsNotificationError();
  }, [pluginsNotificationError]);

  // useEffect(() => {
  //     localStorage.setItem("pluginState", "My Plugins")
  // }, [])

  const message = "Plugin Uninstalled Successfully";

  console.log(state, "tag plugin");

  return (
    <div className="">
      <img src={state?.plugin?.image_url} className="h-[65px] w-[65px]" />
      {/* <WhatsAppIconBig /> */}
      <div className="flex items-center justify-between mt-[24px]">
        <p className="text-[#101828] font-medium text-[24px]">
          {state?.plugin?.name}
        </p>
        {/* (state?.plugin?.name === "Whatsapp Messaging" || state?.plugin?.name === "Whatsapp OTP" || state?.plugin?.name === "Whatsapp Alerts") */}
        {state?.plugin?.name.includes("Whatsapp") ? (
          <div className="flex gap-2">
            {/* <button onClick={() => setIntegrateWhatsapp(true)} className="cursor-pointer flex items-center gap-3 outline-none py-[10px] w-auto px-5 bg-[#121212] rounded-lg text-white text-xs font-semibold">
                        <p>Integrate</p>
                    </button> *disconnectPlugin(state) */}
            <button
              onClick={() => {
                setUninstallPlugin(true);
              }}
              className="cursor-pointer outline-none border border-[#EEE]  w-[129px] h-[43px] flex items-center justify-center rounded-lg text-[#AF202D] text-sm font-medium"
            >
              {uninstallPluginLoading ? (
                <CgSpinner className="text-RED-_100 animate-spin text-lg" />
              ) : (
                "Uninstall App"
              )}
            </button>
          </div>
        ) : (
          <div className="flex gap-4 items-center">
            {isSetup && state?.plugin?.require_configuration_setup ? (
              <div>
                <Switch
                  checked={renderData?.published}
                  onChange={() => togglePlugin(renderData)}
                  as={Fragment}
                >
                  {({ checked }) => (
                    /* Use the `checked` state to conditionally style the button.  mb-[180px] */
                    <button
                      className={`${
                        checked ? "bg-blue-600" : "bg-gray-200"
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${
                          checked ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </button>
                  )}
                </Switch>
              </div>
            ) : (
              <button
                onClick={() => {
                  fetchPluginAgentFeatures(state?.application.id);
                  setViewAgent(!viewAgent);
                  setSelectedAgent(state?.application);
                }}
                className="cursor-pointer flex items-center gap-2.5 outline-none py-[10px] w-auto px-5 bg-[#121212] rounded-lg text-white text-xs font-semibold"
              >
                <img src={SetupIcon} alt="Setup" />
                <p className="text-sm font-semibold">Edit Features</p>
              </button>
            )}
            <button
              onClick={() => setUninstallPlugin(true)}
              className="bg-[#EEEEEE] cursor-pointer outline-none border border-[#EEE]  w-[129px] h-[43px] flex items-center justify-center rounded-lg text-[#AF202D] text-sm font-medium"
            >
              {uninstallPluginLoading ? (
                <CgSpinner className="text-RED-_100 animate-spin text-lg" />
              ) : (
                "Uninstall App"
              )}
            </button>
          </div>
        )}
      </div>
      <div className="flex gap-1.5 mt-5">
        <div
          className={`${
            renderData?.published ? "bg-[#DFF8E74D]" : "bg-[#EEEEEE]"
          } flex items-center justify-center w-fit h-[22px] gap-1 p-2  rounded-xl`}
        >
          {/* w-[85px] */}
          <div
            className={`${
              renderData?.published ? "bg-[#009733]" : "bg-red-700"
            } rounded-full  w-[4px] h-[4px]`}
          ></div>
          {/* <p className='text-xs text-[#009733]'>{state?.plugin?.name.includes("Whatsapp") ? "Active" : "Connected"}</p>  state?.plugin?.name.includes("Whatsapp") ? "Active" : */}
          <p
            className={`${
              renderData?.published ? "text-[#009733]" : "text-RED-_100"
            } text-xs `}
          >
            {renderData?.published ? "Connected" : "Disconnected"}
          </p>
        </div>
        {state?.plugin?.name.includes("Whatsapp") && whatsAppSetupOTPNumber && (
          <div className="w-fit flex items-center gap-1 bg-[#F5F5F5] p-[4px_8px] rounded-lg">
            <ConnectIcon />
            <p className="text-[#7F7F81] text-xs font-normal leading-normal">
              {whatsAppSetupOTPNumber}
            </p>
          </div>
        )}
        {state?.plugin?.name.includes("Whatsapp") && url === null ? (
          <div
            tabIndex={0}
            className="w-fit flex items-center gap-1 bg-[#F5F5F5] p-[4px_8px] rounded-lg cursor-pointer"
            onClick={() => {
              fetchPluginAgentFeatures(state?.application?.id);
              setSelectedAgent(state?.application);
              setAddURL(true);
            }}
          >
            <PlusIcon />
            <p className="text-[#7F7F81] text-xs font-normal leading-normal">
              Add Webhook Link
            </p>
          </div>
        ) : null}
      </div>
      {url && state?.plugin?.name.includes("Whatsapp") && (
        <div
          className="w-fit mt-2 flex items-center gap-1 bg-[#F5F5F5] p-[4px_8px] rounded-lg cursor-pointer"
          onClick={() => {
            fetchPluginAgentFeatures(state?.application?.id);
            setSelectedAgent(state?.application);
            setEditURL(true);
          }}
        >
          {/* <PlusIcon/> */}
          <ConnectIcon />
          <p className="text-[#7F7F81] text-xs font-normal leading-normal">
            {url}
          </p>
          <EditIcon />
        </div>
      )}

      <div className="flex w-full mt-[30px] gap-6">
        {/* {state?.application && (
                <div className='flex-1 flex flex-col gap-4'>
                    <h2 className='text-[18px] font-medium text-[#121212]'>Linked Employee</h2>
                    <div onClick={() => {fetchPluginAgentFeatures(state.application.id); setViewAgent(!viewAgent); setSelectedAgent(state.application)}} className='cursor-pointer w-[35%] flex flex-col gap-[10px] border border-[#E5E5E5] rounded-xl py-[18px] px-4'>
                        <div className='w-full justify-between flex items-center'>
                            <div className='flex gap-[10px] items-center'>
                                {state?.application?.icon_url ? <img src={state?.application?.icon_url} alt='icon' /> :
                                    <div className='w-5 h-5 bg-[#1774FD] rounded-lg flex justify-center items-center'>
                                        <p className='capitalize text-white text-[9px] font-medium'>
                                            {state?.application?.name?.charAt(0)}
                                        </p>
                                    </div>
                                }
                                <p className='text-[16px] font-medium text-[#121212]'>{state?.application?.name}</p>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect x="0.599609" width="23.4" height="23.4" rx="3.51" fill="#FBFBFB"/>
                                <path d="M9.95703 14.0414L15.807 8.19141M15.807 8.19141H9.95703M15.807 8.19141V14.0414" stroke="black" stroke-width="1.17" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <p className='text-xs text-[#7F7F81]'>{state?.application?.description}</p>
                    </div>
                </div>
            )} */}
        {/* <div className='bg-[#FAFAFA] w-[423px] p-[24px] flex gap-5 flex-col'>
                <p className='text-xl text-[#121212] font-medium'></p>
                <p className='text-sm text-[#101828] font-medium'>Your WhatsApp OTP plugin can be used alone as a standalone service or by any of the following AI Employee categories:</p>
                <div className='flex justify-between'>
                    {
                        state?.plugin?.compatible_with?.map((item, index) => {
                            return (
                                <div key={index} className='flex flex-col'>
                                    <div 
                                        className="w-[31.32px] h-[31.32px] bg-WHITE-_100 border border-[#E5E5E5] rounded-lg flex cursor-pointer justify-center items-center p-2" 
                                    >
                                        {item === 'customer' && <CustomerService />}
                                        {item === 'sale' && <Sales />}
                                        {item === 'general' && 
                                            <img src={General} alt='ai'/>
                                        }
                                    </div>
                                    <p className='text-sm text-left text-[#121212] mt-[10px] font-medium'><span className='capitalize'>{item}</span> A.I</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div> */}
      </div>

      {/* <hr className='my-8  w-[672px]'/> */}

      <div className="flex flex-col pb-6 gap-4 w-full">
        <p className="text-[#121212] text-xl font-medium">About App</p>
        <p
          className="text-sm text-[#7F7F81]"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {state?.plugin?.about}
        </p>
      </div>
      {state?.plugin?.name.includes("Whatsapp") ? (
        <div className="mt-[24px] mb-[100px]">
          <div className="flex flex-col gap-4">
            <p className="text-xl text-[#121212] font-medium">
              How to Integrate
            </p>
            <p className="text-sm text-[#7F7F81] mb-1">
              Integrate with Wano Number
            </p>
          </div>
          <div>
            <p className="text-sm text-[#7F7F81] leading-[25.2px]">
              1. Provide a webhook notification URL for the service
            </p>
            <p className="text-sm text-[#7F7F81] leading-[25.2px]">
              2. Click on the available Wano number to integrate and go live on
              WhatsApp instantly
            </p>
            <p className="text-sm text-[#7F7F81] mb-1 mt-5">
              Integrate with Custom Number
            </p>
            <p className="text-sm text-[#7F7F81] leading-[25.2px]">
              1. Provide a webhook notification URL for seamless service
              integration.
            </p>
            <p className="text-sm text-[#7F7F81] leading-[25.2px]">
              2. Provide your unique business number (ensuring it's not already
              connected to WhatsApp)
            </p>
            <p className="text-sm text-[#7F7F81] leading-[25.2px]">
              3. Complete the process by entering the OTP sent to the provided
              number.
            </p>
            <p className="text-sm text-[#7F7F81] leading-[25.2px]">
              4. Your App will be live within 24 hours.
            </p>
            {/* <p>{state?.plugin?.how_it_works}</p> */}
          </div>
        </div>
      ) : state?.plugin?.name.includes("Web Search") ? (
        <WebSearch />
      ) : (
        <Others state={state} />
      )}

      <ModalPop isOpen={integrateWithWano}>
        <IntegrateWano
          purpose={purpose}
          handleClose={() => setIntegrateWithWano(false)}
        />
      </ModalPop>

      <ModalPop isOpen={integrateWhatsApp}>
        <SetupWhatsApp
          handleClose={() => setIntegrateWhatsapp(false)}
          setAddWanoNumber={setAddWanoNumber}
          setPurpose={setPurpose}
          setIntegrateWithCustomBusiness={setIntegrateWithCustomBusiness}
        />
      </ModalPop>

      <ModalPop isOpen={uninstallPlugin}>
        <UninstallPlugin
          handleClose={() => setUninstallPlugin(false)}
          token={token}
          state={state}
        />
      </ModalPop>

      <ModalPop isOpen={integrateWithCustomBusiness}>
        <IntegrateCustomBusinessA
          handleClose={() => setIntegrateWithCustomBusiness(false)}
          addWanoNumber={addWanoNumber}
          setIntegrateWithWano={setIntegrateWithWano}
          setOpenNextModal={setOpenNextModal}
          purpose={purpose}
        />
      </ModalPop>

      <ModalPop isOpen={openNextModal}>
        <IntegrateCustomBusinessB
          purpose={purpose}
          handleClose={() => setOpenNextModal(false)}
        />
      </ModalPop>

      <ModalPop isOpen={viewAgent}>
        <AgentModal
          featureData={featureData}
          handleClose={() => {
            setViewAgent(false);
          }} // navigate('/plugins')
          selectedAgent={selectedAgent}
          fetchFeatures={fetchPluginAgentFeatures}
          singleAgent={true}
          state={state}
        />
      </ModalPop>
      <ModalPop isOpen={addURL || editURL}>
        <AddSetUpURL
          setup={addURL ? true : false}
          featureData={featureData}
          state={state}
          handleClose={
            addURL ? () => setAddURL(false) : () => setEditURL(false)
          }
          selectedAgent={selectedAgent}
          fetchFeatures={fetchPluginAgentFeatures}
          singleAgent={true}
        />
      </ModalPop>
      {pluginsNotificationSuccess && (
        <SuccessMessage
          Formodal={true}
          success={message}
          className="mt-28 mr-12"
        />
      )}
      {pluginsNotificationError && (
        <ErrorMessage Formodal={true} error={error} className="mt-28 mr-12" />
      )}
    </div>
  );
};

export default MyPlugins;

const Others = ({ state }) => {
  const parseHowItWorks = (howItWorks) => {
    return howItWorks.split("\n").filter((line) => line.trim() !== "");
  };
  return (
    <>
      <div className="flex flex-col gap-4 mb-4">
        <p className="text-xl text-[#121212] font-medium">How to Use App</p>
      </div>
      <div className="leading-[25.2px]">
        {/* <p className="text-sm text-[#7F7F81] leading-[25.2px]">
                    1. Configure your App by activating features that fit your business’s use case.
                </p>
                <p className="text-sm text-[#7F7F81] leading-[25.2px]">
                    2. Provide a webhook notification URL for each activated feature.
                </p>
                <p className="text-sm text-[#7F7F81] leading-[25.2px]">
                    3. Save App configurations
                </p> */}
        <ol>
          {parseHowItWorks(state?.plugin?.how_it_works).map((step, index) => (
            <li key={index} className="text-sm text-[#7F7F81] leading-[25.2px]">
              {step}
            </li>
          ))}
        </ol>
      </div>
      <div className="flex flex-col gap-4 mt-[24px] mb-4">
        <p className="text-xl text-[#121212] font-medium">Triggers</p>
      </div>
      <div>
        {/* <p className="text-sm text-[#7F7F81] leading-[25.2px]">hallelujah</p> */}
        {/* <p className="text-sm text-[#7F7F81]" style={{ whiteSpace: 'pre-wrap' }}>{renderData?.trigger}</p>
              <p className="text-sm text-[#7F7F81] leading-[25.2px]">
                You can also tag this application to your Instructions for your employee. You can tag by using {" "}<span className="text-[#1774FD] font-medium">@{renderData?.name.toLowerCase()}</span> in your instruction.
              </p> */}
        <TextWithHighlights text={state?.plugin?.trigger} />
      </div>
    </>
  );
};

const WebSearch = () => {
  return (
    <>
      <div className="flex flex-col gap-4 mb-4">
        <p className="text-xl text-[#121212] font-medium">How to Use App</p>
      </div>
      <div className="leading-[25.2px]">
        <p className="text-sm text-[#7F7F81] leading-[25.2px]">
          1. Install the Application.
        </p>
        <p className="text-sm text-[#7F7F81] leading-[25.2px]">
          2. While interacting with your Assistant on the Ask Assistant Page tag
          the application using{" "}
          <span className="text-[#1774FD] font-medium">“@websearch”</span> to
          trigger it.
        </p>
        <p className="text-sm text-[#7F7F81] leading-[25.2px]">
          3. You could also use secondary triggers by using words like{" "}
          <span className="text-[#1774FD] font-medium">“Search online”</span>,{" "}
          <span className="text-[#1774FD] font-medium">“Google”</span> or{" "}
          <span className="text-[#1774FD] font-medium">
            “Search the internet”
          </span>{" "}
          with your request.
        </p>
        <p className="text-sm text-[#7F7F81] leading-[25.2px]">
          4. Using the tag will allow your assistant to use the application to
          search the internet
        </p>
      </div>
    </>
  );
};

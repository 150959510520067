import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit"

import { api } from "../../services/api"
import { appUrls } from "../../services/urls"


const initialState = {
    loading: false,
    user: [],
    error: ""
}

export const loginUser = createAsyncThunk(
    "user/loginUser", 
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.post(appUrls?.LOGIN_URL, data);
            if (res?.status === 201) {
                const { token } = res?.data?.data;
                sessionStorage.setItem("token", token);
                localStorage.setItem("token", token);
            }
            return res?.data
        }
        catch (err) {
            return rejectWithValue(err?.data?.message)
        }
    }
)


const loginSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: builder => {
        builder.addCase(loginUser.pending, state => {
            state.loading = true
    });
        builder.addCase(loginUser.fulfilled, (state, action) => void(
            state.loading = false,
            state.user = action.payload,
            state.error = ""
        ));
        builder.addCase(loginUser.rejected, (state, action) => void(
            state.loading = false,
            state.user = [],
            state.error = action.payload
        ))
    }
});

export default loginSlice.reducer
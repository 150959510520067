import { useState } from "react";
import calenderIcon from "Assets/svg/calenderIcon.svg";
// import "react-dates/initialize";
// import "react-dates/lib/css/_datepicker.css";
// import { DateRangePicker } from "react-dates";

// import { DateRangePicker } from "react-date-range";
// import "react-date-range/dist/styles.css"; // main style file
// import "react-date-range/dist/theme/default.css"; // theme css file

function DateFilter({ startDate, setStartDate, endDate, setEndDate }) {
  const [showDatePicker, setShowDatePicker] = useState(false);
  function formatDate(dateStr) {
    const options = { day: "numeric", month: "short", year: "numeric" };

    // Parse the date string
    const date = new Date(dateStr);

    // Format the date
    return date.toLocaleDateString("en-GB", options);
  }
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  return (
    <div>
      <div
        className={`
          ${startDate && endDate ? " bg-[#E8F1FF] " : ""}
          
          flex border text-xs cursor-pointer border-BLACK-_400 rounded-lg py-2 px-2.5 gap-2 items-center`}
      >
        {startDate && endDate ? (
          <>
            <svg
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.25 5.83341H0.75M8.33333 1.16675V3.50008M3.66667 1.16675V3.50008M3.55 12.8334H8.45C9.43009 12.8334 9.92014 12.8334 10.2945 12.6427C10.6238 12.4749 10.8915 12.2072 11.0593 11.8779C11.25 11.5036 11.25 11.0135 11.25 10.0334V5.13341C11.25 4.15332 11.25 3.66328 11.0593 3.28893C10.8915 2.95965 10.6238 2.69193 10.2945 2.52415C9.92014 2.33341 9.43009 2.33341 8.45 2.33341H3.55C2.56991 2.33341 2.07986 2.33341 1.70552 2.52415C1.37623 2.69193 1.10852 2.95965 0.940739 3.28893C0.75 3.66328 0.75 4.15332 0.75 5.13341V10.0334C0.75 11.0135 0.75 11.5036 0.940739 11.8779C1.10852 12.2072 1.37623 12.4749 1.70552 12.6427C2.07986 12.8334 2.56991 12.8334 3.55 12.8334Z"
                stroke="#1774FD"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div className="flex flex-wrap items-center w-full text-BLUE-_200 justify-between">
              {formatDate(startDate)} - {formatDate(endDate)}
            </div>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                setStartDate("");
                setEndDate("");
              }}
            >
              <path
                d="M8.5 3.5L3.5 8.5M3.5 3.5L8.5 8.5"
                stroke="#1774FD"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </>
        ) : (
          <div
            className="flex relative gap-3 items-center w-full"
            onClick={() => setShowDatePicker(true)}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 6.66683H2M10.6667 1.3335V4.00016M5.33333 1.3335V4.00016M5.2 14.6668H10.8C11.9201 14.6668 12.4802 14.6668 12.908 14.4488C13.2843 14.2571 13.5903 13.9511 13.782 13.5748C14 13.147 14 12.5869 14 11.4668V5.86683C14 4.74672 14 4.18667 13.782 3.75885C13.5903 3.38252 13.2843 3.07656 12.908 2.88482C12.4802 2.66683 11.9201 2.66683 10.8 2.66683H5.2C4.0799 2.66683 3.51984 2.66683 3.09202 2.88482C2.71569 3.07656 2.40973 3.38252 2.21799 3.75885C2 4.18667 2 4.74672 2 5.86683V11.4668C2 12.5869 2 13.147 2.21799 13.5748C2.40973 13.9511 2.71569 14.2571 3.09202 14.4488C3.51984 14.6668 4.0799 14.6668 5.2 14.6668Z"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>Please Select a Date</span>
            {showDatePicker && (
              <div className="absolute bg-white p-2 rounded-lg top-10 left-0 w-[240px] z-50">
                <label className="text-xs font-figtree text-[#101828]">
                  Start Date
                </label>
                <input
                  type="date"
                  onChange={(e) => setStartDate(e.target.value)}
                  value={startDate}
                  className={`instructionstep-6 focus:outline-none w-full cursor-pointer text-[#101828] text-xs `}
                />
                <label className="mt-2 text-xs font-figtree text-[#101828]">
                  End Date
                </label>
                <input
                  type="date"
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setShowDatePicker(false);
                  }}
                  value={endDate}
                  className={`instructionstep-6 focus:outline-none w-full cursor-pointer text-[#101828] text-xs `}
                />
                {/* <DateRangePicker
                startDate={startDate}
                startDateId="start_date_id"
                endDate={endDate}
                endDateId="end_date_id"
                onDatesChange={({ startDate, endDate }) => {
                  console.log("changes", startDate, endDate);
                  setStartDate(startDate);
                  setEndDate(endDate);
                  if (endDate) {
                    setShowDatePicker(false);
                  }
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                numberOfMonths={2}
                isOutsideRange={() => false}
              /> */}
                {/* <DateRangePicker
                ranges={state}
                onChange={(item) => {
                  console.log("here", item.selection);
                  setState([item.selection]);
                }}
                showDateDisplay={false}
              /> */}
              </div>
            )}
          </div>
        )}
      </div>

      {showDatePicker && (
            <div
              className="fixed inset-0 bg-gray-500/20 h-screen w-screen z-10"
              onClick={() => {
                setShowDatePicker(false);
              }}
            ></div>
          )}
    </div>
  );
}

export default DateFilter;

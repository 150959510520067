export default function AnalyticsBtn({ activeButton, handleButtonClick }) {
  return (
    <section className=" w-full flex justify-end mt-7 font-figtree  mb-10">
      <div className="flex w-[400px] rounded-xl py-[2px] h-[34px] gap-[5px] select-none">
        <button
          className={`font-medium text-xs text-center w-[55px] h-[36px] rounded-lg transform transition-transform outline-none ${
            activeButton === "today"
              ? " text-[white] bg-[#1774FD] shadow-custom"
              : "text-[#7F7F81] border"
          }`}
          onClick={() => handleButtonClick("today")}
        >
          Today
        </button>
        <button
          className={`font-medium  w-[75px] h-[36px]  rounded-lg text-center text-xs transform transition-transform outline-none ${
            activeButton === "week"
              ? " text-[white] bg-[#1774FD] shadow-custom"
              : "text-[#7F7F81] border"
          }`}
          onClick={() => handleButtonClick("week")}
        >
          This Week
        </button>
        <button
          className={`font-medium w-[80px] h-[36px]  rounded-lg text-center text-xs transform transition-transform outline-none ${
            activeButton === "month"
              ? " text-[white] bg-[#1774FD] shadow-custom"
              : "text-[#7F7F81] border"
          }`}
          onClick={() => handleButtonClick("month")}
        >
          This Month
        </button>
        <button
          className={`font-medium w-[85px] h-[36px] rounded-lg text-center text-xs transform transition-transform outline-none ${
            activeButton === "quarter"
              ? " text-[white] bg-[#1774FD] shadow-custom"
              : "text-[#7F7F81] border"
          }`}
          onClick={() => handleButtonClick("quarter")}
        >
          This Quarter
        </button>
        <button
          className={`font-medium w-[68px] h-[36px] rounded-lg text-center text-xs transform transition-transform outline-none ${
            activeButton === "year"
              ? " text-[white] bg-[#1774FD] shadow-custom"
              : "text-[#7F7F81] border"
          }`}
          onClick={() => handleButtonClick("year")}
        >
          This Year
        </button>
      </div>
    </section>
  );
}

import DashboardLayout from "../layout/DashboardLayout";
import { useEffect, useRef, useContext, useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getConversation,
  getConversation1,
  addMessageToConversation,
  getConversationSummary,
} from "features/customer/customerSlice";
import ConversationChatBox from "Customer/ConversationChatBox";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import Spinner from "component/Spinner";

// import {
//   ClassicEditor,
//   Bold,
//   Essentials,
//   Italic,
//   Mention,
//   Paragraph,
//   Undo,
//   List,
//   ListUI,
// } from "ckeditor5";
import { UserContext } from "../context/UserContext";

import blackPlane from "Assets/svg/blackPlane.svg";
import stars from "Assets/svg/starss.svg";
import SummaryIcon from "Assets/svg/Summary.svg";
import emailIcon from "Assets/svg/mail.svg";
import mailIcon from "Assets/svg/mail.svg";
import globe from "Assets/svg/globe.svg";
import tickTick from "Assets/svg/ticktick.svg";
import tick from "Assets/svg/check.svg";
import delIcon from "Assets/svg/delIcon.svg";
import "ckeditor5/ckeditor5.css";
import "ckeditor5-premium-features/ckeditor5-premium-features.css";
// const socketRaw = io(process.env.REACT_APP_BASEURL);
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};
function CustomerConversation() {
  // const [socket, setSocket] = useState(socketRaw);
  const { socket } = useContext(UserContext);
  const getUserProfile = useSelector((state) => state.getProfile);
  const profile = getUserProfile?.data?.data;
  const [conversations, setConversations] = useState([]);
  const [connectingToAgent, setConnectingToAgent] = useState(false);
  const [agentTakeOver, setAgentTakeOver] = useState(false);
  const [isUserTyping, setIsUserTyping] = useState(false);

  useEffect(() => {
    const handleTakeOver = () => {
      fetchData();
    };
    const handledashboardReceiveMessage = (e) => {
      setIsUserTyping(false);
      setShowSummary(false);

      dispatch(addMessageToConversation(e));
    };

    socket.on("userTyping", (message) => {
      setIsUserTyping(true);
    });
    socket.on("connect", () => {
      console.log("connected", socket); // x8WIv7-mJelg7on_ALbx
    });
    socket.on("dashboardResolve", () => {
      fetchData();
    });

    socket.on("dashboardReceiveMessage", handledashboardReceiveMessage);
    const handleHandOff = () => {
      console.log("await handleHandOff");
      conversationSummary();
      setConnectingToAgent(true);
    };
    socket.on("awaitingHandoff", handleHandOff);
    socket.on("agentTakeOver", handleTakeOver);
    socket.on("exception", (e) => {
      console.log("exception", e); // x8WIv7-mJelg7on_ALbx
      if (e.status === "error") {
        const payload = {
          message: e.message,
          role: "assistant",
        };
        // socket.emit("sendMessage", payload);
        dispatch(addMessageToConversation(payload));
        setConversations((prevConversations) => [
          ...prevConversations,
          {
            message: e.message,
            role: "assistant",
          },
        ]);
        setIsUserTyping(false);
      }
    });

    socket.on("disconnect", () => {
      console.log("disconnected", socket.id); // undefined
    });
    socket.on("error", (error) => {
      console.log("error with socket", error);
    });
    socket.on("ping", (ping) => {
      // ...
      console.log("ping  socket", ping);
    });

    socket.on("reconnect", (attempt) => {
      // ...
      console.log("reconnecting  socket,attempt");
    });

    return () => {
      // socket.off("receiveMessage", handleReceiveMessage);
      socket.off("agentTakeOver", handleTakeOver);
      socket.off("dashboardReceiveMessage", handledashboardReceiveMessage);
      socket.off("awaitingHandoff", handleHandOff);
      // socket.off("dashboardReceiveMessage", handledashboardReceiveMessage);
      // socket.off("dashboardReceiveMessage", handledashboardReceiveMessage);
      // socket.disconnect();
      // socket.disconnect();
    };
  }, []);

  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    if (socket) {
      socket.emit("joinConversation", { conversationId: id });
    }
  }, []);

  const dispatch = useDispatch();
  const resetState = () => {
    setIsUserTyping(false);
    setConnectingToAgent(false);
    setShowSummary(false);
  };
  const [stateCustomerDetails, setStateCustomerDetails] = useState({});
  const [isConversationLoading, setIsConversationLoading] = useState(false);
  const [isResolving, setIsResolving] = useState(false);
  const [conversationResolved, setConversationResolved] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const fetchData = async () => {
    const response = await dispatch(getConversation(id));

    const chatMessages = response.payload.data;
    setIsResolving(false);
    resetState();
    setStateCustomerDetails(chatMessages);
    if (chatMessages?.messages?.length) {
      setConversations(chatMessages.messages);
    }
    setConversationResolved(chatMessages?.resolved_at);
    if (
      chatMessages.messages.some((e) => {
        return e?.status === "connecting";
      })
    ) {
      conversationSummary();
    }
  };
  const [showSummary, setShowSummary] = useState(false);

  const conversationSummary = async () => {
    try {
      console.log();
      setShowSummary(false);
      let newId;
      if (!stateCustomerDetails?.customer?.id) {
        const response = await dispatch(getConversation1(id));
        newId = response.payload.data.customer.id;
      }
      setIsConversationLoading(true);
      const response = await dispatch(
        getConversationSummary({
          conversationId: id,
          customerId: customer?.id
            ? customer?.id
            : stateCustomerDetails.customer?.id
            ? stateCustomerDetails.customer?.id
            : newId,
          applicationId: newAgent.id,
        })
      );
      if (response.payload.data) {
        setShowSummary(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsConversationLoading(false);
    }
  };
  const handleResolve = async () => {
    try {
      setIsResolving(true);
      socket.emit("resolveConversation", { conversationId: id });
    } catch (e) {
      console.log(e);
    }
  };
  const getData = async () => {
    try {
      setPageLoading(true);
      await fetchData();
    } catch (e) {
      console.log(e);
    } finally {
      setPageLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handleTakeOver = () => {
    socket.emit("takeOverConversation", {
      conversationId: id,
      user: {
        name: `${profile.first_name} ${profile.last_name}`,
        id: profile.id,
      },
    });
  };
  const { conversation, customer, loading } = useSelector(
    (state) => state?.getCustomers
  );
  useEffect(() => {
    if (
      conversations?.some((e) => {
        return e?.status === "connecting";
      })
    ) {
      // conversationSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, conversations]);
  const hasTakeOverHappened = conversation?.some((e) => {
    return e?.status === "connected";
  });
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  // const applicationId = JSON.parse(localStorage.getItem("wano_agent"));
  const newAgent = getAgentApplication?.agent || null;
  const [message, setMessage] = useState("");

  const handleSendMessage = async () => {
    if (!message) return;

    const date = new Date();

    const payload = {
      conversationId: id,
      message,
      businessCustomerId: stateCustomerDetails?.businessCustomerId,
      widgetRandomUserId: stateCustomerDetails?.randomCustomerId,
      applicationId: newAgent.id,
      role: "agent",
      created_at: date.toISOString(),
      agent_name: `${profile.first_name} ${profile.last_name}`,
      businessId: newAgent.business.id,
    };
    // socket.emit("sendMessage", payload);
    socket.emit("agentSendMessage", payload);
    dispatch(addMessageToConversation(payload));

    setMessage("");
    setShowSummary(false);
  };
  const isUserTypingRef = useRef(false);
  // Function to emit typing event
  const emitTypingEvent = () => {
    socket.emit("isTyping", { conversationId: id, destination: "widget" });
    // console.log("Typing event emitted");
    isUserTypingRef.current = true;
  };

  // Debounced function to reset typing state
  const resetTypingEvent = debounce(() => {
    // console.log("User stopped typing");
    isUserTypingRef.current = false;
  }, 1500);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage(message);
    }
  };
  const handleChange = (event) => {
    // const data = editor.getData();
    const data = event.target.value;
    setMessage(data || "");
    if (!isUserTypingRef.current) {
      emitTypingEvent(); // Emit typing event immediately
    }
    resetTypingEvent(); // Reset the debounce timer
  };
  return (
    <DashboardLayout title={"Customers"} user={true}>
      {pageLoading ? (
        <div className="h-screen w-screen flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          {!!stateCustomerDetails?.id && (
            <div className="px-10 py-12 max-h-screen  overflow-auto">
              <header className="flex gap-2 items-center mb-4">
                <div
                  onClick={() =>
                    navigate("/customers", { state: "Conversations" })
                  }
                  className="cursor-pointer underline text-[#D2D0D0] text-sm font-normal hover:underline"
                >
                  Conversations
                </div>
                <BiChevronRight className=" text-xl text-[#D2D0D0]" />
                <p className="text-[#121212] text-sm font-normal capitalize ">
                  {stateCustomerDetails.customer.name ??
                    stateCustomerDetails.random_customer.random_username}
                </p>
              </header>
              <div className="grid grid-cols-10 mt-7 bg-grey-200">
                <div className="col-span-8  relative">
                  <ConversationChatBox
                    stateCustomerDetails={stateCustomerDetails}
                    connectingToAgent={connectingToAgent}
                    agentTakeOver={agentTakeOver}
                    isUserTyping={isUserTyping}
                    showSummary={showSummary}
                    conversationResolved={conversationResolved}
                  />
                  <section className="absolute bottom-2 left-1/2 transform -translate-x-1/2 w-[95%]  flex gap-5 px-4 items-center justify-between border-BLACK-_400 rounded-xl">
                    {!conversationResolved && (
                      <div className="bg-white  w-full ">
                        {hasTakeOverHappened ? (
                          <>
                            <div className="p-3 border rounded-xl flex gap-3 w-full">
                              <div className="w-full">
                                {/* <CKEditor
                            ref={isUserTypingRef}
                            editor={ClassicEditor}
                            config={{
                              toolbar: {
                                items: [
                                  "undo",
                                  "redo",
                                  "|",
                                  "bold",
                                  "italic",
                                  "bulletedList",
                                  "numberedList",
                                ],
                              },
                              plugins: [
                                Bold,
                                Essentials,
                                Italic,
                                Mention,
                                Paragraph,
                                Undo,
                                List,
                                ListUI,
                              ],
                              licenseKey: "<YOUR_LICENSE_KEY>",
                              mention: {
                                // Mention configuration
                              },
                              placeholder: "Type a message",
                            }}
                            data={message}
                            onChange={handleChange1}
                            onKeyDown={handleKeyDown}
                          /> */}
                                <input
                                  type="text"
                                  value={message}
                                  onChange={handleChange}
                                  onKeyDown={handleKeyDown}
                                  className={`flex-grow focus:outline-none p-2 text-xs  border-none bg-transparent rounded-3xl placeholder:text-[#A6ADB5] placeholder:text-xs   `}
                                  placeholder="Enter a message"
                                />
                              </div>

                              <img
                                src={blackPlane}
                                alt="blackPlane"
                                onClick={handleSendMessage}
                                className="cursor-pointer"
                              />
                            </div>
                          </>
                        ) : (
                          <p
                            className="text-BLUE-_200 text-center cursor-pointer py-3"
                            onClick={handleTakeOver}
                          >
                            Takeover Conversation
                          </p>
                        )}
                      </div>
                    )}

                    {/* summary button  */}
                    <div
                      className={`flex justify-center  items-center gap-5 bg-[#E7F2FFB5] px-5 py-4 h-full rounded-lg ${
                        conversationResolved ? "w-full" : ""
                      }`}
                    >
                      {isConversationLoading ? (
                        <div className="w-5 h-5 mx-auto">
                          <Spinner />
                        </div>
                      ) : (
                        <div
                          className="flex w-full justify-center  items-center cursor-pointer gap-1"
                          onClick={() => {
                            conversationSummary();
                          }}
                        >
                          <img src={stars} alt="stars" />
                          <img src={SummaryIcon} alt="SummaryIcon" />
                        </div>
                      )}
                    </div>
                  </section>
                </div>
                <div className="col-span-2  max-h-[80vh] no-scrollbar overflow-auto">
                  <div className="border-l bg-white pt-2 pb-5 border-WHITE-_300 pl-6 pr-8 flex flex-col">
                    {hasTakeOverHappened && (
                      <div
                        className={`flex  mb-8 items-center gap-[10px] text-sm font-medium text-BLUE-_200 ${
                          conversationResolved ? "" : "cursor-pointer"
                        }`}
                        onClick={() => {
                          !conversationResolved && handleResolve();
                        }}
                      >
                        {conversationResolved ? "" : " Mark as "}
                        Resolved
                        {isResolving ? (
                          <div className="w-5 h-5">
                            <Spinner />
                          </div>
                        ) : (
                          <img
                            src={conversationResolved ? tickTick : tick}
                            alt="line"
                          />
                        )}
                      </div>
                    )}

                    <p className="text-BLACK-_160 font-medium text-sm uppercase mb-4">
                      Customer Profile
                    </p>
                    {/* NAME  */}
                    <div
                      className="flex items-center gap-2 mb-5 cursor-pointer "
                      onClick={() => {
                        navigate(
                          `/customers/${stateCustomerDetails?.customer.id}`
                        );
                      }}
                    >
                      <div className="w-8 h-8 rounded-full capitalize bg-BLUE-_200 text-white flex gap-4 justify-center items-center font-bold text-[16px]">
                        {stateCustomerDetails?.customer?.name?.charAt(0) ??
                          stateCustomerDetails?.random_customer?.random_username.charAt(
                            0
                          )}
                      </div>
                      <p className="text-sm font-semibold capitalize underline ">
                        {" "}
                        {stateCustomerDetails?.customer?.name ??
                          stateCustomerDetails?.random_customer
                            ?.random_username}
                      </p>
                    </div>
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-3 text-sm font-normal text-BLACK-_500">
                        <img src={emailIcon} alt="line" />
                        {stateCustomerDetails?.customer?.email}
                      </div>{" "}
                      <div className="flex gap-3 text-sm font-normal text-BLACK-_500">
                        <img src={globe} alt="line" />
                        {stateCustomerDetails?.customer?.phone_number ?? "N/A"}
                      </div>{" "}
                      {/* <div className="flex gap-3 text-sm font-normal text-BLACK-_500">
                    <img src={globe} alt="line" />
                    Lagos, Nigeria
                  </div> */}
                    </div>
                  </div>
                  {/* CONVERSATION NOTES  */}
                  <div className="mt-1 pb-10 bg-white pl-6  pt-6">
                    <p className="text-BLACK-_160 text-sm font-medium mb-4">
                      CONVERSATION NOTES
                    </p>
                    <input
                      type="text"
                      placeholder="Type note"
                      className="w-full border focus:outline-none border-BLACK-_400 rounded-lg"
                    />
                    <div className="flex justify-end">
                      <div className="border mt-2.5 mb-5 cursor-pointer border-grey-300 rounded-lg py-2 px-3 text-PRIMARY">
                        Add note
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 5">
                      <div className="rounded-lg border border-grey-300 p-2.5 bg-[#FDFDFD]">
                        <p className="text-xs font-medium text-BLACK-_300">
                          User asked for the best toilet paper we have and i
                          couldn’t provide an answer, call back when I confirm.
                        </p>
                        <div className="flex justify-end">
                          <img
                            src={delIcon}
                            alt="stars"
                            className="cursor-pointer"
                          />
                        </div>
                      </div>{" "}
                      <div className="rounded-lg border border-grey-300 p-2.5 bg-[#FDFDFD]">
                        <p className="text-xs font-medium text-BLACK-_300">
                          User asked for the best toilet paper we have and i
                          couldn’t provide an answer, call back when I confirm.
                        </p>
                        <div className="flex justify-end">
                          <img
                            src={delIcon}
                            alt="stars"
                            className="cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </DashboardLayout>
  );
}

export default CustomerConversation;

import React, { useState, useEffect } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import Profile from "../Settings/ProfileTab";
import Teams from "../Settings/TeamsTab";
import API from "../Settings/ApiTab";

import Integration from "../Settings/IntegrationTab";
import { useDispatch, useSelector } from "react-redux";
import { fetchTeamMembers } from "../features/teams/getTeamsSlice";
import { fetchRole } from "../features/role/getRoleSlice";
import { useFormikContext } from "formik";
import ModalPop from "../component/modalPop";
import { BottomGuide } from "../component/modals/agentGuide/Guide";
import { useLocation, useNavigate } from "react-router";
import SettingsSvg from "../Assets/svg/settings.svg";
import info from "../Assets/svg/infocircle.svg";
import Plugins from "../Settings/PluginsTab";
import ApiKey from "@/../Settings/IntegrationTab/component/ApiKey";
import { updateBusinessModals } from "@/../features/profile/getProfileSlice";
import { getProfile } from "@/../features/profile/getProfileSlice";
import PlanCard from "./PlanCard";
import { BiChevronRight } from "react-icons/bi";
import SetupAssistance from "./SetupAssistance";
import UpgradeToPlan from "./UpgradeToPlan";
import UpgradePlan from "./UpgradePlan";
import { getCurrentPlan } from "@/../features/profile/getCurrentPlanSlice";
import Pay from "@/../component/modals/wallet/Pay";
import RedeemGift from "@/../component/modals/redeemgift";
import { getGiftBalance } from "@/../features/profile/getGiftBalanceSlice";
import RedeemSuccess from "@/../component/modals/redeemgift/RedeemSuccess";

export default function Settings() {
  const location = useLocation();
  const [openRedeemSuccess, setOpenRedeemSuccess] = useState(false);
  const [activeButton, setActiveButton] = useState("Profile");
  const [openTourModal, setOpenTourModal] = useState(false);
  const [page, setPage] = useState("");
  const [category, setCategory] = useState("");
  const [openPayModal, setOpenPayModal] = useState(false);
  const [openRedeemGift, setOpenRedeemGift] = useState(false);
  
  const [show, setShow] = useState(false);

  
  const [upgrade, setUpgrade] = useState(false);
  const [downgrade, setDowngrade] = useState(false);
  const [chat, setChat] = useState(false);
  const [openUpgradeToPlan, setOpenUpgradeToPlan] = useState(false);
  const [openUpgradePlan, setOpenUpgradePlan] = useState(false);

  const [showUnsavedSettingsModal, setShowUnsavedSettingsModal] =
    useState(false);
  const [openSetupAssistance, setOpenSetupAssistance] = useState(false);

  const dispatch = useDispatch();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const plan = useSelector((state) => state.getCurrentPlan.data);
  const { subscriptions = {}, currentPlan: current } = plan || {};
  const currentPlan = current?.subscription_details?.name;
  const nextAvailablePlanAmount = subscriptions[1]?.monthly_amount;
  const business =  useSelector(state => state.getBusiness.activeBusiness);
  const businessID = business?.id

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");
    const category = searchParams.get("category");
    const openSetupAssistanceParam = searchParams.get("openSetupAssistance");

    setOpenSetupAssistance(openSetupAssistanceParam === "true");
  }, [location.search]);

  // console.log(showUnsavedSettingsModal, "modal value");

  useEffect(() => {
    const isModified =
      localStorage.getItem("showUnsavedSettingsModal") === "true";
    setShowUnsavedSettingsModal(isModified);
  }, [showUnsavedSettingsModal]);

  const [reroute, setReroute] = useState("Profile");
  const [isFormModified, setIsFormModified] = useState(false);
  // console.log(isFormModified, "modified ");

  useEffect(() => {
    const isModified = localStorage.getItem("isFormModified") === "true";
    setIsFormModified(isModified);
  }, [isFormModified]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");
    setPage(page);
    const category = searchParams.get("category");
    setCategory(category);
  }, [location.search]);

  const handleCloseSetupAssistance = () => {
    setOpenSetupAssistance(false);
    navigate("/settings", { replace: true });
  };

  const handleOpenSetupAssistance = () => {
    setOpenSetupAssistance(true);
    navigate("/settings?openSetupAssistance=true", { replace: true });
  };

  // const pathToButtonValueMap = {
  //     "/dashboard": "dashboard",
  //     "/agent": "agent",
  //     "/ask-agent": "askAgent",
  //     "/customers": "customers",
  //     "/plugins": "plugins",
  //     "/top-up": "billing",
  //     "/settings": "settings",
  //     "/support": "support",
  // };

  // useEffect(() => {
  // const currentPath = location.pathname;
  // const activeButtonValue = Object.entries(pathToButtonValueMap).find(([pathKey]) =>
  //     currentPath.startsWith(pathKey)
  // )?.[1];

  // if (activeButtonValue) {
  //     setActiveButton(activeButtonValue);
  // }
  // }, [location.pathname, setActiveButton]);

  // Function to close the unsaved settings modal
  const handleCloseUnsavedSettingsModal = () => {
    setShowUnsavedSettingsModal(false);
    setActiveButton(reroute);
  };

  const navigate = useNavigate();
  console.log(navigate);

  const handleButtonClick = (buttonName) => {
    if (isFormModified) {
      navigate("/settings");
      setShowUnsavedSettingsModal(true);
      setReroute(buttonName);
      return;
    }
    setActiveButton(buttonName);
  };

  const { loading } = useSelector((state) => state.inviteMember);
  const deletedMember = useSelector((state) => state.deleteMember);

  useEffect(() => {
    dispatch(fetchTeamMembers());
  }, [loading, deletedMember?.loading]);

  useEffect(() => {
    dispatch(fetchRole());
  }, []);

  const setBottomSettingsGuide = () =>
    location.pathname === "/settings"
      ? localStorage.setItem("settings", true)
      : null;
  const getSettingsGuide = localStorage.getItem("settings");

  useEffect(() => {
    if (getSettingsGuide) {
      setOpenTourModal(false);
    } else {
      setOpenTourModal(true);
    }
  }, []);

  // const { id } = useSelector(state => state.getApplicationByUserId.agent)
  // const config = useSelector(state => state.getProfile.businessModals)
  // console.log(config, "configA");

  // const getSettingsGuide = config?.settings; console.log(getSettingsGuide, "configA getSettingsGuide");

  // useEffect(() => {
  //     if(!getSettingsGuide) {
  //         setOpenTourModal(true)
  //       } else {
  //         setOpenTourModal(false)
  //       }
  // }, [getSettingsGuide]);

  // const handleBusinessModal = (name) => {
  //   const currentModalState = config[name];
  //   if (!currentModalState) {
  //     const modalsVisibility = {...config, [name]: true}
  //     dispatch(updateBusinessModals({ modalsVisibility }))
  //     .then((res) => {
  //       dispatch(getProfile());
  //     })
  //     .catch((error) => {
  //       console.error("Failed to update modal state:", error);
  //   });;
  //   }
  // }

  return (
    <DashboardLayout title={"Settings"} user={true}>
      {page && category ? (
        <section className="px-[40px] pt-[52px]">
          <div className="flex mb-[31px]">
            <p
              className="text-[14px] capitalize leading-[16.8px] text-[#D2D0D0] font-normal cursor-pointer"
              onClick={() => navigate("/top-up")}
            >
              {page}
            </p>
            <BiChevronRight className=" text-xl text-[#D2D0D0]" />
            <p className="text-[14px] leading-[16.8px] text-[#121212]">
              {category}s
            </p>
          </div>

          <div className="mb-[27px]">
            <h1 className="font-medium text-[18px] leading-[21.6px]">
              Your Plan
            </h1>
            <p className="leading-[15.42px] text-[12px] font-normal text-[#7F7F81] mt-[3px]">
              Your subscription plan, you can change your plan to suit your
              business
            </p>
            <div className="flex items-center gap-[4px] w-[181px] h-[25px] bg-[#FAFBFC] rounded-[24px] px-[12px] py-[6px] mt-[8px]">
              <img src={info} alt="info circle" className="w-[10px] h-[10px]" />
              <button onClick={() => navigate("/support")}>
                {" "}
                <p className="underline text-[#1774FD] text-[10px] leading-[12.85px]">
                  How we charge for subscription
                </p>
              </button>
            </div>
          </div>
          <PlanCard />
        </section>
      ) : (
        <>
          <section className="flex justify-between font-figtree relative w-full ">
            {" "}
            {/*p-10 mt-7 mb-10 */}
            <div className="w-full flex">
              <div
                className="pt-[35px] pl-[27px] pr-[43px] bg-inherit flex flex-col gap-5 select-none border-r border-[#F7F7F7]"
                style={{ width: "fit-content" }}
              >
                {" "}
                {/* w-[310px] border rounded-xl py-[2px] h-[34px] gap-[10px] */}
                <button
                  className={`font-medium text-xs text-start p-[10px_16px]  w-[135px] transform transition-transform outline-none ${
                    activeButton === "Profile"
                      ? " text-[#1774FD] bg-[#FAFAFA] rounded-md"
                      : "text-[#7F7F81]"
                  }`}
                  onClick={() => handleButtonClick("Profile")}
                >
                  Profile
                </button>
                <button
                  className={`font-medium  w-[135px] p-[10px_16px] text-start text-xs transform transition-transform outline-none ${
                    activeButton === "Teams"
                      ? " text-[#1774FD] bg-[#FAFAFA] rounded-md"
                      : "text-[#7F7F81]"
                  }`}
                  onClick={() => handleButtonClick("Teams")}
                >
                  Teams
                </button>
                <button
                  className={`font-medium  w-[135px] p-[10px_16px] text-start text-xs transform transition-transform outline-none ${
                    activeButton === "API Keys"
                      ? " text-[#1774FD] bg-[#FAFAFA] rounded-md"
                      : "text-[#7F7F81]"
                  }`}
                  onClick={() => handleButtonClick("API Keys")}
                >
                  API Keys
                </button>
                {/* <button
                            className={`font-medium w-[95px]  rounded-lg text-center text-xs transform transition-transform outline-none ${
                            activeButton === "Plugins"
                                ? " text-[black] bg-white shadow-custom"
                                : "text-[#7F7F81]"
                            }`}
                            onClick={() => handleButtonClick("Plugins")}
                        >
                            Plugins
                        </button> */}
              </div>
              <div
                className="w-full p-10 !sticky top-[0] z-0 overflow-y-scroll hide-scrollbar"
                style={{
                  height: `calc(92vh)`,
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
              >
                {activeButton === "Profile" && (
                  <Profile
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                    setShowUnsavedSettingsModal={setShowUnsavedSettingsModal}
                    showUnsavedSettingsModal={showUnsavedSettingsModal}
                    handleCloseUnsavedSettingsModal={
                      handleCloseUnsavedSettingsModal
                    }
                    reroute={reroute}
                    setReroute={setReroute}
                    isFormModified={isFormModified}
                    setIsFormModified={setIsFormModified}
                  />
                )}
                {activeButton === "Teams" && <Teams />}
                {activeButton === "API Keys" && <ApiKey />}
              </div>
              {/* {activeButton === "Plugins" && <Plugins />} */}
            </div>
          </section>
          <ModalPop isOpen={openTourModal}>
            <BottomGuide
              title="Settings"
              subtitle="Welcome to your Business Settings! Tailor and manage your account preferences. Ensure your account's security by setting up Two-Factor Authentication (2FA) for added protection."
              link="View Settings"
              handleClose={() => {
                // handleBusinessModal("settings");
                setOpenTourModal(false);
                setBottomSettingsGuide();
              }}
              image={SettingsSvg}
            />
          </ModalPop>
          <ModalPop
            isOpen={openSetupAssistance}
            onClose={handleCloseSetupAssistance}
          >
            <SetupAssistance
              handleClose={() => {
                setOpenSetupAssistance(false);
              }}
              setOpenUpgradeToPlan={setOpenUpgradeToPlan}
            />
          </ModalPop>
          <ModalPop isOpen={openUpgradeToPlan}>
            <UpgradeToPlan
              handleClose={() => setOpenUpgradeToPlan(false)}
              setOpenUpgradePlan={setOpenUpgradePlan}
              amount={nextAvailablePlanAmount}
            />
          </ModalPop>
          <ModalPop isOpen={openUpgradePlan}>
            <UpgradePlan
              handleClose={() => setOpenUpgradePlan(false)}
              setOpenRedeemSuccess={setOpenRedeemSuccess}
              monthly_amount={nextAvailablePlanAmount}
              data={subscriptions?.[1]}
              refresh={() => {
                dispatch(getCurrentPlan());
              }}
              setOpenPayModal={setOpenPayModal}
            />
          </ModalPop>
          <ModalPop isOpen={openPayModal}>
            <Pay
              handleClose={() => setOpenPayModal(false)}
              insufficient
              setOpenRedeemGift={setOpenRedeemGift}
            />
          </ModalPop>
          <ModalPop isOpen={openRedeemGift}>
            <RedeemGift
              handleClose={() => setOpenRedeemGift(false)}
              back={() => {setOpenPayModal(true); setOpenRedeemGift(false)}}
              setShow={setShow}
              setOpenRedeemSuccess={setOpenRedeemSuccess}
              refresh={() => dispatch(getGiftBalance(businessID))}
            />
          </ModalPop>
          <ModalPop isOpen={openRedeemSuccess}>
            <RedeemSuccess
              handleClose={() => setOpenRedeemSuccess(false)}
              title={"Upgrade Successful"}
              text1={"You have successfully upgraded to the Business Plan"}
              label={"Done"}
              redirectPath="/settings?page=billing&category=plan"
            />
          </ModalPop>
        </>
      )}
    </DashboardLayout>
  );
}

import React, { useRef, useState, useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { getCountries } from "../../../features/countries/getCountriesSlice";
import PasswordField from "../../../component/PasswordField";
import ModalPop from "../../../component/modalPop";
import ChangePassword from "../../../component/modals/password/ChangePassword";
import { CgSpinner } from "react-icons/cg";
import { updateProfile } from "../../../features/profile/updateProfileSlice";
import { SuccessMessage } from "../../../Toast-Messages/Toastmessages";
import { getProfile } from "../../../features/profile/getProfileSlice";
import spinner from "../../../Assets/gif/loader.gif";
import UnsavedSettings from "@/../Settings/UnsavedSettings";

const Personal = ({
  activeButton,
  setActiveButton,
  setShowUnsavedSettingsModal,
  showUnsavedSettingsModal,
  handleCloseUnsavedSettingsModal,
  reroute,
  setReroute,
  isFormModified,
  setIsFormModified
}) => {
  const storeCountries = useSelector((state) => state.getAllCountries);
  const countriesLoading = storeCountries.loading;
  const [countrycode, setCountrycode] = useState("+234");

  const [openPassword, setOpenPassword] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);


  //added this to remove to fix the input field not being able to be completely cleared
  const [fieldEdited, setFieldEdited] = useState({ firstName: false, lastName: false });

  const handleInputChange = (event, fieldName, setFieldValue) => {
    const inputValue = event.target.value;
    setFieldValue(fieldName, inputValue);
    setFieldEdited((prevFieldEdited) => ({ ...prevFieldEdited, [fieldName]: true }));
    setIsFormModified(true)
    localStorage.setItem("isFormModifiedP", true)
    localStorage.setItem("path", "/settings")
  };

  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.updateProfile);

  const getUserProfile = useSelector((state) => state.getProfile);
  // console.log(getUserProfile, "this is the getuserProfile data from personal.jsx")

  const profile = getUserProfile?.data?.data;

  // console.log(profile, "this is the profile from personal.jsx")

  const formValidationSchema = Yup.object().shape({
    firstName: Yup.string(), //.required(),
    lastName: Yup.string(), //.required()
  });
  const getCountiresAndStore = async () => {
    dispatch(getCountries());
  };
  useEffect(() => {
    getCountiresAndStore();
  }, []);
  
  const submitForm = (values) => {
    // console.log(values, "Adam")
    const data = {
      first_name: values?.firstName || profile.first_name,
      last_name: values?.lastName || profile.last_name,
    };
    dispatch(updateProfile(data)).then((res) => {
      // console.log(res, "aussie")
      setUpdateSuccess(true);
    });
  };

  const removeSuccessNotification = () => {
    setTimeout(() => {
      setUpdateSuccess(false);
    }, 1000);
  };
  // const saveChanges = async() => {
  //   if (submitFormRef.current) {
  //     await submitFormRef.current(); 
  //   }
  
  //   setIsFormModified(false);
  //   setTimeout(() => {
  //     setShowUnsavedSettingsModal(false);
  //   }, 1500)
  //   setShowUnsavedSettingsModal(false);
  // //   setActiveButton(reroute);
  //   setReroute('');
  // };

  const saveChanges = async () => {
    if (submitFormRef.current) {
        try {
            await submitFormRef.current(); 
            setUpdateSuccess(true);  
        } catch (error) {
            console.error("Error saving changes:", error);
        }
    }

    setTimeout(() => {
        setShowUnsavedSettingsModal(false); 
        setReroute('');  
        setIsFormModified(false);  
        localStorage.setItem("isFormModifiedP", false)
        localStorage.setItem("showUnsavedSettingsModalP", false)
    }, 1000); 
};

  
  
  const discardChanges = () => {
    setIsFormModified(false);
    localStorage.setItem("isFormModifiedP", false)
    localStorage.setItem("showUnsavedSettingsModalP", false)
    // localStorage.setItem("path", "/settings")
    setShowUnsavedSettingsModal(false);
    setActiveButton(reroute);
    setReroute('');
  };
  
  const closeModal = () => {
    setShowUnsavedSettingsModal(false);
    localStorage.setItem("isFormModifiedP", false)
    localStorage.setItem("showUnsavedSettingsModalP", false)
  };


  useEffect(() => {
    dispatch(getProfile());
  }, [loading]);

  useEffect(() => {
    removeSuccessNotification();
  }, [updateSuccess]);

  const submitFormRef = useRef(null);


  return (
    <div className="flex gap-[155px]">
      <div className="w-[219px] h-[50px] flex flex-col gap-1">
        <p className="font-medium text-BLACK-_100 text-sm">Personal Profile</p>
        <p className="text-xs text-BLACK-_200">
          Personal profile settings. You can change this information at anytime
        </p>
      </div>
      <div className="w-[420px]">
        <Formik
          initialValues={{
            firstName: profile?.first_name || "",
            lastName: profile?.last_name || "",
            phone: "",
            country: "",
            email: "",
            password: "*******************",
          }}
          validationSchema={formValidationSchema}
          onSubmit={(values) => {
            window.scrollTo(0, 0);
            submitForm(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            dirty,
            isValid,
            setFieldValue,
            errors,
            touched,
            // setFieldTouched,
            values,
            submitForm
          }) => {
            
            submitFormRef.current = submitForm;
            return(
            <Form
              onSubmit={handleSubmit}
              className="flex flex-col lg:items-center"
            >
              <div className="flex flex-col gap-5 ">
                <div className="flex gap-5">
                  <div className="w-[200px] h-[58px] flex flex-col">
                    <label
                      htmlFor="Firstname"
                      className="text-xs font-normal text-[#101828]"
                    >
                      Firstname
                    </label>
                    <input
                      name="firstName"
                      placeholder=""
                      type="text"
                      value={
                        fieldEdited.firstName
                          ? values.firstName
                          : profile?.first_name || ""
                      }
                      // value={fieldEdited.firstName? values.firstName : profile?.first_name || ""} // || profile?.first_name
                      // onChange={handleChange}
                      onChange={(event) => handleInputChange(event, "firstName", setFieldValue)}
                      className="rounded-lg border-[#D0D5DD] outline-none w-[200px] mt-1.5 h-[38px] border-solid  p-3 border"
                    />
                    {errors.firstName && touched.firstName ? (
                      <div className="text-RED-_100">{errors.firstName}</div>
                    ) : null}
                  </div>
                  <div className="w-[200px] h-[58px] flex flex-col ">
                    <label
                      htmlFor="Lastname"
                      className="text-xs font-normal text-[#101828]"
                    >
                      Lastname
                    </label>
                    <input
                      name="lastName"
                      placeholder=""
                      type="text"
                      value={
                        fieldEdited.lastName ? values.lastName : profile?.last_name || ""
                      }
                      onChange={(event) => handleInputChange(event, "lastName", setFieldValue)}
                      // value={values.lastName || profile?.last_name} //|| profile?.last_name
                      // onChange={handleChange}
                      className="rounded-lg border-[#D0D5DD] outline-none w-[200px] mt-1.5 h-[38px] border-solid  p-3 border"
                    />
                    {errors.lastName && touched.lastName ? (
                      <div className="text-RED-_100">{errors.lastName}</div>
                    ) : null}
                  </div>
                </div>

                <div className="flex flex-col ">
                  <label
                    htmlFor="email"
                    className="text-xs font-normal text-[#101828]"
                  >
                    Email
                  </label>
                  <input
                    name="email"
                    disabled
                    placeholder=""
                    type="text"
                    value={profile?.email || values?.email}
                    onChange={handleChange}
                    className="rounded-lg border-[#D0D5DD] xs:w-full outline-none lg:w-[420px] mt-1.5 h-[38px] border-solid  p-3 border"
                  />
                  {/* {errors.email && touched.email ? (
      <div className='text-RED-_100'>{errors.email}</div>
      ) : null} */}
                </div>
                <div className="flex flex-col  ">
                  <label
                    htmlFor="number"
                    className="text-xs font-normal text-[#101828]"
                  >
                    Phone Number
                  </label>

                  <div className="flex w-[420px] rounded-lg border-[#D0D5DD] border h-[38px] bg-WHITE-_100 border-solid mt-1.5">
                    {countriesLoading ? (
                      <div className="flex items-center justify-center">
                        <img src={spinner} alt="template" className="w-5 h-5" />
                      </div>
                    ) : (
                      <select
                        name="number"
                        id=""
                        className="text-xs border-t-0 m-0.5 border border-l-0 border-b-0 outline-none "
                        onChange={(e) => {
                          const val = e.target.value;
                          const selectedCountry =
                            storeCountries?.countries.find(
                              (country) => country.name === val
                            );
                          setCountrycode(selectedCountry.phone_code);
                        }}
                      >
                        {storeCountries?.countries.map((country) => (
                          <option
                            className="flex flex-rowb"
                            key={country.id}
                            value={country.name}
                          >
                            {country.code} ({country.phone_code})
                          </option>
                        ))}
                      </select>
                    )}
                    <input
                      name="number"
                      placeholder=""
                      disabled
                      type="text"
                      value={
                        profile?.phone_number.slice(3) || `${countrycode}${values.number}`
                      }
                      onChange={handleChange}
                      className="  outline-none w-[420px] border-none p-3 "
                    />
                  </div>
                  {/* {errors.number && touched.number ? (
        <div className='text-RED-_100'>{errors.number}</div>
        ) : null} */}
                </div>

                <div className="flex flex-col gap-1 ">
                  <label
                    htmlFor="email"
                    className="text-xs font-normal text-[#101828]"
                  >
                    Country
                  </label>
                  <input
                    name="country"
                    disabled
                    placeholder=""
                    type="text"
                    value={profile?.country || values?.country}
                    onChange={handleChange}
                    className="rounded-lg border-[#D0D5DD] xs:w-full outline-none lg:w-[420px] mt-1.5 h-[38px] border-solid  p-3 border"
                  />
                  {/* <select className='w-[420px] text-[13px] text-[#828282] h-[38px] border p-1 border-[#D0D5DD] outline-none rounded-lg'>
    <option value={profile?.country || values?.country}></option>
  </select> */}
                  {errors.country && touched.country ? (
                    <div className="text-RED-_100">{errors.country}</div>
                  ) : null}
                </div>

                <div className="flex flex-col gap-1 xs:w-full lg:w-[420px]">
                  <div className="flex justify-between items-center">
                    <label
                      htmlFor="password"
                      className="text-xs font-normal text-[#101828]"
                    >
                      Password
                    </label>
                    <p
                      className="text-BLUE-_200 text-left text-xs font-semibold cursor-pointer"
                      onClick={() => setOpenPassword(true)}
                    >
                      Change Password?
                    </p>
                  </div>
                  {/* <PasswordField
                    name="password"
                    value={values.password}
                    disabled
                    placeholder=""
                    className="border w-[420px] h-[38px] rounded-lg border-[#D0D5DD] mt-1.5 text-[20px]"
                    onChange={handleChange}
                  /> */}
                  <input 
                    className={"font-bold leading-3 !pt-[12px] !pb-[8px] text-black !rounded-lg cursor-pointer focus:outline-none"}
                    type={"text"}
                    value={"*******************"}
                    disabled
                  />
                  {/* {errors.password && touched.password ? (
      <div className='text-RED-_100'>{errors.password}</div>
      ) : null} */}
                </div>
                <button
                  className="w-[118px] h-[34px] bg-BLACK-_500 rounded-lg px-2 py-1"
                  type="submit"
                >
                  {loading ? (
                    <CgSpinner className=" animate-spin text-lg text-WHITE-_100 mx-auto " />
                  ) : (
                    <p className="text-WHITE-_100 text-xs">Save Changes</p>
                  )}
                </button>
              </div>
            </Form>
            )
          }}
        </Formik>
      </div>

      <ModalPop isOpen={openPassword}>
        <ChangePassword handleClose={() => setOpenPassword(false)} />
      </ModalPop>
      <ModalPop isOpen={showUnsavedSettingsModal}>
        <UnsavedSettings 
          handleClose={discardChanges} 
          handleSave={() => {saveChanges()}}
          close={() => closeModal()}
          loading={loading}
        />
      </ModalPop>

      {updateSuccess && (
        <SuccessMessage
          Formodal={true}
          success="Profile Updated Successfully"
          className="mt-24 mr-20"
        />
      )}
    </div>
  );
};

export default Personal;

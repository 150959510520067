import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Form, Formik } from "formik";
import axios from "axios";
import { useSelector } from "react-redux";
import TokenService from "../../../services/token";
import { useState, useEffect } from "react";
import { CgSpinner } from "react-icons/cg";
import {
  SuccessMessage,
  ErrorMessage,
} from "../../../Toast-Messages/Toastmessages";

const LegalTemplate = ({ handleClose }) => {
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const data = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = data && data.length >= 1 ? data[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [fetchedData, setFetchedData] = useState([]);

  const submitForm = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${URL}/dashboard/applications/${applicationId}/memory-template`,
        {
          data: [
            {
              question: "What are your business’s terms and conditions?",
              category: "legal",
              answer: values.answerOne,
            },
            {
              question:
                "Are there any specific licenses/certifications your business holds?",
              category: "legal",
              answer: values.answerTwo,
            },
            {
              question:
                "How do you ensure security and privacy of customer data?",
              category: "legal",
              answer: values.answerThree,
            },
            {
              question: "Explain your business privacy policy if any",
              category: "legal",
              answer: values.answerFour,
            },
            {
              question:
                "Anything else you’d like your customers to know about your business?",
              category: "legal",
              answer: values.answerFive,
            },
          ].filter(each=>each.answer),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      setSuccess("Successful: record created successfully");
      setLoading(false);
      setTimeout(() => {
        setSuccess(false);
        handleClose();
      }, 2500);
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
      setError("Error uploading the form. Please try again later.");
      setTimeout(() => {
        setError(false);
      }, 2500);
    }
  };
     const fetchData = async () => {
       try {
         const response = await axios.get(
           `${URL}/dashboard/applications/${applicationId}/memory-template`,
           {
             headers: {
               Authorization: `Bearer ${token}`,
             },
           }
         );
         setFetchedData(response.data.data);
       } catch (error) {
         console.error("Error fetching data:", error);
       }
     };

     useEffect(() => {
       fetchData();
     }, []);

     const getAnswerByCategoryAndQuestion = (category, question) => {
       const foundItem = fetchedData.find(
         (item) => item.category === category && item.question === question
       );
       return foundItem ? foundItem.answer : "";
     };

  return (
    <div className="bg-WHITE-_100 w-[946px] mt-[30px] rounded-lg overflow-y-scroll">
      <div className="flex justify-between h-[80px] items-center mx-4 my-3 ">
        <div className="w-[421px] h-[57px] flex flex-col gap-1 ml-6">
          <p className="font-medium text-2xl text-BLACK-_600">
            Legal - Template Guide
          </p>
          <p className="text-xs leading-5 font-normal  text-BLACK-_200">
            Fill the template with the necessary information.
          </p>
        </div>
        <button
          className="w-[36px] h-[36px] border rounded-lg flex justify-center items-center p-2"
          onClick={handleClose}
        >
          <AiOutlineClose />
        </button>
      </div>
      <hr />

      <div className="mt-[30px] ml-6 py-10">
        <Formik
          enableReinitialize
          initialValues={{
            answerOne: getAnswerByCategoryAndQuestion(
              "legal",
              "What are your business’s terms and conditions?"
            ),
            answerTwo: getAnswerByCategoryAndQuestion(
              "legal",
              "Are there any specific licenses/certifications your business holds?"
            ),
            answerThree: getAnswerByCategoryAndQuestion(
              "legal",
              "How do you ensure security and privacy of customer data?"
            ),
            answerFour: getAnswerByCategoryAndQuestion(
              "legal",
              "Explain your business privacy policy if any"
            ),
            answerFive: getAnswerByCategoryAndQuestion(
              "legal",
              "Anything else you’d like your customers to know about your business?"
            ),
          }}
          // validationSchema={formValidationSchema}
          onSubmit={(values) => {
            console.log(values, "otp");
            window.scrollTo(0, 0);
            submitForm(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            dirty,
            isValid,
            setFieldValue,
            errors,
            touched,
            // setFieldTouched,
            values,
          }) => (
            <Form onSubmit={handleSubmit} className="flex justify-center">
              <div className="flex flex-col items-center gap-[40px] ">
                <div className="flex flex-col gap-6">
                  <div className="w-[866px] flex flex-col">
                    <p className="label text-left text-[#121212] text-sm font-medium">
                      Q1. What are your business’s terms and conditions?
                    </p>
                  </div>
                  <div className="w-[866px] flex flex-col">
                    <div className="flex flex-col lg:w-[866px] gap-2">
                      <label className="label text-left text-[#101828] text-xs font-normal">
                        Answer
                      </label>
                      <textarea
                        name="answerOne"
                        type="text"
                        cols="50"
                        rows="10"
                        className=" outline-none rounded-lg border border-[#D0D5DD] p-1"
                        value={values?.answerOne}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    {/* {errors.answerOne && touched.answerOne ? (
                                <div className='text-RED-_100 text-xs'>{errors.answerOne}</div>
                                ) : null} */}
                  </div>
                </div>

                <div className="flex flex-col gap-6">
                  <div className="w-[866px] flex flex-col">
                    <p className="label text-left text-[#121212] text-sm font-medium">
                      Q2. Are there any specific licenses/certifications your
                      business holds?
                    </p>
                  </div>
                  <div className="w-[866px] flex flex-col">
                    <div className="flex flex-col lg:w-[866px] gap-2">
                      <label className="label text-left text-[#101828] text-xs font-normal">
                        Answer
                      </label>
                      <textarea
                        name="answerTwo"
                        type="text"
                        cols="50"
                        rows="10"
                        className=" outline-none rounded-lg border border-[#D0D5DD] p-1"
                        value={values?.answerTwo}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    {/* {errors.answerTwo && touched.answerTwo ? (
                                <div className='text-RED-_100 text-xs'>{errors.answerTwo}</div>
                                ) : null} */}
                  </div>
                </div>

                <div className="flex flex-col gap-6">
                  <div className="w-[866px] flex flex-col">
                    <p className="label text-left text-[#121212] text-sm font-medium">
                      Q3. How do you ensure security and privacy of customer
                      data?
                    </p>
                  </div>
                  <div className="w-[866px] flex flex-col">
                    <div className="flex flex-col lg:w-[866px] gap-2">
                      <label className="label text-left text-[#101828] text-xs font-normal">
                        Answer
                      </label>
                      <textarea
                        name="answerThree"
                        type="text"
                        cols="50"
                        rows="10"
                        className=" outline-none rounded-lg border border-[#D0D5DD] p-1"
                        value={values?.answerThree}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    {/* {errors.answerThree && touched.answerThree ? (
                                <div className='text-RED-_100 text-xs'>{errors.answerThree}</div>
                                ) : null} */}
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <div className="w-[866px] flex flex-col">
                    <p className="label text-left text-[#121212] text-sm font-medium">
                      Q4. Explain your business privacy policy if any
                    </p>
                  </div>
                  <div className="w-[866px] flex flex-col">
                    <div className="flex flex-col lg:w-[866px] gap-2">
                      <label className="label text-left text-[#101828] text-xs font-normal">
                        Answer
                      </label>
                      <textarea
                        name="answerFour"
                        type="text"
                        cols="50"
                        rows="10"
                        className=" outline-none rounded-lg border border-[#D0D5DD] p-1"
                        value={values?.answerFour}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    {/* {errors.answerFour && touched.answerFour ? (
                                <div className='text-RED-_100 text-xs'>{errors.answerFour}</div>
                                ) : null} */}
                  </div>
                </div>

                <div className="flex flex-col gap-6">
                  <div className="w-[866px] flex flex-col">
                    <p className="label text-left text-[#121212] text-sm font-medium">
                      Anything else you’d like your customers to know about your
                      business?
                    </p>
                  </div>
                  <div className="w-[866px] flex flex-col">
                    <div className="flex flex-col lg:w-[866px] gap-2">
                      <label className="label text-left text-[#101828] text-xs font-normal">
                        Answer
                      </label>
                      <textarea
                        name="answerFive"
                        type="text"
                        cols="50"
                        rows="10"
                        className=" outline-none rounded-lg border border-[#D0D5DD] p-1"
                        value={values?.answerFive}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    {/* {errors.answerFive && touched.answerFive ? (
                                <div className='text-RED-_100 text-xs'>{errors.answerFive}</div>
                                ) : null} */}
                  </div>
                </div>

                <div className="w-[886px] flex gap-2 justify-end">
                  <button
                    className="bg-WHITE-_100 border border-solid border-[#D0D5DD] w-[78px] h-[34px] rounded-lg"
                    onClick={handleClose}
                  >
                    <p className="text-[#828282] text-[12px] font-medium">
                      Cancel
                    </p>
                  </button>
                  <button
                    disabled={loading}
                    type="submit"
                    className="bg-BLACK-_500 w-[79px] h-[34px] rounded-lg"
                  >
                    {loading ? (
                      <span className="flex justify-center w-full">
                        <CgSpinner className=" animate-spin text-lg text-WHITE-_100" />
                      </span>
                    ) : (
                      <p className="text-WHITE-_100 text-[12px] font-medium">
                        Upload
                      </p>
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {success && <SuccessMessage success={success} />}
      {error && <ErrorMessage error={error} />}
    </div>
  );
};

export default LegalTemplate;

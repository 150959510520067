import React from 'react'
import { Form, Formik } from 'formik'
import { AiOutlineClose } from 'react-icons/ai'
import * as Yup from "yup"
import { CgSpinner } from 'react-icons/cg'

const Name = ({ handleClose, loading, handleSubmitAgent }) => {
  const formValidationSchema = Yup.object().shape({
    agentName: Yup.string().required(),
    description: Yup.string().required()
  })

  const submitForm = (values) => {
    handleSubmitAgent(values);
  };

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  return (    
      <div className="bg-WHITE-_100 rounded-lg w-[520px] h-[460px] mt-[100px]">
          <div className='w-full flex justify-end'>
            <div className="w-[36px] h-[36px] border rounded-lg mr-3 mt-3 flex cursor-pointer justify-center items-center p-2" onClick={handleClose}><AiOutlineClose /></div>
          </div>
          <div className="flex justify-center flex-col gap-4 py-[5px] px-[50px] items-center">
              <div className="w-[192px] ">
                  <p className="text-BLACK-_600 font-medium text-2xl">Name your Assistant</p>
                  <p className="text-xs text-center text-BLACK-_300">Let’s give your assistant a name.</p>
              </div>
              <div className="flex flex-col">
                <Formik
                  initialValues={{
                      agentName: "",
                      description: ""
                  }}
                    validationSchema={formValidationSchema}
                  onSubmit={(values) => {
                    window.scrollTo(0, 0)
                    submitForm(values)
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    dirty,
                    isValid,
                    setFieldValue,
                    errors,
                    touched,
                    // setFieldTouched,
                    values,
                  }) => (
                    <Form onSubmit={handleSubmit} className="flex flex-col lg:items-center">
                      <div className='flex flex-col gap-4 '>
                          <div className='w-[420px] flex flex-col '>
                              <div className="flex flex-col lg:w-[420px] gap-2">
                                <label className="label text-[#101828] text-xs font-normal">Assistant Name</label>
                                <input 
                                  name='agentName'
                                  type ="text" 
                                  className='outline-none' 
                                  value={values?.agentName} 
                                  onChange={handleChange}
                                />
                              </div>
                                {errors.agentName && touched.agentName ? (
                                  <div className='text-RED-_100 text-xs'>{errors.agentName}</div>
                                  ) : null}
                          </div>
                          <div className='w-[420px] flex flex-col '>
                              <div className="flex flex-col lg:w-[420px] gap-2">
                                <label className="label text-[#101828] text-xs font-normal">Description</label>
                                <textarea 
                                  name='description'
                                  type ="text" 
                                  cols="50"
                                  rows="4"
                                  className=" outline-none rounded-lg border border-[#D0D5DD] p-1"
                                  value={values?.description} 
                                  onChange={handleChange}
                                ></textarea>
                              </div>
                                {errors.description && touched.description ? (
                                  <div className='text-RED-_100 text-xs'>{errors.description}</div>
                                  ) : null}
                          </div>

                          

                          <div className='flex flex-col '>
                              
                              <button 
                                className="w-[100px] h-[40px] flex mx-auto mt-5 justify-center items-center bg-BLACK-_500  p-2 rounded-lg" 
                                type="submit"
                              >
                               <p className='text-WHITE-_100 text-sm font-bold'>{loading ? <CgSpinner className=" animate-spin text-lg " /> : 'Continue'}</p>
                              </button>
                          </div>
                      
                      </div>
                      

                    </Form>
              )}
                </Formik>
              </div>

          </div>
        </div>
  )
}

export default Name
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { Form, Formik } from "formik"
import ModalPop from '../../modalPop';
import Success from './whatsapp/Success';

const PhoneVerify = ({ handleClose, deployAgent }) => {
    const [openModal, setOpenModal] = useState(false)
  return (
    <div className="bg-WHITE-_100 w-[522px] h-[365px] py-[30px] mt-[60px] flex flex-col justify-center items-center rounded-lg">
        <div className="flex flex-col justify-center items-center gap-2 w-[336px] h-[54px]">
            <p className="text-2xl font-medium text-BLACK-_600">Verify Phone Number</p>
            <p className="text-sm font-normal text-center text-BLACK-_300">
                {
                    deployAgent ? 
                      "An SMS OTP has been sent to the phone you provided. Please input the code here." 
                    : "A WhatsApp OTP has been sent to the number you provided. Please input the code here."
                }
                
            </p>
        </div>
        <div className="w-[420px] h-[151px] mt-[30px]">
            <Formik
                initialValues={{
                    otpCode: "",
                }}
                // validationSchema={formValidationSchema}
                onSubmit={(values) => {
                    // window.scrollTo(0, 0)
                    // submitForm(values)
                    if(deployAgent) {
                        setOpenModal(true) 
                    } else {
                        handleClose()
                    }
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    dirty,
                    isValid,
                    setFieldValue,
                    errors,
                    touched,
                    // setFieldTouched,
                    values,
                }) => (
                    <Form onSubmit={handleSubmit} className="flex justify-center">
                        <div className='flex flex-col items-center gap-6 '>
            
                            <div className="mb-2">
                                <label htmlFor='Otp' className="text-sm font-normal text-[#101828]">OTP</label>
                                    {/* Tablet and Laptop */}
                                <div className="mt-3 xs:hidden md:block">
                                    <OtpInput
                                        name="otpCode"
                                        value={values.otpCode}
                                        onChange={(e) => setFieldValue("otpCode", e)}
                                        // placeholder="123456"
                                        numInputs={6}
                                        renderSeparator={<span style={{ width: "8px" }}></span>}
                                        renderInput={(props) => <input {...props} />}
                                        isInputNum={true}
                                        shouldAutoFocus={true}
                                        inputStyle={{
                                            border: "1px solid #D0D5DD",
                                            boxShadow: "0px 0.92453px 1.84906px 0px rgba(16, 24, 40, 0.05)",
                                            borderRadius: "8px",
                                            width: "59px",
                                            height: "59px",
                                            fontSize: "16px",
                                            color: "#000",
                                            fontWeight: "400",
                                            caretColor: "black",
                                            outline: "none"
                                        }}
                                        focusStyle={{
                                            border: "1px solid #D0D5DD",
                                            outline: "none",
                                        }}
                                    />
                                    {/* {errors.otpCode && touched.otpCode ? (
                                        <div className='text-RED-_100'>{errors.otpCode}</div>   
                                    ) : null} */}
                                </div>
                            </div>

                            <button 
                                className="w-[420px] h-[40px] py-3 px-5 rounded-lg flex justify-center items-center text-bold bg-BLACK-_500 text-WHITE-_100 text-sm"
                                type="submit"
                                // onClick={handleClose}
                            >
                                Verify
                            </button>
                        
                        </div>
                        

                    </Form>
                )}
            </Formik>
        </div>

        <div className='flex mt-[40px]'>
            <p className='flex text-sm font-normal text-BLACK-_300'>
                Didn’t get an OTP?
            <span className='font-bold text-sm text-BLUE-_200 ml-0.5 cursor-pointer'> 
                Resend verification code
            </span>
            </p>
        </div>

        <ModalPop isOpen={openModal}>
            <Success handleClose={() => setOpenModal(false)} />
        </ModalPop>

    </div>
  )
}

export default PhoneVerify
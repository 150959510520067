import { ErrorMessage, SuccessMessage } from '@/../Toast-Messages/Toastmessages';
import { getAllPlugins } from '@/../features/plugins/getAllPluginsSlice';
import { api } from '@/../services/api';
import { Switch } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react'
import { CgSpinner } from 'react-icons/cg';
import { HiXMark } from 'react-icons/hi2';
import { useDispatch } from 'react-redux';

const LanguageModal = ({data, labelA, labelB, handleClose}) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({})
    const [loading, setLoading] = useState(false)
    const [pluginsNotificationSuccess, setPluginsNotificationSuccess] = useState(false);
    const [pluginsNotificationError, setPluginsNotificationError] = useState(false);

    const appId  = JSON.parse(localStorage.getItem("wano_agent"));
    const applicationId = appId?.id
    const id = data?.id 

    const check_setup = ""

    // console.log(formData, "formData");


    const inputLabels = {
        slack_token: "Token",
        slack_channel_id: "Channel ID",
        calendly_token: "Api Key",
        hubspot_base_url: "Base URL",
        hubspot_api_key: "Api Key",
    };

    const languages = ["English", "French", "Hausa", "Igbo", "Pidgin", "Portuguese", "Spanish", "Swahili", "Yoruba"]


    const ConnectPlugin = async () => {
        setLoading(true)
        await api.put(`dashboard/applications/${applicationId}/plugins/${id}/configuration`, {...formData})
        .then((res) => {
            setLoading(false)
            localStorage.setItem("pluginState", "All Plugins")
            setPluginsNotificationSuccess(true)
            dispatch(getAllPlugins({ applicationId }));
            setTimeout(() => {
                handleClose()
            }, 2000);
        })
        .catch((err) => {
            setLoading(false)
            setPluginsNotificationError(true)
            // console.log(err, "err")
        })
    }


    const removePluginsNotificationSuccess = () => {
        setTimeout(() => {
            setPluginsNotificationSuccess(false)
        }, 1000);
    }

    const removePluginsNotificationError = () => {
        setTimeout(() => {
            setPluginsNotificationError(false)
        }, 1000);
    }

    const error = "false"
    const message = "Plugin Installed Successfully"

    useEffect(() => {
        removePluginsNotificationSuccess()
    }, [pluginsNotificationSuccess])

    useEffect(() => {
        removePluginsNotificationError()
    }, [pluginsNotificationError])

  return (
    <div className='bg-[#fff] w-[501px] h-fit flex flex-col rounded-lg mt-[100px] gap-[20px]'>
        <div className='pt-[15px] px-[25px] flex items-center justify-between rounded-lg'>{/* w-[48px] h-[48px] border-b-[#E5E5E5] border pt-[30px] px-[50px]*/}
            <h2 className='text-[#101828] text-lg font-medium'>Languages</h2>
            <button
                type='button'
                tabIndex={0} 
                onClick={() => handleClose()} 
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleClose();
                    }
                }} 
                className="border p-2 -mt-1 rounded-lg border-[#F7F7F7] cursor-pointer"
            >
                <HiXMark className="text-[20px] text-[#828282]" />
            </button> 
        </div>
        <div className='hide-scrollbar flex flex-col gap-5 border-t-[1px] border-[#E5E5E5] py-[15px] px-[25px]'>{/*  style={{padding: "24px 0px 10px 0px"}} py-[30px] px-[45px] */}
            {/* <img src={data?.image_url} alt='bank' className='w-[48px] h-[48px]' /> 
            <div>
                <p className='text-[#101828] text-lg'>Connect to your {data?.name} Account</p>
                <p className='text-[#7F7F81] text-xs'>Provide the information below to allow us connect to your {data?.name} Account</p>
            </div> */}
            <div className='flex flex-col gap-5 h-[592px] overflow-y-scroll scroll-m-1'>
                {Object.entries(languages).map(([key, value]) => {
                    // if (key === "type") return null; 
                    // {key.replace(/_/g, ' ')}
                    // const label = inputLabels[key] || key;
                    return (
                        <div key={key} className='w-full flex flex-col justify-between px-2 py-5 bg-[#FAFAFA80] rounded-lg'>
                            <p htmlFor={key} className='text-sm text-[#121212]'>{value}</p>
                            {/* <input name={key} type="text" value={value} className='w-full focus:outline-none' onChange={handleChange}/>
                            <Switch as={Fragment}>
                                {({ checked }) => (
                                    <button
                                    className={`${
                                        checked
                                        ? "bg-blue-600"
                                        : check_setup
                                        ? "bg-blue-600"
                                        : "bg-gray-200"
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                    onClick={toggleSwitch}
                                    >
                                    <span className="sr-only">Enable notifications</span>
                                    <span
                                        className={`${
                                        checked
                                            ? "translate-x-6"
                                            : check_setup
                                            ? "translate-x-6"
                                            : "translate-x-1"
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                    </button>
                                )}
                            </Switch> /* Use the `checked` state to conditionally style the button.  mb-[180px]*/}
                        </div>
                    );
                })}
            </div>
            {/* <div className='w-full flex gap-[12px] justify-end mt-[20px]'>
                <button 
                    className="w-fit h-[37px] py-3 px-5 rounded-lg border-[#E5E5E5] border flex justify-center items-center font-medium  text-[#868686] text-sm"
                    type="button"
                    onClick={() => handleClose()}
                >
                    Close
                </button>
                <button 
                    className="w-[95px] h-[37px] py-3 px-5 rounded-lg flex justify-center items-center font-medium bg-BLACK-_500 text-WHITE-_100 text-sm"
                    type="button"
                    onClick={() => ConnectPlugin()}
                >
                    <p className='text-WHITE-_100 text-sm font-bold'>{loading ? <CgSpinner className=" animate-spin text-lg " /> : 'Connect'}</p>  
                </button>
            </div> */}

        </div>

        {pluginsNotificationSuccess && <SuccessMessage Formodal={true} success={message} className="mt-28 mr-12"/>}
        {pluginsNotificationError && <ErrorMessage Formodal={true} error={error} className="mt-28 mr-12"/>}
    </div>
  )
}

export default LanguageModal
import TemplateIcon from "../../Assets/svg/template.svg";
import { message, Upload } from "antd";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { Switch } from "@headlessui/react";
import { useState, useEffect } from "react";
import { Popover } from "@headlessui/react";
import { Eye, Delete } from "../../Assets/svg";
import DeleteSourceModal from "./DeleteSourceModal";
import ProductTemplate from "../../component/modals/agentMemoryTemplate/ProductTemplate";
import ModalPop from "../../component/modalPop";
import UploadMemory from "../../component/modals/upload";
import axios from "axios";
import deleteIcon from "../../Assets/svg/delete.svg";
import { useSelector,useDispatch } from "react-redux";
import { setMemoryExists,showTestemployeeTour } from "@/../features/testAgent/chatSlice";
import TokenService from "../../services/token";
import { ServiceFeaturesCard } from "../../LoadingSkeleton";
import { CgSpinner } from "react-icons/cg";
import { useNavigate } from "react-router";
import uploadIcon from "../../Assets/img/uploadIcon.svg";
import docxIcon from "../../Assets/svg/docx.svg";
import {
  SuccessMessage,
  ErrorMessage,
} from "../../Toast-Messages/Toastmessages";
import ConfirmModal from "./ConfirmModal";
import CustomFeatures from "./CustomFeatures";
import {
  getApplicationDraft
} from "features/application/getApplicationByIdSlice";
export default function Product() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openTemplate, setOpenTemplate] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const data = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = data && data.length >= 1 ? data[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const purpose = agentDetails.type;
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState({});
  const [publishLoading, setPublishLoading] = useState({});
  const [file, setFile] = useState();
  const [fileList, setFileList] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const getConfirmModalStatus = localStorage.getItem("confirmModal");

  const [viewFeatures, setViewFeatures] = useState(false);
  const [features, setFeatures] = useState([]);

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  useEffect(()=>{
    setTimeout(() => {
    setSuccess('')
    }, 4000);
  },[success])
  const handleCheckboxChange = (id) => {
    const updatedCheckedItems = checkedItems.includes(id)
      ? checkedItems.filter((item) => item !== id)
      : [...checkedItems, id];

    setCheckedItems(updatedCheckedItems);
    setSelectAllChecked(updatedCheckedItems.length === fetchedData.length);
  };

  const handleSelectAllChange = (e) => {
    setSelectAllChecked(e.target.checked);
    setCheckedItems(e.target.checked ? fetchedData.map((item) => item.id) : []);
  };
  const fetchdata = async () => {
    if (!applicationId) {
      console.error(
        "No applicationId provided. fetchData function will not run."
      );
      return;
    }
    try {
      const response = await axios.get(
        `${URL}/dashboard/applications/${applicationId}/memory`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const filteredData = response.data.data.filter(
        (item) => item.purpose === "product_and_service"
      );
      setLoading(false);
      setFetchedData(filteredData);
      if (response?.data?.data.length > 0) {
        dispatch(setMemoryExists(true));
        if(applicationId){
          dispatch(getApplicationDraft(applicationId));
          }
        if (response?.data?.data.length === 1) {
          dispatch(showTestemployeeTour(true));
        }
      } else {
        dispatch(setMemoryExists(false));
        dispatch(showTestemployeeTour(false));
      }
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/");
        // localStorage.clear();
      }
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchdata();
  }, [deleteLoading, publishLoading]);

  const uploadFile = (data) => {
    setFile(data);
  };

  const { Dragger } = Upload;
  const props = {
    name: "file",
    multiple: false,
    fileList,
    beforeUpload: (file) => {
      // const isPDF = file.type === "application/pdf";
      const isDOC = file.type === "application/msword";
      const isDOCX =
        file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      if (!isDOC && !isDOCX) {
        message.error("You can only upload Doc files!");
        setFileList([{ ...file, status: "error", name: file.name }]);
      } else {
        setFileList([{ ...file, status: "success", name: file.name }]);
        setFile(file);
      }
      return false;
    },
    customRequest: ({ file, onSuccess }) => {
      uploadFile(file);
      onSuccess();
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
    },
    onRemove(file) {
      setFileList([]);
      setFile();
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { month: "numeric", day: "numeric", year: "2-digit" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  const [deleteModal, setDeleteModal] = useState(false);
  const [sourceId, setSourceId] = useState("");
  const handleDeleteSource = async ({ applicationAssistantFileId }) => {
    try {
      setDeleteLoading((prev) => ({
        ...prev,
        [applicationAssistantFileId]: true,
      }));
      await axios.delete(
        `${URL}/dashboard/applications/${applicationId}/memory/${applicationAssistantFileId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDeleteLoading((prev) => ({
        ...prev,
        [applicationAssistantFileId]: false,
      }));
      setSuccess("Resource deleted successfully");

      setTimeout(() => {
        setSuccess("");
      }, 3000);
    } catch (error) {
      setDeleteLoading((prev) => ({
        ...prev,
        [applicationAssistantFileId]: false,
      }));
      setTimeout(() => {
        setError("Error deleting resource");
      }, 1000);
      if (error.response.status === 401) {
        navigate("/");
        // localStorage.clear();
      }
    }

    finally {
      setDeleteModal(false);
    }
  };

  const handleUploadTrain = async () => {
    if (!file) {
      message.error("No file uploaded");
      return;
    }
    setOpenUpload(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("purpose", "product_and_service");

    try {
      const response = await axios.post(
        `${URL}/dashboard/applications/${applicationId}/memory`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );


      if (response?.status) {
        message.success(`${file.name} file uploaded successfully.`);
        setFeatures(response?.data?.data?.features);
        fetchdata();
        setOpenUpload(false);
        setConfirm(false);
        setFileList([]);
        setFile();
      } else {
        message.error(`${file.name} file upload failed.`);
        setOpenUpload(false);
      }
    } catch (error) {
      console.log(error);
      message.error(`${file.name} file upload failed.`);

      let feat = {
        data: {
          features: [],
        },
      };

      setFeatures(feat?.data?.features);
      fetchdata();
      setOpenUpload(false);
      setFileList([]);
      setFile();
      setViewFeatures(false);
      // if (error.response.status === 401) {
      //   navigate('/')
      //   localStorage.clear()
      // }
      // message.error(`${file.name} file upload failed.`);
    }
  };

  const handleActivation = async (id) => {
    try {
      setPublishLoading((prev) => ({ ...prev, [id]: true }));
      const response = await axios.put(
        `${URL}/dashboard/applications/${applicationId}/memory/${id}/publish`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPublishLoading((prev) => ({ ...prev, [id]: false }));
      setTimeout(() => {
        setSuccess(response.data.message);
      }, 1000);
      if(applicationId){
      dispatch(getApplicationDraft(applicationId));
      }
    } catch (error) {
      setPublishLoading((prev) => ({ ...prev, [id]: false }));
      setTimeout(() => {
        setError(error.response.message);
      }, 1000);
      if (error.response.status === 401) {
        navigate("/");
        // localStorage.clear();
      }
    }
  };

  return (
    <main>
      <section className=" my-4">
        <div className=" text-xs text-[#7F7F81] font-normal flex justify-end ">
          <span>Don’t have a PRODUCT/SERVICES document prepared already?</span>
          <span
            className="text-[#0359D8] cursor-pointer ml-2"
            onClick={() => setOpenTemplate(true)}
          >
            Use Template
          </span>{" "}
          <span className=" ml-1">
            <img
              src={TemplateIcon}
              alt="template"
              className="w-[13px] h-[13px]"
            />
          </span>
        </div>
        <div className=" mt-2">
       
          <Dragger {...props}>
          {fileList.length ? (
              <div className="flex flex-col items-center gap-2 py-8 bg-ant">
                <img src={docxIcon} alt="template" className="w-8 h-8" />
                <p className="text-BLACK-_200 text-sm">
                  {!!fileList.length && fileList[0].name}
                </p>
                <div
                  className="flex items-center gap-2 text-RED-_100 text-sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    setFileList([]);
                    setFile();
                  }}
                >
                  <p>Remove Document</p>
                  <img src={deleteIcon} alt="template" className="w-3 h-3" />
                </div>
              </div>
            ) : (
              <main className=" py-8 px-[107px] flex items-center flex-col gap-2 select-none">
                <img src={uploadIcon} alt="template" className="w-4 h-4" />

                <p className="text-[#7F7F81] font-normal text-xs">
                  Drag and drop PRODUCT/SERVICES document here or upload from your device
                </p>
                <p className="text-[#A7A7A7] font-normal text-[10px]">
                  Supported file: Doc, 100mb Max
                </p>
              </main>
            )}
          </Dragger>
        </div>
        {/* <p className=" py-4 text-[#7F7F81] text-xs font-normal select-none text-center w-[90%]">
          Or
        </p> */}
        <section className="flex  flex-col items-center gap-3">
          {/* <div className="relative">
            <input
              className=" w-[608px] h-[38px] pl-[14px] text-[10px] outline-none font-normal pr-10"
              type="text"
              placeholder="https://www.wano.app"
            />
            <RiDeleteBin7Line className="text-[#D92D20] absolute right-3 top-3 cursor-pointer" />
          </div>
          <div className="relative">
            <input
              className=" w-[608px] h-[38px] pl-[14px] text-[10px] outline-none font-normal pr-10"
              type="text"
              placeholder="Enter link here"
            />
            <RiDeleteBin7Line className="text-[#D92D20] absolute right-3 top-3 cursor-pointer" />
          </div>
          <div className="w-[608px]">
            <p className=" text-right text-[#1774FD] text-[10px] font-medium">
              Add new Link
            </p>
          </div> */}
          <button
            disabled={!file}
            className={`${
              !file ? "bg-DISABLED" : "bg-[#121212]"
            } mt-4 text-[white] py-3 px-4 rounded-lg text-sm`}
            onClick={() => {
              getConfirmModalStatus ? handleUploadTrain() : setConfirm(true);
            }}
          >
            {!file ? "Upload and Train" : "Upload and Train"}
          </button>
        </section>
        <section className="mt-8 mb-7">
          <p className=" text-lg font-medium text-[#121212]">Your Sources</p>
        </section>
        <table className="fixed-width-table">
          <tr>
            <th>
              <div className="flex items-center">Source</div>
            </th>
            <th>File type</th>
            <th>Status</th>
            {/* <th className="bg-[#E9E9EA] py-[10px] text-left text-sm text-[#7F7F81] font-semibold">
              Sync
            </th> */}
            <th>Visibility</th>
            <th>Action</th>
          </tr>
          {!loading && fetchedData.length === 0 ? (
            <tr>
              <td colSpan={6} className="w-full pt-16">
                <p className="text-[rgba(130,130,130,1)] text-lg font-medium text-center">
                  No Memory Records Found in Product/Services Section
                </p>
                <p className=" text-center text-sm font-normal text-[#828282]">
                  It seems we don't have any records of your Assistant's memory at
                  the moment.
                </p>
              </td>
            </tr>
          ) : (
            fetchedData &&
            fetchedData.map((data, index) => (
              <tr key={index} className="border-b border-[#F7F7F7]">
                <td className="flex items-center pl-[20px] py-[20px] text-[#121212] font-normal text-sm">
                  <span className=" truncate ">{data.name}</span>
                </td>
                <td className="text-[#121212] font-normal text-sm capitalize">
                  {data?.name.split(".")?.slice(-1)}
                </td>
                <td className="text-[#121212] font-normal text-sm">
                  <div className=" text-[#057601] text-xs font-normal px-2 py-1 rounded-[28px] border border-solid border-[#059c00] bg-[#BBFDB9] w-min">
                    {data?.status}
                  </div>
                </td>
                {/* <td>
                  <div className="flex flex-col">
                    <p className="text-[#0359D8] font-semibold text-[11px] flex gap-1 items-center">
                      <MdRefresh className=" text-[14px]" />
                      Sync Website
                    </p>
                    <p className=" text-[10px] font-medium text-[#A7A7A7]">
                      Last sync: {fetchedData && formatDate(data.updated_at)}
                    </p>
                  </div>
                </td> */}
                <td className="text-[#121212] font-normal text-sm">
                  {publishLoading[data.id] ? (
                    <CgSpinner className="animate-spin text-lg" />
                  ) : (
                    <Switch
                    checked={data?.draft ? data.draft?.active : data?.active}
                    onChange={() => handleActivation(data.id)}
                    className={`${
                      (data.draft ? data.draft.active : data.active) ? "bg-blue-600" : "bg-gray-200"
                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span className="sr-only">Enable notifications</span>
                    <span
                      className={`${
                        (data.draft ? data.draft.active : data.active) ? "translate-x-6" : "translate-x-1"
                      } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                  )}
                </td>

                <td className="text-[#121212] font-normal text-sm">
                  <Popover className="relative">
                    <Popover.Button className="outline-none">
                      <PiDotsThreeVerticalBold className="cursor-pointer text-base text-[#121212]" />
                    </Popover.Button>
                    <Popover.Panel>
                      <Popover.Button className="cursor-pointer py-[5px] px-[10px] rounded-lg z-10 absolute bg-white shadow-md right-3 flex flex-col gap-3">
                        <a
                          href={data.file_url}
                          target="_blank"
                          rel="noreferrer"
                          className="flex items-center gap-3"
                        >
                          <Eye />
                          <p className="text-xs text-[#121212] font-figtree">
                            View
                          </p>
                        </a>

                        <div className="flex gap-2">
                          {deleteLoading[data.id] ? (
                            <CgSpinner className="animate-spin text-lg" />
                          ) : (
                            <div
                              className="flex gap-3"
                              onClick={(e) => {
                                setSourceId(data.id);
                                setDeleteModal(true); 
                              }}
                            >
                              <Delete />
                              <p className="text-xs text-[#121212] font-figtree">
                                Delete
                              </p>
                            </div>
                          )}
                        </div>
                      </Popover.Button>
                    </Popover.Panel>
                  </Popover>
                </td>
              </tr>
            ))
          )}
        </table>
        {loading && (
          <div className=" flex justify-center w-[85%] ">
            <ServiceFeaturesCard />
            <ServiceFeaturesCard />
          </div>
        )}
      </section>
      <ModalPop isOpen={deleteModal}>
        <DeleteSourceModal
          handleClose={() => setDeleteModal(false)}
          deleteSource={() =>
            handleDeleteSource({
              applicationAssistantFileId: sourceId,
            })
          }
          loading={deleteLoading[sourceId]}
        />
      </ModalPop>
      <ModalPop isOpen={confirm}>
        <ConfirmModal
          handleClose={() => setConfirm(false)}
          handleDontShow={() => {
            localStorage.setItem("confirmModal", true);
            setConfirm(false);
          }}
          handleUploadTrain={() => {
            setConfirm(false);
            handleUploadTrain();
          }}
          purpose={"Product/Services"}
          id={applicationId}
        />
      </ModalPop>

      <ModalPop isOpen={viewFeatures}>
        <CustomFeatures
          data={features}
          handleClose={() => setViewFeatures(false)}
        />
      </ModalPop>

      <ModalPop isOpen={openUpload}>
        <UploadMemory handleClose={() => setOpenUpload(false)} />
      </ModalPop>

      <ModalPop isOpen={openTemplate}>
        <ProductTemplate handleClose={() => {setOpenTemplate(false);fetchdata()}} />
      </ModalPop>
      {success && <SuccessMessage success={success} />}
      {error && <ErrorMessage error={error} />}
    </main>
  );
}

import { FaCheckCircle } from "react-icons/fa";
import {MdCancel} from 'react-icons/md'
import { RiErrorWarningFill } from "react-icons/ri"
export function SuccessMessage({ success, Formodal, className }) {
  return (
    <main
      className={`${className} py-3 px-4 rounded-lg bg-[#1774FD] flex items-center gap-3 font-figtree z-50 fixed shadow-xl ${
        Formodal ? " -top-7 -right-9" : "top-2 right-4 "
      }`}
    >
      <FaCheckCircle className="text-white text-xl" />
      <div>
        <p className=" font-semibold text-base text-white">Successful!</p>
        <p className=" text-[#F2F2F2] text-sm font-normal w-auto">{success}</p>
      </div>
    </main>
  );
}
export function ErrorMessage({error, Formodal, className}) {
  return (
    <main
      className={`${className} py-3 px-4 rounded-lg bg-[#F04248] flex items-center gap-3 font-figtree z-50 fixed shadow-xl ${
        Formodal ? " -top-7 -right-9" : "top-2 right-4 "
      }`}
    >
      <MdCancel className="text-white text-xl" />
      <div>
        <p className=" font-semibold text-base text-white">Error</p>
        <p className=" text-[#F2F2F2] text-sm font-normal w-64">{error}</p>
      </div>
    </main>
  );
}
export function WarningMessage({warning, Formodal}) {
  return (
    <main
      className={` py-3 px-4 rounded-lg bg-[#FFD21E] flex items-center gap-3 font-figtree z-50 fixed shadow-xl ${
        Formodal ? " -top-7 -right-9" : "top-2 right-4 "
      }`}
    >
      <RiErrorWarningFill className="text-white text-xl" />
      <div>
        <p className=" font-semibold text-base text-[#3D3D3F]">Warning!</p>
        <p className=" text-[#3D3D3F] text-sm font-normal w-64">{warning}</p>
      </div>
    </main>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { BsArrowRight, BsPlus } from "react-icons/bs";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import DashboardLayout from "../layout/DashboardLayout";
import Purse from "../Assets/svg/purse.svg";
import Search from "../Assets/svg/search.svg";
import Plus from "../Assets/svg/plus.svg";
import ModalPop from "../component/modalPop";
import WalletTopUp from "../component/modals/wallet";
import { fetchWalletBalance } from "../features/wallet/walletBalanceSlice";
import { fetchWalletHistory } from "../features/wallet/walletFundingHistorySlice";
import { Skeleton } from "@mui/material";
import { flutterwaveNotificationData } from "../features/wallet/walletFlutterwaveNotificationSlice";
import { BottomGuide, TopGuide } from "../component/modals/agentGuide/Guide";
import BillingSvgA from "../Assets/svg/billing1.svg";
import BillingSvgB from "../Assets/svg/billing2.svg";
import Bulk from "../component/modals/wallet/Bulk";
import Pay from "../component/modals/wallet/Pay";
import { BiArrowToRight } from "react-icons/bi";
import { updateBusinessModals } from "@/../features/profile/getProfileSlice";
import { getProfile } from "@/../features/profile/getProfileSlice";
import RedeemGift from "../component/modals/redeemgift";
import RedeemSuccess from "../component/modals/redeemgift/RedeemSuccess";
import { api } from "@/../services/api";
import { use } from "marked";
import { useAuth, useUserDispatch } from "@/../context/UserContext";

export default function Wallet() {
  const [data, setData] = useState([]);
  const [openTopUp, setOpenTopUp] = useState(false);
  const [credit, setCredit] = useState();
  const [openRedeemGift, setOpenRedeemGift] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [openTopGuide, setOpenTopGuide] = useState(false);
  const [openBottomGuide, setOpenBottomGuide] = useState(false);
  const [show, setShow] = useState(false);
  const [openBulk, setOpenBulk] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [openRedeemSuccess, setOpenRedeemSuccess] = useState(false);
  const [error, setError] = useState("");
  const [checkWhere, setCheckWhere] = useState(false);
  const { giftBal: giftBalance } = useAuth();
  const { setCreditBal, setGiftBal } = useUserDispatch();

  const walletFundingHistory = useSelector((state) => state.walletHistory);
  const plan = useSelector((state) => state.getCurrentPlan.data);
  const { subscriptions, currentPlan: current } = plan;
  const currentPlanName = current?.subscription_details?.name;

  const totalPages = walletFundingHistory?.data?.meta?.totalPages;
  const amount = walletFundingHistory?.data[1]?.amount;
  console.log(amount, "new amount")
  console.log(walletFundingHistory?.data, "wallet fund"); 

  const location = useLocation();
  const tx_ref = new URLSearchParams(location.search).get("tx_ref");
  const status = new URLSearchParams(location.search).get("status");
  const transaction_id = new URLSearchParams(location.search).get(
    "transaction_id"
  );

  const businessID = JSON.parse(localStorage.getItem("cBus"))?.id;
  const fetchBalance = async () => {
    await api
      .get(`/dashboard/wallet/get/all/${businessID}`)
      .then((res) => {
        console.log(res, "i am balance");
        const bal = res?.data?.data.filter(
          (item, i) => item?.currency === "GIFT"
        );
        // console.log(bal?.balance, "bal");
        setGiftBal(bal[0]);
        const data = res?.data?.data || [];
        const usdObject = data.find(item => item.currency === "USD");
        setCredit(usdObject ? usdObject.balance : 0);

        // setCredit(res?.data?.data?.[3]?.balance || 0)
        // console.log(res?.data?.data, "credit response")
        sessionStorage.setItem("giftBal", bal[0]?.balance);
      })
      .catch((error) => {
        setError(error?.data.message);
        setTimeout(() => {
          setError("");
        }, 1000);
      });
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  const flutterwaveData = {
    reference: `${tx_ref}`,
    status: status,
    providerId: `${transaction_id}`,
  };

  useEffect(() => {
    dispatch(flutterwaveNotificationData(flutterwaveData));
  }, [tx_ref, status, transaction_id]);

  const handlePageChange = (e) => {
    console.log(e, "sucre");
    setCurrentPage(Number(e.target.value));
  };
//   useEffect(() => {
//   if (walletFundingHistory?.data?.length > 1) {
//     const amount = walletFundingHistory.data[1].amount;
//     console.log(amount, "new amount"); 
//   }
// }, [walletFundingHistory]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const walletBalance = useSelector((state) => state.walletBalance);
  const { tokens } = walletBalance.data;
  // console.log(tokens, "i am tokens")
  let num = Math.floor(tokens).toFixed(2);
  let convertedCredit = num.toLocaleString("en-US");
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (convertedCredit) {
      setCreditBal(convertedCredit);
    }
  }, [convertedCredit, setCreditBal]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     // await dispatch(fetchWalletBalance());
  //     // setFirstRender(false);
  //   };

  //   // Function to fetch data initially
  //   fetchData();
  //   // Interval to fetch data every 10 seconds
  //   const intervalId = setInterval(fetchData, 10000); // 10 seconds

  //   // Cleanup function to clear interval when component unmounts
  //   return () => clearInterval(intervalId);
  // }, []); // Empty dependency array to run the effect only once when the component mounts

  useEffect(() => {
    if (show === true) {
      setOpenBulk(true);
    }
  }, [show]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(fetchWalletHistory());
      console.log("res is", res.payload.data);
      if (res?.payload?.data) {
        setData(res?.payload?.data);
        setTimeout(() => {
          setFirstRender(false);
        }, 500);
      } else {
        // setFirstRender(false);
        // setData(walletFundingHistory?.data?.data);
      }
    };

    // Function to fetch data initially
    fetchData();
    // Interval to fetch data every 10 seconds
    const intervalId = setInterval(fetchData, 10000); // 10 seconds

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const setTopBillingGuide = () =>
    location.pathname === "/top-up" ? localStorage.setItem("bill", true) : null;
  const setBottomBillingGuide = () =>
    location.pathname === "/top-up"
      ? localStorage.setItem("bottomBilling", true)
      : null;

  const getBillingGuide = localStorage.getItem("bill");
  const getBottomBillingGuide = localStorage.getItem("bottomBilling");

  useEffect(() => {
    if (getBillingGuide) {
      setOpenTopGuide(false);
    } else {
      setOpenTopGuide(true);
    }
  }, []);

  useEffect(() => {
    if (openTopGuide && !getBottomBillingGuide) {
      setOpenBottomGuide(false);
    } else if (!openTopGuide && !getBottomBillingGuide) {
      setOpenBottomGuide(true);
    } else if (!openTopGuide && getBottomBillingGuide) {
      setOpenBottomGuide(false);
    } else {
      setOpenBottomGuide(true);
    }
  }, [openTopGuide, getBottomBillingGuide]);

  // const { id } = useSelector(state => state.getApplicationByUserId.agent)
  // const config = useSelector(state => state.getProfile.businessModals)
  // console.log(config, "configA");

  // const getBillingGuide = config?.billing; console.log(getBillingGuide, "configA getBillingGuide");
  // const getBottomBillingGuide = config?.bottomBilling; console.log(getBottomBillingGuide, "configA getBottomBillingGuide")

  // useEffect(() => {
  //     if(!getBillingGuide && !getBottomBillingGuide){
  //       setOpenTopGuide(true);
  //     }else{
  //       setOpenTopGuide(false);
  //     }
  // }, [getBillingGuide, getBottomBillingGuide]);

  // const handleBusinessModal = (name) => {
  //   const currentModalState = config[name];
  //   if (currentModalState) {
  //     const modalsVisibility = {...config, [name]: true}
  //     dispatch(updateBusinessModals({ modalsVisibility, id: id }))
  //     .then((res) => {
  //       dispatch(getCurrentEmployee({id: id}))
  //     })
  //     .catch((error) => {
  //       console.error("Failed to update modal state:", error);
  //   });;
  //   }
  // }

  // const handleBatchModalUpdate = () => {
  //   const modalsVisibility = {
  //       ...config,
  //       billing: true,
  //       bottomBilling: true
  //   };

  //   dispatch(updateBusinessModals({ modalsVisibility }))
  //   .then(() => {
  //     dispatch(getProfile());
  //   });
  // }

  const formatDate = (dateString) => {
    // Create a Date object from the dateString
    const date = new Date(dateString);

    // Get the day, month, year, hours, and minutes
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" }); // Full month name
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format the time to include leading zeros if needed
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    // Return the formatted date string
    return `${day} ${month} ${year}, ${formattedTime}`;
  };
  // console.log(walletFundingHistory.loading, firstRender)
  return (
    <DashboardLayout title={"Billing"} user={true}>
      <section className="p-10">
        <div className="flex items-center gap-6 mt-11">
          {walletBalance?.loading === true && firstRender ? (
            <div className="flex gap-6">
              <Skeleton
                variant="rectangular"
                width={373}
                height={168}
                style={{
                  backgroundColor: "rgba(249,249,249,0.86)",
                  borderRadius: "12px",
                }}
              />
            </div>
          ) : (
            <div
              className="w-[373px] h-[168px] py-6 px-5 rounded-xl"
              style={{
                boxShadow: "0px 4px 8px 1px rgba(215, 215, 215, 0.25)",
                background:
                  "linear-gradient(90deg, #F7F7F7 0%, rgba(250, 250, 250, 0.48) 52.26%, #F7F7F7 100.19%)",
              }}
            >
              <div className="flex justify-between">
                <div>
                  <img src={Purse} alt="wallet" />
                </div>
                <button
                  className="flex gap-1"
                  onClick={() => {
                    setOpenTopUp(true);
                    setCheckWhere(false);
                  }}
                >
                  <BsPlus className="text-BLUE-_200" />
                  <p className="text-xs text-BLUE-_200">Top-up</p>
                </button>
              </div>
              <div className="flex flex-col w-[119px] h-[40px] mt-6 gap-2">
                <p className="text-BLACK-_300 text-xs">Credits Left</p>
                <div className="flex items-end">
                  <p className="text-2xl text-BLACK-_600 font-semibold">
                  ${credit}
                  </p>

                  {/* {data?.length > 0 ? (
                                        <>
                                            <p className='text-2xl text-BLACK-_600 font-semibold'>9,965</p>
                                            <span className='text-BLACK-_300 text-base'>/12,000</span> 
                                        </>
                                        ) : (
                                        <>
                                            <p className='text-2xl text-RED-_100 font-semibold'>10,000</p>
                                            <span className='text-BLACK-_300 text-base'>/12,000</span> 
                                        </>
                                        )
                                    } */}
                </div>
              </div>
              <div className="flex justify-between mt-[20px]">
                <p className="text-BLACK-_300 text-xs">
                  Gift Balance <span>${giftBalance?.balance}</span>
                </p>
                <button
                  className="flex gap-1"
                  onClick={() => {
                    setOpenRedeemGift(true);
                    setCheckWhere(true);
                  }}
                >
                  <p className="text-xs text-BLUE-_200">Redeem Gift Credits</p>
                </button>
              </div>
            </div>
          )}
          {/* <div 
            className="w-[373px] h-[168px] py-6 px-5 rounded-xl flex flex-col justify-between gap-1 p-6"
            style={{
              boxShadow: "0px 4px 8px 1px rgba(215, 215, 215, 0.25)",
              background:
                "linear-gradient(160deg,#F8C4D326 2%,  #1774FD08 68%)",
            }}
          >
            <div className="flex flex-col gap-3 mt-[16.03px]">
              <h2 className="text-xl font-semibold text-[#052C67]">What are Credits?</h2>
              <p className="text-xs font-normal text-[#7F7F81]">Learn about your credits and how we use them.</p>
            </div>
            <span onClick={() => {navigate("/support"); sessionStorage.setItem("here", true)}} className="text-xs text-[#1774FD] font-medium flex items-center gap-1 cursor-pointer">
              <span className="">Learn More</span>
              <BsArrowRight className=""/>
            </span>
          </div> */}
          <div
            className="w-[373px] h-[168px] py-6 px-5 rounded-xl flex flex-col justify-between gap-1 p-6"
            style={{
              boxShadow: "0px 4px 8px 1px rgba(215, 215, 215, 0.25)",
              background:
                "linear-gradient(160deg,#F8C4D326 2%,  #1774FD08 68%)",
            }}
          >
            <div className="flex justify-between">
              <div>
                <img src={Purse} alt="wallet" />
              </div>
              <button
                className="flex gap-1 text-BLUE-_200"
                onClick={() => setOpenTopUp(true)}
              >
                <p
                  className="text-xs text-BLUE-_200"
                  onClick={() =>
                    navigate("/settings?page=billing&category=plan")
                  }
                >
                  View To Upgrade Plan
                </p>
                <BsArrowRight className="" />
              </button>
            </div>
            <p className="text-BLACK-_300 text-xs">Current Plan</p>
            <p className="text-[20px] leading-[24px] text-[#052C67] font-semibold">
              {currentPlanName}
            </p>
            {/* <span onClick={() => {navigate("/support"); sessionStorage.setItem("here", true)}} className="text-xs text-[#1774FD] font-medium flex items-center gap-1 cursor-pointer"> */}
            {/* <span className="">Learn More</span> */}

            {/* </span> */}
          </div>

          {/* {
                    data.length > 0
                    ? 
                        null
                    :
                        <div
                        className="w-[373px] h-[168px] py-5 px-5 rounded-xl"
                        style={{ 
                            boxShadow: "0px 4px 8px 1px rgba(215, 215, 215, 0.25)", 
                            background: "linear-gradient(0deg, rgba(70, 31, 196, 0.50), #111017 45%) no-repeat" 
                        }}
                    >
                        <div className="flex flex-col gap-4">
                            <p className='font-bold text-WHITE-_100 text-2xl'>Commit</p>
                            <p className='text-WHITE-_100 text-sm w-[282px]'>Get unlimited credit for a whole year. Never worry about the word limit.</p>
                            <button 
                                className='w-[86px] h-[26px] bg-WHITE-_100 rounded-lg text-xs font-semibold text-BLACK-_500'
                                style={{ boxShadow: "0px 4px 8px 1px rgba(215, 215, 215, 0.25)" }}
                                onClick={() => navigate("/support")}
                            >
                                Contact Us
                            </button>
                        </div>
                        </div>
                } */}
        </div>

        <div className="mt-[50px]">
          <p className="font-medium text-[#101828] text-[20px]">
            Funding History
          </p>
          {/* <div className="flex items-center p-2 border rounded-lg w-[420px] mt-6 gap-[6px] h-[48px]">
            <img src={Search} alt="Search" className="w-[15px] h-[15px]" />
            <input
              type="text"
              placeholder="Search History"
              className="border-none p-0 text-xs outline-none text-BLACK-_300"
            />
          </div> */}

          <table className="w-full table-fixed  mt-6  mb-10">
            {" "}
            <thead className="text-left ">
              <tr className=" p-3 rounded-tl-lg rounded-tr-lg bg-[#FAFAFA]">
                {" "}
                {/* flex items-start p-3 justify-between gap-[150px] */}
                <th className="text-sm font-medium py-2 text-BLACK-_300 pl-1">
                  Date
                </th>
                <th className="text-sm font-medium text-BLACK-_300 ">
                  Funding ID
                </th>
                <th className="text-sm font-medium  text-BLACK-_300 ">
                  Amount
                </th>
                <th className="text-sm font-medium text-BLACK-_300 ">Value</th>
                <th className="text-sm font-medium text-BLACK-_300 ">Type</th>
                <th className="text-sm font-medium text-BLACK-_300">Status</th>
              </tr>
            </thead>
            {walletFundingHistory.loading === true && firstRender ? (
              <tbody>
                <tr>
                  <td colSpan="5">
                    <Skeleton
                      variant="rectangular"
                      className="w-full"
                      // width={1300}
                      height={40}
                      style={{
                        backgroundColor: "rgba(249,249,249,0.86)",
                        borderRadius: "12px",
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            ) : (
              <>
                {data?.length > 0 ? (
                  <tbody className="">
                    {data &&
                      data.map((items) => {
                        return (
                          <tr
                            key={items.id}
                            className="   border border-l-0 border-r-0 border-t-0 border-b p-3 "
                          >
                            {" "}
                            <td className="py-3 text-BLACK-_500 text-sm">
                              {formatDate(items?.created_at)}
                            </td>
                            <td className=" text-sm ">
                              {String(items?.reference).substring(0, 18)}
                            </td>
                            <td className="  text-BLACK-_500 text-sm">
                              <span>{items?.currency}</span>
                              <span class="ml-3`">
                                {/* {Math.floor(items?.amount / 100).toLocaleString(
                                  "en-US"
                                )} */}{items?.amount}
                              </span>
                            </td>
                            <td className="  text-BLACK-_500 text-sm">
                              {/* {Math.floor(items?.tokens).toLocaleString(
                                "en-US"
                              )} */}
                             {items?.amount}
                            </td>
                             <td className="  text-BLACK-_500 text-sm">
                              
                             {items?.tx_type}
                            </td>
                            <td className="   ">
                              <div
                                className={`${
                                  items?.status === "pending"
                                    ? "bg-[#FFF8E4] border-[#FBBC05] text-[#FBBC05] w-[60px]"
                                    : items?.status === "fail"
                                    ? "bg-[#FFEFF0] border-RED-_100 text-RED-_100 w-[48px]"
                                    : "bg-[#BBFDB9] border-GREEN-_100 text-GREEN-_100 w-[75px]"
                                } flex border items-center justify-center text-xs h-[22px] p-1 text-BLACK-_500 rounded-lg text-sm`}
                              >
                                {`${
                                  items?.status === "pending"
                                    ? "Pending"
                                    : items?.status === "fail"
                                    ? "Failed"
                                    : "Successful"
                                }`}
                              </div>
                              {/* <div>
                                                        <PiDotsThreeVerticalBold className='cursor-pointer'/>
                                                    </div> */}
                            </td>{" "}
                          </tr>
                        );
                      })}
                  </tbody>
                ) : (
                  <tbody className="">
                    <tr>
                      <td colSpan="5" className="text-center py-10">
                        {" "}
                        {/*  className="w-full flex justify-center flex-col items-center mt-10 gap-[8px]" */}
                        <p className="text-BLACK-_300 text-lg font-medium">
                          No funding record
                        </p>
                        <p className="text-sm text-BLACK-_300">
                          We have no record of funding your wallet yet.
                        </p>
                        <button
                          className="w-[128px] mx-auto mt-3 h-[33px] flex gap-1.5 py-[4px] bg-BLACK-_500 px-[8px] justify-center items-center cursor-pointer border-[1px] rounded-lg"
                          onClick={() => setOpenTopUp(true)}
                        >
                          <img
                            src={Plus}
                            alt="plus"
                            className="w-[15px] h-[15px]"
                          />
                          <p className="text-WHITE-_100 text-xs">Fund Wallet</p>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                )}
              </>
            )}
          </table>
          {data?.length > 0 ? (
            <div
              className={`${
                data?.length > 0 ? "" : "mt-[200px]"
              } flex mb-7 justify-end gap-[10px] items-center`}
            >
              <p className="text-sm font-medium text-[#7F7F81] select-none">
                Page
              </p>
              <div className="border border-solid border-[#DDD] flex items-center gap-2 px-[12px] py-[8px] bg-white rounded-lg">
                <select
                  className="p-0 flex items-center self-center bg-white cursor-pointer appearance-none w-[30px] border-none outline-none text-sm font-medium text-[#7F7F81]"
                  name="page"
                  value={currentPage}
                  onChange={handlePageChange}
                >
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (page) => {
                      return (
                        <option key={page} value={page}>
                          {page}
                        </option>
                      );
                    }
                  )}
                </select>
                {totalPages < 2 ? null : (
                  <MdKeyboardArrowDown className="w-[16px] h-[16px] text-[#7F7F81]" />
                )}
              </div>
              <p className="text-sm font-medium text-[#7F7F81] select-none">{`of ${totalPages}`}</p>
            </div>
          ) : null}
        </div>
      </section>
      <ModalPop isOpen={openTopUp}>
        <WalletTopUp
          handleClose={() => setOpenTopUp(false)}
          setShow={setShow}
          setOpenPay={setOpenPay}
        />
      </ModalPop>

      <ModalPop isOpen={openRedeemGift}>
        <RedeemGift
          handleClose={() => setOpenRedeemGift(false)}
          back={() => {
            checkWhere ? setOpenPay(false) : setOpenPay(true);
            setOpenRedeemGift(false);
          }}
          setShow={setShow}
          setOpenRedeemSuccess={setOpenRedeemSuccess}
          refresh={fetchBalance}
        />
      </ModalPop>

      <ModalPop isOpen={openBulk}>
        <Bulk handleClose={() => setOpenBulk(false)} setShow={setShow} />
      </ModalPop>

      <ModalPop isOpen={openPay}>
        <Pay
          handleClose={() => setOpenPay(false)}
          setOpenRedeemGift={setOpenRedeemGift}
        />
      </ModalPop>

      <ModalPop isOpen={openRedeemSuccess}>
        <RedeemSuccess
          handleClose={() => setOpenRedeemSuccess(false)}
          setShow={setShow}
          title={"Redeem Successful"}
          text1={"You have successfully redeemed  $1000 worth of credits!"}
          label={"View Wallet"}
          redirectPath="/top-up"
        />
      </ModalPop>

      <ModalPop isOpen={openTopGuide}>
        <TopGuide
          title="Credits and Billing"
          handleClose={() => {
            setOpenTopGuide(false);
            // setOpenBottomGuide(true)
            setTopBillingGuide();
            // handleBusinessModal("billing")
          }}
          subtitle="Top up your credits for your Business. Want to know how we calculate the credit system?"
          link="Continue to Billing"
          image={BillingSvgA}
        />
      </ModalPop>

      <ModalPop isOpen={openBottomGuide}>
        <BottomGuide
          title="Billing"
          subtitle="Top up your credits for your business and also view your transaction list here."
          link="Top Up Credit"
          handleClose={() => {
            // handleBusinessModal("bottomBilling")
            // handleBatchModalUpdate()
            setOpenBottomGuide(false);
            setBottomBillingGuide();
          }}
          className="h-[295px]"
          image={BillingSvgB}
          top={() => setOpenTopUp(true)}
        />
      </ModalPop> 
    </DashboardLayout>
  );
}

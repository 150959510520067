import React from 'react'
import NavBar from './NavBar'
import Login from './Login'
import Terms from './Terms'

export default function Forms({ newUser: initialNewUser = false }) {
  return (
    <div className="min-h-screen grid content-between bg-[#F7F7F7]">
      <NavBar />
      <Login initialNewUser={initialNewUser}/>
      <Terms />
    </div>
  );
}

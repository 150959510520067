import React from "react";
import { useNavigate } from "react-router";

const categoryStyles = {
  Behaviour: "bg-[#EFF5FF] text-[#1774FD]",
  Complaints: "bg-[#FFF2F6] text-[#FF407B]",
  Requests: "bg-[#F7FDF8] text-[#009733]",
  ScheduledInstructions:"bg-orange-100 text-orange-500",
  Enquiries: "bg-[#F6FDF8] text-[#009733]",
};

const TableComponent = ({ data, setViewTemplate, setSelectedTemplate }) => {
  return (
    <div className="mt-[35px]  border border-[#EEEFF0] rounded-[6px]">
      <table className="min-w-full">
        <thead className="bg-[#FAFAFAAD] text-[#828282] text-sm">
          <tr>
            <th className="px-4 py-2 text-left">Instruction Name</th>
            <th className="px-4 py-2 text-left">Category</th>
            <th className="px-4 py-2 text-left">Support Channels</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index}
              className="border-t  cursor-pointer hover:bg-[#F4F8FF]/70"
              onClick={() => {
                setSelectedTemplate(item);
                setViewTemplate("form");
              }}
            >
              <td className="px-4 py-4 text-[#121212] font-normal text-sm">
                {item.name}
              </td>
              <td className="px-4 py-2 ">
                <div>
                  <span
                    className={`py-1 px-2 font-normal rounded-lg text-sm capitalize ${
                      categoryStyles[item.category.replace(/\s+/g, "")]
                    }`}
                  >
                    {item.category === 'ScheduledInstructions' ? 'ScheduledInstructions' :item.category }
                  </span>
                </div>
              </td>
              <td className="px-4 py-2 text-[#828282] font-normal text-sm">
                {item.support_channel.join(", ")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;

import React, { useState, useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { PiHeadsetFill } from "react-icons/pi";

import General from "../../../Assets/svg/General.svg";
import salesIcon from "../../../Assets/svg/salesIcon.svg";
import customerIcon from "../../../Assets/svg/customerIcon.svg";

import swapIcon from "../../../Assets/svg/swapImg.svg";
import switchIcon from "../../../Assets/svg/switchIcon.svg";
import downArrow from "../../../Assets/svg/downArrow.svg";

import { useSelector } from "react-redux";
import Service from "../../../Agent/Service";
import { useNavigate } from "react-router";
import { setAgentName } from "../../../features/application/getApplicationByIdSlice";
import { useDispatch } from "react-redux";

const SwitchAgentRole = ({ handleClose, setChange }) => {
  const [selectedRole, setSelectedRole] = useState(null);
  const [showRoles, setShowRoles] = useState(false);
  const [activeButton, setActiveButton] = useState("Profile");
  const divRef = useRef(null);

  const navigate = useNavigate();

  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  // const oldagentType = getAgentApplication?.data?.data[0]?.type || null;
  const agentType = (getAgentApplication?.agent?.draft?.type ? getAgentApplication?.agent?.draft?.type : getAgentApplication?.agent?.type )|| null;

  const handleRoleSelection = (role) => {
    if (selectedRole === role) {
      // Deselect the current role if it is already selected
      setSelectedRole(null);
    } else {
      // Otherwise, select the new role
      setSelectedRole(role);
    }
  };
  const dispatch = useDispatch();

  const changeRole = () => {
    if (selectedRole) {
      dispatch(setAgentName(selectedRole));
      localStorage.setItem("agentType", selectedRole);
      setChange(true);
      handleClose();
    }
  };

  let agentFeatures = "features";
  let agentSettings = "settings";

  useEffect(() => {
    if (agentType === null) {
      navigate("/dashboard");
    }
  }, [agentType]);

  return (
    <div className="bg-WHITE-_100 w-[539px] h-fit rounded-lg">
      <div className="flex justify-between items-center m-4 ">
        <p className="font-medium text-lg text-[#101828] ml-6">
          Switch Assistant Role
        </p>
        <button
          className="w-[36px] h-[36px] border rounded-lg flex justify-center items-center p-2"
          onClick={handleClose}
        >
          <AiOutlineClose className="text-[#828282]" />
        </button>
      </div>
      <hr />
      <div className="flex flex-col py-[30px] px-[40px] gap-[40px]">
        <div className="w-[456px] bg-[#FFF7D6] px-3 py-5 rounded-[4px] flex flex-col gap-1">
          <p className="text-sm text-[#121212] font-medium">Please Note:</p>
          <p className="text-xs text-[#4A4A4A] ">
            Changing your assistant’s role will reset any{" "}
            <span
              className="text-[#0359D8] underline cursor-pointer"
              onClick={() => navigate("/agent", { state: agentFeatures })}
            >
              Instruction,
            </span>{" "}
            <span
              className="text-[#0359D8] underline cursor-pointer"
              onClick={() => navigate("/agent", { state: "settings" })}
            >
              Settings,
            </span>{" "}
            and
            <span
              className="mx-1 text-[#0359D8] underline cursor-pointer"
              onClick={() => navigate("/plugins")}
            >
              Apps
            </span>
            you have set up with the current role. Your
            <span
              className="text-[#0359D8] underline cursor-pointer"
              onClick={() => navigate("/agent")}
            >
              {" "}
              Assistant's memory
            </span>{" "}
            will not be affected.
          </p>
        </div>
        <div className="border  border-[#E3E3E3] rounded-lg py-2 px-4 flex items-center justify-between bg-WHITE-_100">
          <div className="flex items-center gap-2">
            {agentType === "sale" ? (
              <img src={salesIcon} alt="general" className="w-8 h-8" />
            ) : agentType === "general" ? (
              <img src={General} alt="general" className="w-8 h-8" />
            ) : (
              <img src={customerIcon} alt="general" className="w-8 h-8" />
            )}

            <div className="flex gap-3 items-center">
              <p className="text-BLACK-_200 text-sm font-medium capitalize">
                {agentType === "sale"
                  ? " Sales X"
                  : agentType === "general"
                  ? "General X"
                  : "Customer Care X"}
              </p>
              {agentType !== "sale" && (
                <div
                  className="text-BLUE-_200 py-1 px-2 text-xs"
                  style={{
                    "box-shadow": "0px 0px 2.4px 0px #86B7FF42",
                  }}
                >
                  Beta
                </div>
              )}
            </div>
          </div>
          <div className="p-2 bg-[#F9F9F9] rounded text-xs text-BLACK-_200">
            Current Role
          </div>
        </div>

        <div className="flex justify-center">
          <img src={switchIcon} alt="" className={`w-4 h-5`} />
        </div>
        <div className="relative border  border-[#E3E3E3] rounded-xl py-2 px-4 flex items-center justify-between bg-WHITE-_100">
          {selectedRole ? (
            <div className="mt-3 w-full ">
              <div className="flex w-full items-center justify-between">
                {selectedRole === "sale" ? (
                  <img src={salesIcon} alt="general" />
                ) : selectedRole === "general" ? (
                  <img src={General} alt="general" />
                ) : (
                  <img src={customerIcon} alt="general" />
                )}
                <div
                  className="p-2 bg-[#F9F9F9] text-xs cursor-pointer rounded text-BLACK-_200"
                  onClick={() => {
                    setShowRoles(true);
                    setTimeout(() => {
                      divRef?.current?.focus();
                    }, 500);
                  }}
                >
                  Change Role
                </div>
              </div>

              <div className="mt-2">
                <div className="flex justify-between items-center">
                  <p className="text-BLACK-_600 text-sm">
                    {selectedRole === "sale"
                      ? " Sales X"
                      : selectedRole === "general"
                      ? "General X"
                      : "Customer Care X"}
                  </p>
                  {selectedRole !== "sale" && (
                    <div
                      className="text-BLUE-_200 py-1 px-2 text-xs"
                      style={{
                        "box-shadow": "0px 0px 2.4px 0px #86B7FF42",
                      }}
                    >
                      Beta
                    </div>
                  )}
                </div>

                <p className="mt-2 text-[#949496] text-xs">
                  {selectedRole === "sale"
                    ? "Meet your AI sales rep, trained to boost your sales. He helps you close sales with leads, engage leads, represent your brand, and offer useful sales insights."
                    : selectedRole === "general"
                    ? "Meet the new generalist in your team. It does all in one go — sell your service, attend to customers, and give you a broader insight across sales and customer care combined."
                    : "Meet the new member of your customer care team. It can tirelessly handle your customer inquiries, requests, and complaints all day long, at any scale."}
                </p>
              </div>
            </div>
          ) : (
            <div
              className=" cursor-pointer w-full items-center flex justify-between"
              onClick={() => {
                setShowRoles(!showRoles);
                setTimeout(() => {
                  divRef?.current?.focus();
                }, 500);
              }}
            >
              <div className="p-2 bg-[#F9F9F9] text-xs rounded text-BLACK-_200">
                Select New Role
              </div>

              <div className="flex items-center gap-2">
                <img src={downArrow} alt="" className={`w-3 h-2`} />
              </div>
            </div>
          )}

          {showRoles && (
            <div
              ref={divRef}
              onBlur={() => setShowRoles(false)}
              tabIndex={0}
              className="absolute -bottom-44 left-0 bg-white rounded-lg border border-[#E3E3E3] flex flex-col gap-3 py-3 px-6 "
            >
              {agentType === "sale" ? null : (
                <div
                  className="cursor-pointer  p-1 rounded-lg hover:bg-[#cddef833]"
                  onClick={() => {
                    handleRoleSelection("sale");
                    setShowRoles(false);
                  }}
                >
                  <p className="text-BLACK-_600 text-xs">Sales X</p>
                  <p className="mt-2 text-[#949496] text-xs">
                    Meet your AI sales rep, trained to boost your sales. He
                    helps you close sales with leads, engage leads, represent
                    your brand, and offer useful sales insights.
                  </p>
                </div>
              )}
              {agentType === "customer" ? null : (
                <div
                  className="cursor-pointer  p-1 rounded-lg hover:bg-[#cddef833]"
                  onClick={() => {
                    handleRoleSelection("customer");
                    setShowRoles(false);
                  }}
                >
                  <p className="text-BLACK-_600 text-xs">Customer Service X</p>
                  <p className="mt-2 text-[#949496] text-xs">
                    Meet the new member of your customer care team. It can
                    tirelessly handle your customer inquiries, requests, and
                    complaints all day long, at any scale.
                  </p>
                </div>
              )}

              {agentType === "general" ? null : (
                <div
                  className="cursor-pointer p-1 rounded-lg hover:bg-[#cddef833]"
                  onClick={() => {
                    handleRoleSelection("general");
                    setShowRoles(false);
                  }}
                >
                  <p className="text-BLACK-_600 text-xs">General X</p>
                  <p className="mt-2 text-[#949496] text-xs">
                    Meet the new generalist in your team. It does all in one go
                    — sell your service, attend to customers, and give you a
                    broader insight across sales and customer care combined.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex flex-col gap-6">
          {/* {agentType === "sale" ? null : (
            <button
              className={`${
                selectedRole === "sale"
                  ? "border-[#1774FD] bg-[#cddef833]"
                  : "border-[#E3E3E3] bg-WHITE-_100"
              } cursor-pointer w-[459px] h-[160px] rounded-2xl border border-solid px-[19px] py-[14px]`}
              onClick={() => handleRoleSelection("sale")}
            >
              <div className="w-[36px] h-[36px] border rounded-lg mr-3 mt-3 flex cursor-pointer justify-center items-center p-2">
                <PiHeadsetFill />
              </div>
              <p className="text-sm text-left text-[#121212] mt-[10px] font-medium">
                Sales A.I
              </p>
              <p className="text-xs text-left text-[#7F7F81] font-normal mt-[12px]">
                Sales AI will sell your financial solution to customers talk to
                customers, represent your brand and offer useful insights to
                make better sales decisions.
              </p>
            </button>
          )}

          {agentType === "customer" ? null : (
            <button
              className={`${
                selectedRole === "customer"
                  ? "border-[#1774FD] bg-[#cddef833]"
                  : "border-[#E3E3E3] bg-WHITE-_100"
              } cursor-pointer w-[459px] h-[160px] rounded-2xl border border-solid px-[19px] py-[14px]`}
              onClick={() => handleRoleSelection("customer")}
            >
              <div className="w-[36px] h-[36px] border rounded-lg mr-3 mt-3 flex cursor-pointer justify-center items-center p-2">
                <PiHeadsetFill />
              </div>
              <p className="text-sm text-left text-[#121212] mt-[10px] font-medium">
                Customer Service A.I
              </p>
              <p className="text-xs text-left text-[#7F7F81] font-normal mt-[12px]">
                Customer Service AI never sleeps or tire, he/she will always
                attend to your customer enquiries, requests and complaints.
              </p>
            </button>
          )}
          {agentType === "general" ? null : (
            <button
              className={`${
                selectedRole === "general"
                  ? "border-[#1774FD] bg-[#cddef833]"
                  : "border-[#E3E3E3] bg-WHITE-_100"
              } w-[459px] cursor-pointer h-[160px] rounded-2xl border border-solid px-[19px] py-[14px]`}
              onClick={() => handleRoleSelection("general")}
            >
              <div className="w-[36px] h-[36px] border rounded-lg mr-3 mt-3 flex cursor-pointer justify-center items-center p-2">
                <img src={General} alt="general" />
              </div>
              <p className="text-sm text-[#121212] text-left mt-[10px] font-medium">
                General A.I
              </p>
              <p className="text-xs text-[#7F7F81] text-left font-normal mt-[12px]">
                General AI can help you do it all in one go sell your service,
                attend to customers, and give you a broader insight across sales
                and customer care combined.
              </p>
            </button>
          )} */}

          <div className="flex gap-2 justify-end">
            <button
              className="bg-WHITE-_100 border border-solid border-[#D0D5DD] w-[78px] h-[34px] rounded-lg"
              onClick={handleClose}
            >
              <p className="text-[#828282] text-[12px] font-medium">Cancel</p>
            </button>
            <button
              className="bg-BLACK-_500 w-[106px] h-[34px] rounded-lg"
              onClick={() => changeRole()}
            >
              <p className="text-WHITE-_100 text-[12px] font-medium">
                Switch Role
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchAgentRole;

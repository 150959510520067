import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import OtpInput from "react-otp-input";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { CgSpinner } from "react-icons/cg";

import { resetAgentType } from "../../../features/agentResetType/agentResetTypeSlice";
import {
  ErrorMessage,
  SuccessMessage,
} from "../../../Toast-Messages/Toastmessages";
// import { getApplicationById } from '../../../features/application/getApplicationByIdSlice';
// import { getBusiness } from '../../../features/business/getBusinessSlice';
import { useNavigate } from "react-router-dom";
// import { getAllPlugins } from '../../../features/plugins/getAllPluginsSlice';
import {
  setAgentName,
  getApplicationDraft,
  getAnEmployee,
} from "../../../features/application/getApplicationByIdSlice";

const ConfirmSwitch = ({ handleClose, setChange }) => {
  const [randomNumberOne, setRandomNumberOne] = useState(
    generateRandomNumberOne()
  );
  const [randomNumberTwo, setRandomNumberTwo] = useState(
    generateRandomNumberTwo()
  );
  const [randomNumberThree, setRandomNumberThree] = useState(
    generateRandomNumberThree()
  );
  const [randomNumberFour, setRandomNumberFour] = useState(
    generateRandomNumberFour()
  );
  const [randomNumberFive, setRandomNumberFive] = useState(
    generateRandomNumberFive()
  );
  const [randomNumber, setRandomNumber] = useState(`
        ${randomNumberOne}${randomNumberTwo}${randomNumberThree}${randomNumberFour}${randomNumberFive}
    `);
  const [numberError, setNumberError] = useState("");
  const [resetAgentSuccess, setResetAgentSuccess] = useState(false);
  const [resetAgentError, setResetAgentError] = useState(false);
  const navigate = useNavigate();
  const agentData = localStorage.getItem("wano_agent");
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const applicationId = appDetail?.agent.id; //changed to this

  function generateRandomNumberOne() {
    return Math.floor(Math.random() * 9) + 1;
  }
  function generateRandomNumberTwo() {
    return Math.floor(Math.random() * 9) + 1;
  }
  function generateRandomNumberThree() {
    return Math.floor(Math.random() * 9) + 1;
  }
  function generateRandomNumberFour() {
    return Math.floor(Math.random() * 9) + 1;
  }
  function generateRandomNumberFive() {
    return Math.floor(Math.random() * 9) + 1;
  }
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const newAgent = getAgentApplication?.agent || null;

  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.agentReset);
  const [loading, setLoading] = useState(false);
  const { id } = newAgent;
  //just added this
  // const applicationId = id
  //end

  const submitForm = async (values) => {
    try {
      setLoading(true);
      const userInput = parseInt(values?.otpCode, 10);

      const switchAgent = localStorage.getItem("agentType");

      // console.log('switch',)
      const data = {
        type: switchAgent,
      };
      if (userInput == randomNumber) {
        dispatch(setAgentName(switchAgent));

        setChange(false);
        const res = await dispatch(resetAgentType({ id, data }));
        if(applicationId){
        dispatch(getApplicationDraft(applicationId));
        }
        sessionStorage.removeItem("serviceData");
        sessionStorage.removeItem("choosenServiceData");
        console.log("response is", res);

        if (res.error) {
          setResetAgentError(res.payload);
          console.log("response error is", res.payload);
          setTimeout(() => {
            handleClose();
            sessionStorage.setItem("agentType", switchAgent);
          }, 3000);
        } else {
          setResetAgentSuccess(res.payload.message);
          navigate("/dashboard?switchEmployee=true");
          await dispatch(getAnEmployee({ id }));
          handleClose();
          sessionStorage.setItem("agentType", switchAgent);

          // navigate(0)
        }
      } else {
        setNumberError("Wrong Input");
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  };

  const Close = () => {
    setChange(false);
    handleClose();
  };

  const removeSuccessNotification = () => {
    setTimeout(() => {
      setResetAgentSuccess(false);
    }, 3000);
  };

  //added this
  // useEffect(() => {
  //     dispatch(getApplicationById());
  // }, [loading, dispatch]);
  //end of this

  useEffect(() => {
    removeSuccessNotification();
  }, [resetAgentSuccess]);

  const removeErrorNotification = () => {
    setTimeout(() => {
      setResetAgentError(false);
    }, 3000);
  };

  useEffect(() => removeErrorNotification(), [resetAgentError]);


  return (
    <div className="bg-WHITE-_100 w-[620px] h-[480px]  p-[50px] flex flex-col mt-[50px] rounded-lg">
      {" "}
      {/* change height to this after removing the close button => h-[441px] */}
      <div className="flex flex-col gap-[42px]">
        <div className="w-[520px] text-center gap-2">
          <p className="font-medium text-[28px] text-[#101828]">
            Confirm Switch
          </p>
          <p className="text-base text-[#7F7F81]">
            To confirm that you want to switch enter the code you see below
          </p>
        </div>
        <div className="flex justify-center gap-[42px]">
          <p className="text-[#101828] font-medium text-[42px]">
            {randomNumberOne}
          </p>
          <p className="text-[#101828] font-medium text-[42px]">
            {randomNumberTwo}
          </p>
          <p className="text-[#101828] font-medium text-[42px]">
            {randomNumberThree}
          </p>
          <p className="text-[#101828] font-medium text-[42px]">
            {randomNumberFour}
          </p>
          <p className="text-[#101828] font-medium text-[42px]">
            {randomNumberFive}
          </p>
          {/* <p className='text-[#101828] font-medium text-[42px]'>1</p>
                <p className='text-[#101828] font-medium text-[42px]'>2</p>
                <p className='text-[#101828] font-medium text-[42px]'>3</p>
                <p className='text-[#101828] font-medium text-[42px]'>4</p>
                <p className='text-[#101828] font-medium text-[42px]'>5</p> */}
        </div>

        <div className="w-[473px]  mx-auto">
          <Formik
            initialValues={{
              otpCode: "",
            }}
            // validationSchema={formValidationSchema}
            onSubmit={(values) => {
              // console.log(values, "otp")
              window.scrollTo(0, 0);
              submitForm(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              dirty,
              isValid,
              setFieldValue,
              errors,
              touched,
              // setFieldTouched,
              values,
            }) => (
              <Form onSubmit={handleSubmit} className="flex justify-center">
                <div className="flex flex-col items-center gap-6 ">
                  <div className="mb-2">
                    <div className=" xs:hidden md:block">
                      <OtpInput
                        name="otpCode"
                        value={values.otpCode}
                        onChange={(e) => setFieldValue("otpCode", e)}
                        // placeholder="123456"
                        numInputs={5}
                        renderSeparator={<span style={{ width: "8px" }}></span>}
                        renderInput={(props) => (
                          <input {...props} type="number" />
                        )}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        inputStyle={{
                          border: "1px solid #D0D5DD",
                          boxShadow:
                            "0px 0.92453px 1.84906px 0px rgba(16, 24, 40, 0.05)",
                          borderRadius: "8px",
                          width: "59px",
                          height: "59px",
                          fontSize: "16px",
                          color: "#000",
                          fontWeight: "400",
                          caretColor: "black",
                          outline: "none",
                        }}
                        focusStyle={{
                          border: "1px solid #D0D5DD",
                          outline: "none",
                        }}
                      />
                      <div className="text-RED-_100 text-xs">{numberError}</div>
                    </div>
                  </div>

                  <button
                    className="w-[473px] h-[40px] py-3 px-5 rounded-lg flex justify-center items-center font-medium bg-BLACK-_500 text-WHITE-_100 text-sm"
                    type="submit"
                  >
                    <p className="text-WHITE-_100 text-sm font-bold">
                      {loading ? (
                        <CgSpinner className=" animate-spin text-lg " />
                      ) : (
                        "Proceed"
                      )}
                    </p>
                  </button>
                  <button
                    className="w-[473px]   flex justify-center items-center font-medium  text-[#868686] text-sm"
                    type="submit"
                    onClick={() => Close()}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {resetAgentSuccess && (
        <SuccessMessage
          Formodal={true}
          success={resetAgentSuccess}
          className="mt-24 mr-20"
        />
      )}
      {resetAgentError && (
        <ErrorMessage
          Formodal={true}
          error={resetAgentError}
          className="mt-24 mr-20"
        />
      )}
    </div>
  );
};

export default ConfirmSwitch;

import React, { useState, Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Progress } from "antd";
import { BsPlus } from "react-icons/bs"
import { BiChevronDown } from "react-icons/bi"
import { AiOutlineCheck } from "react-icons/ai"
import { Menu, Transition } from '@headlessui/react'

import DashboardLayout from '../layout/DashboardLayout'
import Line from "../Assets/img/line.png"
import LineB from "../Assets/img/line-b.png"
import RangeB from "../Assets/img/range-b.png"
import Arrow from "../Assets/svg/forward-arrow.svg"

import Logo from "../Assets/svg/logo-b.svg"
import Button from '../component/Button'
import Purse from "../Assets/svg/purse.svg"
import User from "../Assets/svg/user.svg"
import Chat from "../Assets/svg/chat.svg"
import { useDispatch, useSelector } from 'react-redux';
import { fetchWalletBalance } from '../features/wallet/walletBalanceSlice';
import { Skeleton } from '@mui/material';


const GetStartedDashboard = () => {
    const [data, setData] = useState(false)
    const [userWeek, setUserWeek] = useState("")
    const [conversationWeek, setConversationWeek] = useState("")

    const navigate = useNavigate()
    const dispatch = useDispatch()


    
    const walletBalance = useSelector(state => state.walletBalance)
    const user = useSelector(state => state.userLogin)
    const client = user?.user?.data?.user;
    const { tokens } = walletBalance.data;
    let num = Math.floor(tokens);
    let convertedCredit = num.toLocaleString("en-US");

    useEffect(() => {
      dispatch(fetchWalletBalance())
    }, [])

    // setData([
    //     {
    //         features: "Send Money",
    //         // img: RangeA,
    //         percent: 60, //60
    //         conversation: 200,
    //         sync: "Modified",
    //         creations: "6 months"
    //     },
    //     {
    //         features: "Request Loan",
    //         // img: RangeB,
    //         percent: 20, //20
    //         conversation: 200,
    //         sync: "Modified",
    //         creations: "6 months"
    //     },
    //     {
    //         features: "Pay Bill",
    //         img: RangeB,
    //         percent: 20, //20
    //         conversation: 200,
    //         sync: "Modified",
    //         creations: "6 months"
    //     },
    // ])

    


  return (
    <DashboardLayout title={""} user={true}>
      <div className="p-10">
        <div className="bg-WHITE-_100 py-5 px-6 h-[112px] rounded-[12px] border-[1px] border-[#E5E5E5]">
          <div className="w-full flex flex-col gap-6">
            <div className="w-full h-[31px] flex items-center justify-between">
              <div className="w-[310px] h-[31px] flex flex-col">
                <p className="text-base font-medium text-BLACK-_500">
                  Let's guide you through!
                </p>
                <p className="text-xs text-BLACK-_200">
                  Finish setting up your assistant to go live.
                </p>
              </div>
              <Button
                text="Continue"
                onClick={() => navigate("/getstarted")}    
                type="button"
                className="bg-BLACK-_500 text-WHITE-_100 text-xs p-2 rounded-md font-medium"
              />
            </div>
            <div className="w-full flex justify-between ">
              <p className="text-BLACK-_200 text-[11px]">0% completed</p>
              {/* <p
                onClick={() => navigate("/dashboard")}
                className="cursor-pointer text-BLUE-_200 text-[11px]"
              >
                Skip Guide
              </p> */}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-1 mt-10">
          <p className="text-xl font-medium text-BLACK-_600 capitalize">Hello {client?.first_name}</p>
          <p className="text-sm text-BLACK-_300">
            Here’s what’s happening!
          </p>
        </div>

        {
          walletBalance?.loading  === true ?
            <div className="flex gap-6 mt-6">
              <Skeleton variant="rectangular" width={264} height={159} style={{ backgroundColor: 'rgba(249,249,249,0.86)', borderRadius: '12px' }} />
              <Skeleton variant="rectangular" width={264} height={159} style={{ backgroundColor: 'rgba(249,249,249,0.86)', borderRadius: '12px' }} />
              <Skeleton variant="rectangular" width={264} height={159} style={{ backgroundColor: 'rgba(249,249,249,0.86)', borderRadius: '12px' }} />
              <Skeleton variant="rectangular" width={264} height={159} style={{ backgroundColor: 'rgba(249,249,249,0.86)', borderRadius: '12px' }} />
            </div>
            :
            <div className="flex gap-6 mt-6">
              <div
                className="w-[264px] h-[159px] py-5 px-3.5 rounded-xl"
                style={{ 
                  boxShadow: "0px 4px 8px 1px rgba(215, 215, 215, 0.25)",
                  background:"linear-gradient(90deg, #F7F7F7 0%, rgba(250, 250, 250, 0.48) 52.26%, #F7F7F7 100.19%)"
                }}
              >
                <div className="flex justify-between">
                  <div >
                    <img src={Purse} alt="wallet"/> 
                  </div>
                  <button className='flex gap-1'>
                    <BsPlus className='text-BLUE-_200' onClick={() => navigate("/wallet")} />
                    <p className="text-xs text-BLUE-_200">Top-up</p>
                  </button>
                </div>
                <div className='flex flex-col w-[119px] h-[40px] mt-6 gap-2' >
                  <p className='text-BLACK-_300 text-xs'>Credits left</p>
                  <p className='text-2xl text-BLACK-_600 font-semibold'>{convertedCredit}</p>
                  {/* <div className='flex items-end'>
                    <span className='text-BLACK-_300 text-base'>/12,000</span> 
                  </div> */}
                </div>
              </div>

              <div
                className="w-[264px] h-[159px] py-5 px-3.5 rounded-xl"
                style={{ boxShadow: "0px 4px 8px 1px rgba(215, 215, 215, 0.25)"}}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <img src={Chat} alt="user"/> 
                  </div>
                  <div className="">
                    <Menu as="div" className="relative text-left">
                      <div className=''>
                        <Menu.Button className="text-xs flex items-center text-BLACK-_300"> {/* inline-flex w-full justify-center rounded-md bg-black/20 px-4 py-2 text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75*/}
                          {conversationWeek || "This week"}
                          <BiChevronDown
                            className="ml-2 -mr-1 h-5 w-5 text-[#828282]"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 mt-2 w-[142px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                          <div className="">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? 'bg-[#F9FAFB] text-[#121212]' : 'text-gray-900'
                                  } group flex justify-between w-full items-center  px-2 py-2 text-sm`}
                                  onClick={() => setConversationWeek("Today")}
                                >
                                  Today
                                  {active ? (
                                    <AiOutlineCheck
                                      className="mr-2 h-5 w-5 text-[#1774FD]"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <AiOutlineCheck
                                      className="mr-2 h-5 w-5 hidden"
                                      aria-hidden="true"
                                    />
                                  )}
                                  
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? 'bg-[#F9FAFB] text-[#121212]' : 'text-gray-900'
                                  } group flex w-full justify-between items-center  px-2 py-2 text-sm`}
                                  onClick={() => setConversationWeek("This week")}
                                >
                                  This Week
                                  {active ? (
                                    <AiOutlineCheck
                                      className="mr-2 h-5 w-5 text-[#1774FD]"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <AiOutlineCheck
                                      className="mr-2 h-5 w-5 hidden"
                                      aria-hidden="true"
                                    />
                                  )}
                                  
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                          <div className="">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? 'bg-[#F9FAFB] text-[#121212]' : 'text-gray-900'
                                  } group flex w-full items-center justify-between px-2 py-2 text-sm`}
                                  onClick={() => setConversationWeek("This Month")}
                                >
                                  This Month
                                  {active ? (
                                    <AiOutlineCheck
                                      className="mr-2 h-5 w-5 text-[#1774FD]"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <AiOutlineCheck
                                      className="mr-2 h-5 w-5 hidden"
                                      aria-hidden="true"
                                    />
                                  )}
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? 'bg-[#F9FAFB] text-[#121212]' : 'text-gray-900'
                                  } group flex w-full items-center justify-between px-2 py-2 text-sm`}
                                  onClick={() => setConversationWeek("This Year")}
                                >
                                This Year
                                {active ? (
                                  <AiOutlineCheck
                                    className="mr-2 h-5 w-5 text-[#1774FD]"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <AiOutlineCheck
                                    className="mr-2 h-5 w-5 hidden"
                                    aria-hidden="true"
                                  />
                                )}
                              </button>
                              )}
                            </Menu.Item>
                          </div>
                        
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <p className='text-BLACK-_300 text-xs font-medium mt-6'>Conversations</p>
                <div className='flex flex-col w-[107px] h-[36px] mt-[6px] ' >
                  <p className='text-2xl text-BLACK-_600 font-semibold'>0</p>
                  <p className='text-GREEN-_200 text-[10px]'>0% More than last week</p> 
                </div>
              </div>

              <div
                className="w-[264px] h-[159px] py-5 px-3.5 rounded-xl"
                style={{ boxShadow: "0px 4px 8px 1px rgba(215, 215, 215, 0.25)"}}
              >
                <div className="flex justify-between items-center">
                  <div >
                    <img src={User} alt="user"/> 
                  </div>
                  <div className="">
                    <Menu as="div" className="relative text-left">
                      <div className=''>
                        <Menu.Button className="text-xs flex items-center text-BLACK-_300"> {/* inline-flex w-full justify-center rounded-md bg-black/20 px-4 py-2 text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75*/}
                          {userWeek || "This week"}
                          <BiChevronDown
                            className="ml-2 -mr-1 h-5 w-5 text-[#828282]"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 mt-2 w-[142px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                          <div className="">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? 'bg-[#F9FAFB] text-[#121212]' : 'text-gray-900'
                                  } group flex justify-between w-full items-center  px-2 py-2 text-sm`}
                                  onClick={() => setUserWeek("Today")}
                                >
                                  Today
                                  {active ? (
                                    <AiOutlineCheck
                                      className="mr-2 h-5 w-5 text-[#1774FD]"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <AiOutlineCheck
                                      className="mr-2 h-5 w-5 hidden"
                                      aria-hidden="true"
                                    />
                                  )}
                                  
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? 'bg-[#F9FAFB] text-[#121212]' : 'text-gray-900'
                                  } group flex w-full justify-between items-center  px-2 py-2 text-sm`}
                                  onClick={() => setUserWeek("This week")}
                                >
                                  This Week
                                  {active ? (
                                    <AiOutlineCheck
                                      className="mr-2 h-5 w-5 text-[#1774FD]"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <AiOutlineCheck
                                      className="mr-2 h-5 w-5 hidden"
                                      aria-hidden="true"
                                    />
                                  )}
                                  
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                          <div className=""> {/* px-1 py-1 */}
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? 'bg-[#F9FAFB] text-[#121212]' : 'text-gray-900'
                                  } group flex w-full items-center justify-between px-2 py-2 text-sm`}
                                  onClick={() => setUserWeek("This Month")}
                                >
                                  This Month
                                  {active ? (
                                    <AiOutlineCheck
                                      className="mr-2 h-5 w-5 text-[#1774FD]"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <AiOutlineCheck
                                      className="mr-2 h-5 w-5 hidden"
                                      aria-hidden="true"
                                    />
                                  )}
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={`${
                                    active ? 'bg-[#F9FAFB] text-[#121212]' : 'text-gray-900'
                                  } group flex w-full items-center justify-between px-2 py-2 text-sm`}
                                  onClick={() => setUserWeek("This Year")}
                                >
                                This Year
                                {active ? (
                                  <AiOutlineCheck
                                    className="mr-2 h-5 w-5 text-[#1774FD]"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <AiOutlineCheck
                                    className="mr-2 h-5 w-5 hidden"
                                    aria-hidden="true"
                                  />
                                )}
                              </button>
                              )}
                            </Menu.Item>
                          </div>
                        
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <p className='text-BLACK-_300 text-xs font-medium mt-6'>New Users</p>
                <div className='flex flex-col w-[107px] h-[36px]  mt-[6px] ' >
                  <p className='text-2xl text-BLACK-_600 font-semibold'>0</p>
                  <p className='text-GREEN-_200 text-[10px]'>0% More than last week</p> 
                </div>
              </div>

              <div
                className="w-[264px] h-[159px] rounded-lg flex flex-col justify-center gap-[19px] p-2"
                style={{
                  background:
                    "linear-gradient(140deg, #CA62EF 3.63%, #4B92FB 71.59%, #F6C0D1 106.7%)"
                }}
              >
                <p className="text-WHITE-_100 w-[179px] text-sm mt-2">
                  Get insights from your Digital Assistant.
                </p>
                <div className="flex justify-end">
                  <div
                    onClick={() => navigate("/ask-agent")}
                    className="cursor-pointer w-[103px] h-[32px] bg-WHITE-_100 rounded-lg py-2 px-3 flex justify-between "
                  >
                    <img src={Logo} alt="logo" />
                    <p className="text-xs font-medium text-BLACK-_500" style={{    whiteSpace: "nowrap"}}>
                      Ask Wano
                    </p>
                  </div>
                </div>
              </div>

            </div>
        }

        <div className="w-full h-[245px] flex flex-col items-start mb-[60px]  gap-6 mt-[60px]">
          <p className="text-xl font-medium text-BLACK-_600">Top Features</p>
          <table className="w-full table-auto  mb-10">
            {" "}
            {/*w-full border-collapse mb-5*/}
            <thead className="w-full">
              <tr className="flex p-3 justify-between gap-[141.5px] bg-[#FAFAFA] rounded-tl-lg rounded-tr-lg ">
                {" "}
                {/* flex items-start p-3 justify-between gap-[150px] */}
                <th className="text-sm font-medium text-BLACK-_300">Feature</th>
                <th className="text-sm font-medium text-BLACK-_300">Usage</th>
                <th className="text-sm font-medium text-BLACK-_300">Conversations</th>
                <th className="text-sm font-medium text-BLACK-_300">Status</th>
                <th className="text-sm font-medium text-BLACK-_300">Creation</th>
              </tr>
            </thead>
            {data ?
              <tbody className="">
                {data &&
                  data.map((items) => (
                    <tr
                      key={items.id}
                      className=" w-full flex items-center justify-between border border-l-0 border-r-0 border-t border-b p-3 "
                    >
                      {" "}
                      {/*  */}
                      <td className="w-5/12 text-BLACK-_500 text-sm">
                        {items.features}
                      </td>{" "}
                      {/* w-5/12 */}
                      <td className="w-5/12 gap-1.5 flex items-center">
                        <div className="w-[100px] h-[5px] rounded-lg ">
                          <Progress percent={items?.percent} size="small" />
                        </div>
                      </td>
                      <td className="w-5/12 text-BLACK-_500 text-sm">
                        {items?.conversation}
                      </td>
                      <td className="w-5/12 text-BLACK-_500 text-sm">
                        {items?.sync}
                      </td>
                      <td className="w-5/12 text-BLACK-_500 text-sm">
                        {items?.creations}
                      </td>
                    </tr>
                  ))}
              </tbody>
              : 
                <tbody className=''>
                  
              </tbody>
            }
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default GetStartedDashboard
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { api } from "../../services/api"



const initialState = {
    loading: false,
    data: [],
    error: ""
};

export const updatePlugins = createAsyncThunk(
    "plugins/updatePlugins", 
    async({ applicationId, applicationPluginId }, { rejectWithValue }) => {
        try {
            const res = await api.put(`/dashboard/applications/${applicationId}/plugins/${applicationPluginId}/publish`, {
                "featuresToUpdate": [
                  {
                    "id": `${applicationPluginId}`,
                    "published": true
                  }
                ]
              });
            console.log(res, "jojo")
            return res?.data

        } catch (error) {
            return rejectWithValue(error?.data?.message)
        }
    }
)

const updatePluginsSlice = createSlice({
    name: "plugins",
    initialState,
    extraReducers: builder => {
        builder.addCase(updatePlugins.pending, state => {
            state.loading = true
        });
        builder.addCase(updatePlugins.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(updatePlugins.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default updatePluginsSlice.reducer
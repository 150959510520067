import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import { useSelector } from 'react-redux'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';
import styles from './mentionStyle.module.css'

const AtInput6 = forwardRef(({value, onChange, placeholder, setter, className, minHeight = 206, tags, setTags, allowSuggestionsAboveCursor = false, ...restprops}, ref)  => {
    const [filteredApps, setFilteredApps] = useState([]);
    console.log(filteredApps, "all");
    const [groupedApps, setGroupedApps] = useState({});

    const [enough, setEnough] = useState(false)
    const data  = useSelector(state => state.getAllPlugins.data)
    const active = useMemo(() => data?.data?.active || [], [data]);
    const available = useMemo(() => data?.data?.available || [], [data]);
    console.log(active, "actives");

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
        ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: '#A7A7A7',
            boxShadow: theme.shadows[1],
            fontSize: 11,
            zIndex: 1000000,
        },
    }));


    // const groupApps = useCallback(() => {
    //     const installedApps = active.map(app => ({ ...app, isInstalled: true }));
    //     const uninstalledApps = available.map(app => ({ ...app, isInstalled: false }));
    //     return { installedApps, uninstalledApps };
    // }, [active, available]);

    useEffect(() => {
      const filtered = [...active, ...available].map(app => ({
          id: app?.applicationPlugins.length > 0 ? app?.applicationPlugins[0]?.id : app?.id,
          // name: app?.name, 
          display: app?.name, 
          slug: app?.app_tag_slug,
          description: app?.description,
          image_url: app?.image_url, 
          active: app?.applicationPlugins.length > 0 ? true: false,
      }))
      setFilteredApps(filtered);
    }, [active, available])


    useEffect(() => {
      const activeApps = filteredApps?.filter(app => app.active);
      const inactiveApps = filteredApps?.filter(app => !app.active);
      setGroupedApps({ activeApps, inactiveApps });
    }, [filteredApps]);

    const defaultStyle = {
      control: {
        backgroundColor: '#fff',
        fontSize: 14,
        fontWeight: 'normal',
      },
    
      '&multiLine': {
        control: {
          // fontFamily: 'monospace',
          minHeight: minHeight,
        },
        highlighter: {
          padding: 9,
          // border: '1px solid transparent',
        },
        input: {
          padding: 9,
          border: '1px solid silver',
          borderRadius: "8px",
          outline: 'none',
        },
      },
    
      '&singleLine': {
        display: 'inline-block',
        width: 180,
    
        highlighter: {
          padding: 1,
          border: '2px inset transparent',
        },
        input: {
          padding: 1,
          border: '2px inset',
        },
      },
    
      suggestions: {
        list: {
          backgroundColor: 'white',
          border: '1px solid rgba(0,0,0,0.15)',
          fontSize: 14,
          overflow: "overflow-y-scroll", 
          borderRadius: '6px', 
          dropShadow: "",
        },
        item: {
          padding: '5px 15px',
          // borderBottom: '1px solid rgba(0,0,0,0.15)',
          '&focused': {
          //   backgroundColor: '#1774FA',
          },
        },
      },
    }

    return (
        <div className="scrollable">
          <MentionsInput
            value={value}
            onChange={onChange}
            style={defaultStyle}
            // className={className}
            ignoreAccents
            appendSpaceOnAdd
            placeholder={placeholder}
            allowSuggestionsAboveCursor={allowSuggestionsAboveCursor}
            a11ySuggestionsListLabel={'Suggested mentions'}
            customSuggestionsContainer={(children) => {
              const suggestions = React.Children.map(children.props.children, child => child.props.suggestion);
              const activeSuggestions = suggestions.filter(suggestion => suggestion.active);
              const inactiveSuggestions = suggestions.filter(suggestion => !suggestion.active);
            
              return (
                <div className='flex flex-col gap-3 border border-neutral-200 rounded px-1 py-6 list-none'>
                  <p className='text-[#7F7F81] font-medium text-xs px-4 pb-2'>Installed Apps</p>
                  
                  {React.Children.map(children.props.children, child => {
                    console.log(child.props.suggestion, "a child");
                      if (child.props.suggestion.active) {
                          // return React.cloneElement(child, {
                          //     ref: inputRef,
                          //     onChange: (e) => {
                          //         if (typeof child.props.onChange === 'function') {
                          //             child.props.onChange(e);
                          //         }
                          //         handleInputChange(e); 
                          //     }
                          // });
                          
                          // return child;
                          return (
                            // <LightTooltip
                            //   key={child.props.suggestion.id}
                            //   title={child.props.suggestion.description}
                            //   arrow
                            //   TransitionComponent={Zoom}
                            //   placement="right"
                            // >
                            //   <div 
                            //     className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
                            //     style={{ padding: '5px', cursor: 'pointer', opacity: enough ? "1" : "0.4" }}
                            //   >
                            //     <img src={child.props.suggestion.image_url} alt={child.props.suggestion.display} className='w-4 h-4 rounded-[2.26px]' /> {child}
                            //   </div>
                            // </LightTooltip>
                            child
                          )
                      }
                  })}
            
                  <p className='text-[#7F7F81] font-medium text-xs px-4 py-2'>Uninstalled Apps</p>
                  
                  {React.Children.map(children.props.children, child => {
                    console.log(child.props.suggestion, "a child");
                      if (!child.props.suggestion.active) {
                          return (
                            // <LightTooltip
                            //   key={child.props.suggestion.id}
                            //   title={child.props.suggestion.description}
                            //   arrow
                            //   TransitionComponent={Zoom}
                            //   placement="right"
                            // >
                            //   <div 
                            //     className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
                            //     style={{ padding: '5px', cursor: 'pointer', opacity: enough ? "1" : "0.4" }}
                            //   >
                            //     <img src={child.props.suggestion.image_url} alt={child.props.suggestion.display} className='w-4 h-4 rounded-[2.26px]' /> {child}
                            //   </div>
                            // </LightTooltip>
                            child
                          )
                      }
                  })}
                </div>
              );
            }}
          >
           
            <Mention
                  trigger="@"
                  // markup="@[__display__](user:__display__)"
                  displayTransform={(app) => `@${app}`}
                  data={groupedApps.activeApps}
                  renderSuggestion={(suggestion, search, highlightedDisplay) => (
                    <LightTooltip
                        key={suggestion?.id}
                        title={suggestion?.description}
                        arrow
                        TransitionComponent={Zoom}
                        placement="right"
                    >
                      <div className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
                          style={{ padding: '5px', cursor: 'pointer', opacity: "1" }}>
                        <img src={suggestion.image_url} alt={suggestion.display} className='w-4 h-4 rounded-[2.26px]' />
                        {highlightedDisplay}
                      </div>
                    </LightTooltip> 
                  )}
                  className={styles.mentions}
                />

                <Mention
                  trigger="@"
                  markup="@[__display__](user:__display__)"
                  displayTransform={(app) => `@${app}`}
                  data={groupedApps.inactiveApps}
                  renderSuggestion={(suggestion, search, highlightedDisplay) => (
                    <LightTooltip
                        key={suggestion?.id}
                        title={suggestion?.description}
                        arrow
                        TransitionComponent={Zoom}
                        placement="right"
                    >
                      <div className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
                          style={{ padding: '5px', cursor: 'pointer', opacity: "0.4" }}>
                        <img src={suggestion.image_url} alt={suggestion.display} className='w-4 h-4 rounded-[2.26px]' />
                        {highlightedDisplay}
                      </div>
                    </LightTooltip> 
                  )}
                  className={styles.mentions}
                />
          </MentionsInput>
        </div>
    )
})

export default AtInput6

    // const renderSuggestion = (app, search, highlightedDisplay) => {
    //     const tooltipText = app?.props?.suggestion?.isInstalled
    //         ? app?.props?.suggestion?.description
    //         : "This app needs to be installed.";

    //     return (
    //         <LightTooltip
    //             key={app?.props?.suggestion?.id}
    //             title={tooltipText}
    //             arrow
    //             TransitionComponent={Zoom}
    //             placement="right"
    //         >
    //             <div 
    //                 className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
    //                  style={{ padding: '5px', cursor: 'pointer', opacity: enough? "1": "0.4" }}
    //             >
    //                 <img src={app?.props?.suggestion?.image_url} alt={app?.props?.suggestion?.name} className='w-4 h-4 rounded-[2.26px]' /> {app?.props?.suggestion?.name}
    //             </div>
    //         </LightTooltip>
    //     );
    // };

    // const renderSuggestionsContainer = (children, search) => {
    //     const { installedApps, uninstalledApps } = groupApps();
    //     // console.log(children?.props?.children, "tags apps");
    //     // console.log(installedApps, "tags installed");

    //     return (
    //         <div className='bg-white rounded-md drop-shadow-md'>
    //             <div className="flex flex-col gap-3 hide-scrollbar overflow-y-scroll p-3 w-fit h-[180px] cursor-pointer">
    //                 {installedApps.length > 0 && (
    //                     <div className="installed-section">
    //                         <p className="text-[#7F7F81] font-medium text-xs px-2 pb-2">Installed Apps</p>
    //                         {children?.props?.children?.map(app => {
    //                             // Assuming each app object has a unique identifier `id`
    //                             const isInstalled = installedApps.some(installedApp => installedApp.id === app?.props?.suggestion?.id);
    //                             return isInstalled ? renderSuggestion(app, search, app.name) : null;
    //                         })}
    //                         {/* {installedApps.map(app => renderSuggestion(app, search, app.name))} */}
    //                     </div>
    //                 )}

    //                 {uninstalledApps.length > 0 && (
    //                     <div className="uninstalled-section">
    //                         <p className="text-[#7F7F81] font-medium text-xs px-2 pb-2">Uninstalled Apps</p>
    //                         {children?.props?.children?.map(app => {
    //                             // Assuming each app object has a unique identifier `id`
    //                             const isUninstalled = uninstalledApps.some(installedApp => installedApp.id === app?.props?.suggestion?.id);
    //                             return isUninstalled ? renderSuggestion(app, search, app.name) : null;
    //                         })}
    //                     </div>
    //                 )}
    //             </div>
    //         </div>
    //     );
    // };


    // const renderSuggestionsContainer = (children, search) => {
    //   const { installedApps, uninstalledApps } = groupApps();
    //   const installedChildren = children?.props?.children?.filter(app => installedApps.some(installedApp => installedApp.id === app?.props?.suggestion?.id));
    //   const uninstalledChildren = children?.props?.children?.filter(app => uninstalledApps.some(uninstalledApp => uninstalledApp.id === app?.props?.suggestion?.id));
    
    //   return (
    //     <div className='bg-white rounded-md drop-shadow-md'>
    //       <div className="flex flex-col gap-3 hide-scrollbar overflow-y-scroll p-3 w-fit h-[180px] cursor-pointer">
    //         {installedApps.length > 0 && (
    //           <div className="installed-section">
    //             <p className="text-[#7F7F81] font-medium text-xs px-2 pb-2">Installed Apps</p>
    //             {installedChildren.map(app => renderSuggestion(app, search, app.name))}
    //           </div>
    //         )}
    //         {uninstalledApps.length > 0 && (
    //           <div className="uninstalled-section">
    //             <p className="text-[#7F7F81] font-medium text-xs px-2 pb-2">Uninstalled Apps</p>
    //             {uninstalledChildren.map(app => renderSuggestion(app, search, app.name))}
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //   );
    // };


{/* {activeSuggestions.map((suggestion) => (
                    <LightTooltip
                      key={suggestion.id}
                      title={suggestion.description}
                      arrow
                      TransitionComponent={Zoom}
                      placement="right"
                    >
                      <div 
                        className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
                        style={{ padding: '5px', cursor: 'pointer', opacity: enough ? "1" : "0.4" }}
                      >
                        <img src={suggestion.image_url} alt={suggestion.display} className='w-4 h-4 rounded-[2.26px]' /> {suggestion.display}
                      </div>
                    </LightTooltip>
                  ))} */}
                  {/* {inactiveSuggestions.map((suggestion) => (
                    <LightTooltip
                      key={suggestion.id}
                      title={suggestion.description}
                      arrow
                      TransitionComponent={Zoom}
                      placement="right"
                    >
                      <div 
                        className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
                        style={{ padding: '5px', cursor: 'pointer', opacity: enough ? "1" : "0.4" }}
                      >
                        <img src={suggestion.image_url} alt={suggestion.display} className='w-4 h-4 rounded-[2.26px]' /> {suggestion.display}
                      </div>
                    </LightTooltip>
                  ))} */}

 {/* <Mention
              markup="@[__display__](user:__display__)"
              displayTransform={(app) => `@${app}`}
              trigger="@"
              data={filteredApps}
              renderSuggestion={(suggestion, search, highlightedDisplay) => {
                let Active = [];
                let InActive = [];
                if(suggestion?.active){
                  Active.push(suggestion)
                }else{
                  InActive.push(suggestion)
                }
                return(
                  // <div className="user">{highlightedDisplay}</div>
                  <div className='flex flex-col gap-3'>
                    <p className='text-[#7F7F81] font-medium text-xs px-2 pb-2'>Installed Apps</p>
                     {Active.map((app) => (
                          <LightTooltip
                              key={app?.id}
                              title={app?.description}
                              arrow
                              TransitionComponent={Zoom}
                              placement="right"
                          >
                              <div 
                                  className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
                                  style={{ padding: '5px', cursor: 'pointer', opacity: enough? "1": "0.4" }}
                              >
                                  <img src={app?.image_url} alt={app?.display} className='w-4 h-4 rounded-[2.26px]' /> {app?.display}
                              </div>
                          </LightTooltip>
                      ))}

                    <p className='text-[#7F7F81] font-medium text-xs p-2'>Uninstalled Apps</p>
                      {InActive.map((app) => (
                        <LightTooltip
                            key={app?.id}
                            title={app?.description}
                            arrow
                            TransitionComponent={Zoom}
                            placement="right"
                        >
                            <div 
                                className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
                                style={{ padding: '5px', cursor: 'pointer', opacity: enough? "1": "0.4" }}
                            >
                                <img src={app?.image_url} alt={app?.display} className='w-4 h-4 rounded-[2.26px]' /> {app?.display}
                            </div>
                        </LightTooltip>
                      ))}
                  </div>
                )
              }}
              className={styles.mentions}
            /> */}
            {/* <Mention
              trigger="@"
              markup="@[__display__](user:__display__)"
              displayTransform={(app) => `@${app}`}
              data={groupedApps.activeApps}
              className={styles.mentions}
            />
            <Mention
              trigger="@"
              markup="@[__display__](user:__display__)"
              displayTransform={(app) => `@${app}`}
              data={groupedApps.inactiveApps}
              className={styles.mentions}
            /> */}


{/* <Mention
  trigger="@"
  markup="@[__display__](user:__display__)"
  displayTransform={(app) => `@${app}`}
  data={groupedApps.activeApps}
  renderSuggestion={(suggestion, search, highlightedDisplay) => (
    <div>
      <p className='text-[#7F7F81] font-medium text-xs px-2 pb-2'>Installed Apps</p>
      <div className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
          style={{ padding: '5px', cursor: 'pointer', opacity: enough ? "1" : "0.4" }}>
        <img src={suggestion.image_url} alt={suggestion.display} className='w-4 h-4 rounded-[2.26px]' />
        {highlightedDisplay}
      </div>
    </div>
  )}
  className={styles.mentions}
/>

<Mention
  trigger="@"
  markup="@[__display__](user:__display__)"
  displayTransform={(app) => `@${app}`}
  data={groupedApps.inactiveApps}
  renderSuggestion={(suggestion, search, highlightedDisplay) => (
    <div>
      <p className='text-[#7F7F81] font-medium text-xs px-2 pb-2'>Uninstalled Apps</p>
      <div className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
          style={{ padding: '5px', cursor: 'pointer', opacity: enough ? "1" : "0.4" }}>
        <img src={suggestion.image_url} alt={suggestion.display} className='w-4 h-4 rounded-[2.26px]' />
        {highlightedDisplay}
      </div>
    </div>
  )}
  className={styles.mentions}
/>
</MentionsInput> */}


// customSuggestionsContainer={(children)=> {
//     console.log(children, "tags children");
//     return (
//         renderSuggestionsContainer(children)
// )}}

// customSuggestionsContainer={renderSuggestionsContainer}
// customSuggestionsContainer={(children)=> {
//     console.log(children, "tags children");
//     return(
//         // <div>
//         //     <span style={{fontWeight: "bold"}}>
//         //         <h2>This container has customised suggestions</h2>
//         //     </span>
//         //     {children}
//         // </div>
//         <div className='flex flex-col gap-3'>
//             <p className='text-[#7F7F81] font-medium text-xs px-2 pb-2'>Installed Apps</p>
//                 {children.filter(app => active.includes(app)).map(app => renderApp(app, true))}

//             <p className='text-[#7F7F81] font-medium text-xs p-2'>Uninstalled Apps</p>
//                 {children.filter(app => !active.includes(app)).map(app => renderApp(app, false))}
//         </div>
//     )
// }}

// markup="@[__display__](name:__name__)"
// displayTransform={(app) => `@${app.name}`}
// markup="@[__display__](name:__name__)"
// displayTransform={(name) => `@${name}`}
// trigger="@"
// data={groupApps().installedApps.concat(groupApps().uninstalledApps)}
// data={filteredApps}
//   renderSuggestion={(suggestion, search, highlightedDisplay) => (
//     //<div className='text-[#1774FD]'>{highlightedDisplay}</div>

//     <div className='flex flex-col gap-3'>
//         <p className='text-[#7F7F81] font-medium text-xs px-2 pb-2'>Installed Apps</p>
//         {active.includes(suggestion)? renderApp(suggestion, true) : null}

//         <p className='text-[#7F7F81] font-medium text-xs p-2'>Uninstalled Apps</p>
//         {!active.includes(suggestion)? renderApp(suggestion, false) : null}
//     </div>
//   )}
// renderSuggestion={renderSuggestion}
// onAdd={(e) => console.log(e, "this")}
// style={{ backgroundColor: 'transparent'}}
// renderSuggestion={renderSuggestion}


{/* <Dropdown top={`${dropdownPosition.top}px`} left={dropdownPosition.left} position="absolute">
        <div className='flex flex-col gap-3'>
            <p className='text-[#7F7F81] font-medium text-xs px-2 pb-2'>Installed Apps</p>
            {filteredApps.filter(app => active.includes(app)).map(app => renderApp(app, true))}

            <p className='text-[#7F7F81] font-medium text-xs p-2'>Uninstalled Apps</p>
            {filteredApps.filter(app => !active.includes(app)).map(app => renderApp(app, false))}
        </div>
</Dropdown> */}






      // const renderSuggestion = (suggestion, search, highlightedDisplay) => {
    //     const isInstalled = active.some(app => app.name === suggestion.name);
    //     const tooltipText = isInstalled ? suggestion.description : "This app needs to be installed.";

    //     return (
    //         <LightTooltip
    //             key={suggestion.id}
    //             title={tooltipText}
    //             arrow
    //             TransitionComponent={Zoom}
    //             placement="right"
    //         >
    //             <div 
    //                 className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
    //                 style={{ padding: '5px', cursor: 'pointer', opacity: isInstalled ? enough? "0.4": "1": "0.4" }}
    //             >
    //                 <img src={suggestion.image_url} alt={suggestion.name} className='w-4 h-4 rounded-[2.26px]' /> {suggestion.name}
    //             </div>
    //         </LightTooltip>
    //     );
    // };

    // const renderApp = (app, isInstalled) => {
    //     const tooltipText = enough ? "You can currently only tag one app per instruction or message" : app.description;

    //     return (
    //         <LightTooltip
    //             key={app.id}
    //             title={tooltipText}
    //             arrow
    //             TransitionComponent={Zoom}
    //             placement="right"
    //             sx={{
    //                 '& .MuiTooltip-arrow': {
    //                     color: '#fff',
    //                     dropShadow: "0 2px 10px rgba(200, 200, 200, 0.03)",
    //                     // transform: 'translateY(-6px)',
    //                 },
    //                 zIndex: 1000000,
    //             }}
    //             slotProps={{
    //                 popper: {
    //                 modifiers: [
    //                     {
    //                     name: 'offset',
    //                     options: {
    //                         offset: [0, 10],
    //                     },
    //                     },
    //                 ],
    //                 },
    //             }}
    //         >
    //             <div
    //                 className='flex gap-2 items-center justify-start p-2 text-xs text-[#101828]'
    //                 style={{ padding: '5px', cursor: 'pointer', opacity: isInstalled ? enough? "0.4": "1": "0.4" }}
    //                 // onClick={() => handleAppClick(app, isInstalled)}
    //             >
    //                 <img src={app.image_url} alt={app.name} className='w-4 h-4 rounded-[2.26px]'/> {app.name}
    //             </div>
    //         </LightTooltip>
    //     );
    // };

        // useEffect(() => {
    //     // if (showDropdown) {
    //         const searchTerm = value?.split('@')?.pop();
    //         const filtered = [...active, ...available]
    //             // .filter(app => app.name.toLowerCase().includes(searchTerm.toLowerCase()));
    //         setFilteredApps(filtered);
    //     // }
    // }, [value, active, available]);

    // const groupApps = () => {
    //     const installedApps = [];
    //     const uninstalledApps = [];
    //     [...active, ...available].forEach(app => active.includes(app) ? installedApps.push(app) : uninstalledApps.push(app));
    //     return { installedApps, uninstalledApps };
    // };

    // const renderApp = (app, isInstalled) => {
    //     const tooltipText = "You can only tag one app per instruction or message";

    //     return (
    //         <LightTooltip
    //             key={app.id}
    //             title={tooltipText}
    //             arrow
    //             TransitionComponent={Zoom}
    //             placement="right"
    //             sx={{
    //                 '& .MuiTooltip-arrow': {
    //                     color: '#fff',
    //                 },
    //                 zIndex: 1000000,
    //             }}
    //         >
    //             <div className={`app-item ${isInstalled ? "installed" : "uninstalled"}`}>
    //                 <img src={app.image_url} alt={app.name} className="app-icon" /> {app.name}
    //             </div>
    //         </LightTooltip>
    //     );
    // };
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaRegCopy } from "react-icons/fa";
import Key from "../../../Assets/svg/key.svg"
import { getApiKey } from '../../../features/apikey/apiKeySlice'
import { copyTextToClipboard } from '../../../helper'
import { resetSecretApiKey } from '../../../features/apikey/apiSecretKeySlice'
import { resetPublicApiKey } from '../../../features/apikey/apiPublicKeySlice'
import { CgSpinner } from 'react-icons/cg';
import { SuccessMessage } from '../../../Toast-Messages/Toastmessages';
import { SwitchRoleIcon } from '@/../Assets/svg';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import ModalPop from '@/../component/modalPop';

export default function ApiKey() {
  const [openModal, setOpenModal] = useState(false);
  const [openPhoneModal, setOpenPhoneModal] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);

  const [copyPublicKey, setCopyPublicKey] = useState(false);
  const [copySecretKey, setCopySecretKey] = useState(false);

    const [secretKeyNotification, setSecretKeyNotification] = useState(false);
    const [publicKeyNotification, setPublicKeyNotification] = useState(false);

    const [showKey, setShowKey] = useState(false)

  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible1, setPasswordVisible1] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };
  const togglePasswordVisibility1 = () => {
    setPasswordVisible1((prev) => !prev);
  };

  const peerWhatsApp = () => {
    if (openPhoneModal) {
      setOpenModal(false);
    } else if (openOtpModal) {
      setOpenPhoneModal(false);
      // setOpenModal(false);
    } else {
      return null;
    }
  };

  // console.log(openPhoneModal, "star");

  const peerNumber = localStorage.getItem("whatsapp");

  useEffect(() => {
    peerWhatsApp();
  }, [openOtpModal, openPhoneModal]);

  const apiKey = useSelector((state) => state.apiKey);
  const secretKey = useSelector((state) => state.secretApiKey);
  const secretKeyLoading = secretKey?.loading;

  const publicKey = useSelector((state) => state.publicApiKey);
  const publicKeyLoading = publicKey.loading;

  useEffect(() => {
    dispatch(getApiKey());
  }, [publicKeyLoading, secretKeyLoading]);

  function resetSecret() {
    // if(!copySecretKey) {
    dispatch(resetSecretApiKey());
    setSecretKeyNotification(true);
    // }
  }

    function resetPublic () {
        // if(!copyPublicKey) {
            dispatch(resetPublicApiKey())
            setPublicKeyNotification(true)
        // } else {
        //     return null
        // }
    }

    const changeType = () => {
        setShowKey(prev => !prev)
    }

  const removeSecretKeyNotification = () => {
    setTimeout(() => {
      setSecretKeyNotification(false);
    }, 1000);
  };
  const removePublicKeyNotification = () => {
    setTimeout(() => {
      setPublicKeyNotification(false);
    }, 1000);
  };

  useEffect(() => {
    removeSecretKeyNotification();
  }, [secretKeyLoading]);

  useEffect(() => {
    removePublicKeyNotification();
  }, [publicKeyLoading]);

    return (
        <div className='flex gap-[64px] w-full'>
            <div className='w-[301px] h-[35px] flex flex-col gap-1'>
                <p className='font-medium text-BLACK-_100 text-sm'>API Keys</p>
                <p className='text-xs text-BLACK-_200'>Your Business Public and Secret Keys are here.</p>
            </div>
            <div className='flex flex-col gap-5 bg-WHITE-_100'> {/* rounded-lg  border-BLACK-_400 border */}
                {/* <div className='flex justify-between py-[15px] border-b'> */}
                    {/* <div className='flex gap-1 items-center ml-[20px]'> */}
                        {/* <img src={Key} className='w-[24px] h-[24px]'/> */}
                        <div className='flex flex-col gap-3'>
                            <div className='flex justify-between'>
                                <label className='text-BLACK-_100 text-xs font-medium'>Secret Api Key</label>
                                <button 
                                    className={`flex items-center gap-1 text-[#1774FD] text-xs font-semibold cursor-pointer`}
                                    onClick={() => {
                                        copyTextToClipboard(`${apiKey?.data?.secret_key}`); 
                                        setCopySecretKey(true)
                                        setTimeout(() => {
                                            setCopySecretKey(false);
                                        }, 1000);
                                    }}
                                >
                                    <p>{copySecretKey ? "Copied Key" : "Copy Key"}</p>
                                    {/* <FaRegCopy /> ${copySecretKey ? "text-[10px]"  : "text-[10px] " } */}
                                </button>
                            </div>
                            <div className='relative'>
                                <input 
                                    className='text-[10px] cursor-pointer text-BLACK-_200 !rounded-lg'
                                    value={apiKey?.data?.secret_key}
                                />
                                <button 
                                    className="cursor-pointer  absolute right-3 top-3"  
                                    onClick={() => resetSecret()}
                                >
                                    {secretKeyLoading ? 
                                        <CgSpinner className=" animate-spin text-lg text-black mx-auto " />
                                    :
                                        // <p className='text-WHITE-_100 text-[10px] font-semibold'>Reset</p> mr-[20px]
                                        <SwitchRoleIcon color="#828282"/>
                                    }
                                    
                                </button>
                            </div>
                           
                        </div>
                    {/* </div> */}
                    {/* <div className='flex items-center gap-1'>
                        <button 
                            className={`${copySecretKey ? "text-[10px]"  : "text-[10px] " } flex items-center gap-1 text-WHITE-_100 bg-BLACK-_500 py-2 rounded-lg  px-2.5 font-semibold cursor-pointer`}
                            onClick={() => {copyTextToClipboard(`${apiKey?.data?.secret_key}`); setCopySecretKey(true)}}
                        >
                            <p>{copySecretKey ? "Copied" : "Copy"}</p>
                            <FaRegCopy />
                        </button>
                        <button 
                            className="rounded-lg bg-BLACK-_500 py-2 px-2.5 cursor-pointer mr-[20px] "  
                            onClick={() => resetSecret()}
                        >
                            {secretKeyLoading ? 
                                <CgSpinner className=" animate-spin text-lg text-WHITE-_100 mx-auto " />
                            :
                                <p className='text-WHITE-_100 text-[10px] font-semibold'>Reset</p>
                            }
                            
                        </button>

                    </div> */}
                {/* </div> */}
                {/* <div className='flex justify-between py-[15px] border-b'> */}
                    {/* <div className='flex gap-1 items-center ml-[20px]'> */}
                        {/* <img src={Key} className='w-[24px] h-[24px]'/> */}
                        <div className='flex flex-col gap-3'>
                            <div className='flex justify-between'>
                                <label className='text-BLACK-_100 text-xs font-medium'>Public Api Key</label>
                                <button 
                                    className={`flex items-center gap-1  text-[#1774FD] text-xs font-semibold cursor-pointer`}
                                    onClick={() => {
                                        copyTextToClipboard(`${apiKey?.data?.public_key}`); 
                                        setCopyPublicKey(true);
                                        setTimeout(() => {
                                            setCopyPublicKey(false);
                                        }, 1000);
                                    }}
                                >
                                    <p>{copyPublicKey ? "Copied Key" : "Copy Key"}</p>
                                    {/* <FaRegCopy /> ${copyPublicKey ? "text-[10px]"  : " text-[10px] " } */}
                                </button>
                            </div>
                            <div className='relative'>
                                <input 
                                    className={`${showKey? 'text-[10px] text-BLACK-_200 ' : "font-bold leading-3 text-black"} !pt-[11px] !pb-[7px] !rounded-lg cursor-pointer focus:outline-none`}
                                    // type={showKey? "text" : "password"}
                                    value={showKey?  apiKey?.data?.public_key : "*******************"}
                                />
                                <button 
                                    className="cursor-pointer  absolute right-9 top-2.5"
                                    onClick={() => changeType()}
                                >
                                    {showKey ? 
                                        <BsEyeSlash className='text-[#828282]'/>
                                        // <CgSpinner className=" animate-spin text-lg text-WHITE-_100 mx-auto " />
                                    :
                                        // <p className='text-WHITE-_100 text-[10px] font-semibold'>Reset</p>
                                        <BsEye className='text-[#828282]'/>
                                    }
                                </button>
                                <button 
                                    className="cursor-pointer  absolute right-3 top-3"
                                    onClick={() => resetPublic()}
                                >
                                    {publicKeyLoading ? 
                                        <CgSpinner className=" animate-spin text-lg text-black mx-auto " />
                                    :
                                        // <p className='text-WHITE-_100 text-[10px] font-semibold'>Reset</p>
                                        <SwitchRoleIcon color="#828282"/>
                                    }
                                </button>
                            </div>
                        </div>
                    {/* </div> */}
                    {/* <div className='flex items-center gap-1'>
                        <button 
                            className={`${copyPublicKey ? "text-[10px]"  : " text-[10px] " } flex items-center gap-1  text-WHITE-_100 rounded-lg bg-BLACK-_500 py-2 px-2.5 font-semibold cursor-pointer`}
                            onClick={() => {copyTextToClipboard(`${apiKey?.data?.public_key}`); setCopyPublicKey(true)}}
                        >
                            <p>{copyPublicKey ? "Copied" : "Copy"}</p>
                            <FaRegCopy />
                        </button>
                        <button 
                            className=" rounded-lg bg-BLACK-_500  py-2 px-2.5  cursor-pointer mr-[20px]"
                            onClick={() => resetPublic()}
                        >
                            {publicKeyLoading ? 
                                <CgSpinner className=" animate-spin text-lg text-WHITE-_100 mx-auto " />
                            :
                                <p className='text-WHITE-_100 text-[10px] font-semibold'>Reset</p>
                            }
                            
                        </button>

                    </div> */}
                {/* </div> */}

                <ModalPop isOpen={""}>
                    <div className='w-[496px] h-[261px] px-[50px] py-[30px] bg-white rounded-lg shadow flex-col justify-start items-center gap-10 flex'>
                        <div className='flex flex-col gap-[50px]'>
                            <h2 className="text-neutral-900 text-2xl font-medium">Reset API Key</h2>
                            <p className="w-[396px] text-center text-zinc-500 text-xs font-normal leading-[18px]">If you choose to reset your API Key, the current key will no longer be authorized to make requests to our Admin API. A new key will be generated for your use.</p>
                        </div>
                        <div className="w-[396px] h-10 justify-start items-start gap-6 inline-flex">
                            <button className="self-stretch px-6 py-2 rounded-lg border border-gray-300 justify-center items-center gap-2 flex">
                                <p className="text-zinc-500 text-sm font-semibold leading-normal">Cancel</p>
                            </button>
                            <button className="self-stretch px-6 py-2 bg-neutral-900 rounded-lg justify-center items-center gap-2 flex">
                                <p className="text-white text-sm font-bold leading-normal">Reset Key</p>
                            </button>
                        </div>
                    </div>
                </ModalPop>
            </div>
            {secretKeyNotification && <SuccessMessage Formodal={true} success={secretKey?.data?.message} className="mt-24 mr-20" />}
            {publicKeyNotification && <SuccessMessage Formodal={true} success={publicKey?.data?.message} className="mt-24 mr-20" />}
        </div>
    //   </div>

    //   {secretKeyNotification && (
    //     <SuccessMessage
    //       Formodal={true}
    //       success={secretKey?.data?.message}
    //       className="mt-24 mr-20"
    //     />
    //   )}
    //   {publicKeyNotification && (
    //     <SuccessMessage
    //       Formodal={true}
    //       success={publicKey?.data?.message}
    //       className="mt-24 mr-20"
    //     />
    //   )}
    // </div>
  );
}

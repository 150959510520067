export async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
      // alert("copied")
    } else {
      return document.execCommand('copy', true, text);
    }
  }

export const highlightAtText = (text) => {
  const parts = text.split(/(@\w+)/g);
  return parts.map((part, index) => {
      if (part.startsWith('@')) {
          return <span key={index} style={{ color: 'blue' }}>{part}</span>;
      }
      return part;
  });
}

export const checkConfigType = (application) => {
  if (!application.applicationPlugins || application.applicationPlugins.length === 0) {
      console.log("No applicationPlugins available.");
      return false;
  }

  const plugin = application.applicationPlugins[0];
  if (!plugin.config || plugin.config.length === 0) {
      // console.log("No config available in the first plugin.");
      return false;
  }

  return plugin.config.some((configItem) => {
      if (configItem.type && configItem.type.trim() !== "") {
          // console.log(`Found non-empty type: ${configItem.type}`);
          return true;
      }
      return false;
  });
}

export const setInputHeight = (element, defaultHeight) => {
  if (element){
    const target = element.target? element.target : element;
    target.style.height = defaultHeight;
    target.style.height = `${target.scrollHeight}px`;
  }
}

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import darkMode from "Assets/svg/newDarkMode.svg";
import { useDispatch, useSelector } from "react-redux";
import lightMode from "Assets/svg/newLightMode.svg";
import widgetArrow from "Assets/gif/widgetArrow.gif";
import Spinner from "component/Spinner";
import axios from "axios";
import TokenService from "services/token";
import {
  getApplicationById,
  setAgent,
  getApplicationDraft,
} from "features/application/getApplicationByIdSlice";
const Widget = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [showImage, setShowImage] = useState(true);

  useEffect(() => {
    // Set a timer to hide the image after 10 seconds
    const timer = setTimeout(() => {
      setShowImage(false);
    }, 5000); // 10000 milliseconds = 10 seconds

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    // Function to create and load a script
    const loadScript = (src, crossOrigin = false) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        if (crossOrigin) {
          script.crossOrigin = "anonymous";
        }
        script.onload = () => resolve();
        script.onerror = () =>
          reject(new Error(`Failed to load script: ${src}`));
        document.head.appendChild(script);
      });
    };

    // Function to load and initialize widget script
    const loadAndInitWidgetScript = async () => {
      try {
        // Load React and ReactDOM scripts if needed
        await loadScript(
          "https://unpkg.com/react@18/umd/react.development.js",
          true
        );
        await loadScript(
          "https://unpkg.com/react-dom@18/umd/react-dom.development.js",
          true
        );

        // Load the widget script
        await loadScript(`${process.env.REACT_APP_WIDGET_URL}`);

        // Initialize the widget if the library is available
        if (window.WidgetLibrary) {
          window.WidgetLibrary.initChatWidget(id);
          setIsScriptLoaded(true); // Set the state to true once the script is loaded and initialized
        }
      } catch (error) {
        console.error("Failed to load scripts", error);
        setIsScriptLoaded(false); // Optionally set to false if loading fails
      }
    };

    loadAndInitWidgetScript();

    // Cleanup function to remove scripts when the component unmounts
    return () => {
      const scripts = [
        "https://unpkg.com/react@18/umd/react.development.js",
        "https://unpkg.com/react-dom@18/umd/react-dom.development.js",
        process.env.REACT_APP_WIDGET_URL,
      ];
      scripts.forEach((src) => {
        const script = document.querySelector(`script[src="${src}"]`);
        if (script) {
          document.head.removeChild(script);
        }
      });
    };
  }, [id]);
  const token = TokenService.getToken();
  const URL = process.env.REACT_APP_BASEURL;
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const dispatch = useDispatch();

  const newAgent = getAgentApplication?.agent || null;

  const getDarkModeStatus = () => {
    const savedTheme = localStorage.getItem("theme");
    console.log("here",newAgent, savedTheme);
    if (savedTheme) {
      return savedTheme
    } else {
      // put api light mode
      return newAgent.is_light ? false : true;
    }
  };
  const [isDark, setIsDark] = useState(getDarkModeStatus());

  const toggleSwitch = async () => {
    try {
      setIsDark(!isDark);
      localStorage.setItem("theme",!isDark);
      return;
      // setIsScriptLoaded(false);

      // const formData = new FormData();
      // formData.append("is_light", !isDark ? false : true);
      // formData.append("type", newagentType);

      // const response = await axios.put(
      //   `${URL}/dashboard/applications/${id}`,
      //   formData,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //       "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
      //     },
      //   }
      // );
      // const object = {
      //   ...(response?.data?.data?.draft
      //     ? response?.data?.data?.draft
      //     : response?.data?.data),
      // };
      // object.id = response?.data?.data?.id;
      // dispatch(setAgent(object));
      // dispatch(getApplicationById());
      // dispatch(getApplicationDraft(id));
    } catch (error) {
      console.error("Error updating data:", error);
    } finally {
      setIsScriptLoaded(true);
    }
  };
  return (
    <div
      className={`h-screen border-t border-[#FFF175] w-screen relative  flex items-center justify-center
    
    ${isDark ? "bg-[#1E1E1C]" : " bg-white"}
    `}
    >
      <div className="bg-[#FFE81A] font-semibold text-xs px-1 py-0.5 absolute top-2 rounded left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      TEST
      </div>
      {showImage && (
        <img
          src={widgetArrow}
          alt="blackPlane"
          className="absolute bottom-40 right-20 md:bottom-10  md:right-20"
        />
      )}

      <div className=" absolute top-10  flex gap-1 items-center justify-center  ">
        <div
          className={`w-[48px] h-8 flex items-center bg-[#E6E7EC] rounded-full p-1 cursor-pointer transition-colors duration-300 `}
          onClick={toggleSwitch}
        >
          <div
            className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-transform duration-300 ${
              isDark ? "translate-x-5" : ""
            }`}
            style={{
              backgroundImage: `url(${isDark ? darkMode : lightMode})`,
              backgroundSize: "cover",
            }}
          ></div>
        </div>
      </div>
      {isScriptLoaded ? (
        <p></p>
      ) : (
        <div className="flex flex-col">
          <Spinner />
          <p
            className={`
   ${isDark ? "text-white" : " text-dark"}
  `}
          >
            This might take a few seconds...
          </p>
        </div>
      )}
    </div>
  );
};

export default Widget;

import React, { useState } from "react";
import Whatsapp from "../../../../Assets/svg/whatsApp.svg";
import Button from "../../../Button";
import ModalPop from "../../../modalPop";
import Success from "./Success";
import { CgSpinner } from "react-icons/cg";

import {
  ErrorMessage,
  SuccessMessage,
} from "../../../../Toast-Messages/Toastmessages";
import TokenService from "../../../../services/token";
import { useSelector } from "react-redux";
import WhatsAppIcon from "../../../../Assets/svg/WhatsAppIcon.svg";
import BigPlus from "../../../../Assets/svg/BigPlus.svg";
import Stars from "../../../../Assets/svg/Stars.svg";

import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";

const Otp = ({ handleClose, setOpenOtpModal, openSuccessViewModal }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [code, setCode] = useState("");
  const number = localStorage.getItem("whatsapp");
  const channel = localStorage.getItem("channel");
  const type = localStorage.getItem("type");

  const token = TokenService.getToken();
  const URL = process.env.REACT_APP_BASEURL;
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const data = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = data && data.length >= 1 ? data[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;

  const handleCloseOtpModal = () => {
    setOpenOtpModal(false);
    openSuccessViewModal();
  };

  const submitForm = async () => {
    if (!applicationId) {
      console.error(
        "No applicationId provided. submitform function will not run."
      );
      return;
    }
    setLoading(true);
    const data = {
      phone_number: number,
      code: code,
      type: type,
      channel: channel,
    };

    try {
      const res = await axios.post(
        `${URL}/businesses/application/${applicationId}/peer/connect`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccess("Connected successfully!");
      setTimeout(() => {
        setSuccess(false);
      }, 1500);
      setLoading(false);
      // handleClose()
      handleCloseOtpModal();
    } catch (error) {
      if (number) {
        // localStorage.removeItem("whatsapp");
      }
      console.error("error", error.response.data.message);
      setLoading(false);
      setError(error?.response?.data?.message);
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  };
  const [resending, setResending] = useState(false);
  const resendOtp = async () => {
    if (!applicationId) {
      console.error(
        "No applicationId provided. submitform function will not run."
      );
      return;
    }
    setResending(true);
    const data = {
      phone_number: number,
      type: type,
      channel: channel,
    };

    try {
      const res = await axios.post(
        `${URL}/businesses/application/${applicationId}/peer/request/resend`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // handleClose()
    } catch (error) {
      if (number) {
        // localStorage.removeItem("whatsapp");
      }
      console.error("error", error.response.data.message);
      setError(error?.response?.data?.message);
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
    finally{
      setResending(false);

    }
  };
  return (
    <div className="bg-WHITE-_100 w-[529px] h-fit mt-[60px] flex flex-col justify-center items-center rounded-lg"> {/* h-[399px] h-[459px] w-[558px]*/}
        <div className='w-full flex items-center justify-end px-[28px] py-[14px] border-b border-neutral-150'>
          <div className="w-[36px] h-[36px] border rounded-lg mr-3  flex cursor-pointer justify-center items-center p-2" onClick={handleClose}><AiOutlineClose className='text-neutral-500'/></div>
        </div>
        <div className="w-[410px]  flex flex-col gap-5 justify-center items-center mt-10"> {/* h-[122px] */}
            <div className="flex flex-col gap-[20px] justify-center items-center">
              <div className='flex justify-between w-[106.48px] h-[40.32px]'>
                  <img src={Whatsapp} alt='whatsapp_icon' className='w-[40.32px] h-[40.32px]'/>
                  <img src={BigPlus} alt="plus icon" className=''/>
                  <img src={Stars} alt="Star icon" className=''/>
              </div>
              <p className="text-2xl font-medium text-BLACK-_600">Integrate With WhatsApp</p>
              <p className="text-sm font-normal text-BLACK-_300">To integrate your assistant with WhatsApp we need just 2 things</p>
            </div>
            <div className="w-[174px]  mt-0 flex justify-center items-center gap-1"> {/* h-[27px] */}
                <p className="w-[27px] h-[27px] text-[10px] bg-BLUE-_200 rounded-full text-white flex flex-col items-center justify-center">1</p>
                <div className="w-[24px] h-[2px] border bg-WHITE-_200"></div>
                <p className="w-[27px] h-[27px] text-[10px] bg-BLUE-_200 rounded-full text-white flex flex-col items-center justify-center">2</p>
                <div className="w-[24px] h-[2px] border bg-WHITE-_200"></div>
                <p className="text-[10px] w-[52px] h-[23px] bg-BLUE-_300 rounded text-white flex flex-col items-center justify-center">Deploy</p>
            </div>
        </div>
      {/* </div> */}
      <div className="flex flex-col gap-1 w-[420px] mt-10">
        <p className="text-xs text-BLACK-_600">OTP</p>
        <input
          type="text"
          className="outline-none p-[10px_14px]  placeholder:text-xs"
          onChange={(e) => setCode(e.target.value)}
          value={code}
          placeholder="Please enter the OTP code sent to your number "
        />
        <div className="text-sm w-fit flex gap-1 self-end">
          <p className="text-BLACK-_300">Didn’t get an OTP?</p>

          {resending ? (
            <span className="text-[#1774FD] px-8">
              <CgSpinner className=" animate-spin text-lg " />
            </span>
          ) : (
            <span className="text-[#1774FD] cursor-pointer" onClick={resendOtp}>
              Resend OTP
            </span>
          )}
        </div>
      </div>
      <div className="w-full h-[34px] flex justify-end gap-3 my-8 px-[30px]">
        <Button
          text="No,Later"
          type="button"
          onClick={handleClose}
          className="bg-none cursor-pointer w-[91px] text-[#828282] text-xs font-semibold border border-neutral-200  rounded-lg"
        />
        <Button
          onClick={submitForm}
          loading={loading}
          text="Continue"
          className="bg-BLACK-_100 text-sm w-[91px]  cursor-pointer rounded-lg font-semibold text-WHITE-_100"
          type="button"
        />
      </div>
      {success && <SuccessMessage success={success} />}
      {error && <ErrorMessage error={error} />}
      {/* <ModalPop isOpen={openSuccessModal}>
            <Success handleClose={() => setOpenSuccessModal(false)} whatsapp={whatsapp} />
        </ModalPop> */}
    </div>
  );
};

export default Otp;


{/* <svg xmlns="http://www.w3.org/2000/svg" width="66" height="65" viewBox="0 0 66 65" fill="none">
                <rect x="1.3125" y="0.8125" width="63.375" height="63.375" rx="12.1875" fill="white"/>
                <rect x="1.3125" y="0.8125" width="63.375" height="63.375" rx="12.1875" stroke="#E5E5E5" stroke-width="1.625"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M42.566 22.9235C40.0282 20.3946 36.653 19.0013 33.0567 19C25.6463 19 19.6154 25.0014 19.6128 32.3781C19.6115 34.7363 20.231 37.038 21.4074 39.0665L19.5 46L26.6268 44.1396C28.5902 45.2058 30.8012 45.7671 33.0509 45.7678H33.0567C40.4658 45.7678 46.4975 39.7658 46.5 32.389C46.5013 28.8139 45.1044 25.453 42.566 22.9241V22.9235ZM33.0567 43.5084H33.0522C31.0475 43.5078 29.0808 42.9714 27.3649 41.9585L26.9568 41.7173L22.7276 42.8213L23.8563 38.7175L23.5907 38.2967C22.4723 36.5262 21.8812 34.4797 21.8825 32.3788C21.885 26.2478 26.8975 21.2594 33.0613 21.2594C36.0458 21.2607 38.8511 22.4186 40.9609 24.5209C43.0707 26.6225 44.2316 29.4169 44.2304 32.3877C44.2277 38.5194 39.2153 43.5078 33.0567 43.5078V43.5084ZM39.1856 35.1803C38.8498 35.0128 37.1984 34.2045 36.8902 34.0929C36.5821 33.9812 36.3584 33.9255 36.1347 34.2603C35.9111 34.5952 35.2671 35.3483 35.0711 35.5709C34.8752 35.7942 34.6792 35.8218 34.3433 35.6543C34.0076 35.4869 32.9252 35.134 31.6418 33.9953C30.6434 33.1088 29.969 32.0144 29.7731 31.6795C29.5772 31.3446 29.7525 31.1637 29.9201 30.9976C30.0709 30.8475 30.2559 30.6069 30.4241 30.4119C30.5924 30.2168 30.6479 30.077 30.76 29.8544C30.8722 29.6311 30.8161 29.4362 30.7323 29.2687C30.6485 29.1013 29.9768 27.4557 29.6964 26.7867C29.4237 26.1349 29.1466 26.2234 28.9409 26.2125C28.745 26.2029 28.5213 26.201 28.297 26.201C28.0726 26.201 27.7091 26.2844 27.401 26.6193C27.0929 26.9541 26.2252 27.763 26.2252 29.4079C26.2252 31.0527 27.4287 32.6431 27.5969 32.8663C27.7652 33.0896 29.9658 36.4659 33.3352 37.9144C34.1365 38.2589 34.7624 38.4648 35.2503 38.6188C36.0548 38.8734 36.7871 38.8375 37.3659 38.7516C38.0112 38.6553 39.3533 37.9426 39.633 37.1619C39.9128 36.3811 39.9128 35.7114 39.829 35.5722C39.7452 35.433 39.5209 35.3489 39.185 35.1815L39.1856 35.1803Z" fill="#292D32"/>
            </svg> */}
            {/* <img src={Whatsapp} alt='whatsapp_icon' className='w-[65px] h-[65px]'/>

            <p className="text-2xl font-medium text-BLACK-_600">Integrate With Whatsapp</p>
            <p className="text-sm font-normal text-BLACK-_300">To integrate your App with WhatsApp we need just 2 things</p> */}
{/* <div className="w-[340px] h-[76px] flex flex-col mt-14 gap-3"> 
            <Button
                onClick={submitForm}  
                loading={loading}
                text="Finish" 
                className="bg-BLACK-_100 text-sm h-[40px] cursor-pointer rounded-lg font-semibold text-WHITE-_100"
                type="button" 
            />
            <Button 
                text="Cancel"
                type="button" 
                onClick={handleClose}  
                className="bg-none cursor-pointer text-[#868686] font-bold" 
            />
        </div> */}
import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import UploadGif from "../../../Assets/gif/uploadAnimation.gif"
import LoadingGif from "../../../Assets/gif/loading.gif"

const UploadMemory = ({ handleClose }) => {

  return (
    <div className="bg-WHITE-_100 rounded-lg w-[635px] py-[54px] flex flex-col gap-[28px] items-center justify-center  h-[306.89px] mt-[100px]">
        {/* <div className='w-full flex justify-end'>
            <div className="w-[36px] h-[36px] border rounded-lg mr-3 mt-3 flex cursor-pointer justify-center items-center p-2" onClick={handleClose}><AiOutlineClose /></div>
        </div> */}
            <img src={UploadGif}  alt="upload" className='w-[100px] h-[100px]'/>
        {/* <div className="flex justify-center flex-col gap-2 items-center"> */}
            <div className='flex flex-col justify-center items-center gap-[32px]'>
                <p className='text-[#101828] font-medium text-2xl'>Building Memory</p>
                <p className='text-[#7F7F81] text-base'>Training Assistant</p>
            </div>
            <div className='pb-[26px]'>
            <img src={LoadingGif}  alt="loading" className='w-[287px] h-[4px] '/>


            </div>
        {/* </div> */}
    </div>
  )
}

export default UploadMemory
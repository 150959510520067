import React, { Fragment, useEffect, useState } from 'react'
import { Switch } from '@headlessui/react';

import Personal from './components/Personal'
import Business from './components/Business'
import ModalPop from '../../component/modalPop';
import TwoFA from '../../component/modals/password/TwoFA';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsTwoFa } from '../../features/twofa/settingsTwoFaSlice';
import { toggleTwoFA } from '../../features/twofa/toggleTwoFaSlice';
import { getProfile } from '../../features/profile/getProfileSlice';
import { generateTwoFA } from '@../../features/twofa/generateTwoFaSlice';

const Profile = ({
  activeButton,
  setActiveButton,
  setShowUnsavedSettingsModal,
  showUnsavedSettingsModal,
  handleCloseUnsavedSettingsModal,
  reroute,
  setReroute,
  isFormModified,
  setIsFormModified
}) => {
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();
  const settings = useSelector(state => state.settingsTwoFa);
  // console.log(settings, "this is settings 2FA from settings")
  const secret = useSelector(state => state.generateTwoFa)
  // console.log(secret, "this is secret 2FA from settings")
  const check_setup = settings?.data?.is_setup; //included this to check if 2FA has been setup
  const check_enabled = settings?.data?.is_enabled; //included this to check if 2FA is enabled

  const { loading } = useSelector(state => state.setupTwoFa); //included this to act as a dependency
  const wait = useSelector(state => state.toggleTwoFa.loading);//included this to act as a dependency

  useEffect(() => {
    dispatch(getSettingsTwoFa())
  },[dispatch, loading, wait])

  useEffect(() => {
    dispatch(getProfile())
  }, [])

  function checkTwoFa () {
    if(check_setup === false) { //corrected this logic
      setOpenModal(true)
      dispatch(generateTwoFA())
    } else {
      dispatch(toggleTwoFA())
    }

  } 


  return (
    <div className=''>{/* mt-[52px] */}
      <div className='mb-[50px]'>
        <Personal 
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          setShowUnsavedSettingsModal={setShowUnsavedSettingsModal}
          showUnsavedSettingsModal={showUnsavedSettingsModal}
          handleCloseUnsavedSettingsModal={handleCloseUnsavedSettingsModal}
          reroute={reroute}
          setReroute={setReroute}
          isFormModified={isFormModified}
          setIsFormModified={setIsFormModified}
        />
      </div>
      <hr />
      <div className='mt-[50px]'> 
        <Business 
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          setShowUnsavedSettingsModal={setShowUnsavedSettingsModal}
          showUnsavedSettingsModal={showUnsavedSettingsModal}
          handleCloseUnsavedSettingsModal={handleCloseUnsavedSettingsModal}
          reroute={reroute}
          setReroute={setReroute}
          isFormModified={isFormModified}
          setIsFormModified={setIsFormModified}
        />
      </div>
      <hr/>
      <div className="flex w-[421px] gap-[119px] mt-[50px] items-center">
        <div className='flex flex-col'>
          <p className='text-sm text-BLACK-_100 font-medium'>2FA Authentication (Multi Factor)</p>
          <p className='text-sm text-[#7F7F81]'>Set up another layer of authentication for extra protection of your account.</p>
        </div>
        <div>
          <Switch checked={openModal} onChange={checkTwoFa} as={Fragment}>
            {({ checked }) => (
                /* Use the `checked` state to conditionally style the button.  mb-[180px]*/
                <button
                className={`${
                    checked ? 'bg-blue-600' : check_setup && check_enabled ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                <span className="sr-only">Enable notifications</span>
                <span
                    className={`${
                    checked ? 'translate-x-6' : check_setup && check_enabled ?  'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
                </button>
            )}
          </Switch>
        </div>
      </div>

      <ModalPop isOpen={openModal}>
          <TwoFA handleClose={() => setOpenModal(false)} secret={secret?.data?.url}/>
      </ModalPop>
    </div>
  )
}

export default Profile
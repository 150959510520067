import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

let URL = process.env.REACT_APP_BASEURL;

const initialState = {
  loading: false,
  customers: [],
  conversations: [],
  conversationDetails: {},
  conversation: [],
  customer: {},
  customerPagination: {},
  conversationPagination: {},
  conversationSummary: {},
  error: "",
};

export const getCustomers = createAsyncThunk(
  "customers/getCustomers",
  async ({ applicationId, page, per_page }, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    if (applicationId === null) {
      console.error(
        "No applicationId provided. fetchData function will not run."
      );
      return;
    }
    try {
      const response = await axios.get(
        `${URL}/dashboard/applications/${applicationId}/customers?page=${page}&per_page=${per_page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;

      // put it in store
    } catch (error) {
      console.log(error, "error");
      return rejectWithValue("failed to get countries");
    }
  }
);
export const getConversations = createAsyncThunk(
  "customers/getConversations",
  async (
    {
      applicationId,
      page,
      per_page,
      status = "",
      search_term,
      start_date,
      end_date,
    },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("token");

    if (applicationId === null) {
      console.error(
        "No applicationId provided. fetchData function will not run."
      );
      return;
    }
    try {
      const response = await axios.get(
        `${URL}/application-widget/dashboard/conversation/${applicationId}?page=${page}&per_page=${per_page}&status=${status}&search_term=${
          search_term ?? ""
        }&start_date=${start_date ?? ""}&end_date=${end_date ?? ""}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;

      // put it in store
    } catch (error) {
      console.log(error, "error");
      return rejectWithValue("failed to get countries");
    }
  }
);
export const getConversation = createAsyncThunk(
  "customers/getConversation",
  async (conversationId, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    if (conversationId === null) {
      console.error(
        "No conversationId provided. fetchData function will not run."
      );
      return;
    }
    try {
      const response = await axios.get(
        `${URL}/application-widget/dashboard/conversation/${conversationId}/get`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;

      // put it in store
    } catch (error) {
      console.log(error, "error");
      return rejectWithValue("failed to get conversationId");
    }
  }
);
export const getConversation1 = createAsyncThunk(
  "customers/getConversation1",
  async (conversationId, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    if (conversationId === null) {
      console.error(
        "No conversationId provided. fetchData function will not run."
      );
      return;
    }
    try {
      const response = await axios.get(
        `${URL}/application-widget/dashboard/conversation/${conversationId}/get`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;

      // put it in store
    } catch (error) {
      console.log(error, "error");
      return rejectWithValue("failed to get conversationId");
    }
  }
);
export const getConversationSummary = createAsyncThunk(
  "customers/getConversationSummary",
  async (
    { conversationId, applicationId, customerId },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("token");

    if (conversationId === null) {
      console.error(
        "No conversationId provided. fetchData function will not run."
      );
      return;
    }
    try {
      const response = await axios.post(
        `${URL}/widget-conversation/generate-summary`,
        {
          conversationId,
          customerId,
          applicationId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;

      // put it in store
    } catch (error) {
      console.log(error, "error");
      return rejectWithValue("failed to get conversationId");
    }
  }
);
export const getCustomer = createAsyncThunk(
  "customers/getCustomer",
  async ({ applicationId, id }, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    if (applicationId === null) {
      console.error(
        "No applicationId provided. fetchData function will not run."
      );
      return;
    }
    try {
      const response = await axios.get(
        `${URL}/dashboard/applications/${applicationId}/customers/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;

      // put it in store
    } catch (error) {
      console.log(error, "error");
      return rejectWithValue("failed to get countries");
    }
  }
);

const getCustomerSlice = createSlice({
  name: "plugins",
  initialState,
  reducers: {
    addMessageToConversation: (state, action) => {
      if (state.conversation.includes(action.payload)) return;
      state.conversation.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomers.pending, (state) => {
      if (!state.customers.length) state.loading = true;
    });
    builder.addCase(
      getCustomers.fulfilled,
      (state, action) =>
        void ((state.loading = false),
        (state.customers = action.payload.data),
        (state.customerPagination = action.payload.meta))
    );
    builder.addCase(
      getCustomers.rejected,
      (state, action) =>
        void (
          // showToast
          ((state.data = []), (state.error = action.payload))
        )
    );
    builder.addCase(getCustomer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getCustomer.fulfilled,
      (state, action) =>
        void ((state.loading = false), (state.customer = action.payload.data))
    );
    builder.addCase(
      getCustomer.rejected,
      (state, action) =>
        void (
          // showToast
          ((state.customer = {}), (state.error = action.payload))
        )
    );
    builder.addCase(getConversations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getConversations.fulfilled,
      (state, action) =>
        void ((state.loading = false),
        (state.conversations = action.payload.data),
        (state.conversationPagination = action.payload.meta))
    );
    builder.addCase(
      getConversations.rejected,
      (state, action) =>
        void (
          // showToast
          ((state.conversations = []), (state.error = action.payload))
        )
    );
    builder.addCase(getConversation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getConversation.fulfilled,
      (state, action) =>
        void ((state.loading = false),
        (state.conversation = action.payload.data.messages),
        (state.conversationDetails = action.payload.data))
    );
    builder.addCase(
      getConversation.rejected,
      (state, action) =>
        void (
          // showToast
          ((state.conversations = []), (state.error = action.payload))
        )
    );

    builder.addCase(getConversationSummary.pending, (state) => {
      state.loading = true;
      state.conversationSummary = {};
    });
    builder.addCase(
      getConversationSummary.fulfilled,
      (state, action) =>
        void ((state.loading = false),
        (state.conversationSummary = action.payload.data))
    );
    builder.addCase(
      getConversationSummary.rejected,
      (state, action) =>
        void (
          // showToast
          ((state.conversationSummary = {}), (state.error = action.payload))
        )
    );
  },
});
export const { addMessageToConversation } = getCustomerSlice.actions;
export default getCustomerSlice.reducer;

import React from 'react'
import { Link, useLocation, useParams } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";

import DashboardLayout from '../../layout/DashboardLayout'
import AllPlugins from './components/AllPlugins';

const AllPluginType = () => {
    const { state } = useLocation()
    // console.log(state, "whatever")
   
    const pluginType = state?.name === "WhatsApp OTP" ? "WhatsApp Otp" : state?.name
    console.log(pluginType, 'lala')

  return (
    <DashboardLayout title={"App Store"} user={true}>
        <main className=" font-figtree mt-3 mb-7 p-10">
            <header className=" flex gap-2 items-center mb-4">
                <Link
                    to="/plugins"
                    className="text-[#D2D0D0] text-sm font-normal hover:underline"
                >
                    All Apps
                </Link>
                <BiChevronRight className=" text-xl text-[#D2D0D0]" />
                <p className="text-[#121212] text-sm font-normal ">{pluginType}</p>
            </header>
            <div className='mt-[46px]'>
                <AllPlugins data={state}/>
            </div>
        </main>
    </DashboardLayout>
  )
}

export default AllPluginType
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from "../../services/api"



const initialState = {
    loading: false,
    data: [],
    error: ""
};
export const createConversation = createAsyncThunk(
    "agentChat/createConversation", 
    async({data, appId}, { rejectWithValue }) => {
        try {
            const res = await api.post(`/dashboard/applications/${appId}/chat/create`, data);
            return res?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)
export const businessAskAgentChat = createAsyncThunk(
    "agentChat/businessAskAgentChat", 
    async({data, appId}, { rejectWithValue }) => {
        try {
            const res = await api.post(`/dashboard/applications/${appId}/chat/create`, data);
            return res?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)

const businessAskAgentChatSlice = createSlice({
    name: "agentChat",
    initialState,
    extraReducers: builder => {
        builder.addCase(businessAskAgentChat.pending, state => {
            state.loading = true
        });
        builder.addCase(businessAskAgentChat.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(businessAskAgentChat.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default businessAskAgentChatSlice.reducer
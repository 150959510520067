import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { api } from "../../services/api"
// import { appUrls } from "../../services/urls";

const initialState = {
    loading: false,
    data: [],
    error: ""
};

export const updateNotification = createAsyncThunk(
    "notification/updateNotification", 
    async({values, id}, { rejectWithValue }) => {
        try {
            const res = await api.put(`/dashboard/notifications/${id}`, values);
            return res?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)

const updateNotificationSlice = createSlice({
    name: "notification",
    initialState,
    extraReducers: builder => {
        builder.addCase(updateNotification.pending, state => {
            state.loading = true
        });
        builder.addCase(updateNotification.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(updateNotification.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default updateNotificationSlice.reducer
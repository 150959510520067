import React, { useState, useEffect } from "react";
import { CgSpinner } from "react-icons/cg";
import { AiOutlineClose } from "react-icons/ai";
import * as Yup from "yup";
import {
  getApplicationById,
  setAgent,
} from "../../../features/application/getApplicationByIdSlice";
import {
  GeneralAIIcon,
  SalesAIIcon,
  CustomerAIIcon,
} from "@/../Assets/svg/index.js";
import { useDispatch, useSelector } from "react-redux";
import { createApplication } from "../../../features/application/createApplicationSlice";
import {
  SuccessMessage,
  ErrorMessage,
} from "../../../Toast-Messages/Toastmessages";
import { useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi2";
import { showTestemployeeTour } from "@/../features/testAgent/chatSlice";
import { setIsDone } from "@/../features/modals/agentModalsSlice";

const AgentServiceSelect = ({
  agents,
  handleClose,
  setDone,
  showCloseButton,
  previousModal,
}) => {
  const [agentSetUpSuccess, setAgentSetUpSuccess] = useState(false);
  const [showEmployeeError, setShowEmployeeError] = useState(false);
  const [checkedItem, setCheckedItem] = useState();
  const [error, setError] = useState();
  //   const navigate = useNavigate()
  const navigate = useNavigate();

  const aiData = [
  
    {
      id: 1,
      title: "Support X",
      icon: <CustomerAIIcon />,
      description:
        "Meet the new member of your customer care team. It can tirelessly handle your customer inquiries, requests, and complaints all day long, at any scale.",
      details: [
        "It’s your junior-level customer service Assistant, trained to improve your customer service experience.",
        "Will answer all your customer enquiries like a human, 24/7.",
        "Assist your customers in fulfilling any request about your product or service they might have.",
        "Can intelligently resolve your customer resolve your customer requests and complaints by simply learning from any CS instructions you can provide on how request and complaint issues should be resolved.",
        "Interacts with your customers humanely and tries to boost their perception of your service and products which leads to better engagement.",
      ],
      type: "customer",
    },
    {
      id: 2,
      title: "Sales X",
      icon: <SalesAIIcon />,
      description:
        "Designed and trained to boost your sales. It helps you close sales with customers, engage customers, represent your brand, and offer useful sales insights.",
      details: [
        "It’s your junior-level salesman, trained to help your business sell more.",
        "Great at acquiring new customers and increasing your sales with existing customers.",
        "Offers useful sales insights to help you understand how and why customers are active, inactive or not converting in your business.",
        "Helps you understand how customers engage with your product, and collects useful customer feedback that might impact sales.",
        "Can intelligently replicate and automate all your online sales processes by learning from any sales instruction you provide in plain english.",
      ],
      type: "sale",
    },
    // {
    //   id: 3,
    //   title: "Generalist X",
    //   icon: <GeneralAIIcon />,
    //   description:
    //     "Meet the new generalist in your team. It does all in one go — sell your service, attend to customers, and give you a broader insight across sales and customer care combined.",
    //   details: [
    //     "General AI is the generalist in your team. It can perform tasks of all new and existing (customer care and sales) roles supported on Wano.",
    //     "Trained on sales, to help your business sell more ",
    //     "Trained on customer service, to help improve the customer service experience of your business.",
    //     "Can intelligently provide general insights and customer feedback across the sales and customer service performance in your business.",
    //   ],
    //   type: "general",
    // },
  ];

  const handleCheckedItem = (type) => {
    if (type === "sale") {
      return; // Prevent checking the "Sales X" item
    }
    setShowEmployeeError(false);
    setCheckedItem((prevCheckedItem) => {
      if (prevCheckedItem === type) {
        return undefined;
      } else {
        const element = document.getElementById(type);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
        return type;
      }
    });
  };


  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.createApplication);

  const submitForm = () => {
    const newAgentDetails = JSON.parse(localStorage.getItem("Employee_data"));
    if (!checkedItem) {
      return setError("Choose an Assistant");
    }
    const data = {
      //   name: values?.EmployeeName,
      //   description: values?.description,
      //   sale_agent_name: values?.EmployeeName,
      ...newAgentDetails,
      type: `${
        checkedItem === "sale"
          ? "sale"
          : checkedItem === "customer"
          ? "customer"
          : "general"
      }`,
    };
    dispatch(createApplication(data)).then((res) => {
      if (res?.payload) {
        dispatch(setAgent(res.payload));
        dispatch(getApplicationById());
        setAgentSetUpSuccess(true);
        sessionStorage.removeItem("serviceData");
        sessionStorage.removeItem("choosenServiceData");
        setDone(true);
        dispatch(setIsDone(true));//made it globally available so when a new employee is created the dashboard guide will be updated
        //dispatch(showTestemployeeTour(false)); //this returns back to the default state
        setTimeout(() => {
          navigate("/dashboard?switchEmployee=true");
          handleClose();
        }, 2000);
        localStorage.removeItem("Employee_data");
        // window.location.reload();
      } else if (res.error.message === "Rejected") {
        setError("Application name is not unique!");
      }
    });
    // actions.resetForm();
  };

  const removeSuccessNotification = () => {
    setTimeout(() => {
      setAgentSetUpSuccess(false);
      setError();
    }, 1000);
  };

  useEffect(() => {
    removeSuccessNotification();
  }, [agentSetUpSuccess, error]);

  const message = "Assistant Created Successfully";

  // console.log(agents.length, "agent length")

  return (
    <div className="relative max-w-[519px] h-[588px] mt-[5px]">
      {" "}
      {/* h-[720px] h-[647px]*/}
      <div className="bg-WHITE-_100 rounded-lg w-[519px] h-[588px]">
        {" "}
        {/* h-[720px]  max-h-[90vh] mt-[20px] overflow-y-scroll*/}
        <div className="rounded-lg w-full flex flex-col items-center justify-center">
          {" "}
          {/* h-[214px] bg-[#FAFBFC]*/}
          {showCloseButton ? (
            <div className="w-full flex justify-between items-center">
              <span
                className="p-2 ml-3 mt-3 flex items-center justify-center"
                onClick={() => {
                  previousModal();
                  handleClose();
                }}
              >
                <HiChevronLeft className="cursor-pointer" />
              </span>
              <div
                className="max-w-[36px] max-h-[36px] border rounded-lg mr-3 mt-3 flex cursor-pointer justify-center items-center p-2"
                onClick={handleClose}
              >
                <AiOutlineClose />
              </div>
            </div>
          ) : null}
        </div>
        <div className="w-full flex justify-center flex-col gap-4 py-[5px] text-center items-center">
          <div className="max-w-[395px] flex flex-col gap-1 lg:gap-3">
            {" "}
            {/* mt-4 */}
            <div className="font-normal text-sm text-center text-[#7F7F81]">
              Step 2
            </div>
            <div className="text-BLACK-_600 font-medium text-lg lg:text-2xl">
              Assistant Role
            </div>
            <div className="font-normal text-xs lg:text-sm text-center text-[#7F7F81]">
              Choose a Role for your Assistant. Click to expand and collapse
            </div>
          </div>
          <div className="w-full flex flex-col mt-[0px]">
            <div className="flex flex-col items-center">
              <div className="flex flex-col max-w-[470px] mx-auto">
                <div className="flex flex-col gap-5 h-[285.64px] overflow-y-scroll hide-scrollbar">
                  {" "}
                  {/*mb-[100px] h-[379.68px] max-h-[620px] id="scroll-container" */}
                  {/* <p className="text-left text-xs text-[#101828]">
                        Select Employee Type
                      </p> */}
                  {showEmployeeError && (
                    <div className="text-RED-_100 text-xs">
                      Assistant Type is required
                    </div>
                  )}
                  <div className="flex flex-col gap-5 mb-2">
                    {" "}
                    {/* border border-[#E3E3E3] rounded-2xl */}
                    {aiData.map((item) => {
                      return (
                        <div
                          id={item?.type}
                          className={`${
                            checkedItem === item?.type ? "bg-[#CDDDF81A]" : ""
                          } flex flex-col gap-[15px] p-3 border border-[#EDEDED] rounded-lg cursor-pointer`}
                          key={item?.id}
                          value={item?.type}
                          onClick={() => handleCheckedItem(item?.type)}
                          onChange={() => handleCheckedItem(item?.type)}
                        >
                          <div>{item.icon}</div>
                          <div className="flex flex-col">
                            <div className="flex flex-col gap-2">
                              <div className="flex justify-between items-center">
                                <p className="text-[#121212] text-sm text-left font-medium">
                                  {item?.title}
                                </p>
                                {item.type == "sale" && (
                                  <div
                                    className="text-BLUE-_200 bg-[#F0F6FF] rounded-[3px] py-1 px-2 text-xs"
                                    style={{
                                      "box-shadow":
                                        "0px 0px 2.4px 0px #86B7FF42",
                                    }}
                                  >
                                    Coming soon
                                  </div>
                                )}
                              </div>

                              <p className="text-xs font-normal text-left text-[#7F7F81]">
                                {item?.description}
                              </p>
                            </div>
                            {checkedItem === item.type && (
                              <div className="flex flex-col gap-2 mt-2">
                                {item.details.map((text, i) => (
                                  <div
                                    key={i}
                                    className="w-[418px] ml-2 text-xs font-normal text-left text-[#7F7F81] flex items-base gap-2"
                                  >
                                    <span className="self-start text-xl leading-[4px]">
                                      .
                                    </span>{" "}
                                    <p>{text}</p>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {/* removed div buttons from here */}
                  </div>
                </div>
                <div
                  // style={{
                  //   boxShadow: "0px -4px 8px 1px #D7D7D740",
                  // }}
                  // h-[100px] py-6
                  className="flex flex-col items-center w-[455px] mx-auto mt-[50.36px]"
                >
                  <button
                    className={`${
                      checkedItem
                        ? "bg-BLACK-_500"
                        : "bg-[#EDEDED] cursor-not-allowed"
                    } w-full h-[40px] flex mx-auto justify-center items-center  p-2 rounded-lg`}
                    type="submit"
                    onClick={submitForm}
                    disabled={!checkedItem}
                  >
                    <p className="text-WHITE-_100 text-sm font-bold">
                      {loading ? (
                        <CgSpinner className=" animate-spin text-lg " />
                      ) : (
                        "Continue"
                      )}
                    </p>
                  </button>
                  {!agents.length && (
                    <div
                      className="mt-2 text-BLACK-_200 font-bold text-sm cursor-pointer"
                      onClick={handleClose}
                    >
                      Skip for Now
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {agentSetUpSuccess && (
          <SuccessMessage
            Formodal={true}
            success={message}
            className="mt-24 mr-20"
          />
        )}
        {error && <ErrorMessage error={error} />}
      </div>
    </div>
  );
};

export default AgentServiceSelect;

{
  /*//   const CheckedIcon = () => {
//     return (
//       <div className="w-5 h-5 bg-[#1774FD] rounded-[999px] flex justify-center items-center">
//         <BsCheck className="text-white w-5 h-5" />
//       </div>
//     );
//   }; <Formik
              initialValues={{
                // EmployeeName: "",
                // description: "",
                sales: "",
                customer: "",
                general: "",
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values, actions) => {
                window.scrollTo(0, 0);
                if (!checkedItem) {
                  return setShowEmployeeError(true);
                }
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                dirty,
                isValid,
                setFieldValue,
                errors,
                touched,
                // setFieldTouched,
                values,
              }) => ( */
}
{
  /* )}
            </Formik> */
}

{
  /* <div className="flex justify-between px-5 py-4 ">
                                            <div className="flex flex-col gap-2">
                                                <p className="text-[#121212] text-sm text-left font-medium">Sales A.I</p>
                                                <p className="text-xs font-medium text-left text-BLACK-_200">
                                                    Meet a new member of your sales team. 
                                                    Sales AI will sell your financial solution to customers talk to customers, 
                                                    represent your brand and offer useful insights to make better sales decisions.
                                                </p>
                                            </div>
                                            <Radio
                                                sx={{ color: '#EBEBEB', height: 20, width: 20, border: "1px", background: "#fff" }}
                                                checked={sales}
                                                disabled={customer || general}
                                                value={values?.sales}
                                                checkedIcon={<CheckedIcon />}
                                                onClick={() => handleSalesChange()}
                                                // onChange={handleChange}
                                                name="sales"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                    </div>
                                    <hr /> */
}
{
  /* <div className="flex justify-between px-5 py-4 ">
                                        <div className="flex flex-col gap-2">
                                            <p className="text-[#121212] text-sm text-left font-medium">Customer Service A.I</p>
                                            <p className="text-xs font-medium text-left text-BLACK-_200">
                                                Meet a new member of your customer care team. Customer Service AI never sleeps or tire, 
                                                he/she will always attend to your customer enquiries, requests and complaints.
                                            </p>
                                        </div>
                                        <Radio
                                            sx={{ color: '#EBEBEB', height: 20, width: 20, border: "1px", background: "#fff" }}
                                            checked={customer}
                                            disabled={sales || general}
                                            value={values?.customer}
                                            checkedIcon={<CheckedIcon />}
                                            onClick={() => handleCustomerServiceChange()}
                                            name="customer"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                    </div>
                                    <hr />
                                    <div className="flex justify-between px-5 py-4">
                                        <div className="flex flex-col gap-2">
                                            <p className="text-[#121212] text-sm text-left font-medium">General A.I</p>
                                            <p className="text-xs font-medium text-left text-BLACK-_200">
                                                Meet the new generalist in your team. General AI can help you do it all 
                                                in one go — sell your service, attend to customers, and give you a 
                                                broader insight across sales and customer care combined.
                                            </p>
                                        </div>
                                        <Radio
                                            sx={{ color: '#EBEBEB', height: 20, width: 20, border: "1px", background: "#fff" }}
                                            checked={general}
                                            checkedIcon={<CheckedIcon />}
                                            value={values?.general}
                                            disabled={sales || customer}
                                            onClick={() => handleGeneralChange()}
                                            name="general"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                    </div> */
}

{
  /* <div className=" flex flex-col gap-5">
                      <div className="w-[455px] flex flex-col">
                        <div className="flex flex-col lg:w-[455px] gap-2">
                          <label className="label text-left text-[#101828] text-xs font-normal">
                            Employee Name
                          </label>
                          <input
                            name="EmployeeName"
                            type="text"
                            className="outline-none  w-[455px]"
                            value={values?.EmployeeName}
                            onChange={handleChange}
                          />
                        </div>
                        <p className="text-left">
                          {errors.EmployeeName && touched.EmployeeName ? (
                            <div className="text-RED-_100 text-xs">
                              {errors.EmployeeName}
                            </div>
                          ) : null}
                        </p>
                      </div>
                      <div className="w-[455px] flex flex-col ">
                        <div className="flex flex-col w-[455px] gap-2">
                          <label className="label text-left text-[#101828] text-xs font-normal">
                            Employee Description
                          </label>
                          <input
                            name="description"
                            type="text"
                            className="outline-none w-[455px]"
                            value={values?.description}
                            onChange={handleChange}
                          />
                        </div>
                        <p className="text-left">
                          {errors.description && touched.description ? (
                            <div className="text-RED-_100 text-xs">
                              {errors.description}
                            </div>
                          ) : null}
                        </p>
                      </div>
                    </div> */
}

{
  /* <Radio
                                    sx={{
                                    color: "#EBEBEB",
                                    height: 20,
                                    width: 20,
                                    border: "1px",
                                    background: "#fff",
                                    }}
                                    checked={checkedItem === item?.type}
                                    // disabled={customer || general}
                                    value={item?.type}
                                    checkedIcon={<CheckedIcon />}
                                    onChange={() => handleCheckedItem(item?.type)}
                                    // onChange={handleChange}
                                    name="item"
                                    inputProps={{ "aria-label": "A" }}
                                /> */
}

{
  /*
                                if (prevCheckedItem === type) {
            // If the item is already checked, do nothing
            return undefined;
          } else {
            // Scroll the newly checked item into view
            const element = document.getElementById(type);
            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "nearest" });
      
              // Adjust the scroll position to center the item vertically
              const container = document.getElementById("scroll-container");
              if (container) {
                const containerRect = container.getBoundingClientRect();
                const elementRect = element.getBoundingClientRect();
                const offset = elementRect.top - containerRect.top - (containerRect.height - elementRect.height) / 2;
                container.scrollTop += offset;
              }
            }
            return type;
        }
                             */
}

import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { FiPhone } from 'react-icons/fi';

import Phone from "../../../Assets/svg/phone.svg";
import Logo from "../../../Assets/wano logo 1 1.png"

const SetupWhatsApp = ({ handleClose, setPurpose, setIntegrateWithCustomBusiness, setAddWanoNumber, setOpenSetupInstruction }) => {
   
    const openWanoNumbers = () => {
        setIntegrateWithCustomBusiness(true)
        setPurpose('Wano')
        setAddWanoNumber(true)
        handleClose()
    }

    const openCustomsNumbers = () => {
        setOpenSetupInstruction(true)
        // setIntegrateWithCustomBusiness(true)
        setPurpose('Custom')
        setAddWanoNumber(false)
        handleClose()
    }

  return (
    <div className='w-[632px] h-[328px] bg-[#fff] mt-[100px] flex flex-col rounded-lg'>
        <div className='w-full flex items-center justify-between px-[28px] py-[14px]'>
            <p className='text-lg font-medium text-[#101828]'>Setup App</p>
            <div className="w-[36px] h-[36px] border rounded-lg mr-3  flex cursor-pointer justify-center items-center p-2" onClick={handleClose}><AiOutlineClose /></div>
        </div>
        <hr />
        <div className='flex flex-col mt-[35px] gap-6 mx-[31px]'>
            <div 
                onClick={() => openWanoNumbers()}
                className='flex items-center gap-2.5 p-5 w-[570px] h-[77px] cursor-pointer rounded-xl bg-[#F9F9F966] border border-[#E5E5E5]'
            >
                <img src={Logo} alt='wano-logo' className='w-[20px] h-[20px]'/>
                <div className='flex flex-col gap-0'>
                    <p className='font-medium text-[#121212] text-base'>Setup with Wano Number</p>
                    <p className='text-[#7F7F81] text-left text-xs'>Peer Plugin to default Wano number. Go live instantly</p>
                </div>
            </div>
            <div 
                onClick={() => openCustomsNumbers()}
                className='flex items-center gap-2.5 p-[20px] w-[570px] h-[77px] cursor-pointer rounded-xl bg-[#F9F9F966] border border-[#E5E5E5]'
            >
                <FiPhone className='w-[20px] h-[20px] text-[#7F7F81]' />
                <div className='flex flex-col gap-0'>
                    <p className='font-medium text-[#121212] text-base'>Setup with Custom Business Number</p>
                    <p className='text-[#7F7F81] text-left text-xs'>Peer with your custom business number. Please note that it’ll take 24 hours to become active</p>
                </div>
            </div>

        </div>
    </div>
  )
}

export default SetupWhatsApp
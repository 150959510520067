import React from 'react'

export default function WelcomeLogoText({ Logo, title, text, width = "w-[246px]", padddown }) {
  return (
    <div
      className={`mx-auto flex flex-col justify-center gap-[20px] ${width} `}
    >
      {Logo ? (
        <img src={Logo} alt="Wano logo" className="w-[35px] mx-auto " />
      ) : (
        ""
      )}
      <p className=" mx-auto text-2xl text-[#101828] font-medium">{title}</p>
      <span
        className={` mx-auto text-[16px] text-[#828282] text-center font-normal ${padddown}`}
      >
        {text}
      </span>
    </div>
  );
}

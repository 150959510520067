import React, { createContext, useContext, useState } from "react";

// Inside your TourProvider or UserContext
const TourContext = createContext();

export const TourProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const resetTour = () => {
    setCurrentStep(0);
  };

  return (
    <TourContext.Provider value={{ currentStep, setCurrentStep, resetTour }}>
      {children}
    </TourContext.Provider>
  );
};

export const useTour = () => useContext(TourContext);

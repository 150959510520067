import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from "react-icons/ai"
import { useDispatch, useSelector } from 'react-redux'

import { getPluginsChannel } from '../../../features/plugins/getPluginChannelSlice';
import { getApplicationById } from '../../../features/application/getApplicationByIdSlice';
import { Skeleton } from '@mui/material';
import { postPluginById } from '../../../features/plugins/postPluginByIdSlice';
import { ErrorMessage, SuccessMessage } from '../../../Toast-Messages/Toastmessages';
import { CgSpinner } from 'react-icons/cg';
import ModalPop from '../../modalPop';
import Success from '../kyc/whatsapp/Success';

const IntegrateWano = ({ handleClose, purpose }) => {
    const [messaging, setMessaging] = useState(false)
    const [loading, setLoading] = useState({});
    const [pluginsNotificationSuccess, setPluginsNotificationSuccess] = useState(false);
    const [pluginsNotificationError, setPluginsNotificationError] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getApplicationById())
    },[])


    const appDetail = useSelector((state) => state.getApplicationByUserId);
    const getdata = appDetail?.data?.data;
    const agentData = localStorage.getItem("wano_agent");
    const agent = getdata && getdata.length >= 1 ? getdata[0] : {};
    const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
    const applicationId = agentDetails.id;
   
    let fromPeer = false

    useEffect(() => {
        dispatch(getPluginsChannel({ applicationId }))
    }, []) //loading

    //pluginId
    const getAllPluginsByIdData = useSelector(state => state.getAllPluginsById);
    // console.log(getAllPluginsByIdData, "austria")

    const pluginId = getAllPluginsByIdData?.data?.data?.id

    //channelId
    const channels = useSelector((state) => state.getPluginChannel)
    const channelsData = channels?.data?.data;
    console.log(channelsData, "overseas")

    const postPlugin = useSelector(state => state.postPluginById)
    console.log(postPlugin, "postPluginById")

    const integrateNumber = (value) => {
        setLoading((prev) => ({...prev, [value]: true }));
        const url = localStorage.getItem("notificationUrl")
        const data = {
            pluginId: pluginId,
            channelId: value,
            notification_url: url
        }
        dispatch(postPluginById({ applicationId, data}))
        .then((res) => {
            console.log(res, "fire")
            if(res?.payload) {
                setLoading((prev) => ({...prev, [value]: false }))
                // setPluginsNotificationSuccess(true)
                setOpenSuccessModal(true)
                setTimeout(() => {
                    handleClose()
                }, 2000);
            }  
        }) 
    }

    const removeModalSuccess = () => {
        setTimeout(() => {
            setOpenSuccessModal(false)
        }, 2500);
    }
    // const removePluginsNotificationSuccess = () => {
    //     setTimeout(() => {
    //         setPluginsNotificationSuccess(false)
    //     }, 1000);
    // }

    const removePluginsNotificationError = () => {
        setTimeout(() => {
            setPluginsNotificationError(false)
        }, 1000);
    }

    const error = "false"

    useEffect(() => {
        removeModalSuccess()
    }, [openSuccessModal])
    // useEffect(() => {
    //     removePluginsNotificationSuccess()
    // }, [pluginsNotificationSuccess])

    useEffect(() => {
        removePluginsNotificationError()
    }, [pluginsNotificationError])
    
    const message = "Application plugin created successfully"
    const uniquePhoneNumbers = new Set();

  return (
    <div className="bg-WHITE-_100 w-[484px] h-[50%] mt-[60px] rounded-lg" style={{height:'fit-content'}}> {/*  h-[247px] */}
        <div className="flex justify-between h-[65px] items-center mx-4 my-1 ">
            <div className=" flex ml-6">
                <p className="font-medium text-lg text-BLACK-_600">Integrate With {purpose === 'Wano' ? 'Wano' : 'Custom Business'} Number</p> {/* conditionally renders the text on the integrate modal */}
            </div>
            <button className="w-[30px] h-[30px] border border-[#F7F7F7] rounded-lg flex justify-center items-center p-2" onClick={handleClose}>
                <AiOutlineClose fill='#828282'/>
            </button>
        </div>
        <hr />
        <div className='flex flex-col ml-10 mr-3 mt-[35px]  mb-[35px] gap-[50px]'>
            <p className='text-sm text-[#828282]'>Peer Plugin to default Wano number. Go live instantly</p>
            <div className='flex flex-col gap-5'>
                {
                channels.loading ? 
                    <div className='flex flex-col gap-1'>
                        <Skeleton variant="rectangular" width={400} height={50} style={{ backgroundColor: 'rgba(249,249,249,0.86)', borderRadius: '12px' }} />
                        <Skeleton variant="rectangular" width={400} height={50} style={{ backgroundColor: 'rgba(249,249,249,0.86)', borderRadius: '12px' }} />
                    </div>
                :
                    channelsData?.map((item, index) => {
                        const isUniquePhoneNumber = !uniquePhoneNumbers.has(item?.phone_number);
                        if (isUniquePhoneNumber) {
                            uniquePhoneNumbers.add(item?.phone_number);
                            return (
                                <div className='flex items-center justify-between' key={index}>
                                    {(purpose === 'Wano' && item?.is_general) && 
                                        <p className='text-sm font-medium text-[#101828]'>{item?.phone_number}</p>
                                    }
                                    {(purpose === 'Custom' && !item?.is_general) && 
                                        <p className='text-sm font-medium text-[#101828]'>{item?.phone_number}</p>
                                    }
                                    <button 
                                        className='w-[81px] text-[#0359D8] px-[8px] py-[8px] bg-[#FAFAFA] flex justify-center items-center border-solid border-[1px] border-[#EEE] rounded-lg font-medium text-sm'
                                        onClick={() =>  integrateNumber(item?.id)}
                                    >
                                        {loading[item?.id] ? <CgSpinner className=" text-[#0359D8] animate-spin text-lg " /> : 'Integrate'}
                                    </button>
                                </div>

                            )
                        } else {
                            return null;
                        }
                    })
                }
            </div>
        </div>
        {pluginsNotificationSuccess && <SuccessMessage Formodal={true} success={message} className="mt-28 mr-12"/>}
        {pluginsNotificationError && <ErrorMessage Formodal={true} error={error} className="mt-28 mr-12"/>}
        
        <ModalPop isOpen={openSuccessModal}>
            <Success handleClose={() => setOpenSuccessModal(false)}  fromPeer={fromPeer}/>
        </ModalPop>
    </div>
  )
}

export default IntegrateWano
import React, { Fragment, useState, useRef } from "react";
import Button from "./Button";
import FormFooter from "./FormFooter";
import Terms from "./Terms";
import NavBar from "./NavBar";
import { Link, useNavigate } from "react-router-dom";
import WelcomeLogoText from "./WelcomeLogotext";
import { useAuth } from "../context/UserContext";
import Arrow from "../Assets/img/arrow-narrow-left.png"



export default function Verification() {
    const [loading, setLoading] = useState(false);
  const { verify } = useAuth()
  const [inputValues, setInputValues] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
  });
  const navigate = useNavigate();
  const inputRefs = {
    input1: useRef(),
    input2: useRef(),
    input3: useRef(),
    input4: useRef(),
    input5: useRef(),
    input6: useRef(),
  };

  const isFilled = Object.values(inputValues).every(value => value);

  const handleInputSubmit = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
    // Move focus to the next input field
    if (value && name !== "input6") {
      inputRefs[`input${parseInt(name.charAt(name.length - 1)) + 1}`].current.focus();
    }
    
  };


  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    // setIsLoading(true);
    const handleVerification = async () => {
      const verificationCode = Object.values(inputValues).join("")
      const data = { 
        code: verificationCode,
      };
      try{
       const res = await verify(JSON.stringify(data))
        navigate("/company");
        setLoading(false)
      }catch(error){
        setLoading(false);
      }
        // setIsLoading(false); 
    };
    handleVerification();
  }; 

  return (
    <div className="h-screen grid content-between">
      <NavBar />
      <div className="w-[526px] mx-auto shadow py-[30px] px-[50px] rounded-xl grid gap-10 items-center justify-center">
        <img src={Arrow} className="w-[18px] h-[18px]"/>
        <WelcomeLogoText
          width={"w-[424px]"}
          title={"Enter 6-digit code from your authenticator app"}
          text={
            "This helps us keep your account secure."
          }
          titleStyle={"text-center"}
        />
        <div className="grid gap-3">
          <div className="w-[420px] mx-auto">
            <div className="w-[395px] mx-auto flex justify-between">
              {Object.keys(inputValues).map((inputName, index) => (
                <input
                  ref={inputRefs[inputName]}
                  key={inputName}
                  className="verifyInput input h-[59px] w-[59px] text-center"
                  name={inputName}
                  // type="number"
                  inputMode="numeric"
                  pattern="[0-9]*" 
                  value={inputValues[inputName]}
                  onChange={handleInputSubmit}
                />
              ))}
            </div>
          </div>
        </div>
        <Button
          onClick={handleSubmit}
          disabled={!isFilled}
          className={isFilled ? 'w-[420px] bg-black rounded-xl px-[12px] line-[24px] py-[13px] text-white text-[14px]' : ''}
          text={"Verify Code"}
          loading={loading}
        />
      </div>
      <Terms />
    </div>
  );
}


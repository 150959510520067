import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from "../../services/api"



const initialState = {
    loading: false,
    data: [],
    error: ""
};

export const postApplication = createAsyncThunk(
    "post/postApplication", 
    async({ data, appId }, { rejectWithValue }) => {
        try {
            const res = await api.post(`/dashboard/applications/${appId}/modules`, data); 
            return res?.data?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)

const postApplicationSlice = createSlice({
    name: "application",
    initialState,
    extraReducers: builder => {
        builder.addCase(postApplication.pending, state => {
            state.loading = true
        });
        builder.addCase(postApplication.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(postApplication.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default postApplicationSlice.reducer
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "component/Button";
import phoneIcon from "Assets/svg/docPhone.svg";
import laptopIcon from "Assets/svg/activeWeb.svg";
import copyIcon from "Assets/svg/copyScript.svg";

import { SuccessMessage, ErrorMessage } from "Toast-Messages/Toastmessages";
const Documentation = ({ handleClose, script }) => {
  const [activeApp, setActiveApp] = useState("web");

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  return (
    <div
      className={`h-fit  pb-7 px-10 bg-WHITE-_100  mt-[60px] flex flex-col justify-center items-center rounded-lg`}
    >
      <div className="flex  py-7 w-full items-center justify-center">
        <p className="font-semibold text-[22px] text-bamboo ">Embed Widget</p>
      </div>

      <div className="text-center   text-BLACK-_200 font-normal text-sm mb-11">
        Add the employee widget by embedding the script on your <br /> Website,
        Store or Custom App.
      </div>
      <div className="flex justify-center mb-4 border border-chatBrown bg-[#F7F4F4] rounded-xl">
        <div
          className={`
        
        ${activeApp === "web" ? " shadow-lg text-[#1774FD] " : " "}
          py-2 cursor-pointer rounded-xl text-xs px-6 flex items-center gap-1`}
          onClick={() => {
            setActiveApp("web");
          }}
        >
          Website/Web App
          <img src={laptopIcon} alt="Uploaded" className="w-3 h-2.5" />
        </div>
        <div
          className={`
                ${activeApp === "phone" ? " shadow-lg " : " "}
                  py-2 cursor-not-allowed rounded-xl text-xs px-6 flex items-center gap-1`}
          onClick={() => {
            // setActiveApp("phone");
          }}
        >
          Mobile App
          <img src={phoneIcon} alt="phoneIcon" className="" />
          <div className="text-[#1774FD] bg-white px-1 py-1.5 rounded text-[10px]">
            Coming Soon
          </div>
        </div>
      </div>
      <div
        className="bg-[#F7F4F4] p-5 max-w-[441px] text-xs rounded-lg"
        style={{ boxShadow: "0px 2px 8px 0px #1774FD1A inset" }}
      >
        {script}
      </div>

      <div className={` mt-10  flex w-full  justify-between`}>
        <Button
          text="Cancel"
          type="button"
          onClick={handleClose}
          className="bg-none cursor-pointer text-[#868686] border border-[#D0D5DD] rounded-lg font-semibold px-5 text-sm h-[40px] "
        />
        <Button
          onClick={() => {
            navigator.clipboard
              .writeText(script)
              .then(() => {
                setSuccess(true);
              })
              .catch((err) => {
                setError(false);
              });
          }}
          text="Copy Script"
          className="bg-BLACK-_100 px-5 text-sm h-[40px] cursor-pointer rounded-lg font-semibold text-WHITE-_100"
          type="button"
        />
          {/* <img src={copyIcon} alt="Uploaded" className="w-3 h-2.5" /> */}

      </div>
      {success && <SuccessMessage success={"script copied to clipboard"} />}
      {error && <ErrorMessage error={"failed to copy script"} />}
    </div>
  );
};

export default Documentation;

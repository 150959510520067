import { useState } from "react";
import { LuAsterisk } from "react-icons/lu";

import TestAgent from "./TestAgent/TestAgent";
import About from "./BusinessTabContent/About";
import Template from "../component/modals/agentMemoryTemplate/FaqTemplate";
import ModalPop from "../component/modalPop";
import Other from "./BusinessTabContent/Other";
import Faq from "./BusinessTabContent/Faq";
import Legal from "./BusinessTabContent/Legal";
import Support from "./BusinessTabContent/Support";
import Product from "./BusinessTabContent/Product";

export default function Business({ activeBtn, setActiveBtn }) {
  const [activeButton, setActiveButton] = useState("About");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <section className="mt-10 font-figtree mb-24 w-full">
      <h2 className=" text-2xl font-medium">Memory</h2>
      <p className=" font-normal text-xs text-[#7F7F81] pb-8 mt-2">
        Upload necessary company documents to your Assistant’s memory so it
        can learn all the basics about your business.
        <br />
        Don’t worry, your digital assistant has 100% retention and recollection so you
        only upload once, and you can add or delete documents anytime.
      </p>
      <div className=" w-[80%]">
        <section className="flex justify-center w-full ">
          <div className="bg-[#FDFDFD]  flex w-[580px] border  border-[#F2F4F7] rounded-xl py-[2px] h-[40px] gap-[10px] select-none"> {/* w-560px */}
            <button
              className={`font-medium flex justify-center items-center text-xs text-center w-[82px] rounded-lg transform transition-transform outline-none ${
                activeButton === "About"
                  ? " text-[#1774FD] bg-white shadow-custom"
                  : "text-[#7F7F81]"
              }`}
              onClick={() => handleButtonClick("About")}
            >
              <p>About</p>
              <LuAsterisk className="text-RED-_100 w-[10px] relative bottom-[12%]" />
            </button>
            <button
              className={`font-medium w-[82px] flex justify-center items-center  rounded-lg text-center text-xs transform transition-transform outline-none ${
                activeButton === "FAQs"
                  ? " text-[#1774FD] bg-white shadow-custom"
                  : "text-[#7F7F81]"
              }`}
              onClick={() => handleButtonClick("FAQs")}
            >
              <p>FAQs</p>
              <LuAsterisk className="text-RED-_100 w-[10px] relative bottom-[12%]" />
            </button>
            <button
              className={`font-medium w-fit flex justify-center items-center  px-2 rounded-lg text-center text-xs transform transition-transform outline-none ${
                activeButton === "Product/Services"
                  ? " text-[#1774FD] bg-white shadow-custom "
                  : "text-[#7F7F81]"
              }`}
              onClick={() => handleButtonClick("Product/Services")}
            >
              <p>Product/Services</p>
              <LuAsterisk className="text-RED-_100 w-[10px] relative bottom-[12%]" />
            </button>
            <button
              className={`font-medium w-[82px]  rounded-lg text-center text-xs transform transition-transform outline-none ${
                activeButton === "Legal"
                  ? " text-[#1774FD] bg-white shadow-custom"
                  : "text-[#7F7F81]"
              }`}
              onClick={() => handleButtonClick("Legal")}
            >
              Legal
            </button>
            <button
              className={`font-medium w-[82px]  rounded-lg text-center text-xs transform transition-transform outline-none ${
                activeButton === "Support"
                  ? " text-[#1774FD] bg-white shadow-custom"
                  : "text-[#7F7F81]"
              }`}
              onClick={() => handleButtonClick("Support")}
            >
              Support
            </button>
            <button
              className={`font-medium w-[82px]  rounded-lg text-center text-xs transform transition-transform outline-none ${
                activeButton === "Other"
                  ? " text-[#1774FD] bg-white shadow-custom"
                  : "text-[#7F7F81]"
              }`}
              onClick={() => handleButtonClick("Other")}
            >
              Other
            </button>
          </div>
        </section>
        {activeButton === "About" && <About />}
        {activeButton === "Other" && <Other />}
        {activeButton === "FAQs" && <Faq />}
        {activeButton === "Legal" && <Legal />}
        {activeButton === "Support" && <Support />}
        {activeButton === "Product/Services" && <Product />}
      </div>
    </section>
  );
}

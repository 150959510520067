import React, { useState, useEffect } from "react";
import Button from "./Button";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { ErrorMessage } from "../Toast-Messages/Toastmessages";
import { useAuth } from "../context/UserContext";
import { getCountries } from "../features/countries/getCountriesSlice";
import { useSelector, useDispatch } from "react-redux";
// import ReactCountryFlag from "react-country-flag";
import spinner from "../Assets/gif/loader.gif";

const URL = process.env.REACT_APP_BASEURL;

const style =
  "rounded-xl px-[12px] w-[420px] line-[24px] py-[13px] text-white text-14px";

export default function SignUpForm() {
  const dispatch = useDispatch();
  const storeCountries = useSelector((state) => state.getAllCountries);
  const countriesLoading = storeCountries.loading;
  const [country, setCountry] = useState("Nigeria");
  const [countrycode, setCountrycode] = useState("+234");
  const [countryFlagIcon, setCountryFlagIcon] = useState("🇳🇬");

  const { registerUser } = useAuth();
  const { register, handleSubmit, formState } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const { errors, isValid } = formState;
  const [isFormValid, setIsFormValid] = useState(isValid);
  const navigate = useNavigate();
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [countryError, setCountryError] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [showOptions, setShowOptions] = useState(false);
  const toggleOptionsVisibility = () => {
    setShowOptions(!showOptions);
  };
  const getCountiresAndStore = async () => {
    dispatch(getCountries());
  };
  useEffect(() => {
    getCountiresAndStore();
  }, []);
  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid]);

  function onSubmit(data) {
    setCountryError(false);
    const { firstName, lastName, email, phonenumber, password } = data;
    const userData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: `${countrycode}${phonenumber}`,
      password: password,
      country: country,
    };
    if (!country) {
      return setCountryError(true);
    }
    setLoading(true);

    const handleSignUp = async () => {
      const logDetails = {
        email: email,
        password: password,
      };
      sessionStorage.setItem("Log_Details", JSON.stringify(logDetails));
      try {
        const token = await registerUser(userData);
        setLoading(false);
      } catch (error) {
        setError(error.response.data.message);
        setLoading(false);
        setTimeout(() => {
          setError(false);
        }, 2000);
      } finally {
        setLoading(false);
      }
    };

    handleSignUp();
  }

  const handleChange = (e) => {
    // const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const p = e.target.value;
    const isContainsSymbol =
      /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    setIsFormValid(false);

    if (p.length < 8) {
      setErrorMessage("Your password must be at least 8 characters");
    } else if (!isContainsUppercase.test(p)) {
      setErrorMessage(
        "Your password must contain at least one uppercase letter."
      );
    } else if (p.search(/[A-Z]/i) < 0) {
      setErrorMessage(
        "Your password must contain at least one uppercase letter."
      );
    } else if (!isContainsSymbol.test(p)) {
      setErrorMessage(
        "Your password must contain at least one special character."
      );
    } else if (p.search(/[0-9]/) < 0) {
      setErrorMessage("Your password must contain at least one digit.");
    } else {
      setErrorMessage("");
      setIsFormValid(true);
    }
  };

  function onError(errors) {
    if (errors?.email && errors?.email?.message) {
      setApiError(errors.email.message);
    } else {
      setApiError(null);
    }
  }

  return (
    <>
      <div className="grid gap-8">
        <form className="grid gap-5" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="flex gap-5 w-[420px]">
            <label className="grid gap-2 label">
              Firstname
              <input
                type="text"
                id="firstName"
                className={`nameInput h-10 outline-none w-full text-base rounded-lg
                ${
                  errors?.firstName && errors?.firstName?.message
                    ? "border !border-RED-_100"
                    : ""
                }
                
                `}
                {...register("firstName", {
                  required: "Enter your first name",
                  pattern: {
                    value: /^[A-Za-z]+$/,
                    message: "Invalid first name",
                  },
                })}
              />
              <p className="text-RED-_100 text-xs">
                {errors?.firstName && errors?.firstName?.message}
              </p>
            </label>
            <label className="grid gap-2 label">
              Lastname
              <input
                type="text"
                className={`nameInput h-10 outline-none w-full text-base rounded-lg
                ${
                  errors?.lastName && errors?.lastName?.message
                    ? "border !border-RED-_100"
                    : ""
                }
                
                `}
                id="lastName"
                {...register("lastName", {
                  required: "Enter your last name",
                  pattern: {
                    value: /^[A-Za-z]+$/,
                    message: "Invalid last name",
                  },
                })}
              />
              <p className="text-RED-_100 text-xs">
                {errors?.lastName && errors?.lastName?.message}
              </p>
            </label>
          </div>
          <label className="grid gap-2 label">
            Email
            <input
              className={`input h-10  rounded-lg text-base focus:outline-none
              ${
                errors?.email && errors?.email?.message
                  ? "border !border-RED-_100"
                  : ""
              }
              
              `}
              type="email"
              id="email"
              {...register("email", {
                required: "Email address is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
            />
            <p className="text-RED-_100 text-xs">
              {errors?.email && errors?.email?.message}
            </p>
          </label>
          <label className="grid gap-3 label">
            Phone Number
            <span>
              <div
                className={`flex border py-[3px] px-[10px] rounded-lg h-10}
              
              ${
                errors?.phonenumber && errors?.phonenumber?.message
                  ? "border !border-RED-_100"
                  : ""
              }
              
              `}
              >
                {countriesLoading ? (
                  <div className="flex items-center justify-center">
                    <img src={spinner} alt="template" className="w-5 h-5" />
                  </div>
                ) : (
                  <select
                    onChange={(e) => {
                      const val = e.target.value;
                      const selectedCountry = storeCountries?.countries.find(
                        (country) => country.name === val
                      );
                      setCountry(selectedCountry.name);
                      setCountryFlagIcon(selectedCountry.flag);
                      setCountrycode(selectedCountry.phone_code);
                    }}
                    value={country}
                    className="border-r border-solid border-[#E5E5E5] outline-none"
                  >
                    {storeCountries?.countries.map((country) => (
                      <option
                        className="flex flex-row"
                        key={country.code}
                        value={country.name}
                      >
                        {country.code} ({country.phone_code})
                      </option>
                    ))}
                  </select>
                )}

                <input
                  className={`w-full border-none outline-none text-base h-8
                
                  ${
                    errors?.phonenumber && errors?.phonenumber?.message
                      ? "border !border-RED-_100"
                      : ""
                  }
                  
                  `}
                  type="number"
                  id="phonenumber"
                  {...register("phonenumber", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^[0-9]{8,}$/, // Change the regex pattern to allow at least 9 digits
                      message: "Invalid phone number",
                    },
                  })}
                />
              </div>
              <p className="text-RED-_100 text-xs">
                {errors?.phonenumber && errors?.phonenumber?.message}
              </p>
            </span>
          </label>
          <label className="grid gap-3 label">
            Country
            <div
              className={`w-full rounded-lg h-10 flex gap-2 items-center text-base border px-[10px] cursor-pointer select text-[#575656]
            
            ${countryError ? "border !border-RED-_100" : ""}
            `}
            >
              {/* <ReactCountryFlag
                svg
                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                cdnSuffix="svg"
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
                countryCode={countryFlag}
              /> */}
              <p className="text-3xl">{countryFlagIcon}</p>
              <select
                name=""
                id=""
                className="w-full outline-none cursor-pointer rounded-lg h-[38px] text-base"
                onChange={(e) => {
                  const val = e.target.value;
                  const selectedCountry = storeCountries?.countries.find(
                    (country) => country.name === val
                  );
                  setCountryFlagIcon(selectedCountry.flag);
                  setCountry(selectedCountry.name);
                  setCountrycode(selectedCountry.phone_code);
                  setCountryError(false)
                }}
                value={country}
              >
                {/* {countries?.map((item, index) => {
                  // console.log(item, "turn")
                  return (
                    <option  
                      value={item?.name} 
                      key={index} 
                      className="flex"
                    >
                      {
                        <div className="flex items-center gap-1.5">
                          <img src={item?.flag} alt={item?.name} className="w-14 h-14 rounded-full" />
                          <p>{item?.name}</p>
                        </div>
                      }
                    </option>
                  )
                })} */}
                <option value="" disabled></option>
                {storeCountries?.countries.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </label>
          {countryError && (
            <p className="text-RED-_100 text-xs">Country is required</p>
          )}

          <label className="grid gap-3 label">
            Password
            <div className=" relative">
              <input
                className={`w-full input outline-none rounded-lg h-10 pr-10 text-base
                
                ${
                  errors?.password && errors?.password?.message
                    ? "border !border-RED-_100"
                    : ""
                }
                
                `}
                type={showPassword ? "text" : "password"}
                id="password"
                {...register("password", {
                  required: "Password is required ",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                  pattern: {
                    value:
                      /^(?=.*[A-Z])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])(?=.*\d).*$/,
                    message:
                      "Your password must contain at least one uppercase letter, one special character, and one digit",
                  },
                })}
                onChange={(e) => handleChange(e)}
              />
              {showPassword ? (
                <BsEyeSlash
                  className=" absolute top-[10px] right-3 cursor-pointer text-[#828282] text-lg"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <BsEye
                  className=" absolute top-[10px] right-3 cursor-pointer text-[#828282] text-lg"
                  onClick={togglePasswordVisibility}
                />
              )}
            </div>
            <div className="text-RED-_100 text-xs">{errorMessage}</div>
            {/* <p className="text-RED-_100 text-xs">
              {errors?.password && errors?.password?.message}
            </p> */}
            <p>
              Minimum 8 characters, at least 1 Uppercase, 1 Lowercase and 1
              Special character (#$&*%)
            </p>
          </label>
          <Button
            type="submit"
            disabled={false}
            className={
              true
                ? `bg-black ${style} cursor-pointer`
                : `bg-[#EBEBEB] ${style} cursor-not-allowed`
            }
            text={"Sign Up"}
            loading={loading}
          />
          {/* <Button
            type="submit"
            disabled={!isFormValid}
            className={
              isFormValid
                ? `bg-black ${style} cursor-pointer`
                : `bg-[#EBEBEB] ${style} cursor-not-allowed`
            }
            text={"Sign Up"}
            loading={loading}
          /> */}
        </form>
        {/* {apiError && <div className="text-red-500">{apiError}</div>} */}
      </div>
      {error && <ErrorMessage error={error} className="mt-5 mr-2" />}
    </>
  );
}

import React from 'react'
import { Link } from 'react-router-dom';
import { Progress } from "antd";
import { TestAgentIcon } from "../Assets/svg";
import { useNavigate } from "react-router";
import { UserContext } from "../context/UserContext";
import { useContext } from "react";
import { PolygonIcon } from '../Assets/svg';

export default function Card({
  text,
  title,
  polygon,
  progress,
  gridStyle,
  number,
  empty,
  helpful,
  NotHelpful,
  FirstProgressbartext,
  SecondProgressbartext,
  blur,
  agentPolygon,
}) {
  const { userType } = useContext(UserContext);
  const navigate = useNavigate();
  return (
    <section
      className={`p-6 rounded-[10px] shadow-custom relative ${gridStyle}`}
    >
      <div className={` ${empty} flex justify-between`}>
        <p className={`text-base font-medium text-[#121212] `}>{title}</p>
        {"Sales" === "Customer Service" && agentPolygon && (
          <Link
            to="/ask-agent"
            className="text-[10px] font-normal flex items-center gap-1 bg-gradient-to-t from-[#CA62EF] via-[#4B92FB] to-[#F6C0D1] bg-clip-text text-transparent cursor-pointer"
          >
            <PolygonIcon /> Ask assistant
          </Link>
        )}
      </div>
      <section className={`${blur ? "blur-[7px]" : ""}`}>
        {polygon && userType !== "Customer Service" && (
          <div className="py-[12px] w-full flex justify-center">
            <Link
              to="/ask-agent"
              className="text-[12px] bg-gradient-to-r from-[#BB14F6] via-[#1370F9] to-[#FE6997] text-transparent bg-clip-text"
            >
              Ask Assistant about metric
            </Link>
          </div>
        )}
        {progress && (
          <section>
            <div className="flex justify-between items-center ">
              <p className=" text-[10px] text-[#7F7F81]">
                {FirstProgressbartext}
              </p>
              <div className=" w-[100px]">
                <Progress percent={helpful} size="small" />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <p className=" text-[10px] text-[#7F7F81]">
                {SecondProgressbartext}
              </p>
              <div className=" w-[100px]">
                <Progress percent={NotHelpful} size="small" />
              </div>
            </div>
          </section>
        )}
        <span>
          <p className=" text-[32px] font-medium text-[#121212]">{number}</p>
          <p className=" text-[#7F7F81] text-xs font-normal mt-2">{text}</p>
        </span>
      </section>
      {blur && (
        <div className="px-4 absolute bottom-2 bg-white left-0 w-full">
          <p className=" text-[#828282] text-center font-normal mt-2 text-xs">
            Want more analytics?
          </p>
          <div
            onClick={() => navigate("/what-will-you-use-wano-for")}
            className=" flex justify-center items-center mt-2 gap-2 rounded-lg border border-solid border-[#F7F7F7] p-2 cursor-pointer"
          >
            <span className=" relative">
              <TestAgentIcon />
              <p className=" absolute text-[4px] text-[#1774FD] font-bold items-end right-0 bottom-0">
                Ai
              </p>
            </span>
            <p className=" text-[#0359D8] font-medium text-xs">
              Expand Assistant Capabilities
            </p>
          </div>
        </div>
      )}
    </section>
  );
}
import React, { useEffect, useState, useRef } from "react";
import { BiCopy } from "react-icons/bi";
import { Send } from "../Assets/svg";
import twitterLogo from "../Assets/img/twitter.svg";
import linkIcon from "../Assets/img/link.svg";
import wikiIcon from "../Assets/img/wiki.svg";
import youTubeIcon from "../Assets/img/youtube.svg";
import prev from "../Assets/img/prev.svg";
import { marked } from "marked";
import Loader from "../Assets/gif/loadingAnimation.gif";
import AiLogo from "../Assets/svg/Group 12.svg";
import { useDispatch, useSelector } from "react-redux";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm"; // For GitHub-flavored markdown like tables, strikethrough, etc.
import rehypeRaw from "rehype-raw"; // To allow rendering HTML in markdown

import {
  businessAskAgentChatH,
  getInbox,
} from "../features/chat/businessAskAgentChatSliceH";
import {
  businessAskAgentChat,
  createConversation,
} from "../features/chat/businessAskAgentChatSlice";
import { getBusinessAskAgentChatH } from "../features/chat/getBusinessAskAgentChatSliceH";
import { copyTextToClipboard } from "../helper";
import { rateBusinessAskAgentChat } from "../features/chat/rateBusinessAskAgentChat";
import { SuccessMessage } from "../Toast-Messages/Toastmessages";
import { CgSpinner } from "react-icons/cg";
import AtInput4 from "@/../Plugins/AllPluginType/components/AtInput4";
import AtInput6 from "@/../Plugins/AllPluginType/components/AtInput6";

const ViewMore = ({ allItems, noTitle }) => {
  const [itemsToShow, setItemsToShow] = useState(3); // Numbe
  function truncateSentence(sentence) {
    if (sentence.length <= 30) {
      return sentence;
    } else {
      return sentence.substring(0, 30) + "...";
    }
  }
  const handleViewMore = () => {
    // const remainingItems = allItems.length - itemsToShow;
    // const itemsToView = remainingItems > 3 ? 3 : remainingItems;
    // setItemsToShow((prev) => prev + itemsToView); // Increment the number of items to show
    setItemsToShow(allItems.length);
  };

  return (
    <div>
      {!noTitle && <p className="text-sm font-semibold mt-4 mb-2">Sources:</p>}
      <div className="flex flex-wrap gap-4">
        {allItems.slice(0, itemsToShow).map((item, index) => (
          <div
            key={index}
            className="rounded-full cursor-pointer flex items-center gap-3 justify-between text-xs text-BLACK-_200 bg-newAsh px-3 py-2"
            onClick={() => {
              window.open("https://www.google.com", "_blank");
            }}
          >
            <img src={item.logo} alt="Uploaded" />
            <p className="break-all">{truncateSentence(item.text)}</p>
            <img src={linkIcon} alt="Uploaded" />
          </div>
        ))}
        {itemsToShow < allItems.length && (
          <div
            className="rounded-full flex items-center text-xs text-BLACK-_200 bg-newAsh px-3 py-2"
            onClick={handleViewMore}
          >
            View {Math.min(allItems.length - itemsToShow)} More
          </div>
        )}
      </div>
      {!noTitle && <p className="text-sm font-semibold mt-4 mb-2">Answer:</p>}
    </div>
  );
};
const ImagePreview = ({ randomImages }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(0);

  const openModal = (index) => {
    setModalImageIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const [smallImagesStartIndex] = useState(3);

  return (
    <div>
      <p className="text-sm font-semibold mt-4 mb-2">Media:</p>

      <div className="flex gap-1 bg-BLACK-_400 p-1 w-fit flex-wrap">
        {randomImages.slice(0, 3).map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`alt ${index + 1}`}
            className="w-28  cursor-pointer"
            onClick={() => openModal(index)}
          />
        ))}
        {randomImages.length > 3 && (
          <div className="flex  overflow-y-hidden flex-col gap-1">
            {randomImages
              .slice(smallImagesStartIndex, smallImagesStartIndex + 2)
              .map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`alt ${index + smallImagesStartIndex + 1}`}
                  className="w-10 h-10 cursor-pointer"
                  onClick={() => openModal(index + smallImagesStartIndex)}
                />
              ))}
            <div
              className="w-10 h-10 bg-gray-800 text-white flex justify-center items-center cursor-pointer"
              onClick={() => openModal(randomImages.length)}
            >
              +{randomImages.length - 6}
            </div>
          </div>
        )}
      </div>

      {modalOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center z-10"
          onClick={closeModal}
        >
          <div
            className=" flex flex-col items-center p-4"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={randomImages[modalImageIndex]}
              alt={`Modal  ${modalImageIndex + 1}`}
              className="w-[600px] rounded-xl h-[510px] mb-4"
            />
            <div className="flex gap-3 mt-5 justify-center items-center">
              <div className="w-10">
                {modalImageIndex > 0 && (
                  <img
                    src={prev}
                    alt="Uploaded"
                    className="cursor-pointer w-10 h-10"
                    onClick={() => {
                      setModalImageIndex(modalImageIndex - 1);
                    }}
                  />
                )}
              </div>
              <div className="flex gap-6 overflow-auto w-[582px]">
                {randomImages.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Small  ${modalImageIndex + index + 1}`}
                    className="w-[140px] rounded-lg h-[125px] cursor-pointer"
                    onClick={() => setModalImageIndex(modalImageIndex + index)}
                  />
                ))}
              </div>
              <div className="w-10">
                {modalImageIndex < randomImages.length - 1 && (
                  <img
                    src={prev}
                    alt="Uploaded"
                    className="cursor-pointer  w-10 h-10 rotate-180"
                    onClick={() => {
                      setModalImageIndex(modalImageIndex + 1);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default function Chatcontent({
  setDone,
  section,
  done,
  trig,
  setTrig,
  tags,
  setTags,
  setSection,
  inboxChat,
  setInboxChat,
}) {
  const [chatTitle, setChatTitle] = useState("");
  // console.log(chatTitle, "tags")
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [chat, setChat] = useState([]);
  const [page, setPage] = useState(1);
  const [data, setData] = useState(true);
  const [typing, setTyping] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState(false);
  const [copiedMessages, setCopiedMessages] = useState(
    Array(chat?.length).fill(false)
  );
  useEffect(() => {
    console.log("inbox chat is", inboxChat);
    if (inboxChat) {
      setChat([inboxChat]);
    }
  }, [inboxChat]);
  const handleCopyClick = (index, message) => {
    copyTextToClipboard(message);
    const updatedCopiedMessages = [...copiedMessages];
    updatedCopiedMessages[index] = true;
    setCopiedMessages(updatedCopiedMessages);

    setTimeout(() => {
      updatedCopiedMessages[index] = false;
      setCopiedMessages(updatedCopiedMessages);
    }, 2000);
  };

  const pageSize = 5;

  const scrollContainerRef = useRef(null);
  const dispatch = useDispatch();

  // const applicationId = JSON.parse(localStorage.getItem("wano_agent"));
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  const applicationId = getAgentApplication?.agent || null;
  const appId = applicationId?.id;
  const type = applicationId?.type;
  const chatId = section.id;

  const { loading } = useSelector((state) => state.askAgentChatH);
  const { user } = useSelector((state) => state.userLogin);
  const agentChat = useSelector((state) => state.askAgentChat);
  const agentChatH = useSelector((state) => state.getAgentChatH);

  // console.log(agentChatH, "H here this is agent chat H from ChatContent");
  // getAgentChat
  // getAgentChatH
  useEffect(() => {
    const newMessage = {
      role: "user",
      message: agentChat?.data?.data?.title,
    };
    if (agentChat?.data?.data?.title) setChat([newMessage]); // Update chat immediately
  }, []);

  useEffect(() => {
    if (isLoading) {
      setTyping(true);
      const timeOutId = setTimeout(() => {
        setTyping(false);
      }, 5000); //loader time 10000

      return () => clearTimeout(timeOutId);
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(getBusinessAskAgentChatH({ page, pageSize, appId, chatId })).then(
      (res) => {
        if (res?.payload?.data?.length > 0) {
          setChat(res?.payload?.data);
          //setChat((prevChat) => [...(prevChat ?? []), ...res?.payload?.data]);//added this to prevent prevChat iterable error
        } else {
          // setChat(res?.payload?.data);
          //setChat((prevChat) => [...(prevChat ?? []), ...res?.payload?.data]); //added this to prevent prevChat iterable error
        }
        setChatTitle("");
        setDone(done);
        dispatch(getInbox({ appId }));
      }
    );
  }, [trig, data, appId, chatId]); //added dependencies appId, chatId
  const [rated, setRated] = useState([]);
  const rateChatConversation = (messageId, rate) => {
    const data = {
      is_helpful: rate,
    };
    setRated([...rated, { messageId, is_helpful: rate }]);

    dispatch(rateBusinessAskAgentChat({ data, appId, chatId, messageId })).then(
      (res) => {
        setSuccess("Your rating has been successfully recorded!");
        setTimeout(() => {
          setSuccess(false);
        }, 1000);
      }
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitTitle();
    }
  };

  const submitTitle = (tryAgain) => {
    if (isLoading) return;
    setIsLoading(true);

    const newMessage = {
      role: "user",
      message: chatTitle || title,
      tags: tags,
    };
    if (!tryAgain) setChat((prevChat) => [newMessage, ...prevChat]); // Update chat immediately

    //added this to prevent prevChat iterable error
    //setChat((prevChat) => [...(prevChat ?? []), newMessage]); // Update chat immediately

    setError(false);
    setChatTitle("");
    if (inboxChat) {
      console.log("SENDING", inboxChat);

      const data = {
        message: chatTitle || title,
        assistant_type: type,
        tags: tags,
        applicationConversationInboxId: inboxChat.id,
      };
      dispatch(businessAskAgentChatH({ data, appId, chatId }))
        .then((res) => {
          setTrig(res);
          setData(res);
          setTags([]);
          if (res.error.message === "Rejected") {
            setError(true);
            setTags([]);
          }
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          setTags([]);
          setIsLoading(false);

          // Optionally, you can remove the user's message from the chat if the backend request fails.
          setChat((prevChat) => prevChat.slice(1));
          // Optionally, you can remove the user's message from the chat if the backend request fails.
          //  setChat((prevChat) => prevChat.filter((msg) => msg !== newMessage));
        });
    } else {
      const data = {
        message: chatTitle || title,
        assistant_type: type,
        tags: tags,
      };

      dispatch(businessAskAgentChatH({ data, appId, chatId }))
        .then((res) => {
          setTrig(res);
          setData(res);
          setTags([]);
          setTimeout(() => {
            setIsLoading(false);
          }, 500);

          if (res.error.message === "Rejected") {
            setError(true);
            setTags([]);
            setTimeout(() => {
              setIsLoading(false);
            }, 500);
          }
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          setTags([]);

          // Optionally, you can remove the user's message from the chat if the backend request fails.
          setChat((prevChat) => prevChat.slice(1));
          setTimeout(() => {
            setIsLoading(false);
          }, 500);

          // Optionally, you can remove the user's message from the chat if the backend request fails.
          //  setChat((prevChat) => prevChat.filter((msg) => msg !== newMessage));
        });
    }
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  }, [chat, loading, isLoading, typing]);

  const [allItems] = useState([
    // Your array of objects
    { id: 1, text: "Rory Sutherland (@rorysutherland)", logo: twitterLogo },
    { id: 1, text: "Rory Sutherland - Advertising executive", logo: wikiIcon },
    { id: 1, text: "Rory Sutherland (@rorysutherland)", logo: twitterLogo },
    { id: 1, text: "Rory Sutherland - Advertising executive", logo: wikiIcon },
    { id: 1, text: "Rory Sutherland (@rorysutherland)", logo: twitterLogo },
    { id: 1, text: "Rory Sutherland - Advertising executive", logo: wikiIcon },
    { id: 1, text: "Rory Sutherland (@rorysutherland)", logo: twitterLogo },
    { id: 1, text: "Rory Sutherland - Advertising executive", logo: wikiIcon },
  ]);

  const [youtubeLinks] = useState([
    { id: 1, text: "Rory Sutherland (@rorysutherland)", logo: youTubeIcon },
    {
      id: 1,
      text: "Rory Sutherland - Advertising executive",
      logo: youTubeIcon,
    },
  ]);
  const chatImages = [
    // Array of 10 random image URLs
    "https://source.unsplash.com/random/800x600",
    "https://source.unsplash.com/random/800x601",
    "https://source.unsplash.com/random/800x602",
    "https://source.unsplash.com/random/800x603",
    "https://source.unsplash.com/random/800x604",
    "https://source.unsplash.com/random/800x605",
    "https://source.unsplash.com/random/800x606",
    "https://source.unsplash.com/random/800x607",
    "https://source.unsplash.com/random/800x608",
    "https://source.unsplash.com/random/800x609",
  ];
  const renderMarkdown = (text) => {
    const markdown = text || "";
    const __html = marked(markdown, { sanitize: true });
    // console.log("stuff is", text);
    return { __html };
  };
  return (
    <>
      <main className="h-[92vh] flex flex-col pt-[14px] bg-[#FAFBFC]  font-figtree">
        <article className="overflow-y-auto h-[90%]" ref={scrollContainerRef}>
          <section className="px-[36px] flex flex-col">
            {chat?.length > 0 ? (
              chat
                .slice()
                .reverse()
                .map((item, index) => (
                  <React.Fragment key={index}>
                    {item?.role === "user" ? (
                      <div
                        className="px-6 py-4 flex flex-col gap-2"
                        style={{ paddingBottom: "2rem" }}
                      >
                        <div className="gap-[15px] flex items-center">
                          <div className="w-8 h-8 rounded-[28px] bg-[#1774FD] flex justify-center items-center p-[10px]">
                            <p className="text-[16px] font-bold text-white capitalize">
                              {user.data.user.first_name.charAt(0)}
                            </p>
                          </div>
                          <p className="text-sm font-semibold text-[#121212]">
                            You
                          </p>
                        </div>
                        <p className="rounded-[12px] text-[#121212] text-sm font-normal">
                          {item?.message}
                        </p>
                      </div>
                    ) : (
                      <div className="bg-white rounded-[12px] px-6 py-4 flex flex-col gap-[15px] mb-[50px]">
                        <div className="gap-2 flex flex-col">
                          <div className="gap-2 flex items-center">
                            <img
                              className={`${
                                item.application.icon_url && "rounded-full"
                              } w-8 h-8`}
                              src={item.application.icon_url || AiLogo}
                              alt="assistant"
                            />
                            <p className="text-sm font-semibold text-[#121212] capitalize">
                              {applicationId.name} AI
                            </p>
                          </div>
                          {/* SOURCES //// */}
                          {/* <ViewMore allItems={allItems} /> */}
                          <div className="rounded-[12px] text-[#121212] text-sm font-normal chatbox">
                            <Markdown
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[rehypeRaw]}
                              className="prose prose-sm prose-indigo"
                            >
                              {item?.message}
                            </Markdown>
                          </div>
                          {/* <ImagePreview randomImages={chatImages} /> */}
                          {/* <ViewMore allItems={youtubeLinks} noTitle={true} /> */}
                        </div>

                        <div className="flex justify-center gap-4 items-center">
                          <p className="text-[#828282] text-sm font-normal">
                            Rate this response:
                          </p>
                          <button
                            onClick={() => rateChatConversation(item.id, true)}
                            className={`text-[#828282] text-sm font-medium  px-3 py-2 rounded-lg border border-solid shadow-sm
                            ${
                              rated.find((e) => e.messageId === item.id)
                                ?.is_helpful === true
                                ? "border-BLUE-_200 bg-[#D9E9FFC2]"
                                : "border-[#F7F7F7] "
                            }
                            
                            `}
                          >
                            👍 Helpful
                          </button>
                          <button
                            onClick={() => rateChatConversation(item.id, false)}
                            className={`text-[#828282] text-sm font-medium  px-3 py-2 rounded-lg border border-solid shadow-sm
                            ${
                              rated.find((e) => e.messageId === item.id)
                                ?.is_helpful === false
                                ? "border-BLUE-_200 bg-[#D9E9FFC2]"
                                : "border-[#F7F7F7] "
                            }
                            
                            `}
                          >
                            👎 Not Helpful
                          </button>
                          <button
                            onClick={() => handleCopyClick(index, item.message)}
                            className="text-[#1774FD] text-sm font-medium  px-3 py-2 rounded-lg border border-solid border-[#F7F7F7] shadow-sm flex items-center gap-3"
                          >
                            <BiCopy className="text-[18px]" />
                            {copiedMessages[index] ? "Copied" : "Copy"}
                          </button>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))
            ) : (
              // If chat is empty
              <div className="text-center mt-4 text-gray-500">
                No messages yet.
              </div>
            )}
            {(loading ||
              isLoading ||
              agentChat.loading ||
              agentChatH.loading) &&
              (typing ? (
                <div className="-mt-7 w-full h-[100px] bg-[#FAFBFC]">
                  <img
                    src={Loader}
                    alt="Loading..."
                    className="h-full object-contain mix-blend-darken"
                  />
                </div>
              ) : (
                <div className="bg-white rounded-[12px] px-6 py-4 flex flex-col gap-[15px] mb-[50px]">
                  <div className="gap-2 flex flex-col">
                    <div className="gap-2 flex items-center">
                      <img
                        className={`${
                          applicationId.icon_url && "rounded-full"
                        } w-8 h-8`}
                        src={applicationId.icon_url || AiLogo}
                        alt="assistant"
                      />
                      <p className="text-sm font-semibold text-[#121212] capitalize">
                        {applicationId.name} AI
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <div className="-mt-7 w-full h-[100px]">
                        <img
                          src={Loader}
                          alt="Loading..."
                          className="h-full object-contain mix-blend-darken"
                        />
                      </div>
                      <p className="text-sm font-normal text-[#828282]">
                        {/* Please hold on, I’m thinking... */}
                        Give me a moment please...
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            {error && (
              <div className="bg-white rounded-[12px] px-6 py-4 flex flex-col gap-[15px] mb-[50px]">
                <div className="gap-2 flex flex-col">
                  <div className="gap-2 flex items-center">
                    <img
                      className={`${
                        applicationId.icon_url && "rounded-full"
                      } w-8 h-8`}
                      src={applicationId.icon_url || AiLogo}
                      alt="assistant"
                    />
                    <p className="text-sm font-semibold text-[#121212] capitalize">
                      {applicationId.name} AI
                    </p>
                  </div>
                  <div className="flex flex-col gap-3">
                    <p className="text-sm font-normal text-[#828282]">
                      There was a problem somewhere, try again to regenerate a
                      response.
                    </p>
                    <div
                      onClick={() => submitTitle(true)}
                      className="w-[92px] flex justify-center items-center h-[37px] rounded-lg border border-[#EEE] bg-[#FAFAFA] cursor-pointer"
                    >
                      <p className="text-sm font-medium text-[#AF202D]">
                        Try Again
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
        </article>
        <section className="flex-1 flex items-end pb-[14px]">
          <div className="w-full relative px-[36px] ">
            {/* <input
              type="text"
              className=" outline-none w-full pr-10"
              placeholder="Ask me anything"
              value={chatTitle}
              onChange={(e) => {
                setChatTitle(e.target.value);
                setTitle(e.target.value);
              }}
              onKeyPress={handleKeyPress}
            /> */}
            <AtInput4
              type="input"
              className={
                "placeholder:text-[#A6ADB5] outline-none w-full pr-10 relative"
              }
              placeholder="Ask me anything"
              value={chatTitle}
              onChange={(e) => {
                setChatTitle(e.target.value);
                setTitle(e.target.value);
              }}
              onKeyPress={handleKeyPress}
              tags={tags}
              setTags={setTags}
            />
            {/* <AtInput6
              type="input"
              className={
                "placeholder:text-[#A6ADB5] outline-none w-full pr-10 relative"
              }
              placeholder={"Ask me anything"}
              value={chatTitle}
              onChange={(e) => {
                setChatTitle(e.target.value);
                setTitle(e.target.value);
              }}
              onKeyPress={handleKeyPress}
              tags={tags}
              setTags={setTags}
              allowSuggestionsAboveCursor={true}
              minHeight={30}
            /> */}
            <span
              className=" absolute right-11 bottom-3 cursor-pointer"
              onClick={() => submitTitle()}
            >
              {loading || isLoading ? (
                <CgSpinner className=" animate-spin text-lg text-BLACK-_100" />
              ) : (
                <Send />
              )}
            </span>
          </div>
        </section>
      </main>
      {success && <SuccessMessage success={success} className="mt-5 mr-2" />}
    </>

    // <>
    //   <main className="h-[92vh] flex flex-col pt-[14px] bg-[#FAFBFC]  font-figtree">
    //     <article className="overflow-y-auto h-[90%]" ref={scrollContainerRef} >
    //       <section className="px-[36px] flex flex-col">
    //         { chat?.length > 0 ? chat.slice().reverse().map((item, index) => (
    //           <React.Fragment key={index}>
    //             {item?.role === 'user' ? (
    //               <div className="px-6 py-4 flex flex-col gap-2" style={{ paddingBottom: "2rem"}}>
    //                   <div className='gap-[15px] flex items-center'>
    //                     <div className="w-8 h-8 rounded-[28px] bg-[#1774FD] flex justify-center items-center p-[10px]">
    //                       <p className="text-[16px] font-bold text-white capitalize">{user.data.user.first_name.charAt(0)}</p>
    //                     </div>
    //                     <p className="text-sm font-semibold text-[#121212]">
    //                       You
    //                     </p>
    //                   </div>
    //                   <p className='rounded-[12px] text-[#121212] text-sm font-normal'>
    //                     {item?.message}
    //                   </p>
    //                 </div>
    //               ) : (
    //                 <div className="bg-white rounded-[12px] px-6 py-4 flex flex-col gap-[15px] mb-[50px]">
    //                   <div className="gap-2 flex flex-col">
    //                     <div className='gap-2 flex items-center'>
    //                       <img className={`${item.application.icon_url && 'rounded-full'} w-8 h-8`} src={item.application.icon_url  || AiLogo} alt="assistant" />
    //                       <p className="text-sm font-semibold text-[#121212] capitalize">
    //                         {applicationId.name} {/* AI */}
    //                       </p>
    //                     </div>
    //                     <p className='rounded-[12px] text-[#121212] text-sm font-normal'>
    //                       {item?.message}
    //                     </p>
    //                   </div>

    //                   <div className="flex justify-center gap-4 items-center">
    //                     <p className="text-[#828282] text-sm font-normal">
    //                       Rate this response:
    //                     </p>
    //                     <button onClick={() => rateChatConversation(item.id, true)} className="text-[#828282] text-sm font-medium  px-3 py-2 rounded-lg border border-solid border-[#F7F7F7] shadow-sm">
    //                       👍 Helpful
    //                     </button>
    //                     <button onClick={() => rateChatConversation(item.id, false)} className="text-[#828282] text-sm font-medium  px-3 py-2 rounded-lg border border-solid border-[#F7F7F7] shadow-sm">
    //                       👎 Not Helpful
    //                     </button>
    //                     <button onClick={() => handleCopyClick(index, item.message)} className="text-[#1774FD] text-sm font-medium  px-3 py-2 rounded-lg border border-solid border-[#F7F7F7] shadow-sm flex items-center gap-3">
    //                       <BiCopy className="text-[18px]" />
    //                       {copiedMessages[index] ? 'Copied' : 'Copy'}
    //                     </button>
    //                   </div>
    //                 </div>
    //             )}
    //           </React.Fragment>
    //         )): ''}
    //         {loading && (
    //           typing ? (
    //             <div className="-mt-7 w-full h-[100px] bg-[#FAFBFC]">
    //               <img
    //                 src={Loader}
    //                 alt="Loading..."
    //                 className="h-full object-contain mix-blend-darken"
    //               />
    //             </div>
    //           ) : (
    //             <div className="bg-white rounded-[12px] px-6 py-4 flex flex-col gap-[15px] mb-[50px]">
    //               <div className="gap-2 flex flex-col">
    //                 <div className='gap-2 flex items-center'>
    //                   <img className={`${applicationId.icon_url && 'rounded-full'} w-8 h-8`} src={applicationId.icon_url  || AiLogo} alt="assistant" />
    //                   <p className="text-sm font-semibold text-[#121212] capitalize">
    //                     {applicationId.name} AI
    //                   </p>
    //                 </div>
    //                 <div className="flex flex-col">
    //                   <div className="-mt-7 w-full h-[100px]">
    //                     <img
    //                       src={Loader}
    //                       alt="Loading..."
    //                       className="h-full object-contain mix-blend-darken"
    //                     />
    //                   </div>
    //                   <p className="text-sm font-normal text-[#828282]">Please hold on, I’m thinking...</p>
    //                 </div>
    //               </div>
    //             </div>
    //           )
    //         )}
    //         {error && (
    //           <div className="bg-white rounded-[12px] px-6 py-4 flex flex-col gap-[15px] mb-[50px]">
    //             <div className="gap-2 flex flex-col">
    //               <div className='gap-2 flex items-center'>
    //                 <img className={`${applicationId.icon_url && 'rounded-full'} w-8 h-8`} src={applicationId.icon_url  || AiLogo} alt="assistant" />
    //                 <p className="text-sm font-semibold text-[#121212] capitalize">
    //                   {applicationId.name} AI
    //                 </p>
    //               </div>
    //               <div className="flex flex-col gap-3">
    //                 <p className="text-sm font-normal text-[#828282]">There was a problem somewhere, try again to regenerate a response.</p>
    //                 <div onClick={() => submitTitle()} className="w-[92px] flex justify-center items-center h-[37px] rounded-lg border border-[#EEE] bg-[#FAFAFA] cursor-pointer">
    //                   <p className="text-sm font-medium text-[#AF202D]">Try Again</p>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         )}
    //       </section>
    //     </article>
    //     <section className="flex-1 flex items-end pb-[14px]">
    //       <div className="w-full relative px-[36px] ">
    //       <input
    //           type="text"
    //           className=" outline-none w-full pr-10"
    //           placeholder="Ask me anything"
    //           value={chatTitle}
    //           onChange={(e) => {setChatTitle(e.target.value); setTitle(e.target.value)}}
    //           onKeyPress={handleKeyPress}

    //         />
    //         <span className=" absolute right-11 top-3 cursor-pointer" onClick={() => submitTitle()}>
    //           {
    //             loading ?
    //             <CgSpinner className=" animate-spin text-lg text-BLACK-_100" />
    //             :
    //             <Send />
    //           }
    //         </span>
    //       </div>
    //     </section>
    //   </main>
    //   {success && <SuccessMessage success={success} className="mt-5 mr-2" />}
    // </>
  );
}

import React from "react";
import Head from "../../../Assets/svg/Shape65.svg";
import { useTour } from "@reactour/tour";

const Guide = ({ close, text1, text2, text3, label, handleClose }) => {
  const { setIsOpen } = useTour();

  return (
    <div className="bg-white text-[12px] leading-[18px] w-[337px] h-fit p-[18px] rounded-[12px] flex flex-col gap-6 mt-[170px]">
      <div className="flex items-center gap-[10px]">
        <img src={Head} alt="Shape" className="w-[23.63px] h-[23.63px]" />
        <p className=" text-[#7F7F81]">{text1}</p>
      </div>
      <p className="text-[#121212]">{text2}</p>
      <div className="flex justify-between items-center">
        <p className="text-[#7F7F81] cursor-pointer" onClick={handleClose}>{text3}</p> {/* close the modal and register it as viewed */}
        <button
          className="text-white leading-[14.4px] bg-[#1774FD] p-[8px] rounded-[8px]"
          onClick={() => {
            setIsOpen(true);
            handleClose(); //Separate the handleClose call //should only close the modal
          }}
        >
          {label}
        </button>
      </div>
    </div>
  );
};

export default Guide;

import Bank from "../../../Assets/svg/bank.svg";
export default function ComingSoon({ name }) {
  return (
    <section
      className={`w-[264px] rounded-xl pb-1 bg-[rgba(249,249,249,0.86)] h-[158px] `}
    >
      <div className="flex justify-between  px-[15px] items-center pt-5">
        <div className=" flex gap-[12px] items-center opacity-20">
          <img src={Bank} alt="" />
          <p className="capitalize text-[16px] font-medium text-[#121212] whitespace-nowrap">
            {name}
          </p>
        </div>
        <p className=" text-[#1774FD] text-[10px] font-bold whitespace-nowrap">
          Coming Soon!
        </p>
      </div>
    </section>
  );
}

import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";

import WhatsAppIcon from "../../../../Assets/svg/WhatsAppIcon.svg";
import Whatsapp from "../../../../Assets/svg/whatsApp.svg";
import BigPlus from "../../../../Assets/svg/BigPlus.svg"
import Stars from "../../../../Assets/svg/Stars.svg"

import Button from "../../../Button";
import ModalPop from "../../../modalPop";
import Otp from "./Otp";
import axios from "axios";
import TokenService from "../../../../services/token";
import { ErrorMessage } from "../../../../Toast-Messages/Toastmessages";
import { getCountries } from "../../../../features/countries/getCountriesSlice";

import spinner from "../../../../Assets/gif/loader.gif";
import { AiOutlineClose } from "react-icons/ai";

const Phone = ({
  handleClose,
  openOtpViewModal,
  setOpenPhoneModal,
  messaging,
}) => {
  const [loading, setLoading] = useState(false);
  const URL = process.env.REACT_APP_BASEURL;
  const token = TokenService.getToken();
  const appDetail = useSelector((state) => state.getApplicationByUserId);
  const data = appDetail?.data?.data;
  const agentData = localStorage.getItem("wano_agent");
  const agent = data && data.length >= 1 ? data[0] : {};
  const agentDetails = agentData == undefined ? agent : JSON.parse(agentData);
  const applicationId = agentDetails.id;
  const storeCountries = useSelector((state) => state.getAllCountries);
  const countriesLoading = storeCountries.loading;

  const [countrycode, setCountrycode] = useState("+234");

  const formValidationSchema = Yup.object().shape({
    number: Yup.string()
      .required()
      .min(10, "Number must be at least 10 digits")
      .max(11, "Number can't be more than 11 digits"),
  });
  const dispatch = useDispatch();

  const getCountiresAndStore = async () => {
    dispatch(getCountries());
  };
  useEffect(() => {
    getCountiresAndStore();
  }, []);
  const handleClosePhoneModal = () => {
    setOpenPhoneModal(false);
    openOtpViewModal();
  };

  const messageType = messaging ? "messaging" : "ai_bot";

  const submitForm = async (values) => {
    if (!applicationId) {
      console.error(
        "No applicationId provided. submitform function will not run."
      );
      return;
    }
    setLoading(true);
    const data = {
      phone_number: `${countrycode}${values?.number}`,
      type: `${messageType}`,
      channel: "whatsapp",
    };
    try {
      const res = await axios.post(
        `${URL}/businesses/application/${applicationId}/peer/request`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res?.data?.message === "OTP sent successfully") {
        localStorage.setItem("whatsapp", `${countrycode}${values?.number}`);
        localStorage.setItem("channel", `whatsapp`);
        localStorage.setItem("type", messageType);
        setLoading(false);
        handleClosePhoneModal();
      }
    } catch (error) {
      console.error("error", error?.response?.data?.message);
      setError(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  const [error, setError] = useState();

  return (
    <div className="bg-WHITE-_100 w-[500px] h-fit mt-[60px] flex flex-col gap-6 justify-center items-center rounded-lg">{/* h-[459px] */}
      <div className='w-full flex items-center justify-end px-[28px] py-[14px] border-b border-neutral-150'>
        <div className="w-[36px] h-[36px] border rounded-lg mr-3  flex cursor-pointer justify-center items-center p-2" onClick={handleClose}><AiOutlineClose className='text-neutral-500'/></div>
      </div>

      <div className="w-fit flex flex-col gap-5 justify-center items-center">
        {" "}
        <div className="flex flex-col gap-[20px]  justify-center items-center">
            <div className='flex justify-between w-[106.48px] h-[40.32px]'>
                <img src={Whatsapp} alt='whatsapp_icon' className='w-[40.32px] h-[40.32px]'/>
                <img src={BigPlus} alt="plus icon" className=''/>
                <img src={Stars} alt="Star icon" className=''/>
            </div>
            <p className="text-2xl font-medium text-BLACK-_600">Integrate With WhatsApp</p>
            <p className="text-sm font-normal text-BLACK-_300">To integrate your assistant with WhatsApp we need just 2 things</p>
        </div>
        <div className="flex w-[174px] mt-0 justify-center items-center gap-1">
          {" "}
          {/* h-[27px] */}
          <p className="w-[27px] h-[27px] text-[10px] bg-BLUE-_200 rounded-full text-white flex flex-col items-center justify-center">
            1
          </p>
          <div className="w-[24px] h-[2px] border bg-WHITE-_200"></div>
          <p className="w-[27px] h-[27px] text-[10px] bg-BLUE-_300 rounded-full text-white flex flex-col items-center justify-center">
            2
          </p>
          <div className="w-[24px] h-[2px] border bg-WHITE-_200"></div>
          <p className="text-[10px] w-[52px] h-[23px] bg-BLUE-_300 rounded text-white flex flex-col items-center justify-center">
            Deploy
          </p>
        </div>
      </div>
      <div className="flex flex-col w-[420px] mt-4">
        <Formik
          initialValues={{
            number: "",
          }}
          validationSchema={formValidationSchema}
          onSubmit={(values) => {
            window.scrollTo(0, 0);
            submitForm(values);
            // handleClosePhoneModal()
          }}
        >
          {({
            handleSubmit,
            handleChange,
            dirty,
            isValid,
            setFieldValue,
            errors,
            touched,
            // setFieldTouched,
            values,
          }) => (
            <Form
              onSubmit={handleSubmit}
              className="flex flex-col mb-8 lg:items-center"
            >
              <div className="flex flex-col gap-5 ">
                <div className="flex flex-col  ">
                  <label htmlFor="number" className="text-xs text-BLACK-_600">
                    Phone Number
                  </label>

                  <div className=" flex border py-[3px] px-[10px] rounded-[10px] h-10 w-[400px]">
                    {/* <select
                                    // onChange={handleCountryChange}
                                    className="border-r border-solid border-[##E5E5E5] outline-none text-[10px] font-normal text-[#121212]"
                                >
                                    <option
                                        className="flex flex-row text-[10px] font-normal text-[#121212]"
                                    >
                                        +234
                                    </option>
                                </select> */}
                    {countriesLoading ? (
                      <div className="flex items-center justify-center">
                        <img src={spinner} alt="template" className="w-5 h-5" />
                      </div>
                    ) : (
                      <select
                        // onChange={handleCountryChange}
                        className="border-r border-solid border-[##E5E5E5] outline-none text-[10px] font-normal text-[#121212]"
                        onChange={(e) => {
                          const val = e.target.value;
                          const selectedCountry =
                            storeCountries?.countries.find(
                              (country) => country.name === val
                            );
                          setCountrycode(selectedCountry.phone_code);
                        }}
                      >
                        {storeCountries?.countries.map((country) => (
                          <option
                            className="flex flex-row text-[10px] font-normal text-[#121212]"
                            key={country.code}
                            value={country.name}
                          >
                            {country.code} ({country.phone_code})
                          </option>
                        ))}
                      </select>
                    )}
                    <input
                      name="number"
                      placeholder=""
                      type="number"
                      value={values.number}
                      onChange={handleChange}
                      className="outline-none border-0 w-full p-3 "
                    />
                  </div>
                  {/* <p className="text-[10px] text-BLACK-_300">Please note the Whatsapp number can not already be in use for anything on whatsapp</p>
                                {errors.number && touched.number ? (
                                <div className='text-RED-_100 text-xs mt-1'>{errors.number}</div>
                                ) : null}  */}
                  {errors.number && touched.number ? (
                    <div className="text-RED-_100 text-xs mt-1">
                      {errors.number}
                    </div>
                  ) : null}
                  <p className="text-[10px] text-BLACK-_300">
                    Please note the Whatsapp number can not already be in use
                    for anything on whatsapp
                  </p>
                </div>

                <div className="w-full h-[34px] flex justify-end gap-3 mt-2">
                  <Button 
                      text="No,Later"
                      type="button" 
                      onClick={handleClose}  
                      className="bg-none cursor-pointer w-[91px] text-[#828282] text-xs font-semibold border border-neutral-200  rounded-lg" 
                  />
                  <Button
                      // onClick={() => {openCustomsNum}}  
                      loading={loading}
                      text="Continue" 
                      className="bg-BLACK-_100 text-sm w-[91px]  cursor-pointer rounded-lg font-semibold text-WHITE-_100"
                      type="submit" 
                  />
                </div>

                {/* <div className="w-[340px] h-[76px] flex flex-col mx-auto gap-3">
                  <Button
                    loading={loading}
                    text="Next"
                    className="bg-BLACK-_100 text-sm h-[40px] cursor-pointer rounded-lg font-semibold text-WHITE-_100"
                    type="submit"
                  />
                  <Button
                    text="Go Back"
                    type="button"
                    onClick={handleClose}
                    className="bg-none cursor-pointer text-[#868686] font-bold"
                  />
                </div> */}
              </div>
            </Form>
          )}
        </Formik>

        {/* <p className="text-xs text-BLACK-_600">Whatsapp Phone Number</p>
            <input type="text" className='outline-none'/>
            <p className="text-[10px] text-BLACK-_300">Please note the Whatsapp number can not already be in use for anything on whatsapp</p> */}
      </div>
      {/* <div className="w-[340px] h-[76px] flex flex-col mt-14 gap-3">
            <Button
                onClick={handleClosePhoneModal}  
                text="Next" 
                className="bg-BLACK-_100 text-sm h-[40px] cursor-pointer rounded-lg font-semibold text-WHITE-_100"
                type="button" 
            />
            <Button 
                text="Go Back"
                type="button" 
                onClick={handleClose}  
                className="bg-none cursor-pointer text-[#868686] font-bold" 
            /> */}
      {/* </div> */}
      {/* <ModalPop isOpen={openOtpModal}>
            <Otp handleClose={() => setOpenOtpModal(false)} whatsapp={whatsapp}/>
        </ModalPop> */}
      {error && <ErrorMessage error={error} />}
    </div>
  );
};

export default Phone;

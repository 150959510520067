import { HiXMark } from "react-icons/hi2";
import { useState } from "react";
import TokenService from "../../../services/token";
import axios from "axios";
import { CgSpinner } from "react-icons/cg";
import {
  SuccessMessage,
  ErrorMessage,
} from "../../../Toast-Messages/Toastmessages";
export default function MakeRequest({ buttonText, handleClose, text, title }) {
  const [requestTitle, setRequestTitle] = useState("");
  // const [toolLink, setToolLink] = useState("");
  const [requestDescription, setRequestDescription] = useState("");
  const token = TokenService.getToken();
  const URL = process.env.REACT_APP_BASEURL;
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleSendRequest = async () => { // toolLink.trim() === "" ||
    if (
      requestTitle.trim() === "" || requestDescription.trim() === ""
      
      
    ) {
      setError("Please fill in all the fields before sending the request.");
      setTimeout(() => {
        setError(false);
      }, 2000);
      return;
    }
    setIsLoading(true);
    try {
      await axios.post(
        `${URL}/dashboard/request`,
        {
          name: requestTitle,
          // tool_link: "www.web.com", //tooLink
          description: requestDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      setSuccess("Request has been sent successfully");
      setTimeout(() => {
        setSuccess(false);
        handleClose();
      }, 1000);
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating data:", error);
      setError("Unable to make a request at this time");
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };

  return (
    <div className=" min-w-[693px] h-[450px] bg-white mt-10 ml-6 rounded-lg shadow-makeReqModal font-figtree"> {/* changed the height of the modal */}
      <header className=" pl-10 flex pr-6 justify-between pt-[20px] pb-2">
        <h2 className="text-[#101828] font-medium text-lg">{title || "Make Request"}</h2>
        <div
          className="border p-2 -mt-1 rounded-lg border-[#F7F7F7] cursor-pointer"
          onClick={handleClose}
        >
          <HiXMark className="text-[20px]   text-[#828282]" />
        </div>
      </header>
      <hr />
      <section className="pb-6 px-[40px]">
        <p className=" text-[#7F7F81] text-sm font-medium my-[24px]  leading-none">
          {text || "Need an App peculiar to your business? Make a request to let us know"}
        </p>
        <section className="flex flex-col gap-5">
          <div>
            <p className=" mb-3 text-[#101828] font-normal text-xs">
              App Name
            </p>
            <input
              type="text"
              className=" outline-none w-[613px]  h-[38px]"
              value={requestTitle}
              onChange={(e) => setRequestTitle(e.target.value)}
            />
          </div>
        {/* </section> */}
        {/* <section className="flex gap-5 mb-5">
          <div>
            <p className=" mb-3 text-[#101828] font-normal text-xs">
              Tool Link
            </p>
            <input
              type="text"
              className=" outline-none w-[613px]  h-[38px]"
              value={toolLink}
              onChange={(e) => setToolLink(e.target.value)}
            />
          </div>
        </section> */}
        <section>
          <p className=" mb-3 text-[#101828] font-normal text-xs">
            App Description
          </p>
          <textarea
            name=""
            id=""
            cols="73"
            rows="3"
            value={requestDescription}
            onChange={(e) => setRequestDescription(e.target.value)}
            className="outline-none border border-[#D0D5DD] border-solid rounded-lg px-1 pt-1 w-full"
          ></textarea>
        </section>
        </section>
        <div className=" flex justify-end mt-[48px]">
          <button
            className=" font-semibold text-sm text-white bg-[#121212] py-[13px] w-36 rounded-lg shadow-md"
            onClick={handleSendRequest}
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="flex justify-center w-full">
                <CgSpinner className=" animate-spin text-lg " />
              </span>
            ) : (
              <span>{buttonText || "Send Request"}</span>
            )}
          </button>
        </div>
      </section>
      {success && <SuccessMessage success={success} />}
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

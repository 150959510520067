import { FiCopy } from "react-icons/fi";
export default function PublishChange({
  handleClose,
  setShowAgentNotification,
}) {
  return (
    <div className=" w-[519px] h-[500px]  bg-white mt-10 ml-6 rounded-lg shadow-makeReqModal font-figtree py-[30px] px-[50px]">
      <p className=" text-center text-[#101828] text-2xl font-medium">
        Publish Changes
      </p>
      <p className=" text-sm font-normal text-center text-[#828282] mt-5 mb-10">
        Are you sure you want to make your changes life?
      </p>
      <p className=" font-medium text-sm text-[#101828] mb-[19px]">
        Review Changes
      </p>
      <section className=" flex flex-col gap-[10px] mb-6">
        <p className=" text-[#828282] text-sm font-medium  border-b border-[#F7F7F7] border-solid pb-1">
          Added new source(s)
        </p>
        <p className=" text-[#828282] text-sm font-medium border-b border-[#F7F7F7] border-solid py-1 ">
          Disabled Savings Feature
        </p>
        <p className=" text-[#828282] text-sm font-medium border-b border-[#F7F7F7] border-solid py-1">
          Disabled Savings Feature
        </p>
      </section>
      <p className="font-medium text-sm text-[#101828] mb-[19px]">Live Link</p>
      <section className=" flex gap-3">
        <p className=" px-[14px] py-[10px] text-xs font-normal text-[#828282] bg-[#F7F7F7] rounded-lg border border-[#D0D5DD] w-[300px] text-ellipsis whitespace-nowrap overflow-hidden">
          https//whatsapp.me/Test-your-assistant/24567fjf/m....
        </p>
        <button className=" px-[14px] py-[10px] text-xs font-normal text-[#828282] bg-[#F7F7F7] rounded-lg border border-[#D0D5DD] flex items-center gap-2">
          <FiCopy className=" text-base text-[rgba(23,116,253,1)]" />
          <p className="text-[#1774FD] font-medium text-sm">Copy</p>
        </button>
      </section>
      <section className=" mt-6">
        <button
          onClick={() => {
            setShowAgentNotification(false);
            handleClose();
          }}
          className=" text-sm text-white font-bold rounded-lg bg-[#121212] py-3 w-full"
        >
          Publish
        </button>
        <p
          onClick={() => {
            setShowAgentNotification(true);
            handleClose();
          }}
          className=" text-center text-[#828282] font-medium text-sm mt-2 cursor-pointer hover:underline select-none"
        >
          Cancel
        </p>
      </section>
    </div>
  );
}

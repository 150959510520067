import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from "../../services/api"
import { appUrls } from "../../services/urls";



const initialState = {
    loading: false,
    data: [],
    error: ""
};

export const updatePassword = createAsyncThunk(
    "password/updatePassword", 
    async(values, { rejectWithValue }) => {
        try {
            const res = await api.put(appUrls?.UPDATE_PASSWORD, values); 
            return res?.data?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)

const updatePasswordSlice = createSlice({
    name: "profile",
    initialState,
    extraReducers: builder => {
        builder.addCase(updatePassword.pending, state => {
            state.loading = true
        });
        builder.addCase(updatePassword.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(updatePassword.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default updatePasswordSlice.reducer
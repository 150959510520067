import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import AuthLayout from '../layout/AuthLayout'
import Accept from '../component/modals/teams/Accept'
import ModalPop from '../component/modalPop'
import { useDispatch, useSelector } from 'react-redux'
import { checkTeamMemberExist } from '../features/teams/inviteCheckSlice'
import TeamInvitation from '../component/modals/teams/TeamInvitation'

export default function Invitation() {
    const [openModal, setOpenModal] = useState(false);
    const [openTeamInvitationModal, setOpenTeamInvitationModal] = useState(false);

    const dispatch = useDispatch()

    const location = useLocation();
    const code = new URLSearchParams(location.search).get('code');
    // console.log(code, "lapo")

    useEffect(() => {
        dispatch(checkTeamMemberExist(code))
    },[]);

  
    const checkMember = useSelector(state => state.inviteCheckMember);

    const check = checkMember?.data


    const Modal = () => {
        if(check?.userExists) {
            setOpenModal(true)
        } else {
            setOpenTeamInvitationModal(true)
        }
    } 

   


    useEffect(() => {
        Modal()
    }, [check?.userExists])

    return (
        <AuthLayout>
            <ModalPop isOpen={openModal}>
                <Accept handleClose={() => setOpenModal(false)} check={check}/>
            </ModalPop> 

            <ModalPop isOpen={openTeamInvitationModal}>
                <TeamInvitation closeInvitationModal={() => setOpenTeamInvitationModal(false)} check={check}/>
            </ModalPop> 

        </AuthLayout>
    )
}

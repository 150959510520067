// ModalContainer.js
import { useModal } from "@/../context/ModalContext";
import SetupAssistance from "@/../Routes/SetupAssistance";
import UpgradePlan from "@/../Routes/UpgradePlan";
import UpgradeToPlan from "@/../Routes/UpgradeToPlan";
import React, { useState } from "react";
import Pay from "./wallet/Pay";
import RedeemSuccess from "./redeemgift/RedeemSuccess";
import AssistSuccess from "./redeemgift/AssistSuccess";
import { getCurrentPlan } from "@/../features/profile/getCurrentPlanSlice";
import { useDispatch, useSelector } from "react-redux";
import RedeemGift from "./redeemgift";
import { getGiftBalance } from "@/../features/profile/getGiftBalanceSlice";
import ModalPop from "../modalPop";
import {
  ErrorMessage,
  SuccessMessage,
} from "@/../Toast-Messages/Toastmessages";

const ModalContainer = () => {
  const { modals, openModal, closeModal } = useModal();
  const dispatch = useDispatch();

  const [val, setVal] = useState();
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const [isNotSufficient, setSufficient] = useState(false);

  const plan = useSelector((state) => state.getCurrentPlan.data);
  const { subscriptions = [], currentPlan: current = {} } = plan || {};
  const currentPlan = current?.subscription_details?.name;
  const currentPlanIndex = subscriptions?.findIndex(
    (sub) => sub.name === currentPlan
  );
  const nextAvailablePlan = subscriptions?.find(
    (_, index) => index === currentPlanIndex + 1
  );
  const { monthly_amount = "", name = "" } = nextAvailablePlan || {};
  // const nextAvailablePlanName =
  // console.log(nextAvailablePlan, "the index")
  // const monthly_amount = subscriptions[1]?.monthly_amount;
  const business = useSelector((state) => state.getBusiness.activeBusiness);
  const businessID = business?.id;

  const handleModalChange = (newVal) => {
    // console.log(newVal, "playing with the modals vaA");
    return (isOpen) => {
      // console.log(isOpen, "playing modal bool val");
      openModal(newVal);
      setVal(isOpen);
    };
  };

  const setOpenUpgradePlan = handleModalChange("ModalC");
  const setOpenRedeemSuccess = handleModalChange("ModalF");
  const setOpenAssistSuccess = handleModalChange("ModalG");
  const setOpenPayModal = handleModalChange("ModalD");
  const setOpenRedeemGift = handleModalChange("ModalE");

  const isAnyModalOpen = (modalObject) => {
    return Object.values(modals).some((modal) => modal.isOpen);
  };

  return (
    <ModalPop isOpen={isAnyModalOpen()}>
      {modals.ModalA?.isOpen && (
        <SetupAssistance
          handleClose={() => {
            closeModal("ModalA");
          }}
          openModal={(modalType, props) => {
            if (modalType === "ModalB") {
              openModal("ModalB", {
                title: "Setup Assistance",
                text: "Get monthly technical support from an AI specialist on the ready for all your setup needs and more!",
                icon: "isotoxal",
              });
            } else {
              openModal(modalType, props);
            }
          }}
          setError={setError}
          setSuccess={setSuccess}
        />
      )}
      {modals.ModalB?.isOpen && (
        <UpgradeToPlan
          handleClose={() => closeModal("ModalB")}
          setOpenUpgradePlan={setOpenUpgradePlan}
          amount={monthly_amount}
          nextPlanName={name}
          props={modals.ModalB?.props}
        />
      )}
      {modals.ModalC?.isOpen && (
        <UpgradePlan
          handleClose={() => closeModal("ModalC")}
          setOpenRedeemSuccess={setOpenRedeemSuccess}
          data={nextAvailablePlan}
          refresh={() => {
            dispatch(getCurrentPlan());
          }}
          setOpenPayModal={setOpenPayModal}
          setSufficient={setSufficient}
        />
      )}
      {modals.ModalD?.isOpen && (
        <Pay
          handleClose={() => closeModal("ModalD")}
          insufficient
          setOpenRedeemGift={setOpenRedeemGift}
        />
      )}
      {modals.ModalE?.isOpen && (
        <RedeemGift
          handleClose={() => closeModal("ModalE")}
          back={() => {
            openModal("ModalD");
            closeModal("ModalE");
          }}
          setShow={setShow}
          setOpenRedeemSuccess={setOpenRedeemSuccess}
          refresh={() => dispatch(getGiftBalance(businessID))}
        />
      )}
      {modals.ModalG?.isOpen && (
        <AssistSuccess
          handleClose={() => closeModal("ModalG")}
        />
      )}
      {modals.ModalF?.isOpen && (
        <RedeemSuccess
          handleClose={() => {
            closeModal("ModalF");
          }}
          title={"Upgrade Successful"}
          text1={"You have successfully upgraded to the Business Plan"}
          label={"Done"}
          redirectPath={"/settings?page=billing&category=plan"}
        />
      )}

      {success && <SuccessMessage success={success} />}
      {error && <ErrorMessage error={error} />}
    </ModalPop>
  );
};

export default ModalContainer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from "../../services/api"



const initialState = {
    loading: false,
    data: [],
    error: ""
};

export const switchBusiness = createAsyncThunk(
    "put/switchBusiness", 
    async({ id,data }, { rejectWithValue }) => {
        console.log(id,'xxxccc')
        try {
            const res = await api.put(`/dashboard/businesses/${id}/switch`, data); 
            return res?.data?.data

        } catch (error) {
            return rejectWithValue(error?.response?.data?.message)
        }
    }
)

const switchBusinessSlice = createSlice({
    name: "businesses",
    initialState,
    extraReducers: builder => {
        builder.addCase(switchBusiness.pending, state => {
            state.loading = true
        });
        builder.addCase(switchBusiness.fulfilled, (state, action) => void(
            state.loading = false,
            state.data = action.payload,
            state.error = ""
        ));
        builder.addCase(switchBusiness.rejected, (state, action) => void(
            state.loading = false,
            state.data = [],
            state.error = action.payload
        ))
    }
});


export default switchBusinessSlice.reducer
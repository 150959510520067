import { AgentBankIcon, Send } from "../Assets/svg";
import AiLogo from "../Assets/svg/Group 12.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { businessAskAgentChat } from "../features/chat/businessAskAgentChatSlice";
import { businessAskAgentChatH } from "../features/chat/businessAskAgentChatSliceH";
import Suggest from "../Assets/img/suggest.png";
import { getBusinessAskAgentChat } from "../features/chat/getBusinessAskAgentChatSlice";
import Welcome from "../component/modals/welcome/mustCreate";
import ModalPop from "../component/modalPop";
import { useLocation } from "react-router";

import { CgSpinner } from "react-icons/cg";
import AtInput3 from "@/../Plugins/AllPluginType/components/AtInput3";
import AtInput4 from "@/../Plugins/AllPluginType/components/AtInput4";
import { getAllPlugins } from "@/../features/plugins/getAllPluginsSlice";
import AtInput from "@/../Plugins/AllPluginType/components/AtInput";
import AtInput6 from "@/../Plugins/AllPluginType/components/AtInput6";

export default function Maincontent({ data, handleChangeSection, setTrig, tags, setTags }) {
  const [chatTitle, setChatTitle] = useState(" ");
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const [welcomeModal, setOpenWelcomeModal] = useState(false);
  const [employeeChats, setEmployeeChats] = useState({});

  const dispatch = useDispatch();
  const getAgentApplication = useSelector(
    (state) => state?.getApplicationByUserId
  );
  // const applicationId = JSON.parse(localStorage.getItem("wano_agent"));
  const newAgent = getAgentApplication?.agent || null;

  const appId = newAgent?.id;

  // useEffect(() => {
  //   dispatch(getAllPlugins({ appId }))
  // }, [])

  useEffect(() => {
    if (!appId) {
      setOpenWelcomeModal(true);
    }
  }, []);

  //added this
  const handleSwitchEmployee = (agentId) => {
    setEmployeeChats((prevChats) => {
      const updatedChats = { ...prevChats };
      updatedChats[agentId] = [];
      return updatedChats;
    });
  };

  const { loading } = useSelector((state) => state.askAgentChat);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitTitle();
    }
  };
const [isLoading,setIsLoading] = useState(false)
  const submitTitle = () => {
    if(isLoading) return
    setIsLoading(true)
    const data = {
      title: chatTitle,
    };
    dispatch(businessAskAgentChat({ data, appId })).then((res1) => {
      let chatId = res1?.payload.data.id;
      const type = newAgent?.type;
      const data = {
        message: chatTitle,
        assistant_type: type,
        tags: tags
      };
      dispatch(businessAskAgentChatH({ data, appId, chatId })).then((res) => {
        setChatTitle(" ");
        setTrig(res);
        setTags([])
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }).catch(err => {
        setIsLoading(false)

      });
      handleChangeSection({
        type: res1?.payload.data.title,
        id: res1?.payload.data.id,
      });
      setChatTitle(" ");
    });
  };
  const location = useLocation();

  const handleRec = (msg) => {
    const data = {
      title: msg,
    };

    dispatch(businessAskAgentChat({ data, appId })).then((res) => {
      let chatId = res?.payload.data.id;
      const type = newAgent?.type;
      const data = {
        message: msg,
        assistant_type: type,
      };
      dispatch(businessAskAgentChatH({ data, appId, chatId })).then((res) => {
        setChatTitle(" ");
        setTrig(res);
      });
      handleChangeSection({
        type: res?.payload.data.title,
        id: res?.payload.data.id,
      });
      setChatTitle(" ");
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const questionQueryParam = searchParams.get("question");
    if(!questionQueryParam)return
    try {
      switch (questionQueryParam) {
        case "1":
          // Do something when 'question' is '1'
          handleRec("What's happening?");

          break;
        case "2":
          // Do something when 'question' is '2'
          handleRec("Any New Insights Today?");
          break;
        case "3":
          // Do something when 'question' is '2'
          handleRec("Let’s Brainstorm Ideas");
          break;
        default:
          // Handle other cases
          console.log("Unknown question");
          break;
      }
    } catch (e) {
      console.log(e);
    }
    // Do something with the 'question' parameter
  }, []);

  return (
    <main className="h-[90vh] flex flex-col justify-between bg-[#FAFBFC]  font-figtree relative">
      <article className=" overflow-y-auto h-[90%]">
        <div className="h-full flex flex-col">
          <section className="flex justify-center flex-col items-center mt-1 h-[20%]">
            <img
              src={  newAgent?.draft?.icon_url
                ? newAgent?.draft?.icon_url
                : newAgent?.icon_url
                ? newAgent?.icon_url
                : AiLogo}
              alt="sterling"
              className="h-14 w-16 rounded-full"
            />
            <p className=" text-[#7F7F81] text-2xl font-medium mt-[14px]">
              What would you like to know?
            </p>
          </section>
          <div className="flex-1 flex flex-col justify-end">
            <section className="flex justify-center flex-col items-center">
              <p className="text-center w-[365px] text-[#7F7F81] font-normal text-xs mb-3">
                Ask your AI anything about your business or customers, you can
                start with the prompts below
              </p>
            </section>
            <section className="px-8">
              <article className="flex gap-3 w-full mb-2 px-[20px]">
                <div className="gradient-border w-full p-[1.1px]">
                  <div
                    className="rounded-[12px] py-4 px-4 h-auto bg-white cursor-pointer flex flex-col gap-3"
                    style={{ borderRadius: "12px" }}
                    onClick={() => handleRec("Any New Insights Today?")}
                  >
                    <p className="text-[#121212] font-medium text-sm">
                      Any New Insights Today?
                    </p>
                    <img src={Suggest} alt="suggestions" />
                  </div>
                </div>

                <div className="gradient-border w-full p-[1.1px]">
                  <div
                    className="rounded-[12px] p-4 h-auto bg-white w-full cursor-pointer flex flex-col gap-3"
                    onClick={() => handleRec("Let’s Brainstorm Ideas")}
                  >
                    <p className="text-[#121212] font-medium text-sm">
                      Let’s Brainstorm Ideas
                    </p>
                    <img src={Suggest} alt="suggestions" />
                  </div>
                </div>

                <div className="gradient-border w-full p-[1.1px]">
                  <div
                    className="rounded-[12px] p-4 h-auto bg-white w-full cursor-pointer flex flex-col gap-3"
                    onClick={() => handleRec("What’s Happening?")}
                  >
                    <p className="text-[#121212] font-medium text-sm">
                      What’s Happening?
                    </p>
                    <img src={Suggest} alt="suggestions" />
                  </div>
                </div>
              </article>
            </section>
          </div>
        </div>
      </article>
      <section className="pb-[14px]">
        <div className="w-full mt-6 relative px-[36px] ">
          <AtInput4
            type="input"
            className={"placeholder:text-[#A6ADB5] outline-none w-full pr-10 relative"}
            placeholder="Ask me anything"
            value={chatTitle}
            onChange={(e) => setChatTitle(e.target.value)}
            onKeyPress={handleKeyPress}
            tags={tags}
            setTags={setTags}
          />
          {/* <AtInput6
            type="input"
            className={"placeholder:text-[#A6ADB5] outline-none w-full pr-10 relative"}
            placeholder={"Ask me anything"}
            value={chatTitle}
            onChange={(e) => setChatTitle(e.target.value)}
            onKeyPress={handleKeyPress}
            tags={tags}
            setTags={setTags}
            allowSuggestionsAboveCursor={true}
            minHeight={30}
          /> */}
          {/* <input
            type="text"
            className="placeholder:text-[#A6ADB5] outline-none w-full pr-10"
            placeholder="Ask me anything"
            value={chatTitle}
            onChange={(e) => setChatTitle(e.target.value)}
            onKeyPress={handleKeyPress}
          /> */}
          <span
            className=" absolute right-11 top-3 cursor-pointer"
            onClick={submitTitle}
          >
            {loading ? (
              <CgSpinner className=" animate-spin text-lg text-BLACK-_100" />
            ) : (
              <Send />
            )}
          </span>
        </div>
      </section>
      <ModalPop isOpen={welcomeModal}>
        <Welcome handleClose={() => setOpenWelcomeModal(false)} />
      </ModalPop>
    </main>
  );
}
